export class Contato {
  tipo_contato: string = '';
  mensagem: string = '';
  documento: string = '';
  nome: string = '';
  email: string = '';
  telefone:string='';
  copia:boolean=false;
  idusuario:number=0;

}
