import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { centrocustomodel } from 'src/app/Dashboard/Deducao_Compras/vincular-responsavel/CentroCustoModel';
import { UsuarioModel } from 'src/app/Recuperacoes/recuperar-autenticacao/usuario-model';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { EventEmitterService } from 'src/app/shared/EventEmitterService';
import { VincullarResponsavelService } from './vincullar-responsavel.service';
import { SharedModule } from 'src/app/shared/shared.module';

@Component({
  selector: 'app-vincular-responsavel',
  templateUrl: './vincular-responsavel.component.html',
  styleUrls: ['./vincular-responsavel.component.css']
})
export class VincularResponsavelComponent implements OnInit {
  rota: Router;
  perfil: string;
  nome: string;
  cpf: string;


  formusuario: FormGroup;
  formperfil: FormGroup;

  submitted = false;
  modelusuario: UsuarioModel;
  modelperfil: centrocustomodel;

  pastor_presidente: boolean = false;
  pastor_auxiliar: boolean = false;
  ministerio_consolidacao: boolean = false;
  ministerio_obreiro: boolean = false;
  ministerio_diaconato: boolean = false;
  ministerio_louvor: boolean = false;
  ministerio_secretaria: boolean = false;
  ministerio_casais: boolean = false;
  ministerio_jovem: boolean = false;
  ministerio_mulheres: boolean = false;
  ministerio_homens: boolean = false;
  ministerio_danca: boolean = false;
  ministerio_patrimonio: boolean = false;
  ministerio_infantil: boolean = false;
  ministerio_midias: boolean = false;
  ministerio_eventos: boolean = false;
  ministerio_sonoplastia: boolean = false;
  ministerio_geral: boolean = false;
  centrocusto: any;





  constructor(private http: HttpClient,
    private primengConfig: PrimeNGConfig,
    private alert: AlertModalService,
    private autenticado: SharedModule,
    private formBuilder: FormBuilder,
    private service: VincullarResponsavelService,
    r: Router
  ) {
    this.rota = r;
    this.perfil = this.autenticado.perfil;
    EventEmitterService.get('loginAlert').emit(true);

  }

  startedClass = false;
  completedClass = false;
  preventAbuse = false;

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//


  criarformusuario() {
    this.formusuario = this.formBuilder.group({
      cpf: ['', Validators.required],
      nome: ['', Validators.required]

    });
  }

  // Pegar nome dos campos,
  //====================================================================//
  get f() {
    return this.formusuario.controls;
  }


  criarformperfil() {
    this.formperfil = this.formBuilder.group({
      cpf: ['', Validators.required],
      pastor_presidente: [false, Validators.required],
      pastor_auxiliar: [false, Validators.required],
      ministerio_consolidacao: [false, Validators.required],
      ministerio_obreiro: [false, Validators.required],
      ministerio_diaconato: [false, Validators.required],
      ministerio_louvor: [false, Validators.required],
      ministerio_secretaria: [false, Validators.required],
      ministerio_casais: [false, Validators.required],
      ministerio_jovem: [false, Validators.required],
      ministerio_mulheres: [false, Validators.required],
      ministerio_homens: [false, Validators.required],
      ministerio_danca: [false, Validators.required],
      ministerio_patrimonio: [false, Validators.required],
      ministerio_infantil: [false, Validators.required],
      ministerio_midias: [false, Validators.required],
      ministerio_eventos: [false, Validators.required],
      ministerio_sonoplastia: [false, Validators.required],
      ministerio_geral: [false, Validators.required],


    });
  }




  ngOnInit(): void {
    if (this.autenticado.token == null) {
      this.autenticado.ResetDados();
      this.alert.AlertShowDanger('Usuário não autenticado, redirecionando...')
      this.rota.navigate(['/login']);
    } else {
      if (this.autenticado.ValidarToken() != true) {
        this.autenticado.ResetDados();
        this.alert.AlertShowDanger('Token Invalido!')
        this.rota.navigate(['/login']);
      }
      this.modelperfil = new centrocustomodel();
      this.modelusuario = new UsuarioModel();

      this.criarformperfil();
      this.criarformusuario();
    }
  }



  get p() {
    return this.formperfil.controls;
  }


  consultacpf(cpf: any) {
    if (this.modelusuario.cpf.length >= 8) {
      this.service.Consulta(this.modelusuario.cpf).subscribe(
        (success) => {
          this.StopProgressBar();

          (this.modelusuario.nome = success.nome);
          (this.modelperfil.cpf = this.modelusuario.cpf);
          this.CarrgarPerfil(this.modelusuario.cpf)

        },
        (error) => {
          if (error.status == 200) {
            (this.modelusuario.nome = error.error.text);
            (this.modelperfil.cpf = this.modelusuario.cpf);
            this.CarrgarPerfil(this.modelusuario.cpf)
          }
          this.StopProgressBar();
        }
      );
    }
  }



  CarrgarPerfil(cpf) {
    this.service
      .GETPErfil(cpf)
      .subscribe(
        (success) => {

          if (success.pastor_presidente == "T") {
            this.modelperfil.pastor_presidente = true;
            this.pastor_presidente = true;
          }

          if (success.pastor_auxiliar == "T") {
            this.modelperfil.pastor_auxiliar = true;
            this.pastor_auxiliar = true;
          }

          if (success.ministerio_consolidacao == "T") {
            this.modelperfil.ministerio_consolidacao = true;
            this.ministerio_consolidacao = true;
          }

          if (success.ministerio_obreiro == "T") {
            this.modelperfil.ministerio_obreiro = true;
            this.ministerio_obreiro = true;
          }

          if (success.ministerio_diaconato == "T") {
            this.modelperfil.ministerio_diaconato = true;
            this.ministerio_diaconato = true;
          }

          if (success.ministerio_louvor == "T") {
            this.modelperfil.ministerio_louvor = true;
            this.ministerio_louvor = true;
          }

          if (success.ministerio_secretaria == "T") {
            this.modelperfil.ministerio_secretaria = true;
            this.ministerio_secretaria = true;
          }

          if (success.ministerio_casais == "T") {
            this.modelperfil.ministerio_casais = true;
            this.ministerio_casais = true;
          }

          if (success.ministerio_jovem == "T") {
            this.modelperfil.ministerio_jovem = true;
            this.ministerio_jovem = true;
          }

          if (success.ministerio_mulheres == "T") {
            this.modelperfil.ministerio_mulheres = true;
            this.ministerio_mulheres = true;
          }

          if (success.ministerio_homens == "T") {
            this.modelperfil.ministerio_homens = true;
            this.ministerio_homens = true;
          }

          if (success.ministerio_danca == "T") {
            this.modelperfil.ministerio_danca = true;
            this.ministerio_danca = true;
          }

          if (success.ministerio_patrimonio == "T") {
            this.modelperfil.ministerio_patrimonio = true;
            this.ministerio_patrimonio = true;
          }

          if (success.ministerio_infantil == "T") {
            this.modelperfil.ministerio_infantil = true;
            this.ministerio_infantil = true;
          }

          if (success.ministerio_midias == "T") {
            this.modelperfil.ministerio_midias = true;
            this.ministerio_midias = true;
          }

          if (success.ministerio_eventos == "T") {
            this.modelperfil.ministerio_eventos = true;
            this.ministerio_eventos = true;
          }

          if (success.ministerio_sonoplastia == "T") {
            this.modelperfil.ministerio_sonoplastia = true;
            this.ministerio_sonoplastia = true;
          }

          if (success.ministerio_geral == "T") {
            this.modelperfil.ministerio_geral = true;
            this.ministerio_geral = true;
          }

        },
        (error) => {
          this.rota.navigate(['/dashboard/gerenciamento/deducao-compras/vincular-responsavel']);
        }
      );
  }


  onSubmit() {
    this.submitted = true;

    if (this.formusuario.invalid) {
      this.alert.AlertShowDanger('Favor verificar os campos em destaque!');
      return;
    }
    this.formperfil.controls['cpf'].setValue(this.modelusuario.cpf);
    if (this.formperfil.invalid) {
      this.alert.AlertShowDanger('Favor verificar os campos em destaque!');
      return;
    }

    this.modelperfil.cpf = this.modelusuario.cpf;
    this.service.CadastroVinculo(this.formperfil.value).subscribe(
      () => {
        this.alert.AlertShowSucess('Perfil atualizado com sucesso!');
        this.modelperfil = new centrocustomodel();
        this.modelusuario = new UsuarioModel();
        this.submitted = false;
      },
      (error) => {
        this.alert.AlertShowDanger('Erro ao Atualizar Perfil!');
      });
  }

}
