<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>
<div class="border wrapper">
    <h4 class="espacamentotopo">
        <img src="../../assets/img/cadastro.jpeg" style="width: 40px" /> Cadastro Seminario
    </h4>
    <label class="j">
        Seja bem-vindo(a)!<br />
        Você está na área de cadastro para participação dos seminarios ministrados por nossa igreja, Informamos que
        todos os dados aqui informados são tratados somente por nossa equipe de
        gestão não sendo compartilhados com terceiros!
    </label>
    <label class="j">
        Lembramos que os campos com (<strong class="cor"> *</strong> ) são campos de
        preenchimento obrigatório!
    </label>
    <hr class="espacamentotopo bold" />
    <form [formGroup]="formseminario" class="m" disabled="">
        <div class="row">
            <!---INCIO PRIMEIRA LINHA-->
            <div class="col-md-2">
                <div class="form-group">
                    <label>CPF:</label><strong class="cor">*</strong>
                    <input type="text" class="form-control" id="cpf" placeholder="informe o CPF" mask="CPF_CNPJ"
                        name="cpf" maxlength="14" autocomplete="off" formControlName="cpf"
                        [(ngModel)]="cadastroseminario.cpf" [ngClass]="{ 'is-invalid': submitted && f.cpf.errors }" />
                    <div *ngIf="submitted && f.cpf.errors" class="invalid-feedback">
                        <div *ngIf="f.cpf.errors.required">Preenchimento obrigatório</div>
                    </div>
                </div>
            </div>

            <div class="col-md-7">
                <div class="form-group">
                    <label>Nome Completo:</label><strong class="cor">*</strong>
                    <input type="text" class="form-control" id="nome" name="nome" [(ngModel)]="cadastroseminario.nome"
                        placeholder="Informe Nome Completo" formControlName="nome" maxlength="500" minlength="3"
                        [(ngModel)]="cadastroseminario.nome" autocomplete="off"
                        [ngClass]="{ 'is-invalid': submitted && f.nome.errors }" />
                    <div *ngIf="submitted && f.nome.errors" class="invalid-feedback">
                        <div *ngIf="f.nome.errors.required">Preenchimento obrigatório</div>
                    </div>
                </div>
            </div>

            <div class="col-md-3">
                <label>WhatsApp:</label><strong class="cor">*</strong>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1"><i class="fas fa-phone"></i></span>
                    </div>
                    <input type="text" class="form-control" id="telefone" name="telefone"
                        [(ngModel)]="cadastroseminario.telefone" placeholder="Informe o Telefone"
                        title="Formato (DD) 0000-0000 ou (DD) 00000-0000" formControlName="telefone" maxlength="500"
                        minlength="3" autocomplete="off" mask="(00) 0000-0000 || (00) 00000-0000" [ngClass]="{
                  'is-invalid': submitted && f.telefone.errors
                }" />
                    <div *ngIf="submitted && f.telefone.errors" class="invalid-feedback">
                        <div *ngIf="f.telefone.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!---FIM PRIMEIRA LINHA-->

        <!---INICIO SEGUNDA LINHA-->
        <div class="row">
            <div class="col-md-5">
                <label>E-mail:</label><strong class="cor">*</strong>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1"><i class="fas fa-at"></i></span>
                    </div>
                    <input type="email" class="form-control" id="email" placeholder="Informe E-mail" name="email"
                        formControlName="email" [(ngModel)]="cadastroseminario.email" maxlength="500" minlength="3"
                        autocomplete="off" [ngClass]="{
                            'is-invalid': submitted && f.email.errors
                          }" />
                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                        <div *ngIf="f.email.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-md-7">
                <div class="dropdown">
                    <label>Seminario:</label><strong class="cor">*</strong>
                    <div class="mb-3">
                        <select class="custom-select" type="text" name="tipo_seminario" id="tipo_seminario"
                            [(ngModel)]="cadastroseminario.tipo_seminario"
                            (change)="DadosSeminario(cadastroseminario.tipo_seminario)"
                            (blur)="DadosSeminario(cadastroseminario.tipo_seminario)" formControlName="tipo_seminario"
                            [ngClass]="{ 'is-invalid': submitted && f.tipo_seminario.errors }">
                            <div *ngIf="submitted && f.tipo_seminario.errors" class="invalid-feedback"></div>
                            <option value=""></option>
                            <option *ngFor="let seminario of seminarios" [ngValue]="seminario">
                                {{ seminario }}
                            </option>
                        </select>
                        <span
                            style="font-size: small; margin-left: 5px; font-style: oblique;"><b>{{dados_seminario}}</b></span>
                        <div *ngIf="submitted && f.tipo_seminario.errors" class="invalid-feedback">
                            <div *ngIf="f.tipo_seminario.errors.required">
                                Preenchimento obrigatório
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <!---FIM SEGNDA LINHA-->
        <div class="row">
            <div class="col-md-3">
                <div class="dropdown">
                    <label>Como ficou sabendo?:</label><strong class="cor">*</strong>
                    <div class="mb-3">
                        <select class="custom-select" type="text" name="ficou_sabendo" id="ficou_sabendo"
                            [(ngModel)]="cadastroseminario.ficou_sabendo" formControlName="ficou_sabendo"
                            [ngClass]="{ 'is-invalid': submitted && f.ficou_sabendo.errors }">
                            <div *ngIf="submitted && f.ficou_sabendo.errors" class="invalid-feedback"></div>
                            <option value=""></option>
                            <option *ngFor="let d of ficousabendo" [ngValue]="d">
                                {{ d }}
                            </option>
                        </select>
                        <div *ngIf="submitted && f.ficou_sabendo.errors" class="invalid-feedback">
                            <div *ngIf="f.ficou_sabendo.errors.required">
                                Preenchimento obrigatório
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="dropdown">
                    <label>Pertence da Morada de Deus:</label><strong class="cor">*</strong>
                    <div class="mb-3">
                        <select class="custom-select" type="text" name="ministerio_morada"
                            (change)="changeministeriomorada($event)" id="ministerio_morada"
                            [(ngModel)]="cadastroseminario.ministerio_morada" formControlName="ministerio_morada"
                            [ngClass]="{ 'is-invalid': submitted && f.ministerio_morada.errors }">
                            <div *ngIf="submitted && f.ministerio_morada.errors" class="invalid-feedback"></div>
                            <option value=""></option>
                            <option *ngFor="let d of membromorada" [ngValue]="d">
                                {{ d }}
                            </option>
                        </select>
                        <div *ngIf="submitted && f.ministerio_morada.errors" class="invalid-feedback">
                            <div *ngIf="f.ministerio_morada.errors.required">
                                Preenchimento obrigatório
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <label>Pertence Qual Ministerio?:</label><strong class="cor">*</strong>

                <div class="input-group mb-3" *ngIf="mostrar =='T' ">
                    <div class="input-group-prepend">
                    </div>
                    <input type="text" class="form-control" id="ministerio_pertence"
                        placeholder="Informe Ministerio Pertence" name="ministerio_pertence"
                        formControlName="ministerio_pertence" [(ngModel)]="cadastroseminario.ministerio_pertence"
                        maxlength="500" minlength="3" autocomplete="off" [ngClass]="{
                            'is-invalid': submitted && f.ministerio_pertence.errors
                          }" />
                    <div *ngIf="submitted && f.ministerio_pertence.errors" class="invalid-feedback">
                        <div *ngIf="f.ministerio_pertence.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>
                <div class="input-group mb-3" *ngIf="mostrar =='F' ">
                    <div class="input-group-prepend">
                    </div>
                    <input type="text" class="form-control" id="ministerio_pertence" disabled
                        placeholder="Informe Ministerio Pertence" name="ministerio_pertence"
                        formControlName="ministerio_pertence" [(ngModel)]="cadastroseminario.ministerio_pertence"
                        maxlength="500" minlength="3" autocomplete="off" [ngClass]="{
                            'is-invalid': submitted && f.ministerio_pertence.errors
                          }" />
                    <div *ngIf="submitted && f.ministerio_pertence.errors" class="invalid-feedback">
                        <div *ngIf="f.ministerio_pertence.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-2">
                <div class="form-group">
                    <label>Cep:</label>
                    <input type="text" class="form-control" placeholder="Informe o CEP" id="cep" mask="00.000-000"
                        name="cep" formControlName="cep" (blur)="consulta_cep(cadastroseminario.cep)"
                        [(ngModel)]="cadastroseminario.cep" maxlength="500" minlength="3" />
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>Logradouro:</label>
                    <input type="text" class="form-control" id="logradouro" placeholder="Informe Logradouro"
                        name="logradouro" formControlName="logradouro" [(ngModel)]="cadastroseminario.logradouro"
                        maxlength="500" minlength="3" />
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group">
                    <label>Numero:</label>
                    <input type="number" class="form-control" id="numero" placeholder="Numero" name="numero"
                        formControlName="numero" [(ngModel)]="cadastroseminario.numero" maxlength="9" />
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group">
                    <label>apto:</label>
                    <input type="number" class="form-control" id="apto" placeholder="apto" name="apto"
                        formControlName="apto" [(ngModel)]="cadastroseminario.apto" maxlength="9" />
                </div>
            </div>
        </div>
        <!----->
        <!----->
        <div class="row">
            <div class="col-md-5">
                <div class="form-group">
                    <label>Bairro:</label>
                    <input type="text" class="form-control" id="bairro" placeholder="Informe o Bairro" name="bairro"
                        formControlName="bairro" [(ngModel)]="cadastroseminario.bairro" maxlength="500" minlength="3" />
                </div>
            </div>
            <div class="col-md-2">
                <div class="dropdown">
                    <label>UF:</label>
                    <div class="mb-3">
                        <select class="custom-select" type="text" name="uf" id="uf" [(ngModel)]="cadastroseminario.uf"
                            formControlName="uf">
                            <option value=""></option>
                            <option *ngFor="let city of luf" [ngValue]="city">
                                {{ city }}
                            </option>
                        </select>
                    </div>
                </div>
            </div>
            <div class="col-md-5">
                <div class="form-group">
                    <label>Cidade:</label>
                    <input type="text" class="form-control" id="cidade" placeholder="Informe sua cidade" name="cidade"
                        formControlName="cidade" [(ngModel)]="cadastroseminario.cidade" maxlength="500" minlength="3" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md">
                <div class="form-group">
                    <label style="padding: 0%;">Alguma dúvida?:</label> <span style="font-size: x-small; padding: 0%;">
                        (
                        Favor preencher somente em caso de dúvidas )</span>
                    <textarea type="text" rows="2" class="form-control" id="duvida" aria-describedby="emailHelp"
                        name="duvida" formControlName="duvida" [(ngModel)]="cadastroseminario.duvida" autocomplete="off"
                        maxlength="500" minlength="3"></textarea>

                    <span>{{ cadastroseminario.duvida.length }}/500</span>
                </div>
            </div>
        </div>
        <!----->
        <div>
            <button pButton pRipple type="button" icon="pi pi-save" class="p-button-rounded" style="margin-right: 5px;"
                title=":: SALVAR ::" (click)="onSubmit()"></button>
            <button pButton pRipple type="button" icon="pi pi-refresh" style="margin-right: 5px;"
                class="p-button-rounded p-button-secondary" title="::LIMPAR::" (click)="reset()"></button>
            <button pButton pRipple type="button" icon="pi pi-home" style="margin-right: 5px;"
                class="p-button-rounded p-button-help" title="::HOME::" routerLink=""></button>
        </div>

        <!-- Modal -->
        <button id="openModalButton" [hidden]="true" data-toggle="modal" data-target="#myModal"></button>
        <div class="modal fade bd-example-modal-lg" data-backdrop="static" data-keyboard="false" id="myModal"
            tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                            <i class="fas fa-info-circle" style="color: blue"></i>
                            {{ modaltitulo }}
                        </h5>
                    </div>
                    <div class="modal-body">
                        <div [innerHTML]="modalconteudo"></div>
                    </div>
                    <div class="modal-footer">
                        <div>
                            <button pButton pRipple type="button" icon="pi pi-exclamation-triangle"
                                class="p-button-rounded p-button-warning" title=":: REPORTAR PROBLEMAS ::"
                                data-dismiss="modal" routerLink="/problema"></button>
                            <button pButton pRipple type="button" icon="pi pi-times"
                                class="p-button-rounded p-button-danger" title=":: FECHAR ::" data-dismiss="modal"
                                (click)="reset()">
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </form>
</div>
