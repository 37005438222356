import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { Parametros } from 'src/app/shared/parametros.enum';
import { estabelecimentomodel } from '../cadastro-estabelecimento/EstabelecimentoModel';

@Component({
  selector: 'app-listagem-geral-estabelecimentos',
  templateUrl: './listagem-geral-estabelecimentos.component.html',
  styleUrls: ['./listagem-geral-estabelecimentos.component.css']
})
export class ListagemGeralEstabelecimentosComponent implements OnInit {

  private urlestabelecimento = Parametros.CADASTROESTABELECIMENTO;
  filtro_cabecalho: string = ""
  explicacao: string = "";
  textoconsulta: string = "*";
  idestabelecimento: any;

  constructor(

    private http: HttpClient,
    private primengConfig: PrimeNGConfig,
    private alert: AlertModalService,

  ) {

    
  }

  startedClass = false;
  completedClass = false;
  preventAbuse = false;

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//

  estabelecimento: estabelecimentomodel[];
  qto: number;
  cols: any[];


  ngOnInit() {

    this.cols = [
      { field: 'estabelecimento', header: 'Estabelecimento' },
      { field: 'telefone', header: 'Contato' },
      { field: 'funcionamento', header: 'Funcionamento' },
      { field: 'cliques', header: 'Visualizações' }

    ];

    this.primengConfig.ripple = true;
  this.Ranking();
  }

  UpdateClique(event) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
    this.http.get(this.urlestabelecimento + 'GETUpdateClique/?parametro=' + event, httpOptions).subscribe(
      (success) => {

      }
    )
  }

  Ranking() {
    this.StartProgressBar();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
    this.http.get<estabelecimentomodel[]>(this.urlestabelecimento + 'GETEstabelecimentoRanking', httpOptions).subscribe(
      (success) => {
        this.StopProgressBar();
        this.estabelecimento = success;
        this.qto = success.length;
        this.textoconsulta = "";

        if (success.length == 0) {

          this.alert.AlertShowInfo('Empresa/Serviço não encontrada!');
        }

      }
     
    )
    this.StopProgressBar();
  }

  Consulta() {
    
    var texto = (<HTMLInputElement>document.getElementById("texto_consulta")).value;
    if (texto != "") {
      this.textoconsulta = texto;
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
    this.StartProgressBar();
    this.http.get<estabelecimentomodel[]>(this.urlestabelecimento + 'GETConsultaEstabelecimento/?parametro=' + this.textoconsulta, httpOptions).subscribe(
      (success) => {
        this.StopProgressBar();
        this.estabelecimento = success;
        this.qto = success.length;
        this.textoconsulta = "";

        if (success.length == 0) {

          this.alert.AlertShowInfo('Empresa/Serviço não encontrada!');
        }
      }

    )
    this.StopProgressBar();
  }

}
