import { calcPossibleSecurityContexts } from '@angular/compiler/src/template_parser/binding_parser';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { take } from 'rxjs/operators';
import { seminariomodel } from 'src/app/seminarios/seminario-cadastro/seminariomodel';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { PerfilService } from '../../perfil/perfil.service';
import { ComunicadoService } from './comunicado.service';
import { ComunicadoSeminarioModel } from './ComunicadoSeminarioModel';

@Component({
  selector: 'app-comunicado-seminario',
  templateUrl: './comunicado-seminario.component.html',
  styleUrls: ['./comunicado-seminario.component.css']
})
export class ComunicadoSeminarioComponent implements OnInit {

  rota: Router;
  comunicadomodel: ComunicadoSeminarioModel;
  formcomunicadoseminario: FormGroup;
  startedClass = false;
  completedClass = false;
  preventAbuse = false;
  submitted: boolean;
  converted_image: string;
  Total: number = 0;
  @ViewChild("fileInput") fileInput;

  listapara: any = [
    'Todos usuarios Cadastrados ( Ativos e Inativos )',
    'Todos usuarios Ativos',
    'Todos usuarios Inativos',
    'CRESCIMENTO E DESENVOLVIMENTO DE MINISTÉRIOS',
    'BATALHA ESPIRITUAL E CURA INTERIOR',
    'LOUVOR ADORAÇÃO E CHAMADO'];
  base64Data: { new(sw: number, sh: number): ImageData; new(data: Uint8ClampedArray, sw: number, sh?: number): ImageData; prototype: ImageData; };
  anexo: string = "";
  fotopadrao: string;


  constructor(
    private formBuilder: FormBuilder,
    private alert: AlertModalService,
    private serviceseminario: ComunicadoService,
    private serviceperfil: PerfilService,
    private router: Router,
    private autenticado: SharedModule,
  ) {
    this.rota = this.router;
    this.comunicadomodel = new ComunicadoSeminarioModel();
  }

  ngOnInit() {
    if (this.autenticado.token == null) {
      this.autenticado.ResetDados();
      this.alert.AlertShowDanger('Usuário não autenticado, redirecionando...')
      this.rota.navigate(['/login']);
    } else {
      if (this.autenticado.ValidarToken() != true) {
        this.autenticado.ResetDados();
        this.alert.AlertShowDanger('Token Invalido!')
        this.rota.navigate(['/login']);
      } else {
        this.comunicadomodel = new ComunicadoSeminarioModel();
        this.criarform();
        this.carregarusuario();
        this.carregarfoto();
      }
    }
  }

  carregarusuario() {
    this.serviceperfil
      .GetUsuaruio(this.autenticado.hash)
      .subscribe(
        (success) => {
          if (success.nome != null) {
            (this.comunicadomodel.nome = success.nome),
              (this.comunicadomodel.cpf = success.cpf);
            if (success.receber_comunicado == "T") {
              this.formcomunicadoseminario.controls['cpf'].setValue(success.cpf);
              this.formcomunicadoseminario.controls['nome'].setValue(success.nome);
            } else {
            }
          }
        },
        (error) => {
          this.StopProgressBar();
          this.alert.AlertShowDanger(
            '( ' + error.status + ' ) ' + error.message
          );
        }
      );
  }

  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }

  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  criarform() {
    this.formcomunicadoseminario = this.formBuilder.group({
      cpf: ['', Validators.required],
      nome: ['', Validators.required],
      assunto: ['', Validators.required],
      mensagem: ['', Validators.required],
      c: ['', Validators.required],
      para: [''],
      anexo: ['']

    });
  }

  
  totalizador() {
    this.serviceseminario.GetQuantidadeEnvio(this.autenticado.token, this.formcomunicadoseminario.get('c').value).subscribe(
      (success) => {
        this.Total = success;

        if (this.Total == 0) {
          this.alert.AlertShowDanger('Não existem usuarios para o tipo Selecionado!');
        }
      }
    );
  }


  SetPara() {
    this.totalizador();



  }

  carregarfoto() {
    this.base64Data = ImageData;
    this.serviceseminario.DownloadFoto(this.autenticado.hash, this.autenticado.token)
      .subscribe(
        (success) => {
          this.converted_image = "data:image/jpeg;base64," + success;
          this.fotopadrao = "data:image/jpeg;base64," + success;
        },
        (error) => {
          if (error.error.text != 'StatusCode(400,Sem Dados de Foto)') {
            this.converted_image = "data:image/jpg;base64," + error.error.text;
            this.fotopadrao = "data:image/jpg;base64," + error.error.text;
          } else {
            this.converted_image = "data:image/jpeg;base64," + error.error.text;
            this.fotopadrao = "data:image/jpeg;base64," + error.error.text;
          }
        }
      );
  }

  FotoPadrao() {
    this.converted_image = "../../../../assets/img/foto.png";
    this.formcomunicadoseminario.controls['anexo'].setValue("");
    this.anexo = "";
  }

  SetFoto(event) {
    this.anexo = "x";
  }


  onSubmit() {
    this.submitted = true;
    if (this.formcomunicadoseminario.invalid) {
      this.alert.AlertShowDanger('Favor verificar os campos em destaque!');
      return;
    }
    let fi = this.fileInput.nativeElement;

    if (this.anexo == "undefined" || this.anexo == "") {
      this.formcomunicadoseminario.controls['anexo'].setValue("");
      this.serviceseminario.GetQuantidadeEnvio(this.autenticado.token, this.formcomunicadoseminario.get('c').value).subscribe(
        (success) => {
          this.StopProgressBar();
          if (success == 0) {
            this.alert.AlertShowDanger("Nada a ser Enviado!");
            this.Total = 0;
            return;
          } else {
            this.formcomunicadoseminario.controls['para'].setValue(this.formcomunicadoseminario.get('c').value);
            this.alert.AlertShowInfo("Estamos preparando e enviando ( " + success + " ) e-mails! Pode descansar enquanto trabalhamos!")
            this.serviceseminario.UpdateComunicado(this.autenticado.token, this.formcomunicadoseminario.value)
            this.comunicadomodel = new ComunicadoSeminarioModel();
            this.criarform();
            this.submitted = false;
            this.carregarusuario();
            this.carregarfoto();
            this.Total = 0;
          }
        },
        (error) => {
          this.StopProgressBar();
          this.alert.AlertShowDanger("Não Existem dados a serem enviados!")
          this.comunicadomodel = new ComunicadoSeminarioModel();
          this.criarform();
          this.submitted = false;
          this.carregarusuario();
          this.carregarfoto();
          this.Total = 0;
        });



    } else {
      if (fi.files && fi.files[0]) {
        let fileToUpload = fi.files[0];
        this.serviceseminario.UploadArquivo(fileToUpload, this.autenticado.token, this.autenticado.cpf)
          .subscribe(
            (success) => {
              this.formcomunicadoseminario.controls['anexo'].setValue(success);
              this.serviceseminario.GetQuantidadeEnvio(this.autenticado.token, this.formcomunicadoseminario.get('c').value).subscribe(
                (success) => {
                  this.StopProgressBar();
                  if (success == 0) {
                    this.alert.AlertShowDanger("Nada a ser Enviado!");
                    this.Total = 0;
                    return;
                  } else {
                    this.formcomunicadoseminario.controls['para'].setValue(this.formcomunicadoseminario.get('c').value);
                    this.alert.AlertShowInfo("Estamos preparando e enviando ( " + success + " ) e-mails! Pode descansar enquanto trabalhamos!")
                    this.serviceseminario.UpdateComunicado(this.autenticado.token, this.formcomunicadoseminario.value)
                    this.comunicadomodel = new ComunicadoSeminarioModel();
                    this.criarform();
                    this.submitted = false;
                    this.carregarusuario();
                    this.Total = 0;
                  }
                },
                (error) => {
                  this.StopProgressBar();
                  this.alert.AlertShowDanger("Não Existem dados a serem enviados!")
                  this.comunicadomodel = new ComunicadoSeminarioModel();
                  this.criarform();
                  this.submitted = false;
                  this.carregarusuario();
                  this.Total = 0;

                });
            },
            (error) => {
              this.anexo = error.error.text;
              this.formcomunicadoseminario.controls['anexo'].setValue(this.anexo);
              this.formcomunicadoseminario.controls['para'].setValue(this.formcomunicadoseminario.get('c').value);
              this.serviceseminario.GetQuantidadeEnvio(this.autenticado.token, this.formcomunicadoseminario.get('c').value).subscribe(
                (success) => {
                  this.StopProgressBar();
                  if (success == 0) {
                    this.alert.AlertShowDanger("Nada a ser Enviado!");
                    this.Total = 0;
                    return;
                  } else {
                    this.formcomunicadoseminario.controls['para'].setValue(this.formcomunicadoseminario.get('c').value);
                    this.alert.AlertShowInfo("Estamos preparando e enviando ( " + success + " ) e-mails! Pode descansar enquanto trabalhamos!")
                    this.serviceseminario.UpdateComunicado(this.autenticado.token, this.formcomunicadoseminario.value)
                    this.comunicadomodel = new ComunicadoSeminarioModel();
                    this.criarform();
                    this.submitted = false;
                    this.carregarusuario();
                    this.Total = 0;
                  }
                },
                (error) => {
                  this.StopProgressBar();
                  this.alert.AlertShowDanger("Não Existem dados a serem enviados!")
                  this.comunicadomodel = new ComunicadoSeminarioModel();
                  this.criarform();
                  this.submitted = false;
                  this.carregarusuario();
                  this.Total = 0;

                });
            });
      }
    }


  }


  get f() {
    return this.formcomunicadoseminario.controls;
  }

}


