<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>
<div class="container">
    <div class="col-md-12">
        <div class="row" style="margin-right: 2px; padding-left: 20px;">
            <img src="../../../assets/img/detalhe.png" height="130px" width="100px" style="padding: 0%;" />

            <div class="col-md-10" style="padding-top: 15px;">
                <h4 class="espacamentotopo " style="margin-left: 5px;">
                    <b>Detalhe Cadastro Participante Congresso</b>
                </h4>
                <label>
                    Aqui você encontra o detalhe do cadastro realizado na plataforma para participação do Culto!
                    <br>
                    <b>#FicaaDica: </b>Em caso de Celular melhor visualizado na horizontal!
                </label>
            </div>
        </div>
    </div>
    <hr>
    <br>
    <label><b>Nome: </b>{{nome}}</label><br>
    <label><b>Data Cadastro: </b>{{data_cadastro}}</label><br>
    <label><b>Culto: </b>{{culto}}</label><br>    
    <label><b>Telefone: </b>{{telefone}}</label><br>
    <label><b>E-mail: </b>{{email}}</label><br><br>

    <br>
    <hr>

    <button pButton pRipple type="button" icon="pi pi-sign-in" title="Retornar" class="p-button-rounded p-button-danger"
        routerLink="/dashboard/gerenciamento/congresso-jovens/cadastros"></button>
    <br>
    <br>
    <br>
    <br>
</div>
<button class="voltar-ao-topo" id="back"><a class="ancora" style="color: white;"><i
            class="pi pi-arrow-circle-up"></i></a></button>