import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UsuarioModel } from 'src/app/Recuperacoes/recuperar-autenticacao/usuario-model';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { Parametros } from 'src/app/shared/parametros.enum';
import { SharedModule } from 'src/app/shared/shared.module';

@Component({
  selector: 'app-detalhe-membros-ativos',
  templateUrl: './detalhe-membros-ativos.component.html',
  styleUrls: ['./detalhe-membros-ativos.component.css']
})
export class DetalheMembrosAtivosComponent implements OnInit {

  idusuario: any;
  rota: any;
  private urlacessos = Parametros.CADASTROUSUARIO;


  nome: string = "";
  cpf: any = "";
  estadocivil: string = "";
  genero: string = "";
  dt_nascimento: any = "";
  dt_cadastro: any = "";
  dt_cancelamento: any = "";
  telefone: string = "";
  email: string = "";
  cargo: string = "";
  congregacao: string = "";
  dizimista: string = "";
  cep: string = "";
  logradouro: string = "";
  numero: string = "";
  apto: string = "";
  bairro: string = "";
  cidade: string = "";
  uf: string = "";
  ativo: string = "";
  anoreferencia: string = "";
  recebercomunicado: string = "";
  registro: string = "";

  usuario: Array<UsuarioModel>;
  motivocancelamento: string;

  constructor(private http: HttpClient,
    private alert: AlertModalService,
    private autenticado: SharedModule,
    private r: Router) {
    this.rota = r

  }

  startedClass = false;
  completedClass = false;
  preventAbuse = false;

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//

  ngOnInit(): void {

    if (this.autenticado.token == null) {
      this.autenticado.ResetDados();
      this.alert.AlertShowDanger('Usuário não autenticado, redirecionando...')
      this.rota.navigate(['/login']);
    } else {
      if (this.autenticado.ValidarToken() != true) {
        this.autenticado.ResetDados();
        this.alert.AlertShowDanger('Token Invalido!')
        this.rota.navigate(['/login']);
      }
    }

    this.idusuario = localStorage.getItem('UsuarioAtivoDetalhe');
    if (this.idusuario == null || this.idusuario == "") {
      this.rota.navigate(['/dashboard/gerenciamento/listagem-membros']);
    } else {
      //localStorage.removeItem(' ElogioDetalhe')
      this.GetUsuario();
    }
  }

  GetUsuario() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get<UsuarioModel>(this.urlacessos + 'GETLoadUsuario/?parametro=' + this.idusuario, httpOptions).subscribe(
      (success) => {

        this.dt_cadastro = success.dt_cadastro.replace("T00:00:00", "");
        this.cpf = success.cpf;
        this.nome = success.nome;
        this.estadocivil = success.estado_civil;
        this.genero = success.genero;
        this.dt_nascimento = success.dt_nascimento.replace("T00:00:00", "");
        this.telefone = success.contato_preferencial;
        this.email = success.email;
        this.cargo = success.cargo;
        this.congregacao = success.congregacao_nome;
        this.dizimista = success.dizimista;
        this.cep = success.cep;
        this.logradouro = success.logradouro;
        this.numero = success.numero;
        this.apto = success.apto;
        this.bairro = success.bairro;
        this.cidade = success.cidade;
        this.uf = success.uf;

        if (success.dt_cancelamento == null && success.ativo == "F") {
          this.ativo = "AGUARDANDO LIBERAÇÃO";

        } else if (success.dt_cancelamento != null && success.ativo == "F") {
          this.ativo = "CANCELADO";
          this.motivocancelamento = success.motivo_cancelamento;
          this.dt_cancelamento = (success.dt_cancelamento).replace("T00:00:00","");
        }
        else {
          this.ativo = success.ativo.replace("T", "ATIVO").replace("F", "INATIVO");
        }
        this.anoreferencia = success.ano_referencia;
        this.recebercomunicado = success.receber_comunicado.replace("T", "SIM").replace("F", "NÃO");
        this.registro = success.numero_registro;
      },
      (error) => {
        this.rota.navigate(['/dashboard/gerenciamento/listagem-membros']);
      }
    )
  }


}
