<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>
<div class="container">
    <div class="h">
        <h2 style="font-style: oblique;"><img src="../../../../assets/img/QRCode_morada.png"
                style="width: 100px; margin-right: 5px;">Patrimônio Morada de Deus</h2>
        <h5>Dados do Patrimônio</h5>
        <label><b>Identificação: </b>{{qrcode}}</label><br>
        <label><b>Patrimônio: </b>{{patrimonio}}</label><br>
        <label><b>Lotação: </b>{{setor}}</label><br>
        <label><b>Tipo: </b>{{tipo}}</label><br>
        <div *ngIf="status =='T'">
            <label><b>Status: </b> <label style="color: green; font: bold;"><b>ATIVO</b></label></label><br>
        </div>
        <div *ngIf="status =='F'">
            <label><b>Status: </b> <label style="color: rgb(128, 0, 0); font: bold;"><b>INATIVO</b></label></label><br>
        </div>
        <label>Em caso de problemas ou duvidas favor procurar diretamente o responsável pelo Setor de Lotação ou
            Responsável patrimonial da igreja Morada de Deus</label><br>
        <label>Att.</label><br>
        <label><b>Equipe Morada de Deus</b></label><br>
        <button pButton pRipple type="button" icon="pi pi-home" class="p-button-rounded p-button-help" title="Início"
            routerLink=""></button>
    </div>
</div>