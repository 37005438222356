<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>
<div class="border wrapper">
    <img src="./assets/img/novos/novos/Oficina_do_amor.png" style="width: 150px; height: 50px; margin-top: 0%; margin-left: 30px;" />
    <img src="/assets/img/2023/Arte - Casais Morada de Deus.png" style="width: 150px; height: 50px; margin-top: 0%; margin-left: 30px;" />
  
    <h4 class="espacamentotopo">
       
        <label style="margin-top: 10px;">  Cadastro
            Curso Para Casais</label>
    </h4>
    <label class="j">
        Seja bem-vindo(a)!<br />
        &nbsp;&nbsp;&nbsp;&nbsp; Você está na área de cadastro para curso para casais de nossa igreja, Informamos que
        todos os dados aqui informados são tratados somente por nossa equipe de
        gestão não sendo compartilhados com terceiros e sendo de uso para controle e contato por nossa equipe
        organizadora!
    </label>
    <label class="j">
        Lembramos que os campos com (<strong class="cor"> *</strong> ) são campos de
        preenchimento obrigatório!
    </label>
    <hr class="espacamentotopo bold" />
    <form [formGroup]="formcadastro" disabled="">
        <div class="row">

            <div class="col-md-2">
                <div class="form-group">
                    <label>CPF (Esposa):</label><strong class="cor">*</strong>
                    <input type="text" class="form-control" id="cpf_esposa" placeholder="informe o CPF Esposa"
                        mask="000.000.000-00" name="cpf_esposa" maxlength="14" minlength="11" autocomplete="off"
                        formControlName="cpf_esposa" (blur)="consultacpf(cadastrocasais.cpf_esposa)"
                        [(ngModel)]="cadastrocasais.cpf_esposa"
                        [ngClass]="{ 'is-invalid': submitted && f.cpf_esposa.errors }" />
                    <div *ngIf="submitted && f.cpf_esposa.errors" class="invalid-feedback">
                        <div *ngIf="f.cpf_esposa.errors.required">Preenchimento obrigatório</div>
                    </div>
                </div>
            </div>


            <div class="col-md-5">
                <div class="form-group">
                    <label>Nome Completo (Esposa):</label><strong class="cor">*</strong>
                    <input type="text" class="form-control" id="nome_esposa" name="nome_esposa"
                        [(ngModel)]="cadastrocasais.nome_esposa" placeholder="Informe Nome Completo Esposa"
                        formControlName="nome_esposa" maxlength="500" minlength="3"
                        [(ngModel)]="cadastrocasais.nome_esposa" autocomplete="off"
                        [ngClass]="{ 'is-invalid': submitted && f.nome_esposa.errors }" />
                    <div *ngIf="submitted && f.nome_esposa.errors" class="invalid-feedback">
                        <div *ngIf="f.nome_esposa.errors.required">Preenchimento obrigatório</div>
                    </div>
                </div>
            </div>

            <div class="col-md-3">
                <label>Telefone/Celular (Esposa):</label><strong class="cor">*</strong>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1"><i class="fas fa-phone"></i></span>
                    </div>
                    <input type="text" class="form-control" id="telefone_esposa" name="telefone_esposa"
                        [(ngModel)]="cadastrocasais.telefone_esposa" placeholder="Informe o Telefone Esposa"
                        title="Formato (DD) 0000-0000 ou (DD) 00000-0000" formControlName="telefone_esposa"
                        maxlength="500" minlength="3" autocomplete="off" mask="(00) 0000-0000 || (00) 00000-0000"
                        [ngClass]="{
                  'is-invalid': submitted && f.telefone_esposa.errors
                }" />
                    <div *ngIf="submitted && f.telefone_esposa.errors" class="invalid-feedback">
                        <div *ngIf="f.telefone_esposa.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-2">
                <div class="form-group">
                    <label>Data Nasc. (Esposa):</label><strong class="cor">*</strong>
                    <input type="date" class="form-control" id="dt_nascimento_esposa"
                        placeholder="Data Nascimento Esposa" name="dt_nascimento_esposa" maxlength="10" minlength="10"
                        autocomplete="off" formControlName="dt_nascimento_esposa"
                        [(ngModel)]="cadastrocasais.dt_nascimento_esposa"
                        [ngClass]="{ 'is-invalid': submitted && f.dt_nascimento_esposa.errors }" />
                    <div *ngIf="submitted && f.dt_nascimento_esposa.errors" class="invalid-feedback">
                        <div *ngIf="f.dt_nascimento_esposa.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="row">

            <div class="col-md-4">
                <div class="form-group">
                    <label>Profisão (Esposa):</label>
                    <input type="text" class="form-control" id="profissao_esposa" name="profissao_esposa"
                        [(ngModel)]="cadastrocasais.profissao_esposa" placeholder="Informe a Profissão da Esposa"
                        formControlName="profissao_esposa" maxlength="500" 
                        [(ngModel)]="cadastrocasais.profissao_esposa" autocomplete="off"
                        [ngClass]="{ 'is-invalid': submitted && f.profissao_esposa.errors }" />
                </div>
            </div>

            <div class="col-md-4">
                <label>E-mail (Esposa):</label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1"><i class="fas fa-at"></i></span>
                    </div>
                    <input type="email" class="form-control" id="email_esposa" placeholder="Informe E-mail Esposa"
                        name="email_esposa" formControlName="email_esposa" [(ngModel)]="cadastrocasais.email_esposa"
                        maxlength="500" minlength="3" autocomplete="off" />
                </div>
            </div>

            <div class="col-md-4">
                <div class="dropdown">
                    <label>Fé Professa (Esposa):</label><strong class="cor">*</strong>
                    <div class="mb-3">
                        <select class="custom-select" type="text" name="religiao_esposa" id="religiao_esposa"
                            [(ngModel)]="cadastrocasais.religiao_esposa" formControlName="religiao_esposa"
                            [ngClass]="{ 'is-invalid': submitted && f.religiao_esposa.errors }">
                            <div *ngIf="submitted && f.religiao_esposa.errors" class="invalid-feedback"></div>
                            <option value=""></option>
                            <option *ngFor="let d of religiao" [ngValue]="d">
                                {{ d }}
                            </option>
                        </select>
                        <div *ngIf="submitted && f.religiao_esposa.errors" class="invalid-feedback">
                            <div *ngIf="f.religiao_esposa.errors.required">
                                Preenchimento obrigatório
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
        <!---FIM ESPOSA LINHA-->

        <div class="row">

            <div class="col-md-2">
                <div class="form-group">
                    <label>CPF (Esposo):</label><strong class="cor">*</strong>
                    <input type="text" class="form-control" id="cpf_esposo" placeholder="informe o CPF Esposo"
                        mask="000.000.000-00" name="cpf_esposo" maxlength="14" minlength="11" autocomplete="off"
                        formControlName="cpf_esposo" (blur)="consultacpf(cadastrocasais.cpf_esposo)"
                        [(ngModel)]="cadastrocasais.cpf_esposo"
                        [ngClass]="{ 'is-invalid': submitted && f.cpf_esposo.errors }" />
                    <div *ngIf="submitted && f.cpf_esposo.errors" class="invalid-feedback">
                        <div *ngIf="f.cpf_esposo.errors.required">Preenchimento obrigatório</div>
                    </div>
                </div>
            </div>


            <div class="col-md-5">
                <div class="form-group">
                    <label>Nome Completo (Esposo):</label><strong class="cor">*</strong>
                    <input type="text" class="form-control" id="nome_esposo" name="nome_esposo"
                        [(ngModel)]="cadastrocasais.nome_esposo" placeholder="Informe Nome Completo Esposo"
                        formControlName="nome_esposo" maxlength="500" minlength="3"
                        [(ngModel)]="cadastrocasais.nome_esposo" autocomplete="off"
                        [ngClass]="{ 'is-invalid': submitted && f.nome_esposo.errors }" />
                    <div *ngIf="submitted && f.nome_esposo.errors" class="invalid-feedback">
                        <div *ngIf="f.nome_esposo.errors.required">Preenchimento obrigatório</div>
                    </div>
                </div>
            </div>

            <div class="col-md-3">
                <label>Telefone/Celular (Esposo):</label><strong class="cor">*</strong>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1"><i class="fas fa-phone"></i></span>
                    </div>
                    <input type="text" class="form-control" id="telefone_esposo" name="telefone_esposo"
                        [(ngModel)]="cadastrocasais.telefone_esposo" placeholder="Informe o Telefone Esposo"
                        title="Formato (DD) 0000-0000 ou (DD) 00000-0000" formControlName="telefone_esposo"
                        maxlength="500" minlength="3" autocomplete="off" mask="(00) 0000-0000 || (00) 00000-0000"
                        [ngClass]="{
          'is-invalid': submitted && f.telefone_esposo.errors
        }" />
                    <div *ngIf="submitted && f.telefone_esposo.errors" class="invalid-feedback">
                        <div *ngIf="f.telefone_esposo.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-2">
                <div class="form-group">
                    <label>Data Nasc. (Esposo):</label><strong class="cor">*</strong>
                    <input type="date" class="form-control" id="dt_nascimento_esposo"
                        placeholder="Data Nascimento Esposo" name="dt_nascimento_esposo" maxlength="10" minlength="10"
                        autocomplete="off" formControlName="dt_nascimento_esposo"
                        [(ngModel)]="cadastrocasais.dt_nascimento_esposo"
                        [ngClass]="{ 'is-invalid': submitted && f.dt_nascimento_esposo.errors }" />
                    <div *ngIf="submitted && f.dt_nascimento_esposo.errors" class="invalid-feedback">
                        <div *ngIf="f.dt_nascimento_esposo.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="row">

            <div class="col-md-4">
                <div class="form-group">
                    <label>Profisão (Esposo):</label>
                    <input type="text" class="form-control" id="profissao_esposo" name="profissao_esposo"
                        [(ngModel)]="cadastrocasais.profissao_esposo" placeholder="Informe a Profissão da Esposa"
                        formControlName="profissao_esposo" maxlength="500" 
                        [(ngModel)]="cadastrocasais.profissao_esposo" autocomplete="off"
                        [ngClass]="{ 'is-invalid': submitted && f.profissao_esposo.errors }" />
                </div>
            </div>

            <div class="col-md-4">
                <label>E-mail (Esposo):</label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1"><i class="fas fa-at"></i></span>
                    </div>
                    <input type="email" class="form-control" id="email_esposo" placeholder="Informe E-mail Esposo"
                        name="email_esposo" formControlName="email_esposo" [(ngModel)]="cadastrocasais.email_esposo"
                        maxlength="500" minlength="3" autocomplete="off" />
                </div>
            </div>

            <div class="col-md-4">
                <div class="dropdown">
                    <label>Fé Professa (Esposo):</label><strong class="cor">*</strong>
                    <div class="mb-3">
                        <select class="custom-select" type="text" name="religiao_esposo" id="religiao_esposo"
                            [(ngModel)]="cadastrocasais.religiao_esposo" formControlName="religiao_esposo"
                            [ngClass]="{ 'is-invalid': submitted && f.religiao_esposo.errors }">
                            <div *ngIf="submitted && f.religiao_esposo.errors" class="invalid-feedback"></div>
                            <option value=""></option>
                            <option *ngFor="let d of religiao" [ngValue]="d">
                                {{ d }}
                            </option>
                        </select>
                        <div *ngIf="submitted && f.religiao_esposo.errors" class="invalid-feedback">
                            <div *ngIf="f.religiao_esposo.errors.required">
                                Preenchimento obrigatório
                            </div>
                        </div>
                    </div>
                </div>
            </div>


        </div>
        <!---FIM ESPOSO LINHA-->

        <hr>

        <div class="row">
            <div class="col-md-3">
                <div class="dropdown">
                    <label>União:</label><strong class="cor">*</strong>
                    <div class="mb-3">
                        <select class="custom-select" type="text" name="tipo_uniao" id="tipo_uniao"
                            [(ngModel)]="cadastrocasais.tipo_uniao" formControlName="tipo_uniao"
                            [ngClass]="{ 'is-invalid': submitted && f.tipo_uniao.errors }">
                            <div *ngIf="submitted && f.tipo_uniao.errors" class="invalid-feedback"></div>
                            <option value=""></option>
                            <option *ngFor="let d of uniao" [ngValue]="d">
                                {{ d }}
                            </option>
                        </select>
                        <div *ngIf="submitted && f.tipo_uniao.errors" class="invalid-feedback">
                            <div *ngIf="f.tipo_uniao.errors.required">
                                Preenchimento obrigatório
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-3">
                <div class="dropdown">
                    <label>Tempo União:</label><strong class="cor">*</strong>
                    <div class="mb-3">
                        <select class="custom-select" type="text" name="tempo_casamento" id="tempo_casamento"
                            [(ngModel)]="cadastrocasais.tempo_casamento" formControlName="tempo_casamento"
                            [ngClass]="{ 'is-invalid': submitted && f.tempo_casamento.errors }">
                            <div *ngIf="submitted && f.tempo_casamento.errors" class="invalid-feedback"></div>
                            <option value=""></option>
                            <option *ngFor="let d of tempo" [ngValue]="d">
                                {{ d }}
                            </option>
                        </select>
                        <div *ngIf="submitted && f.tempo_casamento.errors" class="invalid-feedback">
                            <div *ngIf="f.tempo_casamento.errors.required">
                                Preenchimento obrigatório
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-2">
                <div class="dropdown">
                    <label>Filhos:</label><strong class="cor">*</strong>
                    <div class="mb-3">
                        <select class="custom-select" type="text" name="filhos" id="filhos"
                            [(ngModel)]="cadastrocasais.filhos" formControlName="filhos"
                            (change)="changemostrarfilhos($event)"
                            [ngClass]="{ 'is-invalid': submitted && f.tipo_uniao.errors }">
                            <div *ngIf="submitted && f.filhos.errors" class="invalid-feedback"></div>
                            <option value=""></option>
                            <option *ngFor="let d of filhos" [ngValue]="d">
                                {{ d }}
                            </option>
                        </select>
                        <div *ngIf="submitted && f.filhos.errors" class="invalid-feedback">
                            <div *ngIf="f.filhos.errors.required">
                                Preenchimento obrigatório
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-2">
                <div class="dropdown">
                    <label>Quantidade Filhos:</label><strong class="cor">*</strong>
                    <div class="mb-3">
                        <select class="custom-select" disabled type="text" name="quantidade_filhos"
                            id="quantidade_filhos" [(ngModel)]="cadastrocasais.quantidade_filhos"
                            formControlName="quantidade_filhos"
                            [ngClass]="{ 'is-invalid': submitted && f.quantidade_filhos.errors }">
                            <div *ngIf="submitted && f.quantidade_filhos.errors" class="invalid-feedback"></div>
                            <option value=""></option>
                            <option *ngFor="let d of quantidade" [ngValue]="d">
                                {{ d }}
                            </option>
                        </select>
                        <div *ngIf="submitted && f.quantidade_filhos.errors" class="invalid-feedback">
                            <div *ngIf="f.quantidade_filhos.errors.required">
                                Preenchimento obrigatório
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-2">
                <div class="dropdown">
                    <label>Quantidade Enteados:</label><strong class="cor">*</strong>
                    <div class="mb-3">
                        <select class="custom-select" disabled type="text" name="quantidade_enteados"
                            id="quantidade_enteados" [(ngModel)]="cadastrocasais.quantidade_enteados"
                            formControlName="quantidade_enteados"
                            [ngClass]="{ 'is-invalid': submitted && f.quantidade_enteados.errors }">
                            <div *ngIf="submitted && f.quantidade_enteados.errors" class="invalid-feedback"></div>
                            <option value=""></option>
                            <option *ngFor="let d of quantidade" [ngValue]="d">
                                {{ d }}
                            </option>
                        </select>
                        <div *ngIf="submitted && f.quantidade_enteados.errors" class="invalid-feedback">
                            <div *ngIf="f.quantidade_enteados.errors.required">
                                Preenchimento obrigatório
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="row">
            <div class="col-md-12">
                <label>Observações Filhos:</label><strong style="font-size: x-small;" class="cor"> (Preencher se
                    necessário )</strong>
                <div class="input-group mb-3">
                    <input type="observacoes_filhos" class="form-control" id="observacoes_filhos" placeholder=""
                        name="observacoes_filhos" formControlName="observacoes_filhos"
                        [(ngModel)]="cadastrocasais.observacoes_filhos" maxlength="500" minlength="3"
                        autocomplete="off" />
                </div>

            </div>
        </div>
        <hr>
        <div class="row">
            <div class="col-md-2">
                <div class="dropdown">
                    <label>Frequenta congregação:</label><strong class="cor">*</strong>
                    <div class="mb-4">
                        <select class="custom-select" type="text" name="pertence_alguma_igreja"
                            id="pertence_alguma_igreja" [(ngModel)]="cadastrocasais.pertence_alguma_igreja"
                            formControlName="pertence_alguma_igreja" (change)="changemostrarcongregacao($event)"
                            [ngClass]="{ 'is-invalid': submitted && f.pertence_alguma_igreja.errors }">
                            <div *ngIf="submitted && f.pertence_alguma_igreja.errors" class="invalid-feedback"></div>
                            <option value=""></option>
                            <option *ngFor="let d of filhos" [ngValue]="d">
                                {{ d }}
                            </option>
                        </select>
                        <div *ngIf="submitted && f.pertence_alguma_igreja.errors" class="invalid-feedback">
                            <div *ngIf="f.pertence_alguma_igreja.errors.required">
                                Preenchimento obrigatório
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <label>Nome Congregação:</label>
                <div class="input-group mb-3">
                    <input type="igreja_pertence" disabled class="form-control" id="igreja_pertence" placeholder=""
                        name="igreja_pertence" formControlName="igreja_pertence"
                        [(ngModel)]="cadastrocasais.igreja_pertence" maxlength="500" minlength="3" autocomplete="off" />
                </div>

            </div>

            <div class="col-md-4">
                <label>Responsável Congregação:</label>
                <div class="input-group mb-3">
                    <input type="responsavel_igreja" disabled class="form-control" id="responsavel_igreja"
                        placeholder="" name="responsavel_igreja" formControlName="responsavel_igreja"
                        [(ngModel)]="cadastrocasais.responsavel_igreja" maxlength="500" minlength="3"
                        autocomplete="off" />
                </div>

            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <label>Observações Congregação:</label><strong style="font-size: x-small;" class="cor"> (Preencher se
                    necessário )</strong>
                <div class="input-group mb-3">
                    <input type="observacoes_igreja" disabled class="form-control" id="observacoes_igreja"
                        placeholder="" name="observacoes_igreja" formControlName="observacoes_igreja"
                        [(ngModel)]="cadastrocasais.observacoes_igreja" maxlength="500" minlength="3"
                        autocomplete="off" />
                </div>

            </div>
        </div>
        <hr>

        <div class="row">
            <div class="col-md-2">
                <div class="form-group">
                    <label>Cep:</label>
                    <input type="text" class="form-control" placeholder="Informe o CEP" id="cep" mask="00.000-000"
                        name="cep" formControlName="cep" (blur)="consulta_cep(cadastrocasais.cep)"
                        [(ngModel)]="cadastrocasais.cep" maxlength="500" minlength="3" />
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>Logradouro:</label><strong class="cor">*</strong>
                    <input type="text" class="form-control" id="logradouro" placeholder="Informe Logradouro"
                        name="logradouro" formControlName="logradouro" [(ngModel)]="cadastrocasais.logradouro"
                        maxlength="500" minlength="3" [ngClass]="{ 'is-invalid': submitted && f.logradouro.errors }" />
                    <div *ngIf="submitted && f.logradouro.errors" class="invalid-feedback">
                        <div *ngIf="f.logradouro.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group">
                    <label>Numero:</label><strong class="cor">*</strong>
                    <input type="number" class="form-control" id="numero" placeholder="Numero" min="1" name="numero"
                        formControlName="numero" [(ngModel)]="cadastrocasais.numero" maxlength="9" minlength="3"
                        [ngClass]="{ 'is-invalid': submitted && f.numero.errors }" />
                    <div *ngIf="submitted && f.numero.errors" class="invalid-feedback">
                        <div *ngIf="f.numero.errors.required">
                            Preenchimento obrigatório
                        </div>
                        <div *ngIf="f.numero.errors.required">Menor valor 0</div>
                    </div>
                </div>
            </div>

            <div class="col-md-2">
                <div class="form-group">
                    <label>apto:</label>
                    <input type="number" class="form-control" id="apto" placeholder="apto" min="1" name="apto"
                        formControlName="apto" [(ngModel)]="cadastrocasais.apto" maxlength="9" minlength="3" />
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-5">
                <div class="form-group">
                    <label>Bairro:</label><strong class="cor">*</strong>
                    <input type="text" class="form-control" id="bairro" placeholder="Informe o Bairro" name="bairro"
                        formControlName="bairro" [(ngModel)]="cadastrocasais.bairro" maxlength="500" minlength="3"
                        [ngClass]="{ 'is-invalid': submitted && f.bairro.errors }" />
                    <div *ngIf="submitted && f.bairro.errors" class="invalid-feedback">
                        <div *ngIf="f.bairro.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="dropdown">
                    <label>UF:</label><strong class="cor">*</strong>
                    <div class="mb-3">
                        <select class="custom-select" type="text" (change)="changeCity($event)" name="uf" id="uf"
                            [(ngModel)]="cadastrocasais.uf" formControlName="uf"
                            [ngClass]="{ 'is-invalid': submitted && f.uf.errors }">
                            <div *ngIf="submitted && f.uf.errors" class="invalid-feedback"></div>
                            <option value=""></option>
                            <option *ngFor="let city of luf" [ngValue]="city">
                                {{ city }}
                            </option>
                        </select>
                        <div *ngIf="submitted && f.uf.errors" class="invalid-feedback">
                            <div *ngIf="f.uf.errors.required">Preenchimento obrigatório</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-5">
                <div class="form-group">
                    <label>Cidade:</label><strong class="cor">*</strong>
                    <input type="text" class="form-control" id="cidade" placeholder="Informe sua cidade" name="cidade"
                        formControlName="cidade" [(ngModel)]="cadastrocasais.cidade"
                        [ngClass]="{ 'is-invalid': submitted && f.cidade.errors }" maxlength="500" minlength="3" />
                    <div *ngIf="submitted && f.cidade.errors" class="invalid-feedback">
                        <div *ngIf="f.cidade.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <label>Anotação Casal:</label><strong style="font-size: x-small;" class="cor"> (Preencher se
                    necessário)</strong>
                <div class="input-group mb-3">
                    <input type="text" class="form-control" id="observacoes_cadastro" placeholder=""
                        name="observacoes_cadastro" formControlName="observacoes_cadastro"
                        [(ngModel)]="cadastrocasais.observacoes_cadastro" maxlength="500" minlength="3"
                        autocomplete="off" />
                </div>

            </div>
        </div>
        <div class="row">
           


            <div class="col-md-4">
                <div class="dropdown">
                    <label>Disponabilidade:</label><strong class="cor">*</strong><br>
                    <label>Lembramos que as ministrações são na parte da noite:</label>

                    <div class="mb-3">
                        <select class="custom-select" type="text" name="disponabilidade" id="disponabilidade"
                            [(ngModel)]="cadastrocasais.disponabilidade" formControlName="disponabilidade"
                            [ngClass]="{ 'is-invalid': submitted && f.disponabilidade.errors }">
                            <div *ngIf="submitted && f.disponabilidade.errors" class="invalid-feedback"></div>
                            <option value=""></option>
                            <option *ngFor="let di of dias" [ngValue]="di">
                                {{ di }}
                            </option>
                        </select>
                        <div *ngIf="submitted && f.disponabilidade.errors" class="invalid-feedback">
                            <div *ngIf="f.disponabilidade.errors.required">
                                Preenchimento obrigatório
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-8">
                <label>Nos conte se Participou do encontro de casais ou curso de casais da IEPMD?:</label><strong class="cor">*</strong><br>
                <label>se a Resposta for ( SIM ), nós informe Quando?,Qual foi seu casal cuidador?), caso contrário escreva NÃO:</label>
                <div class="input-group mb-3">
                    <textarea type="text" rows="2" class="form-control" id="informacao_cadastro" placeholder=""
                        name="informacao_cadastro" formControlName="informacao_cadastro" [ngClass]="{ 'is-invalid': submitted && f.informacao_cadastro.errors }"
                        [(ngModel)]="cadastrocasais.informacao_cadastro" maxlength="500" minlength="3"
                        autocomplete="off" ></textarea>
                        <div *ngIf="submitted && f.informacao_cadastro.errors" class="invalid-feedback">
                            <div *ngIf="f.informacao_cadastro.errors.required">
                                Preenchimento obrigatório
                            </div>
                        </div>
                </div>

            </div>
            
        </div>
        <hr>















        <div class="row" style="padding: 0%;">
            <div class="col-md" style="padding-top: 0%;">
                <div>
                    <input type="checkbox" id="marked" data-md-icheck (change)="AceiteTermo($event)"
                        formControlName="marked" [(ngModel)]="marked" />
                    <b>&nbsp;Aceito os Termos</b>.<strong class="cor">*</strong>
                    <div *ngIf="submitted && f.marked.errors">
                        <div *ngIf="f.marked.errors.required" class="cor bold">
                            O aceite dos termos e obrigatório
                        </div>
                    </div>
                </div>
            </div>

            
        </div>

        <br>






        <!-- Modal -->
        <button id="openModalButton" [hidden]="true" data-toggle="modal" data-target="#myModal"></button>
        <div class="modal fade bd-example-modal-lg" data-backdrop="static" data-keyboard="false" id="myModal"
            tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                            <i class="fas fa-info-circle" style="color: blue"></i>
                            {{ modaltitulo }}
                        </h5>
                    </div>
                    <div class="modal-body">
                        <div [innerHTML]="modalconteudo"></div>
                    </div>
                    <div class="modal-footer">
                        <div *ngIf="bt_termo == false">
                            <button pButton pRipple type="button" icon="pi pi-times" title="Fechar"
                                class="p-button-rounded p-button-danger" data-dismiss="modal">
                            </button>

                        </div>
                        <div *ngIf="bt_termo == true">
                            <button pButton pRipple type="button" icon="pi pi-thumbs-up" title="CONCORDO"
                                class="p-button-rounded" data-dismiss="modal">
                            </button>
                            <button pButton pRipple type="button" icon="pi pi-thumbs-down"
                                class="p-button-rounded p-button-danger" title="DISCORDO" (click)="DiscordeTermo()"
                                data-dismiss="modal">
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <button id="openModalButtons" [hidden]="true" data-toggle="modal" data-target="#myModals"></button>
        <div class="modal fade bd-example-modal-lg" data-backdrop="static" data-keyboard="false" id="myModals"
            tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                            <i class="fas fa-info-circle" style="color: blue"></i>
                            {{ modaltitulo }}
                        </h5>
                    </div>
                    <div class="modal-body">
                        <div [innerHTML]="modalconteudo"></div>
                    </div>
                    <div class="modal-footer">
                        <div>
                            <button pButton pRipple type="button" icon="pi pi-times"
                                class="p-button-rounded p-button-danger" title=":: FECHAR ::" data-dismiss="modal"
                                routerLink="">
                            </button>
                        </div>
                    </div>
                </div>
            </div>

        </div>


        <div>
            <button pButton pRipple type="button" icon="pi pi-save" class="p-button-rounded" style="margin-right: 5px;"
                title=":: SALVAR ::" (click)="onSubmit()"></button>
            <button pButton pRipple type="button" icon="pi pi-refresh" style="margin-right: 5px;"
                class="p-button-rounded p-button-secondary" title="::LIMPAR::" (click)="reset()"></button>
            <button pButton pRipple type="button" icon="pi pi-home" style="margin-right: 5px;"
                class="p-button-rounded p-button-help" title="::HOME::" routerLink=""></button>

                <a href="https://api.whatsapp.com/send?l=pt_BR&phone=55032988189440&text=Problemas Cadastro Curso Casaias"
                style="text-align: right; float: right;" title="Fale Conosco" target="_blank">Informar Problema!</a>

            <br>
            <br>
        </div>



    </form>
</div>