export class ModelCasais {
    [x: string]: any;

    tempo_casamento: string = '';
    tipo_uniao: string = '';
    cpf_esposa: string = '';
    nome_esposa: string = '';
    dt_nascimento_esposa: string = '';
    dt_cadastro: string = '';
    telefone_esposa: string = '';
    profissao_esposa: string = '';
    email_esposa: string = '';
    religiao_esposa: string = '';
    cpf_esposo: string = '';
    nome_esposo: string = '';
    dt_nascimento_esposo: string = '';
    telefone_esposo: string = '';
    profissao_esposo: string = '';
    email_esposo: string = '';
    religiao_esposo: string = '';
    observacoes_cadastro: string = '';
    cep: string = '';
    logradouro: string = '';
    numero: string = '';
    apto: string = '';
    bairro: string = '';
    cidade: string = '';
    uf: string = '';
    filhos: string = '';
    disponabilidade: string = '';
    quantidade_filhos: string = '';
    quantidade_enteados: string = '';
    observacoes_filhos: string = '';
    pertence_alguma_igreja: string = '';
    igreja_pertence: string = '';
    responsavel_igreja: string = '';
    observacoes_igreja: string = '';
    informacao_cadastro: string = '';
    confirmado: string = '';
    motivo: string = '';
    registro: string = '';
    ativo: string = '';


}