import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { from } from 'rxjs';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { Parametros } from 'src/app/shared/parametros.enum';
import { SharedModule } from 'src/app/shared/shared.module';
import { OportunidadeModel } from '../cadastro-oportunidade/OportunidadeModel';
import { ListagemOportunidadeService } from './listagem-oportunidade.service';

@Component({
  selector: 'app-listagem-oportunidade',
  templateUrl: './listagem-oportunidade.component.html',
  styleUrls: ['./listagem-oportunidade.component.css']
})
export class ListagemOportunidadeComponent implements OnInit {

  private urlestabelecimento = Parametros.CADASTROOPORTUNIDADE;

  x = 1;
  y = 5;
  filtro_cabecalho: string = ""
  explicacao: string = "";
  textoconsulta: string = "";
  rota: any;
  perfil: any;
  pagina_atual = 1;
  imprimir: string = "Filtro:Todos";
  blob: Blob;
  idoportunidade: any;
  nome: string;


  constructor(private http: HttpClient,
    private primengConfig: PrimeNGConfig,
    private alert: AlertModalService,
    private autenticado: SharedModule,
    private service: ListagemOportunidadeService,
    r: Router
  ) {
    this.rota = r;
    this.perfil = this.autenticado.perfil;
    this.nome = this.autenticado.nome;
  }

  startedClass = false;
  completedClass = false;
  preventAbuse = false;

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//

  oportunidade: Array<OportunidadeModel>;
  qto: number;
  cols: any[];


  GetLista() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get<OportunidadeModel[]>(this.urlestabelecimento + 'GETListaOportunidadeCpf/?parametro=' + this.autenticado.cpf, httpOptions).subscribe(
      dados => this.oportunidade = dados, dados => this.qto = dados.length
    )
  }




  ngOnInit() {
    if (this.autenticado.token == null) {
      this.autenticado.ResetDados();
      this.alert.AlertShowDanger('Usuário não autenticado, redirecionando...')
      this.rota.navigate(['/login']);
    } else {
      if (this.autenticado.ValidarToken() != true) {
        this.autenticado.ResetDados();
        this.alert.AlertShowDanger('Token Invalido!')
        this.rota.navigate(['/login']);
      }
    }
    this.primengConfig.ripple = true;
    this.filtro_cabecalho = "Listagem de Oportunidades"
    this.explicacao = "Aqui você está visualizando todas as Oportunidades registrados por você a Morada de Deus."

    this.GetLista();

    this.cols = [
      { field: 'Data Cadastro', header: 'Data Cadastro' },
      { field: 'Oportunidade', header: 'Oportunidade' },
      { field: 'telefone', header: 'Contato' },
      { field: 'Ativa', header: 'Ativa' },
      { field: 'Bloqueada', header: 'Bloqueada' },
      { field: 'Excluir', header: 'Excluir' },
      { field: 'Editar', header: 'Editar' }

    ];
  }



  VisualizarDetalhe(event) {
    localStorage.removeItem('OportunidadeoDetalhe');
    localStorage.setItem('OportunidadeDetalhe', event.currentTarget.value);
    this.rota.navigate(['/dashboard/editar-oportunidade']);
  }


  //modal
  ValorImpressao(event) {
    this.imprimir = event.currentTarget.value;
  }



  SetDados(event) {
    this.idoportunidade = event.currentTarget.value;
  }

  BotaoExcluir() {
    this.ExcluirConsulta();
  }


  ExcluirConsulta() {
    this.service.CancelarOportunidade(this.idoportunidade, this.autenticado.token).subscribe(
      (success) => {
        this.StopProgressBar();
        this.alert.AlertShowInfo('Oportunidade Excluida com sucesso!');
        this.GetLista();
      },
      (error) => {
        this.StopProgressBar();
        this.alert.AlertShowDanger('Erro ao cancelar Oportunidade!');
        this.GetLista();
      }
    )
  }
}
