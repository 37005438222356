import { Injectable } from '@angular/core';
import { Parametros } from '../shared/parametros.enum';
import { Router } from '@angular/router';
import { AlertModalService } from './../shared/alert-modal/alert-modal.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SharedModule } from './../shared/shared.module';
import { retry } from 'rxjs/operators';


@Injectable({
    providedIn: 'root',
  })
  export class ReportarProblemaService {
    private urlcontato = Parametros.PROBLEMA;
    private rota: Router;


  constructor(private http: HttpClient,
    router: Router,
    private alert: AlertModalService,
    private Is: SharedModule) {
    this.rota = router;
  }
  RegistrarProblema(_problema) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http
      .post(this.urlcontato + 'postproblema', _problema, httpOptions)
      .pipe(retry(1));
  }

}
