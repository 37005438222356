import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { Parametros } from 'src/app/shared/parametros.enum';
import { SharedModule } from 'src/app/shared/shared.module';
import { Visitante } from 'src/app/visitante/visitante-model';
import { VisitantesService } from './visitantes.service';

@Component({
  selector: 'app-listagem-visitantes',
  templateUrl: './listagem-visitantes.component.html',
  styleUrls: ['./listagem-visitantes.component.css']
})
export class ListagemVisitantesComponent implements OnInit {


  private urlgestao = Parametros.VISITANTE;

  x = 1;
  y = 10;
  filtro_cabecalho: string = ""
  explicacao: string = "";
  textoconsulta: string = "";
  rota: any;
  perfil: any;
  pagina_atual = 1;
  imprimir: string = "Todos";
  idusuario: any;
  nome: string;


  constructor(private http: HttpClient,
    private primengConfig: PrimeNGConfig,
    private alert: AlertModalService,
    private autenticado: SharedModule,
    private service: VisitantesService,
    r: Router
  ) {
    this.rota = r;
    this.perfil = this.autenticado.perfil;
    this.nome = this.autenticado.nome;
  }

  startedClass = false;
  completedClass = false;
  preventAbuse = false;

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//

  visitantes: Array<Visitante>;
  qto: number;
  cols: any[];


  GetLista() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get<Visitante[]>(this.urlgestao + 'GETListaTodosVisitantes', httpOptions).subscribe(
      (success) => {
        this.visitantes = success;
       
      }
    )
  }

  GetListaTotal() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get<any>(this.urlgestao + 'GetTotalVisitantes', httpOptions).subscribe(
      dados => this.qto = dados
    )
  }

  GetListaTotalQuantidadeDez(pagina: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get<any>(this.urlgestao + 'GetListaConsultaLimite/?parametro=' + pagina, httpOptions).subscribe(
      (success) => {
        this.visitantes = success;
     
      }
    )
  }

  ngOnInit() {
    if (this.autenticado.token == null) {
      this.autenticado.ResetDados();
      this.alert.AlertShowDanger('Usuário não autenticado, redirecionando...')
      this.rota.navigate(['/login']);
    } else {
      if (this.autenticado.ValidarToken() != true) {
        this.autenticado.ResetDados();
        this.alert.AlertShowDanger('Token Invalido!')
        this.rota.navigate(['/login']);
      }
    }
    this.primengConfig.ripple = true;
    this.filtro_cabecalho = "Listagem de visitantes Cadastrados "
    this.explicacao = "Aqui você está visualizando todos os usuarios cadastrados na plataforma."
    this.GetListaTotal();
    this.GetLista();
  }

  Paginate(event) {
    this.x = event.first + 1;
    this.pagina_atual = event.first;

    this.GetListaTotalQuantidadeDez(event.first.toString());
    if (event.first + 10 > this.qto) {
      this.y = this.qto;
    } else {
      this.y = event.first + 10;
    }
  }

  Consulta() {
    var texto = (<HTMLInputElement>document.getElementById("texto_consulta")).value;
    if (texto != "") {
      this.textoconsulta = texto;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
        })
      }
      this.http.get<Visitante[]>(this.urlgestao + 'GetListaConsultaVisitante/?parametro=' + texto, httpOptions).subscribe(
        (success) => {
          this.visitantes = success;
         
        }

      )
    } else {
      this.textoconsulta = "";
      this.GetLista();
    }
  }



  VisualizarDetalhe(event) {
    localStorage.removeItem('VisitanteDetalhe');
    localStorage.setItem('VisitanteDetalhe', event.currentTarget.value);
    this.rota.navigate(['/dashboard/gerenciamento/visitantes/detalhamento/visitantes-detalhes']);
  }


  //modal
  Operacao() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get(this.urlgestao + 'POSTConfirmarContato/?parametro='+ this.idusuario, httpOptions).subscribe(
      () => {
        this.alert.AlertShowSucess('Contato com visitante confirmado com sucesso!')
        this.GetLista();
        this.GetListaTotal();
      },
      (error) => {
        this.alert.AlertShowDanger('Erro ao confirmar contato, Tente novamente!')
        this.GetLista();
        this.GetListaTotal();
      }

    )
  }


  SetDados(event) {
    this.idusuario = event.currentTarget.value;
  }


}
