import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AlertModalComponent } from './alert-modal/alert-modal.component';
import { EventEmitterService } from './EventEmitterService';

import { data } from 'jquery';



@NgModule({
    declarations: [AlertModalComponent],
    imports: [
        CommonModule
    ],
    exports: [
        AlertModalComponent
    ]
})

//Variaveis Globais da aplicação usadas para Login
export class SharedModule {
  public autenticado: boolean = false;
  public token = '';
  public nome = '';
  public cpf = '';
  public iduser = '';
  public registro = '';
  public curso = '';
  public ministerio = '';
  public ministerio_custo = '';
  public perfil = '';
  public hash = '';
  public email = '';
  public dt_geracao_token = '';
  public hora_token_geracao = '';
  public dt_validade_token = '';
  public hora_validade_token = '';


  ResetDados() {
    localStorage.removeItem('dadosusuario')
    localStorage.removeItem('confirmacao-save')
    localStorage.removeItem('QRCODE-save')
    localStorage.removeItem('contato-save')
    localStorage.removeItem('cadastro-save')
    localStorage.removeItem('Culto-save')
    localStorage.removeItem('CultoKids-save')
    localStorage.removeItem('Seminario-save')
    localStorage.removeItem('problema-save')
    localStorage.removeItem('ElogioDetalhe')
    localStorage.removeItem('VisitaDetalhe');
    localStorage.removeItem('ReclamacaoDetalhe');
    localStorage.removeItem('SugestoesDetalhe');
    localStorage.removeItem('TestemunhoDetalhe');
    localStorage.removeItem('AcessoDetalhe');
    localStorage.removeItem('SeminarioDetalhe');
    localStorage.removeItem('UsuarioDetalhe');
    localStorage.removeItem('UsuarioAtivoDetalhe');
    localStorage.removeItem('UsuarioAguardandoLiberacaoDetalhe');
    localStorage.removeItem('UsuarioCanceladoDetalhe');
    localStorage.removeItem('UsuarioInativoDetalhe');
    localStorage.removeItem('tipo');
    localStorage.removeItem('UsuarioPerfilDetalhe');
    localStorage.removeItem('VisitanteDetalhe');
    localStorage.removeItem('cadastro-homens-save');
    localStorage.removeItem('encontro-homens');
    localStorage.removeItem('cadastro-mulheres-save');
    localStorage.removeItem('curso-casais-save');
    localStorage.removeItem('encontro-mulheres');
    localStorage.removeItem('cadastro-homens-save');
    localStorage.removeItem('encontro-homens');
    localStorage.removeItem('CongressoInfantilRegistrado');
    localStorage.removeItem('CongressoInfantil-save');
    this.token = '';
    this.autenticado = false;
    this.email = '';
    this.registro = '';
    this.hash = '';
    this.iduser = '';
    this.perfil = '';
    this.curso = '';
    this.ministerio = '';
    this.nome = '';
    this.cpf = '';
    this.dt_geracao_token = '';
    this.dt_validade_token = '';
    this.hora_token_geracao = '';
    this.hora_validade_token = '';
    this.ministerio_custo = '';
    EventEmitterService.get('loginAlert').emit(false);
  }

  ValidarToken() {

    if (this.token == '' || this.token == null) {
      return false;
    } else {

      var str = this.dt_geracao_token;
      var novaData = new Date();

      var str1 = this.dt_validade_token;
      var date1 = new Date(str.split('/').reverse().join('/'));
      var novaData1 = new Date();
      if (date => novaData && date1 <= date) {
        return true;
      }
      else {
        this.ResetDados();
        return false;
      }
    }
  }
}

