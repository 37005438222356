import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { PrimeNGConfig } from 'primeng/api';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { Parametros } from 'src/app/shared/parametros.enum';
import { SharedModule } from 'src/app/shared/shared.module';
import { OportunidadeModel } from '../cadastro-oportunidade/OportunidadeModel';

@Component({
  selector: 'app-lista-consulta-oportunidades',
  templateUrl: './lista-consulta-oportunidades.component.html',
  styleUrls: ['./lista-consulta-oportunidades.component.css']
})
export class ListaConsultaOportunidadesComponent implements OnInit {

  private urlestabelecimento = Parametros.CADASTROOPORTUNIDADE;
  filtro_cabecalho: string = ""
  explicacao: string = "";
  textoconsulta: string = "*";
  idoportunidade: any;

  constructor(

    private http: HttpClient,
    private primengConfig: PrimeNGConfig,
    private alert: AlertModalService,
    private autenticado:SharedModule

  ) {

   
  }

  startedClass = false;
  completedClass = false;
  preventAbuse = false;

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//

  oportunidade: OportunidadeModel[];
  qto: number;
  cols: any[];


  ngOnInit() {

    this.cols = [
      { field: 'oportunidade', header: 'Oportunidade' },
      { field: 'telefone', header: 'Contato' },
      { field: 'funcionamento', header: 'Funcionamento' },
      { field: 'sexo', header: 'Sexo' },
      { field: 'cliques', header: 'Visualizações' }

    ];

    this.primengConfig.ripple = true;
    this.filtro_cabecalho = "Listagem de Oportunidades Cadastradas "
    this.explicacao = "Você está na área de consulta de Oportunidades oferecidos por nossos membros!,Lembramos que todos os dados aqui informados são controlado por nossos membros não sendo informados ou cadastrados pela Morada de Deus."
    this.Ranking();
  }

  UpdateClique(event) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get(this.urlestabelecimento + 'GETUpdateClique/?parametro=' + event, httpOptions).subscribe(
      (success) => {

      }
    )
  }

  Ranking() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get<OportunidadeModel[]>(this.urlestabelecimento + 'GETOportunidadeRanking', httpOptions).subscribe(
      (success) => {
        this.oportunidade = success;
        this.qto = success.length;
        this.textoconsulta = "";

        if (success.length == 0) {

          this.alert.AlertShowInfo('Oportunidade não encontrada!');
        }
      }
    )
  }

  Consulta() {
    var texto = (<HTMLInputElement>document.getElementById("texto_consulta")).value;
    if (texto != "") {
      this.textoconsulta = texto;
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get<OportunidadeModel[]>(this.urlestabelecimento + 'GETConsultaOportunidade/?parametro=' + this.textoconsulta, httpOptions).subscribe(
      (success) => {
        this.oportunidade = success;
        this.qto = success.length;
        this.textoconsulta = "";

        if (success.length == 0) {

          this.alert.AlertShowInfo('Oportunidade não encontrada!');
        }
      }

    )
  }

}
