import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { Parametros } from 'src/app/shared/parametros.enum';
import { SharedModule } from 'src/app/shared/shared.module';
import { ListagemEstabelecimentoService } from '../listagem-estabelecimento/listagem-estabelecimento.service';
import { estabelecimentomodel } from '../cadastro-estabelecimento/EstabelecimentoModel';

@Component({
  selector: 'app-listagem-estabelecimento',
  templateUrl: './listagem-estabelecimento.component.html',
  styleUrls: ['./listagem-estabelecimento.component.css']
})
export class ListagemEstabelecimentoComponent implements OnInit {
  private urlestabelecimento = Parametros.CADASTROESTABELECIMENTO;

  x = 1;
  y = 5;
  filtro_cabecalho: string = ""
  explicacao: string = "";
  textoconsulta: string = "";
  rota: any;
  perfil: any;
  pagina_atual = 1;
  imprimir: string = "Filtro:Todos";
  blob: Blob;
  idelogio: any;
  nome: string;


  constructor(private http: HttpClient,
    private primengConfig: PrimeNGConfig,
    private alert: AlertModalService,
    private autenticado: SharedModule,
    private service: ListagemEstabelecimentoService,
    r: Router
  ) {
    this.rota = r;
    this.perfil = this.autenticado.perfil;
    this.nome = this.autenticado.nome;
  }

  startedClass = false;
  completedClass = false;
  preventAbuse = false;

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//

  estabelecimento: Array<estabelecimentomodel>;
  qto: number;
  cols: any[];


  GetLista() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get<estabelecimentomodel[]>(this.urlestabelecimento + 'GETListaEstabelecimentoCpf/?parametro=' + this.autenticado.cpf, httpOptions).subscribe(
      dados => this.estabelecimento = dados, dados => this.qto = dados.length
    )
  }

 


  ngOnInit() {
    if (this.autenticado.token == null) {
      this.autenticado.ResetDados();
      this.alert.AlertShowDanger('Usuário não autenticado, redirecionando...')
      this.rota.navigate(['/login']);
    } else {
      if (this.autenticado.ValidarToken() != true) {
        this.autenticado.ResetDados();
        this.alert.AlertShowDanger('Token Invalido!')
        this.rota.navigate(['/login']);
      }
    }
    this.primengConfig.ripple = true;
    this.GetLista();

    this.cols = [
      { field: 'Data Cadastro', header: 'Data Cadastro' },
      { field: 'Nome', header: 'Nome' },
      { field: 'Telefone', header: 'Telefone' },
      { field: 'Ativa', header: 'Ativa' },
      { field: 'Bloqueada', header: 'Bloqueada' },      
      { field: 'Excluir', header: 'Excluir' },
      { field: 'Editar', header: 'Editar' }

    ];   
  }

 

  VisualizarDetalhe(event) {
    localStorage.removeItem('EstabelecimentoDetalhe');
    localStorage.setItem('EstabelecimentoDetalhe', event.currentTarget.value);
    this.rota.navigate(['/dashboard/editar-estabelecimento']);
  }

  
  //modal
  ValorImpressao(event) {
    this.imprimir = event.currentTarget.value;
  }

 

  SetDados(event) {
    this.idelogio = event.currentTarget.value;
  }

  BotaoExcluir() {
    this.ExcluirConsulta();
  }


  ExcluirConsulta() {
    this.service.CancelarEstabelecimento(this.idelogio, this.autenticado.token).subscribe(
      (success) => {
        this.StopProgressBar();
        this.alert.AlertShowInfo('Empresa / Serviços Excluido com sucesso!');
        this.GetLista();
      },
      (error) => {
        this.StopProgressBar();
        this.alert.AlertShowDanger('Erro ao cancelar Empresa / Serviços!');
        this.GetLista();
      }
    )
  }
}
