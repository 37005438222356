import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { PerfilService } from '../../perfil/perfil.service';
import { ComunicadoVisitantesModel } from './ComunicadoVisitantesModel';
import { ComunicacaoVisitantesService } from './comunicacao-visitantes.service';

@Component({
  selector: 'app-comunicar-visitantes',
  templateUrl: './comunicar-visitantes.component.html',
  styleUrls: ['./comunicar-visitantes.component.css']
})
export class ComunicarVisitantesComponent implements OnInit {

  rota: Router;
  comunicadomodel: ComunicadoVisitantesModel;
  formcomunicadovisitantes: FormGroup;
  startedClass = false;
  completedClass = false;
  preventAbuse = false;
  submitted: boolean;
  Total: number = 0;

  converted_image: string = '../../../../assets/img/foto.png';
  @ViewChild("fileInput") fileInput;

  anexo: string = "";
  fotopadrao: string;

  listapara: any = [
    'Todos Visitantes Cadastrados',
    'Todos Visitantes Sem resposta',
    'Todos Visitantes Respondidos',
  ];
  base64Data: { new(sw: number, sh: number): ImageData; new(data: Uint8ClampedArray, sw: number, sh?: number): ImageData; prototype: ImageData; };

  constructor(
    private formBuilder: FormBuilder,
    private alert: AlertModalService,
    private servicecomunicado: ComunicacaoVisitantesService,
    private serviceperfil: PerfilService,
    private router: Router,
    private autenticado: SharedModule,
  ) {
    this.rota = this.router;
    this.comunicadomodel = new ComunicadoVisitantesModel();
  }

  ngOnInit() {
    if (this.autenticado.token == null) {
      this.autenticado.ResetDados();
      this.alert.AlertShowDanger('Usuário não autenticado, redirecionando...')
      this.rota.navigate(['/login']);
    } else {
      if (this.autenticado.ValidarToken() != true) {
        this.autenticado.ResetDados();
        this.alert.AlertShowDanger('Token Invalido!')
        this.rota.navigate(['/login']);
      } else {
        this.comunicadomodel = new ComunicadoVisitantesModel();
        this.criarform();
        this.carregarusuario();
        this.carregarfoto();
      }
    }
  }

  carregarusuario() {
    this.serviceperfil
      .GetUsuaruio(this.autenticado.hash)
      .subscribe(
        (success) => {
          if (success.nome != null) {
            (this.comunicadomodel.nome = success.nome),
              (this.comunicadomodel.cpf = success.cpf);
            if (success.receber_comunicado == "T") {
              this.formcomunicadovisitantes.controls['cpf'].setValue(success.cpf);
              this.formcomunicadovisitantes.controls['nome'].setValue(success.nome);
            } else {
            }
          }
        },
        (error) => {
          this.StopProgressBar();
          this.alert.AlertShowDanger(
            '( ' + error.status + ' ) ' + error.message
          );
        }
      );
  }

  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }

  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  criarform() {
    this.formcomunicadovisitantes = this.formBuilder.group({
      cpf: ['', Validators.required],
      nome: ['', Validators.required],
      assunto: ['', Validators.required],
      mensagem: ['', Validators.required],
      c: ['', Validators.required],
      para: [''],
      anexo: ['']

    });
  }
  SetPara(event) {
    if (event.target.value.includes("Todos Visitantes Cadastrados")) {
      this.formcomunicadovisitantes.controls['para'].setValue("Todos");
    } else if (event.target.value.includes("Todos Visitantes Sem resposta")) {
      this.formcomunicadovisitantes.controls['para'].setValue("TodosSemResposta");
    } else if (event.target.value.includes("Todos Visitantes Respondidos")) {
      this.formcomunicadovisitantes.controls['para'].setValue("TodosRespondidos");
    }

    this.servicecomunicado.GetQuantidadeEnvio(this.autenticado.token, this.formcomunicadovisitantes.get('c').value).subscribe(
      (success) => {
        this.StopProgressBar();
      
        if (success == 0) {
          this.alert.AlertShowDanger("Nada a ser Enviado!");
          this.Total = 0;
          return;
        } else {
          this.Total = success;

        }
      }
    )
  }




  carregarfoto() {
    this.base64Data = ImageData;
    this.servicecomunicado.DownloadFoto(this.autenticado.hash, this.autenticado.token)
      .subscribe(
        (success) => {
          this.converted_image = "data:image/jpeg;base64," + success;
          this.fotopadrao = "data:image/jpeg;base64," + success;
        },
        (error) => {
          if (error.error.text != 'StatusCode(400,Sem Dados de Foto)') {
            this.converted_image = "data:image/jpg;base64," + error.error.text;
            this.fotopadrao = "data:image/jpg;base64," + error.error.text;
          } else {
            this.converted_image = "data:image/jpeg;base64," + error.error.text;
            this.fotopadrao = "data:image/jpeg;base64," + error.error.text;
          }
        }
      );
  }


  FotoPadrao() {

    this.converted_image = "../../../../assets/img/foto.png";
    this.formcomunicadovisitantes.controls['anexo'].setValue("");
    this.anexo = "";
  }

  SetFoto(event) {
    this.anexo = "x";
  }


  onSubmit() {
    this.submitted = true;
    if (this.formcomunicadovisitantes.invalid) {
      this.alert.AlertShowDanger('Favor verificar os campos em destaque!');
      return;
    }
    let fi = this.fileInput.nativeElement;

    if (this.anexo == "undefined" || this.anexo == "") {
      this.formcomunicadovisitantes.controls['anexo'].setValue("");
      this.servicecomunicado.GetQuantidadeEnvio(this.autenticado.token, this.formcomunicadovisitantes.get('c').value).subscribe(
        (success) => {
          this.StopProgressBar();
          if (success == 0) {
            this.alert.AlertShowDanger("Nada a ser Enviado!");
            this.Total = 0;
            return;
          } else {
            this.formcomunicadovisitantes.controls['para'].setValue(this.formcomunicadovisitantes.get('c').value);
            this.alert.AlertShowInfo("Estamos preparando e enviando ( " + success + " ) e-mails! Pode descansar enquanto trabalhamos!")
            this.servicecomunicado.UpdateComunicado(this.autenticado.token, this.formcomunicadovisitantes.value)
            this.comunicadomodel = new ComunicadoVisitantesModel();
            this.criarform();
            this.submitted = false;
            this.carregarusuario();
            this.carregarfoto();
            this.Total = 0;
          }
        },
        (error) => {
          this.StopProgressBar();
          this.alert.AlertShowDanger("Não Existem dados a serem enviados!")
          this.comunicadomodel = new ComunicadoVisitantesModel();
          this.criarform();
          this.submitted = false;
          this.carregarusuario();
          this.carregarfoto();
          this.Total = 0;
        });
    } else {
      if (fi.files && fi.files[0]) {
        let fileToUpload = fi.files[0];
        this.servicecomunicado.UploadArquivo(fileToUpload, this.autenticado.token, this.autenticado.cpf)
          .subscribe(
            (success) => {
              this.formcomunicadovisitantes.controls['anexo'].setValue(success);
              this.formcomunicadovisitantes.controls['para'].setValue(this.formcomunicadovisitantes.get('c').value);
              this.servicecomunicado.GetQuantidadeEnvio(this.autenticado.token, this.formcomunicadovisitantes.get('c').value).subscribe(
                (success) => {
                  this.StopProgressBar();
                  if (success == 0) {
                    this.alert.AlertShowDanger("Nada a ser Enviado!");
                    this.Total = 0;
                    return;
                  } else {
                    this.formcomunicadovisitantes.controls['para'].setValue(this.formcomunicadovisitantes.get('c').value);
                    this.alert.AlertShowInfo("Estamos preparando e enviando ( " + success + " ) e-mails! Pode descansar enquanto trabalhamos!")
                    this.servicecomunicado.UpdateComunicado(this.autenticado.token, this.formcomunicadovisitantes.value)
                    this.comunicadomodel = new ComunicadoVisitantesModel();
                    this.criarform();
                    this.submitted = false;
                    this.carregarusuario();
                    this.Total = 0;
                  }
                },
                (error) => {
                  this.StopProgressBar();
                  this.alert.AlertShowDanger("Não Existem dados a serem enviados!")
                  this.comunicadomodel = new ComunicadoVisitantesModel();
                  this.criarform();
                  this.submitted = false;
                  this.carregarusuario();
                  this.Total = 0;

                });
            },
            (error) => {
              this.anexo = error.error.text;
              this.formcomunicadovisitantes.controls['anexo'].setValue(this.anexo);
              this.formcomunicadovisitantes.controls['para'].setValue(this.formcomunicadovisitantes.get('c').value);
              this.servicecomunicado.GetQuantidadeEnvio(this.autenticado.token, this.formcomunicadovisitantes.get('c').value).subscribe(
                (success) => {
                  this.StopProgressBar();
                  if (success == 0) {
                    this.alert.AlertShowDanger("Nada a ser Enviado!");
                    this.Total = 0;
                    return;
                  } else {
                    this.formcomunicadovisitantes.controls['para'].setValue(this.formcomunicadovisitantes.get('c').value);
                    this.alert.AlertShowInfo("Estamos preparando e enviando ( " + success + " ) e-mails! Pode descansar enquanto trabalhamos!")
                    this.servicecomunicado.UpdateComunicado(this.autenticado.token, this.formcomunicadovisitantes.value)
                    this.comunicadomodel = new ComunicadoVisitantesModel();
                    this.criarform();
                    this.submitted = false;
                    this.carregarusuario();
                    this.Total = 0;
                  }
                },
                (error) => {
                  this.StopProgressBar();
                  this.alert.AlertShowDanger("Não Existem dados a serem enviados!")
                  this.comunicadomodel = new ComunicadoVisitantesModel();
                  this.criarform();
                  this.submitted = false;
                  this.carregarusuario();
                  this.Total = 0;
                });
            });
      }






    }


  }
  get f() {
    return this.formcomunicadovisitantes.controls;
  }

}
