export class TrocaSenhaModel {


    cpf: string = '';
    nome: string = '';
    login: string = '';
    senha: string = '';
    senha_nova: string = '';
    senha_nova_confirmar: string = '';

}
