import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { from } from 'rxjs';
import { UsuarioModel } from 'src/app/Recuperacoes/recuperar-autenticacao/usuario-model';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { Parametros } from 'src/app/shared/parametros.enum';
import { SharedModule } from 'src/app/shared/shared.module';
import { MembrosCanceladosService } from './membros-cancelados.service';

@Component({
  selector: 'app-membros-cancelados',
  templateUrl: './membros-cancelados.component.html',
  styleUrls: ['./membros-cancelados.component.css']
})
export class MembrosCanceladosComponent implements OnInit {
  private urlgestao = Parametros.GERENCIAMEMBROS;

  x = 1;
  y = 10;
  filtro_cabecalho: string = ""
  explicacao: string = "";
  textoconsulta: string = "";
  rota: any;
  perfil: any;
  pagina_atual = 1;
  imprimir: string = "Todos";
  idusuario: any;
  nome: string;
  opcao: string = "Ativar";


  constructor(private http: HttpClient,
    private primengConfig: PrimeNGConfig,
    private alert: AlertModalService,
    private autenticado: SharedModule,
    private service: MembrosCanceladosService,
    r: Router
  ) {
    this.rota = r;
    this.perfil = this.autenticado.perfil;
    this.nome = this.autenticado.nome;
  }

  startedClass = false;
  completedClass = false;
  preventAbuse = false;

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//

  usuario: Array<UsuarioModel>;
  qto: number;
  cols: any[];


  GetLista() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get<UsuarioModel[]>(this.urlgestao + 'GETListaTodosUsuariosCancelados', httpOptions).subscribe(
      (success) => {
        this.usuario = success;
        this.GetListaQuantidade();
      }

    )
  }

  GetListaQuantidade() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get<UsuarioModel[]>(this.urlgestao + 'GETListaQuantidadeTodoscancelados', httpOptions).subscribe(
      (success) => {
      
        this.qto = success.length
      }
    )
  }

  GetListaTotalQuantidadeDez(pagina: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get<any>(this.urlgestao + 'GetListaConsultaLimiteCancelados/?parametro=' + pagina, httpOptions).subscribe(
      (success) => {
        this.usuario = success;
        this.GetListaQuantidade();
      }
    )
  }

  ngOnInit() {
    if (this.autenticado.token == null) {
      this.autenticado.ResetDados();
      this.alert.AlertShowDanger('Usuário não autenticado, redirecionando...')
      this.rota.navigate(['/login']);
    } else {
      if (this.autenticado.ValidarToken() != true) {
        this.autenticado.ResetDados();
        this.alert.AlertShowDanger('Token Invalido!')
        this.rota.navigate(['/login']);
      }
    }
    this.primengConfig.ripple = true;
    this.filtro_cabecalho = "Listagem de Usuarios Cancelados"
    this.explicacao = "Aqui você está visualizando todos os usuarios Cancelados na plataforma."
    this.GetLista();
  }

  
  Paginate(event) {
    if (event.page == 0) {
      this.x = 1;
      this.y = 10;
    } else {

      if (event.page * 10 > this.qto) {
        this.x = this.qto;
        this.y = this.qto;
      } else {
        this.x = event.page * 10;
        if (this.x + 10 > this.qto) {
          this.y = this.qto;
        } else {
          this.y = this.x + 10;
        }
      }
    }


    this.pagina_atual = event.page;

    this.GetListaTotalQuantidadeDez(this.pagina_atual);

  }


  Consulta() {
    var texto = (<HTMLInputElement>document.getElementById("texto_consulta")).value;
    if (texto != "") {
      this.textoconsulta = texto;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
        })
      }
      this.http.get<UsuarioModel[]>(this.urlgestao + 'GetListaConsultaUsuarioNomeCancelados/?parametro=' + texto, httpOptions).subscribe(
        (success) => {
          this.usuario = success;
          this.qto = success.length;
        }

      )
    } else {
      this.textoconsulta = "";
      this.GetLista();
    }
  }



  VisualizarDetalhe(event) {
    localStorage.removeItem('UsuarioCanceladoDetalhe');
    localStorage.setItem('UsuarioCanceladoDetalhe', event.currentTarget.value);
    this.rota.navigate(['/dashboard/gerenciamento/detalhamento/usuario-cancelado']);
  }


  //modal
  ValorImpressao(event) {
    this.imprimir = event.currentTarget.value;
  }



 



  Operacao() {
    if (this.opcao == "Ativar") {
      this.service.AtivarUsuario(this.idusuario).subscribe(
        (success) => {
          this.alert.AlertShowInfo('Membro Ativado com sucesso!');
          this.GetLista();
        },
        (error) => {
          this.alert.AlertShowDanger('Erro ao tentar Ativar Membro!');
          this.GetLista();
        }
      );
    }

    if (this.opcao == "Inativar") {
      this.service.InativarUsuario(this.idusuario).subscribe(
        (success) => {
          this.alert.AlertShowInfo('Membro Inativado com sucesso!');
          this.GetLista();
        },
        (error) => {
          this.alert.AlertShowDanger('Erro ao tentar Inativar Membro!');
          this.GetLista();
        }
      );
    }
    if (this.opcao == "Cancelar") {
      this.service.Cancelarsuario(this.idusuario).subscribe(
        (success) => {
          this.alert.AlertShowInfo('Membro Cancelado com sucesso!');
          this.GetLista();
        },
        (error) => {
          this.alert.AlertShowDanger('Erro ao tentar Cancelar Membro!');
          this.GetLista();
        }
      );
    }
  }



  SetDados(event) {
    this.idusuario = event.currentTarget.value;
  }

  SetOpcao(event) {
    this.opcao = event.currentTarget.value;
  }



}
