import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { retry } from 'rxjs/operators';
import { AlertModalService } from '../shared/alert-modal/alert-modal.service';
import { Parametros } from '../shared/parametros.enum';
import { SharedModule } from '../shared/shared.module';

@Injectable({
  providedIn: 'root'
})
export class VisitanteService {
  private urlcontato = Parametros.VISITANTE;
  private rota: Router;


  constructor(private http: HttpClient,
    router: Router,
    private alert: AlertModalService
  ) {
    this.rota = router;
  }

  Registrarvisitante(_visitante) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http
      .post(this.urlcontato + 'Postvisitante', _visitante, httpOptions)
      .pipe(retry(1));
  }

}
