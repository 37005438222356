import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, retry, take } from 'rxjs/operators';
import { ParametrosModel } from '../Dashboard/configuracaoes/ParametrosModel';
import { Parametros } from '../shared/parametros.enum';
import { cultomodel } from './CultoModel';

@Injectable({
  providedIn: 'root'
})
export class CultoInscricaoService {
  private urlcadastro = Parametros.CADASTROCULTO;
  private urlparametros = Parametros.PARAMETROS;
  private urlcorreios = Parametros.CORREIOS;

  constructor(private http: HttpClient) { }
  startedClass = false;
  completedClass = false;
  preventAbuse = false;

  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }

  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }


  ConsultaCEP(cep) {
    this.StartProgressBar();
    return this.http
      .get<cultomodel>(
        this.urlcorreios +
        cep.replace('\\', '').replace('-', '').replace('.', '') + '/json/'
      )
      .pipe(take(1));
  }


  CadastroCulto(c) {
    this.StartProgressBar();
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http
      .post(this.urlcadastro + 'POSTCadastroCultoRegistrado', c, httpOptions).pipe(first());
  }

  QuantidadeCadastro(_culto) {
    this.StartProgressBar();
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http
      .get(this.urlcadastro + 'GETQuantidadeCadastro/?parametro=' + _culto, httpOptions).pipe(retry(2));
  }

  LimiteCadastro() {
    this.StartProgressBar();
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http
      .get<any>(this.urlparametros + 'GETLimiteCulto', httpOptions).pipe(retry(2));
  }




  Consulta(cpf) {
    this.StartProgressBar();
    var j = cpf
      .replace('\\', '')
      .replace('-', '')
      .replace('.', '')
      .replace('/', '');
    return this.http
      .get<cultomodel>(this.urlcadastro + 'GETUsuarioExiste/?cpf=' + j.replace('\\', '')
        .replace('-', '')
        .replace('.', '')
        .replace('/', ''))
      .pipe(take(1));
  }



}
