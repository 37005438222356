import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { from } from 'rxjs';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { ComunicadoMembrosModel } from './ComunicadoMembrosModel';
import { ComunicadoMembrosService } from './comunicado-membros.service';
import { PerfilService } from '../../perfil/perfil.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { ComunicacaoModel } from '../../comunicacao/comunicacaoModel';

@Component({
  selector: 'app-comunicado-membros',
  templateUrl: './comunicado-membros.component.html',
  styleUrls: ['./comunicado-membros.component.css']
})
export class ComunicadoMembrosComponent implements OnInit {

  rota: Router;
  comunicadomodel: ComunicadoMembrosModel;
  formcomunicadomembros: FormGroup;
  startedClass = false;
  completedClass = false;
  preventAbuse = false;
  submitted: boolean;
  converted_image: string = '../../../../assets/img/foto.png';
  @ViewChild("fileInput") fileInput;
  anexo: string = "";
  fotopadrao: string;
  Total: number = 0;

  listapara: any = [
    'Todos Membros Cadastrados ( Ativos e Inativos )',
    'Todos Membros Ativos',
    'Todos Membros Inativos',
  ];
  base64Data: { new(sw: number, sh: number): ImageData; new(data: Uint8ClampedArray, sw: number, sh?: number): ImageData; prototype: ImageData; };


  constructor(
    private formBuilder: FormBuilder,
    private alert: AlertModalService,
    private servicecomunicado: ComunicadoMembrosService,
    private serviceperfil: PerfilService,
    private router: Router,
    private autenticado: SharedModule,
  ) {
    this.rota = this.router;
    this.comunicadomodel = new ComunicadoMembrosModel();
  }

  ngOnInit() {
    if (this.autenticado.token == null) {
      this.autenticado.ResetDados();
      this.alert.AlertShowDanger('Usuário não autenticado, redirecionando...')
      this.rota.navigate(['/login']);
    } else {
      if (this.autenticado.ValidarToken() != true) {
        this.autenticado.ResetDados();
        this.alert.AlertShowDanger('Token Invalido!')
        this.rota.navigate(['/login']);
      } else {
        this.comunicadomodel = new ComunicadoMembrosModel();
        this.criarform();
        this.carregarusuario();
        this.carregarfoto();
      }
    }
  }

  carregarusuario() {
    this.serviceperfil
      .GetUsuaruio(this.autenticado.hash)
      .subscribe(
        (success) => {
          if (success.nome != null) {
            (this.comunicadomodel.nome = success.nome),
              (this.comunicadomodel.cpf = success.cpf);
            if (success.receber_comunicado == "T") {
              this.formcomunicadomembros.controls['cpf'].setValue(success.cpf);
              this.formcomunicadomembros.controls['nome'].setValue(success.nome);
            } else {
            }
          }
        },
        (error) => {
          this.StopProgressBar();
          this.alert.AlertShowDanger(
            '( ' + error.status + ' ) ' + error.message
          );
        }
      );
  }

  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }

  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  criarform() {
    this.formcomunicadomembros = this.formBuilder.group({
      cpf: ['', Validators.required],
      nome: ['', Validators.required],
      assunto: ['', Validators.required],
      mensagem: ['', Validators.required],
      c: ['', Validators.required],
      para: [''],
      anexo: ['']

    });
  }
  SetPara() {
    this.totalizador();

  }

  carregarfoto() {
    this.base64Data = ImageData;
    this.servicecomunicado.DownloadFoto(this.autenticado.hash, this.autenticado.token)
      .subscribe(
        (success) => {
          this.converted_image = "data:image/jpeg;base64," + success;
          this.fotopadrao = "data:image/jpeg;base64," + success;
        },
        (error) => {
          if (error.error.text != 'StatusCode(400,Sem Dados de Foto)') {
            this.converted_image = "data:image/jpg;base64," + error.error.text;
            this.fotopadrao = "data:image/jpg;base64," + error.error.text;
          } else {
            this.converted_image = "data:image/jpeg;base64," + error.error.text;
            this.fotopadrao = "data:image/jpeg;base64," + error.error.text;
          }
        }
      );
  }

  FotoPadrao() {
    this.converted_image = "../../../../assets/img/foto.png";
    this.formcomunicadomembros.controls['anexo'].setValue("");
    this.anexo = "";
  }

  SetFoto(event) {
    this.anexo = "x";
  }

  totalizador() {
    this.servicecomunicado.GetQuantidadeEnvio(this.autenticado.token, this.formcomunicadomembros.get('c').value).subscribe(
      (success) => {
        this.Total = success;

        if (this.Total == 0) {
          this.alert.AlertShowDanger('Não existem usuarios para o tipo Selecionado!');
        }
      }
    );
  }

  onSubmit() {
    this.submitted = true;
    if (this.Total == 0) {
      this.alert.AlertShowDanger('Não existem usuarios para o tipo Selecionado!');
      return;
    }
    if (this.formcomunicadomembros.invalid) {
      this.alert.AlertShowDanger('Favor verificar os campos em destaque!');
      return;
    }
    let fi = this.fileInput.nativeElement;

    if (this.anexo == "undefined" || this.anexo == "") {
      this.formcomunicadomembros.controls['anexo'].setValue("");
      this.servicecomunicado.GetQuantidadeEnvio(this.autenticado.token, this.formcomunicadomembros.get('c').value).subscribe(
        (success) => {
          this.StopProgressBar();

          this.formcomunicadomembros.controls['para'].setValue(this.formcomunicadomembros.get('c').value);
          this.alert.AlertShowInfo("Estamos preparando e enviando ( " + success + " ) e-mails! Pode descansar enquanto trabalhamos!")
          this.servicecomunicado.UpdateComunicado(this.autenticado.token, this.formcomunicadomembros.value)
          this.comunicadomodel = new ComunicadoMembrosModel();
          this.criarform();
          this.submitted = false;
          this.carregarusuario();
          this.carregarfoto();
          this.Total = 0;

        },
        (error) => {
          this.StopProgressBar();
          this.alert.AlertShowDanger("Não Existem dados a serem enviados!")
          this.comunicadomodel = new ComunicadoMembrosModel();
          this.criarform();
          this.submitted = false;
          this.carregarusuario();
          this.carregarfoto();
          this.Total = 0;
        });
    } else {
      if (fi.files && fi.files[0]) {
        let fileToUpload = fi.files[0];
        this.servicecomunicado.UploadArquivo(fileToUpload, this.autenticado.token, this.autenticado.cpf)
          .subscribe(
            (success) => {
              this.formcomunicadomembros.controls['anexo'].setValue(success);
              this.servicecomunicado.GetQuantidadeEnvio(this.autenticado.token, this.formcomunicadomembros.get('c').value).subscribe(
                (success) => {
                  this.StopProgressBar();

                  this.formcomunicadomembros.controls['para'].setValue(this.formcomunicadomembros.get('c').value);
                  this.alert.AlertShowInfo("Estamos preparando e enviando ( " + success + " ) e-mails! Pode descansar enquanto trabalhamos!")
                  this.servicecomunicado.UpdateComunicado(this.autenticado.token, this.formcomunicadomembros.value)
                  this.comunicadomodel = new ComunicadoMembrosModel();
                  this.criarform();
                  this.submitted = false;
                  this.carregarusuario();
                  this.Total = 0;
                },
                (error) => {
                  this.StopProgressBar();
                  this.alert.AlertShowDanger("Não Existem dados a serem enviados!")
                  this.comunicadomodel = new ComunicadoMembrosModel();
                  this.criarform();
                  this.submitted = false;
                  this.carregarusuario();
                  this.Total = 0;

                });
            },
            (error) => {
              this.anexo = error.error.text;
              this.formcomunicadomembros.controls['anexo'].setValue(this.anexo);
              this.servicecomunicado.GetQuantidadeEnvio(this.autenticado.token, this.formcomunicadomembros.get('c').value).subscribe(
                (success) => {
                  this.StopProgressBar();

                  this.formcomunicadomembros.controls['para'].setValue(this.formcomunicadomembros.get('c').value);
                  this.alert.AlertShowInfo("Estamos preparando e enviando ( " + success + " ) e-mails! Pode descansar enquanto trabalhamos!")
                  this.servicecomunicado.UpdateComunicado(this.autenticado.token, this.formcomunicadomembros.value)
                  this.comunicadomodel = new ComunicadoMembrosModel();
                  this.criarform();
                  this.submitted = false;
                  this.carregarusuario();
                  this.Total = 0;

                },
                (error) => {
                  this.StopProgressBar();
                  this.alert.AlertShowDanger("Não Existem dados a serem enviados!")
                  this.comunicadomodel = new ComunicadoMembrosModel();
                  this.criarform();
                  this.submitted = false;
                  this.carregarusuario();
                  this.Total = 0;
                });
            });
      }

    }


  }
  get f() {
    return this.formcomunicadomembros.controls;
  }

}
