import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { ComunicacaoModel } from './comunicacaoModel';
import { ComunicacaoService } from './comunicacao.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { PerfilService } from '../perfil/perfil.service';

@Component({
  selector: 'app-comunicacao',
  templateUrl: './comunicacao.component.html',
  styleUrls: ['./comunicacao.component.css']
})
export class ComunicacaoComponent implements OnInit {
  rota: Router;
  modelcomunicado: ComunicacaoModel;
  formcomunicado: FormGroup;
  startedClass = false;
  completedClass = false;
  preventAbuse = false;
  submitted: boolean;
  receber_comunicado: boolean = false;
  



  constructor(
    private formBuilder: FormBuilder,
    private alert: AlertModalService,
    private servicecomunicacao: ComunicacaoService,
    private router: Router,
    private serviceperfil: PerfilService,
    private autenticado: SharedModule,
  ) {
    this.rota = this.router;
    this.modelcomunicado = new ComunicacaoModel();
  }

  ngOnInit() {
    if (this.autenticado.token == null) {
      this.autenticado.ResetDados();
      this.alert.AlertShowDanger('Usuário não autenticado, redirecionando...')
      this.rota.navigate(['/login']);
    } else {
      if (this.autenticado.ValidarToken() != true) {
        this.autenticado.ResetDados();
        this.alert.AlertShowDanger('Token Invalido!')
        this.rota.navigate(['/login']);
      } else {
        this.modelcomunicado = new ComunicacaoModel();
        this.criarform();
        this.carregarusuario();
      }
    }
  }

  carregarusuario() {
    this.serviceperfil
      .GetUsuaruio(this.autenticado.hash)
      .subscribe(
        (success) => {
          if (success.nome != null) {
            (this.modelcomunicado.nome = success.nome),
              (this.modelcomunicado.cpf = success.cpf);
            if (success.receber_comunicado == "T") {
              this.modelcomunicado.receber_comunicado == true;
              this.receber_comunicado = true;
            } else {
              this.modelcomunicado.receber_comunicado == false;
              this.receber_comunicado = false;
            }
                     }
        },
        (error) => {
          this.StopProgressBar();
          this.alert.AlertShowDanger(
            '( ' + error.status + ' ) ' + error.message
          );
        }
      );
  }

  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }

  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  criarform() {
    this.formcomunicado = this.formBuilder.group({
      cpf: ['', Validators.required],
      nome: ['', Validators.required],
      receber_comunicado: [false, Validators.required]     

    });
  }


  onSubmit() {

    this.submitted = true;


    this.servicecomunicacao.UpdateComunicado(this.autenticado.token, this.formcomunicado.value).subscribe(
      () => {
        this.alert.AlertShowSucess('Comunicação Atualizada com sucesso!');
      },
      (error) => {
        this.alert.AlertShowDanger('Erro ao Atualizar Comunicação!');
      });

  }


  get f() {
    return this.formcomunicado.controls;
  }

}
