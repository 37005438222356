<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>
<div>
  <div class="row">
    <div class="col-md-2" style="margin-right: 2px; padding: 0%;">
      <img src="../../../assets/img/configuracoes.png" height="200px"  style="padding: 0%;"/>
    </div>
    <div class="col-md">
      <h4 class="espacamentotopo " style="margin-left: 5px;">
        Ajustes dos Termos
      </h4>
      <label style="margin-left: 5px;">
        Aqui você define os termos exibidos na plataforma!.
        <br>
        Caso fique em branco será gravado por segurança o texto (Termo ainda não configurado) para evitar erros
        no sistema!<br>
        A configuração do texto usa TAG's HTML,se por algum motivo tiver duvidas por favor não deixe de
        comunicar.<br>
        Exemplos de TAG (<'br'> = Quebra de linha | <'p'> = Paragrafo ).<br>
        Exemplo de texto (<'br'>Olá Morada de Deus!,<'p'>Estamos aqui para lhe ajudar<'/p'>), lembre-se de tirar os (') das Tags.
      </label>
    </div>
  </div>


  <hr class="espacamentotopo bold" />
  <form [formGroup]="formtermo" class="m" disabled="">
    <div class="row">
      <!---INCIO PRIMEIRA LINHA-->
      <div class="col-md-12">
        <div class="form-group">
          <label>Termo Encontro Casais:</label>
          <textarea type="text" rows="7" class="form-control" id="termo" name="termo" maxlength="2000"
                    autocomplete="off" formControlName="termo" [(ngModel)]="modeltermo.termo"
                    [ngClass]="{ 'is-invalid': submitted && f.termo.errors }"></textarea>
          <div *ngIf="submitted && f.termo.errors" class="invalid-feedback">
            <div *ngIf="f.termo.errors.required">Preenchimento obrigatório</div>
          </div>
        </div>
      </div>
    </div>



    <br>

    <div style="padding-top: 10px; padding-bottom: 20px;">
      <button pButton pRipple type="button" icon="fas fa-sync-alt" class="p-button-rounded"
              title="::Salvar Alterações::" (click)="onSubmit()">
      </button>
      <button pButton pRipple type="button" icon="pi pi-sign-in" class="p-button-rounded p-button-danger"
              title="::Fechar::" routerLink="/dashboard/resumo">
      </button>
    </div>

  </form>
</div>
<button class="voltar-ao-topo" id="back"><a class="ancora" style="color: white;"><i
  class="pi pi-arrow-circle-up"></i></a></button>
