export class VisitaModel {

    [x: string]: any;
    cpf: string = '';
    nome: string = '';
    email: string = '';
    telefone: string = '';
    tipo_visita: string = '';
    mensagem: string = '';
    receber_copia: boolean = false;
    segunda_feira: boolean = false;
    terca_feira: boolean = false;
    quarta_feira: boolean = false;
    quinta_feira: boolean = false;
    sexta_feira: boolean = false;
    sabado: boolean = false;
    domingo: boolean = false;    
    melhor_horario: string = '';
    visualizada: string = '';
    idusuario:number;


}
