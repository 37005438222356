import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { AlertModalComponent } from 'src/app/shared/alert-modal/alert-modal.component';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { Parametros } from 'src/app/shared/parametros.enum';
import { SharedModule } from 'src/app/shared/shared.module';
import { patrimoniomodel } from '../registrar-patrimonio/PatrimonioModel';
import { HistoricoModel } from './HistoricoModel';

@Component({
  selector: 'app-detalhe-patrimonio',
  templateUrl: './detalhe-patrimonio.component.html',
  styleUrls: ['./detalhe-patrimonio.component.css']
})
export class DetalhePatrimonioComponent implements OnInit {
  private urlpatrimonio = Parametros.PATRIMONIO;
  urlrelatoriotodospatrimonio = Parametros.RELATORIOTODOSPATRIMONIO;


  x = 1;
  y:any = 10;
  filtro_cabecalho: string = ""
  explicacao: string = "";
  textoconsulta: string = "";
  rota: any;
  perfil: any;
  pagina_atual = 0;
  idpatrimonio: string;
  numeropatrimonio: string = '';
  nomepatrimonio: string = '';
  valorpatrimonio: any = '';
  setorpatrimonio: string = '';




  constructor(private http: HttpClient,
    private primengConfig: PrimeNGConfig,
    private autenticado: SharedModule,
    private alert: AlertModalService,

    r: Router
  ) {
    this.rota = r;
    this.perfil = this.autenticado.perfil;
  }

  startedClass = false;
  completedClass = false;
  preventAbuse = false;

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//

  usuario: Array<HistoricoModel>;
  qto: any;
  cols: any[];






  GetListaConsultaLimite(pagina: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get<any>(this.urlpatrimonio + 'GETHistoricoPatrimonio/?parametro=' + this.idpatrimonio + '&pagina=' + pagina, httpOptions).subscribe(
      (success) => {
        this.usuario = success;   

      },
      (error) => {
      }
    )
  }

  GetPatrimonio() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get<patrimoniomodel>(this.urlpatrimonio + 'GETPatrimonio/?parametro=' + this.idpatrimonio, httpOptions).subscribe(
      (success) => {
        this.numeropatrimonio = success[0].numero_patrimonio;
        this.nomepatrimonio = success[0].patrimonio;
        this.valorpatrimonio = success[0].valor_estimado;
        this.setorpatrimonio = success[0].setor;

      },
      (error) => {
      }
    )
  }

  ngOnInit() {
    if (this.autenticado.token == null) {
      this.autenticado.ResetDados();
      this.alert.AlertShowDanger('Usuário não autenticado, redirecionando...')
      this.rota.navigate(['/login']);
    } else {
      if (this.autenticado.ValidarToken() != true) {
        this.autenticado.ResetDados();
        this.alert.AlertShowDanger('Token Invalido!')
        this.rota.navigate(['/login']);
      }
    }
    this.primengConfig.ripple = true;
    this.filtro_cabecalho = "Historico detalhado do patrimonio"
    this.explicacao = "Abaixo todas as movimentação do patrimonio desde seu cadastro na plataforma."


    this.cols = [
      { field: 'Data', header: 'Data' },
      { field: 'Hora', header: 'Hora' },
      { field: 'Tipo Movimentação', header: 'Tipo Movimentação' },
      { field: 'Detalhes', header: 'Detalhes' },
      { field: 'Repsonsavel', header: 'Responsavel' }
    ];

    this.idpatrimonio = localStorage.getItem('HistoricoPatrimonio');
    if (this.idpatrimonio == null || this.idpatrimonio == "") {
      this.rota.navigate(['/dashboard/gerenciamento/patrimonio/listagem-patrimonio']);
    } else {
      this.GetListaConsultaLimite(this.pagina_atual);
      this.GetPatrimonio();
      this.Total();
      localStorage.removeItem('HistoricoPatrimonio')
    }
  }

  Paginate(event) {
    if (event.page == 0) {
      this.x = 1;
      this.y = 10;
    } else {

      if (event.page * 10 > this.qto) {
        this.x = this.qto;
        this.y = this.qto;
      } else {
        this.x = event.page * 10;
        if (this.x + 10 > this.qto) {
          this.y = this.qto;
        } else {
          this.y = this.x + 10;
        }
      }
    }
   
    this.pagina_atual = event.page;
    this.GetListaConsultaLimite(this.pagina_atual);
  }

  ImprimirEtiqueta(){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token
      })
    }
    this.http.get(this.urlrelatoriotodospatrimonio + 'GetRelatorioPatrimonioQrcodeEspecifico/?parametro=' + this.numeropatrimonio, httpOptions).subscribe(
      (success) => {
        const linkSource = 'data:application/pdf;base64,' + success;
        const downloadLink = document.createElement("a");
        const fileName = "IEPMD_Etiqueta_Patrimonio_" + (this.autenticado.nome).replace(" ", "") + ".pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }, (error) => {
        if (error.status == '403') {
          this.alert.AlertShowDanger('Erro ao tentar Gerar PDF');
        } else {
          const linkSource = 'data:application/pdf;base64,' + error.error.text;
          const downloadLink = document.createElement("a");
          const fileName = "IEPMD_Etiqueta_Patrimonio_" + (this.autenticado.nome).replace(" ", "") + ".pdf";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      }
    );
  }




  Consulta() {
    var texto = (<HTMLInputElement>document.getElementById("texto_consulta")).value;
    if (texto != "") {
      this.textoconsulta = texto;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
        })
      }
      this.http.get<HistoricoModel[]>(this.urlpatrimonio + 'GetConsultaHistoricoPatrimonio/?parametro=' + this.idpatrimonio + '&texto=' + texto, httpOptions).subscribe(
        (success) => {
          this.usuario = success;
          this.qto = success.length
          this.x = 1;
          this.y = success.length;

        }
      )
    } else {
      this.textoconsulta = "";
      this.GetListaConsultaLimite(this.pagina_atual);
    }
  }

  Total() {
    var texto = (<HTMLInputElement>document.getElementById("texto_consulta")).value;

    this.textoconsulta = texto;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get<HistoricoModel[]>(this.urlpatrimonio + 'GETTotalRegistros/?parametro='+this.idpatrimonio, httpOptions).subscribe(
      (success) => {
        this.qto = success.length;
        this.x = 1;
        

      },
      (error)=>{

      }
    )

  }


}
