import { Component, OnInit } from '@angular/core';
import { EventEmitterService } from '../shared/EventEmitterService';
import { SharedModule } from '../shared/shared.module';

@Component({
  selector: 'app-nossa-igreja',
  templateUrl: './nossa-igreja.component.html',
  styleUrls: ['./nossa-igreja.component.css']
})
export class NossaIgrejaComponent implements OnInit {

  constructor(private autenticado: SharedModule) {
    EventEmitterService.get('loginAlert').emit(false);
  }

  ngOnInit(): void {
  }

}
