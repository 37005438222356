import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { Parametros } from 'src/app/shared/parametros.enum';
import { SharedModule } from 'src/app/shared/shared.module';
import { ListagemDeducoesComprasService } from './listagem-deducoes-compras.service';
import { listagemdeducaomodel } from './deducaolistagemmodel';
import { deducaomodel } from '../registrar-compra/deducaomodel';

@Component({
  selector: 'app-listagem-deducoes-compras',
  templateUrl: './listagem-deducoes-compras.component.html',
  styleUrls: ['./listagem-deducoes-compras.component.css']
})
export class ListagemDeducoesComprasComponent implements OnInit {

  private urldeducao = Parametros.CADASTRODEDUCOESCOMPRAS;

  x = 1;
  y = 10;
  filtro_cabecalho: string = ""
  explicacao: string = "";
  textoconsulta: string = "";
  rota: any;
  perfil: any;
  pagina_atual = 1;
  idusuario: any;
  nome: string;
  ministerio: string;
  modalconteudo: string;
  bt_error: boolean;
  bt_termo: boolean;
  modaltitulo: string;
  iddeducaocompra: any;
  valortotalmesatual: any = '0.00';
  valortotalmespassado: any = '0.00';
  valortotalreembolso: any = '0.00';
  valortotalanual: any = '0.00';

  constructor(private http: HttpClient,
    private primengConfig: PrimeNGConfig,
    private alert: AlertModalService,
    private autenticado: SharedModule,
    private service: ListagemDeducoesComprasService,
    r: Router
  ) {
    this.rota = r;
    this.perfil = this.autenticado.perfil;
    this.nome = this.autenticado.nome;



  }

  startedClass = false;
  completedClass = false;
  preventAbuse = false;

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//

  compras: Array<listagemdeducaomodel>;
  qto: number;
  cols: any[];


  GetLista() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get<listagemdeducaomodel[]>(this.urldeducao + 'GETListagemComprasUsuario/?cpf=' + this.autenticado.cpf + '&ministerio=' + this.autenticado.ministerio_custo, httpOptions).subscribe(
      (success) => {
        this.compras = success;
        this.GetListaTotal();
        this.GetListaTotalMesAtual();
        this.GetListaTotalMesAanterior();
        this.GetListaTotalAnoAtual();
        this.GetListaTotalReembolso();
      }
    )
  }

  GetListaTotal() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
    this.http.get<any>(this.urldeducao + 'GETTotalDeducaoUsuario/?cpf=' + this.autenticado.cpf + '&ministerio=' + this.autenticado.ministerio_custo, httpOptions).subscribe(
      dados => this.qto = dados
    )
  }

  GetListaTotalReembolso() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
    this.http.get<any>(this.urldeducao + 'GETTotalReembolso/?cpf=' + this.autenticado.cpf + '&ministerio=' + this.autenticado.ministerio_custo, httpOptions).subscribe(
      dados => this.valortotalreembolso = dados
    )
  }

  GetListaTotalMesAtual() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
    this.http.get<any>(this.urldeducao + 'GETTotalMesAtual/?cpf=' + this.autenticado.cpf + '&ministerio=' + this.autenticado.ministerio_custo, httpOptions).subscribe(
      dados => this.valortotalmesatual = dados
    )
  }

  GetListaTotalAnoAtual() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
    this.http.get<any>(this.urldeducao + 'GETTotalAnoAtual/?cpf=' + this.autenticado.cpf + '&ministerio=' + this.autenticado.ministerio_custo, httpOptions).subscribe(
      dados => this.valortotalanual = dados
    )
  }

  GetListaTotalMesAanterior() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
    this.http.get<any>(this.urldeducao + 'GETTotalMesAnterior/?cpf=' + this.autenticado.cpf + '&ministerio=' + this.autenticado.ministerio_custo, httpOptions).subscribe(
      dados => this.valortotalmespassado = dados
    )
  }

  GetListaTotalQuantidadeDez(pagina: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get<any>(this.urldeducao + 'GetListaDeducaoConsultaLimite/?parametro=' + this.autenticado.cpf + '&ministerio=' + this.autenticado.ministerio_custo + '&pagina=' + pagina, httpOptions).subscribe(
      (success) => {
        this.compras = success;
        this.GetListaTotal();
      }
    )
  }

  ngOnInit() {
    if (this.autenticado.token == null) {
      this.autenticado.ResetDados();
      this.alert.AlertShowDanger('Usuário não autenticado, redirecionando...')
      this.rota.navigate(['/login']);
    } else {
      if (this.autenticado.ValidarToken() != true) {
        this.autenticado.ResetDados();
        this.alert.AlertShowDanger('Token Invalido!')
        this.rota.navigate(['/login']);
      }
    }
    this.primengConfig.ripple = true;
    this.filtro_cabecalho = "Listagem de Compras Cadastradas "
    this.explicacao = "Aqui você está visualizando todas as compras realizadas referentes ao seu ministério."


    this.GetListaTotal();
    this.GetLista();
    this.StopProgressBar();

    this.cols = [
      { field: 'Data Compra', header: 'Data Compra' },
      { field: 'Valor', header: 'Valor' },
      { field: 'Descrição', header: 'Descrição' },
      { field: 'Ministério', header: 'Ministério' },
      { field: 'Data Cadastro', header: 'Data Cadastro' },
      { field: 'Solicitado Reembolso', header: 'Solicitado Reembolso' },
      { field: 'Status Reembolso', header: 'Status Reembolso' },
      { field: 'Excluir', header: 'Excluir' }
    ];
  }

  SetDados(event) {
    this.iddeducaocompra = event.currentTarget.value;

    this.service.ExcluirLancamento(this.iddeducaocompra).subscribe(
      (sucess) => {
        this.alert.AlertShowSucess('Lançamento Removido com sucesso!')
        this.GetLista();

      },
      (error) => {
        this.alert.AlertShowDanger('Lançamento não pode ser Removido [Motivo: Lançamento anterior a data atual, caso necessite exclusão informe ao financeiro]')
      }
    )
  }


  Paginate(event) {
    if (event.page == 0) {
      this.x = 1;
      this.y = 10;
    } else {

      if (event.page * 10 > this.qto) {
        this.x = this.qto;
        this.y = this.qto;
      } else {
        this.x = event.page * 10;
        if (this.x + 10 > this.qto) {
          this.y = this.qto;
        } else {
          this.y = this.x + 10;
        }
      }
    }
    this.pagina_atual = event.page;

    this.GetListaTotalQuantidadeDez(this.pagina_atual);
  }



  Consulta() {
    var texto = (<HTMLInputElement>document.getElementById("texto_consulta")).value;
    if (texto != "") {
      this.textoconsulta = texto;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
        })
      }
      this.http.get<any>(this.urldeducao + 'GETConsulta/?cpf=' + this.autenticado.cpf + '&ministerio=' + this.autenticado.ministerio_custo + '&consulta=' + texto, httpOptions).subscribe(
        (success) => {
          this.compras = success;
          this.qto = success.length

          this.x = 1;
          this.y = success.length;

        }
      )
    } else {
      this.textoconsulta = "";
      this.GetLista();
    }
  }


}
