<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>


<div class="col-md-12" style="margin-top: 7px;">

    <div>
        <h4 style="text-align: center;">
            <i class="fas fa-signal"></i>&nbsp;<b>{{filtro_cabecalho}}</b>
        </h4>
        <p>
            &nbsp;&nbsp;&nbsp;&nbsp;{{explicacao}}
            <br>
            &nbsp;&nbsp;&nbsp;&nbsp;<b>#FicaaDica: </b>Em caso de Celular melhor visualizado na horizontal!
        </p>
    </div>
</div>
<br>



<div style="font-size: medium;">
    Selecione o Culto:
    <select class="custom-select" type="text" name="tipo_culto" id="tipo_culto" (change)="ValorCulto($event)">
        <option *ngFor="let culto of cultos" [ngValue]="culto.congresso">
            {{ culto.congresso}}
        </option>
    </select>
</div>
<br>
<label>Total de Cadastros: </label><b style="margin-left: 7px;">{{total_registros}}</b><br>
<label>Total de Presentes: </label><b style="margin-left: 7px;">{{total_presentes}}</b><br>
<label>Total de Ausentes: </label><b style="margin-left: 7px;">{{total_ausentes}}</b><br>
<br>
<button pButton pRipple type="button" icon="pi pi-cog" class="p-button-rounded" title="Gerar Analise"
    (click)="Consultar()" data-dismiss="modal"></button>


<!--Botao Retorno-->
<button pButton pRipple type="button" icon="pi pi-sign-in" title="Retornar" class="p-button-rounded p-button-danger"
    routerLink="/dashboard/resumo" style="margin-left: 10px;"></button>
<br>
<br>