import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { Parametros } from 'src/app/shared/parametros.enum';
import{ConfirmarDocumentoSeminarioInfantil} from './ConfirmarDocumentoSeminarioInfantil';
import{ConfirmarDocumentoSeminarioInfantilService} from './confirmar-documento-seminario-infantil.service';
@Component({
  selector: 'app-checkout-documento-seminario-infantil',
  templateUrl: './checkout-documento-seminario-infantil.component.html',
  styleUrls: ['./checkout-documento-seminario-infantil.component.css']
})
export class CheckoutDocumentoSeminarioInfantilComponent implements OnInit {

 
  formculto: FormGroup;
  submitted = false;
  cadastroculto: ConfirmarDocumentoSeminarioInfantil;


  startedClass = false;
  completedClass = false;
  preventAbuse = false;
  rota: Router;

  registro: string = '';
  status: string = '';
  user: string = '';
  hash: string = '';
  nome: string = '';
  data: string = '';
  hora: string = '';
  isDone = '';

  private urlcadastro = Parametros.SEMINARIOINFANTIL;


  listapara: any = [
    'Buscando meu amigo Espírito Santo',
    'Festinha da Morada Kids'
  ];

  constructor(
    private formBuilder: FormBuilder,
    private alert: AlertModalService,
    private service: ConfirmarDocumentoSeminarioInfantilService,
    private router: Router
  ) {
    this.rota = this.router;
  }

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//

  criarform() {
    this.formculto = this.formBuilder.group({
      c: [Validators.required],
      numero: [Validators.required],
      documento: ['', [Validators.required, Validators.maxLength(14), Validators.minLength(11)]]
    });
  }



  //BOTAO SALVAR
  //====================================================================//
  onSubmit() {
    this.submitted = true;

    if (this.formculto.controls['documento'].value.length < 8) {
      this.alert.AlertShowDanger('DOCUMENTO INVÁLIDO');
      document.getElementById('cpf').focus();
      this.formculto.controls['cpf'].setValue("");
      return;
    }

    if (this.formculto.controls['numero'].value.length < 1) {
      this.alert.AlertShowDanger('INSCRIÇÃO INVÁLIDA');
      document.getElementById('numero').focus();
      this.formculto.controls['numero'].setValue("");
      return;
    }


    if (this.formculto.invalid) {
      this.alert.AlertShowDanger('Favor verificar os campos em destaque!');
      return;
    } else {
      var x = this.formculto.controls['c'].value;

      if (x == "" || x == "null") {
        this.alert.AlertShowDanger('Favor Preencher o Evento / Seminario!');
        return;
      }
      this.service.ConfirmarCadastro(this.formculto.controls['documento'].value, this.formculto.controls['c'].value,this.formculto.controls['numero'].value).subscribe(
        (success) => {
          this.cadastroculto = new ConfirmarDocumentoSeminarioInfantil();
          document.getElementById('documento').focus();
          this.user = success[0].responsavel;
          this.isDone = 'autorizado';
          this.registro = success[0].hash;
          this.formculto.get("c").patchValue(x);
          this.formculto.get("documento").patchValue("");
        },
        (error) => {
          if (error.status == "400") {
            this.StopProgressBar();
            this.user = "REGISTRO NÃO EXISTE EM NOSSA BASE";
            this.isDone = 'nao_encontrado';
            this.registro = "DOCUMENTO NÃO ENCONTRADO EM NOSSA BASE";
            this.formculto.get("c").patchValue(x);
            this.formculto.get("documento").patchValue("");
            return;
          } else {
            this.StopProgressBar();
            this.user = "REGISTRO JÁ FOI UTILIZADO NA ENTRADA";
            this.isDone = 'usado';
            this.data = error.error[0].data_confirmado;
            this.hora = error.error[0].hora_confirmado;
            this.registro = "DOCUMENTO JÁ FOI UTILIZADO ANTERIORMENTE";
            this.formculto.get("documento").patchValue("");
            this.formculto.get("c").patchValue(x);
            return;
          }
        }
      );
    }
    //====================================================================//
  }
  //====================================================================//



  // Pegar nome dos campos,
  //====================================================================//
  get f() {
    return this.formculto.controls;
  }

  //Inicio
  //====================================================================//
  ngOnInit() {
    this.cadastroculto = new ConfirmarDocumentoSeminarioInfantil();
    this.criarform();
    document.getElementById('c').focus();
  }



}