<div class="col-md-12" style="padding-top: 20px;">

    

<div class="col-md-12">
    <div class="row" style="padding-left: 120px; padding-right: 120px; padding-top: 20px;">
        <div class="card col-md" style="padding: 0%; padding-top: 1px;">
            <img src="../../assets/img/instagram.jpg" class="card-img-top " alt="..." height="150px"style="padding-left: 120px; padding-right: 120px; padding-top: 20px;">
            <div class="card-body">
                <h5 class="card-title">Instagram</h5>

                <p style="text-align: justify;">&nbsp;&nbsp;&nbsp;Veja a captura realizada por nossas lentes e
                    compartilhada em nossa rede, aproveitando inscreva-se,curta,compartilhe e ative o sininho para
                    receber todas nossas notificações!.</p>
                <br>

                <a target="_blank " href="https://instagram.com/moradajfoficial?igshid=byp8397btzog" title="Instagram "
                    class="btn btn-primary " style="align-items: center;">Acessar</a>
            </div>
        </div>
        <!--div class="card col-md" style="padding: 0%; padding-top: 1px;">
            <img src="../../assets/img/facebook.jpg" class="card-img-top" alt="..." height="150px">
            <div class="card-body">
                <h5 class="card-title">Facebook</h5>
                <p style="text-align: justify;">&nbsp;&nbsp;&nbsp;Inscreva-se,curta,compartilhe nossa rede social do
                    facebook e fique por dentro de todas as notificações, você não vai ficar de fora né!</p>
                <br>

                <br>
                <a target="_blank " href="https://www.facebook.com/igrejapentecostalMoradadeDeus" title="Facebook "
                    class="btn btn-primary " style="align-items: center;">Acessar</a>
            </div>
        </div-->
        <div class=" espacamento" style="width: 5rem;">


        </div>
    </div>

</div>
<div class="col-md" style="padding-top: 5px; padding-bottom: 10px;">
    <button pButton pRipple type="button" icon="pi pi-home" class="p-button-rounded p-button-help" title="::HOME::"
        routerLink=""></button>
</div>
