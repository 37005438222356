<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>
<h5 class="espacamentotopo container">
    <i class="fas fa-user-edit" style="margin-left: 5px;"></i>Inscrição Batismo nas Águas
</h5>
<label class="j container">
    Seja bem-vindo(a)!<br />
    &nbsp;&nbsp;&nbsp;&nbsp;Você está na área de cadastro para cadastro Batismo nas Águas.<br>
    &nbsp;&nbsp;&nbsp;&nbsp;Ressaltamos que todos os dados aqui informados são tratados somente por nossa equipe de
    gestão não sendo compartilhados com terceiros.
</label>
<label class="j" style="margin-left: 45px; color: red; font-weight: bold;">
    ATENÇÃO!<br>
    - Informe os dados corretamente.<br>
    - Um contato será realizado com os dados enviados.<br>
    - Quaisquer dúvidas ou esclarecimentos por favor procure a gestão de nossa Igreja.<br>
</label>
<br>
<label class="j">
    &nbsp;&nbsp;&nbsp;&nbsp;Lembramos que os campos com (<strong class="cor"> *</strong> ) são campos de
    preenchimento obrigatório!<BR>
</label><br>
<hr class="espacamentotopo bold" />
<form [formGroup]="formculto" class="m" disabled="">
    <div class="row">


        <!---INCIO PRIMEIRA LINHA-->
        <div class="col-md-2">
            <div class="form-group">
                <label>CPF / RG:</label><strong class="cor">*</strong>
                <input type="tel" class="form-control" id="documento" placeholder="informe o documento"
                    mask="000.000.000-00||00.000.000" name="documento" maxlength="14" minlength="8" autocomplete="off"
                    formControlName="documento" (blur)="consultacpf(cadastroculto.documento)"
                    [(ngModel)]="cadastroculto.documento"
                    [ngClass]="{ 'is-invalid': submitted && f.documento.errors }" />
                <div *ngIf="submitted && f.documento.errors" class="invalid-feedback">
                    <div *ngIf="f.documento.errors.required">Preenchimento obrigatório</div>
                </div>
            </div>
        </div>

        <div class="col-md-8">
            <div class="form-group">
                <label>Nome Completo:</label><strong class="cor">*</strong>
                <input type="text" class="form-control" id="nome" name="nome" [(ngModel)]="cadastroculto.nome"
                    placeholder="Informe Nome Completo" formControlName="nome" maxlength="500" minlength="3"
                    [(ngModel)]="cadastroculto.nome" autocomplete="off"
                    [ngClass]="{ 'is-invalid': submitted && f.nome.errors }" />
                <div *ngIf="submitted && f.nome.errors" class="invalid-feedback">
                    <div *ngIf="f.nome.errors.required">Preenchimento obrigatório</div>
                </div>
            </div>
        </div>

        <div class="col-md-2">
            <div class="dropdown">
                <label>Sexo:</label><strong class="cor">*</strong>
                <div class="mb-3">
                    <select class="custom-select" type="text" name="sexo" id="sexo" [(ngModel)]="cadastroculto.sexo"
                        formControlName="sexo" [ngClass]="{ 'is-invalid': submitted && f.sexo.errors }">
                        <div *ngIf="submitted && f.sexo.errors" class="invalid-feedback"></div>
                        <option value=""></option>
                        <option *ngFor="let culto of sexos" [ngValue]="culto">
                            {{ culto }}
                        </option>
                    </select>
                    <div *ngIf="submitted && f.sexo.errors" class="invalid-feedback">
                        <div *ngIf="f.sexo.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!---FIM PRIMEIRA LINHA-->

    <!---INICIO SEGUNDA LINHA-->
    <div class="row">
        <div class="col-md-3">
            <label>Telefone/ WhatsApp:</label><strong class="cor">*</strong>
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1"><i class="fas fa-phone"></i></span>
                </div>
                <input type="tel" class="form-control" id="telefone" name="telefone"
                    [(ngModel)]="cadastroculto.telefone" placeholder="Informe o Telefone"
                    title="Formato (DD) 0000-0000 ou (DD) 00000-0000" formControlName="telefone" maxlength="500"
                    minlength="3" autocomplete="off" mask="(00) 0000-0000 || (00) 00000-0000" [ngClass]="{
                  'is-invalid': submitted && f.telefone.errors
                }" />
                <div *ngIf="submitted && f.telefone.errors" class="invalid-feedback">
                    <div *ngIf="f.telefone.errors.required">
                        Preenchimento obrigatório
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-7">
            <label>E-mail:</label>
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1"><i class="fas fa-at"></i></span>
                </div>
                <input type="email" class="form-control" id="email" placeholder="Informe E-mail" name="email"
                    formControlName="email" [(ngModel)]="cadastroculto.email" maxlength="500" minlength="3"
                    autocomplete="off" [ngClass]="{'is-invalid': submitted && f.email.errors}" />
            </div>

        </div>
        <div class="col-md-2">
            <div class="form-group">
                <label>Data Nascimento:</label><strong class="cor">*</strong>
                <input type="date" class="form-control" id="dt_nascimento" placeholder="Data Nascimento"
                    name="dt_nascimento" maxlength="10" minlength="10" autocomplete="off"
                    formControlName="dt_nascimento" [(ngModel)]="cadastroculto.dt_nascimento"
                    [ngClass]="{ 'is-invalid': submitted && f.dt_nascimento.errors }" />
                <div *ngIf="submitted && f.dt_nascimento.errors" class="invalid-feedback">
                    <div *ngIf="f.dt_nascimento.errors.required">
                        Preenchimento obrigatório
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md">
            <div class="form-group">
                <label style="padding: 0%;">Observações/Dúvidas?:</label><br> <span
                    style="font-size: small; padding: 0%; color: red;">
                    (
                    Favor preencher somente em caso de dúvidas )</span>
                <textarea type="text" rows="2" class="form-control" id="duvida" aria-describedby="emailHelp"
                    name="duvida" formControlName="duvida" [(ngModel)]="cadastroculto.duvida" autocomplete="off"
                    maxlength="500" minlength="3"></textarea>

                <span>{{ cadastroculto.duvida.length }}/500</span>
            </div>
        </div>
    </div>


    <!-- Modal -->
    <button id="openModalButton" [hidden]="true" data-toggle="modal" data-target="#myModal"></button>
    <div class="modal fade bd-example-modal-lg" data-backdrop="static" data-keyboard="false" id="myModal" tabindex="-1"
        role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">
                        <i class="fas fa-info-circle" style="color: blue"></i>
                        {{ modaltitulo }}
                    </h5>
                </div>
                <div class="modal-body">
                    <div [innerHTML]="modalconteudo"></div>
                </div>
                <div class="modal-footer">

                    <button pButton pRipple type="button" icon="pi pi-search" title="Recuperar Autenticação"
                        class="p-button-rounded" routerLink="/recuperar-autenticacao" data-dismiss="modal">
                    </button>
                    <button pButton pRipple type="button" icon="pi pi-pencil" class="p-button-rounded p-button-danger"
                        title="Reportar Problema" routerLink="/problema" data-dismiss="modal">
                    </button>
                    <button pButton pRipple type="button" icon="pi pi-refresh" style="margin-right: 5px;"
                        class="p-button-rounded p-button-secondary" title="::LIMPAR::" (click)="reset()"
                        data-dismiss="modal"></button>


                </div>
            </div>
        </div>
    </div>

    <!----->
    <div>
        <br>
        <div>
            <button pButton pRipple type="button" icon="pi pi-save" class="p-button-rounded" style="margin-right: 5px;"
                style="margin: 5px;" title=":: SALVAR ::" label="Registrar" (click)="onSubmit()"></button>
            <button pButton pRipple type="button" icon="pi pi-refresh" style="margin-right: 5px;" style="margin: 5px;"
                class="p-button-rounded p-button-secondary" label="Limpar" title="::LIMPAR::"
                (click)="reset()"></button>
            <button pButton pRipple type="button" icon="pi pi-home" style="margin-right: 5px;" style="margin: 5px;"
                class="p-button-rounded p-button-help" label="Retornar" title="::HOME::" routerLink=""></button>
            <a href="https://api.whatsapp.com/send?l=pt_BR&phone=55032988189440&text=Problemas Cadastro Batismo nas Águas"
                style="margin: 5px;" style="text-align: center; float: right;" title="Fale Conosco"
                target="_blank">Informar Problema!</a>
        </div>
    </div>
</form>