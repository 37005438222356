<div class="row" style="margin: auto; width: 60%;">
    <div class="card col-md" style="padding: 0%; padding-top: 1px;">
        <img src="../../assets/img/encontro-casais.jpg" class="card-img-top " alt="..." height="100px">
        <div class="card-body">
            <h5 class="card-title">Encontro de Casais</h5>

            <label class="card-text" style="text-align: justify;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Você casal não pode
                ficar de fora do curso de casais
                realizado por nossa
                igreja, venha cadastre-se e participe conosco ( Vagas Limitadas ).</label>
            <br>
            <br>
            <br>
            <a pButton pRipple icon="pi pi-plus" type="button" label="SAIBA MAIS" class="p-button-rounded"
               href="https://www.instagram.com/rede.imersao.jf/" target="_blank"></a>

        </div>
    </div>

    <div class="card col-md" style="padding: 0%; padding-top: 1px;">
        <img src="../../assets/img/teologia.png" class="card-img-top" alt="..." height="100px">
        <div class="card-body">
            <h5 class="card-title">Curso Teologia Básico</h5>
            <label class="card-text" style="text-align: justify;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Nossa igreja tem um
                curso Bíblico básico teológico
                para você! Curso
                voltado
                para
                conhecimento da bíblia, estudos e propósitos! Faça cadastro e saiba mais.</label>
<br>
<br>
            <a pButton pRipple icon="pi pi-plus" type="button" label="SAIBA MAIS" class="p-button-rounded"
                routerLink="/"></a>
        </div>
    </div>
    <br>


</div>
