import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { Parametros } from 'src/app/shared/parametros.enum';
import { SharedModule } from 'src/app/shared/shared.module';
import { TestemunhoModel } from '../../testemunho/testemunhomodel';

@Component({
  selector: 'app-testemunho-detalhe',
  templateUrl: './testemunho-detalhe.component.html',
  styleUrls: ['./testemunho-detalhe.component.css']
})
export class TestemunhoDetalheComponent implements OnInit {
  idtestemunho: any;
  rota: any;
  private urltestemunho = Parametros.TESTEMUNHO;
  private urltestemunhotodos = Parametros.RELATORIOSTODOSTESTEMUNHO;

  nome: string = "";
  data_envio: any = "";
  mensagem: string = "";
  tipotestemunho: string = "";
  visualizada: string = "";
  imprimir: any;
  testemunho: Array<TestemunhoModel>;
  mantersigilo: string;
  ocultarnome: string;

  constructor(private http: HttpClient,
    private alert: AlertModalService,
    private autenticado: SharedModule,
    private r: Router) {
    this.rota = r

  }

  startedClass = false;
  completedClass = false;
  preventAbuse = false;

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//

  ngOnInit(): void {
    if (this.autenticado.token == null) {
      this.autenticado.ResetDados();
      this.alert.AlertShowDanger('Usuário não autenticado, redirecionando...')
      this.rota.navigate(['/login']);
    } else {
      if (this.autenticado.ValidarToken() != true) {
        this.autenticado.ResetDados();
        this.alert.AlertShowDanger('Token Invalido!')
        this.rota.navigate(['/login']);
      }
    }
    this.idtestemunho = localStorage.getItem('TestemunhoDetalhe');
    if (this.idtestemunho == null || this.idtestemunho == "") {
      this.rota.navigate(['/dashboard/listagem/lista-testemunho']);
    } else {
      //localStorage.removeItem(' ElogioDetalhe')
      this.GetElogio()
      this.mensagem.replace("\n", "<br>");
    }

  }

  GetElogio() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get<TestemunhoModel>(this.urltestemunho + 'GETTestemunhoLoad/?parametro=' + this.autenticado.cpf + '&idtestemunho=' + this.idtestemunho, httpOptions).subscribe(
      (success) => {
        this.nome = success.nome;
        this.data_envio = success.dt_envio.replace("T00:00:00", "");
        this.mensagem = (success.mensagem).replace("\n", "<br>");
        this.tipotestemunho = success.tipo_testemunho;
        this.mantersigilo = success.manter_sigilo.replace("T", "SIM").replace("F", "NÃO");
        this.ocultarnome=success.ocultar_nome.replace("T", "SIM").replace("F", "NÃO");
        this.visualizada = success.visualizada.replace("T", "SIM").replace("F", "NÃO");
        this.mensagem.replace("\n", "<br>");
      },
      (error) => {
        this.rota.navigate(['/dashboard/listagem/lista-testemunho']);
      }
    )
  }

  ImprimirTestemunho(event) {
    this.imprimir = event.currentTarget.value;
    this.GerarPDF(this.imprimir);
  }

  GerarPDF(idelogio: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token
      })
    }
    this.http.get(this.urltestemunhotodos + 'GetRelatorioTodosTestemunhoDetalhado/?parametro=' + this.autenticado.cpf + '&id=' + this.imprimir, httpOptions).subscribe(
      (success) => {
        const linkSource = 'data:application/pdf;base64,' + success;
        const downloadLink = document.createElement("a");
        const fileName = "IEPMD_Testemunho_" + (this.autenticado.nome).replace(" ", "") + ".pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }, (error) => {
        if (error.status == '403') {
          this.alert.AlertShowDanger('Erro ao tentar Gerar PDF');
        } else {
          const linkSource = 'data:application/pdf;base64,' + error.error.text;
          const downloadLink = document.createElement("a");
          const fileName = "IEPMD_Testemunho_" + (this.autenticado.nome).replace(" ", "") + ".pdf";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      }
    );
  }

}
