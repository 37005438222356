import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { take } from 'rxjs/operators';
import { Parametros } from '../shared/parametros.enum';
import { UsuarioModel } from '../Recuperacoes/recuperar-autenticacao/usuario-model';

@Injectable({
  providedIn: 'root'
})
export class PaginasRetornoService {

  private urlcadastro = Parametros.CADASTROUSUARIO;
  constructor(private http: HttpClient) { }


  //Metdo para fazer login e retornar dados
  GetRecuperarUsuarioHash(hash) {
    return this.http.get<any>(this.urlcadastro + 'GETRecuperarUsuarioHash/?hash=' + hash).pipe(take(1));
  }
}

