<div style="margin-top: 5%;"></div>
<h4 class="espacamentotopo">
    Registrar Entrada Seminario
</h4>
<label class="j">
    &nbsp;&nbsp;&nbsp;&nbsp; Favor Informar o RG ou CPF utilizado no cadastro do culto presencial</label>

<hr class="espacamentotopo bold" />
<form [formGroup]="formculto" class="m" disabled="" style="margin-left: 2%;">
    <div class="row">
        <div class="col-md-4">
            <label>EVENTO / SEMINARIO:</label><strong class="cor">*</strong>
            <select class="custom-select" type="text" (change)="SetPara($event)" name="c" id="c"
                [(ngModel)]="cadastroculto.c" formControlName="c"
                [ngClass]="{ 'is-invalid': submitted && f.c.errors }">
                <div *ngIf="submitted && f.c.errors" class="invalid-feedback"></div>
                <option value=""></option>
                <option *ngFor="let city of listapara" [ngValue]="city">
                    {{ city }}
                </option>
            </select>
            <div *ngIf="submitted && f.c.errors" class="invalid-feedback">
                <div *ngIf="f.c.errors.required">Preenchimento obrigatório</div>
            </div>
        </div>
    </div>
<br>
    <div class="row">
        <div class="col-md-4">
            <div class="form-group">
                <label>CPF / RG:</label><strong class="cor">*</strong>
                <input type="tel" class="form-control" id="documento" placeholder="informe o documento"
                    mask="000.000.000-00||00.000.000" name="documento" maxlength="14" minlength="8" autocomplete="off"
                    formControlName="documento" [(ngModel)]="cadastroculto.documento"
                    [ngClass]="{ 'is-invalid': submitted && f.documento.errors }" style="font-size: larger;" />
                <div *ngIf="submitted && f.documento.errors" class="invalid-feedback">
                    <div *ngIf="f.documento.errors.required">Preenchimento obrigatório</div>
                </div>
                <br>
                <button pButton pRipple type="button" icon="pi pi-save" class="p-button-rounded"
            style=" font-size: larger; margin-right: 5px; align-self: center; text-align: center;" title=":: SALVAR ::"
            (click)="onSubmit()"></button>
            </div>
        </div>
    </div>

    <div *ngIf="isDone==''">
        <label><b>Nome:</b></label><br>
        <label><b>Registro:</b></label><br><br>
        <label><b style="font-size: large;">Status:</b></label>
    </div>
    <br>

    <div *ngIf="isDone=='autorizado'">
        <label><b style="color: green;">Nome: {{user}}</b></label><br>
        <label><b style="color: green;">Registro: {{registro}}</b></label><br>
        <label><b style="color: green;font-size: large;">STATUS: ENTRADA AUTORIZADA</b></label>
    </div>

    <!-- Negation operator-->
    <div *ngIf="isDone=='usado'">

        <label><b style="color: red;font-size: large;">STATUS:</b><b style="color: rgb(85, 14, 14);font-size: large;"> ENTRADA JÁ
                REALIZADA ANTERIORMENTE</b></label><br>
        <label><b style="color: red;font-size: large;">DATA/HORA: </b>
            <b style="color: rgb(85, 14, 14);font-size: large;">{{data}}-{{hora}}</b></label><br>

    </div>

    <div *ngIf="isDone=='nao_encontrado'">
        <label><b style="color: red;font-size: large;">STATUS: DOCUMENTO NÃO EXISTE NA BASE DE DADOS</b></label>
    </div>
    <div>
        <br>
      
        
    </div>
</form>