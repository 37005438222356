<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>
<div class="border wrapper">
    <h4 class="espacamentotopo">
        <img src="../../assets/img/cadastro.jpeg" style="width: 40px" /> Cadastro
        Encontro de Mulheres
    </h4>
    <label class="j">
        Seja bem-vindo(a)!<br />
        &nbsp;&nbsp;&nbsp;&nbsp; Você está na área de cadastro para o encontro de mulheres de nossa igreja, Informamos que
        todos os dados aqui informados são tratados somente por nossa equipe de
        gestão não sendo compartilhados com terceiros e sendo de uso para controle e contato por nossa equipe
        organizadora!
    </label>
    <label class="j">
        Lembramos que os campos com (<strong class="cor"> *</strong> ) são campos de
        preenchimento obrigatório!
    </label>
    <hr class="espacamentotopo bold" />
    <form [formGroup]="formcadastro" disabled="">
        <div class="row">

            <div class="col-md-2">
                <div class="dropdown">
                    <label>Tipo Documento:</label><strong class="cor">*</strong>
                    <div class="mb-3">
                        <select class="custom-select" type="text" name="tipo_documento" id="tipo_documento"
                            [(ngModel)]="cadastroencontro.tipo_documento" formControlName="tipo_documento"
                            (change)="Tipo($event)" [ngClass]="{ 'is-invalid': submitted && f.tipo_documento.errors }">
                            <div *ngIf="submitted && f.tipo_documento.errors" class="invalid-feedback"></div>
                            <option value=""></option>
                            <option *ngFor="let documento of documentos" [ngValue]="documento">
                                {{ documento }}
                            </option>
                        </select>
                        <div *ngIf="submitted && f.tipo_documento.errors" class="invalid-feedback">
                            <div *ngIf="f.tipo_documento.errors.required">
                                Preenchimento obrigatório
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!---INCIO PRIMEIRA LINHA-->
            <div class="col-md-2">
                <div class="form-group">
                    <label>CPF / RG:</label><strong class="cor">*</strong>
                    <input type="text" disabled="true" class="form-control" id="cpf" placeholder="informe o CPF"
                        mask="000.000.000-00||00.000.000" name="cpf" maxlength="14" minlength="8" autocomplete="off"
                        formControlName="cpf" (blur)="consultacpf(cadastroencontro.cpf)"
                        [(ngModel)]="cadastroencontro.cpf" [ngClass]="{ 'is-invalid': submitted && f.cpf.errors }" />
                    <div *ngIf="submitted && f.cpf.errors" class="invalid-feedback">
                        <div *ngIf="f.cpf.errors.required">Preenchimento obrigatório</div>
                    </div>
                </div>
            </div>

            <div class="col-md-5">
                <div class="form-group">
                    <label>Nome Completo:</label><strong class="cor">*</strong>
                    <input type="text" class="form-control" id="nome" name="nome" [(ngModel)]="cadastroencontro.nome"
                        placeholder="Informe Nome Completo" formControlName="nome" maxlength="500" minlength="3"
                        [(ngModel)]="cadastroencontro.nome" autocomplete="off"
                        [ngClass]="{ 'is-invalid': submitted && f.nome.errors }" />
                    <div *ngIf="submitted && f.nome.errors" class="invalid-feedback">
                        <div *ngIf="f.nome.errors.required">Preenchimento obrigatório</div>
                    </div>
                </div>
            </div>

            <div class="col-md-3">
                <label>Telefone/Celular::</label><strong class="cor">*</strong>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1"><i class="fas fa-phone"></i></span>
                    </div>
                    <input type="text" class="form-control" id="telefone" name="telefone"
                        [(ngModel)]="cadastroencontro.telefone" placeholder="Informe o Telefone"
                        title="Formato (DD) 0000-0000 ou (DD) 00000-0000" formControlName="telefone" maxlength="500"
                        minlength="3" autocomplete="off" mask="(00) 0000-0000 || (00) 00000-0000" [ngClass]="{
                  'is-invalid': submitted && f.telefone.errors
                }" />
                    <div *ngIf="submitted && f.telefone.errors" class="invalid-feedback">
                        <div *ngIf="f.telefone.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!---FIM PRIMEIRA LINHA-->

        <div class="row">
            <div class="col-md-3">
                <label>Telefone/Celular:</label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1"><i class="fas fa-phone"></i></span>
                    </div>
                    <input type="text" class="form-control" id="celular" name="celular"
                        [(ngModel)]="cadastroencontro.celular" placeholder="Informe o Telefone"
                        title="Formato (DD) 0000-0000 ou (DD) 00000-0000" formControlName="celular" maxlength="500"
                        minlength="3" autocomplete="off" mask="(00) 0000-0000 || (00) 00000-0000" />

                </div>
            </div>
            <div class="col-md-2">
                <div class="dropdown">
                    <label>Estado Civil:</label><strong class="cor">*</strong>
                    <div class="mb-3">
                        <select class="custom-select" type="text" (change)="changeCitys($event)" name="estado_civil"
                            id="estado_civil" [(ngModel)]="cadastroencontro.estado_civil" formControlName="estado_civil"
                            [ngClass]="{ 'is-invalid': submitted && f.estado_civil.errors }">
                            <div *ngIf="submitted && f.estado_civil.errors" class="invalid-feedback"></div>
                            <option value=""></option>
                            <option *ngFor="let city of lcivil" [ngValue]="city">
                                {{ city }}
                            </option>
                        </select>
                        <div *ngIf="submitted && f.estado_civil.errors" class="invalid-feedback">
                            <div *ngIf="f.estado_civil.errors.required">
                                Preenchimento obrigatório
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group">
                    <label>Data Nascimento:</label><strong class="cor">*</strong>
                    <input type="date" class="form-control" id="dt_nascimento" placeholder="Data Nascimento"
                        name="dt_nascimento" maxlength="10" minlength="10" autocomplete="off"
                        formControlName="dt_nascimento" [(ngModel)]="cadastroencontro.dt_nascimento"
                        [ngClass]="{ 'is-invalid': submitted && f.dt_nascimento.errors }" />
                    <div *ngIf="submitted && f.dt_nascimento.errors" class="invalid-feedback">
                        <div *ngIf="f.dt_nascimento.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-5">
                <label>E-mail:</label>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1"><i class="fas fa-at"></i></span>
                    </div>
                    <input type="email" class="form-control" id="email" placeholder="Informe E-mail" name="email"
                        formControlName="email" [(ngModel)]="cadastroencontro.email" maxlength="500" minlength="3"
                        autocomplete="off" />
                </div>
            </div>
        </div>


        <div class="row">
            <div class="col-md-2">
                <div class="form-group">
                    <label>Cep:</label>
                    <input type="text" class="form-control" placeholder="Informe o CEP" id="cep" mask="00.000-000"
                        name="cep" formControlName="cep" (blur)="consulta_cep(cadastroencontro.cep)"
                        [(ngModel)]="cadastroencontro.cep" maxlength="500" minlength="3" />
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>Logradouro:</label><strong class="cor">*</strong>
                    <input type="text" class="form-control" id="logradouro" placeholder="Informe Logradouro"
                        name="logradouro" formControlName="logradouro" [(ngModel)]="cadastroencontro.logradouro"
                        maxlength="500" minlength="3" [ngClass]="{ 'is-invalid': submitted && f.logradouro.errors }" />
                    <div *ngIf="submitted && f.logradouro.errors" class="invalid-feedback">
                        <div *ngIf="f.logradouro.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group">
                    <label>Numero:</label><strong class="cor">*</strong>
                    <input type="number" class="form-control" id="numero" placeholder="Numero" min="1" name="numero"
                        formControlName="numero" [(ngModel)]="cadastroencontro.numero" maxlength="9" minlength="1"
                        [ngClass]="{ 'is-invalid': submitted && f.numero.errors }" />
                    <div *ngIf="submitted && f.numero.errors" class="invalid-feedback">
                        <div *ngIf="f.numero.errors.required">
                            Preenchimento obrigatório
                        </div>
                        <div *ngIf="f.numero.errors.required">Menor valor 0</div>
                    </div>
                </div>
            </div>

            <div class="col-md-2">
                <div class="form-group">
                    <label>apto:</label>
                    <input type="number" class="form-control" id="apto" placeholder="apto" min="1" name="apto"
                        formControlName="apto" [(ngModel)]="cadastroencontro.apto" maxlength="9" minlength="1"
                           [ngClass]="{ 'is-invalid': submitted && f.apto.errors }" />
                  <div *ngIf="submitted && f.apto.errors" class="invalid-feedback">
                    <div *ngIf="f.apto.errors.required">
                      Preenchimento obrigatório
                    </div>
                    <div *ngIf="f.apto.errors.required">Menor valor 0</div>
                  </div>
                </div>
            </div>
        </div>
        <!----->
        <!----->
        <div class="row">
            <div class="col-md-5">
                <div class="form-group">
                    <label>Bairro:</label><strong class="cor">*</strong>
                    <input type="text" class="form-control" id="bairro" placeholder="Informe o Bairro" name="bairro"
                        formControlName="bairro" [(ngModel)]="cadastroencontro.bairro" maxlength="500" minlength="3"
                        [ngClass]="{ 'is-invalid': submitted && f.bairro.errors }" />
                    <div *ngIf="submitted && f.bairro.errors" class="invalid-feedback">
                        <div *ngIf="f.bairro.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="dropdown">
                    <label>UF:</label><strong class="cor">*</strong>
                    <div class="mb-3">
                        <select class="custom-select" type="text" (change)="changeCity($event)" name="uf" id="uf"
                            [(ngModel)]="cadastroencontro.uf" formControlName="uf"
                            [ngClass]="{ 'is-invalid': submitted && f.uf.errors }">
                            <div *ngIf="submitted && f.uf.errors" class="invalid-feedback"></div>
                            <option value=""></option>
                            <option *ngFor="let city of luf" [ngValue]="city">
                                {{ city }}
                            </option>
                        </select>
                        <div *ngIf="submitted && f.uf.errors" class="invalid-feedback">
                            <div *ngIf="f.uf.errors.required">Preenchimento obrigatório</div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-5">
                <div class="form-group">
                    <label>Cidade:</label><strong class="cor">*</strong>
                    <input type="text" class="form-control" id="cidade" placeholder="Informe sua cidade" name="cidade"
                        formControlName="cidade" [(ngModel)]="cadastroencontro.cidade"
                        [ngClass]="{ 'is-invalid': submitted && f.cidade.errors }" maxlength="500" minlength="3" />
                    <div *ngIf="submitted && f.cidade.errors" class="invalid-feedback">
                        <div *ngIf="f.cidade.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>
            </div>
        </div>















        <div class="row">
            <div class="col-md-3">
                <div class="dropdown">
                    <label>Como ficou sabendo?:</label><strong class="cor">*</strong>
                    <div class="mb-3">
                        <select class="custom-select" type="text" name="ficou_sabendo" id="ficou_sabendo"
                            [(ngModel)]="cadastroencontro.ficou_sabendo" formControlName="ficou_sabendo"
                            [ngClass]="{ 'is-invalid': submitted && f.ficou_sabendo.errors }">
                            <div *ngIf="submitted && f.ficou_sabendo.errors" class="invalid-feedback"></div>
                            <option value=""></option>
                            <option *ngFor="let d of ficousabendo" [ngValue]="d">
                                {{ d }}
                            </option>
                        </select>
                        <div *ngIf="submitted && f.ficou_sabendo.errors" class="invalid-feedback">
                            <div *ngIf="f.ficou_sabendo.errors.required">
                                Preenchimento obrigatório
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="dropdown">
                    <label>Batizado nas Águas:</label><strong class="cor">*</strong>
                    <div class="mb-3">
                        <select class="custom-select" type="text" name="batizado" id="batizado"
                            [(ngModel)]="cadastroencontro.batizado" formControlName="batizado"
                            [ngClass]="{ 'is-invalid': submitted && f.batizado.errors }">
                            <div *ngIf="submitted && f.batizado.errors" class="invalid-feedback"></div>
                            <option value=""></option>
                            <option *ngFor="let d of batismo" [ngValue]="d">
                                {{ d }}
                            </option>
                        </select>
                        <div *ngIf="submitted && f.batizado.errors" class="invalid-feedback">
                            <div *ngIf="f.batizado.errors.required">
                                Preenchimento obrigatório
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-2">
                <div class="dropdown">
                    <label>Rede Social:</label><strong class="cor">*</strong>
                    <div class="mb-3">
                        <select class="custom-select" type="text" name="rede_social" id="rede_social"
                            [(ngModel)]="cadastroencontro.rede_social" formControlName="rede_social"
                            (change)="changeredesocial($event)"
                            [ngClass]="{ 'is-invalid': submitted && f.rede_social.errors }">
                            <div *ngIf="submitted && f.rede_social.errors" class="invalid-feedback"></div>
                            <option value=""></option>
                            <option *ngFor="let d of redesocial" [ngValue]="d">
                                {{ d }}
                            </option>
                        </select>
                        <div *ngIf="submitted && f.rede_social.errors" class="invalid-feedback">
                            <div *ngIf="f.rede_social.errors.required">
                                Preenchimento obrigatório
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-5">
                <label>Nome na Rede Social:</label>
                <div class="input-group mb-3" *ngIf="mostrarredesocial =='T' ">
                    <div class="input-group-prepend">
                    </div>
                    <input type="text" class="form-control" id="nome_rede_social"
                        placeholder="Informe seu nome na rede social" name="nome_rede_social"
                        formControlName="nome_rede_social" [(ngModel)]="cadastroencontro.nome_rede_social"
                        maxlength="500" minlength="3" autocomplete="off" />
                </div>
                <div class="input-group mb-3" *ngIf="mostrarredesocial =='F' ">
                    <div class="input-group-prepend">
                    </div>
                    <input type="text" class="form-control" id="nome_rede_social" disabled
                        placeholder="Informe seu nome na rede social" name="nome_rede_social"
                        formControlName="nome_rede_social" [(ngModel)]="cadastroencontro.nome_rede_social"
                        maxlength="500" minlength="3" autocomplete="off" />

                </div>
            </div>


        </div>
        <div class="row">
            <div class="col-md-2">
                <div class="dropdown">
                    <label>Membro da IEPMD:</label><strong class="cor">*</strong>
                    <div class="mb-3">
                        <select class="custom-select" type="text" name="ministerio_morada"
                            (change)="changeministeriomorada($event)" id="ministerio_morada"
                            [(ngModel)]="cadastroencontro.ministerio_morada" formControlName="ministerio_morada"
                            [ngClass]="{ 'is-invalid': submitted && f.ministerio_morada.errors }">
                            <div *ngIf="submitted && f.ministerio_morada.errors" class="invalid-feedback"></div>
                            <option value=""></option>
                            <option *ngFor="let d of membromorada" [ngValue]="d">
                                {{ d }}
                            </option>
                        </select>
                        <div *ngIf="submitted && f.ministerio_morada.errors" class="invalid-feedback">
                            <div *ngIf="f.ministerio_morada.errors.required">
                                Preenchimento obrigatório
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <label>Pertence Qual Ministerio?:</label><strong class="cor">*</strong>

                <div class="input-group mb-3" *ngIf="mostrar =='T' ">
                    <div class="input-group-prepend">
                    </div>
                    <input type="text" class="form-control" id="ministerio_pertence"
                        placeholder="Informe Ministerio Pertence" name="ministerio_pertence"
                        formControlName="ministerio_pertence" [(ngModel)]="cadastroencontro.ministerio_pertence"
                        maxlength="500" minlength="3" autocomplete="off" [ngClass]="{
                                'is-invalid': submitted && f.ministerio_pertence.errors
                              }" />
                    <div *ngIf="submitted && f.ministerio_pertence.errors" class="invalid-feedback">
                        <div *ngIf="f.ministerio_pertence.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>
                <div class="input-group mb-3" *ngIf="mostrar =='F' ">
                    <div class="input-group-prepend">
                    </div>
                    <input type="text" class="form-control" id="ministerio_pertence" disabled
                        placeholder="Informe Ministerio Pertence" name="ministerio_pertence"
                        formControlName="ministerio_pertence" [(ngModel)]="cadastroencontro.ministerio_pertence"
                        maxlength="500" minlength="3" autocomplete="off" [ngClass]="{
                                'is-invalid': submitted && f.ministerio_pertence.errors
                              }" />
                    <div *ngIf="submitted && f.ministerio_pertence.errors" class="invalid-feedback">
                        <div *ngIf="f.ministerio_pertence.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <label>Pastor Ministerio:</label><strong class="cor">*</strong>
                <div class="input-group mb-3" *ngIf="mostrar =='T' ">
                    <div class="input-group-prepend">
                    </div>
                    <input type="text" class="form-control" id="pastor_ministerio" placeholder="Informe nome Pastor"
                        name="pastor_ministerio" formControlName="pastor_ministerio"
                        [(ngModel)]="cadastroencontro.pastor_ministerio" maxlength="500" minlength="3"
                        autocomplete="off" [ngClass]="{
                                'is-invalid': submitted && f.pastor_ministerio.errors
                              }" />
                    <div *ngIf="submitted && f.pastor_ministerio.errors" class="invalid-feedback">
                        <div *ngIf="f.pastor_ministerio.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>
                <div class="input-group mb-3" *ngIf="mostrar =='F' ">
                    <div class="input-group-prepend">
                    </div>
                    <input type="text" class="form-control" id="pastor_ministerio" disabled
                        placeholder="Informe Ministerio Pertence" name="pastor_ministerio"
                        formControlName="pastor_ministerio" [(ngModel)]="cadastroencontro.pastor_ministerio"
                        maxlength="500" minlength="3" autocomplete="off" [ngClass]="{
                                'is-invalid': submitted && f.pastor_ministerio.errors
                              }" />
                    <div *ngIf="submitted && f.pastor_ministerio.errors" class="invalid-feedback">
                        <div *ngIf="f.pastor_ministerio.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="row">
            <div class="col-md-4">
                <label>Convidado por:</label><strong style="font-size: x-small;" class="cor"> (Preencher somente se foi
                    convidado)</strong>
                <div class="input-group mb-3">
                    <input type="convidado_por" class="form-control" id="convidado_por"
                        placeholder="preencher com nome de quem lhe convidou" name="convidado_por"
                        formControlName="convidado_por" [(ngModel)]="cadastroencontro.convidado_por" maxlength="500"
                        minlength="3" autocomplete="off" />
                </div>

            </div>

            <div class="col-md-2">
                <div class="dropdown">
                    <label>Parente participando:</label><strong class="cor">*</strong>
                    <div class="mb-3">
                        <select class="custom-select" type="text" name="parente_participando" id="parente_participando"
                            [(ngModel)]="cadastroencontro.parente_participando" formControlName="parente_participando"
                            (change)="changeparente($event)"
                            [ngClass]="{ 'is-invalid': submitted && f.parente_participando.errors }">
                            <div *ngIf="submitted && f.parente_participando.errors" class="invalid-feedback"></div>
                            <option value=""></option>
                            <option *ngFor="let d of parenteparticipando" [ngValue]="d">
                                {{ d }}
                            </option>
                        </select>
                        <div *ngIf="submitted && f.parente_participando.errors" class="invalid-feedback">
                            <div *ngIf="f.parente_participando.errors.required">
                                Preenchimento obrigatório
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <label>Nome Parente:</label><strong style="font-size: x-small;" class="cor"> (Preencher somente se tem
                    parente passando pelo encontro)</strong>
                <div class="input-group mb-3" *ngIf="mostrarparente =='T' ">
                    <div class="input-group-prepend">
                    </div>
                    <input type="text" class="form-control" id="nome_parente" placeholder="Informe nome do parente"
                        name="nome_parente" formControlName="nome_parente" [(ngModel)]="cadastroencontro.nome_parente"
                        maxlength="500" minlength="3" autocomplete="off" />
                </div>
                <div class="input-group mb-3" *ngIf="mostrarparente =='F' ">
                    <div class="input-group-prepend">
                    </div>
                    <input type="text" class="form-control" id="nome_parente" disabled
                        placeholder="Informe nome do parente" name="nome_parente" formControlName="nome_parente"
                        [(ngModel)]="cadastroencontro.nome_parente" maxlength="500" minlength="3" autocomplete="off" />
                </div>
            </div>
        </div>



        <div class="row">
            <div class="col-md-2">
                <div class="dropdown">
                    <label>Uso de Medicação:</label><strong class="cor">*</strong>
                    <div class="mb-3">
                        <select class="custom-select" type="text" name="uso_medicacao" id="uso_medicacao"
                            [(ngModel)]="cadastroencontro.uso_medicacao" formControlName="uso_medicacao"
                            (change)="changemedicacao($event)"
                            [ngClass]="{ 'is-invalid': submitted && f.uso_medicacao.errors }">
                            <div *ngIf="submitted && f.uso_medicacao.errors" class="invalid-feedback"></div>
                            <option value=""></option>
                            <option *ngFor="let d of usomedicacao" [ngValue]="d">
                                {{ d }}
                            </option>
                        </select>
                        <div *ngIf="submitted && f.uso_medicacao.errors" class="invalid-feedback">
                            <div *ngIf="f.uso_medicacao.errors.required">
                                Preenchimento obrigatório
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-4">
                <label>Nome Medicação:</label>
                <div class="input-group mb-3" *ngIf="mostrarmedicacao =='T' ">
                    <div class="input-group-prepend">
                    </div>
                    <input type="text" class="form-control" id="nome_medicacao" placeholder="Informe nome medicação"
                        name="nome_medicacao" formControlName="nome_medicacao"
                        [(ngModel)]="cadastroencontro.nome_medicacao" maxlength="500" minlength="3"
                        autocomplete="off" />
                </div>
                <div class="input-group mb-3" *ngIf="mostrarmedicacao =='F' ">
                    <div class="input-group-prepend">
                    </div>
                    <input type="text" class="form-control" id="nome_medicacao" disabled
                        placeholder="Informe nome da medicação" name="nome_medicacao" formControlName="nome_medicacao"
                        [(ngModel)]="cadastroencontro.nome_medicacao" maxlength="500" minlength="3"
                        autocomplete="off" />
                </div>
            </div>




            <div class="col-md-2">
                <div class="dropdown">
                    <label>Alergico a Medicações:</label><strong class="cor">*</strong>
                    <div class="mb-3">
                        <select class="custom-select" type="text" name="alergico_medicacao" id="alergico_medicacao"
                            [(ngModel)]="cadastroencontro.alergico_medicacao" formControlName="alergico_medicacao"
                            (change)="changealergicomedicacao($event)"
                            [ngClass]="{ 'is-invalid': submitted && f.alergico_medicacao.errors }">
                            <div *ngIf="submitted && f.alergico_medicacao.errors" class="invalid-feedback"></div>
                            <option value=""></option>
                            <option *ngFor="let d of alergicomedicacao" [ngValue]="d">
                                {{ d }}
                            </option>
                        </select>
                        <div *ngIf="submitted && f.alergico_medicacao.errors" class="invalid-feedback">
                            <div *ngIf="f.alergico_medicacao.errors.required">
                                Preenchimento obrigatório
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-4">
                <label>Nome Medicação:</label>
                <div class="input-group mb-3" *ngIf="mostraralergicomedicacao =='T' ">
                    <div class="input-group-prepend">
                    </div>
                    <input type="text" class="form-control" id="nome_medicacao_alergico"
                        placeholder="Informe nome medicação alergica" name="nome_medicacao_alergico"
                        formControlName="nome_medicacao_alergico" [(ngModel)]="cadastroencontro.nome_medicacao_alergico"
                        maxlength="500" minlength="3" autocomplete="off" />
                </div>
                <div class="input-group mb-3" *ngIf="mostraralergicomedicacao =='F' ">
                    <div class="input-group-prepend">
                    </div>
                    <input type="text" class="form-control" id="nome_medicacao_alergico" disabled
                        placeholder="Informe nome da medicação alergica" name="nome_medicacao_alergico"
                        formControlName="nome_medicacao_alergico" [(ngModel)]="cadastroencontro.nome_medicacao_alergico"
                        maxlength="500" minlength="3" autocomplete="off" />
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-2">
                <div class="dropdown">
                    <label>Alergico a Alimentos:</label><strong class="cor">*</strong>
                    <div class="mb-3">
                        <select class="custom-select" type="text" name="alergico_alimento" id="alergico_alimento"
                            [(ngModel)]="cadastroencontro.alergico_alimento" formControlName="alergico_alimento"
                            (change)="changealimento($event)"
                            [ngClass]="{ 'is-invalid': submitted && f.alergico_alimento.errors }">
                            <div *ngIf="submitted && f.alergico_alimento.errors" class="invalid-feedback"></div>
                            <option value=""></option>
                            <option *ngFor="let d of alergicoalimento" [ngValue]="d">
                                {{ d }}
                            </option>
                        </select>
                        <div *ngIf="submitted && f.alergico_alimento.errors" class="invalid-feedback">
                            <div *ngIf="f.alergico_alimento.errors.required">
                                Preenchimento obrigatório
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div class="col-md-4">
                <label>Nome Alimento:</label>
                <div class="input-group mb-3" *ngIf="mostraralergicoalimento =='T' ">
                    <div class="input-group-prepend">
                    </div>
                    <input type="text" class="form-control" id="nome_alimento_alergico"
                        placeholder="Informe nome alimento" name="nome_alimento_alergico"
                        formControlName="nome_alimento_alergico" [(ngModel)]="cadastroencontro.nome_alimento_alergico"
                        maxlength="500" minlength="3" autocomplete="off" />
                </div>
                <div class="input-group mb-3" *ngIf="mostraralergicoalimento =='F' ">
                    <div class="input-group-prepend">
                    </div>
                    <input type="text" class="form-control" id="nome_alimento_alergico" disabled
                        placeholder="Informe nome da medicação" name="nome_alimento_alergico"
                        formControlName="nomnome_alimento_alergicoe_alimento"
                        [(ngModel)]="cadastroencontro.nome_alimento_alergico" maxlength="500" minlength="3"
                        autocomplete="off" />
                </div>
            </div>

            <div class="col-md-6">
                <label>Observações/Duvidas:</label><strong style="font-size: x-small;" class="cor"> (Preencher em caso
                    de duvidas ou obserações)</strong>
                <div class="input-group mb-3">
                    <input type="observacoes" class="form-control" id="observacoes" placeholder="" name="observacoes"
                        formControlName="observacoes" [(ngModel)]="cadastroencontro.observacoes" maxlength="500"
                        minlength="3" autocomplete="off" />
                </div>

            </div>
        </div>

        <div class="row">
            <div class="col-md-2">
                <div class="form-group">
                    <label>Nome Contato:</label><strong class="cor">*</strong>
                    <input type="text" class="form-control" id="primeiro_contato" name="primeiro_contato"
                        [(ngModel)]="cadastroencontro.primeiro_contato" placeholder="Informe Nome do contato"
                        formControlName="primeiro_contato" maxlength="500" minlength="3"
                        [(ngModel)]="cadastroencontro.primeiro_contato" autocomplete="off"
                        [ngClass]="{ 'is-invalid': submitted && f.primeiro_contato.errors }" />
                    <div *ngIf="submitted && f.primeiro_contato.errors" class="invalid-feedback">
                        <div *ngIf="f.primeiro_contato.errors.required">Preenchimento obrigatório</div>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="dropdown">
                    <label>Grau Parentesco:</label><strong class="cor">*</strong>
                    <div class="mb-2">
                        <select class="custom-select" type="text" name="primeiro_grau_contato"
                            id="primeiro_grau_contato" [(ngModel)]="cadastroencontro.primeiro_grau_contato"
                            formControlName="primeiro_grau_contato"
                            [ngClass]="{ 'is-invalid': submitted && f.primeiro_grau_contato.errors }">
                            <div *ngIf="submitted && f.primeiro_grau_contato.errors" class="invalid-feedback"></div>
                            <option value=""></option>
                            <option *ngFor="let documento of parentesco" [ngValue]="documento">
                                {{ documento }}
                            </option>
                        </select>
                        <div *ngIf="submitted && f.primeiro_grau_contato.errors" class="invalid-feedback">
                            <div *ngIf="f.primeiro_grau_contato.errors.required">
                                Preenchimento obrigatório
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-2">
                <label>Telefone/Celular::</label><strong class="cor">*</strong>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1"><i class="fas fa-phone"></i></span>
                    </div>
                    <input type="text" class="form-control" id="primeiro_telefone_contato"
                        name="primeiro_telefone_contato" [(ngModel)]="cadastroencontro.primeiro_telefone_contato"
                        placeholder="Informe o Telefone" title="Formato (DD) 0000-0000 ou (DD) 00000-0000"
                        formControlName="primeiro_telefone_contato" maxlength="500" minlength="3" autocomplete="off"
                        mask="(00) 0000-0000 || (00) 00000-0000" [ngClass]="{
                  'is-invalid': submitted && f.primeiro_telefone_contato.errors
                }" />
                    <div *ngIf="submitted && f.primeiro_telefone_contato.errors" class="invalid-feedback">
                        <div *ngIf="f.primeiro_telefone_contato.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>
            </div>



            <div class="col-md-2">
                <div class="form-group">
                    <label>Nome Contato:</label><strong class="cor">*</strong>
                    <input type="text" class="form-control" id="segundo_contato" name="segundo_contato"
                        [(ngModel)]="cadastroencontro.segundo_contato" placeholder="Informe Nome do contato"
                        formControlName="segundo_contato" maxlength="500" minlength="3"
                        [(ngModel)]="cadastroencontro.segundo_contato" autocomplete="off"
                        [ngClass]="{ 'is-invalid': submitted && f.segundo_contato.errors }" />
                    <div *ngIf="submitted && f.segundo_contato.errors" class="invalid-feedback">
                        <div *ngIf="f.segundo_contato.errors.required">Preenchimento obrigatório</div>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="dropdown">
                    <label>Grau Parentesco:</label><strong class="cor">*</strong>
                    <div class="mb-2">
                        <select class="custom-select" type="text" name="segundo_grau_contato" id="segundo_grau_contato"
                            [(ngModel)]="cadastroencontro.segundo_grau_contato" formControlName="segundo_grau_contato"
                            [ngClass]="{ 'is-invalid': submitted && f.segundo_grau_contato.errors }">
                            <div *ngIf="submitted && f.segundo_grau_contato.errors" class="invalid-feedback"></div>
                            <option value=""></option>
                            <option *ngFor="let documento of parentesco" [ngValue]="documento">
                                {{ documento }}
                            </option>
                        </select>
                        <div *ngIf="submitted && f.segundo_grau_contato.errors" class="invalid-feedback">
                            <div *ngIf="f.segundo_grau_contato.errors.required">
                                Preenchimento obrigatório
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-2">
                <label>Telefone/Celular::</label><strong class="cor">*</strong>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1"><i class="fas fa-phone"></i></span>
                    </div>
                    <input type="text" class="form-control" id="segundo_telefone_contato"
                        name="segundo_telefone_contato" [(ngModel)]="cadastroencontro.segundo_telefone_contato"
                        placeholder="Informe o Telefone" title="Formato (DD) 0000-0000 ou (DD) 00000-0000"
                        formControlName="segundo_telefone_contato" maxlength="500" minlength="3" autocomplete="off"
                        mask="(00) 0000-0000 || (00) 00000-0000" [ngClass]="{
                  'is-invalid': submitted && f.segundo_telefone_contato.errors
                }" />
                    <div *ngIf="submitted && f.segundo_telefone_contato.errors" class="invalid-feedback">
                        <div *ngIf="f.segundo_telefone_contato.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="row" style="padding: 0%;">
            <div class="col-md" style="padding-top: 0%;">
                <div>
                    <input type="checkbox" id="marked" data-md-icheck (change)="AceiteTermo($event)"
                        formControlName="marked" [(ngModel)]="marked" />
                    <b>&nbsp;Aceito os Termos</b>.<strong class="cor">*</strong>
                    <div *ngIf="submitted && f.marked.errors">
                        <div *ngIf="f.marked.errors.required" class="cor bold">
                            O aceite dos termos e obrigatório
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <br>






        <!-- Modal -->
        <button id="openModalButton" [hidden]="true" data-toggle="modal" data-target="#myModal"></button>
        <div class="modal fade bd-example-modal-lg" data-backdrop="static" data-keyboard="false" id="myModal"
            tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                            <i class="fas fa-info-circle" style="color: blue"></i>
                            {{ modaltitulo }}
                        </h5>
                    </div>
                    <div class="modal-body">
                        <div [innerHTML]="modalconteudo"></div>
                    </div>
                    <div class="modal-footer">
                        <div *ngIf="bt_termo == false">
                            <button pButton pRipple type="button" icon="pi pi-search" title="Fechar"
                                class="p-button-rounded p-button-danger" data-dismiss="modal">
                            </button>

                        </div>
                        <div *ngIf="bt_termo == true">
                            <button pButton pRipple type="button" icon="pi pi-thumbs-up" title="CONCORDO"
                                class="p-button-rounded" data-dismiss="modal">
                            </button>
                            <button pButton pRipple type="button" icon="pi pi-thumbs-down"
                                class="p-button-rounded p-button-danger" title="DISCORDO" (click)="DiscordeTermo()"
                                data-dismiss="modal">
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Modal -->
        <button id="openModalButtons" [hidden]="true" data-toggle="modal" data-target="#myModals"></button>
        <div class="modal fade bd-example-modal-lg" data-backdrop="static" data-keyboard="false" id="myModals"
            tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-lg" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                            <i class="fas fa-info-circle" style="color: blue"></i>
                            {{ modaltitulo }}
                        </h5>
                    </div>
                    <div class="modal-body">
                        <div [innerHTML]="modalconteudo"></div>
                    </div>
                    <div class="modal-footer">
                        <div>
                            <button pButton pRipple type="button" icon="pi pi-times"
                                class="p-button-rounded p-button-danger" title=":: FECHAR ::" data-dismiss="modal"
                                routerLink="">
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <button pButton pRipple type="button" icon="pi pi-save" class="p-button-rounded" style="margin-right: 5px;"
                title=":: SALVAR ::" (click)="onSubmit()"></button>
            <button pButton pRipple type="button" icon="pi pi-refresh" style="margin-right: 5px;"
                class="p-button-rounded p-button-secondary" title="::LIMPAR::" (click)="reset()"></button>
            <button pButton pRipple type="button" icon="pi pi-home" style="margin-right: 5px;"
                class="p-button-rounded p-button-help" title="::HOME::" routerLink=""></button>
                <a href="https://api.whatsapp.com/send?l=pt_BR&phone=55032988189440&text=Problemas Cadastro Encontro Mulheres"
                style="text-align: right; float: right;" title="Fale Conosco" target="_blank">Informar Problema!</a>
            <br>
            <br>
        </div>
    </form>
</div>
