export class ElogioModel {

    [x: string]: any;
    cpf:string='';
    nome:string='';
    email:string='';
    elogio:string='';
    dt_culto:Date;
    mensagem:string='';
    receber_copia:boolean=false;
    lida:string='';
    respondida:string='';
    dt_respondida:Date;
    idresposta:number=0;
    idusuario:number=0;   

}
