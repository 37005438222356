import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { Parametros } from 'src/app/shared/parametros.enum';
import { SharedModule } from 'src/app/shared/shared.module';
import { batismomodel } from '../cadastro-batismo/batismoModel';
import {ListagemBatismoService} from './listagem-batismo.service';

@Component({
  selector: 'app-listagem-batismo',
  templateUrl: './listagem-batismo.component.html',
  styleUrls: ['./listagem-batismo.component.css']
})
export class ListagemBatismoComponent implements OnInit {
  private urlseminario = Parametros.BATISMO;
  x = 1;
  y = 10;
  filtro_cabecalho: string = ""
  explicacao: string = "";
  textoconsulta: string = "";
  rota: any;
  perfil: any;
  pagina_atual = 0;
  primeiro_registro = 0;
  ultimo_registro = 0;
  imprimir: string = "Filtro:Todos";
  blob: Blob;
  idbatismo: any;
  nome: string;
  referencia: string = "TODOS CADASTRADOS";
  _perfil: string = "";

  cultos: any;

  
  tipoculto: any;
  CurrentPageReport: number;


  constructor(private http: HttpClient,
    private primengConfig: PrimeNGConfig,
    private alert: AlertModalService,
    private autenticado: SharedModule,
    private service: ListagemBatismoService,
    r: Router
  ) {
    this.rota = r;
    this.perfil = this.autenticado.perfil;
    this.nome = this.autenticado.nome;
    this._perfil = this.autenticado.perfil;
  }

  startedClass = false;
  completedClass = false;
  preventAbuse = false;

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//

  usuario: Array<batismomodel>;
  qto: number;
  cols: any[];

  




  GetListaTotal() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get<any>(this.urlseminario + 'GETTotalUsuarioBatismo/?parametro=' + this.referencia, httpOptions).subscribe(
      (success) => {

        this.qto = success

        if (this.x > success) {
          this.x = 1;
          this.y = success;
        }
      }
    )
  }

  GETListagemReferencias() {
    this.StartProgressBar();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token
      })
    };
    return this.http
      .get<batismomodel>(this.urlseminario + 'GETlistaReferenciaBatismoUltimos', httpOptions).subscribe(
        (success) => {
          this.cultos = success;
        },
        (error) => {

        }
      )
  }


  GetListaConsultaLimite(pagina: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.GetListaTotal()
    this.http.get<any>(this.urlseminario + 'GetListaConsultaLimite/?parametro=' + this.referencia + '&pagina=' + pagina, httpOptions).subscribe(
      (success) => {
        this.usuario = success;


      },
      (error) => {

      }
    )
  }






  ngOnInit() {
    if (this.autenticado.token == null) {
      this.autenticado.ResetDados();
      this.alert.AlertShowDanger('Usuário não autenticado, redirecionando...')
      this.rota.navigate(['/login']);
    } else {
      if (this.autenticado.ValidarToken() != true) {
        this.autenticado.ResetDados();
        this.alert.AlertShowDanger('Token Invalido!')
        this.rota.navigate(['/login']);
      }
    }
    this.primengConfig.ripple = true;
    this.filtro_cabecalho = "Listagem de Participantes Cadastrados"
    this.explicacao = "Aqui você está visualizando todos os participantes cadastrados para Batismo."
    this.GetListaTotal();
    this.GetListaConsultaLimite(this.pagina_atual);
    this.GETListagemReferencias();

    this.cols = [
      { field: 'Data Cadastro', header: 'Data Cadastro' },
      { field: 'Nome', header: 'Nome' },
      { field: 'Telefone', header: 'Telefone' },
      { field: 'Referencia', header: 'Referencia' }

    ];   
  }

  Paginate(event) {
    if (event.page == 0) {
      this.x = 1;
      this.y = 10;
    } else {

      if (event.page * 10 > this.qto) {
        this.x = this.qto;
        this.y = this.qto;
      } else {
        this.x = event.page * 10;
        if (this.x + 10 > this.qto) {
          this.y = this.qto;
        } else {
          this.y = this.x + 10;
        }
      }
    }


    this.pagina_atual = event.page;

    this.GetListaConsultaLimite(this.pagina_atual);

  }

  Consulta() {
    var texto = (<HTMLInputElement>document.getElementById("texto_consulta")).value;
    if (texto != "") {
      this.textoconsulta = texto;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
        })
      }
      this.http.get<batismomodel[]>(this.urlseminario + 'GetListaConsultaUsuarioNome/?parametro=' + texto + '&tipo=' + this.referencia, httpOptions).subscribe(
        (success) => {
          this.usuario = success;
          this.qto = success.length

          this.x = 1;
          this.y = success.length;

        }
      )
    } else {
      this.textoconsulta = "";
      this.GetListaConsultaLimite(this.pagina_atual);
    }
  }

  //modal
  ValorImpressao(event) {
    this.imprimir = event.currentTarget.value;
  }

  SetDados(event) {
    this.idbatismo = event.currentTarget.value;
  }

  SetConsultaSeminario(event) {
    this.tipoculto = event.currentTarget.value;
    this.GetListaConsultaLimite(this.pagina_atual);
    this.GetListaTotal();
  }

  BotaoExcluir() {
    this.ExcluirConsulta();
  }


  ExcluirConsulta() {
    this.service.CancelarUsuarioculoregistrado(this.idbatismo).subscribe(
      (success) => {
        this.StopProgressBar();
        this.alert.AlertShowInfo('Participante Excluido com sucesso!');
        this.GetListaConsultaLimite(this.pagina_atual);
      },
      (error) => {
        this.StopProgressBar();
        this.alert.AlertShowDanger('Erro ao cancelar Participante!');
        this.GetListaConsultaLimite(this.pagina_atual);
      }
    )
  }
}
