import { Component, OnInit } from '@angular/core';
import { EventEmitterService } from '../shared/EventEmitterService';
import { SharedModule } from '../shared/shared.module';


@Component({
  selector: 'app-desenvolvimento',
  templateUrl: './desenvolvimento.component.html',
  styleUrls: ['./desenvolvimento.component.css']
})
export class DesenvolvimentoComponent implements OnInit {

  constructor(
    private autenticado:SharedModule
  ) {
    EventEmitterService.get('loginAlert').emit(false);
   }

  ngOnInit(): void {

    EventEmitterService.get('loginAlert').emit(false);
    this.autenticado.autenticado=false;
  }

}
