import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivateChild } from '@angular/router';
import { SharedModule } from '../shared/shared.module';

@Injectable({
  providedIn: 'root'
})
export class GuardChild implements CanActivateChild {

  perfil: string = '';
  ministerio: string = '';
  rota: Router;


  constructor(public Is: SharedModule,
    router: Router
  ) {
    this.rota = router;
    if (this.Is.perfil == '') {
      this.rota.navigateByUrl['/login']
    }

    if (this.Is.ministerio == '') {
      this.rota.navigateByUrl['/login']
    }

  }


  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | boolean {

    var url = state.url;
    this.perfil = this.Is.perfil;
    this.ministerio = this.Is.ministerio;
    //DASHBOARD
    //ACESSIVEL A TODOS OS USUARIOS
    //PERFIL
    if (url == '/dashboard/perfil') {
      if (this.perfil.includes('Financeiro') || this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao') || this.perfil.includes('Lideranca') || this.perfil.includes('Auxiliar') || this.perfil.includes('Membro') || this.perfil.includes('Aluno') || this.perfil.includes('Secretaria')) {
        return true;
      } else {
        return false;
      }
    }

    //ESTABELCIMENTO
    if (url == '/dashboard/cadastro-estabelecimento') {
      if (this.perfil.includes('Membro')) {
        return true;
      } else {
        return false;
      }
    }

    //ESTABELCIMENTO
    if (url == '/dashboard/listagem-estabelecimento') {
      if (this.perfil.includes('Membro')) {
        return true;
      } else {
        return false;
      }
    }

    //ESTABELCIMENTO
    if (url == '/dashboard/editar-estabelecimento') {
      if (this.perfil.includes('Membro')) {
        return true;
      } else {
        return false;
      }
    }
    //ESTABELCIMENTO
    if (url == '/dashboard/listagem/listagem-empresa-servico') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao') || this.perfil.includes('Lideranca') || this.perfil.includes('Auxiliar') || this.perfil.includes('Membro') || this.perfil.includes('Aluno') || this.perfil.includes('Secretaria')) {
        return true;
      } else {
        return false;
      }
    }


    //ESTABELCIMENTO
    if (url == '/dashboard/consulta/consulta-geral-servicos-estabelecimentos') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao') || this.perfil.includes('Lideranca') || this.perfil.includes('Auxiliar') || this.perfil.includes('Membro') || this.perfil.includes('Aluno') || this.perfil.includes('Secretaria')) {
        return true;
      } else {
        return false;
      }
    }

    //OPORTUNIDADE
    if (url == '/dashboard/cadastrar-oportunidade') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao') || this.perfil.includes('Lideranca') || this.perfil.includes('Auxiliar') || this.perfil.includes('Membro') || this.perfil.includes('Aluno') || this.perfil.includes('Secretaria')) {
        return true;
      } else {
        return false;
      }
    }

    //OPORTUNIDADE
    if (url == '/dashboard/listagem-oportunidade') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao') || this.perfil.includes('Lideranca') || this.perfil.includes('Auxiliar') || this.perfil.includes('Membro') || this.perfil.includes('Aluno') || this.perfil.includes('Secretaria')) {
        return true;
      } else {
        return false;
      }
    }



    //OPORTUNIDADE
    if (url == '/dashboard/editar-oportunidade') {
      if (this.perfil.includes('Membro')) {
        return true;
      } else {
        return false;
      }
    }

    //OPORTUNIDADE ADMINISTRACAO
    if (url == '/dashboard/listagem/listagem-oportunidades') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao')) {
        return true;
      } else {
        return false;
      }
    }

    //OPORTUNIDADE CONSULTA
    if (url == '/dashboard/consulta/consulta-oportunidades') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Membro') || this.perfil.includes('Gestao')) {
        return true;
      } else {
        return false;
      }
    }



    //OPORTUNIDADE GERAL TODOS
    if (url == '/dashboard/consulta/consulta-geral-oportunidades') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Membro') || this.perfil.includes('Gestao')) {
        return true;
      } else {
        return false;
      }
    }



    //TROCAR SENHA
    if (url == '/dashboard/trocar-senha') {
      if (this.perfil.includes('Financeiro') || this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao') || this.perfil.includes('Lideranca') || this.perfil.includes('Auxiliar') || this.perfil.includes('Membro') || this.perfil.includes('Aluno') || this.perfil.includes('Secretaria')) {
        return true;
      } else {
        return false;
      }
    }
    //COMUNICACAO
    if (url == '/dashboard/comunicacao') {
      if (this.perfil.includes('Financeiro') || this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao') || this.perfil.includes('Lideranca') || this.perfil.includes('Auxiliar') || this.perfil.includes('Membro') || this.perfil.includes('Aluno') || this.perfil.includes('Secretaria')) {
        return true;
      } else {
        return false;
      }
    }
    //RESUMO
    if (url == '/dashboard/resumo') {
      if (this.perfil.includes('Financeiro') || this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao') || this.perfil.includes('Lideranca') || this.perfil.includes('Auxiliar') || this.perfil.includes('Membro') || this.perfil.includes('Aluno') || this.perfil.includes('Secretaria')) {
        return true;
      } else {
        return false;
      }
    }
    //ELOGIO
    if (url == '/dashboard/elogio') {
      if (this.perfil.includes('Financeiro') || this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao') || this.perfil.includes('Lideranca') || this.perfil.includes('Auxiliar') || this.perfil.includes('Membro') || this.perfil.includes('Aluno') || this.perfil.includes('Secretaria')) {
        return true;
      } else {
        return false;
      }
    }
    //VISITA
    if (url == '/dashboard/visita') {
      if (this.perfil.includes('Financeiro') || this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao') || this.perfil.includes('Lideranca') || this.perfil.includes('Auxiliar') || this.perfil.includes('Membro') || this.perfil.includes('Aluno') || this.perfil.includes('Secretaria')) {
        return true;
      } else {
        return false;
      }
    }
    //TESTEMUNHO
    if (url == '/dashboard/testemunho') {
      if (this.perfil.includes('Financeiro') || this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao') || this.perfil.includes('Lideranca') || this.perfil.includes('Auxiliar') || this.perfil.includes('Membro') || this.perfil.includes('Aluno') || this.perfil.includes('Secretaria')) {
        return true;
      } else {
        return false;
      }
    }
    //SUGESTÕES
    if (url == '/dashboard/sugestoes') {
      if (this.perfil.includes('Financeiro') || this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao') || this.perfil.includes('Lideranca') || this.perfil.includes('Auxiliar') || this.perfil.includes('Membro') || this.perfil.includes('Aluno') || this.perfil.includes('Secretaria')) {
        return true;
      } else {
        return false;
      }
    }
    //RECLAMACAO
    if (url == '/dashboard/reclamacao') {
      if (this.perfil.includes('Financeiro') || this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao') || this.perfil.includes('Lideranca') || this.perfil.includes('Auxiliar') || this.perfil.includes('Membro') || this.perfil.includes('Aluno') || this.perfil.includes('Secretaria')) {
        return true;
      } else {
        return false;
      }
    }
    //PRIVACIDADE
    if (url == '/dashboard/privacidade') {
      if (this.perfil.includes('Financeiro') || this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao') || this.perfil.includes('Lideranca') || this.perfil.includes('Auxiliar') || this.perfil.includes('Membro') || this.perfil.includes('Aluno') || this.perfil.includes('Secretaria')) {
        return true;
      } else {
        return false;
      }
    }
    //CANCELAR REGISTRO
    if (url == '/dashboard/cancelar-registro') {
      if (this.perfil.includes('Financeiro') || this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao') || this.perfil.includes('Lideranca') || this.perfil.includes('Auxiliar') || this.perfil.includes('Membro') || this.perfil.includes('Aluno') || this.perfil.includes('Secretaria')) {
        return true;
      } else {
        return false;
      }
    }
    //LISTAGEM ELOGIO
    if (url == '/dashboard/listagem/lista-elogio') {
      if (this.perfil.includes('Financeiro') || this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao') || this.perfil.includes('Lideranca') || this.perfil.includes('Auxiliar') || this.perfil.includes('Membro') || this.perfil.includes('Aluno') || this.perfil.includes('Secretaria')) {
        return true;
      } else {
        return false;
      }
    }
    //LISTAGEM VISITA
    if (url == '/dashboard/listagem/lista-visita') {
      if (this.perfil.includes('Financeiro') || this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao') || this.perfil.includes('Lideranca') || this.perfil.includes('Auxiliar') || this.perfil.includes('Membro') || this.perfil.includes('Aluno') || this.perfil.includes('Secretaria')) {
        return true;
      } else {
        return false;
      }
    }
    //LISTAGEM RECLAMACAO
    if (url == '/dashboard/listagem/lista-reclamacao') {
      if (this.perfil.includes('Financeiro') || this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao') || this.perfil.includes('Lideranca') || this.perfil.includes('Auxiliar') || this.perfil.includes('Membro') || this.perfil.includes('Aluno') || this.perfil.includes('Secretaria')) {
        return true;
      } else {
        return false;
      }
    }
    //LISTAGEM RECLAMACAO
    if (url == '/dashboard/listagem/lista-sugestoes') {
      if (this.perfil.includes('Financeiro') || this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao') || this.perfil.includes('Lideranca') || this.perfil.includes('Auxiliar') || this.perfil.includes('Membro') || this.perfil.includes('Aluno') || this.perfil.includes('Secretaria')) {
        return true;
      } else {
        return false;
      }
    }
    //LISTAGEM TESTEMUNHO
    if (url == '/dashboard/listagem/lista-testemunho') {
      if (this.perfil.includes('Financeiro') || this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao') || this.perfil.includes('Lideranca') || this.perfil.includes('Auxiliar') || this.perfil.includes('Membro') || this.perfil.includes('Aluno') || this.perfil.includes('Secretaria')) {
        return true;
      } else {
        return false;
      }
    }
    //LISTAGEM ACESSOS
    if (url == '/dashboard/listagem/lista-acessos') {
      if (this.perfil.includes('Financeiro') || this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao') || this.perfil.includes('Lideranca') || this.perfil.includes('Auxiliar') || this.perfil.includes('Membro') || this.perfil.includes('Aluno') || this.perfil.includes('Secretaria')) {
        return true;
      } else {
        return false;
      }
    }
    //LISTAGEM ACESSOS
    if (url == '/dashboard/listagem/lista-seminario') {
      if (this.perfil.includes('Financeiro') || this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao') || this.perfil.includes('Secretaria') || this.perfil.includes('Seminario_Parcial') || this.perfil.includes('Seminario_Total')) {
        return true;
      } else {
        return false;
      }
    }
    //DETALHE ELOGIO
    if (url == '/dashboard/detalhe/elogio') {
      if (this.perfil.includes('Financeiro') || this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao') || this.perfil.includes('Lideranca') || this.perfil.includes('Auxiliar') || this.perfil.includes('Membro') || this.perfil.includes('Aluno') || this.perfil.includes('Secretaria')) {
        return true;
      } else {
        return false;
      }
    }
    //DETALHE ELOGIO
    if (url == '/dashboard/detalhe/acesso') {
      if (this.perfil.includes('Financeiro') || this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao') || this.perfil.includes('Lideranca') || this.perfil.includes('Auxiliar') || this.perfil.includes('Membro') || this.perfil.includes('Aluno') || this.perfil.includes('Secretaria')) {
        return true;
      } else {
        return false;
      }
    }
    //DETALHE RECLAMACAO
    if (url == '/dashboard/detalhe/reclamacao') {
      if (this.perfil.includes('Financeiro') || this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao') || this.perfil.includes('Lideranca') || this.perfil.includes('Auxiliar') || this.perfil.includes('Membro') || this.perfil.includes('Aluno') || this.perfil.includes('Secretaria')) {
        return true;
      } else {
        return false;
      }
    }
    //DETALHE SUGESTAO
    if (url == '/dashboard/detalhe/sugestoes') {
      if (this.perfil.includes('Financeiro') || this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao') || this.perfil.includes('Lideranca') || this.perfil.includes('Auxiliar') || this.perfil.includes('Membro') || this.perfil.includes('Aluno') || this.perfil.includes('Secretaria')) {
        return true;
      } else {
        return false;
      }
    }

    //DETALHE TESTEMUNHO
    if (url == '/dashboard/detalhe/testemunho') {
      if (this.perfil.includes('Financeiro') || this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao') || this.perfil.includes('Lideranca') || this.perfil.includes('Auxiliar') || this.perfil.includes('Membro') || this.perfil.includes('Aluno') || this.perfil.includes('Secretaria')) {
        return true;
      } else {
        return false;
      }
    }
    //DETALHE VISITA
    if (url == '/dashboard/detalhe/visita') {
      if (this.perfil.includes('Financeiro') || this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao') || this.perfil.includes('Lideranca') || this.perfil.includes('Auxiliar') || this.perfil.includes('Membro') || this.perfil.includes('Aluno') || this.perfil.includes('Secretaria')) {
        return true;
      } else {
        return false;
      }
    }

    //DETALHE USUARIO SEMINARIO
    if (url == '/dashboard/detalhe/usuario-seminario') {
      if (this.perfil.includes('Financeiro') || this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao') || this.perfil.includes('Secretaria')) {
        return true;
      } else {
        return false;
      }
    }

    if (url == '/dashboard/detalhe/usuario-seminario-infantil') {
      if (this.perfil.includes('Financeiro') || this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao') || this.perfil.includes('Secretaria')) {
        return true;
      } else {
        return false;
      }
    }

    //COMUNICADO SEMINARIO
    if (url == '/dashboard/comunicado/enviar-comunicado') {
      if (this.perfil.includes('Financeiro') || this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao') || this.perfil.includes('Secretaria') || this.perfil.includes('Seminario_Total')) {
        return true;
      } else {
        return false;
      }
    }

    if (url == '/dashboard/comunicado/enviar-comunicado-seminario-infantil') {
      if (this.perfil.includes('Financeiro') || this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao') || this.perfil.includes('Secretaria') || this.perfil.includes('Seminario_Total')) {
        return true;
      } else {
        return false;
      }
    }

    //CHECKOUT_SEMINARIO
    if (url == '/dashboard/checkout/documento-seminario') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Checkout') || this.perfil.includes('Gestao') || this.perfil.includes('Secretaria')) {
        return true;
      } else {
        return false;
      }
    }


    //ENCONTRO MULHERES
    if (url == '/dashboard/gerenciamento/encontro-mulheres/cadastros') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Checkout') || this.perfil.includes('Gestao') || this.perfil.includes('Secretaria') || this.perfil.includes('Evento_Mulheres')) {
        return true;
      } else {
        return false;
      }
    }
    //ENCONTRO MULHERES DETALHES
    if (url == '/dashboard/gerenciamento/detalhamento/Cadastro-mulheres-detalhe') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Checkout') || this.perfil.includes('Gestao') || this.perfil.includes('Secretaria') || this.perfil.includes('Evento_Mulheres')) {
        return true;
      } else {
        return false;
      }
    }



    //ENCONTRO CASAIS
    if (url == '/dashboard/gerenciamento/encontro-casais/cadastros') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Checkout') || this.perfil.includes('Gestao') || this.perfil.includes('Secretaria') || this.perfil.includes('Evento_Casais')) {
        return true;
      } else {
        return false;
      }
    }
    //ENCONTRO CASAIS DETALHES
    if (url == '/dashboard/gerenciamento/detalhamento/Cadastro-casais-detalhe') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Checkout') || this.perfil.includes('Gestao') || this.perfil.includes('Secretaria') || this.perfil.includes('Evento_Casais')) {
        return true;
      } else {
        return false;
      }
    }



    //ENCONTRO HOMENS
    if (url == '/dashboard/gerenciamento/encontro-homens/cadastros') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Checkout') || this.perfil.includes('Gestao') || this.perfil.includes('Secretaria') || this.perfil.includes('Evento_Homens')) {
        return true;
      } else {
        return false;
      }
    }
    //ENCONTRO HOMENS DETALHES
    if (url == '/dashboard/gerenciamento/detalhamento/Cadastro-homens-detalhe') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Checkout') || this.perfil.includes('Gestao') || this.perfil.includes('Secretaria') || this.perfil.includes('Evento_Homens')) {
        return true;
      } else {
        return false;
      }
    }


    //ENCONTRO CASAIS
    if (url == '/dashboard/gerenciamento/encontro-casais/cadastros') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Checkout') || this.perfil.includes('Gestao') || this.perfil.includes('Secretaria') || this.perfil.includes('Evento_Casais')) {
        return true;
      } else {
        return false;
      }
    }
    //ENCONTRO CASAIS DETALHES
    if (url == '/dashboard/gerenciamento/detalhamento/Cadastro-casais-detalhe') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Checkout') || this.perfil.includes('Gestao') || this.perfil.includes('Secretaria') || this.perfil.includes('Evento_Casais')) {
        return true;
      } else {
        return false;
      }
    }

    //TOTAL_SEMINARIO
    if (url == '/dashboard/resumo/resumo-seminario-registrado') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao') || this.perfil.includes('Secretaria')) {
        return true;
      } else {
        return false;
      }
    }
    //TOTAL_SEMINARIO_CONFIRMADO
    if (url == '/dashboard/resumo/resumo-seminario-confirmado') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao') || this.perfil.includes('Secretaria')) {
        return true;
      } else {
        return false;
      }
    }




    //======================================//
    //PERFIS COM RESTRICAO
    //financeiro ( Presidente | Administrador | Gestão | Financeiro)
    if (url == '/dashboard/financeiro') {
      if (this.perfil.includes('Financeiro') || this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao')) {
        return true;
      } else {
        return false;
      }
    }

    //financeiro ( Presidente | Administrador | Gestão)
    if (url == '/dashboard/parametros') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao')) {
        return true;
      } else {
        return false;
      }
    }

    //Termos ( Presidente | Administrador | Gestão)
    if (url == '/dashboard/termos') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao')) {
        return true;
      } else {
        return false;
      }
    }


    //Termos ( Presidente | Administrador | Gestão)
    if (url == '/dashboard/termos-encontro-homens') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao')) {
        return true;
      } else {
        return false;
      }
    }


    //Termos ( Presidente | Administrador | Gestão)
    if (url == '/dashboard/termos-congresso-jovens') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao')) {
        return true;
      } else {
        return false;
      }
    }

    //Termos ( Presidente | Administrador | Gestão)
    if (url == '/dashboard/termos-encontro-mulheres') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao')) {
        return true;
      } else {
        return false;
      }
    }

    //Termos ESTABELECIMENTOS( Presidente | Administrador | Gestão)
    if (url == '/dashboard/termos-estabelecimentos') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao')) {
        return true;
      } else {
        return false;
      }
    }

    //Termos CURSO CASAIS( Presidente | Administrador | Gestão)
    if (url == '/dashboard/termos-curso-casais') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao')) {
        return true;
      } else {
        return false;
      }
    }



    //Termos CURSO CASAIS( Presidente | Administrador | Gestão)
    if (url == '/dashboard/termos-encontro-casais') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao')) {
        return true;
      } else {
        return false;
      }
    }

    //Termos CURSO CASAIS( Presidente | Administrador | Gestão)
    if (url == '/dashboard/termos-congresso-mulheres') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao')) {
        return true;
      } else {
        return false;
      }
    }

     //Termos CONGRESSO HOMENS( Presidente | Administrador | Gestão)
     if (url == '/dashboard/termos-congresso-homens') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao')) {
        return true;
      } else {
        return false;
      }
    }

    //Termos OPORTUNIDADE( Presidente | Administrador | Gestão)
    if (url == '/dashboard/termos-oportunidade') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao')) {
        return true;
      } else {
        return false;
      }
    }


    //Listagem perfis usuarios ( Presidente | Administrador | Gestão)
    if (url == '/dashboard/perfil/perfil-usuario') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao')) {
        return true;
      } else {
        return false;
      }
    }

    //PERFIL ( Presidente | Administrador | Gestão)
    if (url == '/dashboard/gerenciamento/perfil/usuario-ajuste-perfil') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao')) {
        return true;
      } else {
        return false;
      }
    }

    //CURSOS ( Presidente | Administrador | Gestão)
    if (url == '/dashboard/gerenciamento/perfil/usuario-ajuste-curso') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao')) {
        return true;
      } else {
        return false;
      }
    }

    //MINISTERIO ( Presidente | Administrador | Gestão)
    if (url == '/dashboard/gerenciamento/perfil/usuario-ajuste-ministerio') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao')) {
        return true;
      } else {
        return false;
      }
    }

    //VISITANTES ( Presidente | Administrador | Gestão | Consolidacao)
    if (url == '/dashboard/gerenciamento/visitantes/visitantes-listagem') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao') || this.perfil.includes('Secretaria') || this.perfil.includes('Consolidacao')) {
        return true;
      } else {
        return false;
      }
    }

    if (url == '/dashboard/gerenciamento/visitantes/detalhamento/visitantes-detalhes') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao') || this.perfil.includes('Secretaria') || this.perfil.includes('Consolidacao')) {
        return true;
      } else {
        return false;
      }
    }

    if (url == '/dashboard/gerenciamento/visitantes/comunicacao/comunicar-visitantes') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao') || this.perfil.includes('Secretaria') || this.perfil.includes('Consolidacao')) {
        return true;
      } else {
        return false;
      }
    }

    //=============CULTO REGISTRADO==========================
    //Listagem culto registrado ( Presidente | Administrador | Gestão)
    if (url == '/dashboard/listagem/listagem-culto-registrado') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao') || this.perfil.includes('Secretaria') || this.perfil.includes('Checkout')) {
        return true;
      } else {
        return false;
      }
    }

    if (url == '/dashboard/comunicado/enviar-comunicado-culto-registrado') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Secretaria') || this.perfil.includes('Gestao')) {
        return true;
      } else {
        return false;
      }
    }

    if (url == '/dashboard/detalhe/usuario-culto') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Secretaria') || this.perfil.includes('Gestao')) {
        return true;
      } else {
        return false;
      }
    }
    //=============CULTO REGISTRADO==========================

    //=============CULTO KIDS REGISTRADO==========================
    //Listagem culto registrado ( Presidente | Administrador | Gestão)
    if (url == '/dashboard/listagem/listagem-culto-kids-registrado') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao') || this.perfil.includes('Secretaria') || this.perfil.includes('Professor') || this.perfil.includes('Checkout')) {
        return true;
      } else {
        return false;
      }
    }

    if (url == '/dashboard/comunicado/enviar-comunicado-culto-kids-registrado') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao') || this.perfil.includes('Secretaria')) {
        return true;
      } else {
        return false;
      }
    }

    if (url == '/dashboard/detalhe/usuario-culto-kids') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao') || this.perfil.includes('Secretaria')) {
        return true;
      } else {
        return false;
      }
    }
    //=============CULTO KIDS REGISTRADO==========================


    if (url == '/dashboard/resumo/resumo-culto-registrado') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao') || this.perfil.includes('Secretaria')) {
        return true;
      } else {
        return false;
      }
    }


    if (url == '/dashboard/resumo/resumo-culto-kids-registrado') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao') || this.perfil.includes('Secretaria')) {
        return true;
      } else {
        return false;
      }
    }

    if (url == '/dashboard/checkout/documento') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Checkout') || this.perfil.includes('Gestao') || this.perfil.includes('Secretaria')) {
        return true;
      } else {
        return false;
      }
    }

    if (url == '/dashboard/checkout/documento-kids') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Checkout') || this.perfil.includes('Gestao') || this.perfil.includes('Secretaria')) {
        return true;
      } else {
        return false;
      }
    }



    if (url == '/dashboard/resumo/resumo-seminario-infantil-registrado') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Checkout') || this.perfil.includes('Gestao') || this.perfil.includes('Secretaria') || this.perfil.includes('Evento_Infantil')) {
        return true;
      } else {
        return false;
      }
    }


    if (url == '/dashboard/resumo/resumo-seminario-infantil-confirmado') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Checkout') || this.perfil.includes('Gestao') || this.perfil.includes('Secretaria') || this.perfil.includes('Evento_Infantil')) {
        return true;
      } else {
        return false;
      }
    }

    if (url == '/dashboard/listagem/lista-seminario-infantil') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Checkout') || this.perfil.includes('Gestao') || this.perfil.includes('Secretaria') || this.perfil.includes('Evento_Infantil') || this.perfil.includes('Seminario_Parcial') || this.perfil.includes('Seminario_Total')) {
        return true;
      } else {
        return false;
      }
    }

    if (url == '/dashboard/checkout/documento-seminario-infantil') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Checkout') || this.perfil.includes('Gestao') || this.perfil.includes('Secretaria') || this.perfil.includes('Evento_Infantil')) {
        return true;
      } else {
        return false;
      }
    }

    //=============MEMBROS==========================
    //Listagem membros ( Presidente | Administrador | Gestão)
    if (url == '/dashboard/gerenciamento/listagem-membros') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao')) {
        return true;
      } else {
        return false;
      }
    }

    //Membros ativos ( Presidente | Administrador | Gestão)
    if (url == '/dashboard/gerenciamento/membros-ativos') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao')) {
        return true;
      } else {
        return false;
      }
    }

    //Membros ativos ( Presidente | Administrador | Gestão)
    if (url == '/dashboard/gerenciamento/membros-inativos') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao')) {
        return true;
      } else {
        return false;
      }
    }
    //Membros aguardando liberacao ( Presidente | Administrador | Gestão)
    if (url == '/dashboard/gerenciamento/membros-aguardando-liberacao') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao')) {
        return true;
      } else {
        return false;
      }
    }

    //Membros Cancelados ( Presidente | Administrador | Gestão)
    if (url == '/dashboard/gerenciamento/membros-cancelados') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao')) {
        return true;
      } else {
        return false;
      }
    }


    //Detalhe Membros Cancelados ( Presidente | Administrador | Gestão)
    if (url == '/dashboard/gerenciamento/detalhamento/usuario-detalhe') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao')) {
        return true;
      } else {
        return false;
      }
    }

    //Detalhe Membros Ativos ( Presidente | Administrador | Gestão)
    if (url == '/dashboard/gerenciamento/detalhamento/usuario-ativo') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao')) {
        return true;
      } else {
        return false;
      }
    }

    //Detalhe Membros Ativos ( Presidente | Administrador | Gestão)
    if (url == '/dashboard/gerenciamento/detalhamento/usuario-aguardando-liberacao') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao')) {
        return true;
      } else {
        return false;
      }
    }

    //Detalhe Membros cancelados ( Presidente | Administrador | Gestão)
    if (url == '/dashboard/gerenciamento/detalhamento/usuario-cancelado') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao')) {
        return true;
      } else {
        return false;
      }
    }

    //Detalhe Membros cancelados ( Presidente | Administrador | Gestão)
    if (url == '/dashboard/gerenciamento/detalhamento/usuario-inativo') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao')) {
        return true;
      } else {
        return false;
      }
    }

    //Detalhe Membros cancelados ( Presidente | Administrador | Gestão)
    if (url == '/dashboard/gerenciamento/comunicacao/comunicar-usuarios') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao')) {
        return true;
      } else {
        return false;
      }
    }





    //=============FIM MEMBROS==========================

    //=============PATRIMONIO==========================

    //Detalhe Membros cancelados ( Presidente | Administrador | Gestão)
    if (url == '/dashboard/gerenciamento/patrimonio/registrar-patrimonio') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Patrimonio') || this.perfil.includes('Patrimonio')) {
        return true;
      } else {
        return false;
      }
    }

    if (url == '/dashboard/gerenciamento/patrimonio/resumo-patrimonio-setor') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Patrimonio_Total') || this.perfil.includes('Patrimonio')) {
        return true;
      } else {
        return false;
      }
    }


    if (url == '/dashboard/gerenciamento/patrimonio/listagem-patrimonio') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Patrimonio_Total') || this.perfil.includes('Patrimonio')) {
        return true;
      } else {
        return false;
      }
    }

    if (url == '/dashboard/gerenciamento/patrimonio/historico-patrimonio') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Patrimonio_Total') || this.perfil.includes('Patrimonio')) {
        return true;
      } else {
        return false;
      }
    }

    if (url == '/dashboard/gerenciamento/patrimonio/historico-patrimonio-inativo') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Patrimonio_Total') || this.perfil.includes('Patrimonio')) {
        return true;
      } else {
        return false;
      }
    }

    if (url == '/dashboard/gerenciamento/patrimonio/listagem-patrimonio-inativos') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Patrimonio_Total') || this.perfil.includes('Patrimonio')) {
        return true;
      } else {
        return false;
      }
    }
    //=============FIM PATRIMONIO==========================






    //=============COMPRAS==========================
    if (url == '/dashboard/gerenciamento/compras/solicitacao-compras') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Solicitacao_Compra')) {
        return true;
      } else {
        return false;
      }
    }

    if (url == '/dashboard/gerenciamento/compras/listagem-sollicitcao-compras') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Solicitacao_Compra')) {
        return true;
      } else {
        return false;
      }
    }

    if (url == '/dashboard/gerenciamento/compra/historico-solicitacao') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Solicitacao_Compra')) {
        return true;
      } else {
        return false;
      }
    }


    //=============FIM COMPRAS==========================

    //=================CONGRESSO MULHERES======================

    if (url == '/dashboard/checkout/congresso-mulheres') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Evento_Mulheres') || this.perfil.includes('Checkout')) {
        return true;
      } else {
        return false;
      }
    }

    if (url == '/dashboard/resumo/congresso-mulheres') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Evento_Mulheres')) {
        return true;
      } else {
        return false;
      }
    }

    if (url == '/dashboard/gerenciamento/congresso-mulheres/cadastros') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Evento_Mulheres')) {
        return true;
      } else {
        return false;
      }
    }

    if (url == '/dashboard/comunicado/enviar-comunicado-congresso-mulheres') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao')) {
        return true;
      } else {
        return false;
      }
    }


    if (url == '/dashboard/detalhe/Congresso-Mulheres') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao') || this.perfil.includes('Evento_Mulheres')) {
        return true;
      } else {
        return false;
      }
    }
    //=================FIM CONGRESSO MULHERES======================

    //=================CONGRESSO HOMENS======================

    if (url == '/dashboard/checkout/congresso-homens') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Evento_Homens') || this.perfil.includes('Checkout')) {
        return true;
      } else {
        return false;
      }
    }

    if (url == '/dashboard/resumo/congresso-homens') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Evento_Homens')) {
        return true;
      } else {
        return false;
      }
    }

    if (url == '/dashboard/gerenciamento/congresso-homens/cadastros') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Evento_Homens')) {
        return true;
      } else {
        return false;
      }
    }

    if (url == '/dashboard/comunicado/enviar-comunicado-congresso-homens') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao')) {
        return true;
      } else {
        return false;
      }
    }


    if (url == '/dashboard/detalhe/Congresso-Homens') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao') || this.perfil.includes('Evento_Homens')) {
        return true;
      } else {
        return false;
      }
    }
    //=================FIM CONGRESSO HOMENS======================


     //=================CONGRESSO HOMENS======================

     if (url == '/dashboard/checkout/congresso-jovens') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Evento_Jovens') || this.perfil.includes('Checkout')) {
        return true;
      } else {
        return false;
      }
    }

    if (url == '/dashboard/resumo/congresso-jovens') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Evento_Jovens')) {
        return true;
      } else {
        return false;
      }
    }

    if (url == '/dashboard/gerenciamento/congresso-jovens/cadastros') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Evento_Jovens')) {
        return true;
      } else {
        return false;
      }
    }

    if (url == '/dashboard/comunicado/enviar-comunicado-congresso-jovens') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao')) {
        return true;
      } else {
        return false;
      }
    }


    if (url == '/dashboard/detalhe/Congresso-Jovens') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Gestao') || this.perfil.includes('Evento_Jovens')) {
        return true;
      } else {
        return false;
      }
    }
    //=================FIM CONGRESSO JOVENS======================


    //=============CURSO CASAIS==========================




    if (url == '/dashboard/gerenciamento/cursos/curso-para-casais/listagem-cadastros') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Curso_Casais') || this.perfil.includes('Curso_Casais_Total')) {
        return true;
      } else {
        return false;
      }
    }

    if (url == '/dashboard/gerenciamento/cursos/curso-para-casais/detalhe-cadastros-casal') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Curso_Casais_Total')) {
        return true;
      } else {
        return false;
      }
    }

    //=============FIM COMPRAS==========================



    //====================BATISMO=================
    if (url == '/dashboard/listagem/listagem-batismo') {
      if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Secretaria')) {
        return true;
      } else {
        return false;
      }
    }
    //============FIM BATISMO ===================


      //====================DEDUCAO COMPRAS=================
      if (url == '/dashboard/gerenciamento/deducao-compras/vincular-responsavel') {
        if (this.perfil.includes('Administrador') ) {
          return true;
        } else {
          return false;
        }
      }
      if (url == '/dashboard/gerenciamento/deducao-compras/listagem-todas-deducoes') {
        if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Contabilidade')) {
          return true;
        } else {
          return false;
        }
      }

      if (url == '/dashboard/gerenciamento/deducao-compras/listagem-deducoes-compras') {
        if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Deducao_Compra')) {
          return true;
        } else {
          return false;
        }
      }

      if (url == '/dashboard/gerenciamento/deducao-compras/registrar-compra') {
        if (this.perfil.includes('Presidente') || this.perfil.includes('Administrador') || this.perfil.includes('Deducao_Compra')) {
          return true;
        } else {
          return false;
        }
      }





      //============FIM DEDUCAO COMPRAS ===================










    return false;


  }

}
