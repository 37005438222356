import { Component, OnInit } from '@angular/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin  from '@fullcalendar/list';

import interactionPlugin from '@fullcalendar/interaction';
import esLocale from '@fullcalendar/core/locales/pt-br';



@Component({
  selector: 'app-teste',
  templateUrl: './teste.component.html',
  styleUrls: ['./teste.component.css']
})
export class TesteComponent implements  OnInit  {

  public resources: any[];
  public options: any;
  events: any;

  deleteEventTitle: any;
  calendarOptions: any;

  constructor() {

    this.ngAfterViewInit()

  }

  ngOnInit(): void {
    this.calendarOptions = {
      plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin, listPlugin],
      defaultDate: new Date(),
      locale: esLocale,
      eventLimit: true,
      headerToolbar: {
        left: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
        center: 'title',
        right: 'prevYear,prev,next,nextYear'
      },
      defaultView: 'dayGridMonth',
      editable: true,
      weekends: true,
      textColor: 'black',
      selectable: true,
      today: 'dayGridMonth',



      events: this.events,
      eventClick: this.handleEventClick.bind(this),

      dateClick: function (info) {
        alert('Clicked on: ' + info.dateStr);
        alert('Coordinates: ' + info.jsEvent.pageX + ',' + info.jsEvent.pageY);
        alert('Current view: ' + info.view.type);
        // change the day's background color just for fun
        //info.dayEl.style.backgroundColor = 'red';
      }
    };
  }






  handleEventClick(arg){
    this.deleteEventTitle = arg.event._def.title;
  }



  ngAfterViewInit(){
      this.events = [
        {
          id: 575,
          title: 'Long Event',
          start: '2023-10-04',
          end: '2023-10-10',
          display: null,
          extendedProps: {
            status: 'done',
            description:'teste'
          }
        },
        {
          id: 576,
          title: 'Long Event',
          start: '2023-01-04',
          end: '2023-01-10',
          display: ''
        },
        {
          id: 999,
          title: 'Repeating Event' + ' ' + '16:00:00' + ' - ' + '16:15:00',
          start: '2022-10-10T16:00:00',
          end: '2022-10-10T16:15:00',
          backgroundColor: null
        }

      ]
    }
  }



