<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>


<div class="col-md-12">
    <div class="row" style="margin-right: 2px; padding-left: 20px;">
        <img src="../../../assets/img/configuracoes.png" height="130px" width="100px" style="padding: 0%;" />

        <div class="col-md-10" style="padding-top: 15px;">
            <h4 class="espacamentotopo " style="margin-left: 5px;">
                <b>{{filtro_cabecalho}}</b>
            </h4>
            <label>
                {{explicacao}}
                <br>
                <b>#FicaaDica: </b>Em caso de Celular melhor visualizado na horizontal!
            </label>
        </div>
    </div>
</div>

<!--Filtro-->
<div class="col-md-12" style="display: block; border: 0px;padding-bottom: 0%; ">
    <label style=" display: block; border: 0px; padding-bottom: 0%;"><b
            style="padding: 0%; padding-bottom: 0%;">Filtro:</b>
        <span style=" display: block; border: 0px; font-size: x-small; padding-top: 0%;">Consulta por nome</span>
        <input type="text" class="form-control" (keydown)="Consulta()" id="texto_consulta" style="margin-top: 0%;" />
    </label>
</div>

<!--Tabela-->
<div class="panel-body col-md-12">
    <p-table [value]="usuario" [paginator]='false' [rows]="10" [showCurrentPageReport]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" selectionMode="single"
        paginatorTemplate="{RowsPerPageDropdown} {FirstPageLink} {PreviousPageLink} {CurrentPageReport} {NextPageLink} {LastPageLink}"
        (onLazyLoad)='Paginate ($event)' styleClass="p-datatable-responsive-demo" autoLayout="true" [responsive]="true">

        <ng-template pTemplate="header">
            <tr style="background-color: dodgerblue; color: aliceblue;">
                <th>Nome</th>
                <th>Ministério</th>
                <th>Data Compra</th>
                <th>Valor</th>
                <th>Descrição</th>
                <th>Solicitado Reembolso</th>
                <th>Situação Reembolso</th>
                <th>Opções</th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-c>
            <tr>
                <td>{{c.nome}}</td>
                <td>{{c.ministerio_lancamento}}</td>
                <td>{{c.dt_referencia| date: 'dd/MM/yyyy'}}</td>
                <td>R$:{{c.valor|number : '.2-2'}}</td>
                <td>{{c.descricao}}</td>
                <td>{{c.reembolso.replace("T","SIM").replace("F","NÃO")}}</td>
                <td>{{c.aceito}}</td>
                <td><button pButton pRipple type="button" icon="pi pi-cog" class="p-button-rounded p-button-warning"
                        value={{c.iddeducaocompra}} (click)="SetDados($event)" data-toggle="modal"
                        data-target="#exampleModal" title="Opções"></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <!--Paginação-->
    <p-paginator [rows]="10" [paginator]='true' [totalRecords]=qto
        currentPageReportTemplate="Mostrando: {{x}} a {{y}} de {{qto}}" (onPageChange)="Paginate($event)"
        [showCurrentPageReport]="true">
    </p-paginator>

    <hr>
    <!--Botao Retorno-->
    <button pButton pRipple type="button" icon="pi pi-sign-in" title="Retornar" class="p-button-rounded p-button-danger"
        routerLink="/dashboard/resumo"></button> <span></span>
        <button pButton pRipple type="button" icon="pi pi-print"style="margin-left:10px" class="p-button-rounded"
        title="Gerar Documento" data-target="#exampleModal" data-toggle="modal"></button>
    <br>
    <br>
    <br>
    <br>
</div>
<!-- Modal -->
<div id="exampleModal" class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog">

        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Escolha uma operação!</h4>
            </div>
            <div class="modal-body">
                <b>Olá {{nome}}</b>
                <p>Por favor escolha uma das opções abaixo e lembre-se que ao escolhar a mesma, o procedimento será
                    realizado automaticamente.</p>

                <label>Data Incial:</label>
                <input type="date" class="form-control" (change)="SetDataInicial($event)" />
                <label>Data Incial:</label>
                <input type="date" class="form-control" (change)="SetDataFinal($event)" />
                <label>Ministério:</label>
                <select class="custom-select" type="text" (change)="SetMinisterio($event)">
                    <option value="pastor_presidente">Pastor Presidente</option>
                    <option value="pastor_auxiliar">Pastor Auxiliar</option>
                    <option value="ministerio_consolidacao">Ministério Consolidação</option>
                    <option value="ministerio_obreiro">Ministério Obreiros</option>
                    <option value="ministerio_diaconato">Ministério Diaconato</option>
                    <option value="ministerio_louvor">Ministério Louvor</option>
                    <option value="ministerio_secretaria">Ministério Secretária</option>
                    <option value="ministerio_casais">Ministério Casais</option>
                    <option value="ministerio_jovem">Ministério Jovem</option>
                    <option value="ministerio_mulheres">Ministério Mulheres</option>
                    <option value="ministerio_homens">Ministério Homens</option>
                    <option value="ministerio_danca">Ministério Dança</option>
                    <option value="ministerio_patrimonio">Ministério Patrimonio</option>
                    <option value="ministerio_infantil">Ministério Infantil</option>
                    <option value="ministerio_midias">Ministério Mídias</option>
                    <option value="ministerio_eventos">Ministério Eventos</option>
                    <option value="ministerio_sonoplastia">Ministério Sonoplastia</option>
                    <option value="ministerio_geral">Ministério Geral</option>
                </select>
                <label>Tipo reembolso:</label>
                <select class="custom-select" type="text" (change)="SetTipoMinisterio($event)">
                    <option value="todos">TODOS</option>
                    <option value="confirmado">CONFIRMADO</option>
                    <option value="aguardando">AGUARDANDO</option>
                    <option value="não solicitado">NÃO SOLICITADO</option>
                    <option value="recusado">RECUSADO</option>
                </select>
            </div>
            <div class="modal-footer">
                <button pButton pRipple type="button" icon="pi pi-print" class="p-button-rounded"
                        title="Gerar Documento" (click)="GerarRelatorio()"
                    data-dismiss="modal"></button>
                <button pButton pRipple type="button" icon="pi pi-sign-in" class="p-button-rounded p-button-danger"
                    title="Retornar" data-dismiss="modal"></button>
            </div>
        </div>

    </div>
</div>


<!-- Modal -->
<div id="exampleModalimpressao" class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog">

        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Configuração</h4>
            </div>
            <div class="modal-body">
                <b>Olá {{nome}}</b>
                <p>Por favor preencha os dados para geração do relatório.</p>

                <div style="font-size: medium;">
                    <input id="novo" name="base" type="radio" value="Ativar" checked (click)="SetOpcao($event)" />
                    Conceder Reembolso
                </div>
                <div style="font-size: medium;">
                    <input id="novo" name="base" type="radio" value="Inativar" (click)="SetOpcao($event)" />
                    Remover Reembolso
                </div>
                <div style="font-size: medium;">
                    <input id="novo" name="base" type="radio" value="Cancelar" (click)="SetOpcao($event)" />
                    Excluir Compra
                </div>

            </div>
            <div class="modal-footer">
                <button pButton pRipple type="button" icon="pi pi-check-circle"
                    class="p-button-rounded p-button-warning" title="Executar" (click)="Operacao()"
                    data-dismiss="modal"></button>
                <button pButton pRipple type="button" icon="pi pi-sign-in" class="p-button-rounded p-button-danger"
                    title="Retornar" data-dismiss="modal"></button>
            </div>
        </div>

    </div>
</div>
<button class="voltar-ao-topo" id="back"><a class="ancora" style="color: white;"><i
            class="pi pi-arrow-circle-up"></i></a></button>