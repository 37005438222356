import { Injectable } from '@angular/core';
import {Parametros} from "../../../shared/parametros.enum";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {SharedModule} from "../../../shared/shared.module";
import {retry} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class ListagemCadastroCasaisService {
  private urlmembros = Parametros.ENCONTROCASAIS;

  constructor(private http: HttpClient,
              private autenticado: SharedModule) { }

  startedClass = false;
  completedClass = false;
  preventAbuse = false;

  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }

  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }

  Cancelarsuario(idinscricao) {
    this.StartProgressBar();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token
      })
    };
    return this.http
      .post(this.urlmembros + 'PostCancelarCadastro/?parametro=' + idinscricao, "", httpOptions)
      .pipe(retry(2));
  }

  InativarUsuario(idinscricao) {
    this.StartProgressBar();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token
      })
    };
    return this.http
      .post(this.urlmembros + 'PostInativarCadastro/?parametro=' + idinscricao, "", httpOptions)
      .pipe(retry(2));
  }

  AtivarUsuario(idinscricao) {
    this.StartProgressBar();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token
      })
    };
    return this.http
      .post(this.urlmembros + 'PostativarCadastro/?parametro=' + idinscricao, "", httpOptions)
      .pipe(retry(2));
  }

  ConfirmarPagamento(idinscricao) {
    this.StartProgressBar();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token
      })
    };
    return this.http
      .post(this.urlmembros + 'PostConfirmarPagamento/?parametro=' + idinscricao, "", httpOptions)
      .pipe(retry(2));
  }

  Desconfirmar(idinscricao) {
    this.StartProgressBar();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token
      })
    };
    return this.http
      .post(this.urlmembros + 'PostRetirarPagamento/?parametro=' + idinscricao, "", httpOptions)
      .pipe(retry(2));
  }



}



