import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { estabelecimentomodel } from '../Dashboard/Estabelecimento/cadastro-estabelecimento/EstabelecimentoModel';
import { AlertModalService } from '../shared/alert-modal/alert-modal.service';
import { EventEmitterService } from '../shared/EventEmitterService';
import { Parametros } from '../shared/parametros.enum';
import { SharedModule } from '../shared/shared.module';

@Component({
  selector: 'app-consulta-estabelecimento',
  templateUrl: './consulta-estabelecimento.component.html',
  styleUrls: ['./consulta-estabelecimento.component.css']
})
export class ConsultaEstabelecimentoComponent implements OnInit {


  private urlestabelecimento = Parametros.CADASTROESTABELECIMENTO;
  filtro_cabecalho: string = ""
  explicacao: string = "";
  textoconsulta: string = "*";
  idestabelecimento: any;

  constructor(

    private http: HttpClient,
    private primengConfig: PrimeNGConfig,
    private alert: AlertModalService,

  ) {

    EventEmitterService.get('loginAlert').emit(false);
  }

  startedClass = false;
  completedClass = false;
  preventAbuse = false;

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//

  estabelecimento: estabelecimentomodel[];
  qto: number;
  cols: any[];


  ngOnInit() {

    this.cols = [
      { field: 'estabelecimento', header: 'Estabelecimento' },
      { field: 'telefone', header: 'Contato' },
      { field: 'funcionamento', header: 'Funcionamento' },
      { field: 'cliques', header: 'Visualizações' }

    ];

    this.primengConfig.ripple = true;
    this.filtro_cabecalho = "Empresa/Serviços de nossos membros"
    this.explicacao = "Você está na área de consulta de Empresas/Serviços oferecidos por nossos membros!,Lembramos que todos os dados aqui informados são controlado por nossos membros não sendo informados ou cadastrados pela Morada de Deus."
    this.Ranking();
  }

  UpdateClique(event) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
    this.http.get(this.urlestabelecimento + 'GETUpdateClique/?parametro=' + event, httpOptions).subscribe(
      (success) => {

      }
    )
  }

  Ranking() {
    this.StartProgressBar();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
    this.http.get<estabelecimentomodel[]>(this.urlestabelecimento + 'GETEstabelecimentoRanking', httpOptions).subscribe(
      (success) => {
        this.StopProgressBar();
        this.estabelecimento = success;
        this.qto = success.length;
        this.textoconsulta = "";

        if (success.length == 0) {

          this.alert.AlertShowInfo('Empresa/Serviço não encontrada!');
        }

      }
     
    )
    this.StopProgressBar();
  }

  Consulta() {
    
    var texto = (<HTMLInputElement>document.getElementById("texto_consulta")).value;
    if (texto != "") {
      this.textoconsulta = texto;
    }
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    }
    this.StartProgressBar();
    this.http.get<estabelecimentomodel[]>(this.urlestabelecimento + 'GETConsultaEstabelecimento/?parametro=' + this.textoconsulta, httpOptions).subscribe(
      (success) => {
        this.StopProgressBar();
        this.estabelecimento = success;
        this.qto = success.length;
        this.textoconsulta = "";

        if (success.length == 0) {

          this.alert.AlertShowInfo('Empresa/Serviço não encontrada!');
        }
      }

    )
    this.StopProgressBar();
  }

}
