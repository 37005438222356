<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>
<div class="container">
    <div class="col-md-12">
        <div class="row" style="margin-right: 2px; padding-left: 20px;">
            <img src="../../../assets/img/detalhe.png" height="130px" width="100px" style="padding: 0%;" />
    
            <div class="col-md-10" style="padding-top: 15px;">
                <h4 class="espacamentotopo " style="margin-left: 5px;">
                    <b>Detalhe Cadastro</b>
                </h4>
                <label>
                   Aqui você encontra o detalhe do cadastro realizado na plataforma!
                    <br>
                    <b>#FicaaDica: </b>Em caso de Celular melhor visualizado na horizontal!
                </label>
            </div>
        </div>
    </div>
    <hr>
    <br>
    <label><b>Registro: </b>{{registro}}</label><br><br>
    <label><b>Nome: </b>{{nome}}</label>&nbsp;&nbsp;&nbsp;&nbsp;<label><b>CPF: </b>{{cpf}}</label><br>
    <label><b>Estado Civil: </b>{{estadocivil}}</label>&nbsp;&nbsp;&nbsp;&nbsp;<label><b>Data Cadastro:
        </b>{{dt_cadastro}}</label><br>
    <label><b>Genero: </b>{{genero}}</label>&nbsp;&nbsp;&nbsp;&nbsp;<label><b>Data Nascimento:
        </b>{{dt_nascimento}}</label><br>
    <label><b>Telefone: </b>{{telefone}}</label>&nbsp;&nbsp;&nbsp;&nbsp;<label><b>E-mail:
        </b>{{email}}</label><br><br>
    <div *ngIf="ativo =='AGUARDANDO LIBERAÇÃO'">
        <label><b>Status Cadastro: </b><label
                style="background-color: yellow;">{{ativo}}</label></label>&nbsp;&nbsp;&nbsp;&nbsp;<label><b>Referencia:
            </b>{{anoreferencia}}</label><br>
    </div>
    <div *ngIf="ativo =='CANCELADO'">
        <label><b>Status Cadastro: </b><label
                style="background-color: red;">{{ativo}}</label></label>&nbsp;&nbsp;&nbsp;&nbsp;<label><b>Referencia:
            </b>{{anoreferencia}}</label><br>
            <label><b>Motivo Cancelamento: </b><label
                style="background-color: red;">{{motivocancelamento}}</label></label><br>
                <label><b>Data Cancelamento: </b><label
                    style="background-color: red;">{{dt_cancelamento}}</label></label>
    </div>
    <div *ngIf="ativo =='ATIVO'">
        <label><b>Status Cadastro: </b><label
                style="background-color: green;">{{ativo}}</label></label>&nbsp;&nbsp;&nbsp;&nbsp;<label><b>Referencia:
            </b>{{anoreferencia}}</label><br>
    </div>
    <div *ngIf="ativo =='INATIVO'">
        <label><b>Status Cadastro: </b><label
                style="background-color: blue;">{{ativo}}</label></label>&nbsp;&nbsp;&nbsp;&nbsp;<label><b>Referencia:
            </b>{{anoreferencia}}</label><br>
    </div>
    <label><b>Receber Comunicado: </b>{{recebercomunicado}}</label><br>
    <label><b>Dizimista: </b>{{dizimista}}</label><br>
    <label><b>Possui Cargo: </b>{{cargo}}</label><br>
    <label><b>Congregação: </b>{{congregacao}}</label><br><br>
    <label><b>Cep: </b>{{cep}}</label><br>
    <label><b>Logradouro: </b>{{logradouro}}</label>&nbsp;&nbsp;&nbsp;&nbsp;<label><b>Numero:
        </b>{{numero}}</label>&nbsp;&nbsp;&nbsp;&nbsp;<label><b>Apto:
        </b>{{apto}}</label><br>
    <label><b>Bairro: </b>{{bairro}}</label>&nbsp;&nbsp;&nbsp;&nbsp;<label><b>Cidade:
        </b>{{cidade}}</label>&nbsp;&nbsp;&nbsp;&nbsp;<label><b>UF:
        </b>{{uf}}</label><br>

    <br>
    <hr>
    <button pButton pRipple type="button" icon="pi pi-sign-in" title="Retornar" class="p-button-rounded p-button-danger"
        routerLink="/dashboard/gerenciamento/membros-cancelados"></button>
    <br>
    <br>
    <br>
    <br>
    <br>
</div>
<button class="voltar-ao-topo" id="back"><a class="ancora" style="color: white;"><i
    class="pi pi-arrow-circle-up"></i></a></button>