import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { UsuarioModel } from 'src/app/Recuperacoes/recuperar-autenticacao/usuario-model';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { PerfisModel } from './PerfisModel';
import { MembrosPerfilAjustesService } from './membros-perfil-ajustes.service';
import { PerfilService } from '../../perfil/perfil.service';


@Component({
  selector: 'app-membros-perfil-ajustes',
  templateUrl: './membros-perfil-ajustes.component.html',
  styleUrls: ['./membros-perfil-ajustes.component.css']
})
export class MembrosPerfilAjustesComponent implements OnInit {
  rota: Router;
  perfil: string;
  nome: string;
  cpf: string;
  idusuario: string;


  formusuario: FormGroup;
  formperfil: FormGroup;
  formcursos: FormGroup;
  submitted = false;
  modelusuario: UsuarioModel;
  modelperfil: PerfisModel;
  administrador: boolean = false;
  membro: boolean = false;
  presidente: boolean = false;
  financeiro: boolean = false;
  lideranca: boolean = false;
  auxiliar: boolean = false;
  aluno: boolean = false;
  gestao: boolean = false;
  secretaria: boolean = false;
  checkout: boolean = false;
  professor: boolean = false;
  consolidacao: boolean = false;
  seminario_total: boolean = false;
  seminario_parcial: boolean = false;
  evento_homens: boolean = false;
  evento_mulheres: boolean = false;
  evento_jovens: boolean = false;
  evento_casais: boolean = false;
  evento_cursos: boolean = false;
  evento_gerais: boolean = false;
  evento_infantil: boolean = false;
  curso_casais: boolean = false;
  curso_casais_total: boolean = false;
  patrimonio: boolean = false;
  patrimonio_total: boolean = false;
  solicitacao_compra: boolean = false;
  deducao_compra: boolean = false;
  deducao_oferta: boolean = false;
  contabilidade: boolean = false;




  constructor(private http: HttpClient,
    private primengConfig: PrimeNGConfig,
    private alert: AlertModalService,
    private autenticado: SharedModule,
    private s: MembrosPerfilAjustesService,
    private formBuilder: FormBuilder,
    private service: PerfilService,
    private serviceperfil: MembrosPerfilAjustesService,
    r: Router
  ) {
    this.rota = r;
    this.perfil = this.autenticado.perfil;
    this.nome = this.autenticado.nome;
    this.cpf = this.autenticado.cpf;
    this.modelperfil = new PerfisModel();
    this.modelusuario = new UsuarioModel();

  }

  startedClass = false;
  completedClass = false;
  preventAbuse = false;

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//


  criarformusuario() {
    this.formusuario = this.formBuilder.group({
      cpf: ['', Validators.required],
      nome: ['', Validators.required]

    });
  }

  criarformperfil() {
    this.formperfil = this.formBuilder.group({
      idusuario: [this.idusuario, Validators.required],
      administrador: [false, Validators.required],
      membro: [false, Validators.required],
      presidente: [false, Validators.required],
      financeiro: [false, Validators.required],
      lideranca: [false, Validators.required],
      auxiliar: [false, Validators.required],
      aluno: [false, Validators.required],
      gestao: [false, Validators.required],
      secretaria: [false, Validators.required],
      checkout: [false, Validators.required],
      professor: [false, Validators.required],
      consolidacao: [false, Validators.required],
      seminario_total: [false, Validators.required],
      seminario_parcial: [false, Validators.required],
      evento_homens: [false, Validators.required],
      evento_mulheres: [false, Validators.required],
      evento_casais: [false, Validators.required],
      evento_cursos: [false, Validators.required],
      evento_gerais: [false, Validators.required],
      evento_infantil: [false, Validators.required],
      patrimonio: [false, Validators.required],
      patrimonio_total: [false, Validators.required],
      solicitacao_compra: [false, Validators.required],
      curso_casais: [false, Validators.required],
      curso_casais_total: [false, Validators.required],
      evento_jovens: [false, Validators.required],
      deducao_compra: [false, Validators.required],
      deducao_oferta: [false, Validators.required],
      contabilidade: [false, Validators.required]


    });
  }




  ngOnInit(): void {
    if (this.autenticado.token == null) {
      this.autenticado.ResetDados();
      this.alert.AlertShowDanger('Usuário não autenticado, redirecionando...')
      this.rota.navigate(['/login']);
    } else {
      if (this.autenticado.ValidarToken() != true) {
        this.autenticado.ResetDados();
        this.alert.AlertShowDanger('Token Invalido!')
        this.rota.navigate(['/login']);
      }
    }

    this.idusuario = localStorage.getItem('UsuarioPerfilDetalhe');
    if (this.idusuario == null || this.idusuario == "") {
      this.rota.navigate(['/dashboard/perfil/perfil-usuario']);
    } else {
      localStorage.removeItem('UsuarioPerfilDetalhe');


      this.criarformusuario();
      this.criarformperfil();
      this.carregarusuario();
      this.CarrgarPerfil();

      this.modelperfil.idusuario = this.idusuario;
    }
  }

  carregarusuario() {
    this.service
      .GETUsuarioID(this.idusuario)
      .subscribe(
        (success) => {
          if (success.nome != null) {
            (this.modelusuario.nome = success.nome),
              (this.modelusuario.cpf = success.cpf)
          } else {
            this.rota.navigate(['/dashboard/perfil/perfil-usuario']);
          }
        },
        (error) => {
          this.StopProgressBar();
          this.alert.AlertShowDanger(
            '( ' + error.status + ' ) ' + error.message
          );
        }
      );
  }


  get p() {
    return this.formperfil.controls;
  }


  get u() {
    return this.formusuario.controls;
  }

  CarrgarPerfil() {
    this.serviceperfil
      .GETPErfil(this.idusuario)
      .subscribe(
        (success) => {
          this.modelperfil.idusuario = this.idusuario;

          if (success.administrador == "T") {
            this.modelperfil.administrador = true;
            this.administrador = true;
          }
          if (success.presidente == "T") {
            this.modelperfil.presidente = true;
            this.presidente = true;
          }
          if (success.financeiro == "T") {
            this.modelperfil.financeiro = true;
            this.financeiro = true;
          }
          if (success.lideranca == "T") {
            this.modelperfil.lideranca = true;
            this.lideranca = true;
          }
          if (success.auxiliar == "T") {
            this.modelperfil.auxiliar = true;
            this.auxiliar = true;
          }
          if (success.aluno == "T") {
            this.modelperfil.aluno = true;
            this.aluno = true;
          }
          if (success.membro == "T") {
            this.modelperfil.membro = true;
            this.membro = true;
          }
          if (success.gestao == "T") {
            this.modelperfil.gestao = true;
            this.gestao = true;
          }
          if (success.secretaria == "T") {
            this.modelperfil.secretaria = true;
            this.secretaria = true;
          }
          if (success.checkout == "T") {
            this.modelperfil.checkout = true;
            this.checkout = true;
          }
          if (success.professor == "T") {
            this.modelperfil.professor = true;
            this.professor = true;
          }
          if (success.consolidacao == "T") {
            this.modelperfil.consolidacao = true;
            this.consolidacao = true;
          }
          if (success.seminario_parcial == "T") {
            this.modelperfil.seminario_parcial = true;
            this.seminario_parcial = true;
          }
          if (success.seminario_total == "T") {
            this.modelperfil.seminario_total = true;
            this.seminario_total = true;
          }

          if (success.evento_casais == "T") {
            this.modelperfil.evento_casais = true;
            this.evento_casais = true;
          }
          if (success.evento_cursos == "T") {
            this.modelperfil.evento_cursos = true;
            this.evento_cursos = true;
          }
          if (success.evento_gerais == "T") {
            this.modelperfil.evento_gerais = true;
            this.evento_gerais = true;
          }
          if (success.evento_homens == "T") {
            this.modelperfil.evento_homens = true;
            this.evento_homens = true;
          }
          if (success.evento_mulheres == "T") {
            this.modelperfil.evento_mulheres = true;
            this.evento_mulheres = true;
          }
          if (success.evento_infantil == "T") {
            this.modelperfil.evento_infantil = true;
            this.evento_infantil = true;
          }
          if (success.patrimonio == "T") {
            this.modelperfil.patrimonio = true;
            this.patrimonio = true;
          }

          if (success.patrimonio_total == "T") {
            this.modelperfil.patrimonio_total = true;
            this.patrimonio_total = true;
          }

          if (success.solicitacao_compra == "T") {
            this.modelperfil.solicitacao_compra = true;
            this.solicitacao_compra = true;
          }

          if (success.curso_casais == "T") {
            this.modelperfil.curso_casais = true;
            this.curso_casais = true;
          }

          if (success.curso_casais_total == "T") {
            this.modelperfil.curso_casais_total = true;
            this.curso_casais_total = true;
          }

           if (success.evento_jovens == "T") {
            this.modelperfil.evento_jovens = true;
            this.evento_jovens = true;
          }

          if (success.deducao_compra == "T") {
            this.modelperfil.deducao_compra = true;
            this.deducao_compra = true;
          }

          if (success.deducao_oferta == "T") {
            this.modelperfil.deducao_oferta = true;
            this.deducao_oferta = true;
          }
          if (success.contabilidade == "T") {
            this.modelperfil.contabilidade = true;
            this.contabilidade = true;
          }





        },
        (error) => {
          this.rota.navigate(['/dashboard/perfil/perfil-usuario']);
        }
      );
  }


  onSubmit() {
    this.submitted = true;

    if (this.formusuario.invalid) {
      this.alert.AlertShowDanger('Favor verificar os campos em destaque!');
      return;
    }
    if (this.formperfil.invalid) {
      this.alert.AlertShowDanger('Favor verificar os campos em destaque!');
      return;
    }

    this.serviceperfil.POSTPerfil(this.formperfil.value).subscribe(
      () => {
        this.alert.AlertShowSucess('Perfil atualizado com sucesso!');
      },
      (error) => {
        this.alert.AlertShowDanger('Erro ao Atualizar Perfil!');
      });
  }

}
