export class ComunicadoVisitantesModel {


    cpf: string = '';
    nome: string = '';
    para: string = '';
    assunto: string = '';
    mensagem: string = '';
    c: string = '';
    anexo: string = "";
}
