export class UsuarioModel {

    nome: string = '';
    senha: string = '';
    token: string = '';
    identificacao: string = '';
    opcao: boolean = false;
    ativo: string = '';
    email: string = '';
    hash: string = '';
    dt_cancelamento: any = null;
    motivo_cancelamento: string = "";
    cpf: any = "";
    estado_civil: string = "";
    genero: string = "";
    dt_nascimento: any = "";
    dt_cadastro: any = "";
    contato_preferencial: string = "";
    cargo: string = "";
    congregacao_nome: string = "";
    dizimista: string = "";
    cep: string = "";
    logradouro: string = "";
    numero: string = "";
    apto: string = "";
    bairro: string = "";
    cidade: string = "";
    uf: string = "";
    ano_referencia: string = "";
    receber_comunicado: string = "";
    numero_registro: string = "";

}
