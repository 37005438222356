<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>

<div class="col-md" style="margin-top: 10px; margin-left: 20px;">
    <P style="margin-bottom: 0%; font-size: larger;">
        <b>Resumo:</b>
    </P>

    <label style="margin-top: 0%; margin-bottom: 0%;">Aqui você encontra o resumo de sua conta!</label>
    <br>
    <hr>
    <b>Seu Painel:</b><br><label style="font-size: small;" >Seus Registros na plataforma</label>
    <div class="row">

        <div class="card" style="width: 8rem; margin-right: 5px; height: 7rem;">

            <h1 style="text-align: center; margin-top: 5px; ">{{elogio}}</h1>
            <a [routerLink]="['/dashboard/listagem/lista-elogio']" style="text-align: center;">
                <label class="card-title"><b>Elogios<br>registrados</b></label>
              
            </a>
        </div>
        <div class="card" style="width: 8rem; margin-right: 5px;">
            <h1 style="text-align: center; margin-top: 5px; ">{{visita}}</h1>
            <a [routerLink]="['/dashboard/listagem/lista-visita']" style="text-align: center;">
                <label class="card-title"><b>Visita<br>solicitadas</b></label>
            </a>
        </div>
        <div class="card" style="width: 8rem; margin-right: 5px;">
            <h1 style="text-align: center; margin-top: 5px; ">{{reclamacao}}</h1>

            <a [routerLink]="['/dashboard/listagem/lista-reclamacao']" style="text-align: center;">
                <label class="card-title"><b>Reclamações<br>realizadas</b></label>
            </a>

        </div>
        <div class="card" style="width: 8rem; margin-right: 5px;">
            <h1 style="text-align: center; margin-top: 5px; ">{{sugestoes}}</h1>

            <a [routerLink]="['/dashboard/listagem/lista-sugestoes']" style="text-align: center;">

                <label class="card-title"><b>Sugestões<br>registradas</b></label>

            </a>

        </div>
        <div class="card" style="width: 8rem; margin-right: 5px;">
            <h1 style="text-align: center; margin-top: 5px; ">{{testemunho}}</h1>

            <a [routerLink]="['/dashboard/listagem/lista-testemunho']" style="text-align: center;">

                <label class="card-title"><b>Testemunho<br>registrado</b></label>

            </a>
        </div>

        <div class="card" style="width: 8rem; margin-right: 5px;">
            <h1 style="text-align: center; margin-top: 5px; ">{{servicos}}</h1>

            <a [routerLink]="['/dashboard/listagem-estabelecimento']" style="text-align: center;">

                <label class="card-title"><b>Emp/Serviços<br>registrados</b></label>

            </a>
        </div>


        <div class="card" style="width: 8rem; margin-right: 5px;">
            <h1 style="text-align: center; margin-top: 5px; ">{{oportunidades_minhas}}</h1>

            <a [routerLink]="['/dashboard/listagem-oportunidade']" style="text-align: center;">

                <label class="card-title"><b>Oportunidades<br>registradas</b></label>

            </a>

        </div>
        <div class="card" style="width: 8rem; margin-right: 5px;">
            <h1 style="text-align: center; margin-top: 5px; ">{{acessos}}</h1>

            <a [routerLink]="['/dashboard/listagem/lista-acessos']" style="text-align: center;">

                <label class="card-title"><b>Acessos<br>realizados</b></label>

            </a>

        </div>
    </div>
    <div class="row" style="margin-top: 5px; margin-right: 5px;">

    </div>

    <hr>
    <b>Oportunidades / serviços:</b><br><label style="font-size: small;" >Registros de todos membros a plataforma</label>
    <div class="row">

        <div class="card" style="width: 8rem; margin-right: 5px; height: 7rem;">

            <h1 style="text-align: center; margin-top: 5px; ">{{oportunidades_abertas}}</h1>
            <a [routerLink]="['/dashboard/consulta/consulta-geral-oportunidades']" style="text-align: center;">
                <label class="card-title"><b>Todas<br>oportunidades</b></label>
              
            </a>
        </div>
        <div class="card" style="width: 8rem; margin-right: 5px;">
            <h1 style="text-align: center; margin-top: 5px; ">{{servicos_empresas}}</h1>
            <a [routerLink]="['/dashboard/consulta/consulta-geral-servicos-estabelecimentos']" style="text-align: center;">
                <label class="card-title"><b>Todas<br>Emp./Serviços</b></label>
            </a>
        </div>

    </div>
    <div class="row" style="margin-top: 5px; margin-right: 5px;">

    </div>
    <!--Acima e visivl a todos!-->

    <!--Somente Administrador | Presidente | Gestão-->
    <div *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao') ">
        <hr>
        <b>Sua Administração:</b>
        <div class="row">

            <div class="card" style="width: 8rem; margin-right: 5px; height: 7rem;">
                <h1 style="text-align: center; margin-top: 5px; ">{{ativos}}</h1>

                <a [routerLink]="['/dashboard/gerenciamento/membros-ativos']" style="text-align: center;">
                    <label class="card-title"><b>Membros<br>ativos</b></label>
                </a>

            </div>
            <div class="card" style="width: 8rem; margin-right: 5px;">
                <h1 style="text-align: center; margin-top: 5px; ">{{inativos}}</h1>
                <a [routerLink]="['/dashboard/gerenciamento/membros-inativos']" style="text-align: center;">
                    <label class="card-title"><b>Membros<br>Inativos</b></label>
                </a>
            </div>
            <div class="card" style="width: 8rem;margin-right: 5px;">
                <h1 style="text-align: center; margin-top: 5px; ">{{aguardando_liberacao}}</h1>
                <a [routerLink]="['/dashboard/gerenciamento/membros-aguardando-liberacao']" style="text-align: center;">
                    <label class="card-title"><b>Aguardando<br>Liberação</b></label>
                </a>
            </div>
            <div class="card" style="width: 8rem; margin-right: 5px;">
                <h1 style="text-align: center; margin-top: 5px; ">{{cancelados}}</h1>

                <a [routerLink]="['/dashboard/gerenciamento/membros-cancelados']" style="text-align: center;">
                    <label class="card-title"><b>Membros<br>Cancelados</b></label>
                </a>
            </div>
            <div class="card" style="width: 8rem; margin-right: 5px;">
                <h1 style="text-align: center; margin-top: 5px; ">{{geral}}</h1>
                <a [routerLink]="['/dashboard/gerenciamento/listagem-membros']" style="text-align: center;">
                    <label class="card-title"><b>Total<br>Registros</b></label>
                </a>
            </div>
            <hr>
        </div>
    </div>

    <br>
    <br>

</div>
