import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { cultomodel } from 'src/app/culto-inscricao/CultoModel';
import { Parametros } from 'src/app/shared/parametros.enum';
import { SharedModule } from 'src/app/shared/shared.module';

@Injectable({
  providedIn: 'root'
})
export class ConfirmarDocumentoService {
  private urlcadastrousuario = Parametros.CADASTROCULTO;
  private rota: Router;


  constructor(private http: HttpClient,
    router: Router,
    private autenticado: SharedModule
  ) {
    this.rota = router;
  }
  ConfirmarCadastro(_hash,_evento) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token
      })
    };
    return this.http
      .get<cultomodel>(this.urlcadastrousuario + 'GETConfirmarCadastroDocumento/?documento=' + _hash+'&evento='+_evento, httpOptions)
      .pipe();
  }

}
