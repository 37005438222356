export class ParametrosModel {


    idparametro: number = 1;
    proximo_registro: number = 1000;
    email_contato: string = '';
    email_elogio: string = '';
    email_problema: string = '';
    email_visita: string = '';
    email_reclamacao: string = '';
    email_testemunho: string = '';
    email_sugestao: string = '';
    email_visitantes: string = '';
    email_cancelamento: string = '';
    email_seminario: string = '';
    email_seminario_infantil: string = '';
    email_curso_casais: string = '';
    email_pedido_compra: string = '';
    email_secretaria: string = '';
    limite_culto: Number = 0;
    limite_culto_kids: Number = 0;
    limite_encontro_homens: Number = 0;
    limite_encontro_mulheres: Number = 0;
    limite_curso_casais: Number = 0;
    limite_congresso_mulheres: Number=0;
    limite_congresso_homens: Number=0;
    limite_congresso_jovens: Number=0;




}
