export class PerfisModel {

    idusuario: any;
    administrador: boolean = false;
    membro: boolean = false;
    presidente: boolean = false;
    financeiro: boolean = false;
    lideranca: boolean = false;
    auxiliar: boolean = false;
    aluno: boolean = false;
    gestao: boolean = false;
    secretaria: boolean = false;
    checkout: boolean = false;
    professor: boolean = false;
    consolidacao: boolean = false;
    seminario_total: boolean = false;
    seminario_parcial: boolean = false;
    evento_homens: boolean = false;
    evento_jovens: boolean = false;
    evento_mulheres: boolean = false;
    evento_casais: boolean = false;
    evento_gerais: boolean = false;
    evento_cursos: boolean = false;
    evento_infantil: boolean = false;
    patrimonio: boolean = false;
    patrimonio_total: boolean = false;
    solicitacao_compra: boolean = false;
    curso_casais: boolean = false;
    curso_casais_total: boolean = false;
    deducao_compra: boolean = false;
    deducao_oferta: boolean = false;
    contabilidade: boolean = false;
}