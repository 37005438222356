import { Component, OnInit } from '@angular/core';
import { EventEmitterService } from '../shared/EventEmitterService';
import { SharedModule } from '../shared/shared.module';

@Component({
  selector: 'app-pastores',
  templateUrl: './pastores.component.html',
  styleUrls: ['./pastores.component.css']
})
export class PastoresComponent implements OnInit {

  constructor(private autenticado: SharedModule) {
    EventEmitterService.get('loginAlert').emit(false);
  }

  ngOnInit() {
    this.autenticado.ResetDados();
  }

  
}
