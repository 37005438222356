<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>
<div class="row">

    <div class="col-md-12" style="margin-top: 7px;">

        <div>
            <h4 style="text-align: center;">
                <i class="fas fa-lock-open"></i>&nbsp;<b>{{filtro_cabecalho}}</b>
            </h4>
            <p>
                &nbsp;&nbsp;&nbsp;&nbsp;{{explicacao}}
                <br>
                &nbsp;&nbsp;&nbsp;&nbsp;<b>#FicaaDica: </b>Em caso de Celular melhor visualizado na horizontal!
            </p>
        </div>
    </div>
    <br>

    <!--Filtro-->
    <div class="col-md-12" style="display: block; border: 0px;padding-bottom: 0%; ">
        <label style=" display: block; border: 0px; padding-bottom: 0%;"><b
                style="padding: 0%; padding-bottom: 0%;">Filtro:</b>
            <span style=" display: block; border: 0px; font-size: x-small; padding-top: 0%;">Consulta por nome</span>
            <input type="text" class="form-control" (keydown)="Consulta()" id="texto_consulta"
                style="margin-top: 0%;" />
        </label>
    </div>

    <!--Tabela-->
    <div class="panel-body col-md-12">
        <p-table [value]="sugestoes" [columns]="cols" [paginator]='false' [rows]="10" [showCurrentPageReport]="true"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" selectionMode="single"
            paginatorTemplate="{RowsPerPageDropdown} {FirstPageLink} {PreviousPageLink} {CurrentPageReport} {NextPageLink} {LastPageLink}"
            (onLazyLoad)='Paginate ($event)' styleClass="p-datatable-responsive-demo" [responsive]="true"
            autoLayout="true" id="printarea">
            <ng-template pTemplate="header" let-sugestoes let-columns>
                <tr style="background-color: dodgerblue; color: aliceblue;">
                    <th *ngFor="let col of columns">
                        {{col.header}}
                    </th>
                   
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-c>
                <tr>
                    <td>
                        <span class="p-column-title">Data Registro</span>
                        {{c.dt_envio | date: 'dd/MM/yyyy'}}
                    </td>
                    <td>
                        <span class="p-column-title">Tipo Sugestão</span>
                        {{c.tipo_sugestao}}
                    </td>
                    <td *ngIf="c.visualizada === 'F'">
                        <span class="p-column-title">Visualizada</span>
                        SIM
                    </td>
                    <td *ngIf="c.visualizada === 'T'">
                        <span class="p-column-title">Visualizada</span>
                        NÃO
                    </td>

                    <td *ngIf="c.visualizada === 'F'">
                        <span class="p-column-title">Excluir</span>
                        <button pButton pRipple type="button" icon="pi pi-trash" class="p-button-rounded"
                            style="background-color: red;" styleClass="rounded-button ui-button-danger"
                            value={{c.idsugestao}} {{idvisita}}={{c.idsugestao}} (click)="SetDados($event)"
                            data-toggle="modal" data-target="#exampleModal" title="Exluir Sugestão"></button>
                    </td>
                    <td *ngIf="c.visualizada === 'T'">
                        <span class="p-column-title">Excluir</span>
                        <button pButton pRipple type="button" icon="pi pi-trash" class="p-button-rounded"
                            style="background-color: gray;" disabled styleClass="rounded-button ui-button-gray"
                            value={{c.idsugestao}} title="Sugestão Lida ( não permitido exclusão )"></button>
                    </td>
                    <td>
                        <span class="p-column-title">Detalhes</span>
                        <button pButton pRipple type="button" icon="pi pi-search-plus" class="p-button-rounded"
                            style="background-color: green;" styleClass="rounded-button ui-button-success"
                            value={{c.idsugestao}} (click)="BotaoDetalhe($event)" title="Detalhe"></button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <!--Paginação-->
        <p-paginator [rows]="10" [paginator]='true' [totalRecords]=qto
            currentPageReportTemplate="Mostrando: {{x}} a {{y}} de {{qto}}" (onPageChange)="Paginate($event)"
            [showCurrentPageReport]="true">
        </p-paginator>
        <hr>
        <!--Botao Novo-->
        <button pButton pRipple type="button" icon="pi pi-plus-circle" title="Novo"
            class="p-button-rounded p-button-success" routerLink="/dashboard/sugestoes"
            style="margin-right: 10px;"></button>

        <!--Botao Retorno-->
        <button pButton pRipple type="button" icon="pi pi-sign-in" title="Retornar"
            class="p-button-rounded p-button-danger" routerLink="/dashboard/resumo"></button>
        <br>
        <br>
        <br>
    </div>
    <!-- Modal -->
    <div id="exampleModal" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog">

            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Confirma Exclusão?</h4>
                </div>
                <div class="modal-body">
                    <b>Olá {{nome}}</b>
                    <p>Ao excluir a sugestão um e-mail será enviado para gestão da igreja informando a
                        desistencia.</p>
                    <p>Um contato ainda pode ser realizado, mas não se preocupe que respeitamos sua decisão!.</p>

                </div>
                <div class="modal-footer">
                    <button pButton pRipple type="button" icon="pi pi-trash" class="p-button-rounded" title="Excluir"
                        (click)="BotaoExcluir()" data-dismiss="modal"></button>
                    <button pButton pRipple type="button" icon="pi pi-sign-in" class="p-button-rounded p-button-danger"
                        title="Retornar" data-dismiss="modal"></button>
                </div>
            </div>

        </div>
    </div>
</div>
<button class="voltar-ao-topo" id="back"><a class="ancora" style="color: white;"><i
            class="pi pi-arrow-circle-up"></i></a></button>