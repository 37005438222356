<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>
<div class="container">
    <div class="col-md-12">
        <div class="row" style="margin-right: 2px; padding-left: 20px;">
            <img src="../../../assets/img/detalhe.png" height="130px" width="100px" style="padding: 0%;" />
    
            <div class="col-md-10" style="padding-top: 15px;">
                <h4 class="espacamentotopo " style="margin-left: 5px;">
                    <b>Detalhe Cadastro visitante</b>
                </h4>
                <label>
                   Aqui você encontra o detalhe do cadastro de visitante realizado na plataforma!
                    <br>
                    <b>#FicaaDica: </b>Em caso de Celular melhor visualizado na horizontal!
                </label>
            </div>
        </div>
    </div>
    <hr>
    <br>
    <label><b>Nome: </b>{{nome}}</label><br>
    <label><b>Estado Civil: </b>{{estadocivil}}</label>&nbsp;&nbsp;&nbsp;&nbsp;<label><b>Data Nascimento:
        </b>{{dt_nascimento}}</label><br>
    <label><b>Telefone: </b>{{telefone}}</label>&nbsp;&nbsp;&nbsp;&nbsp;<label><b>E-mail:
        </b>{{email}}</label><br><br>
    <label><b>Primeira Visita: </b>{{primeira_visita}}</label><br>
    <label><b>Receber Visita: </b>{{Receber_visita}}</label><br>
    <label><b>Igreja Pertencente: </b>{{igreja_pertence}}</label><br>
    <label><b>Dtata Visita / Culto: </b>{{dt_culto}}</label><br>
    <label><b>Convidado por: </b>{{convite}}</label><br><br>
    <label><b>Respondido: </b>{{respondido}}</label><br>
    <label><b>Data Contato: </b>{{dt_respondido}}</label><br>
    <label><b>Mensagem Deixada: </b>{{mensagem}}</label>   

    <br>
    <hr>
    <button pButton pRipple type="button" icon="pi pi-sign-in" title="Retornar" class="p-button-rounded p-button-danger"
        routerLink="/dashboard/gerenciamento/visitantes/visitantes-listagem"></button>
    <br>
    <br>
    <br>
    <br>
    <br>
</div>
<button class="voltar-ao-topo" id="back"><a class="ancora" style="color: white;"><i
            class="pi pi-arrow-circle-up"></i></a></button>