export class Visitante {

  nome: string = '';
  estado_civil = '';
  dt_nascimento: any = null;
  telefone: string = '';
  email: string = '';
  dt_culto: any;
  igreja_pertence: string = '';
  primeira_visita: string = '';
  receber_visita: string = '';
  convite: string = '';
  receber_copia: boolean = false;
  mensagem: string = '';
  respondido:string='';
  dt_respondido:any;

}
