import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { ParametrosModel } from './ParametrosModel';
import { ConfiguracoesService } from './configuracoes.service';
import { Router } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
@Component({
  selector: 'app-configuracaoes',
  templateUrl: './configuracaoes.component.html',
  styleUrls: ['./configuracaoes.component.css']
})
export class ConfiguracaoesComponent implements OnInit {
  startedClass = false;
  completedClass = false;
  preventAbuse = false;
  formparametros: FormGroup;
  submitted: boolean;
  modelparametros: ParametrosModel;
  rota: Router;

  constructor(
    private formBuilder: FormBuilder,
    private alert: AlertModalService,
    private serviceparametros: ConfiguracoesService,
    private router: Router,
    private autenticado: SharedModule,
  ) {

    this.rota = this.router;
    this.modelparametros = new ParametrosModel();
  }

  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }

  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }

  get f() {
    return this.formparametros.controls;
  }

  ngOnInit(): void {
    if (this.autenticado.token == null) {
      this.autenticado.ResetDados();
      this.alert.AlertShowDanger('Usuário não autenticado, redirecionando...')
      this.rota.navigate(['/login']);
    } else {
      if (this.autenticado.ValidarToken() != true) {
        this.autenticado.ResetDados();
        this.alert.AlertShowDanger('Token Invalido!')
        this.rota.navigate(['/login']);
      } else {
        this.modelparametros = new ParametrosModel();
        this.criarform();
        this.CarregarParametros(this.autenticado.token);
      }
    }
  }

  CarregarParametros(token) {
    this.serviceparametros
      .GetParametros(token)
      .subscribe(
        (success) => {
          if (success != null) {
            (this.modelparametros.proximo_registro = success.proximo_registro),
              (this.modelparametros.email_contato = success.email_contato),
              (this.modelparametros.email_elogio = success.email_elogio),
              (this.modelparametros.email_problema = success.email_problema),
              (this.modelparametros.email_reclamacao = success.email_reclamacao),
              (this.modelparametros.email_sugestao = success.email_sugestao),
              (this.modelparametros.email_testemunho = success.email_testemunho),
              (this.modelparametros.email_visita = success.email_visita),
              (this.modelparametros.email_visitantes = success.email_visitantes),
              (this.modelparametros.email_cancelamento = success.email_cancelamento),
              (this.modelparametros.email_seminario = success.email_seminario),
              (this.modelparametros.limite_culto = success.limite_culto),
              (this.modelparametros.limite_culto_kids = success.limite_culto_kids),
              (this.modelparametros.limite_encontro_homens = success.limite_encontro_homens),
              (this.modelparametros.limite_encontro_mulheres = success.limite_encontro_mulheres),
              (this.modelparametros.email_seminario_infantil = success.email_seminario_infantil),
              (this.modelparametros.email_curso_casais = success.email_curso_casais),
              (this.modelparametros.email_pedido_compra = success.email_pedido_compra),
              (this.modelparametros.limite_curso_casais = success.limite_curso_casais),
              (this.modelparametros.email_secretaria = success.email_secretaria),
              (this.modelparametros.limite_congresso_mulheres = success.limite_congresso_mulheres),
              (this.modelparametros.limite_congresso_homens = success.limite_congresso_homens),
              (this.modelparametros.limite_congresso_jovens = success.limite_congresso_jovens)

          }
        },
        (error) => {
          this.StopProgressBar();
          this.alert.AlertShowDanger(
            '( ' + error.status + ' ) ' + error.message
          );
        }
      );
  }
  criarform() {
    this.formparametros = this.formBuilder.group({
      proximo_registro: [1000, Validators.required],
      idparametro: [1],
      email_contato: [''],
      email_elogio: [''],
      email_problema: [''],
      email_visita: [''],
      email_reclamacao: [''],
      email_visitantes: [''],
      email_sugestao: [''],
      email_testemunho: [''],
      email_cancelamento: [''],
      email_curso_casais: [''],
      email_pedido_compra: [''],
      email_secretaria: [''],
      email_seminario: [''],
      email_seminario_infantil: [''],
      limite_culto: [''],
      limite_culto_kids: [''],
      limite_encontro_homens: [''],
      limite_encontro_mulheres: [''],
      limite_curso_casais: [''],
      limite_congresso_mulheres:[''],
      limite_congresso_homens:[''],
      limite_congresso_jovens:['']


    });
  }


  onSubmit() {
    this.submitted = true;
    this.serviceparametros.UpdateParametros(this.autenticado.token, this.formparametros.value).subscribe(
      () => {
        this.alert.AlertShowSucess('Parametros Atualizados com sucesso!');
      },
      (error) => {
        this.alert.AlertShowDanger('Erro ao Atualizar Parametros!');
      });
  }
}
