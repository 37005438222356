import { Token } from '@angular/compiler/src/expression_parser/lexer';
import { CompilerFactory, Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { error } from 'jquery';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { EventEmitterService } from 'src/app/shared/EventEmitterService';
import { SharedModule } from 'src/app/shared/shared.module';
import { deducaomodel } from './deducaomodel';
import { RegistrarCompraService } from './registrar-compra.service';

@Component({
  selector: 'app-registrar-compra',
  templateUrl: './registrar-compra.component.html',
  styleUrls: ['./registrar-compra.component.css']
})
export class RegistrarCompraComponent implements OnInit {

  formcadastro: FormGroup;
  submitted = false;
  cadastrodeducao: deducaomodel;
  marked: boolean;
  sucesso = new EventEmitter();

  modaltitulo: string = '';
  modalconteudo: string = '';
  bt_termo: boolean;



  startedClass = false;
  completedClass = false;
  preventAbuse = false;
  rota: Router;
  bt_error: boolean;


  constructor(
    private formBuilder: FormBuilder,
    private service: RegistrarCompraService,
    private alert: AlertModalService,
    private router: Router,
    private autenticado: SharedModule,
  ) {
    this.rota = this.router;
    EventEmitterService.get('loginAlert').emit(true);
  }

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//

  //seta os campos para inicio do form
  //====================================================================//
  criarform() {
    this.formcadastro = this.formBuilder.group({
      cpf: ['', Validators.required],
      nome: ['', Validators.required],
      valor: ['', Validators.required],
      autorizacao: [''],
      descricao: ['', Validators.required],
      observacao: [''],
      ministerio: ['', Validators.required],
      dt_referencia: [''],
      reembolso: ['']
    });
  }
  //====================================================================//

  Reembolso(e) {
    if (e.target.checked == true) {
      this.marked = e.target.checked;
      this.formcadastro.controls['reembolso'].setValue("true");
      this.StopProgressBar();
      this.modalconteudo = "Lembramos que o reembolso só será realizado diante autorização da gestão e passará por uma analise do setor Financeiro diante as informações lançadas, por este motivo solicitamos que informe o mais detalhado possivel para que analise seja realizada de forma rápida.";
      this.bt_termo = true;
      this.bt_error = false;
      this.modaltitulo = 'Termo de reembolso';
      document.getElementById('openModalButton').click();
    }else{
      this.formcadastro.controls['reembolso'].setValue("false");
    }
  }

  reset() {
    this.cadastrodeducao = new deducaomodel();
    this.submitted = false;
  }

  ngOnInit() {
    EventEmitterService.get('loginAlert').emit(true);
    this.cadastrodeducao = new deducaomodel();

    this.service.retornarcentrocusto(this.autenticado.cpf).subscribe(
      (success) => {
        if (success==null){
          this.modalconteudo = "Você não está vinculado a nenhum centro de custo, por favor solicite ao financeiro a devida liberação.";
          this.bt_error = true;
          this.bt_termo = false;
          this.modaltitulo = 'Você Precisa de Liberação!';
          document.getElementById('openModalButton').click();
          

        }
        this.cadastrodeducao.ministerio = success.toString();
      }
      , (error) => {
        this.formcadastro.controls['ministerio'].setValue(error.error.text);


      }
    )
this.criarform();




  }

  get f() {
    return this.formcadastro.controls;
  }


  onSubmit() {
    this.submitted = true;
    this.formcadastro.controls['cpf'].setValue(this.autenticado.cpf);
    this.formcadastro.controls['nome'].setValue(this.autenticado.nome);
    this.formcadastro.controls['ministerio'].setValue(this.autenticado.ministerio_custo);
    if (this.formcadastro.invalid) {
      this.alert.AlertShowDanger('Favor verificar os campos em destaque!');
      return;
    }
    this.service
      .CadastroDeducaoCompra(this.formcadastro.value, this.autenticado.token)
      .subscribe(
        (sucess) => {
          this.StopProgressBar();
          this.alert.AlertShowSucess('Compra Registrada com Sucesso!');
          this.marked = false;
          this.cadastrodeducao = new deducaomodel();
          this.criarform();
          this.submitted = false;
        },
        (error) => {
          this.StopProgressBar();
          this.alert.AlertShowDanger('( ' + error.status + ' ) ' + error.error);
        }
      );
  }

}


