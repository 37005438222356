<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>
<div class="wrapper">
    <h4 class="espacamentotopo">
        <img src="../../assets/img/cadastro.jpeg" style="width: 40px" />&nbsp;&nbsp;Deduções de Compras
    </h4>
    <label class="j">
        &nbsp;&nbsp;&nbsp;&nbsp; Nesta área você lançará as compras realizadas para o ministério que foram realizadas
        exporadicamente
    </label>
    <label class="j">
        Lembramos que os campos com (<strong class="cor">*</strong>) são campos de
        preenchimento obrigatório!
    </label>
    <hr class="espacamentotopo bold" />
    <form [formGroup]="formcadastro" disabled="">
        <div class="row">

            <div class="col-md-2">
                <div class="form-group">
                    <label>Data Compra:</label><strong class="cor">*</strong>
                    <input type="date" class="form-control" id="dt_referencia" placeholder="Data Compra"
                        name="dt_referencia" maxlength="10" minlength="10" autocomplete="off"
                        formControlName="dt_referencia" [(ngModel)]="cadastrodeducao.dt_referencia"
                        [ngClass]="{ 'is-invalid': submitted && f.dt_referencia.errors }" />
                    <div *ngIf="submitted && f.dt_referencia.errors" class="invalid-feedback">
                        <div *ngIf="f.dt_referencia.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>
            </div>


            <div class=" col-md-3">
                <label>Valor (R$):</label><strong class="cor">*</strong>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1"><i class="fas fa-dollar-sign"></i></span>
                    </div>

                    <input type="text" class="form-control" style="font-weight: bold; " mask="separator.2"
                        thousandSeparator="." allowNegativeNumbers="false" minlength="3" maxlength="10" id="valor"
                        placeholder="0.00" step="any" [(ngModel)]="cadastrodeducao.valor" formControlName="valor"
                        [ngClass]="{ 'is-invalid': submitted && f.valor.errors }" />

                    <div *ngIf="submitted && f.valor.errors" class="invalid-feedback">
                        <div *ngIf="f.valor.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>
            </div>


            <div class="row" style="padding: 0%;">
                <div class="col-md" style="padding-top: 20px;">
                    <div>
                        <input type="checkbox" id="marked" data-md-icheck (change)="Reembolso($event)"
                            formControlName="marked" [(ngModel)]="marked" />
                        <b>&nbsp;Solicitar Reembolso</b>.<strong class="cor">*</strong>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" style="padding: 0%;">
            <div class="col-md-6">
                <div class="form-group">
                    <label>Descrição:</label><strong class="cor">*</strong>
                    <input type="text" class="form-control" id="descricao" placeholder="Informe a descrição da compra"
                        name="descricao" formControlName="descricao" [(ngModel)]="cadastrodeducao.descricao"
                        maxlength="500" minlength="3" [ngClass]="{ 'is-invalid': submitted && f.descricao.errors }" />
                    <div *ngIf="submitted && f.descricao.errors" class="invalid-feedback">
                        <div *ngIf="f.descricao.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-6">
                <div class="form-group">
                    <label>Autorização:</label>
                    <input type="text" class="form-control" id="autorizacao" placeholder="Autorizado por"
                        name="autorizacao" formControlName="autorizacao" [(ngModel)]="cadastrodeducao.autorizacao"
                        maxlength="200" />
                </div>
            </div>

        </div>


        <div class="row">
            <div class="col-md">
                <div class="form-group">
                    <label>Observações:</label>
                    <textarea type="text" rows="3" class="form-control" id="observacao" aria-describedby="emailHelp"
                        name="observacao" formControlName="observacao" [(ngModel)]="cadastrodeducao.observacao" rows="2"
                        autocomplete="off" maxlength="500" minlength="3"></textarea>
                    <span>{{ cadastrodeducao.observacao.length }}/500</span>
                </div>
            </div>
        </div>
    </form>
</div>

<div style="padding-top: 15px; padding-bottom: 10px;">
    <button pButton pRipple type="button" icon="pi pi-save" class="p-button-rounded" style="margin-right: 5px;"
        title=":: SALVAR ::" (click)="onSubmit()"></button>
    <button pButton pRipple type="button" icon="pi pi-refresh" style="margin-right: 5px;"
        class="p-button-rounded p-button-secondary" title="::LIMPAR::" (click)="reset()"></button>
        <button pButton pRipple type="button" icon="pi pi-sign-in" title="Retornar"
        class="p-button-rounded p-button-danger" routerLink="/dashboard/resumo"></button>
</div>

<!-- Modal -->
<button id="openModalButton" [hidden]="true" data-toggle="modal" data-target="#myModal"></button>
<div class="modal fade bd-example-modal-lg" data-backdrop="static" data-keyboard="false" id="myModal" tabindex="-1"
    role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                    <i class="fas fa-info-circle" style="color: blue"></i>
                    {{ modaltitulo }}
                </h5>
            </div>
            <div class="modal-body">
                <div [innerHTML]="modalconteudo"></div>
            </div>
            <div class="modal-footer">
                <div *ngIf="bt_termo == true">
                    <button pButton pRipple type="button" icon="pi pi-thumbs-up"
                        class="p-button-rounded p-button-danger" title="CONCORDO" data-dismiss="modal">
                    </button>
                </div>
                <div *ngIf="bt_error == true">
                    <button pButton pRipple type="button" icon="pi pi-thumbs-up"
                        class="p-button-rounded p-button-danger" title="SAIR" routerLink="/dashboard/resumo"
                        data-dismiss="modal">
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>