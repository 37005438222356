import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { Parametros } from 'src/app/shared/parametros.enum';
import { seminarioinfantilmodel } from './SeminarioInfantilModel';
import { SeminarioInfantilService } from './seminario-infantil.service';
import { EventEmitterService } from 'src/app/shared/EventEmitterService';

@Component({
  selector: 'app-seminario-infantil',
  templateUrl: './seminario-infantil.component.html',
  styleUrls: ['./seminario-infantil.component.css']
})
export class SeminarioInfantilComponent implements OnInit {

  formseminarioinfantil: FormGroup;
  submitted = false;
  seminarioinfantil: seminarioinfantilmodel;

  startedClass = false;
  completedClass = false;
  preventAbuse = false;
  rota: Router;
  modaltitulo: string;
  modalconteudo: string;
  dados_culto: string = "";
  mostrar: string = "T";
  permitido: boolean = true;

  private urlseminarioinfantil = Parametros.SEMINARIOINFANTIL;


  cultos: any = [
    'Festinha da Morada Kids',
  ];

  membromorada: any = [
    'SIM',
    'NÃO'];

  idades: any = [
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12'
  ];


  luf: any = [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MT',
    'MS',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO',
  ];

  constructor(
    private formBuilder: FormBuilder,
    private alert: AlertModalService,
    private service: SeminarioInfantilService,
    private router: Router
  ) {
    this.rota = this.router;
    EventEmitterService.get('loginAlert').emit(false);
    this.mostrar = "T";

  }

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//

  criarform() {
    this.formseminarioinfantil = this.formBuilder.group({
      cpf: ['', Validators.required],
      responsavel: ['', Validators.required],
      contato_confianca: ['', Validators.required],
      tipo_culto: ['', Validators.required],
      idade: ['', Validators.required],
      nome_filho: ['', Validators.required],
      telefone: ['', Validators.required],
      email: [''],
      dados_culto: [''],
      cep: [''],
      logradouro: [''],
      numero: [''],
      apto: [''],
      bairro: [''],
      uf: [''],
      cidade: [''],
      observacoes: ['']
    });
  }

  //BOTAO SALVAR
  //====================================================================//
  onSubmit() {
    this.submitted = true;
    this.seminarioinfantil.dados_culto = this.dados_culto;
    if (this.formseminarioinfantil.invalid) {
      this.alert.AlertShowDanger('Favor verificar os campos em destaque!');
      return;
    } else {
      this.service.QuantidadeCadastro(this.formseminarioinfantil.controls['tipo_culto'].value).subscribe(
        (success) => {
          var c = success;

          if (c >= 100) {
            this.modaltitulo = 'Cadastro Esgotado!';
            this.modalconteudo =
              'Olá!<br>' +
              'Pedimos desculpas mas atendendo ao decreto e mantendo a segurança o limite para participar do culto presencial foi atingido!<br>' +
              'Neste dia não teremos como ficar com sua criança na salinha KIDS, lamentamos o incoveniente!<br>' +
              'Esperamos por você Online!<br>' +
              'Atenciosamente!<br><b>Gestão Morada de Deus ( IEPMD )</b>';
            document.getElementById('openModalButton').click();
            document.getElementById('cpf').focus();
            return;
          }

        });

      this.service
        .CadastroSeminarioInfantil(this.formseminarioinfantil.value)
        .subscribe(
          () => {
            this.StopProgressBar();
            this.submitted = false;
            this.alert.AlertShowSucess('Registro Realizado Com sucesso!');
            localStorage.setItem('tipo', 'CongressoInfantilRegistrado');
            localStorage.setItem('CongressoInfantil-save', JSON.stringify(this.formseminarioinfantil.value));
            this.rota.navigate(['/retorno']);
          },
          (error) => {
            this.StopProgressBar();
            this.permitido = false;
            if (error.status == '200') {
              this.StopProgressBar();
              this.submitted = false;
              this.alert.AlertShowSucess('Registro Realizado Com sucesso!');
              localStorage.setItem('tipo', 'CongressoInfantilRegistrado');
              localStorage.setItem('CongressoInfantil-save', JSON.stringify('SEM EMAIL'));
              this.rota.navigate(['/retorno']);
            }
            if (error.status == '422') {
              this.permitido = false;
              this.dados_culto = "";
              this.seminarioinfantil = new seminarioinfantilmodel();
              this.modaltitulo = 'Cadastro Existente!';
              this.modalconteudo =
                'Olá! <b>' + error.error.cpf + ' ' + error.error.responsavel
                + '</b>,<br>Estava olhando aqui em minhas anotações e verifiquei que já existe seu cadastro em nossa plataforma para seu filho no Congresso [ <b>' + error.error.tipo_culto + '</b> ], em caso de duvidas não deixe de nos reportar para que possamos analisar e lhe retornar!\nDeus Abençõe!';
              document.getElementById('openModalButton').click();
              document.getElementById('cpf').focus();

            } else if (error.status == '500') {
              this.permitido = false;
              this.dados_culto = "";
              this.alert.AlertShowDanger(error.error);

            } else {
              this.permitido = true;
              if (error.status == '200') {

              } else {
                this.alert.AlertShowDanger(error.error);
              }
              document.getElementById('nome').focus();

            }
          }
        );
    }
    //====================================================================//
  }
  //====================================================================//

  //recarregar Form
  //====================================================================//
  reset() {
    this.seminarioinfantil = new seminarioinfantilmodel();
    this.submitted = false;
    this.mostrar = "F";
    document.getElementById('cpf').focus();
  }

  // Pegar nome dos campos,
  //====================================================================//
  get f() {
    return this.formseminarioinfantil.controls;
  }

  //Inicio
  //====================================================================//
  ngOnInit() {
    this.seminarioinfantil = new seminarioinfantilmodel();
    this.criarform();
    this.formseminarioinfantil.controls['numero'].setValue("0");
    this.formseminarioinfantil.controls['apto'].setValue("0");
    document.getElementById('cpf').focus();
  }



  //Consulta o cadastro do condominio já existe na base de dados ( API )
  //====================================================================//
  DadosSeminario(t: any) {
    var x = t;
   

    if (x == "Festinha da Morada Kids") {
      this.dados_culto = "12 de Outubro 2021 de 14Hs. as 17Hs."
    }
    else {
      this.dados_culto = ""
    }

    this.service.QuantidadeCadastro(x).subscribe(
      (success) => {
        var c = success;

        if (c >= 100) {
          this.modaltitulo = 'Cadastro Esgotado!';
          this.modalconteudo =
            'Olá!<br>' +
            'Pedimos desculpas mas atendendo ao decreto e mantendo a segurança o limite para participar do culto presencial foi atingido!<br>' +
            'Neste dia não teremos como ficar com sua criança na salinha KIDS, lamentamos o incoveniente!<br>' +
            'Esperamos por você Online!<br>' +
            'Atenciosamente!<br><b>Gestão Morada de Deus ( IEPMD )</b>';
          document.getElementById('openModalButton').click();
          document.getElementById('cpf').focus();
          return;
        }

      });

  }


  //Consulta o cadastro do condominio já existe na base de dados ( API )
  //====================================================================//
  consultacpf(cpf: any) {
    if (this.seminarioinfantil.cpf.length >= 11) {
      this.service.Consulta(this.seminarioinfantil.cpf).subscribe(
        (success) => {
          this.StopProgressBar();
          (this.seminarioinfantil.cep = success.cep),
            (this.seminarioinfantil.logradouro = success.logradouro),
            (this.seminarioinfantil.bairro = success.bairro),
            (this.seminarioinfantil.cidade = success.cidade),
            (this.seminarioinfantil.apto = success.apto),
            (this.seminarioinfantil.numero = success.numero),
            (this.seminarioinfantil.contato_confianca = success.contato_confianca),
            (this.seminarioinfantil.uf = success.uf);
          (this.seminarioinfantil.telefone = success.telefone);
          (this.seminarioinfantil.responsavel = success.responsavel);
          (this.seminarioinfantil.email = success.email);
          (this.seminarioinfantil.nome_filho = success.nome_filho);
          (this.seminarioinfantil.idade = success.idade);
          document.getElementById('tipo_culto').focus();
        },
        (error) => {
          this.StopProgressBar();
          (this.seminarioinfantil.cep = ""),
            (this.seminarioinfantil.logradouro = ""),
            (this.seminarioinfantil.bairro = ""),
            (this.seminarioinfantil.cidade = ""),
            (this.seminarioinfantil.uf = ""),
            (this.seminarioinfantil.telefone = ""),
            (this.seminarioinfantil.apto = 0),
            (this.seminarioinfantil.numero = 0),
            (this.seminarioinfantil.contato_confianca = ""),
            (this.seminarioinfantil.responsavel = ""),
            (this.seminarioinfantil.email = "");
          (this.seminarioinfantil.idade = "");
          (this.seminarioinfantil.nome_filho = "");

          this.mostrar = "T";
          document.getElementById('nome').focus();
        }
      );
    }
  }

  //====================================================================//

  //CONSULTA CEP API
  //====================================================================//
  consulta_cep(cep: any) {
    if (cep != '' && cep != null) {
      this.service
        .ConsultaCEP(
          cep
            .replace('.', '')
            .replace('-', '')
            .replace('\\', '')
            .replace('/', '')
        )
        .subscribe(
          (success) => {
            if (success.cep != null) {
              (this.seminarioinfantil.cep = success.cep),
                (this.seminarioinfantil.logradouro = success.logradouro),
                (this.seminarioinfantil.bairro = success.bairro),
                (this.seminarioinfantil.cidade = success.localidade),
                (this.seminarioinfantil.uf = success.uf);
              document.getElementById('numero').focus();
            } else {
              this.alert.AlertShowDanger('CEP não e válido!');
              this.seminarioinfantil.cep = '';
            }
          },
          (error) => {
            this.StopProgressBar();
            this.alert.AlertShowDanger(
              '( ' + error.status + ' ) ' + error.message
            );
          }
        );
    }
  }

  //====================================================================//
  //PEGAR VALOR DIZIMISTA FORM
  //====================================================================//
  get citymembromorada() {
    return this.formseminarioinfantil.get('ministerio_morada');
  }
  //====================================================================//
  // Pegar o valor do combobox DIZIMISTA
  //====================================================================//
  changeministeriomorada(e) {
    console.log(e.name);
    this.citymembromorada.setValue(e.target.value, {
      onlySelf: true,
    });


  }
  //====================================================================//

}
