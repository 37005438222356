import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { retry } from 'rxjs/operators';
import { Parametros } from 'src/app/shared/parametros.enum';
import { deducaomodel } from './deducaomodel';

@Injectable({
  providedIn: 'root'
})
export class RegistrarCompraService {
  private urlcadastro = Parametros.CADASTRODEDUCOESCOMPRAS;
  private urlcentrocusto = Parametros.CENTROCUSTO;


  constructor(private http: HttpClient) { }
  startedClass = false;
  completedClass = false;
  preventAbuse = false;

  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }

  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }

  CadastroDeducaoCompra(c, token) {
    this.StartProgressBar();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + token.replace("\r\n", "").replace("\n", "")
      }),
    };
    return this.http
      .post(this.urlcadastro + 'POSTCadastroDeducaoCompra', c, httpOptions)
      .pipe(retry(2));
  }

  retornarcentrocusto(c) {
    this.StartProgressBar();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
    };
    return this.http
      .get(this.urlcentrocusto + 'GETministeriousuario/?parametro='+c,httpOptions)
      .pipe(retry(2));
  }

}