import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { cultomodel } from 'src/app/culto-inscricao/CultoModel';
import { cultomodelKids } from 'src/app/culto-kids-inscricao/CultoKidsModel';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { Parametros } from 'src/app/shared/parametros.enum';
import { SharedModule } from 'src/app/shared/shared.module';
import { ListagemCultoKidsRegistradoService } from './listagem-culto-kids-registrado.service';

@Component({
  selector: 'app-listagem-participantes-culto-kids-registrado',
  templateUrl: './listagem-participantes-culto-kids-registrado.component.html',
  styleUrls: ['./listagem-participantes-culto-kids-registrado.component.css']
})
export class ListagemParticipantesCultoKidsRegistradoComponent implements OnInit {

  private urlcadastrokids = Parametros.CADASTROCULTOKIDS;
  x = 1;
  y = 10;
  filtro_cabecalho: string = ""
  explicacao: string = "";
  textoconsulta: string = "";
  rota: any;
  perfil: any;
  pagina_atual = 0;
  primeiro_registro = 0;
  ultimo_registro = 0;
  imprimir: string = "Filtro:Todos";
  blob: Blob;
  idculto: any;
  nome: string;
  tipo_culto: string = "TODOS CULTOS";
  _perfil: string = "";

  cultos: any;
  tipoculto: any;
  CurrentPageReport: number;


  constructor(private http: HttpClient,
    private primengConfig: PrimeNGConfig,
    private alert: AlertModalService,
    private autenticado: SharedModule,
    private service: ListagemCultoKidsRegistradoService,
    r: Router
  ) {
    this.rota = r;
    this.perfil = this.autenticado.perfil;
    this.nome = this.autenticado.nome;
    this._perfil = this.autenticado.perfil;
  }

  startedClass = false;
  completedClass = false;
  preventAbuse = false;

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//

  usuario: Array<cultomodelKids>;
  qto: number;
  cols: any[];


  GETListagemCultos() {
    this.StartProgressBar();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token
      })
    };
    return this.http
      .get<cultomodelKids>(this.urlcadastrokids + 'GETlistaCultos', httpOptions).subscribe(
        (success) => {
          this.cultos = success;
        },
        (error) => {

        }
      )
  }

  GetListaTotal() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get<any>(this.urlcadastrokids + 'GETTotalUsuarioCulto/?parametro=' + this.tipo_culto, httpOptions).subscribe(
      (success) => {

        this.qto = success

        if (this.x > success) {
          this.x = 1;
          this.y = success;
        }
      }
    )
  }


 






  ngOnInit() {
    if (this.autenticado.token == null) {
      this.autenticado.ResetDados();
      this.alert.AlertShowDanger('Usuário não autenticado, redirecionando...')
      this.rota.navigate(['/login']);
    } else {
      if (this.autenticado.ValidarToken() != true) {
        this.autenticado.ResetDados();
        this.alert.AlertShowDanger('Token Invalido!')
        this.rota.navigate(['/login']);
      }
    }
    this.primengConfig.ripple = true;
    this.filtro_cabecalho = "Listagem de Filhos Cadastrados"
    this.explicacao = "Aqui você está visualizando todos os Filhos cadastrados para participação presencial da sala KIDS."
    this.GetListaTotal();
    this.GetListaConsultaLimite(this.pagina_atual);
    this.GETListagemCultos();

    this.cols = [
      { field: 'Data Cadastro', header: 'Data Cadastro' },
      { field: 'Filho(a)', header: 'Filho(a)' },
      { field: 'Idade', header: 'Idade' },
      { field: 'Responsável', header: 'Responsável' },
      { field: 'Telefone', header: 'Telefone' },
      { field: 'Culto', header: 'Culto' }

    ];  

    
  }

  Paginate(event) {
    if (event.page == 0) {
      this.x = 1;
      this.y = 10;
    } else {

      if (event.page * 10 > this.qto) {
        this.x = this.qto;
        this.y = this.qto;
      } else {
        this.x = event.page * 10;
        if (this.x + 10 > this.qto) {
          this.y = this.qto;
        } else {
          this.y = this.x + 10;
        }
      }
    }


    this.pagina_atual = event.page;

    this.GetListaConsultaLimite(this.pagina_atual);

  }

  GetListaConsultaLimite(pagina: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.GetListaTotal()
    this.http.get<any>(this.urlcadastrokids + 'GetListaConsultaLimite/?parametro=' + this.tipo_culto + '&pagina=' + pagina, httpOptions).subscribe(
      (success) => {
        this.usuario = success;


      },
      (error) => {

      }
    )
  }

  
  Consulta() {
    var texto = (<HTMLInputElement>document.getElementById("texto_consulta")).value;
    
      this.textoconsulta = texto;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
        })
      }
      this.http.get<cultomodelKids[]>(this.urlcadastrokids + 'GetListaConsultaUsuarioNome/?parametro=' + texto + '&tipo=' + this.tipo_culto, httpOptions).subscribe(
        (success) => {
          this.usuario = success;
          this.qto = success.length

          this.x = 1;
          this.y = success.length;

        },(error)=>{

        }
      )
    
  }

  VisualizarDetalhe(event) {
    localStorage.removeItem('CultoKidsDetalhe');
    localStorage.setItem('CultoKidsDetalhe', event.currentTarget.value);
    this.rota.navigate(['/dashboard/detalhe/usuario-culto-kids']);
  }


  //modal
  ValorImpressao(event) {
    this.imprimir = event.currentTarget.value;
  }



  SetDados(event) {
    this.idculto = event.currentTarget.value;
  }

  SetConsultaSeminario(event) {
    this.tipoculto = event.currentTarget.value;    
    this.GetListaTotal();
    this.Consulta();
  }

  BotaoExcluir() {
    this.ExcluirConsulta();
  }


  ExcluirConsulta() {
    this.service.CancelarUsuarioculoregistrado(this.idculto).subscribe(
      (success) => {
        this.StopProgressBar();
        this.alert.AlertShowInfo('Usuario Excluido com sucesso!');     
      },
      (error) => {
        this.StopProgressBar();
        this.alert.AlertShowDanger('Erro ao cancelar Usuario!');        
      }
    )
  }
}
