<div class="content">
</div>
<footer id="myFooter" style="padding-top: 10px;">
        <div class="footer-social">

                <a href="https://www.instagram.com/moradajfoficial/" target="_blank" title="Instagram"
                        class="social-icons"><i class="fab fa-instagram "
                                style="color: white; margin-right: 15px;"></i></a>
                <a href="https://t.me/moradadedeus" target="_blank" title="Telegram" class="social-icons"><i
                                class="fab fa-telegram-plane" style="color: white; margin-right: 15px;"></i></a>
                <!--
                <a href="https://api.whatsapp.com/send?l=pt_BR&phone=55032988840732" target="_blank" title="whatsApp"
            class="social-icons"><i class="fab fa-whatsapp" style="color: white; margin-right: 15px;"></i></a>
-->

        </div>
        
        


</footer>

