import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { SugestaoModel } from '../sugestoes/sugestaomodel';
import { SugestaoService } from '../sugestoes/sugestao.service';
import { SharedModule } from 'src/app/shared/shared.module';

@Component({
  selector: 'app-sugestoes',
  templateUrl: './sugestoes.component.html',
  styleUrls: ['./sugestoes.component.css']
})
export class SugestoesComponent implements OnInit {
  rota: Router;
  modelsugestao: SugestaoModel;
  formsugestao: FormGroup;
  startedClass = false;
  completedClass = false;
  preventAbuse = false;
  receber_copia: boolean;


  submitted: boolean;


  constructor(
    private formBuilder: FormBuilder,
    private alert: AlertModalService,
    private sugestaoservice: SugestaoService,
    private router: Router,
    private autenticado: SharedModule,
  ) {
    this.rota = this.router;
    this.modelsugestao = new SugestaoModel();

  }

  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }

  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }

  reset() {
    this.modelsugestao = new SugestaoModel();
    this.submitted = false;
    this.formsugestao.get('email').setValue(this.autenticado.email);
    this.formsugestao.get('idusuario').setValue(this.autenticado.iduser);
    this.formsugestao.get('nome').setValue(this.autenticado.nome);
    this.formsugestao.get('cpf').setValue(this.autenticado.cpf);
  }
  get f() {
    return this.formsugestao.controls;
  }


  ReceberCopia(e) {
    if (e.target.checked == true && this.autenticado.email == '') {
      this.receber_copia = false;
      this.alert.AlertShowDanger(':: AVISO ::\nPara que possa receber uma cópia da sugestão e necessário ter e-mail valido em seu cadastro! \nPor favor preencha um e-mail valido em seu Perfil e tente novamente!');
    }

  }

  //BOTAO SALVAR
  //====================================================================//
  onSubmit() {
    this.submitted = true;
    if (this.formsugestao.invalid) {
      this.alert.AlertShowDanger('Favor verificar os campos em destaque!');
      return;
    }
    else if (this.formsugestao.get('telefone').value.length < 10) {
      this.alert.AlertShowDanger('Favor Preencher Telefone corretamente');
    } else {
      this.sugestaoservice
        .Cadastrarsugestao(this.autenticado.token, this.formsugestao.value)
        .subscribe(
          (sucess) => {
            this.StopProgressBar();
            this.alert.AlertShowSucess('Sugestão Enviada com Sucesso');
            this.modelsugestao = new SugestaoModel();
            this.criarform();
            this.formsugestao.get('email').setValue(this.autenticado.email);
            this.formsugestao.get('idusuario').setValue(this.autenticado.iduser);
            this.formsugestao.get('nome').setValue(this.autenticado.nome);
            this.formsugestao.get('cpf').setValue(this.autenticado.cpf);
            this.submitted = false;
          },
          (error) => {
            this.StopProgressBar();
            this.alert.AlertShowDanger('( ' + error.status + ' ) ' + error.error);
          }
        );
    }
  }

  //====================================================================//
  ngOnInit() {
    if (this.autenticado.token == null) {
      this.autenticado.ResetDados();
      this.alert.AlertShowDanger('Usuário não autenticado, redirecionando...')
      this.rota.navigate(['/login']);
    } else {
      if (this.autenticado.ValidarToken() != true) {
        this.autenticado.ResetDados();
        this.alert.AlertShowDanger('Token Invalido!')
        this.rota.navigate(['/login']);
      } else {
        try {
          this.modelsugestao = new SugestaoModel();
          this.criarform();
          this.modelsugestao.cpf = this.autenticado.cpf;
          this.modelsugestao.nome = this.autenticado.nome;
          this.formsugestao.get('idusuario').setValue(this.autenticado.iduser);
          this.formsugestao.get('email').setValue(this.autenticado.email);
        } catch {
          this.rota.navigate(['/dashboard/resumo']);
        }
      }
    }
  }
  //====================================================================//
  criarform() {
    this.formsugestao = this.formBuilder.group({
      cpf: ['', Validators.required],
      nome: ['', Validators.required],
      email: [''],
      telefone: ['', Validators.required],
      tipo_sugestao: ['', Validators.required],
      mensagem: ['', [Validators.required, Validators.min(20), Validators.max(9000)]],
      receber_copia: [false],
      idusuario: [0, Validators.required]
    });
  }

}
