<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>
<div class="container">
    <div class="col-md-12">
        <div class="row" style="margin-right: 2px; padding-left: 20px;">
            <img src="../../../assets/img/detalhe.png" height="130px" width="100px" style="padding: 0%;" />

            <div class="col-md-10" style="padding-top: 15px;">
                <h4 class="espacamentotopo " style="margin-left: 5px;">
                    <b>Detalhe Cadastro Participante</b>
                </h4>
                <label>
                    Aqui você encontra o detalhe do cadastro realizado na plataforma!
                    <br>
                    <b>#FicaaDica: </b>Em caso de Celular melhor visualizado na horizontal!
                </label>
            </div>
        </div>
    </div>
    <hr>
    <br>
    <label><b>Responsavél: </b>{{nome}}</label><br>
    <label><b>Filho(a): </b>{{nome_filho}}</label><br>
    <label><b>Idade: </b>{{idade}}</label><br>
    <label><b>Data Cadastro: </b>{{data_cadastro}}</label><br>
    <label><b>Seminario: </b>{{seminario}}</label><br>
    <label><b>Data / Hora: </b>{{dadosseminario}}</label><br>
    <label><b>Telefone: </b>{{telefone}}</label><br>
    <label><b>E-mail: </b>{{email}}</label><br><br>
    <label><b>Observações: </b>{{observacoes}}</label><br><br>

    <br>
    <hr>
    <button pButton pRipple type="button" icon="pi pi-print" class="p-button-rounded"
        styleClass="rounded-button ui-button-info" value={{idseminarioinfantil}} (click)="ImprimirElogio()"
        title="Imprimir Ficha" style="margin-left: 5px; margin-right: 10px; margin-left: 10px;"></button>

    <button pButton pRipple type="button" icon="pi pi-sign-in" title="Retornar" class="p-button-rounded p-button-danger"
        routerLink="/dashboard/listagem/lista-seminario-infantil"></button>
    <br>
    <br>
    <br>
    <br>
</div>
<button class="voltar-ao-topo" id="back"><a class="ancora" style="color: white;"><i
            class="pi pi-arrow-circle-up"></i></a></button>