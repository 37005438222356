import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { error, valHooks } from 'jquery';
import { ParametrosModel } from '../Dashboard/configuracaoes/ParametrosModel';
import { AlertModalService } from '../shared/alert-modal/alert-modal.service';
import { EventEmitterService } from '../shared/EventEmitterService';
import { Parametros } from '../shared/parametros.enum';
import { CultoInscricaoService } from './culto-inscricao.service';
import { cultomodel } from './CultoModel'

@Component({
  selector: 'app-culto-inscricao',
  templateUrl: './culto-inscricao.component.html',
  styleUrls: ['./culto-inscricao.component.css']
})
export class CultoInscricaoComponent implements OnInit {

  formculto: FormGroup;
  submitted = false;
  cadastroculto: cultomodel;
  parametros: ParametrosModel;

  startedClass = false;
  completedClass = false;
  preventAbuse = false;
  rota: Router;
  modaltitulo: string;
  modalconteudo: string;
  dados_culto: string = "";
  mostrar: string = "T";
  permitido: boolean = true;
  tipo: string = "";
  limite: number = 0;
  private urlcadastro = Parametros.CADASTROCULTO;


  cultos: any = [
    '31/10 - Domingo ( 08:30 )',
    '31/10 - Domingo ( 19:00 )',
    '07/11 - Domingo ( 08:30 )',
    '07/11 - Domingo ( 19:00 )',
    '14/11 - Domingo ( 08:30 )',
    '14/11 - Domingo ( 19:00 )',
    '21/11 - Domingo ( 08:30 )',
    '21/11 - Domingo ( 19:00 )',
    '28/11 - Domingo ( 08:30 )',
    '28/11 - Domingo ( 19:00 )'
  ];

  membromorada: any = [
    'SIM',
    'NÃO'];

  documentos: any = [
    'CPF',
    'RG'];

  ficousabendo: any = [
    'INSTAGRAM',
    'FACEBOOK',
    'WHATSAPP',
    'INDICAÇÃO DE AMIGO(A)',
    'CULTO',
  ];

  luf: any = [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MT',
    'MS',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO',
  ];

  constructor(
    private formBuilder: FormBuilder,
    private alert: AlertModalService,
    private service: CultoInscricaoService,
    private router: Router
  ) {
    this.rota = this.router;
    EventEmitterService.get('loginAlert').emit(false);
    this.mostrar = "T";
    this.LimiteCulto();

  }

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//

  criarform() {
    this.formculto = this.formBuilder.group({
      cpf: ['', [Validators.required, Validators.maxLength(14), Validators.minLength(8)]],
      nome: ['', Validators.required],
      tipo_culto: ['', Validators.required],
      ficou_sabendo: ['', Validators.required],
      tipo_documento: ['', Validators.required],
      ministerio_morada: ['', Validators.required],
      telefone: ['', Validators.required],
      ministerio_pertence: ['', Validators.required],
      email: [''],
      dados_culto: [''],
      cep: [''],
      logradouro: [''],
      numero: [''],
      apto: [''],
      bairro: [''],
      uf: [''],
      cidade: [''],
      duvida: ['']
    });
  }



  LimiteCulto() {
    this.service.LimiteCadastro().subscribe(
      (success) => {
        this.limite = success;
      }, (error) => {
        this.limite = 0;
      });
  }


  Tipo(event: any) {
    this.tipo = event.target.value.replace("1: ", "").replace("2: ", "").replace(" ", "");
    this.formculto.controls['cpf'].setValue("");

    if (this.formculto.controls['tipo_documento'].value != "") {
      this.formculto.controls['cpf'].enable();
      document.getElementById('cpf').focus();
    } else {
      this.formculto.controls['cpf'].disable();
      document.getElementById('tipo_documento').focus();
    }
  }

  //BOTAO SALVAR
  //====================================================================//
  onSubmit() {
    this.submitted = true;
    this.cadastroculto.dados_culto = this.dados_culto;
    if (this.tipo == 'RG' && (this.formculto.controls['cpf'].value).length < 8) {
      this.alert.AlertShowDanger('RG Incorreto');
      document.getElementById('cpf').focus();
      this.formculto.controls['cpf'].setValue("");
      return;
    }

    if (this.tipo == 'RG' && (this.formculto.controls['cpf'].value).length > 8) {
      this.alert.AlertShowDanger('RG Incorreto');
      document.getElementById('cpf').focus();
      this.formculto.controls['cpf'].setValue("");
      return;
    }

    if (this.tipo == 'CPF' && (this.formculto.controls['cpf'].value).length < 11) {
      this.alert.AlertShowDanger('CPF Incorreto');
      document.getElementById('cpf').focus();
      this.formculto.controls['cpf'].setValue("");
      return;
    }

    if (this.formculto.invalid) {
      this.alert.AlertShowDanger('Favor verificar os campos em destaque!');
      return;
    } else {
      this.service.QuantidadeCadastro(this.formculto.controls['tipo_culto'].value).subscribe(
        (success) => {
          var c = success;

          if (c >= this.limite) {
            this.modaltitulo = 'Cadastro Esgotado!';
            this.modalconteudo =
              'Olá!<br>' +
              'Pedimos desculpas mas atendendo ao decreto e mantendo a segurança o limite para participar do culto presencial foi atingido!<br>' +
              'Mas não se preocupe, Você ainda pode curtir o mesmo por nossa rede social do Instagram, onde iremos transmitir o mesmo <b>Ao Vivo</b>!<br>Para assistir Online <a href="https://www.instagram.com/moradajfoficial/"><b>Clique Aqui</b></a><br>' +
              'Esperamos por você Online!<br>' +
              'Atenciosamente!<br><b>Gestão Morada de Deus ( IEPMD )</b>';
            document.getElementById('openModalButton').click();
            document.getElementById('cpf').focus();
            return;
          }

        });

      this.service
        .CadastroCulto(this.formculto.value)
        .subscribe(
          () => {
            this.StopProgressBar();
            this.submitted = false;
            this.alert.AlertShowSucess('Registro Realizado Com sucesso!');
            localStorage.setItem('tipo', 'CultoRegistrado');
            localStorage.setItem('Culto-save', JSON.stringify(this.formculto.value));
            this.rota.navigate(['/retorno']);
          },
          (error) => {
            this.StopProgressBar();
            this.permitido = false;
            if (error.status == '200') {
              this.StopProgressBar();
              this.submitted = false;
              this.alert.AlertShowSucess('Registro Realizado Com sucesso!');
              localStorage.setItem('tipo', 'CultoRegistrado');
              localStorage.setItem('Culto-save', JSON.stringify('SEM EMAIL'));
              this.rota.navigate(['/retorno']);
            }
            if (error.status == '422') {
              this.permitido = false;
              this.dados_culto = "";
              this.cadastroculto = new cultomodel();
              this.modaltitulo = 'Cadastro Existente!';
              this.modalconteudo =
                'Olá! <b>' + error.error.nome
                + '</b>,<br>Estava olhando aqui em minhas anotações e verifiquei que já existe seu cadastro em nossa plataforma para o Culto [ <b>' + error.error.tipo_culto + '</b> ], em caso de duvidas não deixe de nos reportar para que possamos analisar e lhe retornar!\nDeus Abençõe!';
              document.getElementById('openModalButton').click();
              document.getElementById('cpf').focus();

            } else if (error.status == '500') {
              this.permitido = false;
              this.dados_culto = "";
              this.alert.AlertShowDanger(error.error);

            } else {
              this.permitido = true;
              if (error.status == '200') {

              } else {
                this.alert.AlertShowDanger(error.error);
              }
              document.getElementById('nome').focus();

            }
          }
        );
    }
    //====================================================================//
  }
  //====================================================================//

  //recarregar Form
  //====================================================================//
  reset() {
    this.cadastroculto = new cultomodel();
    this.submitted = false;
    this.mostrar = "F";
    document.getElementById('cpf').focus();
  }

  // Pegar nome dos campos,
  //====================================================================//
  get f() {
    return this.formculto.controls;
  }

  //Inicio
  //====================================================================//
  ngOnInit() {
    this.cadastroculto = new cultomodel();
    this.criarform();
    this.formculto.controls['ministerio_morada'].setValue("1: SIM");
    this.formculto.controls['numero'].setValue("0");
    this.formculto.controls['apto'].setValue("0");
    document.getElementById('cpf').focus();

    if (this.formculto.controls['tipo_documento'].value == "") {
      this.formculto.controls['cpf'].disable();
    }
  }



  //Consulta o cadastro do condominio já existe na base de dados ( API )
  //====================================================================//
  DadosSeminario(t: any) {
    var x = t;


    if (x == "31/10 - Domingo ( 08:30 )") {
      this.dados_culto = "31 de Outubro 2021 de 08:30Hs."
    }
    if (x == "31/10 - Domingo ( 19:00 )") {
      this.dados_culto = "31 de Outubro 2021 de 19:00Hs."
    }



    if (x == "07/11 - Domingo ( 08:30 )") {
      this.dados_culto = "07 de Novembro 2021 de 08:30Hs."
    }
    if (x == "07/11 - Domingo ( 19:00 )") {
      this.dados_culto = "07 de Novembro 2021 de 19:00Hs."
    }

    if (x == "14/11 - Domingo ( 08:30 )") {
      this.dados_culto = "14 de Novembro 2021 de 08:30Hs."
    }
    if (x == "14/11 - Domingo ( 19:00 )") {
      this.dados_culto = "14 de Novembro 2021 de 19:00Hs."
    }

    if (x == "21/11 - Domingo ( 08:30 )") {
      this.dados_culto = "21 de Novembro 2021 de 08:30Hs."
    }
    if (x == "21/11 - Domingo ( 19:00 )") {
      this.dados_culto = "21 de Novembro 2021 de 19:00Hs."
    }

    if (x == "28/11 - Domingo ( 08:30 )") {
      this.dados_culto = "28 de Novembro 2021 de 08:30Hs."
    }
    if (x == "28/11 - Domingo ( 19:00 )") {
      this.dados_culto = "28 de Novembro 2021 de 19:00Hs."
    }


    if (x == "") {
    } else {

      this.service.QuantidadeCadastro(x).subscribe(
        (success) => {
          var c = success;

          if (c >= this.limite) {
            this.modaltitulo = 'Cadastro Esgotado!';
            this.modalconteudo =
              'Olá!<br>' +
              'Pedimos desculpas mas atendendo ao decreto e mantendo a segurança o limite para participar do culto presencial foi atingido!<br>' +
              'Mas não se preocupe, Você ainda pode curtir o mesmo por nossa rede social do Instagram, onde iremos transmitir o mesmo <b>Ao Vivo</b>!<br>Para assistir Online <a href="https://www.instagram.com/moradajfoficial/"><b>Clique Aqui</b></a><br>' +
              'Esperamos por você Online!<br>' +
              'Atenciosamente!<br><b>Gestão Morada de Deus ( IEPMD )</b>';
            document.getElementById('openModalButton').click();
            document.getElementById('cpf').focus();
          }

        });
    }
  }


  //Consulta o cadastro do condominio já existe na base de dados ( API )
  //====================================================================//
  consultacpf(cpf: any) {
    if (this.cadastroculto.cpf.length >= 8) {
      this.service.Consulta(this.cadastroculto.cpf).subscribe(
        (success) => {
          this.StopProgressBar();
          (this.cadastroculto.cep = success.cep),
            (this.cadastroculto.logradouro = success.logradouro),
            (this.cadastroculto.bairro = success.bairro),
            (this.cadastroculto.cidade = success.localidade),
            (this.cadastroculto.uf = success.uf);
          (this.cadastroculto.telefone = success.telefone);
          (this.cadastroculto.nome = success.nome);
          (this.cadastroculto.email = success.email);
          (this.cadastroculto.ficou_sabendo = success.ficou_sabendo);
          (this.cadastroculto.ministerio_morada = success.ministerio_morada);
          (this.cadastroculto.ministerio_pertence = success.ministerio_pertence);
          if (success.ministerio_morada == "SIM") {
            this.mostrar = "F";
          } else {
            this.mostrar = "T";
          }
          document.getElementById('tipo_culto').focus();
        },
        (error) => {
          this.StopProgressBar();
          (this.cadastroculto.cep = ""),
            (this.cadastroculto.logradouro = ""),
            (this.cadastroculto.bairro = ""),
            (this.cadastroculto.cidade = ""),
            (this.cadastroculto.uf = ""),
            (this.cadastroculto.telefone = ""),
            (this.cadastroculto.nome = ""),
            (this.cadastroculto.email = "");
          (this.cadastroculto.ficou_sabendo = "");
          (this.cadastroculto.ministerio_morada = "");
          (this.cadastroculto.ministerio_pertence = "");
          this.mostrar = "T";
          document.getElementById('nome').focus();
        }
      );
    }
  }

  //====================================================================//

  //CONSULTA CEP API
  //====================================================================//
  consulta_cep(cep: any) {
    if (cep != '' && cep != null) {
      this.service
        .ConsultaCEP(
          cep
            .replace('.', '')
            .replace('-', '')
            .replace('\\', '')
            .replace('/', '')
        )
        .subscribe(
          (success) => {
            if (success.cep != null) {
              (this.cadastroculto.cep = success.cep),
                (this.cadastroculto.logradouro = success.logradouro),
                (this.cadastroculto.bairro = success.bairro),
                (this.cadastroculto.cidade = success.localidade),
                (this.cadastroculto.uf = success.uf);
              document.getElementById('numero').focus();
            } else {
              this.alert.AlertShowDanger('CEP não e válido!');
              this.cadastroculto.cep = '';
            }
          },
          (error) => {
            this.StopProgressBar();
            this.alert.AlertShowDanger(
              '( ' + error.status + ' ) ' + error.message
            );
          }
        );
    }
  }

  //====================================================================//
  //PEGAR VALOR DIZIMISTA FORM
  //====================================================================//
  get citymembromorada() {
    return this.formculto.get('ministerio_morada');
  }
  //====================================================================//
  // Pegar o valor do combobox DIZIMISTA
  //====================================================================//
  changeministeriomorada(e) {
    console.log(e.name);
    this.citymembromorada.setValue(e.target.value, {
      onlySelf: true,
    });

    if (e.target.value.includes("SIM")) {
      this.mostrar = "F";
      this.cadastroculto.ministerio_pertence = "IEPMD - Igreja Evangelica Pentecostal Morada de Deus."
    } else {
      this.mostrar = "T";
      this.cadastroculto.ministerio_pertence = "";
    }
  }
  //====================================================================//

}
