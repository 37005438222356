import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { EventEmitterService } from 'src/app/shared/EventEmitterService';
import { ConsultaQrcodeService } from './consulta-qrcode.service';

@Component({
  selector: 'app-consulta-qrcode',
  templateUrl: './consulta-qrcode.component.html',
  styleUrls: ['./consulta-qrcode.component.css']
})
export class ConsultaQrcodeComponent implements OnInit {

  public Hash;
  startedClass = false;
  completedClass = false;
  preventAbuse = false;
  rota: Router;
  _service: ConsultaQrcodeService;
  status: any;
  qrcode:any;
  lotacao:any
  patrimonio:any;
  setor:any
  tipo:any;


  constructor(private route: ActivatedRoute,
    private alert: AlertModalService,
    private s: ConsultaQrcodeService,
    router: Router) {
    this.rota = router;
    this._service = s;
    EventEmitterService.get('loginAlert').emit(false);

    //VALIDAR URL
    this.route.params.subscribe(params => this.Hash = params['']);
    if (this.Hash == null || this.Hash == '' || this.Hash == 'undefined') {
      this.alert.AlertShowDanger('QRCODE Não e Valido!');
      EventEmitterService.get('loginAlert').emit(false);
      this.rota.navigate(['']);
    }
    this._service.ConsultaPatrimonio(this.Hash).subscribe(
      (sucess) => {

        if (sucess.length > 0) {
          this.qrcode = sucess[0].qrcode;
          this.patrimonio = sucess[0].patrimonio;
          this.status = sucess[0].ativo;
          this.setor = sucess[0].setor;
          this.tipo = sucess[0].tipo_bem;
        } else {
          this.alert.AlertShowDanger('QRCODE Não e Valido!');
          this.rota.navigate(['']);
        }


      },
      (error) => {
        this.alert.AlertShowDanger('QRCODE Não e Valido!');
        this.rota.navigate(['']);
      }
    );
  }

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }

  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//

  ngOnInit(): void {
  }


}
