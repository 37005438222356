<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>
<div class="row">


    <div class="col-md-12" style="margin-top: 7px;">

        <div>
            <h4 style="text-align: center;">
                <i class="fas fa-users"></i>&nbsp;<b>{{filtro_cabecalho}}</b>
            </h4>
            <p>
                &nbsp;&nbsp;&nbsp;&nbsp;{{explicacao}}
                <br>
                &nbsp;&nbsp;&nbsp;&nbsp;<b>#FicaaDica: </b>Em caso de Celular melhor visualizado na horizontal!
            </p>
        </div>
    </div>

    <!--Filtro-->
    <div class="col-md-12">
        <div class="row" style="margin-bottom: 3px; padding-right: 5px;">

            <div class="col-md-6">
                <label style=" display: block; border: 0px; padding-bottom: 0%;"><b
                        style="padding: 0%; padding-bottom: 0%;">Filtro:</b>
                    <span style=" display: block; border: 0px; font-size: x-small; padding-top: 0%;">Consulta por
                        setor</span>
                    <select class="custom-select" type="text" name="setor" id="setor"
                        (change)="SetConsultaSeminario($event)" [(ngModel)]="setor">
                        <option value="TODOS PEDIDOS" selected="selected">TODOS PEDIDOS</option>
                        <option *ngFor="let culto of setores" [ngValue]="culto.nome">
                            {{ culto.nome }}
                        </option>

                    </select>
                </label>
            </div>

            <!--Filtro-->
            <div class="col-md" style="display: block; border: 0px;padding-bottom: 0%; padding-left: 5px; ">
                <label style=" display: block; border: 0px; padding-bottom: 0%;"><b
                        style=" padding-bottom: 0%;">Filtro:</b>
                    <span style=" display: block; border: 0px; font-size: x-small; padding-top: 0%;">Consulta por
                        Nome/Status</span>
                    <input type="text" class="form-control" (keydown)="Consulta()" id="texto_consulta"
                        style="margin-top: 0%;" />
                </label>
            </div>
        </div>
    </div>

    <!--Tabela-->
    <div class="panel-body col-md-12">
        <p-table [value]="pedido" [paginator]='false' [columns]="cols" [rows]="10" [showCurrentPageReport]="true"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" selectionMode="single"
            paginatorTemplate="{RowsPerPageDropdown} {FirstPageLink} {PreviousPageLink} {CurrentPageReport} {NextPageLink} {LastPageLink}"
            (onLazyLoad)='Paginate ($event)' styleClass="p-datatable-responsive-demo" autoLayout="true"
            [responsive]="true">

            <ng-template pTemplate="header" let-usuario let-columns>
                <tr style="background-color: dodgerblue; color: aliceblue;">
                    <th *ngFor="let col of columns">
                        {{col.header}}
                    </th>

                    <th
                        *ngIf="perfil.includes('Administrador') || perfil.includes('Presidente') || perfil.includes('Gestao')|| perfil.includes('Solicitacao_Compra')">
                        Status</th>
                    <th
                        *ngIf="perfil.includes('Administrador') || perfil.includes('Presidente') || perfil.includes('Gestao')|| perfil.includes('Solicitacao_Compra')">
                        Anotação</th>
                    <th
                        *ngIf="perfil.includes('Administrador') || perfil.includes('Presidente') || perfil.includes('Gestao')|| perfil.includes('Solicitacao_Compra')">
                        Detalhes</th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-c>
                <tr>
                    <td>
                        <span class="p-column-title">Numero Pedido</span>
                        {{c.numero_pedido}}
                    </td>
                    <td>
                        <span class="p-column-title">Nome</span>
                        {{c.nome}}
                    </td>
                    <td>
                        <span class="p-column-title">Status</span>
                        {{c.status_pedido}}
                    </td>
                    <td>
                        <span class="p-column-title">Data Pedido</span>
                        {{c.dt_pedido| date: 'dd/MM/yyyy'}}
                    </td>


                    <td
                        *ngIf="perfil.includes('Administrador') || perfil.includes('Presidente') || perfil.includes('Solicitacao_Compra')">
                        <span class="p-column-title">Status</span>
                        <button pButton pRipple type="button" icon="pi pi-sort-alt" class="p-button-rounded"
                            style="background-color: rgb(245, 211, 17);" styleClass="rounded-button ui-button-danger"
                            value={{c.idsolicitacaocompra}} (click)="SetDadosMover($event)" data-toggle="modal"
                            data-target="#exampleModalMover" title="Mover Patrimonio"></button>
                    </td>

                    <td
                        *ngIf="perfil.includes('Administrador') || perfil.includes('Presidente') || perfil.includes('Solicitacao_Compra')">
                        <span class="p-column-title">Anotações</span>
                        <button pButton pRipple type="button" icon="pi pi-comment" class="p-button-rounded"
                            style="background-color: rgb(88, 90, 245);" styleClass="rounded-button ui-button-danger"
                            value={{c.idsolicitacaocompra}} (click)="SetDadosMover($event)" data-toggle="modal"
                            data-target="#exampleModal2" title="Manutenção/Observações"></button>
                    </td>

                    <td
                        *ngIf="perfil.includes('Administrador') || perfil.includes('Presidente') || perfil.includes('Solicitacao_Compra')">
                        <span class="p-column-title">Detalhes</span>
                        <button pButton pRipple type="button" icon="pi pi-search-plus" class="p-button-rounded"
                            style="background-color: green;" styleClass="rounded-button ui-button-success"
                            value={{c.numero_pedido}} (click)="VisualizarHistorico($event)"
                            title="Visualizar Detalhe"></button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <!--Paginação-->
        <p-paginator [rows]="10" [paginator]='true' [totalRecords]=qto
            currentPageReportTemplate="Mostrando: {{x}} a {{y}} de {{qto}}" (onPageChange)="Paginate($event)"
            [showCurrentPageReport]="true">
        </p-paginator>

        <hr>

        <!--Botao Retorno-->
        <button pButton pRipple type="button" icon="pi pi-sign-in" title="Retornar"
            class="p-button-rounded p-button-danger" routerLink="/dashboard/resumo"></button>
        <br>
        <br>
        <br>
        <br>
    </div>

    <!-- Modal -->
    <div id="exampleModal" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Gerar Documento para Impressão</h4>
                </div>
                <div class="modal-body">
                    <p>Por favor escolha a opção correta para geração do PDF para impressão!</p>
                    <br>
                    <div style="font-size: medium;">
                        <input id="novo" name="base" type="radio" value="Filtro:Todos" checked /> Todos Registros (
                        {{qto}} )
                    </div>
                    <div style="font-size: medium;">
                        <input id="novo" name="base" type="radio" value="Filtro:TodosAbertos" /> Todos Registros não
                        Lidos
                    </div>
                    <div style="font-size: medium;">
                        <input id="novo" name="base" type="radio" value="Filtro:TodosFechados" /> Todos Registros Lidos
                    </div>
                    <div *ngIf="textoconsulta !='' ">
                        <div style="font-size: medium;">
                            <input id="ant" name="base" type="radio" value="Filtro:{{textoconsulta}}" /> Filtro
                            Realizado: ( {{textoconsulta}})
                        </div>
                    </div>
                    <div *ngIf="textoconsulta !='' ">
                        <div style="font-size: medium;">
                            <input id="ant" name="base" type="radio"
                                value="FiltroPagina:{{textoconsulta}} and limit 10 offset {{pagina_atual}}" /> Filtro
                            Realizado e Pagina: ( {{textoconsulta}} e
                            Pagina ( {{pagina_atual}} ))
                        </div>
                    </div>
                    <div style="font-size: medium;">
                        <input id="grade" name="base" type="radio" value="Pagina:{{pagina_atual}}" /> Pagina Atual (
                        {{pagina_atual}} )
                    </div>

                </div>

            </div>

        </div>
    </div>



    <div id="exampleModal2" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog">

            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Registrar Manutenção / Observações</h4>
                </div>
                <div class="modal-body">
                    <b>Olá {{nome}}</b>
                    <p>Informe a manutenção ou observação para o patrimonio selecionado!.</p>
                    <p>Todos os detalhes deste patriminio pode ser visualizado pela detalhamento do mesmo!.</p>
                    <form [formGroup]="forminativar">
                        <div class="form-group">
                            <label for="recipient-name" class="col-form-label">Informe a Manutenção /
                                Observação:</label>
                            <textarea rows="3" type="text" class="form-control" id="motivo" name="motivo"
                                autocomplete="off" formControlName="motivo" [(ngModel)]="inativar.motivo"
                                [ngClass]="{ 'is-invalid': submitted && m.motivo.errors }"></textarea>
                            <div *ngIf="submitted && m.motivo.errors" class="invalid-feedback">
                                <div *ngIf="submitted && m.motivo.errors.required">Preenchimento obrigatório</div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button pButton pRipple type="button" icon="pi pi-check" class="p-button-rounded" title="Registrar"
                        (click)="AnotacaoPedido()"></button>
                    <button pButton pRipple type="button" icon="pi pi-sign-in" class="p-button-rounded p-button-danger"
                        title="Retornar" data-dismiss="modal"></button>
                </div>
            </div>

        </div>
    </div>

    <!-- Modal -->
    <div id="exampleModalMover" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog">

            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Atualizar Status Pedido</h4>
                </div>
                <div class="modal-body">
                    <b>Olá {{nome}}</b>
                    <p>Ao atualizar o status do pedido o mesmo irá atualizar o resumo por tipo de pedidos.</p>
                    <p>Lembramos que em seu historico ainda constará de qual setor o mesmo pertencia!.</p>
                </div>
                <div class="modal-body">
                    <form [formGroup]="formcadastro">
                        <div class="dropdown">
                            <label>Status:</label><strong class="cor">*</strong>
                            <div class="mb-2">
                                <select class="custom-select" type="text" name="setor" id="setor"
                                    [(ngModel)]="contato.setor" formControlName="setor" (change)="SetSetorMover($event)"
                                    [ngClass]="{ 'is-invalid': submitted && f.setor.errors } ">
                                    <div *ngIf="submitted && f.setor.errors" class="invalid-feedback"></div>
                                    <option value=""></option>
                                    <option *ngFor="let documentos of tipos" [ngValue]="documentos">
                                        {{ documentos }}
                                    </option>
                                </select>
                                <div *ngIf="submitted && f.setor.errors" class="invalid-feedback">
                                    <div *ngIf="f.setor.errors.required">
                                        Preenchimento obrigatório
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" (click)="Mover()">Atualizar</button>
                    <button type="button" class="btn btn-danger" data-dismiss="modal">Fechar</button>

                </div>
            </div>

        </div>
    </div>



</div>