import { EventEmitter } from '@angular/core';

//Classe generica usada no login para emittir evento para o appcompoment
export class EventEmitterService {
    private static emitters: {
        [nomeEvento: string]: EventEmitter<any>
    } = {}

    static get(nomeEvento: string): EventEmitter<any> {
        if (!this.emitters[nomeEvento])
            this.emitters[nomeEvento] = new EventEmitter<any>();
        return this.emitters[nomeEvento];
    }
}