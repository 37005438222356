export class ComunicadoCongressoHomensModel {


    cpf: string = '';
    nome: string = '';
   
    assunto: string = '';
    mensagem: string = '';
    c: string = '';
    para: string = '';
    anexo: any;
}
