<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>
<div class="container">
    <div class="col-md-12">
        <div class="row" style="margin-right: 2px; padding-left: 20px;">
            <img src="../../../assets/img/detalhe.png" height="130px" width="100px" style="padding: 0%;" />

            <div class="col-md-10" style="padding-top: 15px;">
                <h4 class="espacamentotopo " style="margin-left: 5px;">
                    <b>Detalhe Acesso</b>
                </h4>
                <label>
                    Aqui você encontra o detalhe do cada acesso realizado na plataforma!
                    <br>
                    <b>#FicaaDica: </b>Em caso de Celular melhor visualizado na horizontal!
                </label>
            </div>
        </div>
    </div>
    <hr>
    <br>
     <label><b>Data Acesso: </b>{{dataacesso}}</label><br>   
     <label><b>Hora Acesso: </b>{{horaacesso}}</label><br>   
     <label><b>CPF: </b>{{cpf}}</label><br>  
    <label><b>Browser: </b>{{browser}}</label><br>
    <label><b>Dispositivo: </b>{{dispositivo}}</label><br><br>
    <label>Lembramos que o acesso a plataforma tem como objetivo lhe detalhar quando e por onde foi acessado a plataforma!</label>
      
    
    <br>
    <hr>    
    <button pButton pRipple type="button" icon="pi pi-sign-in" title="Retornar" class="p-button-rounded p-button-danger" routerLink="/dashboard/listagem/lista-acessos"></button>
    <br>
    <br>
    <br>
    <br>
    <br>
</div>
<button class="voltar-ao-topo" id="back"><a class="ancora" style="color: white;"><i
    class="pi pi-arrow-circle-up"></i></a></button>