import { Component, OnInit } from '@angular/core';
import { EventEmitterService } from '../shared/EventEmitterService';
import { SharedModule } from '../shared/shared.module';
import {FieldsetModule} from 'primeng/fieldset';
import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-missao-visao-valores',
  templateUrl: './missao-visao-valores.component.html',
  styleUrls: ['./missao-visao-valores.component.css']
})
export class MissaoVisaoValoresComponent implements OnInit {


  constructor(private autenticado: SharedModule,private primengConfig: PrimeNGConfig) {
    EventEmitterService.get('loginAlert').emit(false);
  }

  ngOnInit() {
    this.autenticado.ResetDados();
    this.primengConfig.ripple = true;
  }
}
