import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { Parametros } from 'src/app/shared/parametros.enum';
import { SharedModule } from 'src/app/shared/shared.module';
import { AcessosModel } from './acessos'

@Component({
  selector: 'app-acessos-listagem',
  templateUrl: './acessos-listagem.component.html',
  styleUrls: ['./acessos-listagem.component.css']
})
export class AcessosListagemComponent implements OnInit {
  private urlacesso = Parametros.ACESSOS;
  private urlrelatorioacessos = Parametros.RELATORIOSTODOSACESSOS;
  x = 1;
  y = 10;
  filtro_cabecalho: string = ""
  explicacao: string = "";
  textoconsulta: string = "";
  rota: any;
  perfil: any;
  pagina_atual = 1;
  imprimir: string = "Filtro:Todos";
  blob: Blob;
  idacesso: any;
  nome: string;


  constructor(private http: HttpClient,
    private primengConfig: PrimeNGConfig,
    private alert: AlertModalService,
    private autenticado: SharedModule,
    r: Router
  ) {
    this.rota = r;
    this.perfil = this.autenticado.perfil;
    this.nome = this.autenticado.nome;
  }

  startedClass = false;
  completedClass = false;
  preventAbuse = false;

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//

  acesso: Array<AcessosModel>;
  qto: number;
  cols: any[];


  GetLista() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get<AcessosModel[]>(this.urlacesso + 'GETListaacessosCpf/?parametro=' + this.autenticado.cpf, httpOptions).subscribe(
      dados => this.acesso = dados
    )
  }

  GetListaTotal() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get<any>(this.urlacesso + 'GETListaacessosCpfTotal/?parametro=' + this.autenticado.cpf, httpOptions).subscribe(
      dados => this.qto = dados
    )
  }

  ngOnInit() {
    if (this.autenticado.token == null) {
      this.autenticado.ResetDados();
      this.alert.AlertShowDanger('Usuário não autenticado, redirecionando...')
      this.rota.navigate(['/login']);
    } else {
      if (this.autenticado.ValidarToken() != true) {
        this.autenticado.ResetDados();
        this.alert.AlertShowDanger('Token Invalido!')
        this.rota.navigate(['/login']);
      }
    }
    this.primengConfig.ripple = true;
    this.filtro_cabecalho = "Listagem de Acessos a Plataforma"
    this.explicacao = "Aqui você está visualizando todos os acessos feitos por você a Morada de Deus."
    this.GetListaTotal();
    this.GetLista();

    this.cols = [
      { field: 'Data Acesso', header: 'Data Acesso' },
      { field: 'Hora', header: 'Hora' },
      { field: 'Navegador', header: 'Navegador' },
      { field: 'Dispositivo', header: 'Dispositivo' },
      { field: 'Detalhe', header: 'Detalhe' }

    ];
  }

  GetListaTotalQuantidadeDez(pagina: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get<any>(this.urlacesso + 'GetListaConsultaLimite/?cpf=' + this.autenticado.cpf + '&id=' + pagina, httpOptions).subscribe(
      dados => this.acesso = dados
    )
  }
  Paginate(event) {
    this.x = event.first + 1;
    this.GetListaTotalQuantidadeDez(event.first.toString());
    if (event.first + 10 > this.qto) {
      this.y = this.qto;
    } else {
      this.y = event.first + 10;
    }
  }

  Consulta() {
    var texto = (<HTMLInputElement>document.getElementById("texto_consulta")).value;
    if (texto != "") {
      this.textoconsulta = texto;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
        })
      }
      this.http.get<AcessosModel[]>(this.urlacesso + 'GetListaConsultaacessoCpf/?parametro=' + this.autenticado.cpf + '&consulta=' + texto, httpOptions).subscribe(
        dados => this.acesso = dados, dados => this.qto = dados.length
      )
    } else {
      this.textoconsulta = "";
      this.GetLista();
    }
  }

  VisualizarDetalhe(event) {
    localStorage.removeItem('AcessoDetalhe');
    localStorage.setItem('AcessoDetalhe', event.currentTarget.value);
    this.rota.navigate(['/dashboard/detalhe/acesso']);
  }


  ImprimirAcessos() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token
      })
    }
    this.http.get(this.urlrelatorioacessos + 'GetRelatorioTodosacessosDetalhado/?parametro=' + this.autenticado.cpf, httpOptions).subscribe(
      (success) => {
        const linkSource = 'data:application/pdf;base64,' + success;
        const downloadLink = document.createElement("a");
        const fileName = "IEPMD_Acessos_" + (this.autenticado.nome).replace(" ", "") + ".pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }, (error) => {
        if (error.status == '403') {
          this.alert.AlertShowDanger('Erro ao tentar Gerar PDF');
        } else {
          const linkSource = 'data:application/pdf;base64,' + error.error.text;
          const downloadLink = document.createElement("a");
          const fileName = "IEPMD_Acessos_" + (this.autenticado.nome).replace(" ", "") + ".pdf";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      }
    );
  }


  SetDados(event) {
    this.idacesso = event.currentTarget.value;
  }

}
