<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>

<h4 class="espacamentotopo">
    <img src="../../../../assets/img/novos/prestacao.png" style="width: 40px" /> Cadastro
    Oportunidades
</h4>
<label class="j">
    Seja bem-vindo(a)!<br />
    &nbsp;&nbsp;&nbsp;&nbsp; Você está na área de oportunidades!, Informamos que
    todos os dados informados são de reponsabilidade do cadastrante!,A Morada de Deus reserva-se no direito de
    exclusão sem aviso previo caso não concorde com o cadastro realizado.Lembramos que este cadastro e um ajuda para que
    todos membros tenham acesso as oportunidades em aberto!
</label>
<label class="j">
    Lembramos que os campos com (<strong class="cor"> *</strong> ) são campos de
    preenchimento obrigatório!
</label>
<hr class="espacamentotopo bold" />
<form [formGroup]="formcadastro" disabled="">
    <div class="row">
        <!---INCIO PRIMEIRA LINHA-->
        <div class="col-md-2">
            <div class="form-group">
                <label>CPF:</label><strong class="cor">*</strong>
                <input type="text" class="form-control" id="cpf" placeholder="informe o CPF" mask="CPF_CNPJ" name="cpf"
                    disabled="true" maxlength="14" autocomplete="off" formControlName="cpf"
                    [(ngModel)]="cadastromembros.cpf" />

            </div>
        </div>

        <div class="col-md-8">
            <div class="form-group">
                <label>Nome:</label><strong class="cor">*</strong>
                <input type="text" class="form-control" id="nome" name="nome" disabled="true" formControlName="nome"
                    [(ngModel)]="cadastromembros.nome" autocomplete="off" />
            </div>
        </div>

        <div class="col-md" style="padding-top: 2.5%;">
            <div>
                <input type="checkbox" id="ativo" data-md-icheck (change)="CKAtivo($event)" formControlName="ativo"
                    name="ativo" [(ngModel)]="cadastromembros.ativo" />
                <b>&nbsp;Ativa</b>
            </div>
        </div>

    </div>
    <!---FIM PRIMEIRA LINHA-->



    <!---INICIO TERCEIRA LINHA-->
    <div class="row">

        <div class="col-md-6">
            <div class="form-group">
                <label>Oportunidade:</label><span style="font-size: smaller;"> Ex:
                    Vaga Balconista</span><strong class="cor">*</strong>
                <input type="text" class="form-control" id="oportunidade" name="oportunidade"
                    [(ngModel)]="cadastromembros.oportunidade" placeholder="Informe a oportunidade"
                    formControlName="oportunidade" maxlength="500" minlength="3" autocomplete="off"
                    [ngClass]="{ 'is-invalid': submitted && f.oportunidade.errors }" />
                <div *ngIf="submitted && f.oportunidade.errors" class="invalid-feedback">
                    <div *ngIf="f.oportunidade.errors.required">Preenchimento obrigatório</div>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <label>Telefone/Celular:</label><strong class="cor">*</strong>
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1"><i class="fas fa-phone"></i></span>
                </div>
                <input type="tel" class="form-control" id="telefone" name="telefone"
                    [(ngModel)]="cadastromembros.telefone" placeholder="Informe o Telefone"
                    title="Formato (DD) 0000-0000 ou (DD) 00000-0000" formControlName="telefone" maxlength="500"
                    minlength="3" autocomplete="off" mask="(00) 0000-0000 || (00) 00000-0000" [ngClass]="{
              'is-invalid': submitted && f.telefone.errors
            }" />
                <div *ngIf="submitted && f.telefone.errors" class="invalid-feedback">
                    <div *ngIf="f.telefone.errors.required">
                        Preenchimento obrigatório
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <label>WhatsApp:</label>
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1"><i class="fas fa-phone"></i></span>
                </div>
                <input type="tel" class="form-control" id="celular" name="celular" maxlength="500" autocomplete="off"
                    mask="(00) 0000-0000 || (00) 00000-0000" [(ngModel)]="cadastromembros.celular"
                    placeholder="Informe o Telefone" formControlName="celular"
                    title="Formato (DD) 0000-0000 ou (DD) 00000-0000" autocomplete="off" />
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md">
            <div class="form-group">
                <label style="margin-left: 3px;">Descrição da Oportunidade:</label><strong class="cor">*</strong>
                <textarea type="text" rows="3" class="form-control" id="informacoes" name="informacoes"
                    formControlName="informacoes" [(ngModel)]="cadastromembros.informacoes" rows="2" autocomplete="off"
                    maxlength="500" minlength="3"
                    [ngClass]="{ 'is-invalid': submitted && f.informacoes.errors }"></textarea>

                <div *ngIf="submitted && f.informacoes.errors" class="invalid-feedback">
                    <div *ngIf="f.informacoes.errors.required">
                        Preenchimento obrigatório
                    </div>
                </div>
                <span>{{ cadastromembros.informacoes.length }}/500</span>
            </div>
        </div>
        <div class="col-md-4">
            <div>
                <label>Horário de Trabalho:</label><strong class="cor">*</strong>
                <textarea type="text" class="form-control" id="funcionamento" placeholder="Horário de funcionamento"
                    name="funcionamento" formControlName="funcionamento" [(ngModel)]="cadastromembros.funcionamento"
                    maxlength="500" minlength="5" rows="2" autocomplete="off"
                    [ngClass]="{ 'is-invalid': submitted && f.funcionamento.errors }"></textarea>
                <div *ngIf="submitted && f.funcionamento.errors" class="invalid-feedback">
                    <div *ngIf="f.funcionamento.errors.required">
                        Preenchimento obrigatório
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">

        <div class="col-md">
            <div class="form-group">
                <label>TAG de pesquisa:</label><span style="font-size: smaller;"> Ex:
                    Tecnologia|Suporte|Informática</span><strong class="cor">*</strong>
                <input type="text" class="form-control" id="tag" name="tag"
                    placeholder="Informe as TAGS separadas por |" formControlName="tag" maxlength="500" minlength="3"
                    [(ngModel)]="cadastromembros.tag" autocomplete="off"
                    [ngClass]="{ 'is-invalid': submitted && f.tag.errors }" />
                <div *ngIf="submitted && f.tag.errors" class="invalid-feedback">
                    <div *ngIf="f.tag.errors.required">Preenchimento obrigatório</div>
                </div>
                <span style="font-size: smaller;"> <b>Obs:</b> separe por | entre as TAGS como no exemplo,essas
                    TAGS são utilizadas na consulta.</span>
                <br><span>{{ cadastromembros.tag.length }}/500</span>

            </div>
        </div>


        <div class="col-md-3">
            <div class="form-group">
                <label>E-mail:</label>
                <input type="text" class="form-control" id="email" name="email" [(ngModel)]="cadastromembros.email"
                    formControlName="email" maxlength="500" autocomplete="off" />
            </div>
        </div>

        <div class="col-md-2">
            <div class="dropdown">
                <label>Sexo:</label><strong class="cor">*</strong>
                <div class="mb-3">
                    <select class="custom-select" type="text" (change)="changeSexo($event)" name="sexo" id="sexo"
                        [(ngModel)]="cadastromembros.sexo" formControlName="sexo" autocomplete="off"
                        [ngClass]="{ 'is-invalid': submitted && f.sexo.errors }">
                        <option value=""></option>
                        <option *ngFor="let city of lsexo" [ngValue]="city">
                            {{ city }}
                        </option>
                    </select>
                    <div *ngIf="submitted && f.sexo.errors" class="invalid-feedback">
                        <div *ngIf="f.sexo.errors.required">Preenchimento obrigatório</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-2">
            <div class="form-group">
                <label>Quantidade:</label>
                <input type="text" class="form-control" id="quantidade" 
                    name="quantidade" formControlName="quantidade" [(ngModel)]="cadastromembros.quantidade"
                    maxlength="9" minlength="1" autocomplete="off"
                    [ngClass]="{ 'is-invalid': submitted && f.quantidade.errors }" />
                <div *ngIf="submitted && f.quantidade.errors" class="invalid-feedback">
                    <div *ngIf="f.quantidade.errors">
                        Valor mínimo 1
                    </div>                    
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-6">
            <div class="form-group">
                <label>Site:</label><span><a style="color: blue;" (click)=site()> exemplo</a></span>
                <input type="text" class="form-control" id="site" name="site"
                    placeholder="Copie e cole a url do site aqui!" formControlName="site" maxlength="500"
                    [(ngModel)]="cadastromembros.site" autocomplete="off" />
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label>Facebook:</label><span><a style="color: blue;" (click)=facebook()>
                        exemplo</a></span>
                <input type="text" class="form-control" id="facebook" name="facebook"
                    placeholder="Copie e cole a url do facebook aqui!" formControlName="facebook" maxlength="500"
                    [(ngModel)]="cadastromembros.facebook" autocomplete="off" />

            </div>
        </div>


        <div class="col-md-6">
            <div class="form-group">
                <label>Instagram:</label><span><a style="color: blue;" (click)=instagram()>
                        exemplo</a></span>
                <input type="text" class="form-control" id="instagram" name="instagram"
                    placeholder="Copie e cole a url do Instagram aqui!" formControlName="instagram" maxlength="500"
                    [(ngModel)]="cadastromembros.instagram" autocomplete="off" />

            </div>
        </div>


        <div class="col-md-6">
            <div class="form-group">
                <label>Google Maps</label><span><a style="color: blue;" (click)=maps()>
                        exemplo</a></span>
                <input type="text" class="form-control" id="maps" name="maps"
                    placeholder="Copie e cole a url do Google Maps aqui!" formControlName="maps" maxlength="500"
                    [(ngModel)]="cadastromembros.maps" autocomplete="off" />

            </div>
        </div>
    </div>








    <div class="row">
        <div class="col-md-2">
            <div class="form-group">
                <label>Cep:</label>
                <input type="tel" class="form-control" placeholder="Informe o CEP" id="cep" mask="00.000-000" name="cep"
                    formControlName="cep" (blur)="consulta_cep(cadastromembros.cep)" [(ngModel)]="cadastromembros.cep"
                    autocomplete="off" />
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label>Logradouro:</label><strong class="cor">*</strong>
                <input type="text" class="form-control" id="logradouro" placeholder="Informe Logradouro"
                    name="logradouro" formControlName="logradouro" [(ngModel)]="cadastromembros.logradouro"
                    maxlength="500" minlength="3" autocomplete="off"
                    [ngClass]="{ 'is-invalid': submitted && f.logradouro.errors }" />
                <div *ngIf="submitted && f.logradouro.errors" class="invalid-feedback">
                    <div *ngIf="f.logradouro.errors.required">
                        Preenchimento obrigatório
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-2">
            <div class="form-group">
                <label>Numero:</label><strong class="cor">*</strong>
                <input type="tel" class="form-control" id="numero" placeholder="Numero" min="1" name="numero"
                    autocomplete="off" formControlName="numero" [(ngModel)]="cadastromembros.numero" maxlength="9"
                    [ngClass]="{ 'is-invalid': submitted && f.numero.errors }" />
                <div *ngIf="submitted && f.numero.errors" class="invalid-feedback">
                    <div *ngIf="f.numero.errors.required">
                        Preenchimento obrigatório
                    </div>
                    <div *ngIf="f.numero.errors.required">Menor valor 0</div>
                </div>
            </div>
        </div>

        <div class="col-md-2">
            <div class="form-group">
                <label>apto:</label>
                <input type="tel" class="form-control" id="apto" placeholder="apto" name="apto" formControlName="apto"
                    [(ngModel)]="cadastromembros.apto" maxlength="9" autocomplete="off" />
            </div>
        </div>
    </div>

    <!----->








    <!----->
    <div class="row">
        <div class="col-md-5">
            <div class="form-group">
                <label>Bairro:</label><strong class="cor">*</strong>
                <input type="text" class="form-control" id="bairro" placeholder="Informe o Bairro" name="bairro"
                    formControlName="bairro" [(ngModel)]="cadastromembros.bairro" maxlength="500" minlength="3"
                    autocomplete="off" [ngClass]="{ 'is-invalid': submitted && f.bairro.errors }" />
                <div *ngIf="submitted && f.bairro.errors" class="invalid-feedback">
                    <div *ngIf="f.bairro.errors.required">
                        Preenchimento obrigatório
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-2">
            <div class="dropdown">
                <label>UF:</label><strong class="cor">*</strong>
                <div class="mb-3">
                    <select class="custom-select" type="text" (change)="changeCity($event)" name="uf" id="uf"
                        [(ngModel)]="cadastromembros.uf" formControlName="uf" autocomplete="off"
                        [ngClass]="{ 'is-invalid': submitted && f.uf.errors }">
                        <option value=""></option>
                        <option *ngFor="let city of luf" [ngValue]="city">
                            {{ city }}
                        </option>
                    </select>
                    <div *ngIf="submitted && f.uf.errors" class="invalid-feedback">
                        <div *ngIf="f.uf.errors.required">Preenchimento obrigatório</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-5">
            <div class="form-group">
                <label>Cidade:</label><strong class="cor">*</strong>
                <input type="text" class="form-control" id="cidade" placeholder="Informe sua cidade" name="cidade"
                    formControlName="cidade" [(ngModel)]="cadastromembros.cidade" autocomplete="off"
                    [ngClass]="{ 'is-invalid': submitted && f.cidade.errors }" maxlength="500" minlength="3" />
                <div *ngIf="submitted && f.cidade.errors" class="invalid-feedback">
                    <div *ngIf="f.cidade.errors.required">
                        Preenchimento obrigatório
                    </div>
                </div>
            </div>
        </div>
    </div>



    <div class="row" style="padding: 0%;">
        <div class="col-md" style="padding-top: 0%;">
            <div>
                <input type="checkbox" id="marked" data-md-icheck (change)="AceiteTermo($event)"
                    formControlName="marked" [(ngModel)]="marked" />
                <b>&nbsp;Aceito os Termos</b>.<strong class="cor">*</strong>
                <div *ngIf="submitted && f.marked.errors">
                    <div *ngIf="f.marked.errors.required" class="cor bold">
                        O aceite dos termos e obrigatório
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!----->
    <div style="padding-top: 15px; padding-bottom: 10px;">
        <button pButton pRipple type="button" icon="pi pi-save" class="p-button-rounded" style="margin-right: 5px;"
            title=":: SALVAR ::" (click)="onSubmit()"></button>
        <button pButton pRipple type="button" icon="pi pi-refresh" style="margin-right: 5px;"
            class="p-button-rounded p-button-secondary" title="::LIMPAR::" (click)="reset()"></button>
        <button pButton pRipple type="button" icon="pi pi-sign-in" class="p-button-rounded p-button-danger"
            title="::Fechar::" routerLink="/dashboard/resumo">
        </button>

    </div>
    <!-- Modal -->
    <button id="openModalButton" [hidden]="true" data-toggle="modal" data-target="#myModal"></button>
    <div class="modal fade bd-example-modal-lg" data-backdrop="static" data-keyboard="false" id="myModal" tabindex="-1"
        role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">
                        <i class="fas fa-info-circle" style="color: blue"></i>
                        {{ modaltitulo }}
                    </h5>
                </div>
                <div class="modal-body">
                    <div [innerHTML]="modalconteudo"></div>
                </div>
                <div class="modal-footer">
                    <div *ngIf="bt_termo == false">
                        <button pButton pRipple type="button" icon="pi pi-search" title="Recuperar Autenticação"
                            class="p-button-rounded" routerLink="/recuperar-autenticacao" data-dismiss="modal">
                        </button>
                        <button pButton pRipple type="button" icon="pi pi-pencil"
                            class="p-button-rounded p-button-danger" title="Reportar Problema" routerLink="/problema"
                            data-dismiss="modal">
                        </button>
                        <button pButton pRipple type="button" icon="pi pi-refresh" style="margin-right: 5px;"
                            class="p-button-rounded p-button-secondary" title="::LIMPAR::" (click)="reset()"
                            data-dismiss="modal"></button>
                    </div>
                    <div *ngIf="bt_termo == true">
                        <button pButton pRipple type="button" icon="pi pi-thumbs-up" title="CONCORDO"
                            class="p-button-rounded" data-dismiss="modal">
                        </button>
                        <button pButton pRipple type="button" icon="pi pi-thumbs-down"
                            class="p-button-rounded p-button-danger" title="DISCORDO" (click)="DiscordeTermo()"
                            data-dismiss="modal">
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</form>

<br><br><br>