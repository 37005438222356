export class deducaomodel {
    [x: string]: any;

    cpf: string = '';
    nome: string = '';
    valor: any = '';
    autorizacao: Date;  
    descricao: string = '';
    observacao: string = '';
    ministerio: string = '';
    dt_referencia: any;
    reembolso: string = '';   
   }