export class MinisterioModel {

    idusuario: any;
    membro: boolean = false;
    aluno: boolean = false;
    obreiro: boolean = false;
    consolidacao:boolean=false;
    diacono: boolean = false;
    louvor: boolean = false;
    secretaria: boolean = false;
    pastor_presidente: boolean = false;
    pastor_auxiliar: boolean = false;
    ministerio_casais: boolean = false;
    ministerio_jovem: boolean = false;
    ministerio_mulheres: boolean = false;
    ministerio_homens: boolean = false;
    ministerio_danca: boolean = false;
    

}