import { CookieService } from 'ngx-cookie-service';
import { AlertModalService } from './../shared/alert-modal/alert-modal.service';
import { LoginService } from './login.service';
import { Login } from './LogimModel';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { EventEmitterService } from '../shared/EventEmitterService';
import { SharedModule } from '../shared/shared.module';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  formlogin: FormGroup;
  login: Login;
  opcao: string;
  valorlogin: string;
  valorsenha: string;
  ck: boolean = false;
  submitted = false;
  startedClass = false;
  completedClass = false;
  preventAbuse = false;


  constructor(
    private formBuilder: FormBuilder,
    private loginservice: LoginService,
    private alert: AlertModalService,
    private cookie: CookieService
  ) {
    EventEmitterService.get('loginAlert').emit(false);

    
  }

  detectar_mobile() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'Edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'Opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'Chrome';
      case agent.indexOf('trident') > -1:
        return 'Ie';
      case agent.indexOf('firefox') > -1:
        return 'Firefox';
      case agent.indexOf('safari') > -1:
        return 'Safari';
      default:
        return 'Outro';
    }
  }

  detectar() { 
    if( navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i)
    ){
       return "Celular / Tablet";
     }
    else {
       return "Computador";
     }
   }
  //Metodo para criar o form
  createForm() {
    this.formlogin = this.formBuilder.group({
      login: ["", Validators.required],
      senha: ["", Validators.required],
      browser: ["", ],
      dispositivo: ["", ],
      opcao: ['']
    });

    //carregar dados de login form caso exista
    if (this.cookie.get("salvar_login") == "true") {
      this.valorlogin = this.cookie.get("login");
      this.valorsenha = this.cookie.get("senha");
      this.ck = true;
    }
  }

  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }

  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }

  // pegar o nome dos campos para validação
  get f() {
    return this.formlogin.controls;
  }

  //Inicial
  ngOnInit(): void {
    
    this.createForm();
    this.formlogin.controls['browser'].setValue(this.detectar_mobile());
    this.formlogin.controls['dispositivo'].setValue(this.detectar());
    
  }

  // chamar o service para fazer o login
  onSubmit() {
    //validar campos
    this.submitted = true;
    if (this.formlogin.invalid) {
      this.alert.AlertShowDanger('Informe os dados corretamente!');
      return;
    }
    //chamar consulta Service / Api
    this.loginservice.PostLogin(this.formlogin.value);
    

  }


}
