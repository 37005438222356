<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>
<h5 class="espacamentotopo container">
    <i class="fas fa-user-edit" style="margin-left: 20px;"></i>&nbsp;&nbsp;Inscrição Culto Presencial
</h5>
<label class="j container">
    Seja bem-vindo(a)!<br />
    &nbsp;&nbsp;&nbsp;&nbsp; Você está na área de cadastro para participação dos cultos presenciais de nossa
    igreja,atendendo ao decreto estamos com capacidade reduzida visando a segurança de todos!<br>
    &nbsp;&nbsp;&nbsp;&nbsp;Não se preocupe!,se
    não conseguir assistir
    presencialmente estaremos transmitindo o mesmo em nossa rede social do instagram (<a
        href="https://www.instagram.com/moradajfoficial/"><b>@moradajfoficial</b></a>).<br>
    &nbsp;&nbsp;&nbsp;&nbsp;Lembramos que todos os dados aqui informados são tratados somente por nossa equipe de
    gestão não sendo compartilhados com terceiros.
</label>
<label class="j">
    &nbsp;&nbsp;&nbsp;&nbsp;Lembramos que os campos com (<strong class="cor"> *</strong> ) são campos de
    preenchimento obrigatório!
</label><br>
<label class="j"><a href="https://covid19.pjf.mg.gov.br/programa_jfpelavida.php"
    style="color: red; margin-left: 15px;"><b>Decretos JF PELA VIDA</b></a> (passível de mudança sem aviso
prévio)</label><BR>
<label class="j" style="margin-left: 45px; color: red; font-weight: bold;">- LEMBRE-SE DE INFORMAR UM E-MAIL VÁLIDO ONDE
    OS DADOS PARA ENTRADA SERÃO ENVIADOS.<br>- CASO NÃO TENHA E-MAIL LEMBRE-SE DE LEVAR O DOCUMENTO UTILIZADO NO
    CADASTRO.<br>- O CADASTRO AQUI REALIZADO DA DIREITO A ENTRADA SOMENTE PARA O CULTO PRESENCIAL NÃO SENDO VALIDO PARA DEMAIS ENTRADAS, EVENTOS OU CONGRESSOS.</label>

<hr class="espacamentotopo bold" />
<form [formGroup]="formculto" class="m" disabled="">
    <div class="row">

        <div class="col-md-2">
            <div class="dropdown">
                <label>Tipo Documento:</label><strong class="cor">*</strong>
                <div class="mb-3">
                    <select class="custom-select" type="text" name="tipo_culto" id="tipo_documento"
                        [(ngModel)]="cadastroculto.tipo_documento" formControlName="tipo_documento"
                        (change)="Tipo($event)" [ngClass]="{ 'is-invalid': submitted && f.tipo_documento.errors }">
                        <div *ngIf="submitted && f.tipo_documento.errors" class="invalid-feedback"></div>
                        <option value=""></option>
                        <option *ngFor="let documento of documentos" [ngValue]="documento">
                            {{ documento }}
                        </option>
                    </select>
                    <div *ngIf="submitted && f.tipo_documento.errors" class="invalid-feedback">
                        <div *ngIf="f.tipo_documento.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!---INCIO PRIMEIRA LINHA-->
        <div class="col-md-2">
            <div class="form-group">
                <label>CPF / RG:</label><strong class="cor">*</strong>
                <input type="text" class="form-control" id="cpf" placeholder="informe o CPF"
                    mask="000.000.000-00||00.000.000" name="cpf" maxlength="14" minlength="8" autocomplete="off"
                    formControlName="cpf" (blur)="consultacpf(cadastroculto.cpf)" [(ngModel)]="cadastroculto.cpf"
                    [ngClass]="{ 'is-invalid': submitted && f.cpf.errors }" />
                <div *ngIf="submitted && f.cpf.errors" class="invalid-feedback">
                    <div *ngIf="f.cpf.errors.required">Preenchimento obrigatório</div>
                </div>
            </div>
        </div>

        <div class="col-md-5">
            <div class="form-group">
                <label>Nome Completo:</label><strong class="cor">*</strong>
                <input type="text" class="form-control" id="nome" name="nome" [(ngModel)]="cadastroculto.nome"
                    placeholder="Informe Nome Completo" formControlName="nome" maxlength="500" minlength="3"
                    [(ngModel)]="cadastroculto.nome" autocomplete="off"
                    [ngClass]="{ 'is-invalid': submitted && f.nome.errors }" />
                <div *ngIf="submitted && f.nome.errors" class="invalid-feedback">
                    <div *ngIf="f.nome.errors.required">Preenchimento obrigatório</div>
                </div>
            </div>
        </div>

        <div class="col-md-3">
            <label>WhatsApp:</label><strong class="cor">*</strong>
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1"><i class="fas fa-phone"></i></span>
                </div>
                <input type="text" class="form-control" id="telefone" name="telefone"
                    [(ngModel)]="cadastroculto.telefone" placeholder="Informe o Telefone"
                    title="Formato (DD) 0000-0000 ou (DD) 00000-0000" formControlName="telefone" maxlength="500"
                    minlength="3" autocomplete="off" mask="(00) 0000-0000 || (00) 00000-0000" [ngClass]="{
                  'is-invalid': submitted && f.telefone.errors
                }" />
                <div *ngIf="submitted && f.telefone.errors" class="invalid-feedback">
                    <div *ngIf="f.telefone.errors.required">
                        Preenchimento obrigatório
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!---FIM PRIMEIRA LINHA-->

    <!---INICIO SEGUNDA LINHA-->
    <div class="row">
        <div class="col-md-5">
            <label>E-mail:</label>
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1"><i class="fas fa-at"></i></span>
                </div>
                <input type="email" class="form-control" id="email" placeholder="Informe E-mail" name="email"
                    formControlName="email" [(ngModel)]="cadastroculto.email" maxlength="500" minlength="3"
                    autocomplete="off" />
            </div>

        </div>
        <div class="col-md-7">
            <div class="dropdown">
                <label>Culto:</label><strong class="cor">*</strong>
                <div class="mb-3">
                    <select class="custom-select" type="text" name="tipo_culto" id="tipo_culto"
                        [(ngModel)]="cadastroculto.tipo_culto" (change)="DadosSeminario(cadastroculto.tipo_culto)"
                        formControlName="tipo_culto" [ngClass]="{ 'is-invalid': submitted && f.tipo_culto.errors }">
                        <div *ngIf="submitted && f.tipo_culto.errors" class="invalid-feedback"></div>
                        <option value=""></option>
                        <option *ngFor="let culto of cultos" [ngValue]="culto">
                            {{ culto }}
                        </option>
                    </select>
                    <span style="font-size: small; margin-left: 5px; font-style: oblique;"><b>{{dados_culto}}</b></span>
                    <div *ngIf="submitted && f.tipo_culto.errors" class="invalid-feedback">
                        <div *ngIf="f.tipo_culto.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <!---FIM SEGNDA LINHA-->
    <div class="row">
        <div class="col-md-3">
            <div class="dropdown">
                <label>Como ficou sabendo?:</label><strong class="cor">*</strong>
                <div class="mb-3">
                    <select class="custom-select" type="text" name="ficou_sabendo" id="ficou_sabendo"
                        [(ngModel)]="cadastroculto.ficou_sabendo" formControlName="ficou_sabendo"
                        [ngClass]="{ 'is-invalid': submitted && f.ficou_sabendo.errors }">
                        <div *ngIf="submitted && f.ficou_sabendo.errors" class="invalid-feedback"></div>
                        <option value=""></option>
                        <option *ngFor="let d of ficousabendo" [ngValue]="d">
                            {{ d }}
                        </option>
                    </select>
                    <div *ngIf="submitted && f.ficou_sabendo.errors" class="invalid-feedback">
                        <div *ngIf="f.ficou_sabendo.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="dropdown">
                <label>Pertence da Morada de Deus:</label><strong class="cor">*</strong>
                <div class="mb-3">
                    <select class="custom-select" type="text" name="ministerio_morada"
                        (change)="changeministeriomorada($event)" id="ministerio_morada"
                        [(ngModel)]="cadastroculto.ministerio_morada" formControlName="ministerio_morada"
                        [ngClass]="{ 'is-invalid': submitted && f.ministerio_morada.errors }">
                        <div *ngIf="submitted && f.ministerio_morada.errors" class="invalid-feedback"></div>
                        <option value=""></option>
                        <option *ngFor="let d of membromorada" [ngValue]="d">
                            {{ d }}
                        </option>
                    </select>
                    <div *ngIf="submitted && f.ministerio_morada.errors" class="invalid-feedback">
                        <div *ngIf="f.ministerio_morada.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-6">
            <label>Pertence Qual Ministerio?:</label><strong class="cor">*</strong>

            <div class="input-group mb-3" *ngIf="mostrar =='T' ">
                <div class="input-group-prepend">
                </div>
                <input type="text" class="form-control" id="ministerio_pertence"
                    placeholder="Informe Ministerio Pertence" name="ministerio_pertence"
                    formControlName="ministerio_pertence" [(ngModel)]="cadastroculto.ministerio_pertence"
                    maxlength="500" minlength="3" autocomplete="off" [ngClass]="{
                            'is-invalid': submitted && f.ministerio_pertence.errors
                          }" />
                <div *ngIf="submitted && f.ministerio_pertence.errors" class="invalid-feedback">
                    <div *ngIf="f.ministerio_pertence.errors.required">
                        Preenchimento obrigatório
                    </div>
                </div>
            </div>
            <div class="input-group mb-3" *ngIf="mostrar =='F' ">
                <div class="input-group-prepend">
                </div>
                <input type="text" class="form-control" id="ministerio_pertence" disabled
                    placeholder="Informe Ministerio Pertence" name="ministerio_pertence"
                    formControlName="ministerio_pertence" [(ngModel)]="cadastroculto.ministerio_pertence"
                    maxlength="500" minlength="3" autocomplete="off" [ngClass]="{
                            'is-invalid': submitted && f.ministerio_pertence.errors
                          }" />
                <div *ngIf="submitted && f.ministerio_pertence.errors" class="invalid-feedback">
                    <div *ngIf="f.ministerio_pertence.errors.required">
                        Preenchimento obrigatório
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-2">
            <div class="form-group">
                <label>Cep:</label>
                <input type="text" class="form-control" placeholder="Informe o CEP" id="cep" mask="00.000-000"
                    name="cep" formControlName="cep" (blur)="consulta_cep(cadastroculto.cep)"
                    [(ngModel)]="cadastroculto.cep" maxlength="500" minlength="3" />
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label>Logradouro:</label>
                <input type="text" class="form-control" id="logradouro" placeholder="Informe Logradouro"
                    name="logradouro" formControlName="logradouro" [(ngModel)]="cadastroculto.logradouro"
                    maxlength="500" minlength="3" />
            </div>
        </div>
        <div class="col-md-2">
            <div class="form-group">
                <label>Numero:</label>
                <input type="number" class="form-control" id="numero" placeholder="Numero" name="numero"
                    formControlName="numero" [(ngModel)]="cadastroculto.numero" maxlength="9" />
            </div>
        </div>
        <div class="col-md-2">
            <div class="form-group">
                <label>apto:</label>
                <input type="number" class="form-control" id="apto" placeholder="apto" name="apto"
                    formControlName="apto" [(ngModel)]="cadastroculto.apto" maxlength="9" />
            </div>
        </div>
    </div>
    <!----->
    <!----->
    <div class="row">
        <div class="col-md-5">
            <div class="form-group">
                <label>Bairro:</label>
                <input type="text" class="form-control" id="bairro" placeholder="Informe o Bairro" name="bairro"
                    formControlName="bairro" [(ngModel)]="cadastroculto.bairro" maxlength="500" minlength="3" />
            </div>
        </div>
        <div class="col-md-2">
            <div class="dropdown">
                <label>UF:</label>
                <div class="mb-3">
                    <select class="custom-select" type="text" name="uf" id="uf" [(ngModel)]="cadastroculto.uf"
                        formControlName="uf">
                        <option value=""></option>
                        <option *ngFor="let city of luf" [ngValue]="city">
                            {{ city }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="col-md-5">
            <div class="form-group">
                <label>Cidade:</label>
                <input type="text" class="form-control" id="cidade" placeholder="Informe sua cidade" name="cidade"
                    formControlName="cidade" [(ngModel)]="cadastroculto.cidade" maxlength="500" minlength="3" />
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md">
            <div class="form-group">
                <label style="padding: 0%;">Alguma dúvida?:</label> <span
                    style="font-size: small; padding: 0%; color: red;">
                    (
                    Favor preencher somente em caso de dúvidas )</span>
                <textarea type="text" rows="2" class="form-control" id="duvida" aria-describedby="emailHelp"
                    name="duvida" formControlName="duvida" [(ngModel)]="cadastroculto.duvida" autocomplete="off"
                    maxlength="500" minlength="3"></textarea>

                <span>{{ cadastroculto.duvida.length }}/500</span>
            </div>
        </div>
    </div>
    <!----->
    <div>
        <button pButton pRipple type="button" icon="pi pi-save" class="p-button-rounded" style="margin-right: 5px;"
            title=":: SALVAR ::" (click)="onSubmit()"></button>
        <button pButton pRipple type="button" icon="pi pi-refresh" style="margin-right: 5px;"
            class="p-button-rounded p-button-secondary" title="::LIMPAR::" (click)="reset()"></button>
        <button pButton pRipple type="button" icon="pi pi-home" style="margin-right: 5px;"
            class="p-button-rounded p-button-help" title="::HOME::" routerLink=""></button>
        <a href="https://api.whatsapp.com/send?l=pt_BR&phone=55032988189440&text=Problemas Cadastro Culto Adultos"
            style="text-align: right; float: right;" title="Fale Conosco" target="_blank">Informar Problema!</a>
    </div>

    <!-- Modal -->
    <button id="openModalButton" [hidden]="true" data-toggle="modal" data-target="#myModal"></button>
    <div class="modal fade bd-example-modal-lg" data-backdrop="static" data-keyboard="false" id="myModal" tabindex="-1"
        role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">
                        <i class="fas fa-info-circle" style="color: blue"></i>
                        {{ modaltitulo }}
                    </h5>
                </div>
                <div class="modal-body">
                    <div [innerHTML]="modalconteudo"></div>
                </div>
                <div class="modal-footer">
                    <div>
                        <button pButton pRipple type="button" icon="pi pi-exclamation-triangle"
                            class="p-button-rounded p-button-warning" title=":: REPORTAR PROBLEMAS ::"
                            data-dismiss="modal" routerLink="/problema"></button>
                        <button pButton pRipple type="button" icon="pi pi-times"
                            class="p-button-rounded p-button-danger" title=":: FECHAR ::" data-dismiss="modal"
                            (click)="reset()">
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>



</form>