import { AfterViewInit, Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BarcodeScannerLivestreamComponent } from 'ngx-barcode-scanner';
import { ConfirmarCadastroService } from '../confirmar-cadastro/confirmar-cadastro.service';
import { QrCodeService } from './qr-code.service';
import { AlertModalService } from '../shared/alert-modal/alert-modal.service';
import { EventEmitterService } from '../shared/EventEmitterService';

@Component({
  selector: 'app-qr-code',
  templateUrl: './qr-code.component.html',
  styleUrls: ['./qr-code.component.css']
})



export class QrCodeComponent implements OnInit {

  public Hash;
  startedClass = false;
  completedClass = false;
  preventAbuse = false;
  rota: Router;
  _serviceconfirmarcadastro: QrCodeService;


  constructor(private route: ActivatedRoute,
    private alert: AlertModalService,
    private s: QrCodeService,
    router: Router) {
    this.rota = router;
    this._serviceconfirmarcadastro = s;
    EventEmitterService.get('loginAlert').emit(false);

    //VALIDAR URL
    this.route.params.subscribe(params => this.Hash = params['']);
    if (this.Hash == null || this.Hash == '' || this.Hash == 'undefined') {
      this.alert.AlertShowDanger('QRCODE Não e Valido!');
      EventEmitterService.get('loginAlert').emit(false);
      Response.redirect('');
    }
    this._serviceconfirmarcadastro.ConfirmarCadastro(this.Hash).subscribe(
      () => {
        this.StopProgressBar();
        localStorage.setItem('tipo', 'QRCODE');
        localStorage.setItem('QRCODE-save', this.Hash);
        this.rota.navigate(['/retorno']);
        return;
      },
      (error) => {
        if (error.status == "400") {
          this.StopProgressBar();
                   EventEmitterService.get('loginAlert').emit(false);
          this.StopProgressBar();
          localStorage.setItem('tipo', 'QRCODE400');
          localStorage.setItem('QRCODE400-save', this.Hash);
          this.rota.navigate(['/retorno']);
          return;
        } else {
          this.StopProgressBar();         
          EventEmitterService.get('loginAlert').emit(false);
          this.StopProgressBar();
          localStorage.setItem('tipo', 'QRCODE422');
          localStorage.setItem('QRCODE422-save', this.Hash);
          this.rota.navigate(['/retorno']);
          return;
        }
      }
    );
  }

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }

  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//

  ngOnInit(): void {
  }


}
