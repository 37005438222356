export class TestemunhoModel {

    [x: string]: any;
    cpf: string = '';
    nome: string = '';
    email: string = '';
    telefone: string = '';
    tipo_testemunho: string = '';
    mensagem: string = '';
    receber_copia: boolean = false;   
    idusuario:number;
    manter_sigilo:string='';
    ocultar_nome:string='';

}
