<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>
<article>
    <div class="col-md-4 centraliza border bloco">
        <form [formGroup]="formlogin" (ngSubmit)="onSubmit()" class=" centraliza">
            <h4 class="text-center " style="margin-top: 10px;"><img src="../../assets/img/user.jpeg"
                    style="margin-left: 3px; width: 35px;"> Autenticação
            </h4>

            <label for="username">Usuário:</label>
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1"><i class="fas fa-user-circle"></i></span>
                </div>
                <input type="text" class="form-control"  name="login" autocomplete="on"
                    [(ngModel)]="valorlogin" formControlName="login" required placeholder="Informe seu Login"
                    [ngClass]="{ 'is-invalid': submitted && f.login.errors }" >
                <div *ngIf="submitted && f.login.errors" class="invalid-feedback">
                    <div *ngIf="f.login.errors.required">Informe o login</div>
                </div>
            </div>

            <label for="password">Senha:</label>
            <div class="input-group mb-0 " style="margin-bottom: 0%; padding-bottom: 0%;">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1"><i class="fas fa-key blocos"></i></span>
                </div>
                <input type="password" id="senha" class="form-control " name="senha" autocomplete="off"
                    [ngClass]="{ 'is-invalid': submitted && f.senha.errors }" [(ngModel)]="valorsenha"
                    formControlName="senha" requerid placeholder="Informe sua Senha">
                <div *ngIf="submitted && f.senha.errors" class="invalid-feedback">
                    <div *ngIf="f.senha.errors.required">Informe a Senha</div>
                </div>

            </div>


            <input type="checkbox" id="opcao" name="opcao" formControlName="opcao" [(ngModel)]="ck"> Salvar login
            <label class="text-info e" routerLink="/recuperar-autenticacao">Recuperar Login</label>

            <div class="form-group ">
                <button type="submit" class="btn btn-success btn-md mt-3 "><i class="fas fa-lock-open"></i>
                    Entrar</button>
                <button type="submit" routerLink="/cadastro-membros" class="btn btn-info btn-md bt mt-3"><i
                        class="fas fa-pen-square"></i> Cadastrar</button>

            </div>
        </form>
    </div>
</article>