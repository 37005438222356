import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { Parametros } from 'src/app/shared/parametros.enum';
import { SharedModule } from 'src/app/shared/shared.module';
import { AcessosModel } from '../../Listagem/acessos-listagem/acessos';

@Component({
  selector: 'app-acesso-detalhe',
  templateUrl: './acesso-detalhe.component.html',
  styleUrls: ['./acesso-detalhe.component.css']
})
export class AcessoDetalheComponent implements OnInit {

  idacesso: any;
  rota: any;
  private urlacessos = Parametros.ACESSOS;


  nome: string = "";
  cpf: any = "";
  horaacesso: any = "";
  dataacesso: string = "";
  dispositivo: string = "";
  browser: string = "";
  imprimir: any;
  acesso: Array<AcessosModel>;

  constructor(private http: HttpClient,
    private alert: AlertModalService,
    private autenticado: SharedModule,
    private r: Router) {
    this.rota = r

  }

  startedClass = false;
  completedClass = false;
  preventAbuse = false;

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//

  ngOnInit(): void {
    if (this.autenticado.token == null) {
      this.autenticado.ResetDados();
      this.alert.AlertShowDanger('Usuário não autenticado, redirecionando...')
      this.rota.navigate(['/login']);
    } else {
      if (this.autenticado.ValidarToken() != true) {
        this.autenticado.ResetDados();
        this.alert.AlertShowDanger('Token Invalido!')
        this.rota.navigate(['/login']);
      }
    }
    this.idacesso = localStorage.getItem('AcessoDetalhe');
    if (this.idacesso == null || this.idacesso == "") {
      this.rota.navigate(['/dashboard/listagem/lista-acessos']);
    } else {
      //localStorage.removeItem(' ElogioDetalhe')
      this.GetElogio()
    }

  }

  GetElogio() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get<AcessosModel>(this.urlacessos + 'GetAcessoCpfID/?parametro=' + this.autenticado.cpf + '&id=' + this.idacesso, httpOptions).subscribe(
      (success) => {

        this.dataacesso = success[0].dt_acesso.replace("T00:00:00", "");
        this.horaacesso = success[0].hora;
        this.dispositivo = success[0].dispositivo;
        this.browser = success[0].browser;
        this.cpf = this.autenticado.cpf;
      },
      (error) => {
        this.rota.navigate(['/dashboard/listagem/lista-acessos']);
      }
    )
  }


}
