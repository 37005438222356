import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { param } from 'jquery';
import { take } from 'rxjs/operators';
import { LoginService } from 'src/app/login/login.service';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { Parametros } from 'src/app/shared/parametros.enum';

@Injectable({
  providedIn: 'root'
})
export class ResumoService {

  _loginservice: LoginService;
  private url = Parametros.LOGIN;
  private urlelogio = Parametros.ELOGIO;
  private urlvisita = Parametros.VISITA;
  private urlsugestao = Parametros.SUGESTAO;
  private urltestemunho = Parametros.TESTEMUNHO;
  private urlreclamacao = Parametros.RECLAMACAO;
  private urlresumo = Parametros.RESUMO;
  private urloportunidades = Parametros.CADASTROOPORTUNIDADE;
  private urlestabelecimentos = Parametros.CADASTROESTABELECIMENTO;


  constructor(private http: HttpClient) {

  }


  TotalEstabelecimentosMeus(cpf, token) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + token.replace("\r\n", "").replace("\n", "")
      })
    };

    return this.http.get<any>(this.urlestabelecimentos + 'GETListaEstabelecimentoCpf/?parametro=' + cpf, httpOptions).pipe(take(1));

  }


  TotalEstabelecimentos(token) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + token.replace("\r\n", "").replace("\n", "")
      })
    };

    return this.http.get<any>(this.urlestabelecimentos + 'GETTotalEstabelecimentos', httpOptions).pipe(take(1));

  }


  TotalAportunidadesAbertasMinhas(cpf,token) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + token.replace("\r\n", "").replace("\n", "")
      })
    };

    return this.http.get<any>(this.urloportunidades + 'GETTotaloportunidadesMinhas/?cpf='+cpf, httpOptions).pipe(take(1));

  }
  
  TotalAportunidadesAbertas(token) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + token.replace("\r\n", "").replace("\n", "")
      })
    };

    return this.http.get<any>(this.urloportunidades + 'GETTotaloportunidades', httpOptions).pipe(take(1));

  }
  

  TotalLogin(cpf, token) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + token.replace("\r\n", "").replace("\n", "")
      })
    };

    return this.http.get(this.url + 'GETLoginQuantidade/?cpf=' + cpf, httpOptions).pipe(take(1));

  }

  TotalUsuariosAtivos(token) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + token.replace("\r\n", "").replace("\n", "")
      })
    };
    return this.http.get(this.urlresumo + 'GetTotalUsuariosAtivos', httpOptions).pipe(take(1));

  }

  TotalUsuariosInativos(token) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + token.replace("\r\n", "").replace("\n", "")
      })
    };
    return this.http.get(this.urlresumo + 'GetTotalUsuariosInativos', httpOptions).pipe(take(1));

  }

  TotalUsuariosCancelados(token) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + token.replace("\r\n", "").replace("\n", "")
      })
    };
    return this.http.get(this.urlresumo + 'GetTotalUsuarioscancelados', httpOptions).pipe(take(1));

  }

  
  UsuariosAguardandoLiberacao(token) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + token.replace("\r\n", "").replace("\n", "")
      })
    };
    return this.http.get(this.urlresumo + 'GetUsuariosAguardandoLiberacao', httpOptions).pipe(take(1));

  }

  TotalUsuariosPlataforma(token) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + token.replace("\r\n", "").replace("\n", "")
      })
    };
    return this.http.get(this.urlresumo + 'GettotalUsuariosPlataforma', httpOptions).pipe(take(1));

  }


  

  TotalEloagios(cpf, token) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + token.replace("\r\n", "").replace("\n", "")
      })
    };

    return this.http.get(this.urlelogio + 'GETElogioQuantidade/?cpf=' + cpf, httpOptions).pipe(take(1));

  }

  TotalVisitas(cpf, token) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + token.replace("\r\n", "").replace("\n", "")
      })
    };

    return this.http.get(this.urlvisita + 'GETVisitaQuantidade/?cpf=' + cpf, httpOptions).pipe(take(1));

  }

  TotalReclamacao(idusuario, token) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + token.replace("\r\n", "").replace("\n", "")
      })
    };

    return this.http.get(this.urlreclamacao + 'GETQuantidadereclamacao/?idusuario=' + idusuario, httpOptions).pipe(take(1));

  }

  Totalsugestao(idusuario, token) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + token.replace("\r\n", "").replace("\n", "")
      })
    };

    return this.http.get(this.urlsugestao + 'GETQuantidadesugestao/?idusuario=' + idusuario, httpOptions).pipe(take(1));

  }

  Totaltestemunho(idusuario, token) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + token.replace("\r\n", "").replace("\n", "")
      })
    };

    return this.http.get(this.urltestemunho + 'GETListaTestemunhoTotalCpf/?parametro=' + idusuario, httpOptions).pipe(take(1));

  }
}
