import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { retry } from 'rxjs/operators';
import { Parametros } from 'src/app/shared/parametros.enum';
import { SharedModule } from 'src/app/shared/shared.module';

@Injectable({
  providedIn: 'root'
})
export class ListagemCadastroEncontroHomensService {
  private urlmembros = Parametros.ENCONTROHOMENS;

  constructor(private http: HttpClient,
    private autenticado: SharedModule) { }

  startedClass = false;
  completedClass = false;
  preventAbuse = false;

  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }

  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }

  Cancelarsuario(idinscricao) {
    this.StartProgressBar();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token
      })
    };
    return this.http
      .post(this.urlmembros + 'PostCancelarCadastro/?parametro=' + idinscricao, "", httpOptions)
      .pipe(retry(2));
  }

  InativarUsuario(idinscricao) {
    this.StartProgressBar();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token
      })
    };
    return this.http
      .post(this.urlmembros + 'PostInativarCadastro/?parametro=' + idinscricao, "", httpOptions)
      .pipe(retry(2));
  }

  AtivarUsuario(idinscricao) {
    this.StartProgressBar();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token
      })
    };
    return this.http
      .post(this.urlmembros + 'PostativarCadastro/?parametro=' + idinscricao, "", httpOptions)
      .pipe(retry(2));
  }

  ConfirmarPagamento(idinscricao) {
    this.StartProgressBar();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token
      })
    };
    return this.http
      .post(this.urlmembros + 'PostConfirmarPagamento/?parametro=' + idinscricao, "", httpOptions)
      .pipe(retry(2));
  }

  Desconfirmar(idinscricao) {
    this.StartProgressBar();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token
      })
    };
    return this.http
      .post(this.urlmembros + 'PostRetirarPagamento/?parametro=' + idinscricao, "", httpOptions)
      .pipe(retry(2));
  }

}



