<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>
<!-- Modal -->
<div class="col-md-9 centraliza  flex-auto ">
    <form [formGroup]="formproblema" class=" centraliza">
        <h3 class="text-left "><img src="../../assets/img/email.jpeg" style="width: 50px;"> Contato </h3>
        <label>&nbsp; Seja bem vindo a nossa área de contato!</label><br>
        <label>Gostariamos de informar que todos os dados aqui informados são de uso exclusivo para comunicação jamais
            sendo compartilhado com terceiros.</label><br>
        <label>
            Lembramos que os campos com (<strong class="cor">*</strong>) são campos de
            preenchimento obrigatório!
        </label>
        <hr class="espacamentotopo bold">
        <div class="row" style="margin-top: 10px;">
            <div class="col-md-3">
                <div class="form-group">
                    <label for="username">CPF:</label><strong class="cor">*</strong>
                    <input type="text" class="form-control" mask="CPF_CNPJ" (blur)="consultacpf()" aria-label="large"
                        name="documento" formControlName="documento" [(ngModel)]="contato.documento" required
                        placeholder="Informe o Cpf" [ngClass]="{ 'is-invalid': submitted && f.documento.errors }">
                    <div *ngIf="submitted && f.documento.errors">
                        <div *ngIf="f.documento.errors.required" class="cor">Preenchimento obrigatório</div>
                    </div>
                </div>
            </div>
            <div class="col-md-7">
                <div class="form-group">
                    <label for="username">Nome:</label><strong class="cor">*</strong>
                    <input type="text" class="form-control" name="nome" formControlName="nome"
                        [(ngModel)]="contato.nome" required placeholder="Informe seu nome"
                        [ngClass]="{ 'is-invalid': submitted && f.nome.errors }">
                    <div *ngIf="submitted && f.nome.errors">
                        <div *ngIf="f.nome.errors.required" class="cor">Preenchimento obrigatório</div>
                    </div>
                </div>
            </div>
            <div class="col-md-2">
                <div class="form-group" style="padding-top: 30px; margin-left: 0%;">
                    <input type="checkbox" id="copia" data-md-icheck name="copia" formControlName="copia"
                        [(ngModel)]="contato.copia" title="Receber Cópia"> Receber Cópia
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-5">
                <div class="form-group">
                    <label for="username">Email:</label>
                    <div class="input-group mb-1">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1"><i class="fas fa-at"></i></span>
                        </div>
                        <input type="text" class="form-control" name="email" formControlName="email"
                            [(ngModel)]="contato.email" placeholder="Informe seu Email"
                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }">

                    </div>
                    <div *ngIf="submitted && f.email.errors">
                        <div *ngIf="f.email.errors.required" class="cor">Preenchimento obrigatório</div>
                    </div>
                </div>

            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label for="username">Telefone/Celular:</label><strong class="cor">*</strong>
                    <div class="input-group mb-1">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1"><i class="fas fa-phone"></i></span>
                        </div>
                        <input type="text" class="form-control" id="telefone" name="telefone"
                            [(ngModel)]="contato.telefone" placeholder="Informe o Telefone"
                            title="Formato (DD) 0000-0000 ou (DD) 00000-0000" formControlName="telefone"
                            autocomplete="off" [ngClass]="{ 'is-invalid': submitted && f.telefone.errors }"
                            mask="(00) 0000-0000 || (00) 00000-0000">
                    </div>
                    <div *ngIf="submitted && f.telefone.errors">
                        <div *ngIf="f.telefone.errors.required" class="cor">Preenchimento obrigatório</div>
                    </div>

                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label for="text">Selecione o Tipo:</label><strong class="cor">*</strong>
                    <select class="custom-select " id="tipo_contato" formControlName="tipo_contato"
                        [(ngModel)]="contato.tipo_contato"
                        [ngClass]="{ 'is-invalid': submitted && f.tipo_contato.errors }">
                        <option value="Informações">Informações</option>
                        <option value="Aconselhamento">Aconselhamento</option>
                        <option value="Erros|Problemas">Erros|Problemas</option>
                        <option value="Autenticação">Autenticação</option>
                        <option value="Duvidas">Dúvidas</option>
                        <option value="Elogios">Elogios</option>
                        <option value="Reclamação">Reclamação</option>
                        <option value="Sugestões">Sugestões</option>
                        <option value="Visita">Visita</option>
                        <option value="Compra Cadeiras">Compra Cadeiras</option>
                        <option value="Politica de Privacidade">Politica de Privacidade</option>
                        <option value="Reativar Cadastro">Reativar Cadastro</option>
                        <option value="Cancelar Cadastro">Cancelar Cadastro</option>
                    </select>
                    <div *ngIf="submitted && f.tipo_contato.errors">
                        <div *ngIf="f.tipo_contato.errors.required" class="cor">Preenchimento obrigatório</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group">
            <label>Mensagem:</label><strong class="cor">*</strong>
            <textarea type="mensagem" rows="5" class="form-control" id="mensagem" aria-describedby="emailHelp"
                autocomplete="off" maxlength=500 minlength=15 formControlName="mensagem" [(ngModel)]="contato.mensagem"
                [ngClass]="{ 'is-invalid': submitted && f.mensagem.errors }"></textarea>
            <span>{{contato.mensagem.length}}/500</span>
            <div *ngIf="submitted && f.mensagem.errors">
                <div *ngIf="f.mensagem.errors.required" class="cor">Preenchimento obrigatório</div>
            </div>
        </div>

        <div>
            <button pButton pRipple type="button" icon="pi pi-send" class="p-button-rounded" title="::Enviar::"
                (click)="onSubmit()">
            </button>
            <button pButton pRipple type="button" icon="pi pi-home" class="p-button-rounded p-button-help"
                title="::Home::" routerLink="" style="margin-left: 10px;">
            </button>
            <a href="https://api.whatsapp.com/send?l=pt_BR&phone=55032988189440&text=Problemas Formulario Contato"
            style="text-align: right; float: right;" title="Fale Conosco" target="_blank">Informar Problema!</a>
        </div>
    </form>



</div>
