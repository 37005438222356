<div class="col-md-12" style="margin-top: 7px;">

    <div>
        <h4 style="text-align: center;">
            <i class="fas fa-id-card"></i>&nbsp;<b>Reenviar Dados Conferência de Jovens</b>
        </h4>
        <p>
            &nbsp;&nbsp;&nbsp;&nbsp;Informe o documento utilizado no cadastro e um e-mail valido!, Lembramos que este
            e-mail será atualizado no cadastro!, o mesmo poderá ser usado em futuras comunicações.
        </p>
    </div>
</div>
<hr class="espacamentotopo bold" />
<form [formGroup]="formculto" class="m" disabled="" style="margin-left: 2%;">
    <div class="row">
        <div class="col-md-4">
            <div class="form-group">
                <label>CPF / RG:</label><strong class="cor">*</strong>
                <input type="tel" class="form-control" id="documento" placeholder="informe o documento"
                    mask="000.000.000-00||00.000.000" name="documento" maxlength="14" minlength="8" autocomplete="off"
                    formControlName="documento" [(ngModel)]="cadastroculto.documento"
                    [ngClass]="{ 'is-invalid': submitted && f.documento.errors }" style="font-size: larger;" />
                <div *ngIf="submitted && f.documento.errors" class="invalid-feedback">
                    <div *ngIf="f.documento.errors.required">Preenchimento obrigatório</div>
                </div>
                <br>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-4">
            <div class="form-group">
                <label>E-mail:</label><strong class="cor">*</strong>
                <input type="email" class="form-control" id="email" placeholder="informe o email" name="email"
                    minlength="3" autocomplete="off" formControlName="email" [(ngModel)]="cadastroculto.email"
                    [ngClass]="{ 'is-invalid': submitted && f.email.errors }" style="font-size: larger;" />
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">Preenchimento obrigatório</div>
                </div>
                <br>
            </div>
        </div>
    </div>

    <button pButton pRipple type="button" icon="pi pi-save" class="p-button-rounded"
        style=" font-size: larger; margin-right: 5px; align-self: center; text-align: center;" title=":: SALVAR ::"
        (click)="onSubmit()"></button>
    <button pButton pRipple type="button" icon="pi pi-sign-in" class="p-button-rounded p-button-danger"
        title="::Fechar::" routerLink="/conferencia">
    </button>


</form>