<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>
<div>
    <div class="row">
        <div class="col-md-2" style="margin-right: 2px; padding: 0%;">
            <img src="../../../assets/img/configuracoes.png" height="100px" width="200px" style="padding: 0%;" />
        </div>
        <div class="col-md">
            <h4 class="espacamentotopo " style="margin-left: 5px;">
                Ajuste Perfil
            </h4>
            <label style="margin-left: 5px;">
                Aqui você define Qual a permissão do usuario!.
                <br>
                Tome cuidado ao selecionar o perfil para que não seja liberado perfil superior ao necessario do
                usuario.<br>
            </label>
        </div>
    </div>
    <hr class="espacamentotopo bold" />
    <form [formGroup]="formusuario" disabled="">
        <div class="row">
            <!---INCIO PRIMEIRA LINHA-->
            <div class="col-md-2">
                <div class="form-group">
                    <label>CPF:</label><strong class="cor">*</strong>
                    <input type="text" disabled="true" class="form-control" id="cpf" placeholder="informe o CPF"
                        mask="CPF_CNPJ" name="cpf" maxlength="14" autocomplete="off" formControlName="cpf"
                        [(ngModel)]="modelusuario.cpf" [ngClass]="{ 'is-invalid': submitted && u.cpf.errors }" />
                    <div *ngIf="submitted && u.cpf.errors" class="invalid-feedback">
                        <div *ngIf="u.cpf.errors.required">Preenchimento obrigatório</div>
                    </div>
                </div>
            </div>

            <div class="col-md-10">
                <div class="form-group">
                    <label>Nome Completo:</label><strong class="cor">*</strong>
                    <input type="text" disabled="true" class="form-control" id="nome" name="nome"
                        [(ngModel)]="modelusuario.nome" placeholder="Informe Nome Completo" formControlName="nome"
                        maxlength="500" minlength="3" autocomplete="off"
                        [ngClass]="{ 'is-invalid': submitted && u.nome.errors }" />
                    <div *ngIf="submitted && u.nome.errors" class="invalid-feedback">
                        <div *ngIf="u.nome.errors.required">Preenchimento obrigatório</div>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <h4>Participação de Cursos</h4>

    <form [formGroup]="formcursos" disabled="">
        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="casais" data-md-icheck formControlName="casais" [(ngModel)]="casais" />
                    <b>&nbsp;&nbsp;Curso de Casais</b><br><span>Liberar funções para participantes do curso de
                        Casais.</span>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="teologia" data-md-icheck formControlName="teologia"
                        [(ngModel)]="teologia" />
                    <b>&nbsp;&nbsp;Teologia</b><br><span>Alunos do curso de Teologia.</span>
                </div>
            </div>
        </div>
    </form>
    <hr>

    <div>
        <button pButton pRipple type="button" icon="pi pi-save" class="p-button-rounded" title="::Salvar Alterações::"
            (click)="onSubmit()">
        </button>
        <button pButton pRipple type="button" icon="pi pi-sign-in" class="p-button-rounded p-button-danger"
            title="::Fechar::" routerLink="/dashboard/perfil/perfil-usuario">
        </button>
    </div>



    <br><br><br><br>


</div>
<button class="voltar-ao-topo" id="back"><a class="ancora" style="color: white;"><i
    class="pi pi-arrow-circle-up"></i></a></button>