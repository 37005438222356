<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>
<div class="wrapper">
    <!-- Sidebar  -->
    <nav id="sidebar">
        <div class="sidebar-header" style="margin-top: 0%; margin-bottom: 0%;">
            <div class="row">
                <img src="../../../assets/img/novos/1.png" width="45px" class="ml-0" style="color: white;">
                <div style="font-size: large; padding-left: 7px; ">
                    Morada de Deus
                    <div class="" style="font-size:x-small;">
                        Igreja Evangélica Pentecostal
                    </div>

                </div>
            </div>
        </div>
        <hr style="background-color: white; margin-top: 1%;">

        <ul class="list-unstyled components">
            <!---RESUMO-->
            <div
                *ngIf="_perfil.includes('Administrador') ||_perfil.includes('Secretaria') ||_perfil.includes('Aluno')|| _perfil.includes('Consolidacao') ||_perfil.includes('PPresidente') ||_perfil.includes('PAuxiliar') ||_perfil.includes('Casais') || _perfil.includes('Membro')|| _perfil.includes('Conselheiro')|| _perfil.includes('professor')|| _perfil.includes('Checkout') ">
                <li class="mt-0 mb-0 text-center">
                    <a [routerLink]="['/dashboard/resumo']"> <i class="fas fa-tachometer-alt"></i> Resumo</a>
                </li>
                <hr style="background-color: white;">
            </div>
            <!---FIM RESUMO-->
            <!--================================================================================-->

            <!---CADASTROS VISITA ELOGIOS-->
            <div
                *ngIf="_perfil.includes('Administrador') ||_perfil.includes('Secretaria') ||_perfil.includes('Aluno') || _perfil.includes('Consolidacao')||_perfil.includes('PPresidente') ||_perfil.includes('PAuxiliar') ||_perfil.includes('Casais') || _perfil.includes('Membro')|| _perfil.includes('Conselheiro') || _perfil.includes('professor')|| _perfil.includes('Checkout') ">
                <li>
                    <a href="#homeSubmenu" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle"
                        style="margin-left: 20px;"><i class="fas fa-tools"></i> Registrar</a>
                    <ul class="collapse list-unstyled" id="homeSubmenu">
                        <li>
                            <a [routerLink]="['/dashboard/elogio']"><i class="fas fa-file-signature"></i> Elogios</a>
                        </li>
                        <li>
                            <a [routerLink]="['/dashboard/visita']"><i class="fas fa-clock"></i> Pedido de Visita</a>
                        </li>
                        <li>
                            <a [routerLink]="['/dashboard/reclamacao']"><i class="far fa-comment-dots"></i>
                                Reclamação</a>
                        </li>
                        <li>
                            <a [routerLink]="['/dashboard/sugestoes']"><i class="fas fa-hashtag"></i> Sugestões</a>
                        </li>
                        <li>
                            <a [routerLink]="['/dashboard/testemunho']"><i class="fas fa-dove"></i> Testemunho</a>
                        </li>
                        <li>
                            <a [routerLink]="['/dashboard/cadastro-estabelecimento']"><i class="pi pi-pencil"></i>
                                Cad.Empresa/Serviço
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['/dashboard/listagem-estabelecimento']"><i
                                    class="fas fa-clipboard-list"></i> Lista
                                Empresa/Serviço</a>
                        </li>
                        <li>
                            <a [routerLink]="['/dashboard/cadastrar-oportunidade']"><i class="pi pi-pencil"></i>
                                Cadastrar Oportunidade
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['/dashboard/listagem-oportunidade']"><i class="fas fa-clipboard-list"></i>
                                Lista
                                Oportunidades</a>
                        </li>
                    </ul>
                </li>
                <hr style="background-color: white;">
            </div>
            <!---FIM CADASTROS VISITAS ELOGIOS-->
            <!--================================================================================-->

            <!---PERFIL-->
            <div>
                <li>
                    <a href="#PerfilSubmenu" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle"
                        style="margin-left: 20px;"><i class="fas fa-users"></i> Perfil</a>
                    <ul class="collapse list-unstyled" id="PerfilSubmenu">
                        <li>
                            <a [routerLink]="['/dashboard/perfil']"><i class="fas fa-address-card"></i> Dados
                                Cadastrais</a>
                        </li>
                        <li>
                            <a [routerLink]="['/dashboard/trocar-senha']"><i class="fas fa-key"></i> Trocar Senha</a>
                        </li>
                        <li>
                            <a [routerLink]="['/dashboard/comunicacao']"><i class="far fa-comment-dots"></i>
                                Comunicacão</a>
                        </li>

                    </ul>
                </li>
            </div>
            <hr style="background-color: white;">
            <!---FIM PERFIL-->





            <!--BATISMO-->
            <div
                *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao')|| _perfil.includes('Secretaria') ">
                <li>
                    <a href="#Gerenciarbatismo" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle"
                        style="margin-left: 20px;"><i class="fas fa-tools"></i>
                        Gerenciar Batismo</a>
                    <ul class="collapse list-unstyled" id="Gerenciarbatismo">
                        <li>
                            <a [routerLink]="['/dashboard/listagem/listagem-batismo']"><i
                                    class="fas fa-clipboard-list"></i> Lista de Participantes </a>
                        </li>
                        <!--Chama o modal CULTO-->
                        <li>
                            <a data-toggle="modal" data-target="#ModalListagembatismo"><i class="fas fa-print"></i>
                                Impressão Listagem</a>
                        </li>

                    </ul>
                </li>
                <hr style="background-color: white;">
            </div>
            <!--FIM BATISMO-->



            <!--================================================================================-->

            <!---LISTAGEM CULTO REGISTRADO ANALISAR OS PERFIS-->
            <div
                *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao')|| _perfil.includes('Checkout')|| _perfil.includes('Secretaria') ">
                <li>
                    <a href="#Gerenciarcultosgeral" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle"
                        style="margin-left: 20px;"><i class="fas fa-tools"></i>
                        Gerenciar Cultos</a>
                    <ul class="collapse list-unstyled" id="Gerenciarcultosgeral">
                        <div
                            *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao')|| _perfil.includes('Checkout')|| _perfil.includes('Secretaria') ">
                            <li>
                                <a href="#ParametrosSubmenuss" data-toggle="collapse" aria-expanded="false"
                                    class="dropdown-toggle" style="margin-left: 20px;"><i class="fas fa-tools"></i>
                                    Culto Adultos</a>
                                <ul class="collapse list-unstyled" id="ParametrosSubmenuss">
                                    <li
                                        *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao')|| _perfil.includes('Secretaria') ">

                                        <a [routerLink]="['/dashboard/listagem/listagem-culto-registrado']"><i
                                                class="fas fa-clipboard-list"></i> Lista de Participantes </a>
                                    </li>

                                    <!--Chama o modal CULTO-->
                                    <li
                                        *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao')|| _perfil.includes('Secretaria')">
                                        <a data-toggle="modal" data-target="#ModalListagemculto"><i
                                                class="fas fa-print"></i>
                                            Impressão Listagem</a>
                                    </li>

                                    <li
                                        *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao')|| _perfil.includes('Secretaria')">

                                        <a [routerLink]="['/dashboard/resumo/resumo-culto-registrado']"><i
                                                class="fas fa-clipboard-list"></i> Estatística Culto </a>
                                    </li>
                                    <li
                                        *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao')|| _perfil.includes('Secretaria')">
                                        <a [routerLink]="['/dashboard/comunicado/enviar-comunicado-culto-registrado']"><i
                                                class="fas fa-bullhorn"></i> Enviar
                                            Comunicado</a>
                                    </li>
                                    <li
                                        *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao')|| _perfil.includes('Checkout')|| _perfil.includes('Secretaria') ">
                                        <a [routerLink]="['/dashboard/checkout/documento']"><i
                                                class="fas fa-barcode"></i> Validar
                                            Entrada</a>
                                    </li>

                                </ul>
                            </li>

                        </div>
                        <!---FIM CONFIGURAÇÕES EMAILS TERMOS ANALISAR OS PERFIS-->
                        <!--================================================================================-->

                        <!---LISTAGEM CULTO KIDS REGISTRADO ANALISAR OS PERFIS-->
                        <div
                            *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao')|| _perfil.includes('Checkout')|| _perfil.includes('Secretaria') ">
                            <li>
                                <a href="#ParametrosSubmenusks" data-toggle="collapse" aria-expanded="false"
                                    class="dropdown-toggle" style="margin-left: 20px;"><i class="fas fa-tools"></i>
                                    Culto Kids</a>
                                <ul class="collapse list-unstyled" id="ParametrosSubmenusks">
                                    <li
                                        *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao')||  _perfil.includes('Secretaria') ">

                                        <a [routerLink]="['/dashboard/listagem/listagem-culto-kids-registrado']"><i
                                                class="fas fa-clipboard-list"></i> Lista de Crianças </a>
                                    </li>

                                    <li
                                        *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao')|| _perfil.includes('Secretaria')">
                                        <a data-toggle="modal" data-target="#ModalListagemcultokids"><i
                                                class="fas fa-print"></i>
                                            Impressão Listagem</a>
                                    </li>
                                    <li
                                        *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao')|| _perfil.includes('Secretaria')">

                                        <a [routerLink]="['/dashboard/resumo/resumo-culto-kids-registrado']"><i
                                                class="fas fa-clipboard-list"></i> Estatística Culto </a>
                                    </li>
                                    <li
                                        *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao')|| _perfil.includes('Secretaria')">
                                        <a
                                            [routerLink]="['/dashboard/comunicado/enviar-comunicado-culto-kids-registrado']"><i
                                                class="fas fa-bullhorn"></i> Enviar
                                            Comunicado</a>
                                    </li>
                                    <li
                                        *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao')|| _perfil.includes('Checkout')|| _perfil.includes('Secretaria') ">
                                        <a [routerLink]="['/dashboard/checkout/documento-kids']"><i
                                                class="fas fa-barcode"></i>
                                            Validar
                                            Entrada</a>
                                    </li>
                                </ul>
                            </li>
                        </div>
                    </ul>
                </li>
                <hr style="background-color: white;">
            </div>
            <!---FIM KIDS-->

            <!--ENCONTROS-->
            <div
                *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao')|| _perfil.includes('Evento_Mulheres')|| _perfil.includes('Evento_Homens')">
                <li>
                    <a href="#GerenciarEncontros" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle"
                        style="margin-left: 20px;"><i class="fas fa-tools"></i>
                        Gerenciar Encontros</a>
                    <ul class="collapse list-unstyled" id="GerenciarEncontros">

                        <li
                            *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao')|| _perfil.includes('Evento_Mulheres')">
                            <a href="#GerenciarEncontroMulheres" data-toggle="collapse" aria-expanded="false"
                                class="dropdown-toggle" style="margin-left: 20px;"><i class="fas fa-tools"></i>
                                Encontro Mulheres</a>
                            <ul class="collapse list-unstyled" id="GerenciarEncontroMulheres">
                                <li>

                                    <a [routerLink]="['/dashboard/gerenciamento/encontro-mulheres/cadastros']"><i
                                            class="fas fa-clipboard-list"></i> Listagem Cadastros </a>
                                </li>


                                <li
                                    *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao') || _perfil.includes('Secretaria')|| _perfil.includes('Evento_Mulheres')">
                                    <a data-toggle="modal" data-target="#ModalListagemEncontroMulheres"><i
                                            class="fas fa-print"></i>
                                        Impressão Listagem</a>
                                </li>

                            </ul>

                        </li>

                        <li
                            *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao')|| _perfil.includes('Evento_Homens')">
                            <a href="#GerenciarEncontroHomens" data-toggle="collapse" aria-expanded="false"
                                class="dropdown-toggle" style="margin-left: 20px;"><i class="fas fa-tools"></i>
                                Encontro Homens</a>
                            <ul class="collapse list-unstyled" id="GerenciarEncontroHomens">
                                <li>

                                    <a [routerLink]="['/dashboard/gerenciamento/encontro-homens/cadastros']"><i
                                            class="fas fa-clipboard-list"></i> Listagem Cadastros </a>
                                </li>


                                <li
                                    *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao') || _perfil.includes('Secretaria')|| _perfil.includes('Evento_Homens')">
                                    <a data-toggle="modal" data-target="#ModalListagemEncontroHomens"><i
                                            class="fas fa-print"></i>
                                        Impressão Listagem</a>
                                </li>
                            </ul>

                        </li>

                      <li
                        *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao')|| _perfil.includes('Evento_Casais')">
                        <a href="#GerenciarEncontroCasais" data-toggle="collapse" aria-expanded="false"
                           class="dropdown-toggle" style="margin-left: 20px;"><i class="fas fa-tools"></i>
                          Encontro Casais</a>
                        <ul class="collapse list-unstyled" id="GerenciarEncontroCasais">
                          <li>

                            <a [routerLink]="['/dashboard/gerenciamento/encontro-casais/cadastros']"><i
                              class="fas fa-clipboard-list"></i> Listagem Cadastros </a>
                          </li>


                          <li
                            *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao') || _perfil.includes('Secretaria')|| _perfil.includes('Evento_Casais')">
                            <a data-toggle="modal" data-target="#ModalListagemEncontroCasais"><i
                              class="fas fa-print"></i>
                              Impressão Listagem</a>
                          </li>
                        </ul>

                      </li>

                    </ul>

                </li>
                <hr style="background-color: white;">
            </div>
            <!--FIM ENCONTROS-->

            <!--CONGRESSOS-->
            <div
                *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao')|| _perfil.includes('Checkout')|| _perfil.includes('Evento_Mulheres')|| _perfil.includes('Evento_Homens')|| _perfil.includes('Evento_Jovens')">
                <li>
                    <a href="#GerenciarCongressos" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle"
                        style="margin-left: 20px;"><i class="fas fa-tools"></i>
                        Gerenciar Congressos</a>
                    <ul class="collapse list-unstyled" id="GerenciarCongressos">
                        <div>
                            <li
                                *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao')|| _perfil.includes('Evento_Mulheres')|| _perfil.includes('Checkout')">
                                <a href="#GerenciarCongressoMulheres" data-toggle="collapse" aria-expanded="false"
                                    class="dropdown-toggle" style="margin-left: 20px;"><i class="fas fa-tools"></i>
                                    Congresso Mulheres</a>
                                <ul class="collapse list-unstyled" id="GerenciarCongressoMulheres">
                                    <li
                                        *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao') || _perfil.includes('Secretaria')|| _perfil.includes('Evento_Mulheres')">

                                        <a [routerLink]="['/dashboard/gerenciamento/congresso-mulheres/cadastros']"><i
                                                class="fas fa-clipboard-list"></i> Listagem Cadastros </a>
                                    </li>
                                    <li
                                        *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao') || _perfil.includes('Secretaria')|| _perfil.includes('Evento_Mulheres')">
                                        <a data-toggle="modal" data-target="#ModalListagemCongressoMulheres"><i
                                                class="fas fa-print"></i>
                                            Impressão Listagem</a>
                                    </li>

                                    <li
                                        *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao') || _perfil.includes('Secretaria')|| _perfil.includes('Evento_Mulheres')">
                                        <a [routerLink]="['/dashboard/resumo/congresso-mulheres']"><i
                                                class="fas fa-clipboard-list"></i> Estatística Congresso </a>
                                    </li>
                                    <li
                                        *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao')|| _perfil.includes('Secretaria')">
                                        <a
                                            [routerLink]="['/dashboard/comunicado/enviar-comunicado-congresso-mulheres']"><i
                                                class="fas fa-bullhorn"></i> Enviar
                                            Comunicado</a>
                                    </li>

                                    <li
                                        *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao') || _perfil.includes('Secretaria')|| _perfil.includes('Evento_Mulheres') ||_perfil.includes('Checkout')">
                                        <a [routerLink]="['/dashboard/checkout/congresso-mulheres']"><i
                                                class="fas fa-barcode"></i>
                                            Validar
                                            Entrada</a>
                                    </li>
                                </ul>
                            </li>
                        </div>
                        <div>
                            <li
                                *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao')|| _perfil.includes('Evento_Homens')|| _perfil.includes('Checkout')">
                                <a href="#GerenciarCongressoHomens" data-toggle="collapse" aria-expanded="false"
                                    class="dropdown-toggle" style="margin-left: 20px;"><i class="fas fa-tools"></i>
                                    Congresso Homens</a>
                                <ul class="collapse list-unstyled" id="GerenciarCongressoHomens">
                                    <li
                                        *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao') || _perfil.includes('Secretaria')|| _perfil.includes('Evento_Homens')">

                                        <a [routerLink]="['/dashboard/gerenciamento/congresso-homens/cadastros']"><i
                                                class="fas fa-clipboard-list"></i> Listagem Cadastros </a>
                                    </li>
                                    <li
                                        *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao') || _perfil.includes('Secretaria')|| _perfil.includes('Evento_Homens')">
                                        <a data-toggle="modal" data-target="#ModalListagemCongressoHomens"><i
                                                class="fas fa-print"></i>
                                            Impressão Listagem</a>
                                    </li>

                                    <li
                                        *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao') || _perfil.includes('Secretaria')|| _perfil.includes('Evento_Homens')">
                                        <a [routerLink]="['/dashboard/resumo/congresso-homens']"><i
                                                class="fas fa-clipboard-list"></i> Estatística Congresso </a>
                                    </li>
                                    <li
                                        *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao')|| _perfil.includes('Secretaria')">
                                        <a [routerLink]="['/dashboard/comunicado/enviar-comunicado-congresso-homens']"><i
                                                class="fas fa-bullhorn"></i> Enviar
                                            Comunicado</a>
                                    </li>

                                    <li
                                        *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao') || _perfil.includes('Secretaria')|| _perfil.includes('Evento_Homens') ||_perfil.includes('Checkout')">
                                        <a [routerLink]="['/dashboard/checkout/congresso-homens']"><i
                                                class="fas fa-barcode"></i>
                                            Validar
                                            Entrada</a>
                                    </li>
                                </ul>
                            </li>
                        </div>

                        <div>
                            <li
                                *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao')|| _perfil.includes('Evento_Jovens')|| _perfil.includes('Checkout')">
                                <a href="#GerenciarCongressoJovens" data-toggle="collapse" aria-expanded="false"
                                    class="dropdown-toggle" style="margin-left: 20px;"><i class="fas fa-tools"></i>
                                    Congresso Jovens</a>
                                <ul class="collapse list-unstyled" id="GerenciarCongressoJovens">
                                    <li
                                        *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao') || _perfil.includes('Secretaria')|| _perfil.includes('Evento_Jovens')">

                                        <a [routerLink]="['/dashboard/gerenciamento/congresso-jovens/cadastros']"><i
                                                class="fas fa-clipboard-list"></i> Listagem Cadastros </a>
                                    </li>
                                    <li
                                        *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao') || _perfil.includes('Secretaria')|| _perfil.includes('Evento_Jovens')">
                                        <a data-toggle="modal" data-target="#ModalListagemCongressoJovens"><i
                                                class="fas fa-print"></i>
                                            Impressão Listagem</a>
                                    </li>

                                    <li
                                        *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao') || _perfil.includes('Secretaria')|| _perfil.includes('Evento_Jovens')">
                                        <a [routerLink]="['/dashboard/resumo/congresso-jovens']"><i
                                                class="fas fa-clipboard-list"></i> Estatística Congresso </a>
                                    </li>
                                    <li
                                        *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao')|| _perfil.includes('Secretaria')">
                                        <a [routerLink]="['/dashboard/comunicado/enviar-comunicado-congresso-jovens']"><i
                                                class="fas fa-bullhorn"></i> Enviar
                                            Comunicado</a>
                                    </li>

                                    <li
                                        *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao') || _perfil.includes('Secretaria')|| _perfil.includes('Evento_Jovens') ||_perfil.includes('Checkout')">
                                        <a [routerLink]="['/dashboard/checkout/congresso-jovens']"><i
                                                class="fas fa-barcode"></i>
                                            Validar
                                            Entrada</a>
                                    </li>
                                </ul>
                            </li>
                        </div>

                    </ul>

                </li>
                <hr style="background-color: white;">
            </div>
            <!--FIM CONGRESSOS-->


            <!--EMPRESAS E SERVICOS-->
            <div
                *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao')">
                <li>
                    <a href="#Gerenciarempresasoportunidades" data-toggle="collapse" aria-expanded="false"
                        class="dropdown-toggle" style="margin-left: 20px;"><i class="fas fa-tools"></i>
                        Gerenciar Empresas/Oport.</a>
                    <ul class="collapse list-unstyled" id="Gerenciarempresasoportunidades">

                        <div
                            *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao')">
                            <li>
                                <a href="#GerenciarEstabelecimento" data-toggle="collapse" aria-expanded="false"
                                    class="dropdown-toggle" style="margin-left: 20px;"><i class="fas fa-tools"></i>
                                    Gerenciar Emp./Ser.</a>
                                <ul class="collapse list-unstyled" id="GerenciarEstabelecimento">
                                    <li>
                                        <a [routerLink]="['/dashboard/listagem/listagem-empresa-servico']"><i
                                                class="fas fa-clipboard-list"></i> Lista de Emp./Ser. </a>
                                    </li>
                                </ul>
                            </li>

                        </div>
                        <div
                            *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao')">
                            <li>
                                <a href="#GerenciarOportunidades" data-toggle="collapse" aria-expanded="false"
                                    class="dropdown-toggle" style="margin-left: 20px;"><i class="fas fa-tools"></i>
                                    Gerenciar Oport.</a>
                                <ul class="collapse list-unstyled" id="GerenciarOportunidades">

                                    <li>

                                        <a [routerLink]="['/dashboard/listagem/listagem-oportunidades']"><i
                                                class="fas fa-clipboard-list"></i> Lista Oportunidades </a>
                                    </li>
                                </ul>
                            </li>
                        </div>
                    </ul>
                </li>
                <hr style="background-color: white;">
            </div>

            <!---CONFIGURAÇÕES MEMBROS ANALISAR OS PERFIS-->
            <div
                *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao') ">
                <li>
                    <a href="#ParametrosSubmenu" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle"
                        style="margin-left: 20px;"><i class="fas fa-tools"></i> Gerenciar
                        Membros</a>
                    <ul class="collapse list-unstyled" id="ParametrosSubmenu">
                        <li>
                            <a [routerLink]="['/dashboard/gerenciamento/listagem-membros']"><i
                                    class="fas fa-clipboard-list"></i> Listagem
                                Completa</a>
                        </li>
                        <li>
                            <a [routerLink]="['/dashboard/perfil/perfil-usuario']"><i
                                    class="fas fa-universal-access"></i> Ajuste de Permissões</a>
                        </li>
                        <li>
                            <a [routerLink]="['/dashboard/gerenciamento/membros-ativos']"><i
                                    class="fas fa-user-plus"></i> Membros Ativos</a>
                        </li>
                        <li>
                            <a [routerLink]="['/dashboard/gerenciamento/membros-inativos']"><i
                                    class="fas fa-user-minus"></i> Membros Inativos</a>
                        </li>
                        <li>
                            <a [routerLink]="['/dashboard/gerenciamento/membros-aguardando-liberacao']"><i
                                    class="fas fa-user-clock"></i> Aguardando Liberação
                            </a>
                        </li>
                        <li>
                            <a [routerLink]="['/dashboard/gerenciamento/membros-cancelados']"><i
                                    class="fas fa-user-times"></i> Membros
                                Cancelados</a>
                        </li>

                        <li>
                            <a data-toggle="modal" data-target="#ModalListagemMembros"><i class="fas fa-print"></i>
                                Impressão Listagem</a>
                        </li>
                        <li>
                            <a [routerLink]="['/dashboard/gerenciamento/comunicacao/comunicar-usuarios']"><i
                                    class="fas fa-bullhorn"></i> Enviar
                                Comunicado
                            </a>
                        </li>
                    </ul>
                </li>
                <hr style="background-color: white;">
            </div>
            <!---FIM CONFIGURAÇÕES MEMBROS ANALISAR OS PERFIS-->

            <!---GERENCIAR SEMINARIOS ANALISAR OS PERFIS-->
            <div
                *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao') || _perfil.includes('Secretaria')|| _perfil.includes('Seminario_Parcial') || _perfil.includes('Seminario_Total') || _perfil.includes('Evento_Infantil') ">
                <li>
                    <a href="#Gerenciarseminarios" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle"
                        style="margin-left: 20px;"><i class="fas fa-tools"></i>
                        Gerenciar Seminarios</a>
                    <ul class="collapse list-unstyled" id="Gerenciarseminarios">

                        <div
                            *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao') || _perfil.includes('Secretaria')|| _perfil.includes('Seminario_Parcial') || _perfil.includes('Seminario_Total') ">
                            <li>
                                <a href="#seminarioSubmenu" data-toggle="collapse" aria-expanded="false"
                                    class="dropdown-toggle" style="margin-left: 20px;"><i class="fas fa-tools"></i>
                                    Seminarios Adultos</a>
                                <ul class="collapse list-unstyled" id="seminarioSubmenu">
                                    <li>

                                        <a [routerLink]="['/dashboard/listagem/lista-seminario']"><i
                                                class="fas fa-clipboard-list"></i> Lista de Participantes </a>
                                    </li>

                                    <li
                                        *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao') || _perfil.includes('Secretaria')|| _perfil.includes('Seminario_Total') ">
                                        <a data-toggle="modal" data-target="#ModalListagemSeminario"><i
                                                class="fas fa-print"></i>
                                            Impressão Listagem</a>
                                    </li>
                                    <li
                                        *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao') || _perfil.includes('Secretaria')|| _perfil.includes('Seminario_Total') ">
                                        <a [routerLink]="['/dashboard/comunicado/enviar-comunicado']"><i
                                                class="fas fa-bullhorn"></i> Enviar
                                            Comunicado</a>
                                    </li>
                                    <li
                                        *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao')|| _perfil.includes('Secretaria')">

                                        <a [routerLink]="['/dashboard/resumo/resumo-seminario-registrado']"><i
                                                class="fas fa-clipboard-list"></i> Total Registros </a>
                                    </li>
                                    <li
                                        *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao')|| _perfil.includes('Secretaria')">

                                        <a [routerLink]="['/dashboard/resumo/resumo-seminario-confirmado']"><i
                                                class="fas fa-clipboard-list"></i> Total Presentes </a>
                                    </li>
                                    <li
                                        *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao')|| _perfil.includes('Secretaria')|| _perfil.includes('Checkout')">
                                        <a [routerLink]="['/dashboard/checkout/documento-seminario']"><i
                                                class="fas fa-barcode"></i>
                                            Validar
                                            Entrada</a>
                                    </li>
                                </ul>
                            </li>

                        </div>

                        <div
                            *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao') || _perfil.includes('Secretaria')|| _perfil.includes('Seminario_Parcial') || _perfil.includes('Seminario_Total') || _perfil.includes('Evento_Infantil') ">
                            <li>
                                <a href="#seminarioSubmenuinfantil" data-toggle="collapse" aria-expanded="false"
                                    class="dropdown-toggle" style="margin-left: 20px;"><i class="fas fa-tools"></i>
                                    Seminarios Infantil</a>
                                <ul class="collapse list-unstyled" id="seminarioSubmenuinfantil">
                                    <li>

                                        <a [routerLink]="['/dashboard/listagem/lista-seminario-infantil']"><i
                                                class="fas fa-clipboard-list"></i> Lista de Participantes </a>
                                    </li>

                                    <li
                                        *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao') || _perfil.includes('Secretaria')|| _perfil.includes('Seminario_Total') ">
                                        <a data-toggle="modal" data-target="#ModalListagemSeminarioInfantil"><i
                                                class="fas fa-print"></i>
                                            Impressão Listagem</a>
                                    </li>
                                    <li
                                        *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao') || _perfil.includes('Secretaria')|| _perfil.includes('Seminario_Total') ">
                                        <a
                                            [routerLink]="['/dashboard/comunicado/enviar-comunicado-seminario-infantil']"><i
                                                class="fas fa-bullhorn"></i> Enviar
                                            Comunicado</a>
                                    </li>
                                    <li
                                        *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao')|| _perfil.includes('Secretaria')|| _perfil.includes('Evento_Infantil')">

                                        <a [routerLink]="['/dashboard/resumo/resumo-seminario-infantil-registrado']"><i
                                                class="fas fa-clipboard-list"></i> Total Registros </a>
                                    </li>
                                    <li
                                        *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao')|| _perfil.includes('Secretaria')">

                                        <a [routerLink]="['/dashboard/resumo/resumo-seminario-infantil-confirmado']"><i
                                                class="fas fa-clipboard-list"></i> Total Presentes </a>
                                    </li>
                                    <li
                                        *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao')|| _perfil.includes('Secretaria')|| _perfil.includes('Checkout')">
                                        <a [routerLink]="['/dashboard/checkout/documento-seminario-infantil']"><i
                                                class="fas fa-barcode"></i>
                                            Validar
                                            Entrada</a>
                                    </li>
                                </ul>
                            </li>

                        </div>
                    </ul>
                </li>
                <hr style="background-color: white;">
            </div>
            <!---FIM CONFIGURAÇÕES MEMBROS ANALISAR OS PERFIS-->

            <!--================================================================================-->
            <!---GERENCIAR VISITANTES-->
            <div
                *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao')|| _perfil.includes('Secretaria') || _perfil.includes('Consolidacao')">
                <li>
                    <a href="#ParametrosSubmenuvisitantes" data-toggle="collapse" aria-expanded="false"
                        class="dropdown-toggle" style="margin-left: 20px;"><i class="fas fa-tools"></i> Gerenciar
                        Visitantes</a>
                    <ul class="collapse list-unstyled" id="ParametrosSubmenuvisitantes">
                        <li>
                            <a [routerLink]="['/dashboard/gerenciamento/visitantes/visitantes-listagem']"><i
                                    class="fas fa-clipboard-list"></i> Listagem
                                Visitantes</a>
                        </li>

                        <li>
                            <a data-toggle="modal" data-target="#ModalListagemVisitantes"><i class="fas fa-print"></i>
                                Impressão Listagem</a>
                        </li>
                        <li>
                            <a [routerLink]="['/dashboard/gerenciamento/visitantes/comunicacao/comunicar-visitantes']"><i
                                    class="fas fa-bullhorn"></i> Enviar
                                Comunicado
                            </a>
                        </li>
                    </ul>
                </li>
                <hr style="background-color: white;">
            </div>
            <!---FIM CONFIGURAÇÕES MEMBROS ANALISAR OS PERFIS-->
            <!--================================================================================-->

            <!--================================================================================-->
            <!---PATRIMONIO-->
            <div
                *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Patrimonio')|| _perfil.includes('Patrimonio_Total')">
                <li>
                    <a href="#ParametrosSubmenuPatrimonio" data-toggle="collapse" aria-expanded="false"
                        class="dropdown-toggle" style="margin-left: 20px;"><i class="fas fa-tools"></i> Patrimonio
                    </a>
                    <ul class="collapse list-unstyled" id="ParametrosSubmenuPatrimonio">
                        <li
                            *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao')|| _perfil.includes('Patrimonio_Total')|| _perfil.includes('Patrimonio')">
                            <a [routerLink]="['/dashboard/gerenciamento/patrimonio/registrar-patrimonio']"><i
                                    class="fas fa-cogs"></i> Cadastrar Patrimonio</a>
                        </li>

                        <li
                            *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao')|| _perfil.includes('Patrimonio_Total')|| _perfil.includes('Patrimonio')">
                            <a [routerLink]="['/dashboard/gerenciamento/patrimonio/resumo-patrimonio-setor']"><i
                                    class="fas fa-poll"></i> Resumo por Setor</a>
                        </li>

                        <li
                            *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao')|| _perfil.includes('Patrimonio_Total')|| _perfil.includes('Patrimonio')">
                            <a [routerLink]="['/dashboard/gerenciamento/patrimonio/listagem-patrimonio']"><i
                                    class="fas fa-chair"></i> Listagem de Patrimonios</a>
                        </li>

                        <li
                            *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao')|| _perfil.includes('Patrimonio_Total')|| _perfil.includes('Patrimonio')">
                            <a [routerLink]="['/dashboard/gerenciamento/patrimonio/listagem-patrimonio-inativos']"><i
                                    class="fas fa-ban"></i> Patrimonios Inativos</a>
                        </li>

                        <li
                            *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao')|| _perfil.includes('Patrimonio_Total')|| _perfil.includes('Patrimonio')">
                            <a data-toggle="modal" data-target="#ModalImprimirEtiquetas"><i class="fas fa-print"></i>
                                Imprimir Etiquetas</a>
                        </li>


                    </ul>
                </li>
                <hr style="background-color: white;">
            </div>
            <!---FIM CONFIGURAÇÕES MEMBROS ANALISAR OS PERFIS-->
            <!--================================================================================-->


            <!--================================================================================-->
            <!---SOLICITACAO COMPRAS-->
            <div
                *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Solicitacao_Compra')">
                <li>
                    <a href="#SolicitacaoComprasSubmenu" data-toggle="collapse" aria-expanded="false"
                        class="dropdown-toggle" style="margin-left: 20px;"><i class="fas fa-tools"></i> Solicitação de
                        Compras
                    </a>
                    <ul class="collapse list-unstyled" id="SolicitacaoComprasSubmenu">
                        <li
                            *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Solicitacao_Compra')">
                            <a [routerLink]="['/dashboard/gerenciamento/compras/solicitacao-compras']"><i
                                    class="fas fa-cogs"></i> Solicitar Compras</a>
                        </li>
                        <li
                            *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Solicitacao_Compra')">
                            <a [routerLink]="['/dashboard/gerenciamento/compras/listagem-sollicitcao-compras']"><i
                                    class="fas fa-print"></i> Lista Solicitações Compra</a>
                        </li>

                    </ul>
                </li>
                <hr style="background-color: white;">
            </div>
            <!---FIM SOLICITACAO COMPRAS MEMBROS ANALISAR OS PERFIS-->
            <!--================================================================================-->




            <!--================================================================================-->
            <!---SOLICITACAO COMPRAS-->
            <div
                *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Deducao_Compra')|| _perfil.includes('Contabilidade')">
                <li>
                    <a href="#deducaocomprasubmenu" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle"
                        style="margin-left: 20px;"><i class="fas fa-tools"></i> Deduções de
                        Compras
                    </a>
                    <ul class="collapse list-unstyled" id="deducaocomprasubmenu">
                        <li
                            *ngIf="_perfil.includes('Deducao_Compra') || _perfil.includes('Presidente') || _perfil.includes('Administrador')||_perfil.includes('Financeiro')">
                            <a [routerLink]="['/dashboard/gerenciamento/deducao-compras/registrar-compra']"><i
                                    class="fas fa-cogs"></i> Registrar Compras</a>
                        </li>
                        <li
                            *ngIf="_perfil.includes('Deducao_Compra') || _perfil.includes('Financeiro') || _perfil.includes('Administrador')">
                            <a [routerLink]="['/dashboard/gerenciamento/deducao-compras/listagem-deducoes-compras']"><i
                                    class="fas fa-print"></i> Listar Compras</a>
                        </li>
                        <li
                            *ngIf="_perfil.includes('Administrador') || _perfil.includes('Financeiro') || _perfil.includes('Contabilidade')">
                            <a [routerLink]="['/dashboard/gerenciamento/deducao-compras/listagem-todas-deducoes']"><i
                                    class="fas fa-print"></i> Lista Todas Compras</a>
                        </li>

                        <li *ngIf="_perfil.includes('Administrador')">
                            <a [routerLink]="['/dashboard/gerenciamento/deducao-compras/vincular-responsavel']"><i
                                    class="fas fa-tools"></i> Vincular Responsavél</a>
                        </li>

                    </ul>
                </li>
                <hr style="background-color: white;">
            </div>
            <!---FIM SOLICITACAO COMPRAS MEMBROS ANALISAR OS PERFIS-->
            <!--================================================================================-->










            <!--================================================================================-->
            <!---CURSO CASAIS-->
            <div
                *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Curso_Casais')|| _perfil.includes('Curso_Casais_Total')">
                <li>
                    <a href="#CursoCasaisSubmenu" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle"
                        style="margin-left: 20px;"><i class="fas fa-tools"></i> Curso para Casais
                    </a>
                    <ul class="collapse list-unstyled" id="CursoCasaisSubmenu">
                        <li
                            *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Curso_Casais')|| _perfil.includes('Curso_Casais_Total')">
                            <a [routerLink]="['/dashboard/gerenciamento/cursos/curso-para-casais/listagem-cadastros']"><i
                                    class="fas fa-chair"></i> Listagem Cadastros Casais</a>
                        </li>
                        <li
                            *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao')|| _perfil.includes('Curso_Casais_Total')">
                            <a data-toggle="modal" data-target="#ModalImprimirCasais"><i class="fas fa-print"></i>
                                Listagem Detalhada</a>
                        </li>

                        <li
                            *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao')|| _perfil.includes('Curso_Casais_Total')">
                            <a data-toggle="modal" data-target="#ModalImprimirPresencaCasais"><i
                                    class="fas fa-print"></i>
                                Listagem Simplificada</a>
                        </li>



                    </ul>
                </li>
                <hr style="background-color: white;">
            </div>
            <!---FIM CURSO CASAIS MEMBROS ANALISAR OS PERFIS-->
            <!--================================================================================-->






            <!--================================================================================-->



            <!---CONFIGURAÇÕES EMAILS TERMOS ANALISAR OS PERFIS-->
            <div
                *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao') ">
                <li>
                    <a href="#ParametrosSubmenus" data-toggle="collapse" aria-expanded="false" class="dropdown-toggle"
                        style="margin-left: 20px;"><i class="fas fa-tools"></i>
                        Configurações</a>
                    <ul class="collapse list-unstyled" id="ParametrosSubmenus">
                        <li>
                            <a [routerLink]="['/dashboard/parametros']"><i class="fas fa-toolbox"></i>
                                Parametrizações</a>
                        </li>
                        <li>
                            <a [routerLink]="['/dashboard/termos']"><i class="fas fa-file-signature"></i> Termo
                                Cadastro</a>
                        </li>

                        <li>
                            <a [routerLink]="['/dashboard/termos-estabelecimentos']"><i
                                    class="fas fa-file-signature"></i> Termo Emp./Serv.</a>
                        </li>

                        <li>
                            <a [routerLink]="['/dashboard/termos-encontro-homens']"><i
                                    class="fas fa-file-signature"></i> Termo Enc. Homens</a>
                        </li>
                        <li>
                            <a [routerLink]="['/dashboard/termos-encontro-mulheres']"><i
                                    class="fas fa-file-signature"></i> Termo Enc. Mulheres</a>
                        </li>
                        <li>
                            <a [routerLink]="['/dashboard/termos-curso-casais']"><i class="fas fa-file-signature"></i>
                                Termo Curso Casais</a>
                        </li>
                      <li>
                        <a [routerLink]="['/dashboard/termos-encontro-casais']"><i class="fas fa-file-signature"></i>
                          Termo Encontro Casais</a>
                      </li>
                        <li>
                            <a [routerLink]="['/dashboard/termos-congresso-mulheres']"><i
                                    class="fas fa-file-signature"></i>
                                Termo Congresso Mulheres</a>
                        </li>

                        <li>
                            <a [routerLink]="['/dashboard/termos-congresso-homens']"><i
                                    class="fas fa-file-signature"></i>
                                Termo Congresso Homens</a>
                        </li>
                        <li>
                            <a [routerLink]="['/dashboard/termos-congresso-jovens']"><i
                                    class="fas fa-file-signature"></i>
                                Termo Congresso Jovens</a>
                        </li>
                        <li>
                            <a [routerLink]="['/dashboard/termos-oportunidade']"><i class="fas fa-file-signature"></i>
                                Termo Oportunidades</a>
                        </li>

                        <!---BACKUP-->

                        <li *ngIf="_cpf.includes('07509946654')">
                            <a (click)="Backup()"> <i class="far fa-file-archive"></i>
                                Backup</a>
                        </li>
                        <!---FIM BACKUP-->

                        <!---Bot  Backup-->

                        <li *ngIf="_cpf.includes('07509946654')">
                            <a data-toggle="modal" data-target="#ModalBotBackup"><i class="fas fa-robot"></i>
                                Bot Backup</a>
                        </li>
                        <!---FIM -->
                        <!---Bot  Backup-->

                        <li *ngIf="_cpf.includes('07509946654')">
                            <a data-toggle="modal" data-target="#ModalBotVersiculo"><i class="fas fa-robot"></i>
                                Bot Versiculo</a>
                        </li>
                        <!---FIM -->
                        <!---Bot  Backup-->

                        <li *ngIf="_cpf.includes('07509946654')">
                            <a data-toggle="modal" data-target="#ModalBotLive"><i class="fas fa-robot"></i>
                                Bot Live</a>
                        </li>
                        <!---FIM -->
                        <!---Bot  Backup-->

                        <li *ngIf="_cpf.includes('07509946654')">
                            <a data-toggle="modal" data-target="#ModalBotFamilia"><i class="fas fa-robot"></i>
                                Bot Culto Familia</a>
                        </li>
                        <!---FIM -->
                        <!---Bot  Backup-->

                        <li *ngIf="_cpf.includes('07509946654')">
                            <a data-toggle="modal" data-target="#ModalBothomi"><i class="fas fa-robot"></i>
                                Bot Culto Homens</a>
                        </li>
                        <!---FIM -->

                        <!---Bot  Backup-->

                        <li *ngIf="_cpf.includes('07509946654')">
                            <a data-toggle="modal" data-target="#ModalBotmulher"><i class="fas fa-robot"></i>
                                Bot Culto Mulheres</a>
                        </li>
                        <!---FIM -->
                        <!---Bot  Backup-->

                        <li *ngIf="_cpf.includes('07509946654')">
                            <a data-toggle="modal" data-target="#ModalBotdevoc"><i class="fas fa-robot"></i>
                                Bot Devocional</a>
                        </li>
                        <!---FIM -->
                        <!--================================================================================-->

                    </ul>
                </li>
                <hr style="background-color: white;">
            </div>
            <!---FIM CONFIGURAÇÕES EMAILS TERMOS ANALISAR OS PERFIS-->
            <!--================================================================================-->

            <!---POLITICA PRIVACIDADE-->
            <div>
                <li class="mt-0 mb-0 text-center">
                    <a [routerLink]="['/dashboard/privacidade']"> <i class="fas fa-handshake"></i> Politica de
                        Privacidade</a>
                </li>
                <hr style="background-color: white;">
            </div>
            <!---FIM POLITICA PRIVACIDADE-->
            <!--================================================================================-->

            <!---EXCLUIR CONTA-->
            <div>
                <li class="mt-0 mb-0 text-center">
                    <a [routerLink]="['/dashboard/cancelar-registro']"> <i class="far fa-trash-alt"
                            style="color: red;"></i>
                        Excluir Conta</a>
                </li>
                <hr style="background-color: white;">
            </div>
            <!---FIM EXCLUIR CONTA-->
            <!--================================================================================-->



            <!---SAIR-->
            <div>
                <li class="mt-0 mb-0 text-center">
                    <a (click)="exit()"> <i class="fas fa-sign-out-alt"></i>
                        Sair</a>
                </li>
                <hr style="background-color: white;">
            </div>
            <!---FIM SAIR-->
            <!--================================================================================-->
            <br>
            <br>

        </ul>
    </nav>


    <!-- Page Content  -->
    <div id="content">

        <nav class="navbar navbar-expand-lg  small white ">
            <div class="container-fluid" style="margin-top: 3px;">

                <button type="button" id="sidebarCollapse" style="margin-top: 0%;" class="btn bt-success no-border ">
                    <i class="fas fa-align-left" style="margin-top: 0%; color: white;"></i>

                </button>
                <button class="btn btn-dark d-inline-block d-lg-none ml-auto mt-0 p-0" type="button"
                    data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                    aria-expanded="false" aria-label="Toggle navigation">
                    <i class="fas fa-align-justify"></i>
                </button>

                <div class="collapse navbar-collapse row mb-3 white" id="navbarSupportedContent">
                    <ul class="nav navbar-nav ml-auto text-right mr-0 white"
                        style="font-size: smaller; padding: 0%; margin-top: 7px;">
                        <div class="col-md-auto text-right mr-0 white"><i class="far fa-address-card"></i> {{_user}} -
                            {{_cpf}}
                            <div class="col-mr-auto">Registro: {{_registro}}
                            </div>
                            <div class="col-mr-auto">Ultimo Acesso: {{_acesso}} - {{_hora}}Hs</div>

                        </div>
                        <li class="nav-item active mr-3 row">
                            <a class="nav-link" title=":: SAIR ::" (click)="exit()"><i class="fas fa-sign-out-alt "
                                    style="font-size: large;"></i></a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
        <div class="col-md-auto ml-3">

            <router-outlet>

            </router-outlet>
        </div>

    </div>


    <div id="ModalListagemSeminario" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog">

            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Gerar Documento para Impressão</h4>
                </div>
                <div class="modal-body">
                    <p>Por favor escolha a opção correta para geração do PDF para impressão!.</p>

                    <div style="font-size: medium;">
                        <input id="novo" name="base" type="radio" value="Todos Registros ( Ativos e Inativos )" checked
                            (click)="ValorImpressao($event)" />
                        Todos Registros ( Ativos e Inativos )
                    </div>
                    <div style="font-size: medium;">
                        <input id="novo" name="base" type="radio" value="Todos Registros Ativos"
                            (click)="ValorImpressao($event)" />
                        Todos Registros Ativos
                    </div>
                    <div style="font-size: medium;">
                        <input id="novo" name="base" type="radio" value="Todos Registros Inativos"
                            (click)="ValorImpressao($event)" />
                        Todos Registros Inativos
                    </div>
                    <div style="font-size: medium;">
                        <input id="novo" name="base" type="radio" value="CRESCIMENTO E DESENVOLVIMENTO DE MINISTÉRIOS"
                            (click)="ValorImpressao($event)" /> Crescimento e desenvolvimento de ministério
                    </div>
                    <div style="font-size: medium;">
                        <input id="novo" name="base" type="radio" value="BATALHA ESPIRITUAL E CURA INTERIOR"
                            (click)="ValorImpressao($event)" /> Batalha espiritual e cura interior
                    </div>

                    <div style="font-size: medium;">
                        <input id="novo" name="base" type="radio" value="LOUVOR ADORAÇÃO E CHAMADO"
                            (click)="ValorImpressao($event)" /> Louvor Adoração e Chamado
                    </div>



                </div>
                <div class="modal-footer">
                    <button pButton pRipple type="button" icon="pi pi-print" class="p-button-rounded"
                        title="Gerar Documento" (click)="GerarPDF()" data-dismiss="modal"></button>
                    <button pButton pRipple type="button" icon="pi pi-sign-in" class="p-button-rounded p-button-danger"
                        title="Fechar" data-dismiss="modal"></button>
                </div>
            </div>

        </div>
    </div>


    <div id="ModalListagemSeminarioInfantil" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog">

            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Gerar Documento para Impressão</h4>
                </div>
                <div class="modal-body">
                    <p>Por favor escolha a opção correta para geração do PDF para impressão!.</p>

                    <div style="font-size: medium;">
                        <input id="novo" name="base" type="radio" value="Todos Registros ( Ativos e Inativos )" checked
                            (click)="ValorImpressaoInfantil($event)" />
                        Todos Registros ( Ativos e Inativos )
                    </div>
                    <div style="font-size: medium;">
                        <input id="novo" name="base" type="radio" value="Todos Registros Ativos"
                            (click)="ValorImpressaoInfantil($event)" />
                        Todos Registros Ativos
                    </div>
                    <div style="font-size: medium;">
                        <input id="novo" name="base" type="radio" value="Todos Registros Inativos"
                            (click)="ValorImpressaoInfantil($event)" />
                        Todos Registros Inativos
                    </div>
                    <div style="font-size: medium;">
                        <input id="novo" name="base" type="radio" value="BUSCANDO MEU AMIGO ESPÍRITO SANTO"
                            (click)="ValorImpressaoInfantil($event)" /> Buscando meu amigo Espírito Santo
                    </div>

                    <div style="font-size: medium;">
                        <input id="novo" name="base" type="radio" value="Festinha da Morada Kids"
                            (click)="ValorImpressaoInfantil($event)" /> FESTINHA DA MORADA KIDS
                    </div>



                </div>
                <div class="modal-footer">
                    <button pButton pRipple type="button" icon="pi pi-print" class="p-button-rounded"
                        title="Gerar Documento" (click)="GerarPDFInfantil()" data-dismiss="modal"></button>
                    <button pButton pRipple type="button" icon="pi pi-sign-in" class="p-button-rounded p-button-danger"
                        title="Fechar" data-dismiss="modal"></button>
                </div>
            </div>

        </div>
    </div>


    <div id="ModalListagemEncontroMulheres" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog">

            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Gerar Documento para Impressão</h4>
                </div>
                <div class="modal-body">
                    <p>Por favor escolha a opção correta para geração do PDF para impressão!.</p>

                    <div style="font-size: medium;">
                        <input id="novo" name="base" type="radio" value="Todos Registros ( Ativos e Inativos )" checked
                            (click)="ValorImpressaoEncontroMulheres($event)" />
                        Todos Registros ( Ativos e Inativos )
                    </div>
                    <div style="font-size: medium;">
                        <input id="novo" name="base" type="radio" value="Todos Registros Ativos"
                            (click)="ValorImpressaoEncontroMulheres($event)" />
                        Todos Registros Ativos
                    </div>
                    <div style="font-size: medium;">
                        <input id="novo" name="base" type="radio" value="Todos Registros Inativos"
                            (click)="ValorImpressaoEncontroMulheres($event)" />
                        Todos Registros Inativos
                    </div>

                </div>
                <div class="modal-footer">
                    <button pButton pRipple type="button" icon="pi pi-print" class="p-button-rounded"
                        title="Gerar Documento" (click)="GerarPDFEncontroMulheres()" data-dismiss="modal"></button>
                    <button pButton pRipple type="button" icon="pi pi-sign-in" class="p-button-rounded p-button-danger"
                        title="Fechar" data-dismiss="modal"></button>
                </div>
            </div>

        </div>
    </div>



    <div id="ModalImprimirEtiquetas" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Gerar Documento para Impressão Etiquetas</h4>
                </div>
                <div class="modal-body">
                    <p>Por favor escolha a opção correta para geração do PDF para impressão!.</p>
                    <div style="font-size: medium;">
                        Selecione uma Opção:
                        <select class="custom-select" type="text" name="tipo_etiqueta" id="tipo_etiqueta"
                            (change)="ValorImpressaoEtiqueta($event)">
                            <option value=""></option>
                            <option value="TODOS">TODOS SETORES</option>
                            <option *ngFor="let culto of setores" [ngValue]="culto.setor">
                                {{ culto.setor }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="modal-footer">
                    <button pButton pRipple type="button" icon="pi pi-print" class="p-button-rounded"
                        title="Gerar Documento" (click)="GerarPDFEtiquetas()" data-dismiss="modal"></button>
                    <button pButton pRipple type="button" icon="pi pi-sign-in" class="p-button-rounded p-button-danger"
                        title="Fechar" data-dismiss="modal"></button>
                </div>
            </div>
        </div>
    </div>


    <div id="ModalImprimirCasais" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Gerar Documento para Impressão</h4>
                </div>
                <div class="modal-body">
                    <p>Por favor escolha a opção correta para geração do PDF para impressão!.</p>
                    <div style="font-size: medium;">
                        Selecione uma Opção:
                        <select class="custom-select" type="text" name="tipo_casal" id="tipo_casal"
                            (change)="ValorImpressaoCasals($event)">
                            <option value=""></option>
                            <option value="TODOSATIVOS">TODOS ATIVOS</option>
                            <option value="TODOSINATIVOS">TODOS INATIVOS</option>
                            <option value="TODOSCONFIRMADO">TODOS CONFIRMADO</option>
                            <option value="TODOSNAOCONFIRMADO">TODOS NÃO CONFIRMADO</option>
                            <option value="TODOSCONFIRMADOSORDEMALFABETICA">TODOS CONFIRMADOS ORDEM ALFABETICA/option>
                            <option value="TODOSORDEMALFABETICA">TODOS ORDEM ALFABETICA</option>
                            <option value="TODOSCONFIRMADOSORDEMALFABETICA">TODOS CONFIRMADOS ORDEM ALFABETICA</option>
                            <option value="TODOSORDEMALFABETICA">TODOS ORDEM ALFABETICA</option>
                            <option value="TODOSORDEMCADASTRO">TODOS ORDEM CADASTRO</option>

                        </select>
                    </div>
                </div>
                <div class="modal-footer">
                    <button pButton pRipple type="button" icon="pi pi-print" class="p-button-rounded"
                        title="Gerar Documento" (click)="GerarPDFDetalheCasal()" data-dismiss="modal"></button>
                    <button pButton pRipple type="button" icon="pi pi-sign-in" class="p-button-rounded p-button-danger"
                        title="Fechar" data-dismiss="modal"></button>
                </div>
            </div>
        </div>
    </div>

    <div id="ModalImprimirPresencaCasais" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Gerar Documento para Impressão</h4>
                </div>
                <div class="modal-body">
                    <p>Por favor escolha a opção correta para geração do PDF para impressão!.</p>
                    <div style="font-size: medium;">
                        Selecione uma Opção:
                        <select class="custom-select" type="text" name="tipo_casal" id="tipo_casal"
                            (change)="ValorImpressaoCasalssimples($event)">
                            <option value=""></option>
                            <option value="TODOSATIVOS">TODOS ATIVOS</option>
                            <option value="TODOSINATIVOS">TODOS INATIVOS</option>
                            <option value="TODOSCONFIRMADO">TODOS CONFIRMADO</option>
                            <option value="TODOSNAOCONFIRMADO">TODOS NÃO CONFIRMADO</option>
                            <option value="TODOSCONFIRMADOSORDEMALFABETICA">TODOS CONFIRMADOS ORDEM ALFABETICA/option>
                            <option value="TODOSORDEMALFABETICA">TODOS ORDEM ALFABETICA</option>
                            <option value="TODOSCONFIRMADOSORDEMALFABETICA">TODOS CONFIRMADOS ORDEM ALFABETICA</option>
                            <option value="TODOSORDEMALFABETICA">TODOS ORDEM ALFABETICA</option>
                            <option value="TODOSORDEMCADASTRO">TODOS ORDEM CADASTRO</option>

                        </select>
                    </div>
                </div>
                <div class="modal-footer">
                    <button pButton pRipple type="button" icon="pi pi-print" class="p-button-rounded"
                        title="Gerar Documento" (click)="GerarPDFDetalheCasalsimples()" data-dismiss="modal"></button>
                    <button pButton pRipple type="button" icon="pi pi-sign-in" class="p-button-rounded p-button-danger"
                        title="Fechar" data-dismiss="modal"></button>
                </div>
            </div>
        </div>
    </div>

    <div id="ModalListagemEncontroHomens" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog">

            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Gerar Documento para Impressão</h4>
                </div>
                <div class="modal-body">
                    <p>Por favor escolha a opção correta para geração do PDF para impressão!.</p>

                    <div style="font-size: medium;">
                        <input id="novo" name="base" type="radio" value="Todos Registros ( Ativos e Inativos )" checked
                            (click)="ValorImpressaoEncontroHomens($event)" />
                        Todos Registros ( Ativos e Inativos )
                    </div>
                    <div style="font-size: medium;">
                        <input id="novo" name="base" type="radio" value="Todos Registros Ativos"
                            (click)="ValorImpressaoEncontroHomens($event)" />
                        Todos Registros Ativos
                    </div>
                    <div style="font-size: medium;">
                        <input id="novo" name="base" type="radio" value="Todos Registros Inativos"
                            (click)="ValorImpressaoEncontroHomens($event)" />
                        Todos Registros Inativos
                    </div>

                  <div style="font-size: medium;">
                    <input id="novo" name="base" type="radio" value="Todos Trabalhando"
                           (click)="ValorImpressaoEncontroHomens($event)" />
                    Todos Trabalhando
                  </div>

                  <div style="font-size: medium;">
                    <input id="novo" name="base" type="radio" value="Todos Passando"
                           (click)="ValorImpressaoEncontroHomens($event)" />
                    Todos Passando
                  </div>

                </div>
                <div class="modal-footer">
                    <button pButton pRipple type="button" icon="pi pi-print" class="p-button-rounded"
                        title="Gerar Documento" (click)="GerarPDFEncontroHomens()" data-dismiss="modal"></button>
                    <button pButton pRipple type="button" icon="pi pi-sign-in" class="p-button-rounded p-button-danger"
                        title="Fechar" data-dismiss="modal"></button>
                </div>
            </div>

        </div>
    </div>


  <div id="ModalListagemEncontroCasais" class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog">

      <!-- Modal content-->
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">Gerar Documento para Impressão</h4>
        </div>
        <div class="modal-body">
          <p>Por favor escolha a opção correta para geração do PDF para impressão!.</p>

          <div style="font-size: medium;">
            <input id="novo" name="base" type="radio" value="Todos Registros ( Ativos e Inativos )" checked
                   (click)="ValorImpressaoEncontroCasais($event)" />
            Todos Registros ( Ativos e Inativos )
          </div>
          <div style="font-size: medium;">
            <input id="novo" name="base" type="radio" value="Todos Registros Ativos"
                   (click)="ValorImpressaoEncontroCasais($event)" />
            Todos Registros Ativos
          </div>
          <div style="font-size: medium;">
            <input id="novo" name="base" type="radio" value="Todos Registros Inativos"
                   (click)="ValorImpressaoEncontroCasais($event)" />
            Todos Registros Inativos
          </div>

          <div style="font-size: medium;">
            <input id="novo" name="base" type="radio" value="Todos Trabalhando"
                   (click)="ValorImpressaoEncontroCasais($event)" />
            Todos Trabalhando
          </div>

          <div style="font-size: medium;">
            <input id="novo" name="base" type="radio" value="Todos Passando"
                   (click)="ValorImpressaoEncontroCasais($event)" />
            Todos Passando
          </div>

        </div>
        <div class="modal-footer">
          <button pButton pRipple type="button" icon="pi pi-print" class="p-button-rounded"
                  title="Gerar Documento" (click)="GerarPDFEncontroCasais()" data-dismiss="modal"></button>
          <button pButton pRipple type="button" icon="pi pi-sign-in" class="p-button-rounded p-button-danger"
                  title="Fechar" data-dismiss="modal"></button>
        </div>
      </div>

    </div>
  </div>


    <!--
        modal para impressão da listagem de cultos adultos
        Alimentada pela consulta no banco de dados
    -->


    <div id="ModalListagemCongressoMulheres" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Gerar Documento para Impressão</h4>
                </div>
                <div class="modal-body">
                    <p>Por favor escolha a opção correta para geração do PDF para impressão!.</p>
                    <div style="font-size: medium;">
                        Selecione o Culto:
                        <select class="custom-select" type="text" name="tipo_culto" id="tipo_culto"
                            (change)="ValorImpressaocongressomulheres($event)">
                            <option value=""></option>
                            <option value="TodosCadastrados">Todos usuarios Cadastrados</option>
                            <option value="TodosAtivos">Todos usuarios Ativos</option>
                            <option value="TodosInativos">Todos usuarios Inativos</option>
                            <option *ngFor="let culto of congressos" [ngValue]="culto.congresso">
                                {{ culto.congresso }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="modal-footer">
                    <button pButton pRipple type="button" icon="pi pi-print" class="p-button-rounded"
                        title="Gerar Documento" (click)="GerarPDFCongressoMulheres()" data-dismiss="modal"></button>
                    <button pButton pRipple type="button" icon="pi pi-sign-in" class="p-button-rounded p-button-danger"
                        title="Fechar" data-dismiss="modal"></button>
                </div>
            </div>
        </div>
    </div>



    <div id="ModalListagemCongressoHomens" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Gerar Documento para Impressão</h4>
                </div>
                <div class="modal-body">
                    <p>Por favor escolha a opção correta para geração do PDF para impressão!.</p>
                    <div style="font-size: medium;">
                        Selecione o Culto:
                        <select class="custom-select" type="text" name="tipo_culto" id="tipo_culto"
                            (change)="ValorImpressaocongressohomens($event)">
                            <option value=""></option>
                            <option value="TodosCadastrados">Todos usuarios Cadastrados</option>
                            <option value="TodosAtivos">Todos usuarios Ativos</option>
                            <option value="TodosInativos">Todos usuarios Inativos</option>
                            <option *ngFor="let culto of congressoshomens" [ngValue]="culto.congresso">
                                {{ culto.congresso }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="modal-footer">
                    <button pButton pRipple type="button" icon="pi pi-print" class="p-button-rounded"
                        title="Gerar Documento" (click)="GerarPDFCongressoHomens()" data-dismiss="modal"></button>
                    <button pButton pRipple type="button" icon="pi pi-sign-in" class="p-button-rounded p-button-danger"
                        title="Fechar" data-dismiss="modal"></button>
                </div>
            </div>
        </div>
    </div>


    <div id="ModalListagemCongressoJovens" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Gerar Documento para Impressão</h4>
                </div>
                <div class="modal-body">
                    <p>Por favor escolha a opção correta para geração do PDF para impressão!.</p>
                    <div style="font-size: medium;">
                        Selecione o Culto:
                        <select class="custom-select" type="text" name="tipo_culto" id="tipo_culto"
                            (change)="ValorImpressaocongressojovens($event)">
                            <option value=""></option>
                            <option value="TodosCadastrados">Todos usuarios Cadastrados</option>
                            <option value="TodosAtivos">Todos usuarios Ativos</option>
                            <option value="TodosInativos">Todos usuarios Inativos</option>
                            <option *ngFor="let culto of congressosjovens" [ngValue]="culto.congresso">
                                {{ culto.congresso }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="modal-footer">
                    <button pButton pRipple type="button" icon="pi pi-print" class="p-button-rounded"
                        title="Gerar Documento" (click)="GerarPDFCongressoJovens()" data-dismiss="modal"></button>
                    <button pButton pRipple type="button" icon="pi pi-sign-in" class="p-button-rounded p-button-danger"
                        title="Fechar" data-dismiss="modal"></button>
                </div>
            </div>
        </div>
    </div>

    <div id="ModalListagemculto" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Gerar Documento para Impressão</h4>
                </div>
                <div class="modal-body">
                    <p>Por favor escolha a opção correta para geração do PDF para impressão!.</p>
                    <div style="font-size: medium;">
                        Selecione o Culto:
                        <select class="custom-select" type="text" name="tipo_culto" id="tipo_culto"
                            (change)="ValorImpressaoculto($event)">
                            <option value=""></option>
                            <option value="TodosCadastrados">Todos usuarios Cadastrados</option>
                            <option value="TodosAtivos">Todos usuarios Ativos</option>
                            <option value="TodosInativos">Todos usuarios Inativos</option>
                            <option *ngFor="let culto of cultos" [ngValue]="culto.tipo_culto">
                                {{ culto.tipo_culto }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="modal-footer">
                    <button pButton pRipple type="button" icon="pi pi-print" class="p-button-rounded"
                        title="Gerar Documento" (click)="GerarPDFCulto()" data-dismiss="modal"></button>
                    <button pButton pRipple type="button" icon="pi pi-sign-in" class="p-button-rounded p-button-danger"
                        title="Fechar" data-dismiss="modal"></button>
                </div>
            </div>
        </div>
    </div>
    <!---FIM-->


    <!---BATISMO-->
    <div id="ModalListagembatismo" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Gerar Documento para Impressão</h4>
                </div>
                <div class="modal-body">
                    <p>Por favor escolha a opção correta para geração do PDF para impressão!.</p>
                    <div style="font-size: medium;">
                        Selecione o tipo:
                        <select class="custom-select" type="text" name="batismo" id="batismo"
                            (change)="ValorImpressaobatismo($event)">
                            <option value=""></option>
                            <option value="TodosCadastrados">Todos usuarios Cadastrados</option>
                            <option value="TodosAtivos">Todos usuarios Ativos</option>
                            <option value="TodosInativos">Todos usuarios Inativos</option>
                            <option *ngFor="let culto of referencias" [ngValue]="culto.referencia">
                                {{ culto.referencia }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="modal-footer">
                    <button pButton pRipple type="button" icon="pi pi-print" class="p-button-rounded"
                        title="Gerar Documento" (click)="GerarPDFBatismo()" data-dismiss="modal"></button>
                    <button pButton pRipple type="button" icon="pi pi-sign-in" class="p-button-rounded p-button-danger"
                        title="Fechar" data-dismiss="modal"></button>
                </div>
            </div>
        </div>
    </div>
    <!---FIM-->

    <div id="ModalListagemcultokids" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog">

            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Gerar Documento para Impressão</h4>
                </div>
                <div class="modal-body">
                    <p>Por favor escolha a opção correta para geração do PDF para impressão!.</p>
                    <div style="font-size: medium;">
                        Selecione o Culto:
                        <select class="custom-select" type="text" name="tipo_culto" id="tipo_culto"
                            (change)="ValorImpressaoculto($event)">
                            <option value=""></option>
                            <option value="Todos">Todos ( Ativos e Inativos )</option>
                            <option value="TodosAtivos">Todos Ativos</option>
                            <option value="TodosInativos">Todos Inativos</option>
                            <option *ngFor="let culto of cultoskids" [ngValue]="culto.tipo_culto">
                                {{ culto.tipo_culto }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="modal-footer">
                    <button pButton pRipple type="button" icon="pi pi-print" class="p-button-rounded"
                        title="Gerar Documento" (click)="GerarPDFCultoKids()" data-dismiss="modal"></button>
                    <button pButton pRipple type="button" icon="pi pi-sign-in" class="p-button-rounded p-button-danger"
                        title="Fechar" data-dismiss="modal"></button>
                </div>
            </div>

        </div>
    </div>



    <div id="ModalListagemMembros" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog">

            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Gerar Documento para Impressão</h4>
                </div>
                <div class="modal-body">
                    <p>Por favor escolha a opção correta para geração do PDF para impressão!.</p>

                    <div style="font-size: medium;">
                        <input id="novo" name="base" type="radio" value="Todos" checked
                            (click)="ValorImpressaoMembros($event)" /> Todos Membros ( Ativos e Inativos )
                    </div>
                    <div style="font-size: medium;">
                        <input id="novo" name="base" type="radio" value="TodosAtivos"
                            (click)="ValorImpressaoMembros($event)" /> Todos Membros Ativos
                    </div>
                    <div style="font-size: medium;">
                        <input id="novo" name="base" type="radio" value="TodosInativos"
                            (click)="ValorImpressaoMembros($event)" /> Todos Membros Inativos
                    </div>
                    <div style="font-size: medium;">
                        <input id="novo" name="base" type="radio" value="TodosCancelados"
                            (click)="ValorImpressaoMembros($event)" /> Todos Membros Cancelados
                    </div>

                </div>
                <div class="modal-footer">
                    <button pButton pRipple type="button" icon="pi pi-print" class="p-button-rounded"
                        title="Gerar Documento" (click)="GerarPDFMembros()" data-dismiss="modal"></button>
                    <button pButton pRipple type="button" icon="pi pi-sign-in" class="p-button-rounded p-button-danger"
                        title="Fechar" data-dismiss="modal"></button>
                </div>
            </div>

        </div>
    </div>

    <div id="ModalListagemVisitantes" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog">

            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Gerar Documento para Impressão</h4>
                </div>
                <div class="modal-body">
                    <p>Por favor escolha a opção correta para geração do PDF para impressão!.</p>

                    <div style="font-size: medium;">
                        <input id="novo" name="base" type="radio" value="Todos" checked
                            (click)="ValorImpressaoVisitantes($event)" /> Todos Visitantes
                    </div>
                    <div style="font-size: medium;">
                        <input id="novo" name="base" type="radio" value="TodosContatoRealizado"
                            (click)="ValorImpressaoVisitantes($event)" /> Todos Visitantes Com Contato Realizado
                    </div>
                    <div style="font-size: medium;">
                        <input id="novo" name="base" type="radio" value="TodosContatoNaoRealizado"
                            (click)="ValorImpressaoVisitantes($event)" /> Todos Visitantes Sem Contato Realizado
                    </div>
                    <div style="font-size: medium;">
                        <input id="novo" name="base" type="radio" value="TodosPrimeiraVisita"
                            (click)="ValorImpressaoVisitantes($event)" /> Todos Primeira Visita
                    </div>
                    <div style="font-size: medium;">
                        <input id="novo" name="base" type="radio" value="TodosSegundaVisita"
                            (click)="ValorImpressaoVisitantes($event)" /> Todos Mais de uma Visita
                    </div>

                </div>
                <div class="modal-footer">
                    <button pButton pRipple type="button" icon="pi pi-print" class="p-button-rounded"
                        title="Gerar Documento" (click)="GerarPDFVisitantes()" data-dismiss="modal"></button>
                    <button pButton pRipple type="button" icon="pi pi-sign-in" class="p-button-rounded p-button-danger"
                        title="Fechar" data-dismiss="modal"></button>
                </div>
            </div>

        </div>
    </div>


    <!--BOTS-->
    <div id="ModalBotBackup" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog">

            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Bot executar Backup</h4>
                </div>
                <div class="modal-body">
                    <p>Ao clicar o BOT será chamado para executar um backup e enviar por e-mail!</p>

                </div>
                <div class="modal-footer">
                    <button pButton pRipple type="button" icon="pi pi-discord" class="p-button-rounded" title="Executar"
                        (click)="BotBackup()" data-dismiss="modal"></button>
                    <button pButton pRipple type="button" icon="pi pi-sign-in" class="p-button-rounded p-button-danger"
                        title="Desistir" data-dismiss="modal"></button>
                </div>
            </div>
        </div>
    </div>

    <div id="ModalBotVersiculo" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog">

            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Bot Enviar Versiculo</h4>
                </div>
                <div class="modal-body">
                    <p>Ao clicar o BOT será chamado para enviar versiculo no Grupo Telegram!</p>

                </div>
                <div class="modal-footer">
                    <button pButton pRipple type="button" icon="pi pi-discord" class="p-button-rounded" title="Executar"
                        (click)="BotVersiculo()" data-dismiss="modal"></button>
                    <button pButton pRipple type="button" icon="pi pi-sign-in" class="p-button-rounded p-button-danger"
                        title="Desistir" data-dismiss="modal"></button>
                </div>
            </div>
        </div>
    </div>

    <div id="ModalBotLive" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog">

            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Bot Comunicar Live</h4>
                </div>
                <div class="modal-body">
                    <p>Ao clicar o BOT será chamado para convidar para live Grupo Telegram!</p>

                </div>
                <div class="modal-footer">
                    <button pButton pRipple type="button" icon="pi pi-discord" class="p-button-rounded" title="Executar"
                        (click)="BotLive()" data-dismiss="modal"></button>
                    <button pButton pRipple type="button" icon="pi pi-sign-in" class="p-button-rounded p-button-danger"
                        title="Desistir" data-dismiss="modal"></button>
                </div>
            </div>
        </div>
    </div>

    <div id="ModalBotFamilia" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog">

            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Bot Comunicar Culto Familia</h4>
                </div>
                <div class="modal-body">
                    <p>Ao clicar o BOT será chamado para convidar para Culto Familia Grupo Telegram!</p>

                </div>
                <div class="modal-footer">
                    <button pButton pRipple type="button" icon="pi pi-discord" class="p-button-rounded" title="Executar"
                        (click)="BotCultoFamilia()" data-dismiss="modal"></button>
                    <button pButton pRipple type="button" icon="pi pi-sign-in" class="p-button-rounded p-button-danger"
                        title="Desistir" data-dismiss="modal"></button>
                </div>
            </div>
        </div>
    </div>


    <div id="ModalBothomi" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog">

            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Bot Comunicar Culto Homens</h4>
                </div>
                <div class="modal-body">
                    <p>Ao clicar o BOT será chamado para convidar para Culto Homens Grupo Telegram!</p>

                </div>
                <div class="modal-footer">
                    <button pButton pRipple type="button" icon="pi pi-discord" class="p-button-rounded" title="Executar"
                        (click)="BotCultoHomenss()" data-dismiss="modal"></button>
                    <button pButton pRipple type="button" icon="pi pi-sign-in" class="p-button-rounded p-button-danger"
                        title="Desistir" data-dismiss="modal"></button>
                </div>
            </div>
        </div>
    </div>

    <div id="ModalBotmulher" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog">

            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Bot Comunicar Culto Mulheres</h4>
                </div>
                <div class="modal-body">
                    <p>Ao clicar o BOT será chamado para convidar para Culto Mulheres Grupo Telegram!</p>

                </div>
                <div class="modal-footer">
                    <button pButton pRipple type="button" icon="pi pi-discord" class="p-button-rounded" title="Executar"
                        (click)="BotCultoMulheres()" data-dismiss="modal"></button>
                    <button pButton pRipple type="button" icon="pi pi-sign-in" class="p-button-rounded p-button-danger"
                        title="Desistir" data-dismiss="modal"></button>
                </div>
            </div>
        </div>
    </div>

    <div id="ModalBotdevoc" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog">

            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Bot Enviar Devocional</h4>
                </div>
                <div class="modal-body">
                    <p>Ao clicar o BOT será chamado para enviar Devocional no Grupo Telegram!</p>

                </div>
                <div class="modal-footer">
                    <button pButton pRipple type="button" icon="pi pi-discord" class="p-button-rounded" title="Executar"
                        (click)="BotDevocional()" data-dismiss="modal"></button>
                    <button pButton pRipple type="button" icon="pi pi-sign-in" class="p-button-rounded p-button-danger"
                        title="Desistir" data-dismiss="modal"></button>
                </div>
            </div>
        </div>
    </div>










</div>
<button class="voltar-ao-topo" id="back"><a class="ancora" style="color: white;"><i
            class="pi pi-arrow-circle-up"></i></a></button>
