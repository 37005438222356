import { Component, OnInit } from '@angular/core';
import {Parametros} from "../../../shared/parametros.enum";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {PrimeNGConfig} from "primeng/api";
import {AlertModalService} from "../../../shared/alert-modal/alert-modal.service";
import {SharedModule} from "../../../shared/shared.module";
import {ListagemCadastroCasaisService
} from "./listagem-cadastro-casais.service";
import {Router} from "@angular/router";
import {Model} from "../../../encontro-mulheres/Model";
import {ModelEncontroCasais} from "../../../encontro-casais/ModelEncontroCasais";

@Component({
  selector: 'app-listagem-cadastro-encontro-casais',
  templateUrl: './listagem-cadastro-encontro-casais.component.html',
  styleUrls: ['./listagem-cadastro-encontro-casais.component.css']
})
export class ListagemCadastroEncontroCasaisComponent implements OnInit {


  private urlgestao = Parametros.ENCONTROCASAIS;

  x = 1;
  y = 10;
  filtro_cabecalho: string = ""
  explicacao: string = "";
  textoconsulta: string = "";
  rota: any;
  perfil: any;
  pagina_atual = 1;
  imprimir: string = "Todos";
  idusuario: any;
  nome: string;
  opcao: string = "Ativar";
  private _perfil: string;


  constructor(private http: HttpClient,
              private primengConfig: PrimeNGConfig,
              private alert: AlertModalService,
              private autenticado: SharedModule,
              private service: ListagemCadastroCasaisService,
              r: Router
  ) {
    this.rota = r;
    this.perfil = this.autenticado.perfil;
    this.nome = this.autenticado.nome;
    this._perfil = this.autenticado.perfil;
  }

  startedClass = false;
  completedClass = false;
  preventAbuse = false;

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//

  cadastros: Array<ModelEncontroCasais>;
  restricaoalimentar:string="NÃO";
  qto: number;
  cols: any[];


  GetLista() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get<ModelEncontroCasais[]>(this.urlgestao + 'GETListaTodosCadastros', httpOptions).subscribe(
      (success) => {
        this.cadastros = success
        this.GetListaTotal();
      }
    )
  }

  GetListaTotal() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get<any>(this.urlgestao + 'GETTotalCadastros', httpOptions).subscribe(
      dados => this.qto = dados
    )
  }

  GetListaTotalQuantidadeDez(pagina: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get<any>(this.urlgestao + 'GetListaConsultaLimite/?parametro=' + pagina, httpOptions).subscribe(
      (success) => {
        this.cadastros = success;
        this.GetListaTotal();
      }
    )
  }

  ngOnInit() {
    if (this.autenticado.token == null) {
      this.autenticado.ResetDados();
      this.alert.AlertShowDanger('Usuário não autenticado, redirecionando...')
      this.rota.navigate(['/login']);
    } else {
      if (this.autenticado.ValidarToken() != true) {
        this.autenticado.ResetDados();
        this.alert.AlertShowDanger('Token Invalido!')
        this.rota.navigate(['/login']);
      }
    }
    this.primengConfig.ripple = true;
    this.filtro_cabecalho = "Listagem de Casais Cadastradas "
    this.explicacao = "Aqui você está visualizando todos os usuarios cadastrados na plataforma dos casais que passaram pelo encontro."
    this.GetListaTotal();
    this.GetLista();


    this.cols = [
      { field: 'Nome', header: 'Nome' },
      { field: 'Telefone', header: 'Telefone' },
      { field: 'Uso Medicação', header: 'Uso Medicação' },
      { field: 'Alergico Alimentos', header: 'Alergico Alimentos' },
      { field: 'Restrição Açucar', header: 'Restrição Açucar' },
      { field: 'Confirmado', header: 'Confirmado' },
      { field: 'Anotações', header: 'Anotações' }

    ];
  }

  Paginate(event) {
    if (event.page == 0) {
      this.x = 1;
      this.y = 10;
    } else {

      if (event.page * 10 > this.qto) {
        this.x = this.qto;
        this.y = this.qto;
      } else {
        this.x = event.page * 10;
        if (this.x + 10 > this.qto) {
          this.y = this.qto;
        } else {
          this.y = this.x + 10;
        }
      }
    }
    this.pagina_atual = event.page;

    this.GetListaTotalQuantidadeDez(this.pagina_atual);
  }

  Consulta() {
    var texto = (<HTMLInputElement>document.getElementById("texto_consulta")).value;
    if (texto != "") {
      this.textoconsulta = texto;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
        })
      }
      this.http.get<ModelEncontroCasais[]>(this.urlgestao + 'GetListaConsultaUsuarioNome/?parametro=' + texto, httpOptions).subscribe(
        (success) => {
          this.cadastros = success;
          this.qto = success.length;
        }

      )
    } else {
      this.textoconsulta = "";
      this.GetLista();
    }
  }



  VisualizarDetalhe(event) {
    localStorage.removeItem('CadastroEncontroCasaisDetalhe');
    localStorage.setItem('CadastroCasaisDetalhe', event.currentTarget.value);
    this.rota.navigate(['/dashboard/gerenciamento/detalhamento/Cadastro-casais-detalhe']);
  }


  //modal
  Operacao() {
    if (this.opcao == "Ativar") {
      this.service.AtivarUsuario(this.idusuario).subscribe(
        (success) => {
          this.alert.AlertShowInfo('Membro Ativado com sucesso!');
          this.GetLista();
        },
        (error) => {
          this.alert.AlertShowDanger('Erro ao tentar Ativar Membro!');
          this.GetLista();
        }
      );
    }

    if (this.opcao == "Inativar") {
      this.service.InativarUsuario(this.idusuario).subscribe(
        (success) => {
          this.alert.AlertShowInfo('Membro Inativado com sucesso!');
          this.GetLista();
        },
        (error) => {
          this.alert.AlertShowDanger('Erro ao tentar Inativar Membro!');
          this.GetLista();
        }
      );
    }
    if (this.opcao == "Cancelar") {
      this.service.Cancelarsuario(this.idusuario).subscribe(
        (success) => {
          this.alert.AlertShowInfo('Membro Cancelado com sucesso!');
          this.GetLista();
        },
        (error) => {
          this.alert.AlertShowDanger('Erro ao tentar Cancelar Membro!');
          this.GetLista();
        }
      );
    }

    if (this.opcao == "Confirmar") {
      this.service.ConfirmarPagamento(this.idusuario).subscribe(
        (success) => {
          this.alert.AlertShowInfo('Pagamento confirmado com sucesso!');
          this.GetLista();
        },
        (error) => {
          this.alert.AlertShowDanger('Erro ao tentar confirmar pagamento!');
          this.GetLista();
        }
      );
    }

    if (this.opcao == "Desconfirmar") {
      this.service.Desconfirmar(this.idusuario).subscribe(
        (success) => {
          this.alert.AlertShowInfo('Pagamento retirado com sucesso!');
          this.GetLista();
        },
        (error) => {
          this.alert.AlertShowDanger('Erro ao tentar retirar pagamento!');
          this.GetLista();
        }
      );
    }
  }



  SetDados(event) {
    this.idusuario = event.currentTarget.value;
  }

  SetOpcao(event) {
    this.opcao = event.currentTarget.value;
  }




}
