<!----->
<div class="col-md-12">
    <div class="row" style="padding-left: 50px; padding-top: 50px; padding-right: 50px;">
        <div class="card col-md" style="padding: 0%; margin: 0%; margin-top: 1px;">
            <a href="https://instagram.com/casaismoradadedeus/" target="_blank"><img src="../../assets/img/2023/Arte - Casais Morada de Deus.png" class="card-img-top "
                    height="287px" width="20px" alt="..."></a>
            <div class="card-body">
                <h6 class="card-title"><b>Rede de Casais Morada</b></h6>
                <label style="text-align: justify;">CASAIS MORADA: ato ou efeito de imergir; submersão.<br>
                    &nbsp;&nbsp;&nbsp;A Rede de Casais Morada está disponível para você, família, vir e desfrutar conosco de tudo o que
                    Deus tem pra nós.<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;Nos firmamos nos princípios da Palavra de Deus.<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;Esta Palavra do Senhor que é poderosa e transformadora. Que é a nossa bússola.<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;Temos uma equipe abençoada que nos ajuda a cuidar das famílias.<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;Temos nosso Culto da Família, que acontece todas as últimas terças feiras de cada mês as 19:30 hs na
                    nossa IEPMD.<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;Tem sido um momento poderoso do agir de Deus e de nos movermos enquanto Corpo de Cristo (FAMÍLIAS)
                    para guerrearmos pelas famílias da Terra.<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;Vocês são nossos convidados.<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;Estamos aqui por vocês e para vocês, cumprindo o " IDE " do Senhor.<br>
                    Um grande abraço.</label><br>
                <label><b>Responsáveis</b></label><br>
                <label>Pr. Alan / Pra. Rita</label>
                <br>
                <br>
                <div style="padding-top: 20px;">
                    <a pButton pRipple icon="pi pi-plus" type="button" label="Saiba Mais" class="p-button-rounded"
                        style="position: absolute; bottom: 20px;  " href="https://www.instagram.com/casaismoradadedeus/"
                        target="_blank"></a>
                </div>
            </div>
        </div>

        <div class="card col-md" style="padding: 0%; margin: 0%; margin-top: 1px;">
            <a href="https://www.instagram.com/mulheresmoradadedeus/" target="_blank"><img src="../../assets/img/novos/cards/rede_mulheres.jpeg"
                    class="card-img-top " height="287px" width="20px" alt="..."></a>
            <div class="card-body">
                <h6 class="card-title"><b>Rede Mulheres Morada</b></h6>
                <label style="text-align: justify;">&nbsp;&nbsp;&nbsp;&nbsp;Nós da Rede Mulheres Morada, temos
                    o propósito de trazer à existência as promessas de
                    Deus para nós que somos filhas, para você MULHER que deseja ter um relacionamento mais profundo em
                    Deus.</label>
                <label style="text-align: justify;">&nbsp;&nbsp;Em nossos cultos mensais e em atendimentos
                    particulares, ministramos cura, restauração e
                    libertação na vida de MULHERES que ao longo da caminhada desanimaram diante das dificuldades,
                    problemas, traumas, decepções, seja na vida familiar, profissional ou pessoal. Mulheres que perderam
                    a esperança em sua própria vida e se desviaram do propósito de Deus. MULHER, temos uma equipe de
                    braços abertos esperando por você! Nosso propósito é que vocês MULHERES conheçam sua identidade em
                    DEUS!<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;Esperamos por vocês todo segundo sábado de cada mês as 15:30Hs<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;Vocês são nossos convidados.<br>
                </label><br>
                <label><b>Responsáveis</b></label><br>
                <label>Pra. Lucilene</label>
                <br>
                <br>
                <div style="padding-top: 20px;">
                    <a pButton pRipple icon="pi pi-plus" type="button" label="Saiba Mais" class="p-button-rounded"
                        style="position: absolute; bottom: 20px;  " href="https://www.instagram.com/mulheresmoradadedeus/"
                        target="_blank"></a>
                </div>
            </div>
        </div>
        <br>
        <div class="card col-md" style="padding: 0%; margin: 0%; margin-top: 1px;">
            <a href="https://www.instagram.com/geracaomoradadedeus/" target="_blank"><img src="../../assets/img/juventude.jpeg"
                    class="card-img-top " height="287px" width="20px" alt="..."></a>
            <div class="card-body">
                <h6 class="card-title"><b>Geração Morada de Deus</b></h6>
                <label style="text-align: justify;">Visão:<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;O trabalho com os jovens da Geração Morada de Deus visa ser um instrumento nas mãos de Deus para que possamos ganhar almas e seu Reino cresça.
                    Escolhemos servir a Deus através da comunhão e da exposição da sua palavra, edificar e sermos edificados juntamente com o corpo de Cristo.</label>
                <label style="text-align: justify;">Missão:<br>
                    - Levar os jovens a um relacionamento verdadeiro e íntimo com Deus;<br>
                    - Despertar o interesse e satisfação na leitura da palavra;<br>
                    - Despertar o amor pelo evangelismo, cumprindo o ensino efetivo do IDE de Jesus;<br>
                    - Proporcionar comunhão e interação saudável entre os jovens;<br>
                    -Contribuir para o crescimento espiritual com ferramentas de apoio na vida futura do jovem;<br>
                    - Ajudar , aconselhar , orientar seguindo os ensinamentos  bíblicos;<br>
                    - Capacitar o jovem para servir no Corpo de Cristo;<br>
                    - Preparar o jovem para exercer funções de liderança futura.<br>
                </label>                    
                &nbsp;&nbsp;&nbsp;&nbsp;Acreditamos em uma Geração a serviço do Reino e cheia do poder de Deus.<br>
                    &nbsp;&nbsp;&nbsp;&nbsp;Esperamos por vocês todo sábado as 19:30Hs<br>
                <br>
                <label><b>Responsáveis</b></label><br>
                <label>Pr. Carlos / Obreira Daniely</label>
                <br>
                <br>
                <div style="padding-top: 20px;">
                    <a pButton pRipple icon="pi pi-plus" type="button" label="Saiba Mais" class="p-button-rounded"
                        style="position: absolute; bottom: 20px;  " href="https://www.instagram.com/geracaomoradadedeus/"
                        target="_blank"></a>
                </div>
            </div>
        </div>

    </div>

    <!--
    <div class="row" style="padding-left: 50px; padding-top: 5px; padding-right: 50px;">
        <div class="card col-md" style="padding: 0%; margin: 0%; margin-top: 1px;">
            <a routerLink="/desenvolvimento"><img src="../../assets/img/novos/Missão, Visão e Valores.png"
                    class="card-img-top " alt="..."></a>
            <div class="card-body">
                <h6 class="card-title">Rede de Homens</h6>
                <label>Rede formada pelos homens de nossa igreja com ministração especifica para esses guerreiros que o
                    Senhor tem levantado.</label>
                <br>
                <br>
                <div style="padding-top: 20px;">
                    <button pButton pRipple icon="pi pi-plus" type="button" label="Saiba Mais" class="p-button-rounded"
                        style="position: absolute; bottom: 20px; " routerLink="/desenvolvimento"></button>
                </div>
            </div>
        </div>

        <div class="card col-md" style="padding: 0%; margin: 0%; margin-top: 1px;">
            <a routerLink="/desenvolvimento"><img src="../../assets/img/novos/Missão, Visão e Valores.png"
                    class="card-img-top " alt="..."></a>
            <div class="card-body">
                <h6 class="card-title">Rede Jovem</h6>
                <label>Rede da nossa juventude com ministração para essa galera cheia de energia e muita
                    disposição!.</label>
                <br>
                <br>
                <div style="padding-top: 20px;">
                    <button pButton pRipple icon="pi pi-plus" type="button" label="Saiba Mais" class="p-button-rounded"
                        style="position: absolute; bottom: 20px;  " routerLink="/desenvolvimento"></button>
                </div>
            </div>
        </div>
    </div>
    -->







</div>

<div class="col-md" style="padding-top: 10px; padding-bottom: 10px; padding-left: 50px;">
    <button pButton pRipple type="button" icon="pi pi-home" class="p-button-rounded p-button-help" title="::HOME::"
        routerLink=""></button>
</div>