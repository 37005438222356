import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { ParametrosModel } from '../../configuracaoes/ParametrosModel';
import { patrimoniomodel } from './PatrimonioModel';
import { RegistrarPatrimonioService } from './registrar-patrimonio.service';

@Component({
  selector: 'app-registrar-patrimonio',
  templateUrl: './registrar-patrimonio.component.html',
  styleUrls: ['./registrar-patrimonio.component.css']
})
export class RegistrarPatrimonioComponent implements OnInit {

  formpatrimonio: FormGroup;
  submitted = false;
  cadastropatrimonio: patrimoniomodel;
  parametros: ParametrosModel;

  startedClass = false;
  completedClass = false;
  preventAbuse = false;
  rota: Router;
  modaltitulo: string;
  modalconteudo: string;



  tipo: any = [
    'ATIVO IMOBILIZADO',
    'ATIVO PERMANENTE',
    'ATIVOS TANGÍVEIS',
    'ATIVOS INTANGÍVEIS',
    'USO E CONSUMO'
  ];

  setor: any = [
    'SALÃO',
    'COZINHA',
    'SECRETARIA',
    'SOM/MIDIA',
    'USO E CONSUMO',
    'BANHEIRO',
    'EVENTOS',
    'EXTERNO',
    'GERAL',
    'OUTROS'

  ];



  constructor(
    private formBuilder: FormBuilder,
    private alert: AlertModalService,
    private service: RegistrarPatrimonioService,
    private router: Router,
    private autenticado: SharedModule,
  ) {
    this.rota = this.router;

  }

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//

  criarform() {
    this.formpatrimonio = this.formBuilder.group({
      patrimonio: ['', Validators.required],
      numero_patrimonio: ['', Validators.required],
      tipo_bem: ['', Validators.required],
      valor_estimado: ['', Validators.required],
      setor: ['', Validators.required],
      numero_serie: [''],
      codigo_extra: [''],
      modelo: [''],
      marca: [''],
      cor: [''],
      detalhes: ['']
    });
  }



  //BOTAO SALVAR
  //====================================================================//
  onSubmit() {
    this.submitted = true;

    if (this.formpatrimonio.invalid) {
      this.alert.AlertShowDanger('Favor verificar os campos em destaque!');
      return;
    } else {


      this.service.RegistrarPatrimonio(this.formpatrimonio.value, this.autenticado.token).subscribe(
        () => {
          this.alert.AlertShowSucess("Patrimonio Registrado com sucesso!");
          this.reset();
        },
        (error) => {
          this.alert.AlertShowDanger("Falha ao tentar Registrar Patrimonio!" + error.error.text);
          document.getElementById('patrimonio').focus();
        }
      )
    }
    //====================================================================//
  }
  //====================================================================//

  //recarregar Form
  //====================================================================//
  reset() {
    this.cadastropatrimonio = new patrimoniomodel();
    this.submitted = false;

    this.service.RecuperarIDPatrimonio(this.autenticado.token).subscribe(
      (sucess) => {

      },
      (error) => {
        this.cadastropatrimonio.numero_patrimonio = error.error.text;
        document.getElementById('patrimonio').focus();
      }
    );
  }

  // Pegar nome dos campos,
  //====================================================================//
  get f() {
    return this.formpatrimonio.controls;
  }

  //Inicio
  //====================================================================//
  ngOnInit() {
    if (this.autenticado.token == null) {
      this.autenticado.ResetDados();
      this.alert.AlertShowDanger('Usuário não autenticado, redirecionando...')
      this.rota.navigate(['/login']);
    } else {
      if (this.autenticado.ValidarToken() != true) {
        this.autenticado.ResetDados();
        this.alert.AlertShowDanger('Token Invalido!')
        this.rota.navigate(['/login']);
      } else {
        try {
          this.cadastropatrimonio = new patrimoniomodel();
          this.criarform();
          document.getElementById('patrimonio').focus();
          this.service.RecuperarIDPatrimonio(this.autenticado.token).subscribe(
            (sucess) => {

            },
            (error) => {
              this.cadastropatrimonio.numero_patrimonio = error.error.text;
              document.getElementById('patrimonio').focus();
            }
          );
        } catch {
          this.rota.navigate(['/dashboard/resumo']);
        }

      }
    }
  }
  //====================================================================//



}
