export class ReclamacaoModel {

    [x: string]: any;
    cpf: string = '';
    nome: string = '';
    email: string = '';
    telefone: string = '';
    tipo_reclamacao: string = '';
    mensagem: string = '';
    receber_copia: boolean = false;
    dt_ocorrido: Date = null;    
    idusuario:number;


}
