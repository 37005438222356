import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { retry, take } from 'rxjs/operators';
import { Parametros } from 'src/app/shared/parametros.enum';
import { patrimoniomodel } from './PatrimonioModel';

@Injectable({
  providedIn: 'root'
})
export class RegistrarPatrimonioService {
  private urlcadastro = Parametros.PATRIMONIO;
 
  constructor(private http: HttpClient) { }
  startedClass = false;
  completedClass = false;
  preventAbuse = false;

  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }

  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }


  RecuperarIDPatrimonio(token) {
    this.StartProgressBar();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization':"Bearer "+token.replace("\r\n", "").replace("\n", "")
      })
    };
    return this.http
      .get(this.urlcadastro + 'GETIDPatrimonio', httpOptions)
      .pipe(take(1));
  }

  RegistrarPatrimonio(model,token) {
    this.StartProgressBar();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization':"Bearer "+token.replace("\r\n", "").replace("\n", "")
      })
    };
    return this.http
      .post(this.urlcadastro + 'POSTCadastrarPatrimonio',model, httpOptions)
      .pipe(take(1));
  }
}

