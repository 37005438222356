import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { Parametros } from 'src/app/shared/parametros.enum';
import { SharedModule } from 'src/app/shared/shared.module';

@Component({
  selector: 'app-resumo-congresso-homens',
  templateUrl: './resumo-congresso-homens.component.html',
  styleUrls: ['./resumo-congresso-homens.component.css']
})
export class ResumoCongressoHomensComponent implements OnInit {

  

  private urlcadastro = Parametros.CONGRESSOHOMENS;


  filtro_cabecalho: string = ""
  explicacao: string = "";
  rota: any;
  perfil: any;
  tipo_culto: any;
  total_presentes: any;
  total_ausentes: any;
  total_registros: any;
  cultos: any;



  constructor(private http: HttpClient,
    private primengConfig: PrimeNGConfig,
    private alert: AlertModalService,
    private autenticado: SharedModule,
    r: Router
  ) {
    this.rota = r;
    this.perfil = this.autenticado.perfil;
  }

  startedClass = false;
  completedClass = false;
  preventAbuse = false;

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//

  ValorCulto(event) {
    this.tipo_culto = event.currentTarget.value;
  }


  GETPresentes() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get<any>(this.urlcadastro + 'GETQuantidadeRegistrosPresentes/?congresso=' + this.tipo_culto, httpOptions).subscribe(
      (success) => {
        this.total_presentes = success;
      },
      (error) => {

      }
    )
  }
  GETTotal() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get<any>(this.urlcadastro + 'GETQuantidadeRegistros/?congresso=' + this.tipo_culto, httpOptions).subscribe(
      (success) => {
        this.total_registros = success;
      },
      (error) => {

      }
    )
  }

  GETAusentes() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get<any>(this.urlcadastro + 'GETQuantidadeRegistrosAusentes/?congresso=' + this.tipo_culto, httpOptions).subscribe(
      (success) => {
        this.total_ausentes = success;
      },
      (error) => {

      }
    )
  }

  GETListagemCultos() {
    this.StartProgressBar();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token
      })
    };
    return this.http
      .get<any>(this.urlcadastro + 'GETListagemCongressoshomens', httpOptions).subscribe(
        (success) => {
          this.cultos = success;
         
          this.tipo_culto = success[0].congresso;
          
        },
        (error) => {

        }
      )
  }

  ngOnInit() {
    if (this.autenticado.token == null) {
      this.autenticado.ResetDados();
      this.alert.AlertShowDanger('Usuário não autenticado, redirecionando...')
      this.rota.navigate(['/login']);
    } else {
      if (this.autenticado.ValidarToken() != true) {
        this.autenticado.ResetDados();
        this.alert.AlertShowDanger('Token Invalido!')
        this.rota.navigate(['/login']);
      }
    }
    this.primengConfig.ripple = true;
    this.filtro_cabecalho = "Estatística por Congresso"
    this.explicacao = "Aqui você gera a estatística por congresso, basta selecionar o congresso e clicar na engrenagem abaixo.\nLembramos que a apresentado os totalizados conforme cadastro e checkout de entrada."
    this.GETListagemCultos();
  }

  Consultar() {
    this.GETAusentes();
    this.GETPresentes();
    this.GETTotal();
  }

}

