import { FullCalendarModule } from '@fullcalendar/angular';
import { RecuperarAutenticacaoComponent } from './Recuperacoes/recuperar-autenticacao/recuperar-autenticacao.component';

import { GuardChild } from './guards/auth.guard.child';
import { AuthGuard } from './guards/auth.guard';
import { LoginService } from './login/login.service';
import { CookieService } from 'ngx-cookie-service';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SharedModule } from './shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import {InputNumberModule} from 'primeng/inputnumber';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';
import { LoginComponent } from './login/login.component';
import { HttpClientModule } from '@angular/common/http';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { CadastroMembrosComponent } from './cadastro-membros/cadastro-membros.component';
import { HomeComponent } from './home/home.component';
import { NgProgressModule } from "ngx-progressbar";
import { NgProgressHttpModule } from "ngx-progressbar/http";
import { PaginasRetornoComponent } from './paginas-retorno/paginas-retorno.component';
import { DashboardHomeComponent } from './Dashboard/dashboard-home/dashboard-home.component';
import { FooterComponent } from './footer/footer.component';
import { MenuNavbarComponent } from './menu-navbar/menu-navbar.component';
import { ReportarProblemaComponent } from './reportar-problema/reportar-problema.component';
import { ContatoComponent } from './contato/contato.component';
import { FooterAutenticadoComponent } from './Dashboard/footer-autenticado/footer-autenticado.component';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { DataTablesModule } from 'angular-datatables';
import { ConfirmarCadastroComponent } from './confirmar-cadastro/confirmar-cadastro.component';
import { RegistrarElogioComponent } from './Dashboard/registrar-elogio/registrar-elogio.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { VisitanteComponent } from './visitante/visitante.component';
import { DesenvolvimentoComponent } from './desenvolvimento/desenvolvimento.component';
import { PedidoVisitaComponent } from './Dashboard/pedido-visita/pedido-visita.component';
import { HomeCadastrosComponent } from './home-cadastros/home-cadastros.component';
import { MinisteriosComponent } from './ministerios/ministerios.component';
import { MissaoVisaoValoresComponent } from './missao-visao-valores/missao-visao-valores.component';
import { PastoresComponent } from './pastores/pastores.component';
import { PrivacidadeComponent } from './privacidade/privacidade.component';
import { ReclamacaoComponent } from './Dashboard/reclamacao/reclamacao.component';
import { SugestoesComponent } from './Dashboard/sugestoes/sugestoes.component';
import { TestemunhoComponent } from './Dashboard/testemunho/testemunho.component';
import { PerfilComponent } from './Dashboard/perfil/perfil.component';
import { AlifeFileToBase64Module } from 'alife-file-to-base64';
import { TrocarSenhaComponent } from './Dashboard/trocar-senha/trocar-senha.component';
import { ComunicacaoComponent } from './Dashboard/comunicacao/comunicacao.component';
import { ResumoComponent } from './Dashboard/resumo/resumo.component';
import { PoliticaPrivacidadeComponent } from './Dashboard/politica-privacidade/politica-privacidade.component';
import { ConfiguracaoesComponent } from './Dashboard/configuracaoes/configuracaoes.component';
import { TermosComponent } from './Dashboard/termos/termos.component';
import { CancelarContaComponent } from './Dashboard/cancelar-conta/cancelar-conta.component';

import { AccordionModule } from 'primeng/accordion';
import { ButtonModule } from 'primeng/button';
import { TableModule } from "primeng/table"
import { PaginatorModule } from 'primeng/paginator';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InputTextModule } from 'primeng/inputtext';
import { ElogioListagemComponent } from './Dashboard/Listagem/elogio-listagem/elogio-listagem.component';
import { ElogioDetalheComponent } from './Dashboard/Detalhamento/elogio-detalhe/elogio-detalhe.component';
import { VisitaListagemComponent } from './Dashboard/Listagem/visita-listagem/visita-listagem.component';
import { VisitaDetalheComponent } from './Dashboard/Detalhamento/visita-detalhe/visita-detalhe.component';
import { RadioButtonModule } from 'primeng/radiobutton';
import { ReclamacaoListagemComponent } from './Dashboard/Listagem/reclamacao-listagem/reclamacao-listagem.component';
import { ReclamacaoDetalheComponent } from './Dashboard/Detalhamento/reclamacao-detalhe/reclamacao-detalhe.component';
import { SugestoesListagemComponent } from './Dashboard/Listagem/sugestoes-listagem/sugestoes-listagem.component';
import { SugestoesDetalheComponent } from './Dashboard/Detalhamento/sugestoes-detalhe/sugestoes-detalhe.component';
import { TestesmunhoListagemComponent } from './Dashboard/Listagem/testesmunho-listagem/testesmunho-listagem.component';
import { TestemunhoDetalheComponent } from './Dashboard/Detalhamento/testemunho-detalhe/testemunho-detalhe.component';
import { AcessosListagemComponent } from './Dashboard/Listagem/acessos-listagem/acessos-listagem.component';
import { AcessoDetalheComponent } from './Dashboard/Detalhamento/acesso-detalhe/acesso-detalhe.component';
import { NossosCliquesComponent } from './nossos-cliques/nossos-cliques.component';
import { NossaAgendaComponent } from './nossa-agenda/nossa-agenda.component';
import { CursosComponent } from './cursos/cursos.component';
import { SeminariosComponent } from './seminarios/seminarios-home/seminarios.component';
import { SeminarioCadastroComponent } from './seminarios/seminario-cadastro/seminario-cadastro.component';
import { ListagemParticipantesSeminarioComponent } from './Dashboard/Seminario/listagem-participantes-seminario/listagem-participantes-seminario.component';
import { DetalheUsuarioSeminarioComponent } from './Dashboard/Seminario/detalhe-usuario-seminario/detalhe-usuario-seminario.component';
import { ComunicadoSeminarioComponent } from './Dashboard/Seminario/comunicado-seminario/comunicado-seminario.component';
import { ListagemMembrosComponent } from './Dashboard/membros/listagem-membros/listagem-membros.component';
import { MembrosAtivosComponent } from './Dashboard/membros/membros-ativos/membros-ativos.component';
import { MembrosInativosComponent } from './Dashboard/membros/membros-inativos/membros-inativos.component';
import { MembrosAguardandoLiberacaoComponent } from './Dashboard/membros/membros-aguardando-liberacao/membros-aguardando-liberacao.component';
import { MembrosCanceladosComponent } from './Dashboard/membros/membros-cancelados/membros-cancelados.component';
import { DetalheListagemMembrosComponent } from './Dashboard/membros/detalhe-listagem-membros/detalhe-listagem-membros.component';
import { DetalheMembrosAtivosComponent } from './Dashboard/membros/detalhe-membros-ativos/detalhe-membros-ativos.component';
import { DetalheMembrosAguardandoLiberacaoComponent } from './Dashboard/membros/detalhe-membros-aguardando-liberacao/detalhe-membros-aguardando-liberacao.component';
import { DetalheMembrosCanceladosComponent } from './Dashboard/membros/detalhe-membros-cancelados/detalhe-membros-cancelados.component';
import { DetalheMembrosInativosComponent } from './Dashboard/membros/detalhe-membros-inativos/detalhe-membros-inativos.component';
import { ComunicadoMembrosComponent } from './Dashboard/membros/comunicado-membros/comunicado-membros.component';
import { NossaIgrejaComponent } from './nossa-igreja/nossa-igreja.component';
import { MembrosPerfilComponent } from './Dashboard/membros/membros-perfil/membros-perfil.component';
import { MembrosPerfilAjustesComponent } from './Dashboard/membros/membros-perfil-ajustes/membros-perfil-ajustes.component';
import { MembrosCursosAjustesComponent } from './Dashboard/membros/membros-cursos-ajustes/membros-cursos-ajustes.component';
import { MembrosMinisterioAjustesComponent } from './Dashboard/membros/membros-ministerio-ajustes/membros-ministerio-ajustes.component';
import { ListagemVisitantesComponent } from './Dashboard/visitantes/listagem-visitantes/listagem-visitantes.component';
import { VisitantesDetalheComponent } from './Dashboard/visitantes/visitantes-detalhe/visitantes-detalhe.component';
import { ComunicarVisitantesComponent } from './Dashboard/visitantes/comunicar-visitantes/comunicar-visitantes.component';
import { FieldsetModule } from 'primeng/fieldset';
import { CultoInscricaoComponent } from './culto-inscricao/culto-inscricao.component';
import { ListagemParticipantesCultoRegistradoComponent } from './Dashboard/culto_registrado/listagem-participantes-culto-registrado/listagem-participantes-culto-registrado.component';
import { ComunicadoCultoRegistradoComponent } from './Dashboard/culto_registrado/comunicado-culto-registrado/comunicado-culto-registrado.component';
import { DetalheUsuarioCultoRegistradoComponent } from './Dashboard/culto_registrado/detalhe-usuario-culto-registrado/detalhe-usuario-culto-registrado.component';
import { CultoKidsInscricaoComponent } from './culto-kids-inscricao/culto-kids-inscricao.component';
import { ListagemParticipantesCultoKidsRegistradoComponent } from './Dashboard/culto_kids_registrado/listagem-participantes-culto-kids-registrado/listagem-participantes-culto-kids-registrado.component';
import { DetalheUsuarioCultoKidsRegistradoComponent } from './Dashboard/culto_kids_registrado/detalhe-usuario-culto-kids-registrado/detalhe-usuario-culto-kids-registrado.component';
import { ComunicadoCultoKidsRegistradoComponent } from './Dashboard/culto_kids_registrado/comunicado-culto-kids-registrado/comunicado-culto-kids-registrado.component';
import { ResumoCultosComponent } from './Dashboard/culto_registrado/resumo-cultos/resumo-cultos.component';
import { ResumoCultosKidsComponent } from './Dashboard/culto_kids_registrado/resumo-cultos-kids/resumo-cultos-kids.component';
import { QrCodeComponent } from './qr-code/qr-code.component';
import { BarcodeScannerLivestreamModule } from "ngx-barcode-scanner";
import { CheckoutDocumentoComponent } from './Dashboard/culto_registrado/checkout-documento/checkout-documento.component';
import { CheckoutDocumentoSeminarioComponent } from './Dashboard/Seminario/checkout-documento-seminario/checkout-documento-seminario.component';
import { ResumoCadastroSeminarioComponent } from './Dashboard/Seminario/resumo-cadastro-seminario/resumo-cadastro-seminario.component';
import { ResumoSeminarioConfirmadosComponent } from './Dashboard/Seminario/resumo-seminario-confirmados/resumo-seminario-confirmados.component';
import { CadastroEstabelecimentoComponent } from './Dashboard/Estabelecimento/cadastro-estabelecimento/cadastro-estabelecimento.component';
import { TermoEstabelecimentoComponent } from './Dashboard/termo-estabelecimento/termo-estabelecimento.component';
import { ListagemEstabelecimentoComponent } from './Dashboard/Estabelecimento/listagem-estabelecimento/listagem-estabelecimento.component';
import { EditarEstabelecimentoComponent } from './Dashboard/Estabelecimento/editar-estabelecimento/editar-estabelecimento.component';
import { ListagemCompletaEstabelecimentoComponent } from './Dashboard/Estabelecimento/listagem-completa-estabelecimento/listagem-completa-estabelecimento.component';
import { ConsultaEstabelecimentoComponent } from './consulta-estabelecimento/consulta-estabelecimento.component';
import { EditarOportunidadeComponent } from './Dashboard/oportunidades/editar-oportunidade/editar-oportunidade.component';
import { ListagemOportunidadeComponent } from './Dashboard/oportunidades/listagem-oportunidade/listagem-oportunidade.component';
import { ListagemOportunidadeCompletaComponent } from './Dashboard/oportunidades/listagem-oportunidade-completa/listagem-oportunidade-completa.component';
import { CadastroOportunidadeComponent } from './Dashboard/oportunidades/cadastro-oportunidade/cadastro-oportunidade.component';
import { ListaConsultaOportunidadesComponent } from './Dashboard/oportunidades/lista-consulta-oportunidades/lista-consulta-oportunidades.component';
import { TermoOportunidadeComponent } from './Dashboard/termo-oportunidade/termo-oportunidade.component';
import { ListagemGeralOportunidadesComponent } from './Dashboard/oportunidades/listagem-geral-oportunidades/listagem-geral-oportunidades.component';
import { ListagemGeralEstabelecimentosComponent } from './Dashboard/Estabelecimento/listagem-geral-estabelecimentos/listagem-geral-estabelecimentos.component';
import { EncontroHomensComponent } from './encontro-homens/encontro-homens.component';
import { TermoEncontroHomensComponent } from './Dashboard/termo-encontro-homens/termo-encontro-homens.component';
import { CheckoutKidsDocumentoComponent } from './Dashboard/culto_kids_registrado/checkout-kids-documento/checkout-kids-documento.component';
import { SeminarioInfantilComponent } from './seminarios/seminario-infantil/seminario-infantil.component';
import { ResumoCadastroSeminarioInfantilComponent } from './Dashboard/Seminario/resumo-cadastro-seminario-infantil/resumo-cadastro-seminario-infantil.component';
import { ResumoSeminarioInfantilConfirmadosComponent } from './Dashboard/Seminario/resumo-seminario-infantil-confirmados/resumo-seminario-infantil-confirmados.component';
import { ListagemPasticipantesSeminarioInfantilComponent } from './Dashboard/Seminario/listagem-pasticipantes-seminario-infantil/listagem-pasticipantes-seminario-infantil.component';
import { CheckoutDocumentoSeminarioInfantilComponent } from './Dashboard/Seminario/checkout-documento-seminario-infantil/checkout-documento-seminario-infantil.component';
import { DetalheUsuarioSeminarioInfantilComponent } from './Dashboard/Seminario/detalhe-usuario-seminario-infantil/detalhe-usuario-seminario-infantil.component';
import { ComunicadoSeminarioInfantilComponent } from './Dashboard/Seminario/comunicado-seminario-infantil/comunicado-seminario-infantil.component';
import { EncontroMulheresComponent } from './encontro-mulheres/encontro-mulheres.component';
import { TermoEncontroMulheresComponent } from './Dashboard/termo-encontro-mulheres/termo-encontro-mulheres.component';
import { ListagemCadastrosEncontroMulheresComponent } from './Dashboard/Encontros/listagem-cadastros-encontro-mulheres/listagem-cadastros-encontro-mulheres.component';
import { DetalheCadastroEncontroMulheresComponent } from './Dashboard/Encontros/detalhe-cadastro-encontro-mulheres/detalhe-cadastro-encontro-mulheres.component';
import { DetalheCadastroEncontroHomensComponent } from './Dashboard/Encontros/detalhe-cadastro-encontro-homens/detalhe-cadastro-encontro-homens.component';
import { ListagemCadastrosEncontroHomensComponent } from './Dashboard/Encontros/listagem-cadastros-encontro-homens/listagem-cadastros-encontro-homens.component';
import { RegistrarPatrimonioComponent } from './Dashboard/patrimonio/registrar-patrimonio/registrar-patrimonio.component';
import { ResumoPatrimonioSetorComponent } from './Dashboard/patrimonio/resumo-patrimonio-setor/resumo-patrimonio-setor.component';
import { ListagemPatrimonioComponent } from './Dashboard/patrimonio/listagem-patrimonio/listagem-patrimonio.component';
import { DetalhePatrimonioComponent } from './Dashboard/patrimonio/detalhe-patrimonio/detalhe-patrimonio.component';
import { ListagemPatrimonioInativoComponent } from './Dashboard/patrimonio/listagem-patrimonio-inativo/listagem-patrimonio-inativo.component';
import { DetalhePatrimonioInativoComponent } from './Dashboard/patrimonio/detalhe-patrimonio-inativo/detalhe-patrimonio-inativo.component';
import { ConsultaQrcodeComponent } from './Dashboard/patrimonio/consulta-qrcode/consulta-qrcode.component';
import { PedidoCompraComponent } from './Dashboard/solicitacao-compras/pedido-compra/pedido-compra.component';
import { ListagemSolicitacaoComprasComponent } from './Dashboard/solicitacao-compras/listagem-solicitacao-compras/listagem-solicitacao-compras.component';
import { DetalheSollicitacaoCompraComponent } from './Dashboard/solicitacao-compras/detalhe-sollicitacao-compra/detalhe-sollicitacao-compra.component';
import { CadastroCasaisComponent } from './Dashboard/cursos-casais/cadastro-casais/cadastro-casais.component';
import { TermoCursoCasaisComponent } from './Dashboard/termo-curso-casais/termo-curso-casais.component';
import { ListagemCursoCasaisComponent } from './Dashboard/cursos-casais/listagem-curso-casais/listagem-curso-casais.component';
import { DetalheCursoCasaisComponent } from './Dashboard/cursos-casais/detalhe-curso-casais/detalhe-curso-casais.component';
import { CkeckoutCongressoMulheresComponent } from './Dashboard/congresso-mulheres/ckeckout-congresso-mulheres/ckeckout-congresso-mulheres.component';
import { CadastroCongressoMulheresComponent } from './Dashboard/congresso-mulheres/cadastro-congresso-mulheres/cadastro-congresso-mulheres.component';
import { TermoCongressoMulheresComponent } from './Dashboard/termo-congresso-mulheres/termo-congresso-mulheres.component';
import { ResumoCongressoMulheresComponent } from './Dashboard/congresso-mulheres/resumo-congresso-mulheres/resumo-congresso-mulheres.component';
import { ListagemParticipantesCongressoMulheresComponent } from './Dashboard/congresso-mulheres/listagem-participantes-congresso-mulheres/listagem-participantes-congresso-mulheres.component';
import { ComunicadoCongressoMulheresComponent } from './Dashboard/congresso-mulheres/comunicado-congresso-mulheres/comunicado-congresso-mulheres.component';
import { DetalheParticipanteCongressoMulheresComponent } from './Dashboard/congresso-mulheres/detalhe-participante-congresso-mulheres/detalhe-participante-congresso-mulheres.component';
import { RecuperarDadosComponent } from './Dashboard/congresso-mulheres/recuperar-dados/recuperar-dados.component';
import { CadastroBatismoComponent } from './Dashboard/batismo/cadastro-batismo/cadastro-batismo.component';
import { ListagemBatismoComponent } from './Dashboard/batismo/listagem-batismo/listagem-batismo.component';
import { CadastroCongressoHomensComponent } from './Dashboard/congresso-homens/cadastro-congresso-homens/cadastro-congresso-homens.component';
import { CheckoutCongressoHomensComponent } from './Dashboard/congresso-homens/checkout-congresso-homens/checkout-congresso-homens.component';
import { ComunicadoCongressoHomensComponent } from './Dashboard/congresso-homens/comunicado-congresso-homens/comunicado-congresso-homens.component';
import { DetalheParticipanteCongressoHomensComponent } from './Dashboard/congresso-homens/detalhe-participante-congresso-homens/detalhe-participante-congresso-homens.component';
import { ListagemParticipantesCongressoHomensComponent } from './Dashboard/congresso-homens/listagem-participantes-congresso-homens/listagem-participantes-congresso-homens.component';
import { ResumoCongressoHomensComponent } from './Dashboard/congresso-homens/resumo-congresso-homens/resumo-congresso-homens.component';
import { RecuperarDadosCongressoHomensComponent } from './Dashboard/congresso-homens/recuperar-dados-congresso-homens/recuperar-dados-congresso-homens.component';
import { TermoCongressoHomensComponent } from './Dashboard/termo-congresso-homens/termo-congresso-homens.component';
import { ArquivoFotosComponent } from './arquivo-fotos/arquivo-fotos.component';
import { CadastroCongressoJovensComponent } from './Dashboard/congresso-jovens/cadastro-congresso-jovens/cadastro-congresso-jovens.component';
import { CheckoutCongressoJovensComponent } from './Dashboard/congresso-jovens/checkout-congresso-jovens/checkout-congresso-jovens.component';
import { ComunicadoCongressoJovensComponent } from './Dashboard/congresso-jovens/comunicado-congresso-jovens/comunicado-congresso-jovens.component';
import { DetalheParticipanteCongressoJovensComponent } from './Dashboard/congresso-jovens/detalhe-participante-congresso-jovens/detalhe-participante-congresso-jovens.component';
import { ListagemParticipantesCongressoJovensComponent } from './Dashboard/congresso-jovens/listagem-participantes-congresso-jovens/listagem-participantes-congresso-jovens.component';
import { RecuperarDadosCongressoJovensComponent } from './Dashboard/congresso-jovens/recuperar-dados-congresso-jovens/recuperar-dados-congresso-jovens.component';
import { ResumoCongressoJovensComponent } from './Dashboard/congresso-jovens/resumo-congresso-jovens/resumo-congresso-jovens.component';
import { TermoCongressoJovensComponent } from './Dashboard/termo-congresso-jovens/termo-congresso-jovens.component';
import { VincularResponsavelComponent } from './Dashboard/Deducao_Compras/vincular-responsavel/vincular-responsavel.component';
import { ListagemTodasDeducoesComponent } from './Dashboard/Deducao_Compras/listagem-todas-deducoes/listagem-todas-deducoes.component';
import { ListagemDeducoesComprasComponent } from './Dashboard/Deducao_Compras/listagem-deducoes-compras/listagem-deducoes-compras.component';
import { RegistrarCompraComponent } from './Dashboard/Deducao_Compras/registrar-compra/registrar-compra.component';
import { TesteComponent } from './teste/teste.component';
import {RippleModule} from "primeng/ripple";
import { EncontroCasaisComponent } from './encontro-casais/encontro-casais.component';
import { TermoEncontroCasaisComponent } from './Dashboard/termo-encontro-casais/termo-encontro-casais.component';
import { ListagemCadastroEncontroCasaisComponent } from './Dashboard/Encontros/listagem-cadastro-encontro-casais/listagem-cadastro-encontro-casais.component';
import { DetalheCadastroEncontroCasaisComponent } from './Dashboard/Encontros/detalhe-cadastro-encontro-casais/detalhe-cadastro-encontro-casais.component';




const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    RecuperarAutenticacaoComponent,
    HomeComponent,
    PaginasRetornoComponent,
    DashboardHomeComponent,
    FooterComponent,
    MenuNavbarComponent,
    ReportarProblemaComponent,
    ContatoComponent,
    FooterAutenticadoComponent,
    DashboardHomeComponent,
    CadastroMembrosComponent,
    ConfirmarCadastroComponent,
    RegistrarElogioComponent,
    NotFoundComponent,
    VisitanteComponent,
    DesenvolvimentoComponent,
    PedidoVisitaComponent,
    HomeCadastrosComponent,
    MinisteriosComponent,
    MissaoVisaoValoresComponent,
    PastoresComponent,
    PrivacidadeComponent,
    ReclamacaoComponent,
    SugestoesComponent,
    TestemunhoComponent,
    PerfilComponent,
    TrocarSenhaComponent,
    ComunicacaoComponent,
    ResumoComponent,
    PoliticaPrivacidadeComponent,
    ConfiguracaoesComponent,
    TermosComponent,
    CancelarContaComponent,
    ElogioListagemComponent,
    ElogioDetalheComponent,
    VisitaListagemComponent,
    VisitaDetalheComponent,
    ReclamacaoListagemComponent,
    ReclamacaoDetalheComponent,
    SugestoesListagemComponent,
    SugestoesDetalheComponent,
    TestesmunhoListagemComponent,
    TestemunhoDetalheComponent,
    AcessosListagemComponent,
    AcessoDetalheComponent,
    NossosCliquesComponent,
    NossaAgendaComponent,
    CursosComponent,
    SeminariosComponent,
    SeminarioCadastroComponent,
    ListagemParticipantesSeminarioComponent,
    DetalheUsuarioSeminarioComponent,
    ComunicadoSeminarioComponent,
    ListagemMembrosComponent,
    MembrosAtivosComponent,
    MembrosInativosComponent,
    MembrosAguardandoLiberacaoComponent,
    MembrosCanceladosComponent,
    DetalheListagemMembrosComponent,
    DetalheMembrosAtivosComponent,
    DetalheMembrosAguardandoLiberacaoComponent,
    DetalheMembrosCanceladosComponent,
    DetalheMembrosInativosComponent,
    ComunicadoMembrosComponent,
    NossaIgrejaComponent,
    MembrosPerfilComponent,
    MembrosPerfilAjustesComponent,
    MembrosCursosAjustesComponent,
    MembrosMinisterioAjustesComponent,
    ListagemVisitantesComponent,
    VisitantesDetalheComponent,
    ComunicarVisitantesComponent,
    CultoInscricaoComponent,
    ListagemParticipantesCultoRegistradoComponent,
    ComunicadoCultoRegistradoComponent,
    DetalheUsuarioCultoRegistradoComponent,
    CultoKidsInscricaoComponent,
    ListagemParticipantesCultoKidsRegistradoComponent,
    DetalheUsuarioCultoKidsRegistradoComponent,
    ComunicadoCultoKidsRegistradoComponent,
    ResumoCultosComponent,
    ResumoCultosKidsComponent,
    QrCodeComponent,
    CheckoutDocumentoComponent,
    CheckoutDocumentoSeminarioComponent,
    ResumoCadastroSeminarioComponent,
    ResumoSeminarioConfirmadosComponent,
    CadastroEstabelecimentoComponent,
    TermoEstabelecimentoComponent,
    ListagemEstabelecimentoComponent,
    EditarEstabelecimentoComponent,
    ListagemCompletaEstabelecimentoComponent,
    ConsultaEstabelecimentoComponent,
    EditarOportunidadeComponent,
    ListagemOportunidadeComponent,
    ListagemOportunidadeCompletaComponent,
    CadastroOportunidadeComponent,
    ListaConsultaOportunidadesComponent,
    TermoOportunidadeComponent,
    ListagemGeralOportunidadesComponent,
    ListagemGeralEstabelecimentosComponent,
    EncontroHomensComponent,
    TermoEncontroHomensComponent,
    CheckoutKidsDocumentoComponent,
    SeminarioInfantilComponent,
    ResumoCadastroSeminarioInfantilComponent,
    ResumoSeminarioInfantilConfirmadosComponent,
    ListagemPasticipantesSeminarioInfantilComponent,
    CheckoutDocumentoSeminarioInfantilComponent,
    DetalheUsuarioSeminarioInfantilComponent,
    ComunicadoSeminarioInfantilComponent,
    EncontroMulheresComponent,
    TermoEncontroMulheresComponent,
    ListagemCadastrosEncontroMulheresComponent,
    DetalheCadastroEncontroMulheresComponent,
    ListagemCadastrosEncontroHomensComponent,
    DetalheCadastroEncontroHomensComponent,
    RegistrarPatrimonioComponent,
    ResumoPatrimonioSetorComponent,
    ListagemPatrimonioComponent,
    DetalhePatrimonioComponent,
    ListagemPatrimonioInativoComponent,
    DetalhePatrimonioInativoComponent,
    ConsultaQrcodeComponent,
    PedidoCompraComponent,
    ListagemSolicitacaoComprasComponent,
    DetalheSollicitacaoCompraComponent,
    CadastroCasaisComponent,
    TermoCursoCasaisComponent,
    ListagemCursoCasaisComponent,
    DetalheCursoCasaisComponent,
    CkeckoutCongressoMulheresComponent,
    CadastroCongressoMulheresComponent,
    TermoCongressoMulheresComponent,
    ResumoCongressoMulheresComponent,
    ListagemParticipantesCongressoMulheresComponent,
    ComunicadoCongressoMulheresComponent,
    DetalheParticipanteCongressoMulheresComponent,
    RecuperarDadosComponent,
    CadastroBatismoComponent,
    ListagemBatismoComponent,
    CadastroCongressoHomensComponent,
    CheckoutCongressoHomensComponent,
    ComunicadoCongressoHomensComponent,
    DetalheParticipanteCongressoHomensComponent,
    ListagemParticipantesCongressoHomensComponent,
    ResumoCongressoHomensComponent,
    RecuperarDadosCongressoHomensComponent,
    TermoCongressoHomensComponent,
    ArquivoFotosComponent,
    CadastroCongressoJovensComponent,
    CheckoutCongressoJovensComponent,
    ComunicadoCongressoJovensComponent,
    DetalheParticipanteCongressoJovensComponent,
    ListagemParticipantesCongressoJovensComponent,
    RecuperarDadosCongressoJovensComponent,
    ResumoCongressoJovensComponent,
    TermoCongressoJovensComponent,
    VincularResponsavelComponent,
    ListagemTodasDeducoesComponent,
    ListagemDeducoesComprasComponent,
    RegistrarCompraComponent,
    TesteComponent,
    EncontroCasaisComponent,
    TermoEncontroCasaisComponent,
    ListagemCadastroEncontroCasaisComponent,
    DetalheCadastroEncontroCasaisComponent





  ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        AccordionModule,
        BsDropdownModule.forRoot(),
        TooltipModule.forRoot(),
        ModalModule.forRoot(),
        SharedModule,
        ReactiveFormsModule,
        ProgressSpinnerModule,
        FormsModule,
        HttpClientModule,
        NgxMaskModule.forRoot(maskConfig),
        NgProgressModule.withConfig({
            spinnerPosition: "right",
            meteor: true,
            max: 100,
            min: 0,
            thick: true,
            speed: 200,

            color: "#00FF00"
        }),
        NgProgressHttpModule,
        DataTablesModule.forRoot(),
        AlifeFileToBase64Module,
        AccordionModule,
        ButtonModule,
        TableModule,
        PaginatorModule,
        BrowserAnimationsModule,
        InputTextModule,
        InputNumberModule,
        RadioButtonModule,
        FieldsetModule,
        BrowserModule,
        BrowserAnimationsModule,
        BarcodeScannerLivestreamModule,
        FullCalendarModule,
        RippleModule


    ],
  providers: [CookieService,
    LoginService,
    AuthGuard,
    GuardChild],
  bootstrap: [AppComponent],
  exports: [NgxDatatableModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
