<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>
<h5 class="espacamentotopo container">
    <i class="fas fa-user-edit" style="margin-left: 5px;"></i>Inscrição Conferência de Jovens
</h5>
<label class="j container">
    Seja bem-vindo(a)!<br />
    &nbsp;&nbsp;&nbsp;&nbsp;Você está na área de cadastro para a Conferência de Jovens da Rede Geração Morada de Deus da nossa
    igreja!<br>
    &nbsp;&nbsp;&nbsp;&nbsp;Ressaltamos que todos os dados aqui informados são tratados somente por nossa equipe de
    gestão não sendo compartilhados com terceiros.
</label>
<label class="j" style="margin-left: 45px; color: red; font-weight: bold;">
    ATENÇÃO!<br>
    - Informe um e-mail valido para receber o Comprovante de Inscrição.<br>
    - Analise seu email e caixa de spam para comprovar o recebimento.<br>
    - Caso não receba efetue novamente seu cadastro!.<br>
    - Não será permitida a entrada sem o devido cadastro e demais itens necessários!.<br>
    - Não será permitido cadastro no dia do congresso! efetue-o quanto antes!.<br>
    - No dia do congresso será necessário a apresentação dos seguintes itens (Documento com foto + 2KG de Arroz + 1KG Feijão).<br>
    - No dia se possível chegue mais cedo, válide sua entrada e evite imprevisto.<br>
    - Horário SÁBADO ( 15H ).<br>
</label>
<label class="j">
    &nbsp;&nbsp;&nbsp;&nbsp;Lembramos que os campos com (<strong class="cor"> *</strong> ) são campos de
    preenchimento obrigatório!<BR>
</label><br>
<label class="j"><a href="https://covid19.pjf.mg.gov.br/programa_jfpelavida.php"
        style="color: red; margin-left: 15px;"><b>Decretos JF PELA VIDA</b></a> (passível de mudança sem aviso
    prévio)</label><BR>

<hr class="espacamentotopo bold" />
<form [formGroup]="formculto" class="m" disabled="">
    <div class="row">

        <div class="col-md-2">
            <div class="dropdown">
                <label>Tipo Documento:</label><strong class="cor">*</strong>
                <div class="mb-3">
                    <select class="custom-select" type="text" name="tipo_culto" id="tipo_documento"
                        [(ngModel)]="cadastroculto.tipo_documento" formControlName="tipo_documento"
                        (change)="Tipo($event)" [ngClass]="{ 'is-invalid': submitted && f.tipo_documento.errors }">
                        <div *ngIf="submitted && f.tipo_documento.errors" class="invalid-feedback"></div>
                        <option value=""></option>
                        <option *ngFor="let documento of documentos" [ngValue]="documento">
                            {{ documento }}
                        </option>
                    </select>
                    <div *ngIf="submitted && f.tipo_documento.errors" class="invalid-feedback">
                        <div *ngIf="f.tipo_documento.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!---INCIO PRIMEIRA LINHA-->
        <div class="col-md-2">
            <div class="form-group">
                <label>CPF / RG:</label><strong class="cor">*</strong>
                <input type="text" class="form-control" id="cpf" placeholder="informe o CPF"
                    mask="000.000.000-00||00.000.000" name="cpf" maxlength="14" minlength="8" autocomplete="off"
                    formControlName="cpf" (blur)="consultacpf(cadastroculto.cpf)" [(ngModel)]="cadastroculto.cpf"
                    [ngClass]="{ 'is-invalid': submitted && f.cpf.errors }" />
                <div *ngIf="submitted && f.cpf.errors" class="invalid-feedback">
                    <div *ngIf="f.cpf.errors.required">Preenchimento obrigatório</div>
                </div>
            </div>
        </div>

        <div class="col-md-5">
            <div class="form-group">
                <label>Nome Completo:</label><strong class="cor">*</strong>
                <input type="text" class="form-control" id="nome" name="nome" [(ngModel)]="cadastroculto.nome"
                    placeholder="Informe Nome Completo" formControlName="nome" maxlength="500" minlength="3"
                    [(ngModel)]="cadastroculto.nome" autocomplete="off"
                    [ngClass]="{ 'is-invalid': submitted && f.nome.errors }" />
                <div *ngIf="submitted && f.nome.errors" class="invalid-feedback">
                    <div *ngIf="f.nome.errors.required">Preenchimento obrigatório</div>
                </div>
            </div>
        </div>

        <div class="col-md-3">
            <label>WhatsApp:</label><strong class="cor">*</strong>
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1"><i class="fas fa-phone"></i></span>
                </div>
                <input type="text" class="form-control" id="telefone" name="telefone"
                    [(ngModel)]="cadastroculto.telefone" placeholder="Informe o Telefone"
                    title="Formato (DD) 0000-0000 ou (DD) 00000-0000" formControlName="telefone" maxlength="500"
                    minlength="3" autocomplete="off" mask="(00) 0000-0000 || (00) 00000-0000" [ngClass]="{
                  'is-invalid': submitted && f.telefone.errors
                }" />
                <div *ngIf="submitted && f.telefone.errors" class="invalid-feedback">
                    <div *ngIf="f.telefone.errors.required">
                        Preenchimento obrigatório
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!---FIM PRIMEIRA LINHA-->

    <!---INICIO SEGUNDA LINHA-->
    <div class="row">
        <div class="col-md-5">
            <label>E-mail:</label><strong class="cor">*</strong>
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1"><i class="fas fa-at"></i></span>
                </div>
                <input type="email" class="form-control" id="email" placeholder="Informe E-mail" name="email"
                    formControlName="email" [(ngModel)]="cadastroculto.email" maxlength="500" minlength="3"
                    autocomplete="off" [ngClass]="{'is-invalid': submitted && f.email.errors}" />
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                    <div *ngIf="f.email.errors.required">
                        Preenchimento obrigatório
                    </div>
                </div>
            </div>

        </div>
        <div class="col-md-7">
            <div class="dropdown">
                <label>Congresso:</label><strong class="cor">*</strong>
                <div class="mb-3">
                    <select class="custom-select" type="text" name="congresso" id="congresso"
                        [(ngModel)]="cadastroculto.congresso" formControlName="congresso"
                        (blur)="quantidadecadastro(cadastroculto.congresso)"
                        [ngClass]="{ 'is-invalid': submitted && f.congresso.errors }">
                        <div *ngIf="submitted && f.congresso.errors" class="invalid-feedback"></div>
                        <option value=""></option>
                        <option *ngFor="let culto of congressos" [ngValue]="culto">
                            {{ culto }}
                        </option>
                    </select>
                    <div *ngIf="submitted && f.congresso.errors" class="invalid-feedback">
                        <div *ngIf="f.congresso.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <!---FIM SEGNDA LINHA-->
    <div class="row">
        <div class="col-md-2">
            <div class="dropdown">
                <label>Como ficou sabendo?:</label><strong class="cor">*</strong>
                <div class="mb-3">
                    <select class="custom-select" type="text" name="ficou_sabendo" id="ficou_sabendo"
                        [(ngModel)]="cadastroculto.ficou_sabendo" formControlName="ficou_sabendo"
                        [ngClass]="{ 'is-invalid': submitted && f.ficou_sabendo.errors }">
                        <div *ngIf="submitted && f.ficou_sabendo.errors" class="invalid-feedback"></div>
                        <option value=""></option>
                        <option *ngFor="let d of ficousabendo" [ngValue]="d">
                            {{ d }}
                        </option>
                    </select>
                    <div *ngIf="submitted && f.ficou_sabendo.errors" class="invalid-feedback">
                        <div *ngIf="f.ficou_sabendo.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="dropdown">
                <label>Congrega na Morada de Deus:</label><strong class="cor">*</strong>
                <div class="mb-3">
                    <select class="custom-select" type="text" name="ministerio_morada"
                        (change)="changeministeriomorada($event)" id="ministerio_morada"
                        [(ngModel)]="cadastroculto.ministerio_morada" formControlName="ministerio_morada"
                        [ngClass]="{ 'is-invalid': submitted && f.ministerio_morada.errors }">
                        <div *ngIf="submitted && f.ministerio_morada.errors" class="invalid-feedback"></div>
                        <option value=""></option>
                        <option *ngFor="let d of membromorada" [ngValue]="d">
                            {{ d }}
                        </option>
                    </select>
                    <div *ngIf="submitted && f.ministerio_morada.errors" class="invalid-feedback">
                        <div *ngIf="f.ministerio_morada.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-5">
            <label>Pertence Qual Ministerio?:</label><strong class="cor">*</strong>

            <div class="input-group mb-3" *ngIf="mostrar =='T' ">
                <div class="input-group-prepend">
                </div>
                <input type="text" class="form-control" id="ministerio_pertence"
                    placeholder="Informe Ministerio Pertence" name="ministerio_pertence"
                    formControlName="ministerio_pertence" [(ngModel)]="cadastroculto.ministerio_pertence"
                    maxlength="500" minlength="3" autocomplete="off" [ngClass]="{
                            'is-invalid': submitted && f.ministerio_pertence.errors
                          }" />
                <div *ngIf="submitted && f.ministerio_pertence.errors" class="invalid-feedback">
                    <div *ngIf="f.ministerio_pertence.errors.required">
                        Preenchimento obrigatório
                    </div>
                </div>
            </div>
            <div class="input-group mb-3" *ngIf="mostrar =='F' ">
                <div class="input-group-prepend">
                </div>
                <input type="text" class="form-control" id="ministerio_pertence" disabled
                    placeholder="Informe Ministerio Pertence" name="ministerio_pertence"
                    formControlName="ministerio_pertence" [(ngModel)]="cadastroculto.ministerio_pertence"
                    maxlength="500" minlength="3" autocomplete="off" [ngClass]="{
                            'is-invalid': submitted && f.ministerio_pertence.errors
                          }" />
                <div *ngIf="submitted && f.ministerio_pertence.errors" class="invalid-feedback">
                    <div *ngIf="f.ministerio_pertence.errors.required">
                        Preenchimento obrigatório
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-2">
            <div class="dropdown">
                <label>Eu Sou!:</label><strong class="cor">*</strong>
                <div class="mb-3">
                    <select class="custom-select" type="text" name="classificacao" id="classificacao"
                        [(ngModel)]="cadastroculto.classificacao" formControlName="classificacao"
                        [ngClass]="{ 'is-invalid': submitted && f.classificacao.errors }">
                        <div *ngIf="submitted && f.classificacao.errors" class="invalid-feedback"></div>
                        <option value=""></option>
                        <option *ngFor="let sou of classificacao" [ngValue]="sou">
                            {{ sou }}
                        </option>
                    </select>
                    <div *ngIf="submitted && f.classificacao.errors" class="invalid-feedback">
                        <div *ngIf="f.classificacao.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-2">
            <div class="form-group">
                <label>Cep:</label>
                <input type="text" class="form-control" placeholder="Informe o CEP" id="cep" mask="00.000-000"
                    name="cep" formControlName="cep" (blur)="consulta_cep(cadastroculto.cep)"
                    [(ngModel)]="cadastroculto.cep" maxlength="500" minlength="3" />
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label>Logradouro:</label><strong class="cor">*</strong>
                <input type="text" class="form-control" id="logradouro" placeholder="Informe Logradouro"
                    name="logradouro" formControlName="logradouro" [(ngModel)]="cadastroculto.logradouro"
                    maxlength="500" minlength="3" [ngClass]="{
                        'is-invalid': submitted && f.logradouro.errors
                      }" />
                <div *ngIf="submitted && f.logradouro.errors" class="invalid-feedback">
                    <div *ngIf="f.logradouro.errors.required">
                        Preenchimento obrigatório
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-2">
            <div class="form-group">
                <label>Numero:</label><strong class="cor">*</strong>
                <input type="number" class="form-control" id="numero" placeholder="Numero" name="numero"
                    formControlName="numero" [(ngModel)]="cadastroculto.numero" maxlength="9" [ngClass]="{
                        'is-invalid': submitted && f.numero.errors
                      }" />
                <div *ngIf="submitted && f.numero.errors" class="invalid-feedback">
                    <div *ngIf="f.numero.errors.required">
                        Preenchimento obrigatório
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-2">
            <div class="form-group">
                <label>apto:</label>
                <input type="number" class="form-control" id="apto" placeholder="apto" name="apto"
                    formControlName="apto" [(ngModel)]="cadastroculto.apto" maxlength="9" />
            </div>
        </div>
    </div>
    <!----->
    <!----->
    <div class="row">
        <div class="col-md-5">
            <div class="form-group">
                <label>Bairro:</label><strong class="cor">*</strong>
                <input type="text" class="form-control" id="bairro" placeholder="Informe o Bairro" name="bairro"
                    formControlName="bairro" [(ngModel)]="cadastroculto.bairro" maxlength="500" minlength="3" [ngClass]="{
                        'is-invalid': submitted && f.bairro.errors
                      }" />
                <div *ngIf="submitted && f.bairro.errors" class="invalid-feedback">
                    <div *ngIf="f.bairro.errors.required">
                        Preenchimento obrigatório
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-2">
            <div class="dropdown">
                <label>UF:</label><strong class="cor">*</strong>
                <div class="mb-3">
                    <select class="custom-select" type="text" name="uf" id="uf" [(ngModel)]="cadastroculto.uf"
                        formControlName="uf" [ngClass]="{ 'is-invalid': submitted && f.ministerio_morada.errors }">
                        <div *ngIf="submitted && f.uf.errors" class="invalid-feedback"></div>
                        <option value=""></option>
                        <option *ngFor="let dd of luf" [ngValue]="dd">
                            {{ dd }}
                        </option>
                    </select>
                    <div *ngIf="submitted && f.uf.errors" class="invalid-feedback">
                        <div *ngIf="f.uf.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-5">
            <div class="form-group">
                <label>Cidade:</label><strong class="cor">*</strong>
                <input type="text" class="form-control" id="cidade" placeholder="Informe sua cidade" name="cidade"
                    formControlName="cidade" [(ngModel)]="cadastroculto.cidade" maxlength="500" minlength="3" [ngClass]="{
                        'is-invalid': submitted && f.cidade.errors
                      }" />
                <div *ngIf="submitted && f.cidade.errors" class="invalid-feedback">
                    <div *ngIf="f.cidade.errors.required">
                        Preenchimento obrigatório
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md">
            <div class="form-group">
                <label style="padding: 0%;">Alguma dúvida?:</label><br> <span
                    style="font-size: small; padding: 0%; color: red;">
                    (
                    Favor preencher somente em caso de dúvidas )</span>
                <textarea type="text" rows="2" class="form-control" id="duvida" aria-describedby="emailHelp"
                    name="duvida" formControlName="duvida" [(ngModel)]="cadastroculto.duvida" autocomplete="off"
                    maxlength="500" minlength="3"></textarea>

                <span>{{ cadastroculto.duvida.length }}/500</span>
            </div>
        </div>
    </div>
    <!----->
    <!----->
    <div class="row" style="padding: 0%;">
        <div class="col-md" style="padding-top: 0%;">
            <div>
                <input type="checkbox" id="marked" data-md-icheck (change)="AceiteTermo($event)"
                    formControlName="marked" [(ngModel)]="marked" />
                <b>&nbsp;Aceito os Termos</b>.<strong class="cor">*</strong>
                <div *ngIf="submitted && f.marked.errors">
                    <div *ngIf="f.marked.errors.required" class="cor bold">
                        O aceite dos termos e obrigatório
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div>
        <br>
        <div >
        <button pButton pRipple type="button" icon="pi pi-save" class="p-button-rounded" style="margin-right: 5px;"
        style="margin: 5px;" title=":: SALVAR ::" label="Registrar" disabled (click)="onSubmit()"></button>
        <button pButton pRipple type="button" icon="pi pi-refresh" style="margin-right: 5px;"
        style="margin: 5px;" class="p-button-rounded p-button-secondary"  label="Limpar"  title="::LIMPAR::" (click)="reset()"></button>
        <button pButton pRipple type="button" icon="pi pi-home" style="margin-right: 5px;"
        style="margin: 5px;" class="p-button-rounded p-button-help" label="Retornar"  title="::HOME::" routerLink=""></button>
        <button pButton pRipple type="button" class="p-button-rounded p-button-warning" icon="pi pi-search"
        style="margin: 5px; color: white;" style="margin-right: 5px;" label="recuperar dados"  title="::Recuperar Dados::" routerLink="/recuperar-dados">
        </button>

        <a href="https://api.whatsapp.com/send?l=pt_BR&phone=55032988189440&text=Problemas Cadastro Congresso Mulheres"
        style="margin: 5px;" style="text-align: center; float: right;" title="Fale Conosco" target="_blank">Informar Problema!</a>
        </div>
    </div>

    <!-- Modal -->
    <button id="openModalButton" [hidden]="true" data-toggle="modal" data-target="#myModal"></button>
    <div class="modal fade bd-example-modal-lg" data-backdrop="static" data-keyboard="false" id="myModal" tabindex="-1"
        role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">
                        <i class="fas fa-info-circle" style="color: blue"></i>
                        {{ modaltitulo }}
                    </h5>
                </div>
                <div class="modal-body">
                    <div [innerHTML]="modalconteudo"></div>
                </div>
                <div class="modal-footer">
                    <div *ngIf="bt_termo == false">
                        <button pButton pRipple type="button" icon="pi pi-search" title="Recuperar Autenticação"
                            class="p-button-rounded" routerLink="/recuperar-autenticacao" data-dismiss="modal">
                        </button>
                        <button pButton pRipple type="button" icon="pi pi-pencil"
                            class="p-button-rounded p-button-danger" title="Reportar Problema" routerLink="/problema"
                            data-dismiss="modal">
                        </button>
                        <button pButton pRipple type="button" icon="pi pi-refresh" style="margin-right: 5px;"
                            class="p-button-rounded p-button-secondary" title="::LIMPAR::" (click)="reset()"
                            data-dismiss="modal"></button>
                    </div>
                    <div *ngIf="bt_termo == true">
                        <button pButton pRipple type="button" icon="pi pi-thumbs-up" title="CONCORDO"
                            class="p-button-rounded" data-dismiss="modal">
                        </button>
                        <button pButton pRipple type="button" icon="pi pi-thumbs-down"
                            class="p-button-rounded p-button-danger" title="DISCORDO" (click)="DiscordeTermo()"
                            data-dismiss="modal">
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>



</form>