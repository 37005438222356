import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, retry, take } from 'rxjs/operators';
import { cadastromembrosmodel } from '../../cadastro-membros/CadastroMembrosModel';
import { UsuarioModel } from 'src/app/Recuperacoes/recuperar-autenticacao/usuario-model';
import { Parametros } from 'src/app/shared/parametros.enum';
import { SharedModule } from 'src/app/shared/shared.module';
import { PerfilModel } from '././perfilmodel';

@Injectable({
  providedIn: 'root'
})
export class PerfilService {
  private urlcadastro = Parametros.CADASTROUSUARIO;
  private urlcorreios = Parametros.CORREIOS;

  constructor(private http: HttpClient,
    private autenticado:SharedModule) { }
  startedClass = false;
  completedClass = false;
  preventAbuse = false;

  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }

  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }



  ConsultaCEP(cep) {
    this.StartProgressBar();
    return this.http
      .get<cadastromembrosmodel>(
        this.urlcorreios +
        cep.replace('\\', '').replace('-', '').replace('.', '') + '/json/'
      )
      .pipe(take(1));
  }

  GetUsuaruio(hash) {
    this.StartProgressBar();
    return this.http
      .get<PerfilModel>(this.urlcadastro + 'GETRecuperarUsuarioHash/?hash=' + hash)
      .pipe(take(1));
  }

  GETUsuarioID(id) {
    this.StartProgressBar();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token
      })
    };
    return this.http
      .get<UsuarioModel>(this.urlcadastro + 'GETUsuarioID/?parametro=' + id,httpOptions)
      .pipe(take(1));
  }

  


  UpdateUsuario(c, token) {
    this.StartProgressBar();

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + token.replace("\r\n", "").replace("\n", "")
      })
    };
    return this.http
      .post(this.urlcadastro + 'POSTUpdateUsuario', c, httpOptions)
      .pipe(retry(2));
  }

  UploadFoto(fileToUpload, token, cpf) {
    this.StartProgressBar();

    const httpOptions = {
      headers: new HttpHeaders({
        'enctype': 'multipart/form-data',
        'Authorization': "Bearer " + token.replace("\r\n", "").replace("\n", "")
      })
    };
    let formData: FormData = new FormData();
    formData.append("arquivo", fileToUpload);

    return this.http
      .post(this.urlcadastro + 'Upload/?cpf=' + cpf, formData, httpOptions).pipe(map(x => true));
  }

  DownloadFoto(hash, token) {
    this.StartProgressBar();

    const httpOptions = {
      headers: new HttpHeaders({
       
        'Authorization': "Bearer " + token.replace("\r\n", "").replace("\n", ""),
        'responseType': 'blob' as 'json'

      })
    };


    return this.http
      .get<Blob>(this.urlcadastro + 'GETDownloadFoto/?hash=' + hash, httpOptions).pipe(take(2));
  }



}
