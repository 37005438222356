import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { CancelarContaService } from './cancelar-conta.service';
import { CancelarModel } from './cancelarmodel';

@Component({
  selector: 'app-cancelar-conta',
  templateUrl: './cancelar-conta.component.html',
  styleUrls: ['./cancelar-conta.component.css']
})
export class CancelarContaComponent implements OnInit {
  startedClass = false;
  completedClass = false;
  preventAbuse = false;
  rota: Router;
  forncancelamento: FormGroup;
  modelcancelar: CancelarModel;
  submitted = false;
  



  constructor(
    private formBuilder: FormBuilder,
    private alert: AlertModalService,
    private autenticado: SharedModule,
    private _service: CancelarContaService,
    private router: Router
  ) {
    this.rota = this.router;
    this.modelcancelar = new CancelarModel();
    this.modelcancelar.cpf = this.autenticado.cpf;

  }

  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }

  ngOnInit(): void {
    if (this.autenticado.token == null) {
      this.autenticado.ResetDados();
      this.alert.AlertShowDanger('Usuário não autenticado, redirecionando...')
      this.rota.navigate(['/login']);
    } else {
      if (this.autenticado.ValidarToken() != true) {
        this.autenticado.ResetDados();
        this.alert.AlertShowDanger('Token Invalido!')
        this.rota.navigate(['/login']);
      }
    }
    this.criarform();
    this.modelcancelar.opcao ='Excesso de envios de comunicados';
    
  }

  get f() {
    return this.forncancelamento.controls
  }

  criarform() {
    this.forncancelamento = this.formBuilder.group({
      opcao: [''],
      outros: [''],
      cpf:['']
    });
  }
  //====================================================================//
  onSubmit() {
    this.submitted = true;
    if (this.forncancelamento.invalid) {
      this.alert.AlertShowDanger('Favor verificar os campos em destaque!');
      return;
    }
    if (this.modelcancelar.opcao == "outros") {
      if (this.modelcancelar.outros == "") {
        this.alert.AlertShowDanger('O motivos deve ser preenchido');
        return;
      }
      this.modelcancelar.opcao = this.modelcancelar.outros;
     
    }
    this.modelcancelar.cpf = this.autenticado.cpf;

    this._service.CancelarUsuario(this.autenticado.token, this.modelcancelar).subscribe(
      (sucess) => {
        localStorage.setItem('tipo', 'cancelamento');
        this.rota.navigate(['/retorno']);
      },
      (error) => {
        this.alert.AlertShowDanger('Ocorreu um erro ao tentar cancelar!');
      }
    )
  }

  onItemChange(value) {
    this.modelcancelar.opcao = value;
  }
}
