import { take } from 'rxjs/operators';

import { UsuarioModel } from './usuario-model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Parametros } from 'src/app/shared/parametros.enum';
import { EventEmitterService } from 'src/app/shared/EventEmitterService';

@Injectable({
  providedIn: 'root'
})
export class RecuperarAutenticacaoService {


  private urlcadastro = Parametros.CADASTROUSUARIO;
  constructor(private http: HttpClient) {
    EventEmitterService.get('loginAlert').emit(false);
   }

  



//Metdo para fazer login e retornar dados
GetRecuperarAutenticacao(cpf) {
  return this.http.get<UsuarioModel>(this.urlcadastro+'GETRecuperarAutenticacao/?identificacao='+cpf).pipe(take(1));
}
}
