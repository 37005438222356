import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModelEncontroCasais } from './ModelEncontroCasais';
import { Router } from '@angular/router';
import { EncontroCasaisService } from './encontro-casais.service';
import { AlertModalService } from '../shared/alert-modal/alert-modal.service';
import { EventEmitterService } from '../shared/EventEmitterService';

@Component({
  selector: 'app-encontro-casais',
  templateUrl: './encontro-casais.component.html',
  styleUrls: ['./encontro-casais.component.css'],
})
export class EncontroCasaisComponent implements OnInit {
  formcadastro: FormGroup;
  submitted = false;
  cadastrocasais: ModelEncontroCasais;

  modaltitulo: string = '';
  tipo: string = '';
  modalconteudo: string = '';

  marked: boolean;
  bt_termo: boolean;
  quantidade_cadastro: number = 0;
  limite_cadastro: number = 0;
  sucesso = new EventEmitter();
  uniao: any = ['CASADO(A)', 'CONTRATO', 'AMASIADO'];

  tempo: any = [
    'MENOS DE 1 ANO',
    '1 ANO',
    '2 ANOS',
    '3 ANOS',
    '4 ANOS',
    '5 ANOS',
    '6 ANOS',
    '7 ANOS',
    '8 ANOS',
    '9 ANOS',
    '10 ANOS',
    'ENTRE 10 E 20 ANOS',
    'ENTRE 21 E 30 ANOS',
    'ENTRE 31 E 40 ANOS',
    'ENTRE 41 E 50 ANOS',
    'MAIS 51 ANOS',
  ];

  religiao: any = [
    'Evangélica',
    'Católica',
    'Espírita',
    'Umbanda',
    'Judaica',
    'Ateu',
    'Sem Religião',
    'Outra',
  ];

  quantidade: any = [
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    'MAIS DE 10',
  ];

  membromorada: any = ['SIM', 'NÃO'];

  batismo: any = ['SIM', 'NÃO'];

  filhos: any = ['SIM', 'NÃO'];

  dias: any = ['SIM', 'NÃO'];

  luf: any = [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MT',
    'MS',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO',
  ];
  startedClass = false;
  completedClass = false;
  preventAbuse = false;
  rota: Router;

  constructor(
    private formBuilder: FormBuilder,
    private service: EncontroCasaisService,
    private alert: AlertModalService,
    private router: Router
  ) {
    this.rota = this.router;

    EventEmitterService.get('loginAlert').emit(false);
    service.GEtQuantidade().subscribe((success) => {
      this.quantidade_cadastro = success;
    });
    service.GETLimite().subscribe((success) => {
      this.limite_cadastro = success;
      if (this.quantidade_cadastro >= this.limite_cadastro) {
        this.modaltitulo = 'Cadastro Suspenso!';
        this.modalconteudo =
          'Olá!<br>' +
          'Informamos que este cadastro no momento encontra-se desativado!<br>' +
          'Em caso de duvidas por favor procure o <b>Pr.Alan</b> ou <b>Pra.Rita</b> para maiores esclarecimentos<br>' +
          'Gratos pela compreensão!<br>' +
          'Atenciosamente!<br><b>Gestão Morada de Deus ( IEPMD )</b>';
        document.getElementById('openModalButtons').click();
        document.getElementById('cpf').focus();
      }
    });


  }

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//

  //seta os campos para inicio do form
  //====================================================================//
  criarform() {
    this.formcadastro = this.formBuilder.group({
      marked: ['', Validators.required],
      tempo_casamento: ['', Validators.required],
      tipo_uniao: ['', Validators.required],
      cpf_esposa: ['', Validators.required],
      nome_esposa: ['', Validators.required],
      dt_nascimento_esposa: ['', Validators.required],
      telefone_esposa: ['', Validators.required],
      profissao_esposa: [''],
      email_esposa: [''],

      esposa_medicacao: [''],
      esposa_restricao_alimentar: [''],
      esposa_restricao_acucar: [''],
      esposo_medicacao: [''],
      esposo_restricao_alimentar: [''],
      esposo_restricao_acucar: [''],
      cargo_ministerial: [''],
      contato_urgencia: ['', Validators.required],
      telefone_urgencia: ['', Validators.required],

      religiao_esposa: ['', Validators.required],
      cpf_esposo: ['', Validators.required],
      nome_esposo: ['', Validators.required],
      dt_nascimento_esposo: ['', Validators.required],
      telefone_esposo: ['', Validators.required],
      profissao_esposo: [''],
      email_esposo: [''],
      religiao_esposo: ['', Validators.required],

      filhos: ['0', Validators.required],
      quantidade_filhos: [''],
      quantidade_enteados: [''],

      pertence_alguma_igreja: ['', Validators.required],
      igreja_pertence: [''],
      informacao_cadastro: [''],
      responsavel_igreja: [''],
      cep: [''],
      logradouro: ['', Validators.required],
      numero: [
        '',
        [Validators.required, Validators.min(0), Validators.max(999999999)],
      ],
      apto: [''],
      bairro: ['', Validators.required],
      uf: ['', Validators.required],
      cidade: ['', Validators.required],
    });

  }
  //====================================================================//

  //Caso discord do termo no modal
  //====================================================================//
  DiscordeTermo() {
    if (this.marked == true) {
      this.marked = false;
    }
  }
  //====================================================================//

  //Abrir modal quando clicar no aceite para exibir os termos
  //====================================================================//
  AceiteTermo(e) {
    if (e.target.checked == true) {
      this.marked = e.target.checked;
      this.service.GetTermo('encontro-casais').subscribe(
        (success) => {
          this.StopProgressBar();
          this.modalconteudo = success.termo;
          this.bt_termo = true;
          this.modaltitulo = 'Termo Encontro de Casais';
          document.getElementById('openModalButton').click();
        },
        (error) => {
          this.StopProgressBar();
          if (error.status == '400') {
            this.alert.AlertShowDanger(
              'Termo não definido!,solicitamos que não continue e avise a gestão do site, OBS: Continuando confirma está de acordo com toda politica do site!'
            );
          } else {
            this.alert.AlertShowDanger(
              'Termo não definido!,solicitamos que não continue e avise a gestão do site, OBS: Continuando confirma está de acordo com toda politica do site!'
            );
          }
        }
      );
    }
  }
  //====================================================================//

  /*/pegar tipo de documento para habilitar campo
  Tipo(event: any) {
    this.tipo = event.target.value.replace("1: ", "").replace("2: ", "").replace(" ", "");
    this.formcadastro.controls['cpf'].setValue("");

    if (this.formcadastro.controls['tipo_documento'].value != "") {
      this.formcadastro.controls['cpf'].enable();
      document.getElementById('cpf').focus();
    } else {
      this.formcadastro.controls['cpf'].disable();
      document.getElementById('tipo_documento').focus();
    }
  }
  */

  //recarregar Form
  //====================================================================//
  reset() {
    this.cadastrocasais = new ModelEncontroCasais();
    this.submitted = false;
  }

  //====================================================================//
  //PEGAR VALOR UF FORM
  //====================================================================//
  get cityNames() {
    return this.formcadastro.get('uf');
  }
  changeCity(e) {
    console.log(e.value);
    this.cityNames.setValue(e.target.value, {
      onlySelf: true,
    });
  }

  //controle membro morada
  get citymostrarfilhos() {
    return this.formcadastro.get('filhos');
  }
  changemostrarfilhos(e) {
    console.log(e.name);
    this.citymostrarfilhos.setValue(e.target.value, {
      onlySelf: true,
    });

    if (e.target.value.includes('SIM')) {
      this.formcadastro.controls['quantidade_filhos'].enable();
      this.formcadastro.controls['quantidade_enteados'].enable();
      this.cadastrocasais.quantidade_enteados = '0';
      this.cadastrocasais.quantidade_filhos = '0';
    } else {
      this.formcadastro.controls['quantidade_filhos'].disable();
      this.formcadastro.controls['quantidade_enteados'].disable();
      this.cadastrocasais.quantidade_enteados = '0';
      this.cadastrocasais.quantidade_filhos = '0';
    }
  }

  get citymostrarcongregacao() {
    return this.formcadastro.get('pertence_alguma_igreja');
  }
  changemostrarcongregacao(e) {
    console.log(e.name);
    this.citymostrarfilhos.setValue(e.target.value, {
      onlySelf: true,
    });

    if (e.target.value.includes('SIM')) {
      this.formcadastro.controls['igreja_pertence'].enable();
      this.formcadastro.controls['responsavel_igreja'].enable();
      this.formcadastro.controls['cargo_ministerial'].enable();
      this.cadastrocasais.igreja_pertence = '';
      this.cadastrocasais.cargo_ministerial = '';
      this.cadastrocasais.responsavel_igreja = '';
    } else {
      this.formcadastro.controls['igreja_pertence'].disable();
      this.formcadastro.controls['responsavel_igreja'].disable();
      this.formcadastro.controls['cargo_ministerial'].disable();
      this.cadastrocasais.igreja_pertence = '';
      this.cadastrocasais.cargo_ministerial = '';
      this.cadastrocasais.responsavel_igreja = '';
    }
  }

  //Inicio
  //====================================================================//
  ngOnInit() {
    this.cadastrocasais = new ModelEncontroCasais();
    this.criarform();
    document.getElementById('cpf_esposa').focus();
  }

  //====================================================================//

  //Consulta o cadastro do condominio já existe na base de dados ( API )
  //====================================================================//
  consultacpf(cpf: any) {
    this.service.Consulta(cpf).subscribe(
      (success) => {
        this.StopProgressBar();
      },
      (error) => {
        this.StopProgressBar();
        if (error.status == '400') {
          this.cadastrocasais = new ModelEncontroCasais();
          this.bt_termo = false;
          this.modaltitulo = 'Cadastro já Existente!';
          this.modalconteudo =
            'Olá Casal! <b>' +
            error.error +
            '</b>,<br>Estava olhando aqui em minhas anotações e verifiquei que já existe seu cadastro em nossa plataforma!, Pedimos por favor que aguarde o contato de uns dos organizadores!<br>Deus Abençõe!<br><b>Att.<br>Morada de Deus</b>';
          document.getElementById('openModalButton').click();
        } else {
        }
      }
    );
  }
  //====================================================================//

  //CONSULTA CEP API
  //====================================================================//
  consulta_cep(cep: any) {
    if (cep != '' && cep != null) {
      this.service
        .ConsultaCEP(
          cep
            .replace('.', '')
            .replace('-', '')
            .replace('\\', '')
            .replace('/', '')
        )
        .subscribe(
          (success) => {
            if (success.cep != null) {
              (this.cadastrocasais.cep = success.cep),
                (this.cadastrocasais.logradouro = success.logradouro),
                (this.cadastrocasais.bairro = success.bairro),
                (this.cadastrocasais.cidade = success.localidade),
                (this.cadastrocasais.uf = success.uf);
              document.getElementById('numero').focus();
            } else {
              this.alert.AlertShowDanger('CEP não e válido!');
              this.cadastrocasais.cep = '';
            }
          },
          (error) => {
            this.StopProgressBar();
            this.alert.AlertShowDanger(
              '( ' + error.status + ' ) ' + error.message
            );
          }
        );
    }
  }
  //====================================================================//

  // Pegar nome dos campos,
  //====================================================================//
  get f() {
    return this.formcadastro.controls;
  }
  //====================================================================//

  //BOTAO SALVAR
  //====================================================================//
  onSubmit() {
    this.submitted = true;
    if (this.formcadastro.invalid) {
      this.alert.AlertShowDanger('Favor verificar os campos em destaque!');
      return;
    }

    if (
      this.formcadastro.controls['pertence_alguma_igreja'].value.includes(
        'SIM'
      ) &&
      this.formcadastro.controls['igreja_pertence'].value == ''
    ) {
      this.alert.AlertShowDanger(
        'Favor Preencher o nome da congregação que pertence!'
      );
      return;
    }

    if (
      this.formcadastro.controls['pertence_alguma_igreja'].value.includes(
        'SIM'
      ) &&
      this.formcadastro.controls['responsavel_igreja'].value == ''
    ) {
      this.alert.AlertShowDanger(
        'Favor Preencher o nome do responsável pela congregação!'
      );
      return;
    }

    if (this.formcadastro.controls['filhos'].value == '') {
      this.alert.AlertShowDanger(
        'Favor ajustar se possui filhos!')
      return;
    } else {
    }


    if (this.formcadastro.controls['filhos'].value == '1: SIM') {
      if (
        this.formcadastro.controls['quantidade_filhos'].value == '0' &&
        this.formcadastro.controls['quantidade_enteados'].value == '0'
      ) {
        this.alert.AlertShowDanger(
          'Favor ajustar quantidade de filhos / Enteados!'
        );
        return;
      } else {
      }
    }

    this.service.CadastroCasal(this.formcadastro.value).subscribe(
      (sucess) => {
        this.StopProgressBar();
        this.alert.AlertShowSucess('Cadastro salvo');
        localStorage.setItem('tipo', 'encontro-casais');
        localStorage.setItem(
          'encontro-casais-save',
          JSON.stringify(this.formcadastro.value)
        );
        this.rota.navigate(['/retorno']);
      },
      (error) => {
        this.StopProgressBar();
        this.alert.AlertShowDanger('( ' + error.status + ' ) ' + error.error);
      }
    );
    //====================================================================//
  }
  //====================================================================//
}
