<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>
<div>
    <div class="col-md-12">
        <div class="row" style="margin-right: 2px; padding-left: 20px;">
            <img src="../../../assets/img/senha.png" height="150px" width="150px" style="padding: 0%;" />

            <div class="col-md-10">
                <h4 class="espacamentotopo " style="margin-left: 5px;">
                    Alteração de Senha
                </h4>

                <label>
                    &nbsp;&nbsp;&nbsp;&nbsp;Você está preste a trocar sua senha de acesso ao portal!.
                    <br>
                    &nbsp;&nbsp;&nbsp;&nbsp;Tome cuidado ao escolher a senha para que não perca acesso ao portal,
                    Ressaltamos que não temos
                    acesso a sua
                    senha onde a mesma e guardada com Criptografia de ponta.<br>
                </label>
                <label style="text-justify: auto;">
                    &nbsp;&nbsp;&nbsp;&nbsp;Lembramos que os campos com ( <strong class="cor"> *</strong> ) são campos
                    de
                    preenchimento obrigatório!
                </label>
            </div>
        </div>
    </div>


    <hr class="espacamentotopo bold" />
    <form [formGroup]="formtrocasenha" class="m" disabled="">
        <div class="row">
            <!---INCIO PRIMEIRA LINHA-->
            <div class="col-md-2">
                <div class="form-group">
                    <label>CPF:</label><strong class="cor">*</strong>
                    <input type="text" disabled="true" class="form-control" id="cpf" placeholder="informe o CPF"
                        mask="CPF_CNPJ" name="cpf" maxlength="14" autocomplete="off" formControlName="cpf"
                        [(ngModel)]="modelsenha.cpf" [ngClass]="{ 'is-invalid': submitted && f.cpf.errors }" />
                    <div *ngIf="submitted && f.cpf.errors" class="invalid-feedback">
                        <div *ngIf="f.cpf.errors.required">Preenchimento obrigatório</div>
                    </div>
                </div>
            </div>

            <div class="col-md-10">
                <div class="form-group">
                    <label>Nome Completo:</label><strong class="cor">*</strong>
                    <input type="text" disabled="true" class="form-control" id="nome" name="nome"
                        [(ngModel)]="modelsenha.nome" placeholder="Informe Nome Completo" formControlName="nome"
                        maxlength="500" minlength="3" autocomplete="off"
                        [ngClass]="{ 'is-invalid': submitted && f.nome.errors }" />
                    <div *ngIf="submitted && f.nome.errors" class="invalid-feedback">
                        <div *ngIf="f.nome.errors.required">Preenchimento obrigatório</div>
                    </div>
                </div>
            </div>

        </div>
        <div class="row">
            <div class="col-md-4">
                <div class="form-group">
                    <label>Senha Atual:</label><strong class="cor">*</strong>
                    <input type="text" class="form-control" id="senha" aria-describedby="emailHelp" name="senha"
                        formControlName="senha" [(ngModel)]="modelsenha.senha" autocomplete="off" minlength="4"
                        [ngClass]="{ 'is-invalid': submitted && f.senha.errors }">

                    <div *ngIf="submitted && f.senha.errors" class="invalid-feedback">
                        <div *ngIf="f.senha.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label>Nova Senha:</label><strong class="cor">*</strong>
                    <input type="text" class="form-control" id="senha_nova" aria-describedby="emailHelp"
                        name="senha_nova" formControlName="senha_nova" [(ngModel)]="modelsenha.senha_nova"
                        autocomplete="off" minlength="4" [ngClass]="{ 'is-invalid': submitted && f.senha_nova.errors }">

                    <div *ngIf="submitted && f.senha_nova.errors" class="invalid-feedback">
                        <div *ngIf="f.senha_nova.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label>Confirmar Senha:</label><strong class="cor">*</strong>
                    <input type="text" class="form-control" id="senha_nova_confirmar" aria-describedby="emailHelp"
                        name="senha_nova_confirmar" formControlName="senha_nova_confirmar"
                        [(ngModel)]="modelsenha.senha_nova_confirmar" autocomplete="off" minlength="4"
                        [ngClass]="{ 'is-invalid': submitted && f.senha_nova_confirmar.errors }">

                    <div *ngIf="submitted && f.senha_nova_confirmar.errors" class="invalid-feedback">
                        <div *ngIf="f.senha_nova_confirmar.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>

            </div>
        </div>


        <!----->
        <hr>
        <br>
        <!----->
        <div style="margin-bottom: 30px; margin-top: 10px;">
            <button pButton pRipple type="button" icon="pi pi-save" class="p-button-rounded" style="margin-right: 5px;"
                title=":: Atualizar ::" (click)="onSubmit()"></button>
            <button pButton pRipple type="button" icon="pi pi-sign-in" class="p-button-rounded p-button-danger"
                title="::Fechar::" routerLink="/dashboard/resumo"></button>
        </div>
    </form>
</div>
<button class="voltar-ao-topo" id="back"><a class="ancora" style="color: white;"><i
            class="pi pi-arrow-circle-up"></i></a></button>