<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>
<div class="col-md-12" style="margin-top: 7px;">

    <div>
        <h4 style="text-align: center;">
            <i class="fas fa-check"></i>&nbsp;<b>{{filtro_cabecalho}}</b>
        </h4>
        <p>
            &nbsp;&nbsp;&nbsp;&nbsp;{{explicacao}}
            <br>
            &nbsp;&nbsp;&nbsp;&nbsp;<b>#FicaaDica: </b>Em caso de Celular melhor visualizado na horizontal!
        </p>
    </div>
</div>
<br>

<div style="margin-left: 10px;margin-right: 10px;">


    <!--Filtro-->
    <div class="col-md" style="display: block; border: 0px;padding-bottom: 0%; ">
        <label style=" display: block; border: 0px; padding-bottom: 0%;"><b
                style="padding: 0%; padding-bottom: 0%;">Filtro:</b>
            <span style=" display: block; border: 0px; font-size: x-small; padding-top: 0%;">Consulta por Empresa /
                Oportunidade / Tipo</span>
            <div class="row">
                <div class="col-md" style="padding: 0%;">
                    <input type="text" class="form-control" id="texto_consulta" style="margin-top: 0%;" />

                </div>
                <div class="col-md">
                    <button pButton pRipple type="button" icon="fas fa-search" class="p-button-rounded"
                        style="background-color: green;" styleClass="rounded-button ui-button-danger"
                        (click)="Consulta()" style="margin-right: 20px;" title="Consulta Empresa / Serviço"></button>
                </div>
            </div>

        </label>

    </div>
    <label><b>Atenção: </b>Abaixo e apresentado as 5 oportunidades com maior consulta no momento, mas não deixe de
        efetuar a consulta acima com a oportunidade que esteja buscando!</label>


    <p-table [value]="oportunidade" [columns]="cols" dataKey="oportunidade" [resizableColumns]="true" autoLayout="true"
        styleClass="yourTableClass" selectionMode="single" [showCurrentPageReport]="true"
        styleClass="p-datatable-responsive-demo" [responsive]="true">
        <ng-template pTemplate="header" let-oportunidade let-columns>
            <tr>
                <th *ngFor="let col of columns">
                    {{col.header}}
                </th>
               
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-oportunidade let-rowData let-columns="columns" let-expanded="expanded">
            <tr>

                <td>
                    <span class="p-column-title">Oportunidade</span>
                    <img src="../../assets/img/novos/prestacao.png"
                        style="width: 40px; height: 40px; margin-right: 10px;"> {{oportunidade.oportunidade}}

                </td>
                <td>
                    <span class="p-column-title">Contato</span>
                    {{oportunidade.telefone}}

                </td>
                <td>
                    <span class="p-column-title">Horário</span>
                    {{oportunidade.funcionamento}}

                </td>
                <td>
                    <span class="p-column-title">Sexo</span>
                    {{oportunidade.sexo}}

                </td>
                <td>
                    <span class="p-column-title">Visualizações</span>
                    {{oportunidade.cliques}}

                </td>
                <td>
                    <span class="p-column-title">Detalhes</span>
                    <button type="button" pButton pRipple [pRowToggler]="oportunidade" pButton pRipple type="button"
                        icon="pi pi-plus" class="p-button-rounded" style="background-color: green;"
                        styleClass="rounded-button ui-button-success" [icon]="expanded ? 'pi pi-minus':'pi pi-plus' "
                        (click)="UpdateClique(oportunidade.idoportunidade)"></button>
                </td>

            </tr>

        </ng-template>

        <ng-template pTemplate="rowexpansion" let-oportunidade>
            <td colspan="7">
                <div style=" background-color: rgb(188, 211, 245);" class="col-md">
                    <img src="../../assets/img/novos/prestacao.png"
                        style="width: 15px; height: 15px; margin-left: 50px;"><b>
                        Detalhes de [ {{oportunidade.oportunidade}} ]</b>
                    <br>
                    <br>
                    <div style="margin-left: 50px;">
                        <label><i class="fas fa-phone-square-alt"></i><b> Telefone:
                            </b>{{oportunidade.telefone}}</label><br>
                        <div>
                            <ng-template [ngIf]="oportunidade.celular === null" [ngIfElse]="elseBlock">
                            </ng-template>
                            <ng-template #elseBlock>
                                <label><i class="fab fa-whatsapp"></i><b> WhatsApp: </b>{{oportunidade.celular}}<a
                                        href="https://api.whatsapp.com/send?l=pt_BR&phone=550+{{oportunidade.celular}}"
                                        target="_blank"> Conversar</a></label><br>
                            </ng-template>
                        </div>
                        <div>
                            <ng-template [ngIf]="oportunidade.email === null" [ngIfElse]="elseBlock5">
                            </ng-template>
                            <ng-template #elseBlock5>
                                <label><i class="fas fa-at"></i><b> E-mail: </b>{{oportunidade.email}}</label><br>
                            </ng-template>
                        </div>

                        <label><i class="fas fa-info-circle"></i> <b> Informações:
                            </b>{{oportunidade.informacoes}}</label><br>
                        <label><i class="far fa-clock"></i><b> Horário de Funcionamento:
                            </b>{{oportunidade.funcionamento}}</label><br>
                        <label><i class="fas fa-car"></i><b> Logradouro: </b>{{oportunidade.logradouro}} -
                            {{oportunidade.numero}}</label><br>
                        <label><i class="fas fa-map-signs"></i><b> Bairro: </b>{{oportunidade.bairro}} -
                            {{oportunidade.uf}} -
                            {{oportunidade.cidade}}</label><br>

                        <div>
                            <ng-template [ngIf]="oportunidade.maps === null" [ngIfElse]="elseBlock0">
                            </ng-template>
                            <ng-template #elseBlock0>
                                <label><i class="fas fa-map-marked-alt"></i><b> Localização: </b><a
                                        href="{{oportunidade.maps}}" target="_blank">Acessar</a></label><br>
                            </ng-template>
                        </div>

                        <div>
                            <ng-template [ngIf]="oportunidade.site === null" [ngIfElse]="elseBlock1">
                            </ng-template>
                            <ng-template #elseBlock1>
                                <label><i class="fas fa-sitemap"></i><b> Site: </b><a href="{{oportunidade.site}}"
                                        target="_blank">Acessar</a></label><br>
                            </ng-template>
                        </div>

                        <div>
                            <ng-template [ngIf]="oportunidade.facebook === null" [ngIfElse]="elseBlock2">
                            </ng-template>
                            <ng-template #elseBlock2>
                                <label><i class="fab fa-facebook"></i><b> Facebook: </b><a
                                        href="{{oportunidade.facebook}}" target="_blank">Acessar</a></label><br>
                            </ng-template>
                        </div>

                        <div>
                            <ng-template [ngIf]="oportunidade.instagram === null" [ngIfElse]="elseBlock3">
                            </ng-template>
                            <ng-template #elseBlock3>
                                <label><i class="fab fa-instagram"></i><b> Instagram: </b><a
                                        href="{{oportunidade.instagram}}" target="_blank">Acessar</a></label><br>
                            </ng-template>
                        </div>

                    </div>
                </div>
            </td>
            <br>
        </ng-template>


    </p-table>
    <div style="padding-bottom: 20px;">
        <button pButton pRipple type="button" icon="pi pi-sign-in" class="p-button-rounded p-button-danger"
            title="::Fechar::" routerLink="/dashboard/resumo"></button>
    </div>
</div>
<br>
<br><br>