<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>
<div class="row" style="margin-right: 2px; padding-left: 15px;">
    <div class="col-md-12" style="padding-top: 8px;">
       <h4 class="espacamentotopo " style="margin-left: 5px;">
           <i class="fas fa-bullhorn"></i>&nbsp;<b> Envio de comunicado em massa</b>
       </h4>
       <label>
           <b style="color: red;">Cuidado!</b>, ao clicar para em enviar a mensagem será enviada a todos conforme o filtro selecionado!.
           <br>
           Analise bem revise antes de clicar em enviar!, pois o processo e disparado rapidamente!.<br>
           <b>#FicaaDica: </b>Em caso de Celular melhor visualizado na horizontal!
       </label>
   </div>
</div>


<hr class="espacamentotopo bold" />
<form [formGroup]="formcomunicadoseminario" class="m" disabled="">
    <div class="row">
        <!---INCIO PRIMEIRA LINHA-->
        <div class="col-md-2">
            <div class="form-group">
                <label>CPF:</label><strong class="cor">*</strong>
                <input type="text" disabled="true" class="form-control" id="cpf" placeholder="informe o CPF"
                    mask="CPF_CNPJ" name="cpf" maxlength="14" autocomplete="off" formControlName="cpf"
                    [(ngModel)]="comunicadomodel.cpf" [ngClass]="{ 'is-invalid': submitted && f.cpf.errors }" />
                <div *ngIf="submitted && f.cpf.errors" class="invalid-feedback">
                    <div *ngIf="f.cpf.errors.required">Preenchimento obrigatório</div>
                </div>
            </div>
        </div>

        <div class="col-md-10">
            <div class="form-group">
                <label>Nome Completo:</label><strong class="cor">*</strong>
                <input type="text" disabled="true" class="form-control" id="nome" name="nome"
                    [(ngModel)]="comunicadomodel.nome" placeholder="Informe Nome Completo" formControlName="nome"
                    maxlength="500" minlength="10" autocomplete="off"
                    [ngClass]="{ 'is-invalid': submitted && f.nome.errors }" />
                <div *ngIf="submitted && f.nome.errors" class="invalid-feedback">
                    <div *ngIf="f.nome.errors.required">Preenchimento obrigatório</div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <label>Enviar Para:</label><strong class="cor">*</strong>
            <select class="custom-select" type="text" (change)="SetPara()" name="c" id="c"
                [(ngModel)]="comunicadomodel.c" formControlName="c"
                [ngClass]="{ 'is-invalid': submitted && f.c.errors }">
                <div *ngIf="submitted && f.c.errors" class="invalid-feedback"></div>
                <option value=""></option>
                <option value="Todos usuarios Cadastrados ( Ativos e Inativos )">Todos usuarios Cadastrados ( Ativos e
                    Inativos )</option>
                <option value="Todos usuarios Ativos">Todos usuarios Ativos</option>
                <option value="Todos usuarios Inativos">Todos usuarios Inativos</option>
                <option *ngFor="let city of cultoskids" [ngValue]="city.tipo_culto">
                    {{ city.tipo_culto }}
                </option>
            </select>
            <span><b>Quantidade de pessoas comunicadas: </b> <b style="color: red;">[ {{Total}} ]</b></span>
            <div *ngIf="submitted && f.c.errors" class="invalid-feedback">
                <div *ngIf="f.c.errors.required">Preenchimento obrigatório</div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <label>Assunto:</label><strong class="cor">*</strong>
            <input type="text" class="form-control" id="assunto" placeholder="Informe o assunto" name="assunto"
                formControlName="assunto" [(ngModel)]="comunicadomodel.assunto" maxlength="500" minlength="3"
                [ngClass]="{ 'is-invalid': submitted && f.assunto.errors }" />
            <div *ngIf="submitted && f.assunto.errors" class="invalid-feedback">
                <div *ngIf="f.assunto.errors.required">
                    Preenchimento obrigatório
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-sm">
            <div>
                <label>Mensagem:</label><strong class="cor">*</strong>
                <textarea type="text" class="form-control" id="mensagem" name="mensagem" formControlName="mensagem"
                    [(ngModel)]="comunicadomodel.mensagem" rows="10"
                    [ngClass]="{ 'is-invalid': submitted && f.mensagem.errors }" maxlength="9000"
                    minlength="7"></textarea>
                <span>{{ comunicadomodel.mensagem.length }}/9000</span>
                <div *ngIf="submitted && f.mensagem.errors" class="invalid-feedback">
                    <div *ngIf="f.mensagem.errors.required">
                        Preenchimento obrigatório
                    </div>
                </div>
            </div>
        </div>
        <hr>

        <!----->
        <div>
            <div>
                <label>Anexo:</label><br>
                <img id="fotos" src={{converted_image}} width="180px" height="200px" style="border: 2px;" /><br>
                <div class="row" style="margin-top: 0%; margin-left: 15px;">
                    <label for='arquivo' class="x">+</label>
                    <input #fileInput type='file' onchange="readURL(this);" accept="image/png, image/jpg,image/jpeg"
                        id='arquivo' (click)="SetFoto($event)">
                    <label for='remover' class="xx">-</label>
                    <input #fileInput type='button' (click)="FotoPadrao()" accept="image/png, image/jpg,image/jpeg"
                        id='remover'>
                </div>
            </div>
        </div>
        <hr>
    </div>
    <hr>
    <br>
    <div>
        <button pButton pRipple type="button" icon="pi pi-save" class="p-button-rounded" title="::Enviar Comunicado::"
            (click)="onSubmit()">
        </button>
        <button pButton pRipple type="button" icon="pi pi-sign-in" class="p-button-rounded p-button-danger"
            title="::Fechar::" routerLink="/dashboard/resumo">
        </button>
    </div>
    <br><br>

</form>
<button class="voltar-ao-topo" id="back"><a class="ancora" style="color: white;"><i
            class="pi pi-arrow-circle-up"></i></a></button>