export class PedidoModel {



    numero_pedido: string = '';
    cpf: string = '';
    nome: string = '';
   


}
