import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { Parametros } from 'src/app/shared/parametros.enum';
import { SharedModule } from 'src/app/shared/shared.module';
import { HistoricoModel } from './HistoricoModel';
import { patrimoniomodel } from '../../patrimonio/registrar-patrimonio/PatrimonioModel';
import { PedidoModel } from '../pedido-compra/PedidoModel';
import { ProdutosModel } from '../pedido-compra/ProdutosModel';

@Component({
  selector: 'app-detalhe-sollicitacao-compra',
  templateUrl: './detalhe-sollicitacao-compra.component.html',
  styleUrls: ['./detalhe-sollicitacao-compra.component.css']
})
export class DetalheSollicitacaoCompraComponent implements OnInit {

  private urlpatrimonio = Parametros.SOLICITACAOCOMPRA;



  x = 1;
  y: any = 10;
  filtro_cabecalho: string = ""
  explicacao: string = "";
  textoconsulta: string = "";
  rota: any;
  perfil: any;
  pagina_atual = 0;
  idpatrimonio: string;
  numeropedido: string = '';
  nomesolicitante: string = '';
  datapedido: any = '';
  statuspedido: string = '';




  constructor(private http: HttpClient,
    private primengConfig: PrimeNGConfig,
    private autenticado: SharedModule,
    private alert: AlertModalService,

    r: Router
  ) {
    this.rota = r;
    this.perfil = this.autenticado.perfil;
  }

  startedClass = false;
  completedClass = false;
  preventAbuse = false;

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//

  usuario: Array<HistoricoModel>;
  tipos: Array<ProdutosModel>
  qto: any;
  cols: any[];






  GetListaConsultaLimite(pagina: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get<any>(this.urlpatrimonio + 'GETHistoricoPedido/?parametro=' + this.idpatrimonio + '&pagina=' + pagina, httpOptions).subscribe(
      (success) => {
        this.usuario = success;

      },
      (error) => {
      }
    )
  }

  GetPatrimonio() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get<PedidoModel>(this.urlpatrimonio + 'GETPedido/?parametro=' + this.idpatrimonio, httpOptions).subscribe(
      (success) => {
        this.numeropedido = success[0].numero_pedido;
        this.nomesolicitante = success[0].nome;
        this.statuspedido = success[0].status_pedido;
        this.datapedido = success[0].dt_pedido.replace("T00:00:00", "");

        this.http.get<ProdutosModel[]>(this.urlpatrimonio + 'GETPedidoProdutos/?parametro=' + success[0].numero_pedido, httpOptions).subscribe(
          (success) => {
            this.tipos = success;
          }, () => { });
      },
      (error) => {
      }
    )
  }

  ngOnInit() {
    if (this.autenticado.token == null) {
      this.autenticado.ResetDados();
      this.alert.AlertShowDanger('Usuário não autenticado, redirecionando...')
      this.rota.navigate(['/login']);
    } else {
      if (this.autenticado.ValidarToken() != true) {
        this.autenticado.ResetDados();
        this.alert.AlertShowDanger('Token Invalido!')
        this.rota.navigate(['/login']);
      }
    }
    this.primengConfig.ripple = true;
    this.filtro_cabecalho = "Historico detalhado do Pedido"
    this.explicacao = "Abaixo todas as movimentação do Pedido desde seu cadastro na plataforma."


    this.cols = [
      { field: 'Data', header: 'Data' },
      { field: 'Hora', header: 'Hora' },
      { field: 'Tipo Movimentação', header: 'Tipo Movimentação' },
      { field: 'Detalhes', header: 'Detalhes' },
      { field: 'Repsonsavel', header: 'Responsavel' }
    ];

    this.idpatrimonio = localStorage.getItem('HistoricoPedido');
    if (this.idpatrimonio == null || this.idpatrimonio == "") {
      this.rota.navigate(['/dashboard/gerenciamento/compras/listagem-sollicitcao-compras']);
    } else {
      this.GetListaConsultaLimite(this.pagina_atual);
      this.GetPatrimonio();
      this.Total();
      localStorage.removeItem('HistoricoPedido')
    }
  }

  Paginate(event) {
    if (event.page == 0) {
      this.x = 1;
      this.y = 10;
    } else {

      if (event.page * 10 > this.qto) {
        this.x = this.qto;
        this.y = this.qto;
      } else {
        this.x = event.page * 10;
        if (this.x + 10 > this.qto) {
          this.y = this.qto;
        } else {
          this.y = this.x + 10;
        }
      }
    }

    this.pagina_atual = event.page;
    this.GetListaConsultaLimite(this.pagina_atual);
  }




  Consulta() {
    var texto = (<HTMLInputElement>document.getElementById("texto_consulta")).value;
    if (texto != "") {
      this.textoconsulta = texto;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
        })
      }
      this.http.get<HistoricoModel[]>(this.urlpatrimonio + 'GetConsultaHistoricoPedido/?parametro=' + this.idpatrimonio + '&texto=' + texto, httpOptions).subscribe(
        (success) => {
          this.usuario = success;
          this.qto = success.length
          this.x = 1;
          this.y = success.length;

        }
      )
    } else {
      this.textoconsulta = "";
      this.GetListaConsultaLimite(this.pagina_atual);
    }
  }

  Total() {
    var texto = (<HTMLInputElement>document.getElementById("texto_consulta")).value;

    this.textoconsulta = texto;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get<HistoricoModel[]>(this.urlpatrimonio + 'GETTotalRegistros/?parametro=' + this.idpatrimonio, httpOptions).subscribe(
      (success) => {
        this.qto = success.length;
        this.x = 1;


      },
      (error) => {

      }
    )

  }


}
