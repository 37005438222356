import { SharedModule } from './../shared/shared.module';
import { EventEmitterService } from './../shared/EventEmitterService';

import { Router } from '@angular/router';
import { Parametros } from './../shared/parametros.enum';
import { AlertModalService } from './../shared/alert-modal/alert-modal.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Login } from './LogimModel';
import { take } from 'rxjs/operators';
import { CookieService } from 'ngx-cookie-service';


@Injectable({
  providedIn: 'root',
})
export class LoginService {
  private urlcategory = Parametros.LOGIN;
  private rota: Router;

  l: Login;
  constructor(private http: HttpClient, private alert: AlertModalService,
    private cookie: CookieService,
    router: Router,
    private Is: SharedModule) {

    this.rota = router;



  }






  startedClass = false;
  completedClass = false;
  preventAbuse = false;

  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }

  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }


  //Metdo para fazer login e retornar dados
  PostLogin(Login) {
    this.StartProgressBar();
    return this.http.post(this.urlcategory + 'POSTlogin', Login, { responseType: 'text' }).pipe(take(1)).subscribe(
      success => {
        this.StopProgressBar();
        if (Login.opcao == true) {
          this.cookie.set("salvar_login", 'true');
          this.cookie.set("login", Login.login);
          this.cookie.set("senha", Login.senha);
          this.Is.autenticado = true;
          this.Is.token = success.replace("\r\n", "").replace("\n", "");

          EventEmitterService.get('loginAlert').emit(true);
          this.rota.navigate(['/dashboard']);
        } else {
          this.cookie.delete("salvar_login");
          this.cookie.delete("login");
          this.cookie.delete("senha");
          this.Is.autenticado = true;
          this.Is.token = success.replace("\r\n", "").replace("\n", "");

          EventEmitterService.get('loginAlert').emit(true);
          this.rota.navigate(['/dashboard']);
        }
      },
      error => {
        this.StopProgressBar();
        if (error.status === 403) {
          this.alert.AlertShowDanger(error.error);
          this.cookie.delete("salvar_login");
          this.cookie.delete("login");
          this.cookie.delete("senha");
          this.Is.autenticado = false;
          this.Is.ResetDados();
          EventEmitterService.get('loginAlert').emit(false);
          this.l.login = '';
          this.l.senha = '';
          this.l.identificacao = '';
        } else if (error.status === 0) {
          this.alert.AlertShowDanger('Servidor está demorando responder ou fora do AR');
          this.cookie.delete("salvar_login");
          this.cookie.delete("login");
          this.cookie.delete("senha");
          this.Is.autenticado = false;
          this.Is.token = '';
          this.Is.ResetDados();
          EventEmitterService.get('loginAlert').emit(false);
          this.l.login = '';
          this.l.senha = '';
          this.l.identificacao = '';
        }
        else {
          this.alert.AlertShowDanger('Usuario não autorizado!');
          this.cookie.delete("salvar_login");
          this.cookie.delete("login");
          this.cookie.delete("senha");
          this.Is.autenticado = false;
          this.Is.token = '';
          this.Is.ResetDados();
          EventEmitterService.get('loginAlert').emit(false);
          this.l.login = '';
          this.l.senha = '';
          this.l.identificacao = '';
        }
      }
    );
  }

  DadosUsuario() {
    return this.l;
  }
}
