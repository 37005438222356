<label class="col-md-12" style="margin-top: 10px; text-align: right;">Data Atualização - 08/02/2021</label>
<div class="container" style="margin-top: 20px; padding-bottom: 20px;">

    <h3>Como coletamos dados pessoais sobre você?</h3>
    <label>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Em tudo que nós fazemos, buscamos proteger os nossos dados e
        informações. Nós sabemos que pra você também é
        assim.
        Seus dados pessoais("Dados") são bens valiosos que devem ser preservados. Por isso você precisa saber exatamente
        como eles podem ser utilizados. Foi justamente para isso que criamos essa Política de Privacidade (“Política”).
        Para você saber e ficar tranquilo,Lembramos que os dados coletados são somente os dados de preenchimento de
        formularios,não sendo coletado nenhum outro dado a mais sem consentimento
    </label>

    <div id="accordion">
        <div class="card">
            <div class="card-header col-md-12" id="headingOne">
                <h5 class="mb-0">
                    <img src="../../assets/img/formulario.png" width="70px">
                    <button class="btn btn-link" data-toggle="collapse" data-target="#collapseOne" aria-expanded="false"
                        aria-controls="collapseOne" style="font-size: larger; margin-bottom: 0;">
                        Por seu cadastro
                    </button>
                </h5>
                <label style="margin-left: 80px; font-size: medium; margin-top: 0%;">Quando você se registra em nossa
                    plataforma, nós perguntamos Dados como cpf,nome, e-mail, data de nascimento, gênero para que você
                    crie um login e senha. ( saiba mais)
                </label>
            </div>

            <div id="collapseOne" class="collapse " aria-labelledby="headingOne" data-parent="#accordion">
                <div class="card-body">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Coletamos alguns Dados sobre você quando você se registra em nossa
                    plataforma, para que você crie um
                    login e senha (“Registro”). Durante o processo de cadastro, nós solicitamos Dados como seu cpf, nome
                    completo, estado civil, endereço de e-mail, data de nascimento, gênero, endereço, telefone fixo e/ou
                    celular
                    ,Dizimista entre outros. Lembramos que todos os dados informados são guardados com extrema
                    tecnologia e não sendo compartilhados com membros sendo somente para acesso a nosso portal podendo
                    ser compartilhado com pastores e
                    lideres de ministerios ao qual esteja envolvido.
                </div>
            </div>


        </div>

        <div class="card">
            <div class="card-header" id="headingTwo">
                <h5 class="mb-0">
                    <img src="../../assets/img/cookies.jpg" width="70px">
                    <button class="btn btn-link" data-toggle="collapse" data-target="#collapseTwo" aria-expanded="false"
                        aria-controls="collapseTwo" style="font-size: larger; margin-bottom: 0;">
                        Através do uso de cookies
                    </button>
                </h5>
                <label style="margin-left: 80px; font-size: medium; margin-top: 0%;">Os cookies são usados para
                    facilidade
                    de acesso no portal. Eles nos permitem lembrar suas preferências, agilizando e otimizando o acesso
                    ao portal.
                </label>
            </div>

            <div id="collapseTwo" class="collapse" aria-labelledby="headingTwo" data-parent="#accordion">
                <div class="card-body">
                    <label>
                        <b>O que são cookies e qual sua utilidade</b><br>
                        Cookies são pequenos arquivos de texto enviados e armazenados no seu computador. Estes pequenos
                        arquivos servem para reconhecer, acompanhar e armazenar a sua navegação como usuário na
                        Internet.</label>
                    <br>
                    <b>Qual a utilidade dos cookies</b><br>
                    O uso de cookies para acompanhar e armazenar informações possibilitará a Plataforma oferecer um
                    serviço mais personalizado, de acordo com as características e interesses de seus usuários,
                    possibilitando otimização do acesso e facilidade beneficiando a experiência do usuário na
                    plataforma.
                    <br>
                    <b>Em geral, os cookies são utilizados para:</b>
                    <label>· Proporcionar serviços diferenciados, lembrando quem você é e quais são os seus hábitos
                        de
                        navegação, além de acessar as informações do seu cadastro na plataforma;</label>
                    <label>· Administrar e manter a segurança das informações ao acessar a plataforma;</label>
                    <label>· Proporcionar acesso diferenciado, lembramos que não guardamos seus dados nos cookies, e
                        que e
                        guardado no cookie e totalmente de caracter especifico da plataforma não contendo dados
                        pessoais.</label>
                    <br>
                    <b> Categorias de cookies</b><br>
                    <label> * Estritamente necessária</label>
                    <label>Necessários para o funcionamento do site. Eles permitem que você navegue em nossos sites
                        e use os
                        serviços e recursos (por exemplo, cookies de segurança para autenticar usuários, evitar a
                        utilização
                        fraudulenta de credenciais de login e proteger os dados do usuário de terceiros não
                        autorizados).
                    </label>
                    <br>
                    <b>Desempenho </b>
                    <label>Esses cookies normalmente coletam informações de forma anônima e permitem determinar
                        informações,
                        como o número de visitantes de uma página, como os visitantes chegaram ao site e as páginas
                        acessadas.</label>
                    <br>
                    <b>Funcionalidade</b>
                    <label>Os cookies desta categoria permitem que a Plataforma se lembre de informações sobre o
                        comportamento e
                        preferências do usuário.</label>


                    <label>A perda das informações armazenadas em um cookie de preferência pode tornar a experiência
                        no
                        website menos funcional, mas não o impede de funcionar.</label>
                    <br>
                    <b> Como alterar ou bloquear cookies</b>
                    <label>A maioria dos navegadores é configurada para aceitar automaticamente os cookies. Você
                        pode,
                        contudo,
                        alterar as configurações para bloquear cookies ou alertá-lo quando um cookie estiver sendo
                        enviado
                        ao seu dispositivo. Existem várias formas de gerenciar cookies, sendo possível criar um
                        bloqueio
                        geral para cookies, bloquear cookies de um site específico e até mesmo bloquear cookies de
                        terceiros
                        em relação a um site. Bloquear todos os cookies vai afetar o funcionamento da sua
                        experiência,
                        pois
                        não será possível identificar suas preferências e recomendar conteúdo e publicidade
                        relevantes.
                    </label>
                    <br>
                    <label>Consulte as instruções do seu navegador para saber mais sobre como ajustar ou alterar
                        suas
                        configurações, lembrando que a configuração desejada deve ser replicada em todos os
                        dispositivos
                        utilizados para acessar os Serviços (como computadores, smartphones, tablets).</label>
                </div>
            </div>
        </div>


        <div class="card">
            <div class="card-header" id="headingTw">
                <h5 class="mb-0">
                    <img src="../../assets/img/controle.png" width="70px">
                    <button class="btn btn-link" data-toggle="collapse" data-target="#collapseTw" aria-expanded="false"
                        aria-controls="collapseTw" style="font-size: larger; margin-bottom: 0;">
                        Você no controle
                    </button>
                </h5>
                <label style="margin-left: 80px; font-size: medium; margin-top: 0%;">A qualquer momento você pode
                    visualizar
                    e editar seus Dados contidos na sua Conta.
                </label>
            </div>

            <div id="collapseTw" class="collapse" aria-labelledby="headingTw" data-parent="#accordion">
                <div class="card-body">
                    <label>
                        Seus direitos podem ser exercidos das seguintes formas:


                        · Acessar os seus Dados e retificá-los quando os mesmos estiverem incompletos, inexatos ou
                        desatualizados. Por isso, nós disponibilizamos ferramentas e configurações em sua Conta na
                        plataforma
                        (conforme aplicável) para que você possa acessar e
                        corrigir os seus Dados associados a tais cadastros. Caso você tenha dúvidas, você também pode
                        solicitar o acesso e a retificação dos seus Dados, bem como confirmar a existência de
                        tratamento, através do nosso time de privacidade.
                        · Você pode solicitar a inativação completa de seus Dados através do cancelamento da sua Conta
                        (conforme aplicável), lembramos que a inativação retira todo acesso ao site como conteudos,
                        ficando
                        demais cancelamentos ( newsletter e afins ) validos até que seja solicitado o devido
                        cancelamento
                        .Após a exclusão,Um contato pode ser realizado previamente antes do cancelamento para ciencia do
                        motivo.
                    </label>

                </div>
            </div>
        </div>


        <hr>
        <h3>Como usamos os seus Dados?</h3>
        <div class="card">
            <div class="card-header" id="headingTw">
                <h5 class="mb-0">
                    <img src="../../assets/img/comunicar.png" width="70px">
                    <button class="btn btn-link" data-toggle="collapse" data-target="#collapseT" aria-expanded="false"
                        aria-controls="collapseT" style="font-size: larger; margin-bottom: 0;">
                        Nos comunicar com você
                    </button>
                </h5>
                <label style="margin-left: 80px; font-size: medium; margin-top: 0%;">Coletamos seus Dados para aprimorar
                    nossos serviços, responder quando você entra em contato e para lhe manter informado sobre assuntos
                    relevantes.
                </label>
            </div>

            <div id="collapseT" class="collapse" aria-labelledby="headingT" data-parent="#accordion">
                <div class="card-body">
                    <label>
                        o Responder quando você entra em contato conosco, conforme a relação estabelecida com você;
                        <br>
                        o Realizar enquetes e pesquisas com o intuito de testar, resolver problemas, aprimorar ou
                        avaliar nossos Serviços;
                        <br>
                        o Comunicar sobre eventos, cultos ou assuntos destinados ao publico alvo;
                        <br>
                        o Informar sobre mudanças em nossos termos, serviços ou políticas (incluindo esta Política);
                    </label>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header" id="headingTwi">
                <h5 class="mb-0">
                    <img src="../../assets/img/protecao.png" width="70px">
                    <button class="btn btn-link" data-toggle="collapse" data-target="#collapseTwi" aria-expanded="false"
                        aria-controls="collapseTwi" style="font-size: larger; margin-bottom: 0;">

                        Segurança e Proteção
                    </button>
                </h5>
                <label style="margin-left: 80px; font-size: medium; margin-top: 0%;">Mantemos determinados Dados a fim
                    de
                    evitar atividades ilegais, fraudulentas e suspeitas e para promover suporte técnico e operacional.

                </label>
            </div>

            <div id="collapseTwi" class="collapse" aria-labelledby="headingTwi" data-parent="#accordion">
                <div class="card-body">
                    <label>
                        o Prover suporte técnico e operacional e garantir a segurança e a funcionalidade dos Serviços;
                        <br>
                        o Prevenir atividades ilegais, fraudulentas ou suspeitas, que possam provocar danos à Morada de
                        Deus ou a terceiros;
                        <br>
                        o Prevenir problemas técnicos ou de segurança;
                        <br>
                        o Proteger nossos direitos e propriedades, inclusive de invasões e hackeamento; e
                        <br>
                        o Proteger os direitos de propriedade de terceiros que usam nossos Serviços.
                    </label>
                </div>
            </div>
        </div>

        <hr>
        <h3>Com quem compartilhamos os seus Dados?</h3>
        <label>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Podemos compartilhar seus Dados com os Pastores Auxiliares e
            Lideres de ministerios, não sendo compartilhado jamais com terceiros ( Fora da administração / Gestão da
            Morada de Deus),Lembramos que todos os dados compartilhados são somente para fim de contato não sendo
            compartilhado jamais documentos ou preferencias pessoais.
        </label>
        <div class="card">
            <div class="card-header" id="headingTw">
                <h5 class="mb-0">
                    <img src="../../assets/img/comunicar.png" width="70px">
                    <button class="btn btn-link" data-toggle="collapse" data-target="#collapseTi" aria-expanded="false"
                        aria-controls="collapseTi" style="font-size: larger; margin-bottom: 0;">
                        Quem controla seus Dados
                    </button>
                </h5>
                <label style="margin-left: 80px; font-size: medium; margin-top: 0%;">A Morada de Deus ( Gestão e
                    Liderança )
                    e si infraestrutura, sistemas e tecnologia para que você tenha uma experiência integrada, inovadora,
                    eficiente e segura em todos os Serviços.
                </label>
            </div>

            <div id="collapseTi" class="collapse" aria-labelledby="headingTi" data-parent="#accordion">
                <div class="card-body">
                    <label>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Os seus Dados são controlados Pela Morada de Deus:
                        Morada de Deus ( IEPMD).
                    </label>
                </div>
            </div>
        </div>


        <hr>
        <h3>Como protegemos os seus Dados?</h3>
        <label>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; Adotamos práticas e tecnologias alinhadas aos padrões técnicos e
            regulatórios de mercado, bem como controles que são sempre revisados e aprimorados.
        </label>
        <div class="card">
            <div class="card-header" id="headingTo">
                <h5 class="mb-0">
                    <img src="../../assets/img/anonimo.png" width="70px">
                    <button class="btn btn-link" data-toggle="collapse" data-target="#collapseTo" aria-expanded="false"
                        aria-controls="collapseTo" style="font-size: larger; margin-bottom: 0;">

                        Dados anonimizados
                    </button>
                </h5>
                <label style="margin-left: 80px; font-size: medium; margin-top: 0%;">

                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Os seus dados são coletados e tratados de forma que você
                    não seja identificado pessoalmente.

                </label>
            </div>

            <div id="collapseTo" class="collapse" aria-labelledby="headingTo" data-parent="#accordion">
                <div class="card-body">
                    <label>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Além de tratarmos os Dados de acordo
                        com esta Política, coletamos e tratamos também seus dados de forma anonimizada, ou seja, de modo
                        a que você não seja identificado pessoalmente.
                    </label>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header" id="headingTod">
                <h5 class="mb-0">
                    <img src="../../assets/img/protecao_dados.png" width="70px">
                    <button class="btn btn-link" data-toggle="collapse" data-target="#collapseTod" aria-expanded="false"
                        aria-controls="collapseTod" style="font-size: larger; margin-bottom: 0;">


                        Segurança dos seus Dados
                    </button>
                </h5>
                <label style="margin-left: 80px; font-size: medium; margin-top: 0%;">A Morada de Deus implementa
                    controles
                    de segurança para proteger seus Dados.

                </label>
            </div>

            <div id="collapseTod" class="collapse" aria-labelledby="headingTod" data-parent="#accordion">
                <div class="card-body">
                    <label>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Adotamos práticas alinhadas aos padrões técnicos e
                        regulatórios de mercado para segurança e
                        privacidade de Dados, com ações abrangentes em tecnologia e nos processos organizacionais.
                        <br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Nossas medidas para preservar seus Dados contra
                        acesso, uso, alteração, divulgação ou destruição
                        não autorizados incluem a proteção física e lógica dos ativos, comunicações criptografadas,
                        gestão sobre os acessos, adesão ao desenvolvimento seguro de software e políticas internas de
                        conformidade que inserem a segurança no ciclo de vida dos nossos Serviços.
                        <br>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Todos esses controles são continuamente revisados para
                        acompanhar e reagir ao contexto de
                        ameaças na Internet. Ainda assim, não é possível garantir que os nossos Serviços sejam
                        completamente invioláveis. Mas fique tranquilo: contamos com equipes preparadas para detectar e
                        responder prontamente, no caso da ocorrência de algum evento ou incidente que comprometa a
                        segurança dos seus Dados ou de nossos Serviços.
                    </label>
                </div>
            </div>
        </div>

        <hr>
        <h3>Contato</h3>
        <label>
            Caso precise de qualquer suporte ou tenha alguma dúvida, pedido ou sugestão em relação a essa Política,
            somos todos ouvidos.
        </label>
        <div class="card">
            <div class="card-header" id="headingTos">
                <h5 class="mb-0">
                    <img src="../../assets/img/avios.png" width="70px">
                    <button class="btn btn-link" data-toggle="collapse" data-target="#collapseTos" aria-expanded="false"
                        aria-controls="collapseTos" style="font-size: larger; margin-bottom: 0;">

                        Sobre alterações na Política
                    </button>
                </h5>

            </div>

            <div id="collapseTos" class="collapse" aria-labelledby="headingTos" data-parent="#accordion">
                <div class="card-body">
                    <label>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Estamos sempre melhorando nossa Política e as mudanças
                        serão refletidas nesta página. Então, antes de usar um de nossos Serviços dê uma olhadinha aqui.
                        Você sempre será avisado sobre qualquer alteração, seja através de aviso no Portal da
                        Privacidade, nos Serviços, pelo envio de e-mail, ou através de outros meios.

                        Quando publicarmos alterações na Política, atualizaremos a data em que a última alteração foi
                        publicada.

                        Lembre-se: Ao continuar a acessar os Serviços depois que tais alterações passarem a valer, você
                        estará concordando em estar vinculado à nova versão da Política.


                    </label>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="card-header" id="headingTosd">
                <h5 class="mb-0">
                    <img src="../../assets/img/contato.png" width="70px">
                    <button class="btn btn-link" data-toggle="collapse" data-target="#collapseTosd"
                        aria-expanded="false" aria-controls="collapseTosd" style="font-size: larger; margin-bottom: 0;">



                        Como entrar em contato
                    </button>
                </h5>

            </div>

            <div id="collapseTosd" class="collapse" aria-labelledby="headingTosd" data-parent="#accordion">
                <div class="card-body">
                    <label>
                        Essa Política é parte de um processo contínuo que busca utilizar suas informações da maneira
                        mais segura e ética. Totalmente pensado para você. Por isso, é muito importante para gente ouvir
                        de você e conhecer suas impressões sobre o que conversamos aqui. Caso tenha algo a expressar não
                        deixe de nos comunicar clicando
                        <a [routerLink]="['/contato']">AQUI!</a>
                    </label>
                </div>
            </div>
        </div>


    </div>
    <br>
</div>
<div style="padding-top: 10px; padding-bottom: 50px;">
    <button pButton pRipple type="button" icon="pi pi-sign-in" class="p-button-rounded p-button-danger"
        title="::Fechar::" routerLink="/dashboard/resumo">
    </button>
</div>
<button class="voltar-ao-topo" id="back"><a class="ancora" style="color: white;"><i
            class="pi pi-arrow-circle-up"></i></a></button>