import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, retry, take } from 'rxjs/operators';
import { Parametros } from 'src/app/shared/parametros.enum';
import { seminariomodel } from './seminariomodel';

@Injectable({
  providedIn: 'root'
})
export class SeminarioServiceService {
  private urlcadastro = Parametros.SEMINARIO;  
  private urlcorreios = Parametros.CORREIOS;

  constructor(private http: HttpClient) { }
  startedClass = false;
  completedClass = false;
  preventAbuse = false;

  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }

  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }

  
  ConsultaCEP(cep) {
    this.StartProgressBar();
    return this.http
      .get<seminariomodel>(
        this.urlcorreios +
        cep.replace('\\', '').replace('-', '').replace('.', '')+'/json/'
      )
      .pipe(take(1));
  }

 
  CadastroUsuarioSeminario(c) {
    this.StartProgressBar();
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http
      .post(this.urlcadastro + 'POSTCadastroSeminario', c, httpOptions).pipe(first());
     
  }

 
 
}
