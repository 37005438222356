import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { Parametros } from 'src/app/shared/parametros.enum';
import { ConfirmarDocumentoKids } from './ConfirmarDocumentoKids';
import { ConfirmarKidsDocumentosService } from './confirmar-kids-documentos.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { cultomodelKids } from 'src/app/culto-kids-inscricao/CultoKidsModel';

@Component({
  selector: 'app-checkout-kids-documento',
  templateUrl: './checkout-kids-documento.component.html',
  styleUrls: ['./checkout-kids-documento.component.css']
})
export class CheckoutKidsDocumentoComponent implements OnInit {

  formculto: FormGroup;
  submitted = false;
  cadastroculto: ConfirmarDocumentoKids;


  startedClass = false;
  completedClass = false;
  preventAbuse = false;
  rota: Router;

  registro: string = '';
  status: string = '';
  user: string = '';
  hash: string = '';
  nome: string = '';
  data: string = '';
  hora: string = '';
  isDone = '';

  private urlcadastro = Parametros.CADASTROCULTOKIDS;


  listapara: any;

  constructor(
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private alert: AlertModalService,
    private service: ConfirmarKidsDocumentosService,
    private router: Router,
    private autenticado: SharedModule,
  ) {
    this.rota = this.router;

  }

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//

  criarform() {
    this.formculto = this.formBuilder.group({
      c: [Validators.required],
      documento: ['', [Validators.required, Validators.maxLength(14), Validators.minLength(8)]]
    });
  }


  GETListagemCultos() {
    this.StartProgressBar();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token
      })
    };
    return this.http
      .get<cultomodelKids>(this.urlcadastro + 'GETlistaCultosUltimos', httpOptions).subscribe(
        (success) => {
          this.listapara = success;
        },
        (error) => {

        }
      )
  }

 

  //BOTAO SALVAR
  //====================================================================//
  onSubmit() {
    this.submitted = true;

    if (this.formculto.controls['documento'].value.length < 8) {
      this.alert.AlertShowDanger('DOCUMENTO INVÁLIDO');
      document.getElementById('documento').focus();
      this.formculto.controls['documento'].setValue("");
      return;
    }


    if (this.formculto.invalid) {

      this.alert.AlertShowDanger('Favor verificar os campos em destaque!');
      return;
    } else {
      var x = this.formculto.controls['c'].value;

      if (x == "" || x == "null") {
        this.alert.AlertShowDanger('Favor Preencher o Evento / Culto!');
        return;
      }
      this.service.ConfirmarCadastro(this.formculto.controls['documento'].value, this.formculto.controls['c'].value).subscribe(
        (success) => {
          this.cadastroculto = new ConfirmarDocumentoKids();
          document.getElementById('documento').focus();
          this.user = success.nome_filho;
          this.isDone = 'autorizado';
          this.registro = success.hash;
          this.formculto.get("c").patchValue(x);
          this.formculto.get("documento").patchValue("");
        },
        (error) => {
          if (error.status == "400") {
            this.StopProgressBar();
            this.user = "REGISTRO NÃO EXISTE EM NOSSA BASE";
            this.isDone = 'nao_encontrado';
            this.registro = "DOCUMENTO NÃO ENCONTRADO EM NOSSA BASE";
            this.formculto.get("c").patchValue(x);
            this.formculto.get("documento").patchValue("");
            return;
          } else {
            this.StopProgressBar();
            this.user = "REGISTRO JÁ FOI UTILIZADO NA ENTRADA";
            this.isDone = 'usado';
            this.data = error.error.data_confirmado;
            this.hora = error.error.hora_confirmado;
            this.registro = "DOCUMENTO JÁ FOI UTILIZADO ANTERIORMENTE";
            this.formculto.get("documento").patchValue("");
            this.formculto.get("c").patchValue(x);
            return;
          }
        }
      );
    }
    //====================================================================//
  }
  //====================================================================//



  // Pegar nome dos campos,
  //====================================================================//
  get f() {
    return this.formculto.controls;
  }

  //Inicio
  //====================================================================//
  ngOnInit() {
    this.cadastroculto = new ConfirmarDocumentoKids();
    this.criarform();
    document.getElementById('documento').focus();
    this.GETListagemCultos();
  }



}