import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { Parametros } from 'src/app/shared/parametros.enum';
import { SharedModule } from 'src/app/shared/shared.module';
import { SugestaoModel } from '../../sugestoes/sugestaomodel';

@Component({
  selector: 'app-sugestoes-listagem',
  templateUrl: './sugestoes-listagem.component.html',
  styleUrls: ['./sugestoes-listagem.component.css']
})
export class SugestoesListagemComponent implements OnInit {
  private urlsugestao = Parametros.SUGESTAO;



  x = 1;
  y = 10;
  filtro_cabecalho: string = ""
  explicacao: string = "";
  textoconsulta: any;
  rota: any;
  perfil: any;
  visualizada: string = "";
  nome: string = "";
  idsugestao: any;
  cols: { field: string; header: string; }[];


  constructor(private http: HttpClient,
    private primengConfig: PrimeNGConfig,
    private alert: AlertModalService,
    private autenticado: SharedModule,
    private r: Router
  ) {
    this.rota = r;
    this.perfil = this.autenticado.perfil;
    this.nome = this.autenticado.nome;
  }

  sugestoes: Array<SugestaoModel>;
  qto: number;

  startedClass = false;
  completedClass = false;
  preventAbuse = false;

  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }

  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }



  ngOnInit() {
    if (this.autenticado.token == null) {
      this.autenticado.ResetDados();
      this.alert.AlertShowDanger('Usuário não autenticado, redirecionando...')
      this.rota.navigate(['/login']);
    } else {
      if (this.autenticado.ValidarToken() != true) {
        this.autenticado.ResetDados();
        this.alert.AlertShowDanger('Token Invalido!')
        this.rota.navigate(['/login']);
      }
    }
    this.primengConfig.ripple = true;
    this.filtro_cabecalho = "Listagem de Sugestões"
    this.explicacao = "Aqui você está visualizando todas as sugestões feitas por você a Morada de Deus."
    this.GetListaTotal();
    this.GetLista();
    this.StopProgressBar;

    this.cols = [
      { field: 'Data Registro', header: 'Data Registro' },
      { field: 'Tipo Sugestão', header: 'Tipo Sugestão' },
      { field: 'Visualizada', header: 'Visualizada' },
      { field: 'Excluir', header: 'Excluir' },
      { field: 'Detalhe', header: 'Detalhe' }

    ];
  }

  BotaoDetalhe(event) {
    localStorage.removeItem('SugestoesDetalhe');
    localStorage.setItem('SugestoesDetalhe', event.currentTarget.value);
    this.rota.navigate(['/dashboard/detalhe/sugestoes']);
  }

  BotaoExcluir() {
    this.ExcluirConsulta();
  }

  SetDados(event) {
    this.idsugestao = event.currentTarget.value;
  }

  GetListaTotalQuantidadeDez(pagina: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get<any>(this.urlsugestao + 'GetListaConsultaLimite/?cpf=' + this.autenticado.cpf + '&id=' + pagina, httpOptions).subscribe(
      dados => this.sugestoes = dados
    )
  }
  Paginate(event) {
    this.x = event.first + 1;
    this.GetListaTotalQuantidadeDez(event.first.toString());
    if (event.first + 10 > this.qto) {
      this.y = this.qto;
    } else {
      this.y = event.first + 10;
    }
  }

  //API
  Consulta() {
    var texto = (<HTMLInputElement>document.getElementById("texto_consulta")).value;
    if (texto != "") {
      this.StartProgressBar();
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
        })
      }
      this.http.get<SugestaoModel[]>(this.urlsugestao + 'GetListaConsultaSugestaoCpf/?parametro=' + this.autenticado.cpf + '&consulta=' + texto, httpOptions).subscribe(
        dados => this.sugestoes = dados, dados => this.qto = dados.length
      )
    } else {
      this.GetLista();
    }
  }

  ExcluirConsulta() {
    this.StartProgressBar;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http
      .post(this.urlsugestao + 'PostCancelarsugestao/?parametro=' + this.idsugestao, "", httpOptions)
      .subscribe(
        () => {
          this.StopProgressBar;
          this.alert.AlertShowInfo('Sugestão Excluida com sucesso!');
          this.GetLista();
        },
        (error) => {
          this.alert.AlertShowDanger('Erro ao tentar excluir Sugestão');
          this.StopProgressBar;
          this.GetLista();
        }
      );
  }

  GetLista() {
    this.StartProgressBar();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get<SugestaoModel[]>(this.urlsugestao + 'GETListaSugestaoPorCpf/?parametro=' + this.autenticado.cpf, httpOptions).subscribe(
      dados => this.sugestoes = dados
    )
  }


  GetListaTotal() {
    this.StartProgressBar();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get<any>(this.urlsugestao + 'GETListaSugestaoTotalCpf/?parametro=' + this.autenticado.cpf, httpOptions).subscribe(
      dados => this.qto = dados
    )
  }
}

