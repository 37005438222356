<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>
<div>
    <h4 class="espacamentotopo">
        <img src="../../assets/img/cadastro.jpeg" style="width: 40px" /> Enviar Sugestão
    </h4>
    <label class="espacamentotopo col-md" style="margin-left: 5px;">
        &nbsp;&nbsp;&nbsp;&nbsp;Você está na área para enviar sugestões, desde já gostaríamos de informar que ficamos feliz em receber sua
        sugestão e que analisaremos cuidadosamente e com todo carinho.
        <br>
        &nbsp;&nbsp;&nbsp;&nbsp;Ressaltamos que todos os dados aqui informados são confidenciais e são enviados somente para Gestão da igreja
        não ficando acessivel qualquer outra pessoa.<br>
    </label>
    <label class="espacamentotopo col-md" style="margin-left: 5px;">
        &nbsp;&nbsp;&nbsp;&nbsp; Lembramos que os campos com (<span class="cor"> *</span> ) são campos de
        preenchimento obrigatório!
    </label>
    <hr class="espacamentotopo bold" />
    <form [formGroup]="formsugestao" class="m" disabled="">
        <div class="row">
            <!---INCIO PRIMEIRA LINHA-->
            <div class="col-md-2">
                <div class="form-group">
                    <label>CPF:</label><strong class="cor">*</strong>
                    <input type="text" disabled="true" class="form-control" id="cpf" placeholder="informe o CPF"
                        mask="CPF_CNPJ" name="cpf" maxlength="14" autocomplete="off" formControlName="cpf"
                        [(ngModel)]="modelsugestao.cpf" [ngClass]="{ 'is-invalid': submitted && f.cpf.errors }" />
                    <div *ngIf="submitted && f.cpf.errors" class="invalid-feedback">
                        <div *ngIf="f.cpf.errors.required">Preenchimento obrigatório</div>
                    </div>
                </div>
            </div>

            <div class="col-md-7">
                <div class="form-group">
                    <label>Nome Completo:</label><strong class="cor">*</strong>
                    <input type="text" disabled="true" class="form-control" id="nome" name="nome"
                        [(ngModel)]="modelsugestao.nome" placeholder="Informe Nome Completo" formControlName="nome"
                        maxlength="500" minlength="3" autocomplete="off"
                        [ngClass]="{ 'is-invalid': submitted && f.nome.errors }" />
                    <div *ngIf="submitted && f.nome.errors" class="invalid-feedback">
                        <div *ngIf="f.nome.errors.required">Preenchimento obrigatório</div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <label>Telefone/Celular:</label><strong class="cor">*</strong>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1"><i class="fas fa-phone"></i></span>
                    </div>
                    <input type="text" class="form-control" id="telefone" name="telefone"
                        [(ngModel)]="modelsugestao.telefone" placeholder="Informe o Telefone"
                        title="Formato (DD) 0000-0000 ou (DD) 00000-0000" formControlName="telefone" maxlength="500"
                        minlength="3" autocomplete="off" mask="(00) 0000-0000 || (00) 00000-0000" [ngClass]="{
                      'is-invalid': submitted && f.telefone.errors
                    }" />
                    <div *ngIf="submitted && f.telefone.errors" class="invalid-feedback">
                        <div *ngIf="f.telefone.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!----->
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label>Tipo Sugestão:</label><strong class="cor">*</strong>
                    <input type="text" class="form-control" id="tipo_sugestao" name="nome"
                        [(ngModel)]="modelsugestao.tipo_sugestao" placeholder="Informe Tipo de Sugestão" formControlName="tipo_sugestao"
                        maxlength="500" minlength="3" autocomplete="off"
                        [ngClass]="{ 'is-invalid': submitted && f.tipo_sugestao.errors }" />
                    <div *ngIf="submitted && f.tipo_sugestao.errors" class="invalid-feedback">
                        <div *ngIf="f.tipo_sugestao.errors.required">Preenchimento obrigatório</div>
                    </div>
                </div>
            </div>

        </div>
        <!----->

        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label>Sugestão:</label><strong class="cor">*</strong>
                    <textarea type="text" class="form-control" id="mensagem" aria-describedby="emailHelp"
                        name="mensagem" formControlName="mensagem" [(ngModel)]="modelsugestao.mensagem"
                        autocomplete="off" maxlength="9000" minlength="10" rows="5"
                        [ngClass]="{ 'is-invalid': submitted && f.mensagem.errors }"></textarea>
                    <span>{{ modelsugestao.mensagem.length }}/9000</span>

                    <div *ngIf="submitted && f.mensagem.errors" class="invalid-feedback">
                        <div *ngIf="f.mensagem.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <!----->
        <div class="row">
            <div class="col-md">
                <div class="form-group" style=" display: block; border: 0px;">
                    <input type="checkbox" id="receber_copia" data-md-icheck formControlName="receber_copia"
                        [(ngModel)]="receber_copia" (change)="ReceberCopia($event)" />
                    Receber Cópia.
              
                    <span style=" display: block; border: 0px; font-size: x-small;">Para
                        receber uma cópia e necessário
                        ter e-mail valido em seu Perfil </span>
                </div>
            </div>

        </div>
        <!----->
        <hr>
        <br>
        <!----->
        <div style="padding-bottom: 20px;">
            <button pButton pRipple type="button" icon="pi pi-save" class="p-button-rounded" style="margin-right: 5px;"
                title=":: Registrar ::" (click)="onSubmit()"></button>
            <button pButton pRipple type="button" icon="pi pi-refresh" style="margin-right: 5px;"
                class="p-button-rounded p-button-secondary" title="::LIMPAR::" (click)="reset()"></button>
            <button pButton pRipple type="button" icon="pi pi-sign-in" class="p-button-rounded p-button-danger"
                title="::Fechar::" routerLink="/dashboard/resumo"></button>
        </div>
    </form>
</div>
<button class="voltar-ao-topo" id="back"><a class="ancora" style="color: white;"><i
            class="pi pi-arrow-circle-up"></i></a></button>
