import { SharedModule } from './../shared/shared.module';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, ActivatedRoute, Router } from '@angular/router';


@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  Autenticado: boolean = false;
  Perfil: string = 'Membro';
  rota: Router;

  constructor(private Is: SharedModule,
    router: Router
  ) {
    this.Autenticado = Is.autenticado;
    this.Perfil = Is.perfil;
    this.rota = router;
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,

  ): Observable<boolean> | boolean {
    if (this.Autenticado == true ) {
    
      return this.Autenticado;
    } else {     
      this.rota.navigate(['/login']);
    }
  }
}
