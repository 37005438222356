<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>
<div>
    <div class="row">
        <div class="col-md-2" style="margin-right: 2px; padding: 0%;">
            <img src="../../../assets/img/configuracoes.png" height="200px"  style="padding: 0%;"/>
        </div>
        <div class="col-md">
            <h4 class="espacamentotopo " style="margin-left: 5px;">
                Ajustes da Plataforma
            </h4>
            <label style="margin-left: 5px;">
                Aqui você define como a plataforma deve operar de acordo com os itens abaixo!.
                <br>
                Tome cuidado para não deixar todos os e-mails em branco e as lideranças não ficarem sabendo do que está
                ocorrendo.<br>
                Normalmente se cadastra o emails dos lideres responsaveis por cada questão, sendo os emails cadastrados
                sem espaços e separados por (;)<br>
                <b style="color: red;">Exemplo:</b> email@email.com.br;email@email.com.br
            </label>
        </div>
    </div>


    <hr class="espacamentotopo bold" />
    <form [formGroup]="formparametros" class="m" disabled="">
        <div class="row">
            <!---INCIO PRIMEIRA LINHA-->
            <div class="col-md-2">
                <div class="form-group">
                    <label>Proximo Registro:</label>
                    <input type="text" disabled="true" class="form-control" id="proximo_registro"
                        name="proximo_registro" maxlength="14" autocomplete="off" formControlName="proximo_registro"
                        [(ngModel)]="modelparametros.proximo_registro"
                        [ngClass]="{ 'is-invalid': submitted && f.proximo_registro.errors }" />
                    <div *ngIf="submitted && f.proximo_registro.errors" class="invalid-feedback">
                        <div *ngIf="f.proximo_registro.errors.required">Preenchimento obrigatório</div>
                    </div>
                </div>
            </div>

            <div class="col-md-2">
                <div class="form-group">
                    <label>Limite Culto:</label>
                    <input type="number" class="form-control" id="limite_culto"
                        name="limite_culto" maxlength="14" autocomplete="off" formControlName="limite_culto"
                        [(ngModel)]="modelparametros.limite_culto"
                        [ngClass]="{ 'is-invalid': submitted && f.limite_culto.errors }" />
                    <div *ngIf="submitted && f.limite_culto.errors" class="invalid-feedback">
                        <div *ngIf="f.limite_culto.errors.required">Preenchimento obrigatório</div>
                    </div>
                </div>
            </div>

            <div class="col-md-2">
                <div class="form-group">
                    <label>Limite culto Kids:</label>
                    <input type="number"  class="form-control" id="limite_culto_kids"
                        name="limite_culto_kids" maxlength="14" autocomplete="off" formControlName="limite_culto_kids"
                        [(ngModel)]="modelparametros.limite_culto_kids"
                        [ngClass]="{ 'is-invalid': submitted && f.limite_culto_kids.errors }" />
                    <div *ngIf="submitted && f.limite_culto_kids.errors" class="invalid-feedback">
                        <div *ngIf="f.limite_culto_kids.errors.required">Preenchimento obrigatório</div>
                    </div>
                </div>
            </div>

            <div class="col-md-3">
                <div class="form-group">
                    <label>Limite encontro Homens:</label>
                    <input type="number"  class="form-control" id="limite_culto_kids"
                        name="limite_encontro_homens" maxlength="14" autocomplete="off" formControlName="limite_encontro_homens"
                        [(ngModel)]="modelparametros.limite_encontro_homens"
                        [ngClass]="{ 'is-invalid': submitted && f.limite_encontro_homens.errors }" />
                    <div *ngIf="submitted && f.limite_encontro_homens.errors" class="invalid-feedback">
                        <div *ngIf="f.limite_encontro_homens.errors.required">Preenchimento obrigatório</div>
                    </div>
                </div>
            </div>

            <div class="col-md-3">
                <div class="form-group">
                    <label>Limite encontro Mulheres:</label>
                    <input type="number"  class="form-control" id="limite_culto_kids"
                        name="limite_encontro_mulheres" maxlength="14" autocomplete="off" formControlName="limite_encontro_mulheres"
                        [(ngModel)]="modelparametros.limite_encontro_mulheres"
                        [ngClass]="{ 'is-invalid': submitted && f.limite_encontro_mulheres.errors }" />
                    <div *ngIf="submitted && f.limite_encontro_mulheres.errors" class="invalid-feedback">
                        <div *ngIf="f.limite_encontro_mulheres.errors.required">Preenchimento obrigatório</div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-3">
                <div class="form-group">
                    <label>Limite Curso Casais:</label>
                    <input type="number"  class="form-control" id="limite_curso_casais"
                        name="limite_curso_casais" maxlength="14" autocomplete="off" formControlName="limite_curso_casais"
                        [(ngModel)]="modelparametros.limite_curso_casais"
                        [ngClass]="{ 'is-invalid': submitted && f.limite_curso_casais.errors }" />
                    <div *ngIf="submitted && f.limite_curso_casais.errors" class="invalid-feedback">
                        <div *ngIf="f.limite_curso_casais.errors.required">Preenchimento obrigatório</div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label>Limite Congresso Mulheres:</label>
                    <input type="number"  class="form-control" id="limite_congresso_mulheres"
                        name="limite_congresso_mulheres" maxlength="14" autocomplete="off" formControlName="limite_congresso_mulheres"
                        [(ngModel)]="modelparametros.limite_congresso_mulheres"
                        [ngClass]="{ 'is-invalid': submitted && f.limite_congresso_mulheres.errors }" />
                    <div *ngIf="submitted && f.limite_congresso_mulheres.errors" class="invalid-feedback">
                        <div *ngIf="f.limite_congresso_mulheres.errors.required">Preenchimento obrigatório</div>
                    </div>
                </div>
            </div>

            <div class="col-md-3">
                <div class="form-group">
                    <label>Limite Congresso Homens:</label>
                    <input type="number"  class="form-control" id="limite_congresso_homens"
                        name="limite_congresso_homens" maxlength="14" autocomplete="off" formControlName="limite_congresso_homens"
                        [(ngModel)]="modelparametros.limite_congresso_homens"
                        [ngClass]="{ 'is-invalid': submitted && f.limite_congresso_homens.errors }" />
                    <div *ngIf="submitted && f.limite_congresso_homens.errors" class="invalid-feedback">
                        <div *ngIf="f.limite_congresso_homens.errors.required">Preenchimento obrigatório</div>
                    </div>
                </div>
            </div>

            <div class="col-md-3">
                <div class="form-group">
                    <label>Limite Congresso Jovens:</label>
                    <input type="number"  class="form-control" id="limite_congresso_jovens"
                        name="limite_congresso_jovens" maxlength="14" autocomplete="off" formControlName="limite_congresso_jovens"
                        [(ngModel)]="modelparametros.limite_congresso_jovens"
                        [ngClass]="{ 'is-invalid': submitted && f.limite_congresso_jovens.errors }" />
                    <div *ngIf="submitted && f.limite_congresso_jovens.errors" class="invalid-feedback">
                        <div *ngIf="f.limite_congresso_jovens.errors.required">Preenchimento obrigatório</div>
                    </div>
                </div>
            </div>
        </div>
        
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label>E-mail Contato:</label>

                    <input type="text" class="form-control" id="email_contato" name="email_contato" autocomplete="off"
                        formControlName="email_contato" [(ngModel)]="modelparametros.email_contato"
                        [ngClass]="{ 'is-invalid': submitted && f.email_contato.errors }" style="margin-bottom: 0%;" />
                    <div *ngIf="submitted && f.email_contato.errors" class="invalid-feedback">
                        <div *ngIf="f.email_contato.errors.required">Preenchimento obrigatório</div>
                    </div>
                    <span style="margin-top: 0%; font-size: small;">Email de quem irá receber os contatos realizados
                        pelos usuarios no site!</span>
                </div>
            </div>

            <div class="col-md-6">
                <div class="form-group">
                    <label>E-mail Elogios:</label>

                    <input type="text" class="form-control" id="email_elogio" name="email_elogio" autocomplete="off"
                        formControlName="email_elogio" [(ngModel)]="modelparametros.email_elogio"
                        [ngClass]="{ 'is-invalid': submitted && f.email_elogio.errors }" style="margin-bottom: 0%;" />
                    <div *ngIf="submitted && f.email_elogio.errors" class="invalid-feedback">
                        <div *ngIf="f.email_elogio.errors.required">Preenchimento obrigatório</div>
                    </div>
                    <span style="margin-top: 0%; font-size: small;">Email de quem irá receber os elogios enviados pelos
                        usuarios da plataforma!</span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label>E-mail Problema:</label>

                    <input type="text" class="form-control" id="email_problema" name="email_problema" autocomplete="off"
                        formControlName="email_problema" [(ngModel)]="modelparametros.email_problema"
                        [ngClass]="{ 'is-invalid': submitted && f.email_problema.errors }" style="margin-bottom: 0%;" />
                    <div *ngIf="submitted && f.email_problema.errors" class="invalid-feedback">
                        <div *ngIf="f.email_problema.errors.required">Preenchimento obrigatório</div>
                    </div>
                    <span style="margin-top: 0%; font-size: small;">Email de quem irá receber os problemas reportados
                        pelos usuarios da plataforma!</span>
                </div>
            </div>

            <div class="col-md-6">
                <div class="form-group">
                    <label>E-mail Visita:</label>

                    <input type="text" class="form-control" id="email_visita" name="email_visita" autocomplete="off"
                        formControlName="email_visita" [(ngModel)]="modelparametros.email_visita"
                        [ngClass]="{ 'is-invalid': submitted && f.email_visita.errors }" style="margin-bottom: 0%;" />
                    <div *ngIf="submitted && f.email_visita.errors" class="invalid-feedback">
                        <div *ngIf="f.email_visita.errors.required">Preenchimento obrigatório</div>
                    </div>
                    <span style="margin-top: 0%; font-size: small;">Email de quem irá receber as solicitações de visitas
                        feitas pelos usuarios da plataforma!</span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label>E-mail Reclamação:</label>

                    <input type="text" class="form-control" id="email_reclamacao" name="email_reclamacao"
                        autocomplete="off" formControlName="email_reclamacao"
                        [(ngModel)]="modelparametros.email_reclamacao"
                        [ngClass]="{ 'is-invalid': submitted && f.email_reclamacao.errors }"
                        style="margin-bottom: 0%;" />
                    <div *ngIf="submitted && f.email_reclamacao.errors" class="invalid-feedback">
                        <div *ngIf="f.email_reclamacao.errors.required">Preenchimento obrigatório</div>
                    </div>
                    <span style="margin-top: 0%; font-size: small;">Email de quem irá receber as reclamações feitas
                        pelos usuarios da plataforma!</span>
                </div>
            </div>

            <div class="col-md-6">
                <div class="form-group">
                    <label>E-mail Visitantes:</label>

                    <input type="text" class="form-control" id="email_visitantes" name="email_visitantes"
                        autocomplete="off" formControlName="email_visitantes"
                        [(ngModel)]="modelparametros.email_visitantes"
                        [ngClass]="{ 'is-invalid': submitted && f.email_visitantes.errors }"
                        style="margin-bottom: 0%;" />
                    <div *ngIf="submitted && f.email_visitantes.errors" class="invalid-feedback">
                        <div *ngIf="f.email_visitantes.errors.required">Preenchimento obrigatório</div>
                    </div>
                    <span style="margin-top: 0%; font-size: small;">Email de quem irá receber dados dos visitantes dos
                        cultos que se registraram!</span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label>E-mail Sugestões:</label>

                    <input type="text" class="form-control" id="email_sugestao" name="email_sugestao" autocomplete="off"
                        formControlName="email_sugestao" [(ngModel)]="modelparametros.email_sugestao"
                        [ngClass]="{ 'is-invalid': submitted && f.email_sugestao.errors }" style="margin-bottom: 0%;" />
                    <div *ngIf="submitted && f.email_sugestao.errors" class="invalid-feedback">
                        <div *ngIf="f.email_sugestao.errors.required">Preenchimento obrigatório</div>
                    </div>
                    <span style="margin-top: 0%; font-size: small;">Email de quem irá receber as sugestões enviadas
                        pelos usuarios!</span>
                </div>
            </div>

            <div class="col-md-6">
                <div class="form-group">
                    <label>E-mail Testemunhos:</label>

                    <input type="text" class="form-control" id="email_testemunho" name="email_testemunho"
                        autocomplete="off" formControlName="email_testemunho"
                        [(ngModel)]="modelparametros.email_testemunho"
                        [ngClass]="{ 'is-invalid': submitted && f.email_testemunho.errors }"
                        style="margin-bottom: 0%;" />
                    <div *ngIf="submitted && f.email_testemunho.errors" class="invalid-feedback">
                        <div *ngIf="f.email_testemunho.errors.required">Preenchimento obrigatório</div>
                    </div>
                    <span style="margin-top: 0%; font-size: small;">Email de quem irá receber os testemunhos enviadas
                        pelos usuarios!</span>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label>E-mail Cancelamento:</label>

                    <input type="text" class="form-control" id="email_cancelamento" name="email_cancelamento" autocomplete="off"
                        formControlName="email_cancelamento" [(ngModel)]="modelparametros.email_cancelamento"
                        [ngClass]="{ 'is-invalid': submitted && f.email_cancelamento.errors }" style="margin-bottom: 0%;" />
                    <div *ngIf="submitted && f.email_cancelamento.errors" class="invalid-feedback">
                        <div *ngIf="f.email_cancelamento.errors.required">Preenchimento obrigatório</div>
                    </div>
                    <span style="margin-top: 0%; font-size: small;">Email de quem irá receber as sugestões enviadas
                        pelos usuarios!</span>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>E-mail Seminarios:</label>

                    <input type="text" class="form-control" id="email_seminario" name="email_seminario" autocomplete="off"
                        formControlName="email_seminario" [(ngModel)]="modelparametros.email_seminario"
                        [ngClass]="{ 'is-invalid': submitted && f.email_seminario.errors }" style="margin-bottom: 0%;" />
                    <div *ngIf="submitted && f.email_seminario.errors" class="invalid-feedback">
                        <div *ngIf="f.email_seminario.errors.required">Preenchimento obrigatório</div>
                    </div>
                    <span style="margin-top: 0%; font-size: small;">Email de quem irá receber as duvidas dos participantes dos seminarios!</span>
                </div>
            </div>

            <div class="col-md-6">
                <div class="form-group">
                    <label>E-mail Seminarios Infantil:</label>

                    <input type="text" class="form-control" id="email_seminario_infantil" name="email_seminario_infantil" autocomplete="off"
                        formControlName="email_seminario_infantil" [(ngModel)]="modelparametros.email_seminario_infantil"
                        [ngClass]="{ 'is-invalid': submitted && f.email_seminario_infantil.errors }" style="margin-bottom: 0%;" />
                    <div *ngIf="submitted && f.email_seminario_infantil.errors" class="invalid-feedback">
                        <div *ngIf="f.email_seminario_infantil.errors.required">Preenchimento obrigatório</div>
                    </div>
                    <span style="margin-top: 0%; font-size: small;">Email de quem irá receber as duvidas dos participantes dos seminarios Infantil!</span>
                </div>

                <div class="form-group">
                    <label>E-mail Cursos de Casais:</label>

                    <input type="text" class="form-control" id="email_curso_casais" name="email_curso_casais" autocomplete="off"
                        formControlName="email_curso_casais" [(ngModel)]="modelparametros.email_curso_casais"
                        [ngClass]="{ 'is-invalid': submitted && f.email_curso_casais.errors }" style="margin-bottom: 0%;" />
                    <div *ngIf="submitted && f.email_curso_casais.errors" class="invalid-feedback">
                        <div *ngIf="f.email_curso_casais.errors.required">Preenchimento obrigatório</div>
                    </div>
                    <span style="margin-top: 0%; font-size: small;">Email de quem irá receber as duvidas dos participantes do curso de Casais!</span>
                </div>
            </div>

            <div class="col-md-6">
                <div class="form-group">
                    <label>E-mail Pedido Compra:</label>

                    <input type="text" class="form-control" id="email_pedido_compra" name="email_pedido_compra" autocomplete="off"
                        formControlName="email_pedido_compra" [(ngModel)]="modelparametros.email_pedido_compra"
                        [ngClass]="{ 'is-invalid': submitted && f.email_pedido_compra.errors }" style="margin-bottom: 0%;" />
                    <div *ngIf="submitted && f.email_pedido_compra.errors" class="invalid-feedback">
                        <div *ngIf="f.email_pedido_compra.errors.required">Preenchimento obrigatório</div>
                    </div>
                    <span style="margin-top: 0%; font-size: small;">Email de quem irá receber as duvidas dos participantes dos seminarios Infantil!</span>
                </div>
              
            
                <div class="form-group">
                    <label>E-mail Secretaria:</label>

                    <input type="text" class="form-control" id="email_secretaria" name="email_secretaria" autocomplete="off"
                        formControlName="email_secretaria" [(ngModel)]="modelparametros.email_secretaria"
                        [ngClass]="{ 'is-invalid': submitted && f.email_secretaria.errors }" style="margin-bottom: 0%;" />
                    <div *ngIf="submitted && f.email_secretaria.errors" class="invalid-feedback">
                        <div *ngIf="f.email_secretaria.errors.required">Preenchimento obrigatório</div>
                    </div>
                    <span style="margin-top: 0%; font-size: small;">Email de quem irá receber dados referentes a secretaria!</span>
                </div>

               
            </div>
        </div>

        <br>
        <div style="padding-top: 10px; padding-bottom: 20px;">
            <button pButton pRipple type="button" icon="pi pi-save" class="p-button-rounded"
                title="::Salvar Alterações::" (click)="onSubmit()">
            </button>
            <button pButton pRipple type="button" icon="pi pi-sign-in" class="p-button-rounded p-button-danger"
                title="::Fechar::" routerLink="/dashboard/resumo">
            </button>
        </div>
    </form>
</div>
<button class="voltar-ao-topo" id="back"><a class="ancora" style="color: white;"><i
    class="pi pi-arrow-circle-up"></i></a></button>