<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>
<!-- Modal -->
<div class="col-md-9 centraliza border flex-auto bloco">
    <form [formGroup]="formproblema" class=" centraliza">
        <h3 class="text-left "><img src="../../assets/img/email.jpeg" style="width: 50px;"> Reportar Problemas </h3>
        <label>Lamentamos que tenha encontrado problemas na utilização de nossa plataforma!</label>
        <label>Fique tranquilo que assim que recebermos seu comunicado de problema estaremos solicitando nossa equipe
            para que analise e ajuste o mais breve possivel,aproveitando gostariamos que deixa-se um modo de contato
            para assim que resolvermos possamos retornar seu contato!</label>
        <label>Agradecemos seu contato!</label>
        <hr class="espacamentotopo bold">
        <div class="row" style="margin-top: 10px;">
            <div class="col-md-12">
                <div class="form-group">
                    <label for="username">Nome:</label><br>
                    <input type="text" class="form-control" name="nome" formControlName="nome"
                        [(ngModel)]="problema.nome" required placeholder="Informe seu nome"
                        [ngClass]="{ 'is-invalid': submitted && f.nome.errors }">
                    <div *ngIf="submitted && f.nome.errors">
                        <div *ngIf="f.nome.errors.required" class="cor">Preenchimento obrigatório</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-5">
                <div class="form-group">
                    <label for="username">Email:</label>
                    <div class="input-group mb-1">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1"><i class="fas fa-at"></i></span>
                        </div>
                        <input type="text" class="form-control" name="email" formControlName="email"
                            [(ngModel)]="problema.email" placeholder="Informe seu Email"
                            [ngClass]="{ 'is-invalid': submitted && f.email.errors }">

                    </div>
                    <div *ngIf="submitted && f.email.errors">
                        <div *ngIf="f.email.errors.required" class="cor">Preenchimento obrigatório</div>
                    </div>
                </div>

            </div>
            <div class="col-md-4">
                <div class="form-group">
                    <label for="username">Telefone/Celular:</label>
                    <div class="input-group mb-1">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1"><i class="fas fa-phone"></i></span>
                        </div>
                        <input type="text" class="form-control" id="telefone" name="telefone"
                            [(ngModel)]="problema.telefone" placeholder="Informe o Telefone"
                            title="Formato (DD) 0000-0000 ou (DD) 00000-0000" formControlName="telefone"
                            autocomplete="off" [ngClass]="{ 'is-invalid': submitted && f.telefone.errors }"
                            mask="(00) 0000-0000 || (00) 00000-0000">
                    </div>
                    <div *ngIf="submitted && f.telefone.errors">
                        <div *ngIf="f.telefone.errors.required" class="cor">Preenchimento obrigatório</div>
                    </div>

                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label for="text">Tipo de problema:</label>
                    <select class="custom-select " id="tipo_problema" formControlName="tipo_problema"
                        [(ngModel)]="problema.tipo_problema"
                        [ngClass]="{ 'is-invalid': submitted && f.tipo_problema.errors }">
                        <option value="Erros">Erros</option>
                        <option value="Login">Login</option>
                        <option value="Pagina não Encontrada">Pagina não Encontrada</option>
                        <option value="Recuperação">Recuperações</option>
                        <option value="Travamentos">Travamentos</option>
                        <option value="Reativar Cadastro">Reativar Cadastro</option>
                        <option value="Cancelar Cadastro">Cancelar Cadastro</option>
                        <option value="Cadastro Seminario">Cadastro Seminario</option>
                        <option value="Politica de Privacidade">Politica de Privacidade</option>

                    </select>
                    <div *ngIf="submitted && f.tipo_problema.errors">
                        <div *ngIf="f.tipo_problema.errors.required" class="cor">Preenchimento obrigatório</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group">
            <label>Mensagem:</label>
            <textarea type="mensagem" rows="5" class="form-control" id="mensagem" aria-describedby="emailHelp"
                autocomplete="off" maxlength=500 minlength=15 formControlName="mensagem" [(ngModel)]="problema.mensagem"
                [ngClass]="{ 'is-invalid': submitted && f.mensagem.errors }"></textarea>
            <span>{{problema.mensagem.length}}/500</span>
            <div *ngIf="submitted && f.mensagem.errors">
                <div *ngIf="f.mensagem.errors.required" class="cor">Preenchimento obrigatório</div>
            </div>
            <div class="row" style="margin-top: 0%;">
                <div class="col-md-2">
                    <div class="form-group" style="padding-top: 30px; margin-left: 0%;">
                        <input type="checkbox" id="copia" data-md-icheck name="copia" formControlName="copia"
                            [(ngModel)]="problema.copia" title="Receber Cópia" (change)="ReceberCopia($event)"> Receber Cópia
                    </div>
                </div>
            </div>
        </div>      

        <div>
            <button type="submit" class="bt btn-success btn-sm" title="::Enviar::" (click)="onSubmit()"><i
                    class="fas fa-paper-plane"></i>
                Enviar</button>
            <button type="submit" class="bt btn-info btn-sm" title="::Home::" routerLink=""><i class="fas fa-home"></i>
                Home</button>
        </div>
    </form>



</div>
