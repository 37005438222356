export class seminariomodel {

    cpf: string = '';
    nome: string = '';
    tipo_seminario: string = '';
    email: string = '';
    telefone: string = '';
    ministerio_morada: string = '';
    ministerio_pertence: string = '';
    ficou_sabendo: string = '';
    duvida: string = '';
    cep: string = '';
    logradouro: string = '';
    numero: number = 0;
    apto: number = 0;
    bairro: string = '';
    uf: string = '';
    cidade: string = '';
    dados_seminario: string = '';
    localidade: string = '';
    dt_cadastro: any;
    idseminario: any;
    hash: any;


    //usado no checkout
   
    certificado_impresso: any;
    dt_impresso: any;
    ativo: any;
    referencia: any;
    confirmado: any;
    data_confirmado: any;
    hora_confirmado: any;
    para: any;
    assunto: any;
    mensagem: any;
    anexo: any;
    quantidade:any;
}