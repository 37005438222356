import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { DashboardService } from '../dashboard-home/dashboard.service';
import { ResumoService } from './resumo.service';

@Component({
  selector: 'app-resumo',
  templateUrl: './resumo.component.html',
  styleUrls: ['./resumo.component.css']
})
export class ResumoComponent implements OnInit {
  startedClass = false;
  completedClass = false;
  preventAbuse = false;

  elogio: number = 0;
  visita: number = 0;
  sugestoes: number = 0;
  reclamacao: number = 0;
  testemunho: number = 0;
  acessos: any = 0;
  ativos: number = 0;
  inativos: number = 0;
  geral: number = 0;
  cancelados: number = 0;
  aguardando_liberacao: number = 0;
  oportunidades_abertas:number=0;
  oportunidades_minhas:number=0;
  servicos:number=0;
  servicos_empresas:number=0;

  _perfil: string = '';

  _serviceresumo: ResumoService;
  
  rota: Router;
  constructor(roter: Router,
    private autenticado: SharedModule,
    private alert: AlertModalService,
    private s: ResumoService) {
    this.rota = roter;
    this._serviceresumo = s;

  }
  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }

  ngOnInit(): void {

    if (this.autenticado.token == null) {
      this.autenticado.ResetDados();
      this.alert.AlertShowDanger('Usuário não autenticado, redirecionando...')
      this.rota.navigate(['/login']);
    } else {
      if (this.autenticado.ValidarToken() != true) {
        this.autenticado.ResetDados();
        this.alert.AlertShowDanger('Token Invalido!')
        this.rota.navigate(['/login']);
      } else {
        this.StartProgressBar;
        this._perfil = this.autenticado.perfil;
        //Acessos
        this._serviceresumo.TotalLogin(this.autenticado.cpf, this.autenticado.token).subscribe(
          (success) => {
            var total = Number.parseInt(success.toString());
            if (total > 1000) {
              this.acessos = '+1000';
            } else {
              this.acessos = total;
            }
          },
          (error) => {
            this.acessos = 0;
          }
        );

        //Elogios
        this._serviceresumo.TotalEloagios(this.autenticado.cpf, this.autenticado.token).subscribe(
          (success) => {
            this.elogio = Number.parseInt(success.toString());
          },
          (error) => {
            this.elogio = 0;
          }
        );

        //visitas
        this._serviceresumo.TotalVisitas(this.autenticado.cpf, this.autenticado.token).subscribe(
          (success) => {
            this.visita = Number.parseInt(success.toString());
          },
          (error) => {
            this.visita = 0;
          }
        );

        //reclamcao
        this._serviceresumo.TotalReclamacao(this.autenticado.iduser, this.autenticado.token).subscribe(
          (success) => {
            this.reclamacao = Number.parseInt(success.toString());
          },
          (error) => {
            this.reclamacao = 0;
          }
        );

        //sugestao
        this._serviceresumo.Totalsugestao(this.autenticado.iduser, this.autenticado.token).subscribe(
          (success) => {
            this.sugestoes = Number.parseInt(success.toString());
          },
          (error) => {
            this.sugestoes = 0;
          }
        );

        //testemunho
        this._serviceresumo.Totaltestemunho(this.autenticado.cpf, this.autenticado.token).subscribe(
          (success) => {
            this.testemunho = Number.parseInt(success.toString());
          },
          (error) => {
            this.testemunho = 0;
          }
        );

        //Verifica perfil
        if (this.autenticado.perfil.includes('Administrador') || this.autenticado.perfil.includes('Presidente') || this.autenticado.perfil.includes('Gestao')) {
          //ativos
          this._serviceresumo.TotalUsuariosAtivos(this.autenticado.token).subscribe(
            (success) => {
              this.ativos = Number.parseInt(success.toString());
            },
            (error) => {
              this.ativos = 0;
            }
          );

        }

        //Verifica perfil
        if (this.autenticado.perfil.includes('Administrador') || this.autenticado.perfil.includes('Presidente') || this.autenticado.perfil.includes('Gestao')) {
          //inativos
          this._serviceresumo.TotalUsuariosInativos(this.autenticado.token).subscribe(
            (success) => {
              this.inativos = Number.parseInt(success.toString());
            },
            (error) => {
              this.inativos = 0;
            }
          );

        }

        //Verifica perfil
        if (this.autenticado.perfil.includes('Administrador') || this.autenticado.perfil.includes('Presidente') || this.autenticado.perfil.includes('Gestao')) {
          //cancelados
          this._serviceresumo.TotalUsuariosCancelados(this.autenticado.token).subscribe(
            (success) => {
              this.cancelados = Number.parseInt(success.toString());
            },
            (error) => {
              this.cancelados = 0;
            }
          );

        }

        if (this.autenticado.perfil.includes('Administrador') || this.autenticado.perfil.includes('Presidente') || this.autenticado.perfil.includes('Gestao')) {
          //cancelados
          this._serviceresumo.UsuariosAguardandoLiberacao(this.autenticado.token).subscribe(
            (success) => {
              this.aguardando_liberacao = Number.parseInt(success.toString());
            },
            (error) => {
              this.aguardando_liberacao = 0;
            }
          );

        }

        if (this.autenticado.perfil.includes('Administrador') || this.autenticado.perfil.includes('Presidente') || this.autenticado.perfil.includes('Gestao')) {
          //Total
          this._serviceresumo.TotalUsuariosPlataforma(this.autenticado.token).subscribe(
            (success) => {
              this.geral = Number.parseInt(success.toString());
            },
            (error) => {
              this.geral = 0;
            }
          );

        }

        

              //Oportunidades Abertas Minhas               
              this._serviceresumo.TotalAportunidadesAbertasMinhas(this.autenticado.cpf,this.autenticado.token).subscribe(
                (success) => {
                  this.oportunidades_minhas = success;
                },
                (error) => {
                  this.oportunidades_minhas = 0;
                }
              );


              
              //Oportunidades Abertas Minhas               
              this._serviceresumo.TotalAportunidadesAbertas(this.autenticado.token).subscribe(
                (success) => {
                  this.oportunidades_abertas = success;
                },
                (error) => {
                  this.oportunidades_abertas = 0;
                }
              );


          
          //Total Estabelecimentos Meus                
          this._serviceresumo.TotalEstabelecimentosMeus(this.autenticado.cpf,this.autenticado.token).subscribe(
            (success) => {
              this.servicos = success.length;
            },
            (error) => {
              this.servicos = 0;
            }
          );

          //Total Estabelecimentos               
          this._serviceresumo.TotalEstabelecimentos(this.autenticado.token).subscribe(
            (success) => {
              this.servicos_empresas = success;
            },
            (error) => {
              this.servicos_empresas = 0;
            }
          );

        




        //FIM
        this.StopProgressBar;
      }
    }
  }
}

