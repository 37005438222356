export class OportunidadeModel {

    [x: string]: any;
    idusuario: number=0;
    idestabelecimento: any;
    cpf: string = '';
    nome: string = '';
    oportunidade: string = '';
    telefone: any = '';
    celular: any = '';
    email: string = '';
    tag: string = '';
    maps: string = '';
    cep: string = '';
    logradouro: string = '';
    numero: any = '';
    quantidade: any = '';
    sexo: string = '';
    apto: any = '';
    bairro: string = '';
    uf: string = '';
    cidade: string = '';
    informacoes: string = '';
    site: string = '';
    instagram: string = '';
    facebook: string = '';
    funcionamento: string = '';
    ativo: boolean = true;



}