import { Component, OnInit } from '@angular/core';
import { EventEmitterService } from '../shared/EventEmitterService';
import { SharedModule } from '../shared/shared.module';

@Component({
  selector: 'app-privacidade',
  templateUrl: './privacidade.component.html',
  styleUrls: ['./privacidade.component.css']
})
export class PrivacidadeComponent implements OnInit {

  constructor(private autenticado: SharedModule) {
    EventEmitterService.get('loginAlert').emit(false);
  }

  ngOnInit() {
    this.autenticado.ResetDados();
  }

}
