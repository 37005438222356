import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { retry } from 'rxjs/operators';
import { Parametros } from 'src/app/shared/parametros.enum';
import { SharedModule } from 'src/app/shared/shared.module';
import { patrimoniomodel } from '../registrar-patrimonio/PatrimonioModel';

@Injectable({
  providedIn: 'root'
})
export class ConsultaQrcodeService {
  
  private urlpatrimonio = Parametros.PATRIMONIO;

  constructor(private http: HttpClient,
    private autenticado: SharedModule) { }

  startedClass = false;
  completedClass = false;
  preventAbuse = false;

  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }

  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }

  ConsultaPatrimonio(patrimonio) {
    this.StartProgressBar();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'        
      }),
    };
    return this.http
      .get<patrimoniomodel[]>(this.urlpatrimonio + 'GETPatrimonio/?parametro=' + patrimonio, httpOptions)
      .pipe(retry(2));
  }

}
