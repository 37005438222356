import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Parametros } from 'src/app/shared/parametros.enum';
import { SharedModule } from 'src/app/shared/shared.module';
import { congressojovensmodel } from '../cadastro-congresso-jovens/CongressoJovensModel';

@Injectable({
  providedIn: 'root'
})
export class ConfirmarDocumentoService {
  private urlcadastrousuario = Parametros.CONGRESSOJOVENS;
  private rota: Router;


  constructor(private http: HttpClient,
    router: Router,
    private autenticado: SharedModule
  ) {
    this.rota = router;
  }
  ConfirmarCadastro(_hash, _evento) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token
      })
    };
    return this.http
      .get<congressojovensmodel>(this.urlcadastrousuario + 'GETConfirmarCadastroDocumento/?documento=' + _hash + '&evento=' + _evento, httpOptions)
      .pipe();
  }

}
