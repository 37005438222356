import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { TestemunhoModel } from '../testemunho/testemunhomodel';
import { TestemunhoService } from '../testemunho/testemunho.service';
import { SharedModule } from 'src/app/shared/shared.module';

@Component({
  selector: 'app-testemunho',
  templateUrl: './testemunho.component.html',
  styleUrls: ['./testemunho.component.css']
})
export class TestemunhoComponent implements OnInit {


  rota: Router;
  modeltestemunho: TestemunhoModel;
  formtestemunho: FormGroup;
  startedClass = false;
  completedClass = false;
  preventAbuse = false;
  receber_copia: boolean;

  cbtipotestemunho: any = [
    'CURA',
    'PORTA EMPREGO', 'REVELAÇÃO', 'LIBERTAÇÃO'];
  submitted: boolean = false;

  cbsigilo: any = [
    'SIM',
    'NÃO'];


  cbocultarnome: any = [
    'SIM',
    'NÃO'];



  constructor(
    private formBuilder: FormBuilder,
    private alert: AlertModalService,
    private servicetestemunho: TestemunhoService,
    private router: Router,
    private autenticado: SharedModule,
  ) {
    this.rota = this.router;
    this.modeltestemunho = new TestemunhoModel();

  }

  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }

  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }

  reset() {
    this.modeltestemunho = new TestemunhoModel();
    this.submitted = false;

    this.formtestemunho.get('email').setValue(this.autenticado.email);
    this.formtestemunho.get('idusuario').setValue(this.autenticado.iduser);
    this.formtestemunho.get('nome').setValue(this.autenticado.nome);
    this.formtestemunho.get('cpf').setValue(this.autenticado.cpf);
  }
  get f() {
    return this.formtestemunho.controls;
  }

  get tipotestemunho() {
    return this.formtestemunho.get('tipo_testemunho');
  }

  changetestemunho(e) {
    this.tipotestemunho.setValue(e.target.value, {
      onlySelf: true,
    });
  }

  get nomeoculto() {
    return this.formtestemunho.get('ocultar_nome');
  }

  changesigilo(e) {
    this.mantersigilo.setValue(e.target.value, {
      onlySelf: true,
    });
  }

  get mantersigilo() {
    return this.formtestemunho.get('manter_sigilo');
  }

  changenomeoculto(e) {
    this.nomeoculto.setValue(e.target.value, {
      onlySelf: true,
    });
  }

  ReceberCopia(e) {
    if (e.target.checked == true && this.autenticado.email == '') {
      this.receber_copia = false;
      this.alert.AlertShowDanger(':: AVISO ::\nPara que possa receber uma cópia da Reclamação e necessário ter e-mail valido em seu cadastro! \nPor favor preencha um e-mail valido em seu Perfil e tente novamente!');
    }

  }

  //BOTAO SALVAR
  //====================================================================//
  onSubmit() {
    this.submitted = true;
    if (this.formtestemunho.invalid) {
      this.alert.AlertShowDanger('Favor verificar os campos em destaque!');
      return;
    }
    else if (this.formtestemunho.get('telefone').value.length < 10) {
      this.alert.AlertShowDanger('Favor Preencher Telefone corretamente');
    } else {
      this.servicetestemunho
        .RegistrarTestemunho(this.autenticado.token, this.formtestemunho.value)
        .subscribe(
          (sucess) => {
            this.StopProgressBar();
            this.alert.AlertShowSucess('Testemunho Registrado com Sucesso');
            this.criarform();
            this.submitted = false;
            this.formtestemunho.get('email').setValue(this.autenticado.email);
            this.formtestemunho.get('idusuario').setValue(this.autenticado.iduser);
            this.formtestemunho.get('nome').setValue(this.autenticado.nome);
            this.formtestemunho.get('cpf').setValue(this.autenticado.cpf);
          },
          (error) => {
            this.StopProgressBar();
            this.alert.AlertShowDanger('( ' + error.status + ' ) ' + error.error);
          }
        );
    }
  }

  //====================================================================//
  ngOnInit() {
    if (this.autenticado.token == null) {
      this.autenticado.ResetDados();
      this.alert.AlertShowDanger('Usuário não autenticado, redirecionando...')
      this.rota.navigate(['/login']);
    } else {
      if (this.autenticado.ValidarToken() != true) {
        this.autenticado.ResetDados();
        this.alert.AlertShowDanger('Token Invalido!')
        this.rota.navigate(['/login']);
      } else {
        try {
          this.modeltestemunho = new TestemunhoModel();
          this.criarform();
          this.modeltestemunho.cpf = this.autenticado.cpf;
          this.modeltestemunho.nome = this.autenticado.nome;
          this.formtestemunho.get('idusuario').setValue(this.autenticado.iduser);
          this.formtestemunho.get('email').setValue(this.autenticado.email);
        } catch {
          this.rota.navigate(['/dashboard/resumo']);
        }
      }
    }
  }
  //====================================================================//
  criarform() {
    this.formtestemunho = this.formBuilder.group({
      cpf: ['', Validators.required],
      nome: ['', Validators.required],
      email: [''],
      telefone: ['', Validators.required],
      tipo_testemunho: ['', Validators.required],
      ocultar_nome: ['', Validators.required],
      manter_sigilo: ['', Validators.required],
      mensagem: ['', [Validators.required, Validators.min(20), Validators.max(9000)]],
      receber_copia: [false],
      idusuario: [0, Validators.required]
    });
  }

}
