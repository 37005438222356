import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { error } from 'jquery';
import { PrimeNGConfig } from 'primeng/api';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { Parametros } from 'src/app/shared/parametros.enum';
import { SharedModule } from 'src/app/shared/shared.module';
import { listagemdeducaomodel } from '../listagem-deducoes-compras/deducaolistagemmodel';
import{ListagemTodasDeducoesComprasService} from './listagem-todas-deducoes-compras.service';

@Component({
  selector: 'app-listagem-todas-deducoes',
  templateUrl: './listagem-todas-deducoes.component.html',
  styleUrls: ['./listagem-todas-deducoes.component.css']
})
export class ListagemTodasDeducoesComponent implements OnInit {

  private urlgestao = Parametros.CADASTRODEDUCOESCOMPRAS;

  x = 1;
  y = 10;
  filtro_cabecalho: string = ""
  explicacao: string = "";
  textoconsulta: string = "";
  rota: any;
  perfil: any;
  pagina_atual = 1;
  imprimir: string = "Todos";
  iddeducaocompra: any;
  nome: string;
  opcao: string = "Ativar";
  datainicial: any;
  datafinal: any;
  ministeriorelatorio: string = "ministerio_geral";
  ministeriotiporelatorio: string = "todos";


  constructor(private http: HttpClient,
    private primengConfig: PrimeNGConfig,
    private alert: AlertModalService,
    private autenticado: SharedModule,
    private service: ListagemTodasDeducoesComprasService,
    r: Router
  ) {
    this.rota = r;
    this.perfil = this.autenticado.perfil;
    this.nome = this.autenticado.nome;
  }

  startedClass = false;
  completedClass = false;
  preventAbuse = false;

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//

  usuario: Array<listagemdeducaomodel>;
  qto: number;
  cols: any[];


  GetLista() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token
      })
    }
    this.http.get<listagemdeducaomodel[]>(this.urlgestao + 'GETListagemComprasUsuarioFinanceiro', httpOptions).subscribe(
      (success) => {
        this.usuario = success;
      this.GetListaTotal();
      },(error)=>{

      }
    )
  }

  GetListaTotal() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token
      })
    }
    this.http.get<any>(this.urlgestao + 'GETTotalDeducaoUsuarioFinanceiro', httpOptions).subscribe(
      dados => this.qto = dados
    )
  }

  GetListaTotalQuantidadeDez(pagina: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token
      })
    }
    this.http.get<listagemdeducaomodel[]>(this.urlgestao + 'GetListaDeducaoConsultaLimiteFinanceiro/?parametro=' + pagina, httpOptions).subscribe(
      (success) => {
        this.usuario = success;
        this.GetListaTotal();
      }
    )
  }

  ngOnInit() {
    if (this.autenticado.token == null) {
      this.autenticado.ResetDados();
      this.alert.AlertShowDanger('Usuário não autenticado, redirecionando...')
      this.rota.navigate(['/login']);
    } else {
      if (this.autenticado.ValidarToken() != true) {
        this.autenticado.ResetDados();
        this.alert.AlertShowDanger('Token Invalido!')
        this.rota.navigate(['/login']);
      }
    }
    this.primengConfig.ripple = true;
    this.filtro_cabecalho = "Listagem de Todas as Compras Cadastradas "
    this.explicacao = "Aqui você está visualizando todas as compras cadastradas pelos ministérios."
    this.GetListaTotal();
    this.GetLista();
  }

  Paginate(event) {
    if (event.page == 0) {
      this.x = 1;
      this.y = 10;
    } else {

      if (event.page * 10 > this.qto) {
        this.x = this.qto;
        this.y = this.qto;
      } else {
        this.x = event.page * 10;
        if (this.x + 10 > this.qto) {
          this.y = this.qto;
        } else {
          this.y = this.x + 10;
        }
      }
    }
    this.pagina_atual = event.page;

    this.GetListaTotalQuantidadeDez(this.pagina_atual);
  }

  Consulta() {
    var texto = (<HTMLInputElement>document.getElementById("texto_consulta")).value;
    if (texto != "") {
      this.textoconsulta = texto;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json'
           })
      }
      this.http.get<listagemdeducaomodel[]>(this.urlgestao + 'GETConsultaFinanceiro/?parametro=' + texto, httpOptions).subscribe(
        (success) => {
          this.usuario = success;
          this.qto = success.length;
        }

      )
    } else {
      this.textoconsulta = "";
      this.GetLista();
    }
  }


  //modal
  Operacao() {
    if (this.opcao == "Ativar") {
      this.service.ConcederReembolso(this.iddeducaocompra).subscribe(
        (success) => {
          this.alert.AlertShowInfo('Reembolso Ativado com sucesso!');
          this.GetLista();
        },
        (error) => {
          this.alert.AlertShowDanger('Erro ao tentar realizar reembolso!');
          this.GetLista();
        }
      );
    }

    if (this.opcao == "Inativar") {
      this.service.RetirarReembolso(this.iddeducaocompra).subscribe(
        (success) => {
          this.alert.AlertShowInfo('Reembolso removido com sucesso!');
          this.GetLista();
        },
        (error) => {
          this.alert.AlertShowDanger('Erro ao tentar remover reembolso!');
          this.GetLista();
        }
      );
    }
    if (this.opcao == "Cancelar") {
      this.service.ExcluirCompra(this.iddeducaocompra).subscribe(
        (success) => {
          this.alert.AlertShowInfo('Compra excluida com sucesso!');
          this.GetLista();
        },
        (error) => {
          this.alert.AlertShowDanger('Erro ao tentar excluir compra!');
          this.GetLista();
        }
      );
    }
  }



  SetDados(event) {
    this.iddeducaocompra = event.currentTarget.value;
  }

  SetOpcao(event) {
    this.opcao = event.currentTarget.value;
  }

  SetDataInicial(event) {
    this.datainicial = event.currentTarget.value;
  }

  SetDataFinal(event) {
    this.datafinal = event.currentTarget.value;
  }

  SetMinisterio(event) {
    this.ministeriorelatorio = event.currentTarget.value;
  }

  SetTipoMinisterio(event) {
    this.ministeriotiporelatorio = event.currentTarget.value;
  }

  GerarRelatorio() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token
      })
    }
    this.http.get(this.urlgestao + 'GetRelatorioFinanceiro/?datainicial=' + this.datainicial+'&datafinal='+this.datafinal+'&ministerio='+this.ministeriorelatorio+'&tipo='+this.ministeriotiporelatorio, httpOptions).subscribe(
      (success) => {
        const linkSource = 'data:application/pdf;base64,' + success;
        const downloadLink = document.createElement("a");
        const fileName = "IEPMD_Compras_"+this.ministeriorelatorio+"_" + (this.autenticado.nome).replace(" ", "") + ".pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }, (error) => {
        if (error.status == '403') {
          this.alert.AlertShowDanger('Erro ao tentar Gerar PDF');
        } else {
          const linkSource = 'data:application/pdf;base64,' + error.error.text;
          const downloadLink = document.createElement("a");
          const fileName = "IEPMD_Compras_"+this.ministeriorelatorio+"_" + (this.autenticado.nome).replace(" ", "") + ".pdf";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      }
    );
  }




}
