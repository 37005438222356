<div class="col-md-12">
    <div class="row" style="padding-left: 50px; padding-right: 50px; padding-top: 30px;">
        <div class="card col-md" style="padding: 0%; padding-top: 1px;">
            <img src="../../assets/img/novos/Cadastro de Membros.png" class="card-img-top" alt="..." height="100px">
            <div class="card-body">
                <h5 class="card-title">Cadastro de Membros</h5>
                <label class="card-text " style="text-align: justify;">Se você e membro de nossa igreja não deixe de se
                    cadastrar para que possa receber todos os comunicados e eventos!</label>
                <br>
                <br>
                <a routerLink="/cadastro-membros" class="btn btn-primary " style="align-items: center;">Cadastrar-se</a>
            </div>
        </div>
        <div class="card col-md" style="padding: 0%; padding-top: 1px;">
            <img src="../../assets/img/novos/Ministério Infantil.png" class="card-img-top " alt="..." height="100px">
            <div class="card-body">
                <h5 class="card-title">Ministério Infantil</h5>

                <label class="card-text">Cadastre seu filho de 3 anos até 13 anos no ministério dedicado totalmente para
                    eles com ensino da palavra e muito aprendizado.</label>
                <br>
                <br>
                <a routerLink="" class="btn btn-primary " style="align-items: center;">Cadastrar-se</a>
            </div>
        </div>

        <div class="card col-md" style="padding: 0%; padding-top: 1px;">
            <img src="../../assets/img/novos/Estudos Bíblicos.png" class="card-img-top" alt="..." height="100px">
            <div class="card-body">
                <h5 class="card-title">Recebe Estudos</h5>
                <label class="card-text">Quer receber aleatoriamente uma estudo da palabra de Deus para abençoar a sua
                    vida!, então não perca tempo e cadastre-se</label>
                <br>
                <br>
                <a href="https://t.me/moradadedeus" target="_blank" class="btn btn-primary " style="align-items: center;">Cadastrar-se</a>
            </div>
        </div>




    </div>

</div>
<div class="col-md" style="padding-top: 10px; padding-bottom: 10px; padding-left: 50px;">
    <button pButton pRipple type="button" icon="pi pi-home" class="p-button-rounded p-button-help" title="::HOME::"
        routerLink=""></button>
</div>
