import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { seminariomodel } from 'src/app/seminarios/seminario-cadastro/seminariomodel';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { Parametros } from 'src/app/shared/parametros.enum';
import { SharedModule } from 'src/app/shared/shared.module';

@Component({
  selector: 'app-resumo-cadastro-seminario',
  templateUrl: './resumo-cadastro-seminario.component.html',
  styleUrls: ['./resumo-cadastro-seminario.component.css']
})
export class ResumoCadastroSeminarioComponent implements OnInit {

  private urlresumo = Parametros.RESUMO;

  x = 1;
  y = 10;
  filtro_cabecalho: string = ""
  explicacao: string = "";
  rota: any;
  perfil: any;
  pagina_atual = 1;
  blob: Blob;
  tipo_culto: any;
  quantidade: string;


  constructor(private http: HttpClient,
    private primengConfig: PrimeNGConfig,
    private alert: AlertModalService,
    private autenticado: SharedModule,
    r: Router
  ) {
    this.rota = r;
    this.perfil = this.autenticado.perfil;
  }

  startedClass = false;
  completedClass = false;
  preventAbuse = false;

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//

  totalizadores: Array<seminariomodel>;
  qto: number;
  cols: any[];


  GetLista() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get<seminariomodel[]>(this.urlresumo + 'GetTotalSeminarioRegistrado', httpOptions).subscribe(
      (success) => {
        this.totalizadores = success;
        this.qto = success.length;
      },
      (error)=>{
        
      }
    )
  }

 

  ngOnInit() {
    if (this.autenticado.token == null) {
      this.autenticado.ResetDados();
      this.alert.AlertShowDanger('Usuário não autenticado, redirecionando...')
      this.rota.navigate(['/login']);
    } else {
      if (this.autenticado.ValidarToken() != true) {
        this.autenticado.ResetDados();
        this.alert.AlertShowDanger('Token Invalido!')
        this.rota.navigate(['/login']);
      }
    }
    this.primengConfig.ripple = true;
    this.filtro_cabecalho = "Totalizadores por Seminario"
    this.explicacao = "Aqui você está visualizando totalizadores de cadastros por Seminario na Morada de Deus."   
    this.GetLista();
  } 


}
