import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { Parametros } from 'src/app/shared/parametros.enum';
import { SharedModule } from 'src/app/shared/shared.module';
import { ModelCasais } from '../cadastro-casais/CasaisModel';

@Component({
  selector: 'app-detalhe-curso-casais',
  templateUrl: './detalhe-curso-casais.component.html',
  styleUrls: ['./detalhe-curso-casais.component.css']
})
export class DetalheCursoCasaisComponent implements OnInit {
  inscricao: any;
  rota: any;
  private urlacessos = Parametros.CURSOCASAIS;
  urlrelatoriotodosencontromulheres = Parametros.RELATORIOSTODOSCURSOCASAIS;


  usuario: ModelCasais;


  perfil: string;
  dtcadastro: any;
  ativo: string;
  confirmado: string;

  constructor(private http: HttpClient,
    private alert: AlertModalService,
    private autenticado: SharedModule,
    private r: Router) {
    this.rota = r

    this.usuario = new ModelCasais();

  }

  startedClass = false;
  completedClass = false;
  preventAbuse = false;

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//

  ngOnInit(): void {


    if (this.autenticado.token == null) {
      this.autenticado.ResetDados();
      this.alert.AlertShowDanger('Usuário não autenticado, redirecionando...')
      this.rota.navigate(['/login']);
    } else {
      if (this.autenticado.ValidarToken() != true) {
        this.autenticado.ResetDados();
        this.alert.AlertShowDanger('Token Invalido!')
        this.rota.navigate(['/login']);
      }
    }

    this.inscricao = localStorage.getItem('CadastroCursoCasais');
    if (this.inscricao == null || this.inscricao == "") {
      localStorage.removeItem('CadastroCursoCasais')
      this.rota.navigate(['/dashboard/cursos/curso-para-casais/listagem-cadastros']);
    } else {
      //localStorage.removeItem(' ElogioDetalhe')
      this.GetUsuario();
    }

    this.perfil = this.autenticado.perfil;
  }


  GerarPDFEncontroMulheres() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token
      })
    }
    this.http.get(this.urlrelatoriotodosencontromulheres + 'GetRelatorioCasalDetalhado/?parametro=' + this.inscricao, httpOptions).subscribe(
      (success) => {
        const linkSource = 'data:application/pdf;base64,' + success;
        const downloadLink = document.createElement("a");
        const fileName = "IEPMD_Ficha_Cadastro_Casal_" + (this.autenticado.nome).replace(" ", "") + ".pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }, (error) => {
        if (error.status == '403') {
          this.alert.AlertShowDanger('Erro ao tentar Gerar PDF');
        } else {
          const linkSource = 'data:application/pdf;base64,' + error.error.text;
          const downloadLink = document.createElement("a");
          const fileName = "IEPMD_Ficha_Cadastro_Casal_" + (this.autenticado.nome).replace(" ", "") + ".pdf";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      }
    );
  }



  GetUsuario() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get<ModelCasais>(this.urlacessos + 'GETLoadUsuario/?parametro=' + this.inscricao, httpOptions).subscribe(
      (success) => {

        this.usuario = success;
        this.dtcadastro = success.dt_cadastro.replace('T00:00:00','')

        if(success.ativo=='T'){
          this.ativo = "ATIVO";
        }else{
          this.ativo = "INATIVO";
        }

        if(success.confirmado =="T"){
          this.confirmado = "CADASTRO CONFIRMADO";
        }else{
          this.confirmado = "CADASTRO NÃO CONFIRMADO";
        }

      },
      (error) => {
        this.rota.navigate(['/dashboard/cursos/curso-para-casais/listagem-cadastros']);
      }
    )
  }
}


