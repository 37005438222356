import { Component, OnInit } from '@angular/core';
import { EventEmitterService } from '../shared/EventEmitterService';
import { SharedModule } from '../shared/shared.module';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.css']
})
export class NotFoundComponent implements OnInit {

 
  constructor(private autenticado: SharedModule) {
    EventEmitterService.get('loginAlert').emit(false);
  }

  ngOnInit() {
    this.autenticado.ResetDados();
  }
}
