<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>
<!-- Modal -->
<div class="col-md-9 centraliza  flex-auto ">
    <form [formGroup]="formvisitante" class=" centraliza">
        <h3 class="text-left "><img src="../../assets/img/email.jpeg" style="width: 50px;"> Visitante </h3>
        <label>Olá visitante, seja bem vindo(a)!</label><br>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; <label>Nosso coração se alegra em termos sua visita na nossa igreja, fique à
            vontade pois você está na casa de
            DEUS.</label>
        <label>Ressaltamos que todos os dados aqui informados são exclusivos para comunicação jamais
            sendo compartilhado com terceiros.</label><br>
        <label>
            Lembramos que os campos com (<strong class="cor"> *</strong> ) são campos de
            preenchimento obrigatório!
        </label><br>

        <label style="color: red; font-weight: bold;">
            lembramos que o cadastro efetuado nesta aréa não e entendido como cadastro para culto presencial nem para demais eventos que estejam ocorrendo,para culto presencial seu cadastro deve ser efetuado por <a routerLink="/inscricao_culto"><b>AQUI!</b></a>
        </label><br>
        
        

        <hr class="espacamentotopo bold">
        <div class="row" style="margin-top: 10px;">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="username">Nome:</label><strong class="cor">*</strong>
                    <input type="text" class="form-control" name="nome" formControlName="nome"
                        [(ngModel)]="visitante.nome" required placeholder="Informe seu nome"
                        [ngClass]="{ 'is-invalid': submitted && f.nome.errors }">
                    <div *ngIf="submitted && f.nome.errors">
                        <div *ngIf="f.nome.errors.required" class="cor">Preenchimento obrigatório</div>
                    </div>
                </div>
            </div>

            <div class="col-md-3">
                <div class="dropdown">
                    <label>Estado Civil</label><strong class="cor">*</strong>
                    <div class="mb-3">
                        <select class="custom-select" type="text" (change)="changeestadocivil($event)"
                            name="estado_civil" id="estado_civil" [(ngModel)]="visitante.estado_civil"
                            formControlName="estado_civil"
                            [ngClass]="{ 'is-invalid': submitted && f.estado_civil.errors }">
                            <div *ngIf="submitted && f.estado_civil.errors" class="invalid-feedback"></div>
                            <option value=""></option>
                            <option *ngFor="let d of cbestadocivil" [ngValue]="d">
                                {{ d }}
                            </option>
                        </select>
                        <div *ngIf="submitted && f.estado_civil.errors" class="invalid-feedback">
                            <div *ngIf="f.estado_civil.errors.required">
                                Preenchimento obrigatório
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-3">
                <div class="form-group">
                    <label>Data Nascimento:</label><strong class="cor">*</strong>
                    <input type="date" class="form-control" id="dt_nascimento" placeholder="Data Culto"
                        name="dt_nascimento" maxlength="10" minlength="10" autocomplete="off"
                        formControlName="dt_nascimento" [(ngModel)]="visitante.dt_nascimento"
                        [ngClass]="{ 'is-invalid': submitted && f.dt_nascimento.errors }" />
                    <div *ngIf="submitted && f.dt_nascimento.errors" class="invalid-feedback">
                        <div *ngIf="f.dt_nascimento.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>
            </div>
        </div>



        <div class="row">
            <div class="col-md-4">
                <div class="form-group">
                    <label for="username">Telefone/Celular:</label><strong class="cor">*</strong>
                    <div class="input-group mb-1">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1"><i class="fas fa-phone"></i></span>
                        </div>
                        <input type="text" class="form-control" id="telefone" name="telefone"
                            [(ngModel)]="visitante.telefone" placeholder="Informe o Telefone"
                            title="Formato (DD) 0000-0000 ou (DD) 00000-0000" formControlName="telefone"
                            autocomplete="off" [ngClass]="{ 'is-invalid': submitted && f.telefone.errors }"
                            mask="(00) 0000-0000 || (00) 00000-0000">
                    </div>
                    <div *ngIf="submitted && f.telefone.errors">
                        <div *ngIf="f.telefone.errors.required" class="cor">Preenchimento obrigatório</div>
                    </div>
                </div>
            </div>
            <div class="col-md-5">
                <div class="form-group">
                    <label for="username">Email:</label>
                    <div class="input-group mb-1">
                        <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1"><i class="fas fa-at"></i></span>
                        </div>
                        <input type="text" class="form-control" name="email" formControlName="email"
                            [(ngModel)]="visitante.email" placeholder="Informe seu Email">
                    </div>
                </div>
            </div>

            <div class="col-md-3">
                <div class="form-group">
                    <label>Data Culto:</label><strong class="cor">*</strong>
                    <input type="date" class="form-control" id="dt_culto" placeholder="Data Culto" name="dt_culto"
                        maxlength="10" minlength="10" autocomplete="off" formControlName="dt_culto"
                        [(ngModel)]="visitante.dt_culto" [ngClass]="{ 'is-invalid': submitted && f.dt_culto.errors }" />
                    <div *ngIf="submitted && f.dt_culto.errors" class="invalid-feedback">
                        <div *ngIf="f.dt_culto.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group">
                    <label for="username">Pertence a qual Igreja?:</label>
                    <input type="text" class="form-control" name="igreja_pertence" formControlName="igreja_pertence"
                        [(ngModel)]="visitante.igreja_pertence" placeholder="Pertence a qual igreja">

                </div>
            </div>

            <div class="col-md-3">
                <div class="dropdown">
                    <label>Primeira Visita?</label><strong class="cor">*</strong>
                    <div class="mb-3">
                        <select class="custom-select" type="text" (change)="changeprimeiravisita($event)"
                            name="primeira_visita" id="primeira_visita" [(ngModel)]="visitante.primeira_visita"
                            formControlName="primeira_visita"
                            [ngClass]="{ 'is-invalid': submitted && f.primeira_visita.errors }">
                            <div *ngIf="submitted && f.primeira_visita.errors" class="invalid-feedback"></div>
                            <option value=""></option>
                            <option *ngFor="let d of cbprimeiravisita" [ngValue]="d">
                                {{ d }}
                            </option>
                        </select>
                        <div *ngIf="submitted && f.primeira_visita.errors" class="invalid-feedback">
                            <div *ngIf="f.primeira_visita.errors.required">
                                Preenchimento obrigatório
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-3">
                <div class="dropdown">
                    <label>Deseja uma Visita?:</label><strong class="cor">*</strong>
                    <div class="mb-3">
                        <select class="custom-select" type="text" (change)="changerecebervisita($event)"
                            name="receber_visita" id="receber_visita" [(ngModel)]="visitante.receber_visita"
                            formControlName="receber_visita"
                            [ngClass]="{ 'is-invalid': submitted && f.receber_visita.errors }">
                            <div *ngIf="submitted && f.receber_visita.errors" class="invalid-feedback"></div>
                            <option value=""></option>
                            <option *ngFor="let d of cbrecebervisita" [ngValue]="d">
                                {{ d }}
                            </option>
                        </select>
                        <div *ngIf="submitted && f.receber_visita.errors" class="invalid-feedback">
                            <div *ngIf="f.receber_visita.errors.required">
                                Preenchimento obrigatório
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <div class="form-group">
                    <label for="username">Foi convidado(a)? se sim nos informe quem fez o convite:</label>
                    <input type="text" class="form-control" name="convite" formControlName="convite"
                        [(ngModel)]="visitante.convite" placeholder="Informe o nome de quem lhe convidou">
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md">
                <div class="form-group">
                    <label style="margin-bottom: 0%;">Mensagem:</label><br>
                    <span style="font-size: x-small; margin-top: 0%; margin-bottom: 0%;">Se deseja deixar algum
                        recado
                        adicional, fique à vontade de descrever abaixo!</span>
                    <textarea type="text" rows="3" class="form-control" id="mensagem" aria-describedby="emailHelp"
                        name="mensagem" formControlName="mensagem" [(ngModel)]="visitante.mensagem" autocomplete="off"
                        maxlength="900"></textarea>
                    <span>{{ visitante.mensagem.length }}/900</span>
                </div>
            </div>
        </div>
        <!----->
        <div class="row">
            <div class="col-md">
                <div class="form-group">
                    <input type="checkbox" id="receber_copia" data-md-icheck formControlName="receber_copia"
                        [(ngModel)]="copia" (change)="ReceberCopia($event)" />
                    Receber Copia.<br>
                    <span style="margin-top: 0%; margin-bottom: 0%; font-size: x-small; padding-top: 0%;">Para receber uma cópia e
                        necessário
                        ter e-mail valido informado! </span>
                </div>

            </div>
        </div>
     
            <div>
                <button pButton pRipple type="button" icon="pi pi-send" class="p-button-rounded" title="::Enviar::"
                    (click)="onSubmit()">
                </button>
                <button pButton pRipple type="button" icon="pi pi-home" class="p-button-rounded p-button-help"
                    title="::Home::" routerLink="" style="margin-left: 10px;">
                </button>
                <a href="https://api.whatsapp.com/send?l=pt_BR&phone=55032988189440&text=Problemas Cadastro Visitantes"
                style="text-align: right; float: right;" title="Fale Conosco" target="_blank">Informar Problema!</a>
            </div>
        
    </form>
</div>
