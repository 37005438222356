import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CursoModel } from './CursosModel';
import { UsuarioModel } from 'src/app/Recuperacoes/recuperar-autenticacao/usuario-model';
import { HttpClient } from '@angular/common/http';
import { PrimeNGConfig } from 'primeng/api';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { MembrosCursosAjustesService } from './membros-cursos-ajustes.service';
import { PerfilService } from '../../perfil/perfil.service';

@Component({
  selector: 'app-membros-cursos-ajustes',
  templateUrl: './membros-cursos-ajustes.component.html',
  styleUrls: ['./membros-cursos-ajustes.component.css']
})
export class MembrosCursosAjustesComponent implements OnInit {

  rota: Router;
  perfil: string;
  nome: string;
  cpf: string;
  idusuario: string;


  formusuario: FormGroup;
  formcursos: FormGroup;
  submitted = false;
  modelusuario: UsuarioModel;
  modelcurso: CursoModel;

  teologia: boolean = false;
  casais: boolean = false;

  constructor(private http: HttpClient,
    private primengConfig: PrimeNGConfig,
    private alert: AlertModalService,
    private autenticado: SharedModule,
    private formBuilder: FormBuilder,
    private serviceUsuario: PerfilService,
    private servicecursos: MembrosCursosAjustesService,
    r: Router
  ) {
    this.rota = r;
    this.perfil = this.autenticado.perfil;
    this.nome = this.autenticado.nome;
    this.cpf = this.autenticado.cpf;
    this.modelusuario = new UsuarioModel();
    this.modelcurso = new CursoModel();

  }

  startedClass = false;
  completedClass = false;
  preventAbuse = false;

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//


  criarformusuario() {
    this.formusuario = this.formBuilder.group({
      cpf: ['', Validators.required],
      nome: ['', Validators.required]

    });
  }

  criarformcurso() {
    this.formcursos = this.formBuilder.group({
      idusuario: [this.idusuario, Validators.required],
      casais: [false, Validators.required],
      teologia: [false, Validators.required]
    });
  }



  ngOnInit(): void {
    if (this.autenticado.token == null) {
      this.autenticado.ResetDados();
      this.alert.AlertShowDanger('Usuário não autenticado, redirecionando...')
      this.rota.navigate(['/login']);
    } else {
      if (this.autenticado.ValidarToken() != true) {
        this.autenticado.ResetDados();
        this.alert.AlertShowDanger('Token Invalido!')
        this.rota.navigate(['/login']);
      }
    }

    this.idusuario = localStorage.getItem('UsuarioPerfilDetalhe');
    if (this.idusuario == null || this.idusuario == "") {
      this.rota.navigate(['/dashboard/perfil/perfil-usuario']);
    } else {
      localStorage.removeItem('UsuarioPerfilDetalhe');


      this.criarformusuario();
      this.criarformcurso();
      this.carregarusuario();
      this.CarregarCursos();
    }
  }

  carregarusuario() {
    this.serviceUsuario
      .GETUsuarioID(this.idusuario)
      .subscribe(
        (success) => {
          this.StopProgressBar();
          if (success.nome != null) {
            (this.modelusuario.nome = success.nome),
              (this.modelusuario.cpf = success.cpf)
          } else {
            this.rota.navigate(['/dashboard/perfil/perfil-usuario']);
          }
        },
        (error) => {
          this.StopProgressBar();
          this.alert.AlertShowDanger(
            '( ' + error.status + ' ) ' + error.message
          );
        }
      );
  }


  get c() {
    return this.formcursos.controls;
  }

  get u() {
    return this.formusuario.controls;
  }



  CarregarCursos() {
    this.servicecursos
      .GETCursos(this.idusuario)
      .subscribe(
        (success) => {
          this.StopProgressBar();
          this.modelcurso.idusuario = this.idusuario;

          if (success.teologia == "T") {
            this.modelcurso.teologia = true;
            this.teologia = true;
          }

          if (success.casais == "T") {
            this.modelcurso.casais = true;
            this.casais = true;
          }
        },
        (error) => {
          this.StopProgressBar();
          this.alert.AlertShowDanger(
            '( ' + error.status + ' ) ' + error.message
          );
        }
      );
  }


  onSubmit() {
    this.submitted = true;

    if (this.formusuario.invalid) {
      this.alert.AlertShowDanger('Favor verificar os campos em destaque!');
      return;
    }
    if (this.formcursos.invalid) {
      this.alert.AlertShowDanger('Favor verificar os campos em destaque!');
      return;
    }

    this.servicecursos.POSCurso(this.formcursos.value).subscribe(
      () => {
        this.alert.AlertShowSucess('Cursos atualizados com sucesso!');
      },
      (error) => {
        this.alert.AlertShowDanger('Erro ao Atualizar Cursos!');
      });
  }

}
