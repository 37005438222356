import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { Parametros } from 'src/app/shared/parametros.enum';
import { SharedModule } from 'src/app/shared/shared.module';
import { patrimoniomodel } from '../registrar-patrimonio/PatrimonioModel';
import { ListagemPatrimonioService } from './listagem-patrimonio.service';
import { MoverModel } from './MoverModel';
import{InativarModel} from './InativarModel';
import { ManutencaoModel } from './ManutencaoModel';

declare var jQuery: any;
@Component({
  selector: 'app-listagem-patrimonio',
  templateUrl: './listagem-patrimonio.component.html',
  styleUrls: ['./listagem-patrimonio.component.css']
})
export class ListagemPatrimonioComponent implements OnInit {

  private urlpatrimonio = Parametros.PATRIMONIO;

  padrao: any = [
    'SALÃO',
    'COZINHA',
    'SECRETARIA',
    'SOM/MIDIA',
    'USO E CONSUMO',
    'BANHEIRO',
    'EVENTOS',
    'EXTERNO',
    'GERAL',
    'OUTROS'

  ];

  x = 1;
  y = 10;
  filtro_cabecalho: string = ""
  explicacao: string = "";
  textoconsulta: string = "";
  rota: any;
  perfil: any;
  pagina_atual = 0;
  primeiro_registro = 0;
  ultimo_registro = 0;
  imprimir: string = "Filtro:Todos";
  blob: Blob;
  idculto: any;
  nome: string;
  setor: string = "TODOS SETORES";
  _perfil: string = "";

  setores: any;


  tipoculto: any;
  CurrentPageReport: number;
  idmover: any;
  setoratual: any;
  setornovo: any;
  submitted: boolean;
  formcadastro: FormGroup;
  forminativar: FormGroup; 
  contato: any;
  inativar: InativarModel;
 
 



  constructor(private http: HttpClient,
    private primengConfig: PrimeNGConfig,
    private alert: AlertModalService,
    private autenticado: SharedModule,
    private service: ListagemPatrimonioService,
    private formBuilder: FormBuilder,
    r: Router
  ) {
    this.rota = r;
    this.perfil = this.autenticado.perfil;
    this.nome = this.autenticado.nome;
    
  }

  startedClass = false;
  completedClass = false;
  preventAbuse = false;

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//

  usuario: Array<patrimoniomodel>;
  qto: number;
  cols: any[];






  GetListaTotal() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get<any>(this.urlpatrimonio + 'GETTotalPatrimonio/?parametro=' + this.setor, httpOptions).subscribe(
      (success) => {

        this.qto = success

        if (this.x > success) {
          this.x = 1;
          this.y = success;
        }
      }
    )
  }

  GETListagemCultos() {
    this.StartProgressBar();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token
      })
    };
    return this.http
      .get<patrimoniomodel>(this.urlpatrimonio + 'GETlistaSetores', httpOptions).subscribe(
        (success) => {
          this.setores = success;
        },
        (error) => {

        }
      )
  }


  GetListaConsultaLimite(pagina: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.GetListaTotal()
    if(this.setor=="" || this.setor==null){
      this.setor="TODOS SETORES";
    }
    this.http.get<any>(this.urlpatrimonio + 'GetListaConsultaLimite/?parametro=' + this.setor + '&pagina=' + pagina, httpOptions).subscribe(
      (success) => {
        this.usuario = success;

      },
      (error) => {
      }
    )
  }






  ngOnInit() {
    if (this.autenticado.token == null) {
      this.autenticado.ResetDados();
      this.alert.AlertShowDanger('Usuário não autenticado, redirecionando...')
      this.rota.navigate(['/login']);
    } else {
      if (this.autenticado.ValidarToken() != true) {
        this.autenticado.ResetDados();
        this.alert.AlertShowDanger('Token Invalido!')
        this.rota.navigate(['/login']);
      }
    }
    this.primengConfig.ripple = true;
    this.filtro_cabecalho = "Listagem de todos patrimonios Cadastrados"
    this.explicacao = "Listagem de todos patrimonios ATIVOS cadastrados."
    this.GetListaTotal();
    this.GetListaConsultaLimite(this.pagina_atual);
    this.GETListagemCultos();

    this.cols = [
      { field: 'Num.Patrimonio', header: 'Num.Patrimonio' },
      { field: 'Patrimonio', header: 'Patrimonio' },
      { field: 'Valor', header: 'Valor' },
      { field: 'Setor', header: 'Setor' }
      

    ];

    this.setor = localStorage.getItem('SetorPatrimonio');
    if (this.setor == null || this.setor == "") {

    } else {
      this.Consulta();
      localStorage.removeItem('SetorPatrimonio')
    }

    this.contato = new MoverModel();
    this.inativar = new InativarModel();   
    this.criarform();
    this.criarformInativar();
   
  }

  Paginate(event) {
    if (event.page == 0) {
      this.x = 1;
      this.y = 10;
    } else {

      if (event.page * 10 > this.qto) {
        this.x = this.qto;
        this.y = this.qto;
      } else {
        this.x = event.page * 10;
        if (this.x + 10 > this.qto) {
          this.y = this.qto;
        } else {
          this.y = this.x + 10;
        }
      }
    }


    this.pagina_atual = event.page;

    this.GetListaConsultaLimite(this.pagina_atual);

  }

  Consulta() {
    var texto = (<HTMLInputElement>document.getElementById("texto_consulta")).value;
    if (texto != "") {
      this.textoconsulta = texto;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
        })
      }
      this.http.get<patrimoniomodel[]>(this.urlpatrimonio + 'GetListaConsultaSetor/?parametro=' + texto + '&tipo=' + this.setor, httpOptions).subscribe(
        (success) => {
          this.usuario = success;
          this.qto = success.length

          this.x = 1;
          this.y = success.length;

        }
      )
    } else {
      this.textoconsulta = "";
      this.GetListaConsultaLimite(this.pagina_atual);
    }
  }

 

  SetDados(event) {
    this.idculto = event.currentTarget.value;
  }


  SetDadosMover(event) {
    this.idmover = event.currentTarget.value;
  }



  SetConsultaSeminario(event) {
    this.tipoculto = event.currentTarget.value;
    this.GetListaConsultaLimite(this.pagina_atual);
    this.GetListaTotal();
  }

  SetSetorMover(event) {
    this.setornovo = event.currentTarget.value;
  }

  criarform() {
    this.formcadastro = this.formBuilder.group({
      setor: ['', Validators.required]
    });
  }

  criarformInativar() {
    this.forminativar = this.formBuilder.group({
      motivo: ['', Validators.required,Validators.minLength(5),Validators.maxLength(500)]
    });
  }

  

  
  get f() {
    return this.formcadastro.controls;
  }
  get g() {
    return this.forminativar.controls;
  }

  get m() {
    return this.forminativar.controls;
  }
  
  Mover() {
    this.submitted = true;
    if (this.formcadastro.controls['setor'].value == "") {
      this.alert.AlertShowDanger('Favor preencher o setor corretamente!');
    } else {
      this.setor = this.formcadastro.controls['setor'].value
      this.service
        .MoverSetor(this.idmover, this.setoratual,this.setor )
        .subscribe(
          () => {
            jQuery("#exampleModalMover").modal("hide");
            this.StopProgressBar();
            this.alert.AlertShowSucess('Patrimonio movido com sucesso');
            this.GetListaConsultaLimite(this.pagina_atual);
            this.GetListaTotal();
            this.GETListagemCultos();
          },
          (error) => {
            jQuery("#exampleModalMover").modal("hide");
            this.StopProgressBar();
            this.alert.AlertShowDanger('( ' + error.status + ' ) ' + error.error);
          }
        );
    }
  }

    
  Inativar() {
    this.submitted = true;
    if (this.forminativar.controls['motivo'].value == "") {
      this.alert.AlertShowDanger('Favor preencher o Motivo corretamente!');
    } else {
      this.service
        .InativarPatrimonio(this.idmover, this.forminativar.controls['motivo'].value)
        .subscribe(
          () => {
            jQuery("#exampleModal1").modal("hide");
            this.StopProgressBar();
            this.alert.AlertShowSucess('Patrimonio Inativo com sucesso');
            this.GetListaConsultaLimite(this.pagina_atual);
            this.GetListaTotal();
            this.criarformInativar();
          },
          (error) => {
            jQuery("#exampleModal1").modal("hide");
            this.StopProgressBar();
            this.alert.AlertShowDanger('( ' + error.status + ' ) ' + error.error);
          }
        );
    }
  }

  Manutencao() {
    this.submitted = true;
    if (this.forminativar.controls['motivo'].value == "") {
      this.alert.AlertShowDanger('Favor preencher a manutenção/Observação corretamente!');
    } else {
      this.service
        .Manutencao(this.idmover, this.forminativar.controls['motivo'].value)
        .subscribe(
          () => {
            jQuery("#exampleModal2").modal("hide");
            this.StopProgressBar();
            this.alert.AlertShowSucess('Manutenção/Observação registrada com sucesso');
            this.GetListaConsultaLimite(this.pagina_atual);
            this.GetListaTotal();
           this.criarformInativar();
          },
          (error) => {
            jQuery("#exampleModal2").modal("hide");
            this.StopProgressBar();
            this.alert.AlertShowDanger('( ' + error.status + ' ) ' + error.error);
          }
        );
    }
  }


  VisualizarHistorico(event) {
    localStorage.removeItem('HistoricoPatrimonio');
    localStorage.setItem('HistoricoPatrimonio', event.currentTarget.value);
    this.rota.navigate(['/dashboard/gerenciamento/patrimonio/historico-patrimonio']);
  }


}
