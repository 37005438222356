export class seminarioinfantilmodel {
   
    cpf: string = '';
    responsavel: string = '';
    tipo_culto: string = '';   
    email: string = '';
    telefone: string='';
    contato_confianca: string='';
    nome_filho: string = '';
    idade: string = '';
    observacoes: string = '';    
    cep: string = '';  
    logradouro: string = '';
    numero: number=0;
    apto: number=0;   
    bairro: string = '';
    uf: string = '';
    cidade: string = '';   
    dados_culto: string = '';    
    localidade:string='';
    dt_cadastro:any;
    idcultokids:any;
    quantidade:any;

    //usado no checkout
    rg: any;
    hash: any;
    certificado_impresso: any;
    dt_impresso: any;
    ativo: any;
    referencia: any;
    confirmado: any;
    data_confirmado: any;
    hora_confirmado: any;
    para: any;
    assunto: any;
    mensagem: any;
    anexo: any;
   }