import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NavigationEnd, Router } from '@angular/router';
import { PedidoVisitaService } from '../pedido-visita/pedido-visita.service';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { VisitaModel } from '../pedido-visita/VisitaModel';
import { SharedModule } from 'src/app/shared/shared.module';

@Component({
  selector: 'app-pedido-visita',
  templateUrl: './pedido-visita.component.html',
  styleUrls: ['./pedido-visita.component.css']
})
export class PedidoVisitaComponent implements OnInit {

  formvisita: FormGroup;
  submitted = false;
  visitamodel: VisitaModel;
  receber_copia: boolean;

  startedClass = false;
  completedClass = false;
  preventAbuse = false;
  segunda_feira: boolean = false;
  terca_feira: boolean = false;
  quarta_feira: boolean = false;
  quinta_feira: boolean = false;
  sexta_feira: boolean = false;
  sabado: boolean = false;
  domingo: boolean = false;
  todos: boolean = false;

  rota: Router;
  cbmelhorhorario: any = [
    'MANHÃ (Antes das 11:00hs)',
    'TARDE (Antes das 18:00hs)',
    'NOITE (Após as 18:00hs)',
  ];

  cbtipovisita: any = [
    'ACONSELHAMENTO',
    'ENFERMIDADE', 'DIFICULDADES', 'RELACIONAMENTO', 'OUTROS'];

  constructor(
    private formBuilder: FormBuilder,
    private alert: AlertModalService,
    private visitaservice: PedidoVisitaService,
    private router: Router,
    private autenticado: SharedModule,
  ) {
    this.rota = this.router;
    this.visitamodel = new VisitaModel();

  }



  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//

  reset() {
    this.visitamodel = new VisitaModel();
    this.submitted = false;
    this.formvisita.get('idusuario').setValue(this.autenticado.iduser);
    this.formvisita.get('email').setValue(this.autenticado.email);
    this.formvisita.get('cpf').setValue(this.autenticado.cpf);
    this.formvisita.get('nome').setValue(this.autenticado.nome);
  }
  get f() {
    return this.formvisita.controls;
  }

  CKSegunda(e) {
    if (e.target.checked == true) {
      this.visitamodel.segunda_feira == true;
    } else {
      this.visitamodel.segunda_feira == false;
    }
  }
  CKTerca(e) {
    if (e.target.checked == true) {
      this.visitamodel.terca_feira == true;
    } else {
      this.visitamodel.terca_feira == false;
    }
  }
  CKQuarta(e) {
    if (e.target.checked == true) {
      this.visitamodel.quarta_feira == true;
    } else {
      this.visitamodel.quarta_feira == false;
    }
  }
  CKQuinta(e) {
    if (e.target.checked == true) {
      this.visitamodel.quinta_feira == true;
    } else {
      this.visitamodel.quinta_feira == false;
    }
  }
  CKSexta(e) {
    if (e.target.checked == true) {
      this.visitamodel.sexta_feira == true;
    } else {
      this.visitamodel.sexta_feira == false;
    }
  }
  CKSabado(e) {
    if (e.target.checked == true) {
      this.visitamodel.sabado == true;
    } else {
      this.visitamodel.sabado == false;
    }
  }
  CKDomingo(e) {
    if (e.target.checked == true) {
      this.visitamodel.domingo == true;
    } else {
      this.visitamodel.domingo == false;
    }
  }
  CKTodos(e) {
    if (e.target.checked == true) {
      this.formvisita.controls['segunda_feira'].setValue(true);
      this.formvisita.controls['terca_feira'].setValue(true);
      this.formvisita.controls['quarta_feira'].setValue(true);
      this.formvisita.controls['quinta_feira'].setValue(true);
      this.formvisita.controls['sexta_feira'].setValue(true);
      this.formvisita.controls['sabado'].setValue(true);
      this.formvisita.controls['domingo'].setValue(true);


    } else {
      this.formvisita.controls['segunda_feira'].setValue(false);
      this.formvisita.controls['terca_feira'].setValue(false);
      this.formvisita.controls['quarta_feira'].setValue(false);
      this.formvisita.controls['quinta_feira'].setValue(false);
      this.formvisita.controls['sexta_feira'].setValue(false);
      this.formvisita.controls['sabado'].setValue(false);
      this.formvisita.controls['domingo'].setValue(false);

    }
  }
  get cityNames() {
    return this.formvisita.get('tipo');
  }

  changevisita(e) {
    this.cityNames.setValue(e.target.value, {
      onlySelf: true,
    });
  }

  get mhorario() {
    return this.formvisita.get('melhor_horario');
  }

  changemelhorhorario(e) {
    this.mhorario.setValue(e.target.value, {
      onlySelf: true,
    });
  }

  ReceberCopia(e) {
    if (e.target.checked == true && this.autenticado.email == '') {
      this.receber_copia = false;
      this.alert.AlertShowDanger(':: AVISO ::\nPara que possa receber uma cópia do Elogio e necessário ter e-mail valido em seu cadastro! \nPor favor preencha um e-mail valido em seu Perfil e tente novamente!');
    }

  }
  //BOTAO SALVAR
  //====================================================================//
  onSubmit() {
    this.submitted = true;
    if (this.formvisita.invalid) {
      this.alert.AlertShowDanger('Favor verificar os campos em destaque!');
      return;
    }
    else if (this.segunda_feira == false &&
      this.terca_feira == false &&
      this.quarta_feira == false &&
      this.quinta_feira == false &&
      this.sexta_feira == false &&
      this.sabado == false &&
      this.domingo == false) {

      this.alert.AlertShowDanger('Favor Preencher um dia de preferencia!');
      return;
    } else if (this.formvisita.get('telefone').value.length < 10) {
      this.alert.AlertShowDanger('Favor Preencher Telefone corretamente');
    }
    else {
      this.visitaservice
        .CadastrarVisita(this.autenticado.token, this.formvisita.value)
        .subscribe(
          (sucess) => {
            this.StopProgressBar();
            this.alert.AlertShowSucess('Pedido de Visita Enviado com Sucesso');
            this.visitamodel = new VisitaModel();
            this.criarform();
            this.visitamodel.cpf = this.autenticado.cpf;
            this.visitamodel.nome = this.autenticado.nome;
            this.formvisita.get('email').setValue(this.autenticado.email);
            this.formvisita.get('idusuario').setValue(this.autenticado.iduser);
            this.formvisita.get('cpf').setValue(this.autenticado.cpf);
            this.formvisita.get('nome').setValue(this.autenticado.nome);
            document.getElementById('telefone').focus();
            this.submitted = false;


          },
          (error) => {
            this.StopProgressBar();
            this.alert.AlertShowDanger('( ' + error.status + ' ) ' + error.error);
          }
        );
    }
  }
  //====================================================================//
  //====================================================================//
  ngOnInit() {
    if (this.autenticado.token == null) {
      this.autenticado.ResetDados();
      this.alert.AlertShowDanger('Usuário não autenticado, redirecionando...')
      this.rota.navigate(['/login']);
    } else {
      if (this.autenticado.ValidarToken() != true) {
        this.autenticado.ResetDados();
        this.alert.AlertShowDanger('Token Invalido!')
        this.rota.navigate(['/login']);
      } else {
        try {
          this.visitamodel = new VisitaModel();
          this.criarform();
          this.visitamodel.cpf = this.autenticado.cpf;
          this.visitamodel.nome = this.autenticado.nome;
          this.formvisita.get('idusuario').setValue(this.autenticado.iduser);
          this.formvisita.get('email').setValue(this.autenticado.email);

        } catch {
          this.rota.navigate(['/dashboard/resumo']);
        }
      }
    }
  }
  //====================================================================//
  //seta os campos para inicio do form
  //====================================================================//
  criarform() {
    this.formvisita = this.formBuilder.group({
      cpf: ['', Validators.required],
      nome: ['', Validators.required],
      email: [''],
      telefone: ['', Validators.required, Validators.minLength(10)],
      tipo_visita: ['', Validators.required],
      mensagem: ['', Validators.required, Validators.minLength(10)],
      receber_copia: [false],
      segunda_feira: [false],
      terca_feira: [false],
      quarta_feira: [false],
      quinta_feira: [false],
      sexta_feira: [false],
      todos: [false],
      sabado: [false],
      domingo: [false],
      melhor_horario: ['', Validators.required],
      idusuario: [0, Validators.required]
    });
  }
  //====================================================================//

}
