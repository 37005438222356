import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertModalService } from '../shared/alert-modal/alert-modal.service';
import { Parametros } from '../shared/parametros.enum';
import { cultomodelKids } from './CultoKidsModel';
import { CultoKidsInscricaoService } from './culto-kids-inscricao.service';
import { EventEmitterService } from '../shared/EventEmitterService';

@Component({
  selector: 'app-culto-kids-inscricao',
  templateUrl: './culto-kids-inscricao.component.html',
  styleUrls: ['./culto-kids-inscricao.component.css']
})
export class CultoKidsInscricaoComponent implements OnInit {

  formcultokids: FormGroup;
  submitted = false;
  cadastrocultokids: cultomodelKids;

  startedClass = false;
  completedClass = false;
  preventAbuse = false;
  rota: Router;
  modaltitulo: string;
  modalconteudo: string;
  dados_culto: string = "";
  mostrar: string = "T";
  permitido: boolean = true;
  limite: number = 0;



  cultos: any = [
    'Aniversário da Morada ( TODOS DIAS )'

  ];

  membromorada: any = [
    'SIM',
    'NÃO'];

  idades: any = [
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    '10',
    '11',
    '12'
  ];


  luf: any = [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MT',
    'MS',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO',
  ];

  constructor(
    private formBuilder: FormBuilder,
    private alert: AlertModalService,
    private service: CultoKidsInscricaoService,
    private router: Router
  ) {
    this.rota = this.router;
    EventEmitterService.get('loginAlert').emit(false);
    this.mostrar = "T";

  }

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//

  criarform() {
    this.formcultokids = this.formBuilder.group({
      cpf: ['', Validators.required],
      responsavel: ['', Validators.required],
      contato_confianca: ['', Validators.required],
      tipo_culto: ['', Validators.required],
      idade: ['', Validators.required],
      nome_filho: ['', Validators.required],
      telefone: ['', Validators.required],
      email: [''],
      dados_culto: [''],
      cep: [''],
      logradouro: [''],
      numero: [''],
      apto: [''],
      bairro: [''],
      uf: [''],
      cidade: [''],
      observacoes: ['']
    });
  }

  LimiteCulto() {
    this.service.LimiteCadastro().subscribe(
      (success) => {
        this.limite = success;
      }, (error) => {
        this.limite = 0;
      });
  }


  //BOTAO SALVAR
  //====================================================================//
  onSubmit() {
    this.submitted = true;
    this.cadastrocultokids.dados_culto = this.dados_culto;
    if (this.formcultokids.invalid) {
      this.alert.AlertShowDanger('Favor verificar os campos em destaque!');
      return;
    } else {
      this.service.QuantidadeCadastro(this.formcultokids.controls['tipo_culto'].value).subscribe(
        (success) => {
          var c = parseInt(success.toString());

          if (c >= 35) {
            this.modaltitulo = 'Cadastro Esgotado!';
            this.modalconteudo =
            'Olá!<br>' +
            'Pedimos desculpas mas nossas capacidade para a escolinha foi atingida!<br>' +
            'Neste dia não teremos como ficar com seu filho(a) na salinha KIDS, lamentamos o incoveniente!<br>' +
            'Atenciosamente!<br><b>Gestão Morada de Deus ( IEPMD )</b>';
            document.getElementById('openModalButton').click();
            document.getElementById('cpf').focus();
            return;
          }

        });

      this.service
        .CadastroCulto(this.formcultokids.value)
        .subscribe(
          () => {
            this.StopProgressBar();
            this.submitted = false;
            this.alert.AlertShowSucess('Registro Realizado Com sucesso!');
            localStorage.setItem('tipo', 'CultoKidsRegistrado');
            localStorage.setItem('CultoKids-save', JSON.stringify(this.formcultokids.value));
            this.rota.navigate(['/retorno']);
          },
          (error) => {
            this.StopProgressBar();
            this.permitido = false;
            if (error.status == '200') {
              this.StopProgressBar();
              this.submitted = false;
              this.alert.AlertShowSucess('Registro Realizado Com sucesso!');
              localStorage.setItem('tipo', 'CultoKidsRegistrado');
              localStorage.setItem('CultoKids-save', JSON.stringify('SEM EMAIL'));
              this.rota.navigate(['/retorno']);
            }
            if (error.status == '422') {
              this.permitido = false;
              this.dados_culto = "";
              this.cadastrocultokids = new cultomodelKids();
              this.modaltitulo = 'Cadastro Existente!';
              this.modalconteudo =
                'Olá! <b>' + error.error.cpf + ' ' + error.error.responsavel
                + '</b>,<br>Estava olhando aqui em minhas anotações e verifiquei que já existe seu cadastro em nossa plataforma para seu filho na Salinha KIDS [ <b>' + error.error.tipo_culto + '</b> ], em caso de duvidas não deixe de nos reportar para que possamos analisar e lhe retornar!\nDeus Abençõe!';
              document.getElementById('openModalButton').click();
              document.getElementById('cpf').focus();

            } else if (error.status == '500') {
              this.permitido = false;
              this.dados_culto = "";
              this.alert.AlertShowDanger(error.error);

            } else {
              this.permitido = true;
              if (error.status == '200') {

              } else {
                this.alert.AlertShowDanger(error.error);
              }
              document.getElementById('nome').focus();

            }
          }
        );
    }
    //====================================================================//
  }
  //====================================================================//

  //recarregar Form
  //====================================================================//
  reset() {
    this.cadastrocultokids = new cultomodelKids();
    this.submitted = false;
    this.mostrar = "F";
    document.getElementById('cpf').focus();
  }

  // Pegar nome dos campos,
  //====================================================================//
  get f() {
    return this.formcultokids.controls;
  }

  //Inicio
  //====================================================================//
  ngOnInit() {
    this.cadastrocultokids = new cultomodelKids();
    this.criarform();
    this.LimiteCulto();
    this.formcultokids.controls['numero'].setValue("0");
    this.formcultokids.controls['apto'].setValue("0");
    document.getElementById('cpf').focus();


  }



  //Consulta o cadastro do condominio já existe na base de dados ( API )
  //====================================================================//
  DadosSeminario(t: any) {
    var x = t;
 

    if (x == "Aniversário da Morada ( TODOS DIAS )") {
      this.dados_culto = "Aniversário da Morada ( TODOS DIAS )"
    }

    if (x == "10/10 - Domingo ( 19:30 )") {
      this.dados_culto = "10 de Outubro 2021 de 19:30Hs."
    }

    if (x == "17/10 - Domingo ( 19:30 )") {
      this.dados_culto = "17 de Outubro 2021 de 19:30Hs."
    }
    if (x == "24/10 - Domingo ( 19:30 )") {
      this.dados_culto = "24 de Outubro 2021 de 19:30Hs."
    }

    else if (x == "") {
      this.dados_culto = ""
    }

    this.service.QuantidadeCadastro(x).subscribe(
      (success) => {
        var c = parseInt(success.toString());
        

        if (c == this.limite || c >= this.limite) {
          this.modaltitulo = 'Cadastro Esgotado!';
          this.modalconteudo =
            'Olá!<br>' +
            'Pedimos desculpas mas nossas capacidade para a escolinha foi atingida!<br>' +
            'Neste dia não teremos como ficar com seu filho(a) na salinha KIDS, lamentamos o incoveniente!<br>' +
            'Atenciosamente!<br><b>Gestão Morada de Deus ( IEPMD )</b>';
          document.getElementById('openModalButton').click();

        }

      });

  }


  //Consulta o cadastro do condominio já existe na base de dados ( API )
  //====================================================================//
  consultacpf(cpf: any) {
    if (this.cadastrocultokids.cpf.length >= 11) {
      this.service.Consulta(this.cadastrocultokids.cpf).subscribe(
        (success) => {
          this.StopProgressBar();
          (this.cadastrocultokids.cep = success.cep),
            (this.cadastrocultokids.logradouro = success.logradouro),
            (this.cadastrocultokids.bairro = success.bairro),
            (this.cadastrocultokids.cidade = success.localidade),
            (this.cadastrocultokids.contato_confianca = success.contato_confianca),
            (this.cadastrocultokids.uf = success.uf);
          (this.cadastrocultokids.telefone = success.telefone);
          (this.cadastrocultokids.responsavel = success.responsavel);
          (this.cadastrocultokids.email = success.email);
          (this.cadastrocultokids.nome_filho = success.nome_filho);
          (this.cadastrocultokids.idade = success.idade);
          document.getElementById('tipo_culto').focus();
        },
        (error) => {
          this.StopProgressBar();
          (this.cadastrocultokids.cep = ""),
            (this.cadastrocultokids.logradouro = ""),
            (this.cadastrocultokids.bairro = ""),
            (this.cadastrocultokids.cidade = ""),
            (this.cadastrocultokids.uf = ""),
            (this.cadastrocultokids.telefone = ""),
            (this.cadastrocultokids.contato_confianca = ""),
            (this.cadastrocultokids.responsavel = ""),
            (this.cadastrocultokids.email = "");
          (this.cadastrocultokids.idade = "");
          (this.cadastrocultokids.nome_filho = "");

          this.mostrar = "T";
          document.getElementById('nome').focus();
        }
      );
    }
  }

  //====================================================================//

  //CONSULTA CEP API
  //====================================================================//
  consulta_cep(cep: any) {
    if (cep != '' && cep != null) {
      this.service
        .ConsultaCEP(
          cep
            .replace('.', '')
            .replace('-', '')
            .replace('\\', '')
            .replace('/', '')
        )
        .subscribe(
          (success) => {
            if (success.cep != null) {
              (this.cadastrocultokids.cep = success.cep),
                (this.cadastrocultokids.logradouro = success.logradouro),
                (this.cadastrocultokids.bairro = success.bairro),
                (this.cadastrocultokids.cidade = success.localidade),
                (this.cadastrocultokids.uf = success.uf);
              document.getElementById('numero').focus();
            } else {
              this.alert.AlertShowDanger('CEP não e válido!');
              this.cadastrocultokids.cep = '';
            }
          },
          (error) => {
            this.StopProgressBar();
            this.alert.AlertShowDanger(
              '( ' + error.status + ' ) ' + error.message
            );
          }
        );
    }
  }

  //====================================================================//
  //PEGAR VALOR DIZIMISTA FORM
  //====================================================================//
  get citymembromorada() {
    return this.formcultokids.get('ministerio_morada');
  }
  //====================================================================//
  // Pegar o valor do combobox DIZIMISTA
  //====================================================================//
  changeministeriomorada(e) {
    console.log(e.name);
    this.citymembromorada.setValue(e.target.value, {
      onlySelf: true,
    });


  }
  //====================================================================//

}
