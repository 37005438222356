export class SugestaoModel {

    [x: string]: any;
    cpf: string = '';
    nome: string = '';
    email: string = '';
    telefone: string = '';
    tipo_sugestao: string = '';
    mensagem: string = '';
    receber_copia: boolean = false;   
    idusuario:number;

}
