import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { TrocaSenhaModel } from './trocasenhaModel';
import { TrocarSenhaService } from './trocar-senha.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { PerfilService } from '../perfil/perfil.service';


@Component({
  selector: 'app-trocar-senha',
  templateUrl: './trocar-senha.component.html',
  styleUrls: ['./trocar-senha.component.css']
})
export class TrocarSenhaComponent implements OnInit {

  rota: Router;
  modelsenha: TrocaSenhaModel;
  formtrocasenha: FormGroup;
  startedClass = false;
  completedClass = false;
  preventAbuse = false;
  submitted: boolean;



  constructor(
    private formBuilder: FormBuilder,
    private alert: AlertModalService,
    private servicetrocasenha: TrocarSenhaService,
    private serviceperfil: PerfilService,
    private router: Router,
    private autenticado: SharedModule,
  ) {
    this.rota = this.router;
    this.modelsenha = new TrocaSenhaModel();
  }

  ngOnInit() {
    if (this.autenticado.token == null) {
      this.autenticado.ResetDados();
      this.alert.AlertShowDanger('Usuário não autenticado, redirecionando...')
      this.rota.navigate(['/login']);
    } else {
      if (this.autenticado.ValidarToken() != true) {
        this.autenticado.ResetDados();
        this.alert.AlertShowDanger('Token Invalido!')
        this.rota.navigate(['/login']);
      }
    }
    this.modelsenha = new TrocaSenhaModel();
    this.criarform();
    this.carregarusuario();

  }

  carregarusuario() {
    this.serviceperfil
      .GetUsuaruio(this.autenticado.hash)
      .subscribe(
        (success) => {
          if (success.nome != null) {
            (this.modelsenha.nome = success.nome),
              (this.modelsenha.cpf = success.cpf),
              (this.modelsenha.senha = success.senha),
              (this.modelsenha.login = success.login);
          }
        },
        (error) => {
          this.StopProgressBar();
          this.alert.AlertShowDanger(
            '( ' + error.status + ' ) ' + error.message
          );
        }
      );
  }

  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }

  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  criarform() {
    this.formtrocasenha = this.formBuilder.group({
      cpf: ['', Validators.required],
      nome: ['', Validators.required],
      login: ['', Validators.required],
      senha: [
        '',
        [Validators.required, Validators.min(4)],
      ], senha_nova: [
        '',
        [Validators.required, Validators.min(4)],
      ],
      senha_nova_confirmar: [
        '',
        [Validators.required, Validators.min(4)],
      ]
    });
  }


  onSubmit() {

    this.submitted = true;

    if (this.modelsenha.senha_nova_confirmar != this.modelsenha.senha_nova) {
      this.alert.AlertShowDanger('Confirmação de Senha Diverge de nova Senha!');
      return;
    }

    if (this.modelsenha.senha_nova_confirmar == '' || this.modelsenha.senha_nova_confirmar.length < 4) {
      this.alert.AlertShowDanger('Campo Confirmação Senha deve ser preenchido!');
      return;
    }

    if (this.modelsenha.senha_nova == '' || this.modelsenha.senha_nova.length < 4) {
      this.alert.AlertShowDanger('Nova Senha deve ser preenchido!');
      return;
    }
    if (this.modelsenha.senha == '' || this.modelsenha.senha.length < 4) {
      this.alert.AlertShowDanger('Campo Senha deve ser preenchido!');
      return;
    }

    this.servicetrocasenha.UpdateSenha(this.autenticado.token, this.formtrocasenha.value).subscribe(
      () => {
        this.alert.AlertShowSucess('Senha Atualizada com sucesso!');
        this.alert.AlertShowInfo('Você será desconectado no momento!');
        this.autenticado.ResetDados();
        this.rota.navigate(['/login']);
      },
      (error) => {
        this.alert.AlertShowDanger('Erro ao Atualizar Senha!');
      });

  }


  get f() {
    return this.formtrocasenha.controls;
  }

}
