import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Parametros } from '../shared/parametros.enum';

@Injectable({
  providedIn: 'root'
})
export class ConfirmarCadastroService {

  private urlcadastrousuario = Parametros.CADASTROUSUARIO;
  private rota: Router;

  
  constructor(private http: HttpClient,
    router: Router,   
    ) {
    this.rota = router;
  }
  ConfirmarCadastro(_hash) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http
      .get(this.urlcadastrousuario + 'GETConfirmarCadastro/?hash='+_hash, httpOptions)
      .pipe();
  }
}
