<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>
<div>
    <h4 class="espacamentotopo">
        <img src="../../assets/img/cadastro.jpeg" style="width: 40px" /> Registrar Elogio
    </h4>
    <label class="espacamentotopo col-md" style="margin-left: 5px;">
        &nbsp;&nbsp;&nbsp;&nbsp; Você está na área para registro de elogios, desde já gostaríamos de informar que
        estamos felizes por estar
        prestes a nos encaminhar um elogio!
        Que Deus lhe abençoes grandiosamente!
        Abraço da Equipe Morada de Deus

    </label>
    <label class="espacamentotopo col-md" style="margin-left: 5px;">
        &nbsp;&nbsp;&nbsp;&nbsp;Lembramos que os campos com (<span class="cor"> *</span> ) são campos de
        preenchimento obrigatório!
    </label>
    <hr class="espacamentotopo bold" />
    <form [formGroup]="formelogio" class="m" disabled="">
        <div class="row">
            <!---INCIO PRIMEIRA LINHA-->
            <div class="col-md-2">
                <div class="form-group">
                    <label>CPF:</label><strong class="cor">*</strong>
                    <input type="text" disabled="true" class="form-control" id="cpf" placeholder="informe o CPF"
                        mask="CPF_CNPJ" name="cpf" maxlength="14" autocomplete="off" formControlName="cpf"
                        [(ngModel)]="cadastroelogio.cpf" [ngClass]="{ 'is-invalid': submitted && f.cpf.errors }" />
                    <div *ngIf="submitted && f.cpf.errors" class="invalid-feedback">
                        <div *ngIf="f.cpf.errors.required">Preenchimento obrigatório</div>
                    </div>
                </div>
            </div>

            <div class="col-md-7">
                <div class="form-group">
                    <label>Nome Completo:</label><strong class="cor">*</strong>
                    <input type="text" disabled="true" class="form-control" id="nome" name="nome"
                        [(ngModel)]="cadastroelogio.nome" placeholder="Informe Nome Completo" formControlName="nome"
                        maxlength="500" minlength="3" autocomplete="off"
                        [ngClass]="{ 'is-invalid': submitted && f.nome.errors }" />
                    <div *ngIf="submitted && f.nome.errors" class="invalid-feedback">
                        <div *ngIf="f.nome.errors.required">Preenchimento obrigatório</div>
                    </div>
                </div>
            </div>

            <div class="col-md-3">
                <div class="form-group">
                    <label>Data Elogio:</label><strong class="cor">*</strong>
                    <input type="date" class="form-control" id="dt_culto" placeholder="Data Elogio" name="dt_culto"
                        maxlength="10" minlength="10" autocomplete="off" formControlName="dt_culto"
                        [(ngModel)]="cadastroelogio.dt_culto"
                        [ngClass]="{ 'is-invalid': submitted && f.dt_culto.errors }" />
                    <div *ngIf="submitted && f.dt_culto.errors" class="invalid-feedback">
                        <div *ngIf="f.dt_culto.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!---FIM PRIMEIRA LINHA-->




        <!----->
        <div class="row">
            <div class="col-md-12">
                <div class="dropdown">
                    <label>Nos conte o que gostaria de Elogiar?:</label><strong class="cor">*</strong>
                    <div class="mb-12">
                        <select class="custom-select" type="text" (change)="changeelogio($event)" name="elogio"
                            id="elogio" [(ngModel)]="cadastroelogio.elogio" formControlName="elogio"
                            [ngClass]="{ 'is-invalid': submitted && f.elogio.errors }">
                            <div *ngIf="submitted && f.elogio.errors" class="invalid-feedback"></div>
                            <option value=""></option>
                            <option *ngFor="let d of cbelogio" [ngValue]="d">
                                {{ d }}
                            </option>
                        </select>
                        <div *ngIf="submitted && f.elogio.errors" class="invalid-feedback">
                            <div *ngIf="f.elogio.errors.required">
                                Preenchimento obrigatório
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!----->

        <div class="row">
            <div class="col-md">
                <div class="form-group">
                    <label>Elogio:</label><strong class="cor">*</strong>
                    <textarea type="text" rows="10" class="form-control" id="mensagem" aria-describedby="emailHelp"
                        name="mensagem" formControlName="mensagem" [(ngModel)]="cadastroelogio.mensagem"
                        autocomplete="off" maxlength="8000" minlength="10"
                        [ngClass]="{ 'is-invalid': submitted && f.mensagem.errors }"></textarea>
                    <span>{{ cadastroelogio.mensagem.length }}/8000</span>

                    <div *ngIf="submitted && f.mensagem.errors" class="invalid-feedback">
                        <div *ngIf="f.mensagem.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <!----->
        <div class="row">
            <div class="col-md">
                <div class="form-group" style=" display: block; border: 0px;">
                    <input type="checkbox" id="receber_copia" data-md-icheck formControlName="receber_copia"
                        [(ngModel)]="receber_copia" (change)="ReceberCopia($event)" />
                    Receber Cópia.

                    <span style=" display: block; border: 0px; font-size: x-small;">Para
                        receber uma cópia e necessário
                        ter e-mail valido em seu Perfil </span>
                </div>
                
            </div>
        </div>
        <!----->
        <hr>
        <br>
        <!----->
        <div style="padding-bottom: 20px;">
            <button pButton pRipple type="button" icon="pi pi-save" class="p-button-rounded" style="margin-right: 5px;"
                title=":: Registrar ::" (click)="onSubmit()"></button>
            <button pButton pRipple type="button" icon="pi pi-refresh" style="margin-right: 5px;"
                class="p-button-rounded p-button-secondary" title="::LIMPAR::" (click)="reset()"></button>
            <button pButton pRipple type="button" icon="pi pi-sign-in" class="p-button-rounded p-button-danger"
                title="::Fechar::" routerLink="/dashboard/resumo"></button>
        </div>
    </form>
</div>
<button class="voltar-ao-topo" id="back"><a class="ancora" style="color: white;"><i
            class="pi pi-arrow-circle-up"></i></a></button>