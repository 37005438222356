import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { retry } from 'rxjs/operators';
import { Parametros } from 'src/app/shared/parametros.enum';
import { SharedModule } from 'src/app/shared/shared.module';

@Injectable({
  providedIn: 'root'
})
export class ListagemMembrosService {
  private urlmembros = Parametros.GERENCIAMEMBROS;

  constructor(private http: HttpClient,
    private autenticado: SharedModule) { }

  startedClass = false;
  completedClass = false;
  preventAbuse = false;

  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }

  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }

  Cancelarsuario(idusuario) {
    this.StartProgressBar();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token
      })
    };
    return this.http
      .post(this.urlmembros + 'PostCancelarUsuario/?parametro=' + idusuario, "", httpOptions)
      .pipe(retry(2));
  }

  InativarUsuario(idusuario) {
    this.StartProgressBar();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token
      })
    };
    return this.http
      .post(this.urlmembros + 'PostInativarUsuario/?parametro=' + idusuario, "", httpOptions)
      .pipe(retry(2));
  }

  AtivarUsuario(idusuario) {
    this.StartProgressBar();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token
      })
    };
    return this.http
      .post(this.urlmembros + 'PostativarUsuario/?parametro=' + idusuario, "", httpOptions)
      .pipe(retry(2));
  }



}



