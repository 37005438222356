import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { Parametros } from 'src/app/shared/parametros.enum';
import { SharedModule } from 'src/app/shared/shared.module';
import { cultomodelKids } from '../../../culto-kids-inscricao/CultoKidsModel';

@Component({
  selector: 'app-resumo-cultos-kids',
  templateUrl: './resumo-cultos-kids.component.html',
  styleUrls: ['./resumo-cultos-kids.component.css']
})
export class ResumoCultosKidsComponent implements OnInit {



  private urlcadastrokids = Parametros.CADASTROCULTOKIDS;

  x = 1;
  y = 10;
  filtro_cabecalho: string = ""
  explicacao: string = "";
  rota: any;
  perfil: any;
  pagina_atual = 1;
  blob: Blob;
  tipo_culto: any;
  quantidade: string;
  cultos: cultomodelKids;
  urlseminario: string;
  total_presentes: any;
  total_registros: any;
  total_ausentes: any;


  constructor(private http: HttpClient,
    private primengConfig: PrimeNGConfig,
    private alert: AlertModalService,
    private autenticado: SharedModule,
    r: Router
  ) {
    this.rota = r;
    this.perfil = this.autenticado.perfil;
  }

  startedClass = false;
  completedClass = false;
  preventAbuse = false;

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//

  totalizadores: Array<cultomodelKids>;
  qto: number;
  cols: any[];




  GETListagemCultos() {
    this.StartProgressBar();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token
      })
    };
    return this.http
      .get<cultomodelKids>(this.urlcadastrokids + 'GETlistaCultos', httpOptions).subscribe(
        (success) => {
          this.cultos = success;
        },
        (error) => {

        }
      )
  }



  Consultar() {
    this.GETAusentes();
    this.GETPresentes();
    this.GETTotal();
  }

  ValorCulto(event) {
    this.tipo_culto = event.currentTarget.value;
  }

  GETPresentes() {
    
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get<any>(this.urlcadastrokids + 'GETQuantidadeRegistrosPresentes/?culto=' + this.tipo_culto, httpOptions).subscribe(
      (success) => {
        this.total_presentes = success;
      },
      (error) => {

      }
    )
  }
  GETTotal() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get<any>(this.urlcadastrokids + 'GETQuantidadeRegistros/?culto=' + this.tipo_culto, httpOptions).subscribe(
      (success) => {
        this.total_registros = success;
      },
      (error) => {

      }
    )
  }

  GETAusentes() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get<any>(this.urlcadastrokids + 'GETQuantidadeRegistrosAusentes/?culto=' + this.tipo_culto, httpOptions).subscribe(
      (success) => {
        this.total_ausentes = success;
      },
      (error) => {

      }
    )
  }


  ngOnInit() {
    if (this.autenticado.token == null) {
      this.autenticado.ResetDados();
      this.alert.AlertShowDanger('Usuário não autenticado, redirecionando...')
      this.rota.navigate(['/login']);
    } else {
      if (this.autenticado.ValidarToken() != true) {
        this.autenticado.ResetDados();
        this.alert.AlertShowDanger('Token Invalido!')
        this.rota.navigate(['/login']);
      }
    }
    this.primengConfig.ripple = true;
    this.filtro_cabecalho = "Totalizadores por Culto Kids"
    this.explicacao = "Aqui você está visualizando totalizadores de cadastros por culto KIDS presencial na Morada de Deus."
    this.GETListagemCultos();
  }


}
