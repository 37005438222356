<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>
<div>
    <div class="row">
        <div class="col-md-2" style="margin-right: 2px; padding: 0%;">
            <img src="../../../assets/img/configuracoes.png" height="100px" width="200px" style="padding: 0%;" />
        </div>
        <div class="col-md">
            <h4 class="espacamentotopo " style="margin-left: 5px;">
                Ajuste Perfil
            </h4>
            <label style="margin-left: 5px;">
                Aqui você define Qual a permissão do usuario!.
                <br>
                Tome cuidado ao selecionar o perfil para que não seja liberado perfil superior ao necessario do
                usuario.<br>
            </label>
        </div>
    </div>

    <h4>Permissões de Acesso</h4>
    <hr class="espacamentotopo bold" />
    <form [formGroup]="formusuario" disabled="">
        <div class="row">
            <!---INCIO PRIMEIRA LINHA-->
            <div class="col-md-2">
                <div class="form-group">
                    <label>CPF:</label><strong class="cor">*</strong>
                    <input type="text" class="form-control" id="cpf" placeholder="informe o CPF"
                        mask="000.000.000-00||00.000.000" name="cpf" maxlength="14" minlength="8" autocomplete="off"
                        formControlName="cpf" (blur)="consultacpf(modelusuario.cpf)" [(ngModel)]="modelusuario.cpf"
                        [ngClass]="{ 'is-invalid': submitted && f.cpf.errors }" />
                    <div *ngIf="submitted && f.cpf.errors" class="invalid-feedback">
                        <div *ngIf="f.cpf.errors.required">Preenchimento obrigatório</div>
                    </div>
                </div>
            </div>

            <div class="col-md-10">
                <div class="form-group">
                    <label>Nome Completo:</label><strong class="cor">*</strong>
                    <input type="text" disabled="true" class="form-control" id="nome" name="nome"
                        [(ngModel)]="modelusuario.nome" placeholder="Informe Nome Completo" formControlName="nome"
                        maxlength="500" minlength="3" autocomplete="off"
                        [ngClass]="{ 'is-invalid': submitted && f.nome.errors }" />
                    <div *ngIf="submitted && f.nome.errors" class="invalid-feedback">
                        <div *ngIf="f.nome.errors.required">Preenchimento obrigatório</div>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <form [formGroup]="formperfil" disabled="">
        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="pastor_presidente" data-md-icheck formControlName="pastor_presidente"
                        [(ngModel)]="pastor_presidente" />
                    <b>&nbsp;&nbsp;Pastor_Presidente</b>
                </div>
            </div>
        </div>




        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="pastor_auxiliar" data-md-icheck formControlName="pastor_auxiliar"
                        [(ngModel)]="pastor_auxiliar" />
                    <b>&nbsp;&nbsp;Pastor_Auxiliar</b>
                </div>
            </div>
        </div>

        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="ministerio_consolidacao" data-md-icheck
                        formControlName="ministerio_consolidacao" [(ngModel)]="ministerio_consolidacao" />
                    <b>&nbsp;&nbsp;Ministerio_Consolidacao</b>
                </div>
            </div>
        </div>

        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="ministerio_obreiro" data-md-icheck formControlName="ministerio_obreiro"
                        [(ngModel)]="ministerio_obreiro" />
                    <b>&nbsp;&nbsp;Ministerio_Obreiro</b>
                </div>
            </div>
        </div>

        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="ministerio_diaconato" data-md-icheck
                        formControlName="ministerio_diaconato" [(ngModel)]="ministerio_diaconato" />
                    <b>&nbsp;&nbsp;Ministerio_Diaconato</b>
                </div>
            </div>
        </div>

        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="ministerio_louvor" data-md-icheck formControlName="ministerio_louvor"
                        [(ngModel)]="ministerio_louvor" />
                    <b>&nbsp;&nbsp;Ministerio_Louvor</b>
                </div>
            </div>
        </div>

        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="ministerio_secretaria" data-md-icheck
                        formControlName="ministerio_secretaria" [(ngModel)]="ministerio_secretaria" />
                    <b>&nbsp;&nbsp;Ministerio_Secretaria</b>
                </div>
            </div>
        </div>

        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="ministerio_casais" data-md-icheck formControlName="ministerio_casais"
                        [(ngModel)]="ministerio_casais" />
                    <b>&nbsp;&nbsp;Ministerio_Casais</b>
                </div>
            </div>
        </div>

        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="ministerio_jovem" data-md-icheck formControlName="ministerio_jovem"
                        [(ngModel)]="ministerio_jovem" />
                    <b>&nbsp;&nbsp;Ministerio_Jovem</b>
                </div>
            </div>
        </div>

        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="ministerio_mulheres" data-md-icheck formControlName="ministerio_mulheres"
                        [(ngModel)]="ministerio_mulheres" />
                    <b>&nbsp;&nbsp;Ministerio_Mulheres</b>
                </div>
            </div>
        </div>

        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="ministerio_homens" data-md-icheck formControlName="ministerio_homens"
                        [(ngModel)]="ministerio_homens" />
                    <b>&nbsp;&nbsp;Ministerio_Homens</b>
                </div>
            </div>
        </div>


        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="ministerio_danca" data-md-icheck formControlName="ministerio_danca"
                        [(ngModel)]="ministerio_danca" />
                    <b>&nbsp;&nbsp;Ministerio_Danca</b>
                </div>
            </div>
        </div>


        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="ministerio_patrimonio" data-md-icheck
                        formControlName="ministerio_patrimonio" [(ngModel)]="ministerio_patrimonio" />
                    <b>&nbsp;&nbsp;Ministerio_Patrimonio</b>
                </div>
            </div>
        </div>


        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="ministerio_infantil" data-md-icheck formControlName="ministerio_infantil"
                        [(ngModel)]="ministerio_infantil" />
                    <b>&nbsp;&nbsp;Ministerio_Infantil</b>
                </div>
            </div>
        </div>


        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="ministerio_midias" data-md-icheck formControlName="ministerio_midias"
                        [(ngModel)]="ministerio_midias" />
                    <b>&nbsp;&nbsp;Ministerio_Midias</b>
                </div>
            </div>
        </div>


        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="ministerio_eventos" data-md-icheck formControlName="ministerio_eventos"
                        [(ngModel)]="ministerio_eventos" />
                    <b>&nbsp;&nbsp;Ministerio_Eventos</b>
                </div>
            </div>
        </div>


        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="ministerio_sonoplastia" data-md-icheck
                        formControlName="ministerio_sonoplastia" [(ngModel)]="ministerio_sonoplastia" />
                    <b>&nbsp;&nbsp;Ministerio_Sonoplastia</b>
                </div>
            </div>
        </div>


        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="ministerio_geral" data-md-icheck formControlName="ministerio_geral"
                        [(ngModel)]="ministerio_geral" />
                    <b>&nbsp;&nbsp;Ministerio_Geral</b>
                </div>
            </div>
        </div>

    </form>
    <hr>



    <div>
        <button pButton pRipple type="button" icon="pi pi-save" class="p-button-rounded" title="::Salvar Alterações::"
            (click)="onSubmit()">
        </button>
        <button pButton pRipple type="button" icon="pi pi-sign-in" class="p-button-rounded p-button-danger"
            title="::Fechar::" routerLink="/dashboard/resumo">
        </button>
    </div>



</div>