<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>


<h5 class="espacamentotopo container" >
    <i class="fas fa-user-edit" style="margin-left: 20px;"></i>&nbsp;&nbsp;Cadastro Infantil
</h5>
<label class="j container">
    Seja bem-vindo(a)!<br />
    &nbsp;&nbsp;&nbsp; Você está na área de cadastro de seu filho(a) em nossa plataforma.<br>
    &nbsp;&nbsp;&nbsp;&nbsp;Lembramos que todos os dados aqui informados são tratados somente por nossa equipe de
    gestão não sendo compartilhados com terceiros.
</label>
<label class="j">
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Lembramos que os campos com (<strong class="cor"> *</strong> ) são campos de
    preenchimento obrigatório!
</label><br>
<hr class="espacamentotopo bold" />
<form [formGroup]="formseminarioinfantil" class="m" disabled="">
    <div class="row">
        <!---INCIO PRIMEIRA LINHA-->
        <div class="col-md-2">
            <div class="form-group">
                <label>CPF Responsável:</label><strong class="cor">*</strong>
                <input type="text" class="form-control" id="cpf" placeholder="informe o CPF" mask="CPF_CNPJ" name="cpf"
                    maxlength="14" autocomplete="off" formControlName="cpf" (blur)="consultacpf(seminarioinfantil.cpf)"
                    [(ngModel)]="seminarioinfantil.cpf" [ngClass]="{ 'is-invalid': submitted && f.cpf.errors }" />
                <div *ngIf="submitted && f.cpf.errors" class="invalid-feedback">
                    <div *ngIf="f.cpf.errors.required">Preenchimento obrigatório</div>
                </div>
            </div>
        </div>

        <div class="col-md-7">
            <div class="form-group">
                <label>Nome Completo - Responsável:</label><strong class="cor">*</strong>
                <input type="text" class="form-control" id="responsavel" name="responsavel"
                    [(ngModel)]="seminarioinfantil.responsavel" placeholder="Informe Nome Completo"
                    formControlName="responsavel" maxlength="500" minlength="3"
                    [(ngModel)]="seminarioinfantil.responsavel" autocomplete="off"
                    [ngClass]="{ 'is-invalid': submitted && f.responsavel.errors }" />
                <div *ngIf="submitted && f.responsavel.errors" class="invalid-feedback">
                    <div *ngIf="f.responsavel.errors.required">Preenchimento obrigatório</div>
                </div>
            </div>
        </div>

        <div class="col-md-3">
            <label>WhatsApp:</label><strong class="cor">*</strong>
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1"><i class="fas fa-phone"></i></span>
                </div>
                <input type="text" class="form-control" id="telefone" name="telefone"
                    [(ngModel)]="seminarioinfantil.telefone" placeholder="Informe o Telefone"
                    title="Formato (DD) 0000-0000 ou (DD) 00000-0000" formControlName="telefone" maxlength="500"
                    minlength="3" autocomplete="off" mask="(00) 0000-0000 || (00) 00000-0000" [ngClass]="{
                  'is-invalid': submitted && f.telefone.errors
                }" />
                <div *ngIf="submitted && f.telefone.errors" class="invalid-feedback">
                    <div *ngIf="f.telefone.errors.required">
                        Preenchimento obrigatório
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!---FIM PRIMEIRA LINHA-->

    <!---INICIO SEGUNDA LINHA-->
    <div class="row">

        <div class="col-md-3">
            <label>Contato Confiança:</label><strong class="cor">*</strong>
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1"><i class="fas fa-phone"></i></span>
                </div>
                <input type="text" class="form-control" id="contato_confianca" name="contato_confianca"
                    [(ngModel)]="seminarioinfantil.contato_confianca" placeholder="Informe o Telefone"
                    title="Formato (DD) 0000-0000 ou (DD) 00000-0000" formControlName="contato_confianca"
                    maxlength="500" minlength="3" autocomplete="off" mask="(00) 0000-0000 || (00) 00000-0000" [ngClass]="{
                  'is-invalid': submitted && f.contato_confianca.errors
                }" />
                <div *ngIf="submitted && f.contato_confianca.errors" class="invalid-feedback">
                    <div *ngIf="f.contato_confianca.errors.required">
                        Preenchimento obrigatório
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-4">
            <label>E-mail:</label>
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1"><i class="fas fa-at"></i></span>
                </div>
                <input type="email" class="form-control" id="email" placeholder="Informe E-mail" name="email"
                    formControlName="email" [(ngModel)]="seminarioinfantil.email" maxlength="500" minlength="3"
                    autocomplete="off" />
            </div>

        </div>

        <div class="col-md-5">
            <div class="dropdown">
                <label>Seminário / Congresso / Festa:</label><strong class="cor">*</strong>
                <div class="mb-3">
                    <select class="custom-select" type="text" name="tipo_culto" id="tipo_culto"
                        [(ngModel)]="seminarioinfantil.tipo_culto"
                        (change)="DadosSeminario(seminarioinfantil.tipo_culto)"
                        (blur)="DadosSeminario(seminarioinfantil.tipo_culto)" formControlName="tipo_culto"
                        [ngClass]="{ 'is-invalid': submitted && f.tipo_culto.errors }">
                        <div *ngIf="submitted && f.tipo_culto.errors" class="invalid-feedback"></div>
                        <option value=""></option>
                        <option *ngFor="let culto of cultos" [ngValue]="culto">
                            {{ culto }}
                        </option>
                    </select>
                    <span style="font-size: small; margin-left: 5px; font-style: oblique;"><b>{{dados_culto}}</b></span>
                    <div *ngIf="submitted && f.tipo_culto.errors" class="invalid-feedback">
                        <div *ngIf="f.tipo_culto.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <!---FIM SEGNDA LINHA-->
    <div class="row">
        <div class="col-md-10">
            <div class="dropdown">
                <label>Nome Completo - Filho(a):</label><strong class="cor">*</strong>
                <div class="mb-10">
                    <input type="text" class="form-control" id="nome_filho" placeholder="Informe Nome Completo Filho(a)"
                        name="nome_filho" formControlName="nome_filho" [(ngModel)]="seminarioinfantil.nome_filho"
                        maxlength="500" minlength="3" autocomplete="off" [ngClass]="{
                            'is-invalid': submitted && f.nome_filho.errors
                          }" />
                    <div *ngIf="submitted && f.nome_filho.errors" class="invalid-feedback">
                        <div *ngIf="f.nome_filho.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-2">
            <div class="dropdown">
                <label>Idade:</label><strong class="cor">*</strong>
                <div class="mb-2">
                    <select class="custom-select" type="text" name="idade" (change)="changeministeriomorada($event)"
                        id="idade" [(ngModel)]="seminarioinfantil.idade" formControlName="idade"
                        [ngClass]="{ 'is-invalid': submitted && f.idade.errors }">
                        <div *ngIf="submitted && f.idade.errors" class="invalid-feedback"></div>
                        <option value=""></option>
                        <option *ngFor="let d of idades" [ngValue]="d">
                            {{ d }}
                        </option>
                    </select>
                    <div *ngIf="submitted && f.idade.errors" class="invalid-feedback">
                        <div *ngIf="f.idade.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <div class="row">
        <div class="col-md">
            <div class="form-group">
                <label style="padding: 0%;">Alguma Observação:</label> <span style="font-size: x-small; padding: 0%;">
                    (
                    Caso deseje detalhar alguma observação de seu Filho(a) fique a vontade!,os dados aqui jamais serão
                    compartilhados. )</span>
                <textarea type="text" rows="2" class="form-control" id="observacoes" aria-describedby="emailHelp"
                    name="observacoes" formControlName="observacoes" [(ngModel)]="seminarioinfantil.observacoes"
                    autocomplete="off" maxlength="500" minlength="3"></textarea>

                <span>{{ seminarioinfantil.observacoes.length }}/500</span>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-2">
            <div class="form-group">
                <label>Cep:</label>
                <input type="text" class="form-control" placeholder="Informe o CEP" id="cep" mask="00.000-000"
                    name="cep" formControlName="cep" (blur)="consulta_cep(seminarioinfantil.cep)"
                    [(ngModel)]="seminarioinfantil.cep" maxlength="500" minlength="3" />
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label>Logradouro:</label>
                <input type="text" class="form-control" id="logradouro" placeholder="Informe Logradouro"
                    name="logradouro" formControlName="logradouro" [(ngModel)]="seminarioinfantil.logradouro"
                    maxlength="500" minlength="3" />
            </div>
        </div>
        <div class="col-md-2">
            <div class="form-group">
                <label>Numero:</label>
                <input type="number" class="form-control" id="numero" placeholder="Numero" name="numero"
                    formControlName="numero" [(ngModel)]="seminarioinfantil.numero" maxlength="9" />
            </div>
        </div>
        <div class="col-md-2">
            <div class="form-group">
                <label>apto:</label>
                <input type="number" class="form-control" id="apto" placeholder="apto" name="apto"
                    formControlName="apto" [(ngModel)]="seminarioinfantil.apto" maxlength="9" />
            </div>
        </div>
    </div>
    <!----->
    <!----->
    <div class="row">
        <div class="col-md-5">
            <div class="form-group">
                <label>Bairro:</label>
                <input type="text" class="form-control" id="bairro" placeholder="Informe o Bairro" name="bairro"
                    formControlName="bairro" [(ngModel)]="seminarioinfantil.bairro" maxlength="500" minlength="3" />
            </div>
        </div>
        <div class="col-md-2">
            <div class="dropdown">
                <label>UF:</label>
                <div class="mb-3">
                    <select class="custom-select" type="text" name="uf" id="uf" [(ngModel)]="seminarioinfantil.uf"
                        formControlName="uf">
                        <option value=""></option>
                        <option *ngFor="let city of luf" [ngValue]="city">
                            {{ city }}
                        </option>
                    </select>
                </div>
            </div>
        </div>
        <div class="col-md-5">
            <div class="form-group">
                <label>Cidade:</label>
                <input type="text" class="form-control" id="cidade" placeholder="Informe sua cidade" name="cidade"
                    formControlName="cidade" [(ngModel)]="seminarioinfantil.cidade" maxlength="500" minlength="3" />
            </div>
        </div>
    </div>

    <!----->
    <div>
        <button pButton pRipple type="button" icon="pi pi-save" class="p-button-rounded" style="margin-right: 5px;"
            title=":: SALVAR ::" (click)="onSubmit()"></button>
        <button pButton pRipple type="button" icon="pi pi-refresh" style="margin-right: 5px;"
            class="p-button-rounded p-button-secondary" title="::LIMPAR::" (click)="reset()"></button>
        <button pButton pRipple type="button" icon="pi pi-home" style="margin-right: 5px;"
            class="p-button-rounded p-button-help" title="::HOME::" routerLink=""></button>
    </div>

    <!-- Modal -->
    <button id="openModalButton" [hidden]="true" data-toggle="modal" data-target="#myModal"></button>
    <div class="modal fade bd-example-modal-lg" data-backdrop="static" data-keyboard="false" id="myModal" tabindex="-1"
        role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel">
                        <i class="fas fa-info-circle" style="color: blue"></i>
                        {{ modaltitulo }}
                    </h5>
                </div>
                <div class="modal-body">
                    <div [innerHTML]="modalconteudo"></div>
                </div>
                <div class="modal-footer">
                    <div>
                        <button pButton pRipple type="button" icon="pi pi-exclamation-triangle"
                            class="p-button-rounded p-button-warning" title=":: REPORTAR PROBLEMAS ::"
                            data-dismiss="modal" routerLink="/problema"></button>
                        <button pButton pRipple type="button" icon="pi pi-times"
                            class="p-button-rounded p-button-danger" title=":: FECHAR ::" data-dismiss="modal"
                            (click)="reset()">
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>



</form>

