<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>
<div class="border wrapper">
  <h4 class="espacamentotopo">
    <img src="../../assets/img/cadastro.jpeg" style="width: 40px" />&nbsp;&nbsp;Cadastro
    Membros
  </h4>
  <label class="j">
    Seja bem-vindo(a)!<br />
    &nbsp;&nbsp;&nbsp;&nbsp; Você está na área de cadastro de membros de nossa igreja, Informamos que
    todos os dados aqui informados são tratados somente por nossa equipe de
    gestão não sendo compartilhados com terceiros e sendo de uso para acesso a 
    plataforma. Esperamos seu cadastro!
  </label>
  <label class="j">
    Lembramos que os campos com (<strong class="cor">*</strong>) são campos de
    preenchimento obrigatório!
  </label>
  <hr class="espacamentotopo bold" />
  <form [formGroup]="formcadastro"  disabled="">
    <div class="row">
      <!---INCIO PRIMEIRA LINHA-->
      <div class="col-md-2">
        <div class="form-group">
          <label>CPF:</label><strong class="cor">*</strong>
          <input type="text" class="form-control" id="cpf" placeholder="informe o CPF" mask="CPF_CNPJ" name="cpf"
            maxlength="14" autocomplete="off" formControlName="cpf" (blur)="consultacpf(cadastromembros.cpf)"
            [(ngModel)]="cadastromembros.cpf" [ngClass]="{ 'is-invalid': submitted && f.cpf.errors }" />
          <div *ngIf="submitted && f.cpf.errors" class="invalid-feedback">
            <div *ngIf="f.cpf.errors.required">Preenchimento obrigatório</div>
          </div>
        </div>
      </div>

      <div class="col-md-6">
        <div class="form-group">
          <label>Nome Completo:</label><strong class="cor">*</strong>
          <input type="text" class="form-control" id="nome" name="nome" [(ngModel)]="cadastromembros.nome"
            placeholder="Informe Nome Completo" formControlName="nome" maxlength="500" minlength="3"
            [(ngModel)]="cadastromembros.nome" autocomplete="off"
            [ngClass]="{ 'is-invalid': submitted && f.nome.errors }" />
          <div *ngIf="submitted && f.nome.errors" class="invalid-feedback">
            <div *ngIf="f.nome.errors.required">Preenchimento obrigatório</div>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div class="dropdown">
          <label>Estado Civil:</label><strong class="cor">*</strong>
          <div class="mb-3">
            <select class="custom-select" type="text" (change)="changeCitys($event)" name="estado_civil"
              id="estado_civil" [(ngModel)]="cadastromembros.estado_civil" formControlName="estado_civil"
              [ngClass]="{ 'is-invalid': submitted && f.estado_civil.errors }">
              <div *ngIf="submitted && f.estado_civil.errors" class="invalid-feedback"></div>
              <option value=""></option>
              <option *ngFor="let city of lcivil" [ngValue]="city">
                {{ city }}
              </option>
            </select>
            <div *ngIf="submitted && f.estado_civil.errors" class="invalid-feedback">
              <div *ngIf="f.estado_civil.errors.required">
                Preenchimento obrigatório
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label>Data Nascimento:</label><strong class="cor">*</strong>
          <input type="date" class="form-control" id="dt_nascimento" placeholder="Data Nascimento" name="dt_nascimento"
            maxlength="10" minlength="10" autocomplete="off" formControlName="dt_nascimento"
            [(ngModel)]="cadastromembros.dt_nascimento"
            [ngClass]="{ 'is-invalid': submitted && f.dt_nascimento.errors }" />
          <div *ngIf="submitted && f.dt_nascimento.errors" class="invalid-feedback">
            <div *ngIf="f.dt_nascimento.errors.required">
              Preenchimento obrigatório
            </div>
          </div>
        </div>
      </div>
    </div>
    <!---FIM PRIMEIRA LINHA-->

    <!---INICIO TERCEIRA LINHA-->
    <div class="row">
      <div class="col-md-3">
        <label>Telefone/Celular:</label><strong class="cor">*</strong>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1"><i class="fas fa-phone"></i></span>
          </div>
          <input type="text" class="form-control" id="contato_preferencial" name="contato_preferencial"
            [(ngModel)]="cadastromembros.contato_preferencial" placeholder="Informe o Telefone"
            title="Formato (DD) 0000-0000 ou (DD) 00000-0000" formControlName="contato_preferencial" maxlength="500"
            minlength="3" autocomplete="off" mask="(00) 0000-0000 || (00) 00000-0000" [ngClass]="{
              'is-invalid': submitted && f.contato_preferencial.errors
            }" />
          <div *ngIf="submitted && f.contato_preferencial.errors" class="invalid-feedback">
            <div *ngIf="f.contato_preferencial.errors.required">
              Preenchimento obrigatório
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-3">
        <label>Telefone / Celular:</label>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1"><i class="fas fa-phone"></i></span>
          </div>
          <input type="text" class="form-control" id="contato_secundario" name="contato_secundario" maxlength="500"
            minlength="3" autocomplete="off" mask="(00) 0000-0000 || (00) 00000-0000"
            [(ngModel)]="cadastromembros.contato_secundario" placeholder="Informe o Telefone"
            formControlName="contato_secundario" title="Formato (DD) 0000-0000 ou (DD) 00000-0000" autocomplete="off" />
        </div>
      </div>
      <div class="col-md-2">
        <div class="dropdown">
          <label>Gênero:</label><strong class="cor">*</strong>
          <div class="mb-3">
            <select class="custom-select" type="text" (change)="changenero($event)" name="genero" id="genero"
              [(ngModel)]="cadastromembros.genero" formControlName="genero"
              [ngClass]="{ 'is-invalid': submitted && f.genero.errors }">
              <div *ngIf="submitted && f.genero.errors" class="invalid-feedback"></div>
              <option value=""></option>
              <option *ngFor="let d of cbgenero" [ngValue]="d">
                {{ d }}
              </option>
            </select>
            <div *ngIf="submitted && f.genero.errors" class="invalid-feedback">
              <div *ngIf="f.genero.errors.required">
                Preenchimento obrigatório
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-2">
        <div class="dropdown">
          <label>Será Dizimista?:</label><strong class="cor">*</strong>
          <div class="mb-3">
            <select class="custom-select" type="text" (change)="changedizimista($event)" name="dizimista?"
              id="dizimista" [(ngModel)]="cadastromembros.dizimista" formControlName="dizimista"
              [ngClass]="{ 'is-invalid': submitted && f.dizimista.errors }">
              <div *ngIf="submitted && f.dizimista.errors" class="invalid-feedback"></div>
              <option value=""></option>
              <option *ngFor="let d of cbdizimista" [ngValue]="d">
                {{ d }}
              </option>
            </select>
            <div *ngIf="submitted && f.dizimista.errors" class="invalid-feedback">
              <div *ngIf="f.dizimista.errors.required">
                Preenchimento obrigatório
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label>Data Batismo:</label>
          <input type="date" class="form-control" id="dt_batismo" placeholder="Data Batismo" name="dt_batismo"
            maxlength="10" minlength="10" autocomplete="off" formControlName="dt_batismo"
            [(ngModel)]="cadastromembros.dt_batismo" />
        </div>
      </div>
    </div>

    <!---FIM TERCEIRA LINHA-->

    <div class="row">
      <!---INCIO PRIMEIRA LINHA-->
      <div class="col-md-3">
        <div class="form-group">
          <label>Possui Cargo ( Morada de Deus )?:</label>
          <input type="text" class="form-control" id="cargo" placeholder="informe o Cargo" name="cargo" maxlength="500"
            autocomplete="off" formControlName="cargo" [(ngModel)]="cadastromembros.cargo">
          <span style="font-size: small;"> * Se não possuir favor deixar em branco!</span>
        </div>
      </div>

      <div class="col-md-5">
        <div class="form-group">
          <label>Pertencia a outra Igreja?, Qual?:</label>
          <input type="text" class="form-control" id="congregacao_nome" name="congregacao_nome"
            [(ngModel)]="cadastromembros.congregacao_nome" placeholder="Informe Nome da igreja"
            formControlName="congregacao_nome" maxlength="500" minlength="3"
            [(ngModel)]="cadastromembros.congregacao_nome" autocomplete="off" />
          <span style="font-size: small;"> * Se não pertencia favor deixar em branco!</span>
        </div>
      </div>
      <div class="col-md-4">
        <label>E-mail:</label>
        <div class="input-group mb-3">
          <div class="input-group-prepend">
            <span class="input-group-text" id="basic-addon1"><i class="fas fa-at"></i></span>
          </div>
          <input type="email" class="form-control" id="email" placeholder="Informe E-mail" name="email"
            formControlName="email" [(ngModel)]="cadastromembros.email" maxlength="500" minlength="3"
            autocomplete="off" />
        </div>
      </div>


    </div>
    <!---FIM PRIMEIRA LINHA-->
    <div class="row"></div>
    <!----->
    <div class="row">
      <div class="col-md-2">
        <div class="form-group">
          <label>Cep:</label>
          <input type="text" class="form-control" placeholder="Informe o CEP" id="cep" mask="00.000-000" name="cep"
            formControlName="cep" (blur)="consulta_cep(cadastromembros.cep)" [(ngModel)]="cadastromembros.cep"
            maxlength="500" minlength="3" />
        </div>
      </div>
      <div class="col-md-6">
        <div class="form-group">
          <label>Logradouro:</label><strong class="cor">*</strong>
          <input type="text" class="form-control" id="logradouro" placeholder="Informe Logradouro" name="logradouro"
            formControlName="logradouro" [(ngModel)]="cadastromembros.logradouro" maxlength="500" minlength="3"
            [ngClass]="{ 'is-invalid': submitted && f.logradouro.errors }" />
          <div *ngIf="submitted && f.logradouro.errors" class="invalid-feedback">
            <div *ngIf="f.logradouro.errors.required">
              Preenchimento obrigatório
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div class="form-group">
          <label>Numero:</label><strong class="cor">*</strong>
          <input type="number" class="form-control" id="numero" placeholder="Numero" min="1" name="numero"
            formControlName="numero" [(ngModel)]="cadastromembros.numero" maxlength="9" minlength="3"
            [ngClass]="{ 'is-invalid': submitted && f.numero.errors }" />
          <div *ngIf="submitted && f.numero.errors" class="invalid-feedback">
            <div *ngIf="f.numero.errors.required">
              Preenchimento obrigatório
            </div>
            <div *ngIf="f.numero.errors.required">Menor valor 0</div>
          </div>
        </div>
      </div>

      <div class="col-md-2">
        <div class="form-group">
          <label>apto:</label>
          <input type="number" class="form-control" id="apto" placeholder="apto" min="1" name="apto"
            formControlName="apto" [(ngModel)]="cadastromembros.apto" maxlength="9" minlength="3" />
        </div>
      </div>
    </div>
    <!----->
    <!----->
    <div class="row">
      <div class="col-md-5">
        <div class="form-group">
          <label>Bairro:</label><strong class="cor">*</strong>
          <input type="text" class="form-control" id="bairro" placeholder="Informe o Bairro" name="bairro"
            formControlName="bairro" [(ngModel)]="cadastromembros.bairro" maxlength="500" minlength="3"
            [ngClass]="{ 'is-invalid': submitted && f.bairro.errors }" />
          <div *ngIf="submitted && f.bairro.errors" class="invalid-feedback">
            <div *ngIf="f.bairro.errors.required">
              Preenchimento obrigatório
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-2">
        <div class="dropdown">
          <label>UF:</label><strong class="cor">*</strong>
          <div class="mb-3">
            <select class="custom-select" type="text" (change)="changeCity($event)" name="uf" id="uf"
              [(ngModel)]="cadastromembros.uf" formControlName="uf"
              [ngClass]="{ 'is-invalid': submitted && f.uf.errors }">
              <div *ngIf="submitted && f.uf.errors" class="invalid-feedback"></div>
              <option value=""></option>
              <option *ngFor="let city of luf" [ngValue]="city">
                {{ city }}
              </option>
            </select>
            <div *ngIf="submitted && f.uf.errors" class="invalid-feedback">
              <div *ngIf="f.uf.errors.required">Preenchimento obrigatório</div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-5">
        <div class="form-group">
          <label>Cidade:</label><strong class="cor">*</strong>
          <input type="text" class="form-control" id="cidade" placeholder="Informe sua cidade" name="cidade"
            formControlName="cidade" [(ngModel)]="cadastromembros.cidade"
            [ngClass]="{ 'is-invalid': submitted && f.cidade.errors }" maxlength="500" minlength="3" />
          <div *ngIf="submitted && f.cidade.errors" class="invalid-feedback">
            <div *ngIf="f.cidade.errors.required">
              Preenchimento obrigatório
            </div>
          </div>
        </div>
      </div>
    </div>
    <!----->
    <div class="row">
      <div class="col-md">
        <div class="form-group">
          <label>Observações:</label>
          <textarea type="text" rows="3" class="form-control" id="observacoes" aria-describedby="emailHelp"
            name="observacoes" formControlName="observacoes" [(ngModel)]="cadastromembros.observacoes" rows="2"
            autocomplete="off" maxlength="500" minlength="3"></textarea>
          <span>{{ cadastromembros.observacoes.length }}/500</span>
        </div>
      </div>
    </div>
    <hr style="margin-top: 0%;">
    <div class="row" style="padding-bottom: 0%;">
  
        <div class="col-md-4">
          <div class="dropdown">
            <label>Membro novo? ( Ainda não apresentado(a) a igreja ):<strong class="cor">*</strong></label>
            <div class="mb-2">
              <select class="custom-select" type="text" (change)="changemembronovo($event)" name="membro_novo"
                id="membro_novo" [(ngModel)]="cadastromembros.membro_novo" formControlName="membro_novo"
                [ngClass]="{ 'is-invalid': submitted && f.membro_novo.errors }">
               
                <div *ngIf="submitted && f.membro_novo.errors" class="invalid-feedback"></div>
                <option value=""></option>
                <option *ngFor="let d of cbmembronovo" [ngValue]="d">
                  {{ d }}
                </option>
              </select>
              <span style="font-size: x-small;">Nós informe se você já foi recebido/apresentado na igreja morada de Deus.</span>
              <div *ngIf="submitted && f.membro_novo.errors" class="invalid-feedback">
                <div *ngIf="f.membro_novo.errors.required">
                  Preenchimento obrigatório
                </div>
              </div>
            </div>
          </div>
        </div>
      <div class="col-md-4">
        <div class="form-group">
          <label>Login:</label><strong class="cor">*</strong>
          <input type="text" class="form-control" id="login" placeholder="Informe Login" name="login"
            formControlName="login" [(ngModel)]="cadastromembros.login" maxlength="500" minlength="5"
            (blur)="consultanomeusuario(cadastromembros.login)"
            [ngClass]="{ 'is-invalid': submitted && f.login.errors }" />
            <span style="font-size: x-small;">Mínimo de 5 digitos</span>
          <div *ngIf="submitted && f.login.errors" class="invalid-feedback">
            <div *ngIf="f.login.errors.required">
              Preenchimento obrigatório
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4" >
        <div >
          <label>Senha:</label><strong class="cor">*</strong>
          <input type="password" class="form-control" id="senha" placeholder="Informe a Senha" name="senha"
            formControlName="senha" [(ngModel)]="cadastromembros.senha" maxlength="500" minlength="5"
            [ngClass]="{ 'is-invalid': submitted && f.senha.errors }" />
          <span style="font-size: x-small;">Mínimo de 5 digitos</span>
          <div *ngIf="submitted && f.senha.errors" class="invalid-feedback">
            <div *ngIf="f.senha.errors.required">
              Preenchimento obrigatório
            </div>
          </div>
        </div>
      </div>
    </div>
    <!----->
    <div class="row" style="padding: 0%;">
      <div class="col-md" style="padding-top: 0%;">
        <div >
          <input type="checkbox" id="marked" data-md-icheck (change)="AceiteTermo($event)" formControlName="marked"
            [(ngModel)]="marked" />
          <b>&nbsp;Aceito os Termos</b>.<strong class="cor">*</strong>
          <div *ngIf="submitted && f.marked.errors">
            <div *ngIf="f.marked.errors.required" class="cor bold">
              O aceite dos termos e obrigatório
            </div>
          </div>
        </div>
      </div>
    </div>
    <!----->
    <div style="padding-top: 15px; padding-bottom: 10px;">
      <button pButton pRipple type="button" icon="pi pi-save" class="p-button-rounded" style="margin-right: 5px;"
        title=":: SALVAR ::" (click)="onSubmit()"></button>
      <button pButton pRipple type="button" icon="pi pi-refresh" style="margin-right: 5px;"
        class="p-button-rounded p-button-secondary" title="::LIMPAR::" (click)="reset()"></button>
      <button pButton pRipple type="button" icon="pi pi-home" style="margin-right: 5px;"
        class="p-button-rounded p-button-help" title="::HOME::" routerLink=""></button>
        <a href="https://api.whatsapp.com/send?l=pt_BR&phone=55032988189440&text=Problemas Cadastro Membros"
        style="text-align: right; float: right;" title="Fale Conosco" target="_blank">Informar Problema!</a>
    </div>
    <!-- Modal -->
    <button id="openModalButton" [hidden]="true" data-toggle="modal" data-target="#myModal"></button>
    <div class="modal fade bd-example-modal-lg" data-backdrop="static" data-keyboard="false" id="myModal" tabindex="-1"
      role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">
              <i class="fas fa-info-circle" style="color: blue"></i>
              {{ modaltitulo }}
            </h5>
          </div>
          <div class="modal-body">
            <div [innerHTML]="modalconteudo"></div>
          </div>
          <div class="modal-footer">
            <div *ngIf="bt_termo == false">
              <button pButton pRipple type="button" icon="pi pi-search" title="Recuperar Autenticação"
                class="p-button-rounded" routerLink="/recuperar-autenticacao" data-dismiss="modal">
              </button>
              <button pButton pRipple type="button" icon="pi pi-pencil" class="p-button-rounded p-button-danger"
                title="Reportar Problema" routerLink="/problema" data-dismiss="modal">
              </button>
              <button pButton pRipple type="button" icon="pi pi-refresh" style="margin-right: 5px;"
                class="p-button-rounded p-button-secondary" title="::LIMPAR::" (click)="reset()"
                data-dismiss="modal"></button>
            </div>
            <div *ngIf="bt_termo == true">
              <button pButton pRipple type="button" icon="pi pi-thumbs-up" title="CONCORDO" class="p-button-rounded"
                data-dismiss="modal">
              </button>
              <button pButton pRipple type="button" icon="pi pi-thumbs-down" class="p-button-rounded p-button-danger"
                title="DISCORDO" (click)="DiscordeTermo()" data-dismiss="modal">
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
