<div class="col-md-12" style="padding-left: 50px; padding: 20px;">
    <p-fieldset legend="Missão" [toggleable]="true" styleClass="margin-left10px" collapsed="false">
        <div class="row" style="padding-bottom: 10px; margin-left: 20px;">
            <div class="row" style="padding-right: 10px;">
                <a ><img src="../../assets/img/novos/Missão.png"
                        style="border-radius: 30%; margin-top: 30px;" width="210" height="250">
                </a>
            </div>
            <div class="col-8" style="margin-left: 10px;">
                &nbsp;&nbsp;&nbsp; A Morada de Deus (IEPMD) tem como missão o que Deus disseste aos discípulos no livro de Atos. (Atos 1:8).<br>
                <b style="font-style: italic;"> "Mas recebereis poder, ao descer sobre vós o Espírito Santo, e ser-me-eis testemunhas, tanto em Jerusalém, como em toda a Judéia e Samária, e até os confins da terra."</b><br>
                &nbsp;&nbsp;&nbsp; Nossa missão e manifestar a glória de Deus sendo cheios do Espírito, cuidando, capacitando e enviando, cumprindo o IDE (Romanos 12:2).<br>

                Nossa missão esta em cumprir o que Cristo deixou estabelecido, sendo:<br>
                <b>-</b> Sermos cheios do Espírito.<br>
                <b>-</b> Levar as pessoas ao conhecimento do Reino.<br>
                <b>-</b> Transformar vidas.<br>
                <b>-</b> Exercer o IDE.<br>
                <b>-</b> Ganhar vidas para o Reino.<br>
                <b>-</b> Manifestar o avivamento da igreja do Senhor.<br>

            </div>
        </div>
    </p-fieldset>


    <p-fieldset legend="Visão" [toggleable]="true" styleClass="margin-left10px" collapsed="false">
        <div class="row">
            <div class="row" style="padding-bottom: 10px; margin-left: 20px;">
                <a ><img src="../../assets/img/novos/Visão.png"
                        style="border-radius: 30%; margin-top: 30px;" width="210" height="250">
                </a>
            </div>
            <div class="col-8">
                &nbsp;&nbsp;&nbsp; Uma igreja viva com a visão em almas crescimento e expansão do reino amando vidas e
                cuidando!
                <br><b>-: Fundamentos :-</b><br>
                <label style="margin-left: 15px;"><b>- Paternidade:</b>&nbsp;Cuidade de pai para com os filhos
                    espirituais.</label>
                <label style="margin-left: 15px;"><b>- Palavra:</b>&nbsp;Prioridade é a palavra de Deus uma igreja
                    apaixonada
                    pela palavra.</label>
                <label style="margin-left: 15px;"><b>- Libertação:</b>&nbsp;cremos em todas formas de libertação,seja
                    fisica
                    emocional e espiritual.</label>
                <label style="margin-left: 15px;"><b>- Avivamento:</b>&nbsp;Somos uma igreja Pentecostal focado no fogo
                    do
                    espirito e na manifestação dos dons espirituais.</label>
                <label style="margin-left: 15px;"><b>- Amor:</b>&nbsp;Amor a Deus, a palavra ao proximo.</label>
            </div>
        </div>
    </p-fieldset>



    <p-fieldset legend="Valores" [toggleable]="true" styleClass="margin-left10px" collapsed="false">
        <div class="row">
            <div class="row" style="padding-bottom: 10px; margin-left: 20px;">
                <a ><img src="../../assets/img/novos/Valores.png" style="border-radius: 30%; margin-top: 30px;" width="210" height="250">
                </a>
            </div>
            <div class="col-8" style="margin-left: 15px;">    
                <br>
                <br>    
                &nbsp;&nbsp;&nbsp;A Morada de Deus (IEPMD) tem como Valores o respeito mútuo não havendo distinção e diferença em tratamentos de pessoas.<br>
                <b>-</b> Amor incondicional como Cristo Amou a Igreja.<br>
                <b>-</b> Crescimento e frutificação do Reino de Deus.<br>
                <b>-</b> Ensino Bíblico relevante, contextual e totalmente embasado na palavra de Deus.<br>
                <b>-</b> Comprometimento com o reino do Senhor.<br>
                <b>-</b> Fidelidade ao Senhor.<br>
                <b>-</b> Submissão a palavra de Deus. <br>
                <b>-</b> Disponibilidade ao Reino.<br>
                <br>
            </div>
        </div>

    </p-fieldset>

</div>


<div class="col-md" style="padding-left: 20px; padding-top: 10px;padding-bottom: 10px;">
    <button pButton pRipple type="button" icon="pi pi-home" style="padding: 0%;" class="p-button-rounded p-button-help"
        title="::HOME::" routerLink=""></button>
</div>
