import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ReportarProblemaService } from '../reportar-problema/ReportarProblemaService';
import { Problema } from '../reportar-problema/ProblemaModel';
import { AlertModalService } from '../shared/alert-modal/alert-modal.service';
import { EventEmitterService } from '../shared/EventEmitterService';


@Component({
  selector: 'app-reportar-problema',
  templateUrl: './reportar-problema.component.html',
  styleUrls: ['./reportar-problema.component.css']
})
export class ReportarProblemaComponent {
  formproblema: FormGroup
  submitted = false;
  problema: Problema;
  startedClass = false;
  completedClass = false;
  preventAbuse = false;
  rota: Router;

  copia: boolean;

  constructor(
    private formBuilder: FormBuilder,
    private alert: AlertModalService,
    private _registrarproblemaservice: ReportarProblemaService,
    router: Router
  ) {
    this.rota = router;
    EventEmitterService.get('loginAlert').emit(false);
  }

  ngOnInit(): void {
    this.problema = new Problema();
    this.createForm();
  }

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }

  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//


  //Metodo para criar o form
  createForm() {
    this.formproblema = this.formBuilder.group({
      tipo_problema: ["", Validators.required],
      mensagem: ["", [Validators.required, Validators.min(15), Validators.max(500)]],
      nome: ["", Validators.required],
      email: [""],
      telefone: [""],
      copia: [false]
    });
  }


  // pegar o nome dos campos para validação
  get f() {
    return this.formproblema.controls;
  }
  ValidarCampos() {
    if (this.formproblema.get('email').value == '' && this.formproblema.get('telefone').value == '') {
      return false;
    } else {
      return true;
    }
  }

  ReceberCopia(e) {
    if (e.target.checked == true && this.formproblema.get('email').value == '') {
      this.copia = false;
      this.formproblema.get('copia').setValue(false);
      this.alert.AlertShowDanger(':: AVISO ::Para que possa receber uma cópia do Elogio e necessário informar um e-mail valido! \nPor favor preencha um e-mail valido e tente novamente!');
    }

  }

  onSubmit() {
    this.submitted = true;
    if (this.formproblema.invalid) {
      this.alert.AlertShowDanger('Favor verificar os campos em destaque!');
      return;
    } else if (this.ValidarCampos() == false) {
      this.alert.AlertShowDanger('Favor preencher um contato,E-mail ou Telefone!');
      this.formproblema.invalid;
      return;   
    } else {
      this._registrarproblemaservice.RegistrarProblema(this.formproblema.value).subscribe(
        () => {
          this.StopProgressBar();
          this.alert.AlertShowSucess('Problema Enviado com sucesso!');
          localStorage.setItem('tipo', 'problema');
          localStorage.setItem('problema-save', JSON.stringify(this.formproblema.value));
          this.rota.navigate(['/retorno']);
        },
        (error) => {
          this.StopProgressBar();
          this.alert.AlertShowDanger('( ' + error.status + ' ) ' + error.error);
        }
      );
    }
  }
}