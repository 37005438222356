import { Component, OnInit } from '@angular/core';
import {Parametros} from "../../../shared/parametros.enum";
import {Model} from "../../../encontro-mulheres/Model";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {AlertModalService} from "../../../shared/alert-modal/alert-modal.service";
import {SharedModule} from "../../../shared/shared.module";
import {Router} from "@angular/router";
import {ModelEncontroCasais} from "../../../encontro-casais/ModelEncontroCasais";

@Component({
  selector: 'app-detalhe-cadastro-encontro-casais',
  templateUrl: './detalhe-cadastro-encontro-casais.component.html',
  styleUrls: ['./detalhe-cadastro-encontro-casais.component.css']
})
export class DetalheCadastroEncontroCasaisComponent implements OnInit {

  idinscricao: any;
  rota: any;
  private urlacessos = Parametros.ENCONTROCASAIS;
  urlrelatoriotodosencontrocasais = Parametros.RELATORIOSTODOSENCONTROCASAIS;


  nome: string = "";
  cpf: any = "";
  estadocivil: string = "";
  dt_nascimento: any = "";
  dt_cadastro: any = "";
  telefone: string = "";
  email: string = "";
  cep: string = "";
  logradouro: string = "";
  numero: any = "";
  apto: any = "";
  bairro: string = "";
  cidade: string = "";
  uf: string = "";
  ativo: string = "";
  anoreferencia: string = "";

  usuario: Array<Model>;
  motivo: string;
  registro: any;
  usomedicacao: string;
  alergicomedicacao: string;
  nomemedicacao: string;
  nomemedicacaoalergico: string;
  nomealimento: string;
  alergicoalimento: string;
  restricao_alimentar: string;
  observacoes: string;
  perfil: string;

  constructor(private http: HttpClient,
              private alert: AlertModalService,
              private autenticado: SharedModule,
              private r: Router) {
    this.rota = r

  }

  startedClass = false;
  completedClass = false;
  preventAbuse = false;

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//

  ngOnInit(): void {

    if (this.autenticado.token == null) {
      this.autenticado.ResetDados();
      this.alert.AlertShowDanger('Usuário não autenticado, redirecionando...')
      this.rota.navigate(['/login']);
    } else {
      if (this.autenticado.ValidarToken() != true) {
        this.autenticado.ResetDados();
        this.alert.AlertShowDanger('Token Invalido!')
        this.rota.navigate(['/login']);
      }
    }

    this.idinscricao = localStorage.getItem('CadastroCasaisDetalhe');
    if (this.idinscricao == null || this.idinscricao == "") {
      this.rota.navigate(['/dashboard/gerenciamento/encontro-casais/cadastros']);
    } else {
      //localStorage.removeItem(' ElogioDetalhe')
      this.GetUsuario();
    }

    this.perfil=this.autenticado.perfil;
  }


  GerarPDFEncontroCasais() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token
      })
    }
    this.http.get(this.urlrelatoriotodosencontrocasais + 'GetRelatorioUsuariosDetalhado/?parametro=' + this.idinscricao, httpOptions).subscribe(
      (success) => {
        const linkSource = 'data:application/pdf;base64,' + success;
        const downloadLink = document.createElement("a");
        const fileName = "IEPMD_Detalhe_Encontro_Casais_" + (this.autenticado.nome).replace(" ", "") + ".pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }, (error) => {
        if (error.status == '403') {
          this.alert.AlertShowDanger('Erro ao tentar Gerar PDF');
        } else {
          const linkSource = 'data:application/pdf;base64,' + error.error.text;
          const downloadLink = document.createElement("a");
          const fileName = "IEPMD_Detalhe_Encontro_Casais_" + (this.autenticado.nome).replace(" ", "") + ".pdf";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      }
    );
  }



  GetUsuario() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get<ModelEncontroCasais>(this.urlacessos + 'GETLoadUsuario/?parametro=' + this.idinscricao, httpOptions).subscribe(
      (success) => {

        this.dt_cadastro = success.dt_cadastro.replace("T00:00:00", "");
        this.cpf = success.cpf_esposa + " & " + success.cpf_esposo;
        this.dt_nascimento = success.dt_nascimento_esposa + " & " + success.dt_nascimento_esposo;
        this.nome = success.nome_esposa + " & " + success.nome_esposo;
        this.estadocivil = success.tipo_uniao;
        this.telefone = success.telefone_esposa + " & " + success.telefone_esposo;
        if (success.email_esposa == null && success.email_esposo == null) {
          this.email = "";
        } else {
          if (success.email_esposa != null) {
            this.email = success.email_esposa;
          }
          if (success.email_esposo != null) {
            this.email = this.email + " - " + success.email_esposo;
          }
        }
        this.cep = success.cep;
        this.logradouro = success.logradouro;
        this.numero = success.numero;
        this.apto = success.apto;
        this.bairro = success.bairro;
        this.cidade = success.cidade;
        this.uf = success.uf;
        this.motivo = success.motivo;
        this.usomedicacao = "";
        this.alergicoalimento = ""
        this.restricao_alimentar = "";

        if (success.esposa_medicacao != null) {
            this.usomedicacao = "ESPOSA: " + success.esposa_medicacao;
          }

         if (success.esposo_medicacao != null) {
            this.usomedicacao = this.usomedicacao + "\nESPOSO: " + success.esposo_medicacao;
          }




          if (success.esposa_restricao_acucar != null) {
            this.alergicoalimento = "ESPOSA: " + success.esposa_restricao_acucar;
          }
          if (success.esposo_restricao_acucar != null) {
            this.alergicoalimento = this.alergicoalimento + "\nESPOSO: " + success.esposo_restricao_acucar;
           }



          if (success.esposa_restricao_alimentar != null) {
            this.restricao_alimentar = "ESPOSA: " + success.esposa_restricao_alimentar;
          }
          if (success.esposo_restricao_alimentar != null) {
            this.restricao_alimentar = this.restricao_alimentar + "\nESPOSO: " + success.esposo_restricao_alimentar;

        }


        this.observacoes = success.informacao_cadastro;

        if (success.ativo == "F") {
          this.ativo = "INATIVO";
          this.motivo = success.motivo;
        }

        else {
          this.ativo = success.ativo.replace("T", "ATIVO").replace("F", "INATIVO");
        }


        this.anoreferencia = success.ano;
        this.registro = success.registro;
      },
      (error) => {
        this.rota.navigate(['/dashboard/gerenciamento/encontro-casais/cadastros']);
      }
    )
  }
}
