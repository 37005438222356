import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Model } from 'src/app/encontro-mulheres/Model';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { Parametros } from 'src/app/shared/parametros.enum';
import { SharedModule } from 'src/app/shared/shared.module';

@Component({
  selector: 'app-detalhe-cadastro-encontro-mulheres',
  templateUrl: './detalhe-cadastro-encontro-mulheres.component.html',
  styleUrls: ['./detalhe-cadastro-encontro-mulheres.component.css']
})
export class DetalheCadastroEncontroMulheresComponent implements OnInit {

  idinscricao: any;
  rota: any;
  private urlacessos = Parametros.ENCONTROMULHERES;
  urlrelatoriotodosencontromulheres = Parametros.RELATORIOSTODOSENCONTROMULHERES;


  nome: string = "";
  cpf: any = "";
  estadocivil: string = "";
  dt_nascimento: any = "";
  dt_cadastro: any = "";
  telefone: string = "";
  email: string = "";
  cep: string = "";
  logradouro: string = "";
  numero: any = "";
  apto: any = "";
  bairro: string = "";
  cidade: string = "";
  uf: string = "";
  ativo: string = "";
  anoreferencia: string = "";

  usuario: Array<Model>;
  motivo: string;
  registro: any;
  usomedicacao: string;
  alergicomedicacao: string;
  nomemedicacao: string;
  nomemedicacaoalergico: string;
  nomealimento: string;
  alergicoalimento: string;
  observacoes: string;
  perfil: string;

  constructor(private http: HttpClient,
    private alert: AlertModalService,
    private autenticado: SharedModule,
    private r: Router) {
    this.rota = r

  }

  startedClass = false;
  completedClass = false;
  preventAbuse = false;

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//

  ngOnInit(): void {

    if (this.autenticado.token == null) {
      this.autenticado.ResetDados();
      this.alert.AlertShowDanger('Usuário não autenticado, redirecionando...')
      this.rota.navigate(['/login']);
    } else {
      if (this.autenticado.ValidarToken() != true) {
        this.autenticado.ResetDados();
        this.alert.AlertShowDanger('Token Invalido!')
        this.rota.navigate(['/login']);
      }
    }

    this.idinscricao = localStorage.getItem('CadastroMulheresDetalhe');
    if (this.idinscricao == null || this.idinscricao == "") {
      this.rota.navigate(['/dashboard/gerenciamento/encontro-mulheres/cadastros']);
    } else {
      //localStorage.removeItem(' ElogioDetalhe')
      this.GetUsuario();
    }

    this.perfil=this.autenticado.perfil;
  }


  GerarPDFEncontroMulheres() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token
      })
    }
    this.http.get(this.urlrelatoriotodosencontromulheres + 'GetRelatorioUsuariosDetalhado/?parametro=' + this.idinscricao, httpOptions).subscribe(
      (success) => {
        const linkSource = 'data:application/pdf;base64,' + success;
        const downloadLink = document.createElement("a");
        const fileName = "IEPMD_Detalhe_Encontro_Mulheres_" + (this.autenticado.nome).replace(" ", "") + ".pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }, (error) => {
        if (error.status == '403') {
          this.alert.AlertShowDanger('Erro ao tentar Gerar PDF');
        } else {
          const linkSource = 'data:application/pdf;base64,' + error.error.text;
          const downloadLink = document.createElement("a");
          const fileName = "IEPMD_Detalhe_Encontro_Mulheres_" + (this.autenticado.nome).replace(" ", "") + ".pdf";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      }
    );
  }



  GetUsuario() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get<Model>(this.urlacessos + 'GETLoadUsuario/?parametro=' + this.idinscricao, httpOptions).subscribe(
      (success) => {

        this.dt_cadastro = success.dt_cadastro.replace("T00:00:00", "");
        this.cpf = success.cpf;
        this.nome = success.nome;
        this.estadocivil = success.estado_civil;
        this.dt_nascimento = success.dt_nascimento.toString().replace("T00:00:00", "");
        this.telefone = success.telefone;
        this.email = success.email;
        this.cep = success.cep;
        this.logradouro = success.logradouro;
        this.numero = success.numero;
        this.apto = success.apto;
        this.bairro = success.bairro;
        this.cidade = success.cidade;
        this.uf = success.uf;
        this.motivo = success.motivo;
        this.usomedicacao = success.uso_medicacao;
        this.alergicomedicacao = success.alergico_medicacao;
        this.nomemedicacao = success.nome_medicacao;
        this.nomemedicacaoalergico = success.nome_medicacao_alergico;

        this.nomealimento = success.nome_alimento_alergico;
        this.alergicoalimento = success.alergico_alimento;
        this.observacoes = success.observacoes;

        if (success.ativo == "F") {
          this.ativo = "INATIVO";
          this.motivo = success.motivo;
        }

        else {
          this.ativo = success.ativo.replace("T", "ATIVO").replace("F", "INATIVO");
        }
        this.anoreferencia = success.ano_referencia;
        this.registro = success.idinscricao;
      },
      (error) => {
        this.rota.navigate(['/dashboard/gerenciamento/encontro-mulheres/cadastros']);
      }
    )
  }
}
