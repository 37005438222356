<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>

<div class="col-md-6 centraliza border bloco">
    <h5 class="text-center"><img src="../../../assets/img/search.jpeg" style="width: 30px; margin-top: 5px;"> Recuperar
        Autenticação</h5>
    <label style="margin-bottom: 0%;">Seja bem vindo!</label>
    <label>&nbsp;&nbsp;&nbsp;&nbsp; Para recuperar seus dados de acesso a nossa plataforma, por favor informar seu CPF
        utilizado no cadastro,caso não se lembre ou tenha alguma problema por favor entre em contato conosco clicando no
        botão amarelo abaixo "Contato" que teremos
        a satisfação em lhe ajudar!</label>
    <!-- Login Form -->
    <form [formGroup]="formRecuperacaoLogin">
        <span><b>Informe seu CPF</b></span>
        <div class="form-group">

            <input type="text" class="form-control center" id="identificacao" mask="CPF_CNPJ" name="identificacao"
                formControlName="identificacao" [ngClass]="{ 'is-invalid': submitted && f.identificacao.errors }">
            <div *ngIf="submitted && f.identificacao.errors" class="invalid-feedback">
                <div *ngIf="f.identificacao.errors.required">Preenchimento Obrigatório</div>
            </div>
        </div>

    </form>

    <div style="padding-top: 5px; padding-bottom: 10px; padding-left: 20px;">
        <button pButton pRipple type="button" (click)="RecuperarLogin()" icon="fas fa-search"
            class=" p-button-success p-button-rounded" title="::Recuperar Autenticação::"></button>
        <button pButton pRipple type="button" routerLink="/login" icon="fas fa-reply"
            class="p-button-info p-button-rounded" title="::Retornar::"></button>
        <button pButton pRipple type="button" routerLink="/contato" icon="pi pi-send"
            class="p-button-warning p-button-rounded" title="::Contato::"></button>

        <button pButton pRipple type="button" routerLink="" icon="fas fa-home" class=" p-button-help p-button-rounded"
            title="::Retornar::"></button>
    </div>
    <!-- Modal -->
    <button id="openModalButton" [hidden]="true" data-toggle="modal" data-target="#myModal"></button>
    <div class="modal fade" data-backdrop="static" data-keyboard="false" id="myModal" tabindex="-1" role="dialog"
        aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="exampleModalLabel"><i class="fas fa-info-circle cor"></i>
                        {{modaltitulo}}
                    </h5>
                </div>
                <div class="modal-body ">
                    <div class="j" [innerHTML]="modalconteudo"></div>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-danger" data-dismiss="modal"><i class="fas fa-reply"></i>
                        Voltar</button>
                    <button type="button" class="btn btn-warning" data-dismiss="modal" routerLink="/problema"><i
                            class="fas fa-exclamation-triangle cor"></i>
                        Reportar problema</button>
                    <button type="button" class="btn btn-primary" data-dismiss="modal" routerLink=""><i
                            class="fas fa-home"></i> Home</button>
                </div>
            </div>
        </div>
    </div>
</div>