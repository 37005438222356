<div class="col-md-12" style="padding-left: 50px; padding: 20px;">

<!--
    <p-fieldset legend="Congresso de Mulheres (13 a 15 de maio) ::ENCERRADO::" [toggleable]="true" styleClass="margin-left10px"
    collapsed="false">
    <div class="row" style="padding-bottom: 10px; margin-left: 20px;">
        <div class="row" style="padding-right: 10px;">
            <a routerLink="/encontro-mulheres"> <img src="../../../assets/img/novos/cards/Mulheres2022.png"
                    style="border-radius: 20%; margin-top: 30px;" width="210" height="250"></a>

        </div>
        <div class="col-8" style="margin-left: 10px;">
            <b>Congresso Filhas de Leão</b><br>
            &nbsp;&nbsp;&nbsp; Você mulher! venha participar conosco deste mover de Deus!
            <br><br>
            <b>Detalhes</b><br>
            <b>Dias:</b> 13 a 16 de Maio.<br>
            <b>Local:</b> Rua Bernardo Mascarenhas, 1334.<br>
            <b>Bairro:</b> Fábrica.<br><br>
            <br>
            <button pButton pRipple icon="pi pi-user-plus" type="button" label="Fazer Inscrição"
                class="p-button-rounded" disabled routerLink="/encontro-mulheres"></button><br><b
                style="color: red; margin-left: 7px;"></b>
                <label style="color:red"> [ Encerrado ]</label>

        </div>
    </div>
    </p-fieldset>
    -->


<label>Sem Eventos no momento!</label>




</div>


<div class="col-md" style="padding-left: 20px; padding-top: 10px;padding-bottom: 10px;">
    <button pButton pRipple type="button" icon="pi pi-home" style="padding: 0%;" class="p-button-rounded p-button-help"
        title="::HOME::" routerLink=""></button>
</div>
