import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { cadastromembrosmodel } from '../cadastro-membros/CadastroMembrosModel';
import { CadastromembrosService } from '../cadastro-membros/CadastromembrosService';
import { AlertModalService } from '../shared/alert-modal/alert-modal.service';
import { EventEmitterService } from '../shared/EventEmitterService';
@Component({
  selector: 'app-cadastro-membros',
  templateUrl: './cadastro-membros.component.html',
  styleUrls: ['./cadastro-membros.component.css']
})
export class CadastroMembrosComponent implements OnInit {

  formcadastro: FormGroup;
  submitted = false;
  cadastromembros: cadastromembrosmodel;

  modaltitulo: string = '';
  modalconteudo: string = '';
  marked: boolean;
  bt_termo: boolean;
  sucesso = new EventEmitter();
  lcivil: any = [
    'CASADO(A)',
    'SOLTEIRO(A)',
    'DIVORCIADO(A)',
    'VIÚVO(A)',
    'AMASIADO(A)'];

  cbdizimista: any = [
    'SIM',
    'NÃO'];

  cbmembronovo: any = [
    'SIM',
    'NÃO'];
  cbgenero: any = [
    'MASCULINO',
    'FEMININO'];

  luf: any = [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MT',
    'MS',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO',
  ];
  startedClass = false;
  completedClass = false;
  preventAbuse = false;
  rota: Router;


  constructor(
    private formBuilder: FormBuilder,
    private cadastromembrosservice: CadastromembrosService,
    private alert: AlertModalService,
    private router: Router
  ) {
    this.rota = this.router;
    EventEmitterService.get('loginAlert').emit(false);
  }

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//

  //seta os campos para inicio do form
  //====================================================================//
  criarform() {
    this.formcadastro = this.formBuilder.group({
      cpf: ['', Validators.required],
      nome: ['', Validators.required],
      estado_civil: ['', Validators.required],
      dt_nascimento: ['', Validators.required],
      contato_preferencial: ['', Validators.required],
      contato_secundario: [''],
      email: [''],
      dt_batismo: [''],
      cargo: [''],
      congregacao_nome: [''],
      dizimista: ['', Validators.required],
      cep: [''],
      logradouro: ['', Validators.required],
      numero: [
        '',
        [Validators.required, Validators.min(1), Validators.max(999999999)],
      ],
      apto: [''],
      membro_novo: ['', Validators.required],
      bairro: ['', Validators.required],
      uf: ['', Validators.required],
      cidade: ['', Validators.required],
      observacoes: [''],
      login: ['', Validators.required],
      genero: ['', Validators.required],
      senha: ['', Validators.required],
      marked: ['', Validators.requiredTrue]
    });
  }
  //====================================================================//

  //Caso discord do termo no modal
  //====================================================================//
  DiscordeTermo() {
    if (this.marked == true) {
      this.marked = false;
    }
  }
  //====================================================================//

  //Abrir modal quando clicar no aceite para exibir os termos
  //====================================================================//
  AceiteTermo(e) {
    if (e.target.checked == true) {
      this.marked = e.target.checked;
      this.cadastromembrosservice.GetTermo('cadastro').subscribe(
        (success) => {
          this.StopProgressBar();
          this.modalconteudo = success.termo;
          this.bt_termo = true;
          this.modaltitulo = 'Termo de utilização do sistema';
          document.getElementById('openModalButton').click();
        },
        (error) => {
          this.StopProgressBar();
          if (error.status == '400') {
            this.alert.AlertShowDanger(
              'Termo não definido!,solicitamos que não continue e avise a gestão do site, OBS: Continuando confirma está de acordo com toda politica do site!')
          } else {
            this.alert.AlertShowDanger(
              'Termo não definido!,solicitamos que não continue e avise a gestão do site, OBS: Continuando confirma está de acordo com toda politica do site!'
            );
          }
        }
      );
    }
  }
  //====================================================================//

  //recarregar Form
  //====================================================================//
  reset() {
    this.cadastromembros = new cadastromembrosmodel();
    this.submitted = false;
  }
  //====================================================================//
  //PEGAR VALOR UF FORM
  //====================================================================//
  get cityNames() {
    return this.formcadastro.get('uf');
  }
  //====================================================================//
  // Pegar o valor do combobox UF
  //====================================================================//
  changeCity(e) {
    console.log(e.value);
    this.cityNames.setValue(e.target.value, {
      onlySelf: true,
    });
  }
  //====================================================================//
  //PEGAR VALOR ESTADO CIVIL FORM
  //====================================================================//
  get cityNamess() {
    return this.formcadastro.get('estado_civil');
  }
  //====================================================================//
  // Pegar o valor do combobox estado civil
  //====================================================================//
  changeCitys(e) {
    console.log(e.value);
    this.cityNamess.setValue(e.target.value, {
      onlySelf: true,
    });
  }
  //====================================================================//
  //PEGAR VALOR DIZIMISTA FORM
  //====================================================================//
  get citydizimista() {
    return this.formcadastro.get('dizimista');
  }
  //====================================================================//
  // Pegar o valor do combobox DIZIMISTA
  //====================================================================//
  changedizimista(e) {
    console.log(e.name);
    this.citydizimista.setValue(e.target.value, {
      onlySelf: true,
    });
  }
  //====================================================================//

  //====================================================================//
  //PEGAR VALOR DIZIMISTA FORM
  //====================================================================//
  get citymembronovo() {
    return this.formcadastro.get('membro_novo');
  }
  //====================================================================//
  // Pegar o valor do combobox DIZIMISTA
  //====================================================================//
  changemembronovo(e) {
    console.log(e.name);
    this.citymembronovo.setValue(e.target.value, {
      onlySelf: true,
    });
  }
  //====================================================================//


  //====================================================================//
  //PEGAR VALOR DIZIMISTA FORM
  //====================================================================//
  get citygenero() {
    return this.formcadastro.get('genero');
  }
  //====================================================================//
  // Pegar o valor do combobox DIZIMISTA
  //====================================================================//
  changenero(e) {
    console.log(e.name);
    this.citygenero.setValue(e.target.value, {
      onlySelf: true,
    });
  }
  //====================================================================//

  //Inicio
  //====================================================================//
  ngOnInit() {
    this.cadastromembros = new cadastromembrosmodel();
    this.criarform();
    document.getElementById('cpf').focus();
  }
  //====================================================================//

  //Consulta o cadastro do condominio já existe na base de dados ( API )
  //====================================================================//
  consultacpf(cpf: any) {
    this.cadastromembrosservice.Consulta(this.cadastromembros.cpf).subscribe(
      (success) => {
        this.StopProgressBar();
      },
      (error) => {
        this.StopProgressBar();
        if (error.status == '400') {
          this.cadastromembros = new cadastromembrosmodel();
          this.bt_termo = false;
          this.modaltitulo = 'Cadastro Existente!';
          this.modalconteudo =
            'Olá! <b>' + this.formcadastro.get('cpf').value + ' ' + error.error
            + '</b>,<br>Estava olhando aqui em minhas anotações e verifiquei que já existe seu cadastro em nossa plataforma!, Pedimos por favor que utilize uma das opções abaixo e caso encontre qualquer problema não deixe de nos reportar para que possamos analisar e lhe retornar!\nDeus Abençõe!';
          document.getElementById('openModalButton').click();
        } else if (error.status == '500') {
          this.alert.AlertShowDanger(error.error);
        } else {
          document.getElementById('nome').focus();
        }
      }
    );
  }
  //====================================================================//

  //CONSULTA CEP API
  //====================================================================//
  consulta_cep(cep: any) {
    if (cep != '' && cep != null) {
      this.cadastromembrosservice
        .ConsultaCEP(
          cep
            .replace('.', '')
            .replace('-', '')
            .replace('\\', '')
            .replace('/', '')
        )
        .subscribe(
          (success) => {
            if (success.cep != null) {
              (this.cadastromembros.cep = success.cep),
                (this.cadastromembros.logradouro = success.logradouro),
                (this.cadastromembros.bairro = success.bairro),
                (this.cadastromembros.cidade = success.localidade),
                (this.cadastromembros.uf = success.uf);
              document.getElementById('numero').focus();
            } else {
              this.alert.AlertShowDanger('CEP não e válido!');
              this.cadastromembros.cep = '';
            }
          },
          (error) => {
            this.StopProgressBar();
            this.alert.AlertShowDanger(
              '( ' + error.status + ' ) ' + error.message
            );
          }
        );
    }
  }
  //====================================================================//

  consultanomeusuario(login: any) {

    if (login != null && login != "") {
      this.cadastromembrosservice
        .ConsultaNomeUsuario(login)
        .subscribe(
          (success) => {
            this.StopProgressBar();
            document.getElementById('senha').focus();
          },
          (error) => {
            this.StopProgressBar();
            this.alert.AlertShowDanger(
              'Nome de Usuario já utilizado!, Por favor escolha outro!'
            );
            this.cadastromembros.login = "";
            document.getElementById('login').focus();
          }
        );
    }

  }


  // Pegar nome dos campos,
  //====================================================================//
  get f() {
    return this.formcadastro.controls;
  }
  //====================================================================//

  //BOTAO SALVAR
  //====================================================================//
  onSubmit() {
    this.submitted = true;
    if (this.formcadastro.invalid) {
      this.alert.AlertShowDanger('Favor verificar os campos em destaque!');
      return;
    }
    this.cadastromembrosservice
      .CadastroUsuario(this.formcadastro.value)
      .subscribe(
        (sucess) => {
          this.StopProgressBar();
          this.alert.AlertShowSucess('Cadastro salvo');
          localStorage.setItem('tipo', 'cadastro');
          localStorage.setItem('cadastro-save', JSON.stringify(this.formcadastro.value));
          this.rota.navigate(['/retorno']);
        },
        (error) => {
          this.StopProgressBar();
          this.alert.AlertShowDanger('( ' + error.status + ' ) ' + error.error);
        }
      );
    //====================================================================//
  }
  //====================================================================//

}
