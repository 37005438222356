import { Component, OnInit, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { PaginasRetornoService } from '../paginas-retorno/paginas-retorno.service';
import { SharedModule } from '../shared/shared.module';
import { EventEmitterService } from '../shared/EventEmitterService';


@Component({
  selector: 'app-paginas-retorno',
  templateUrl: './paginas-retorno.component.html',
  styleUrls: ['./paginas-retorno.component.css'],

})
export class PaginasRetornoComponent implements OnInit {
  subscription: Subscription;
  nome: string = '';
  email: string = '';
  hash: string = '';
  copia: boolean;
  dados: any;
  rota: Router
  tipo: string = '';
  _serviceretorno: PaginasRetornoService;


  constructor(private router: Router,
    private s: PaginasRetornoService,
    private IS: SharedModule
  ) {
    this.rota = router;
    this._serviceretorno = s;
  }

  ngOnInit(): void {
    var openedWindow;
    EventEmitterService.get('loginAlert').emit(false);
    try {
      this.tipo = localStorage.getItem('tipo');

      if (this.tipo == 'cadastro') {
        var x = localStorage.getItem('cadastro-save');
        var mydata = JSON.parse(x);
        this.nome = mydata['nome'].toUpperCase();
        this.email = mydata['email'];
        this.IS.ResetDados();

      } else if (this.tipo == 'contato') {
        var x = localStorage.getItem('contato-save');
        var mydata = JSON.parse(x);
        this.nome = mydata['nome'].toUpperCase();
        this.email = mydata['email'];
        this.copia = mydata['copia'];
        this.IS.ResetDados();

      } else if (this.tipo == 'visitante') {
        var x = localStorage.getItem('visitante-save');
        var mydata = JSON.parse(x);
        this.nome = mydata['nome'].toUpperCase();
        this.email = mydata['email'];
        this.copia = mydata['receber_copia'];
        this.IS.ResetDados();
      }
      else if (this.tipo == 'problema') {
        var x = localStorage.getItem('problema-save');
        var mydata = JSON.parse(x);
        this.nome = mydata['nome'].toUpperCase();
        this.email = mydata['email'];
        this.copia = mydata['copia'];
        this.IS.ResetDados();
      }
      else if (this.tipo == 'encontro-homens') {
        var x = localStorage.getItem('cadastro-homens-save');
        var mydata = JSON.parse(x);
        this.nome = mydata['nome'].toUpperCase();
        this.email = mydata['email'];
        this.IS.ResetDados();
      }
      else if (this.tipo == 'encontro-mulheres') {
        var x = localStorage.getItem('cadastro-mulheres-save');
        var mydata = JSON.parse(x);
        this.nome = mydata['nome'].toUpperCase();
        this.email = mydata['email'];
        this.IS.ResetDados();
      }
      else if (this.tipo == 'Seminario') {
        try {
          var x = localStorage.getItem('Seminario-save');
          var mydata = JSON.parse(x);
          this.nome = mydata['nome'].toUpperCase();
          this.email = mydata['email'];
          this.dados = mydata['tipo_seminario'];
          this.IS.ResetDados();
        } catch {
          this.email = 'SEM EMAIL';
          this.IS.ResetDados();
        }
      }
      else if (this.tipo == 'CongressoInfantilRegistrado') {
        try {
          var x = localStorage.getItem('CongressoInfantil-save');
          var mydata = JSON.parse(x);
          this.nome = mydata['nome_filho'].toUpperCase();
          this.email = mydata['email'];
          this.IS.ResetDados();
        } catch {
          this.email = 'SEM EMAIL';
          this.IS.ResetDados();
        }
      }
      else if (this.tipo == 'CongressoMulheres') {
        try {
          var x = localStorage.getItem('CongressoMulheres-save');
          var mydata = JSON.parse(x);
          this.nome = mydata['nome'].toUpperCase();
          this.email = mydata['email'];
          this.IS.ResetDados();
        } catch {
          this.email = 'SEM EMAIL';
          this.IS.ResetDados();
        }
      }
      else if (this.tipo == 'CongressoHomens') {
        try {
          var x = localStorage.getItem('CongressoHomens-save');
          var mydata = JSON.parse(x);
          this.nome = mydata['nome'].toUpperCase();
          this.email = mydata['email'];
          this.IS.ResetDados();
        } catch {
          this.email = 'SEM EMAIL';
          this.IS.ResetDados();
        }
      }
      else if (this.tipo == 'CongressoJovens') {
        try {
          var x = localStorage.getItem('CongressoJovens-save');
          var mydata = JSON.parse(x);
          this.nome = mydata['nome'].toUpperCase();
          this.email = mydata['email'];
          this.IS.ResetDados();
        } catch {
          this.email = 'SEM EMAIL';
          this.IS.ResetDados();
        }
      }
      else if (this.tipo == 'CultoRegistrado') {
        try {
          var x = localStorage.getItem('Culto-save');
          var mydata = JSON.parse(x);
          this.nome = mydata['nome'].toUpperCase();
          this.email = mydata['email'];
          this.dados = mydata['tipo_culto'];
          this.IS.ResetDados();

        } catch {
          this.email = 'SEM EMAIL';
          this.IS.ResetDados();
        }
      }
      else if (this.tipo == 'curso-casais') {
        try {
          var x = localStorage.getItem('curso-casais-save');
          var mydata = JSON.parse(x);
          this.nome = mydata['nome_esposa'].toUpperCase()+" & "+mydata['nome_esposo'].toUpperCase();
          this.email = mydata['email_esposa']+" - "+ mydata['email_esposo'];
          this.IS.ResetDados();

        } catch {
          var x = localStorage.getItem('curso-casais-save');
          var mydata = JSON.parse(x);
          this.nome = mydata['nome_esposa'].toUpperCase()+" - "+mydata['nome_esposo'].toUpperCase();
          this.email = 'SEM EMAIL';
          this.IS.ResetDados();

        }
      }
      else if (this.tipo == 'encontro-casais') {
        try {
          var x = localStorage.getItem('encontro-casais-save');
          var mydata = JSON.parse(x);
          this.nome = mydata['nome_esposa'].toUpperCase()+" & "+mydata['nome_esposo'].toUpperCase();
          this.email = mydata['email_esposa']+" - "+ mydata['email_esposo'];
          this.IS.ResetDados();

        } catch {
          var x = localStorage.getItem('encontro-casais-save');
          var mydata = JSON.parse(x);
          this.nome = mydata['nome_esposa'].toUpperCase()+" - "+mydata['nome_esposo'].toUpperCase();
          this.email = 'SEM EMAIL';
          this.IS.ResetDados();

        }
      }
      else if (this.tipo == 'CadastroBatismo') {
        try {
          var x = localStorage.getItem('CadastroBatismo-save');
          var mydata = JSON.parse(x);
          this.nome = mydata['nome'].toUpperCase();
          this.email = mydata['email'];
          this.IS.ResetDados();

        } catch {
          var x = localStorage.getItem('CadastroBatismo-save');
          var mydata = JSON.parse(x);
          this.nome = mydata['nome'].toUpperCase();
          this.email = 'SEM EMAIL';
          this.IS.ResetDados();

        }
      }
      else if (this.tipo == 'QRCODE') {
        try {
          this.IS.ResetDados();
        } catch {
          this.IS.ResetDados();
        }
      }
      else if (this.tipo == 'QRCODE400') {
        try {
          this.IS.ResetDados();
        } catch {
          this.IS.ResetDados();
        }
      }
      else if (this.tipo == 'QRCODE422') {
        try {
          this.IS.ResetDados();
        } catch {
          this.IS.ResetDados();
        }
      }

      else if (this.tipo == 'DOCUMENTO') {
        try {
          this.IS.ResetDados();
        } catch {
          this.IS.ResetDados();
        }
      }
      else if (this.tipo == 'DOCUMENTO400') {
        try {
          this.IS.ResetDados();
        } catch {
          this.IS.ResetDados();
        }
      }
      else if (this.tipo == 'DOCUMENTO422') {
        try {
          this.IS.ResetDados();
        } catch {
          this.IS.ResetDados();
        }
      }

      else if (this.tipo == 'CultoKidsRegistrado') {
        try {
          var x = localStorage.getItem('CultoKids-save');
          var mydata = JSON.parse(x);
          this.nome = mydata['nome'].toUpperCase();
          this.email = mydata['email'];
          this.dados = mydata['tipo_culto'];
          this.IS.ResetDados();
        } catch {
          this.email = 'SEM EMAIL';
          this.IS.ResetDados();
        }
      }
      else if (this.tipo == 'cancelamento') {

      }
      else if (this.tipo == 'confirmacao') {
        var x = localStorage.getItem('confirmacao-save');

        this._serviceretorno.GetRecuperarUsuarioHash(x).subscribe(
          (success) => {
            this.nome = success.nome.toUpperCase();
            this.email = success.email;
          },
          () => {

          }
        )
        this.IS.ResetDados();
      } else {
        this.IS.ResetDados();
        this.rota.navigate(['error']);
      }
    } catch {
      this.IS.ResetDados();
      this.rota.navigate(['error'])
    }
  }

  ngOnDestroy(): void {
    this.IS.ResetDados();
  }

}


