import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { Parametros } from 'src/app/shared/parametros.enum';
import { SharedModule } from 'src/app/shared/shared.module';
import { ParametrosModel } from '../../configuracaoes/ParametrosModel';
import { patrimoniomodel } from '../registrar-patrimonio/PatrimonioModel';
import { ResumoPatrimonioService } from './resumo-patrimonio.service';

@Component({
  selector: 'app-resumo-patrimonio-setor',
  templateUrl: './resumo-patrimonio-setor.component.html',
  styleUrls: ['./resumo-patrimonio-setor.component.css']
})
export class ResumoPatrimonioSetorComponent implements OnInit {
  private urlpatrimonio = Parametros.PATRIMONIO;


  x = 1;
  y = 10;
  filtro_cabecalho: string = ""
  explicacao: string = "";
  textoconsulta: string = "";
  rota: any;
  perfil: any;
  pagina_atual = 1;
  imprimir: string = "Todos";
  idusuario: any;
  nome: string;
  opcao: string = "Ativar";
  private _perfil: string;


  constructor(private http: HttpClient,
    private primengConfig: PrimeNGConfig,
    private alert: AlertModalService,
    private autenticado: SharedModule,
    private service: ResumoPatrimonioService,
    r: Router
  ) {
    this.rota = r;
    this.perfil = this.autenticado.perfil;
    this.nome = this.autenticado.nome;
    this._perfil = this.autenticado.perfil;
  }

  startedClass = false;
  completedClass = false;
  preventAbuse = false;

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//

  cadastros: Array<patrimoniomodel>;
  qto: number;
  cols: any[];


  GetLista() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.service.Resumo().subscribe(
      (success) => {
        this.cadastros = success;
        this.qto = success.length;
      }
    )
  }



  ngOnInit() {
    if (this.autenticado.token == null) {
      this.autenticado.ResetDados();
      this.alert.AlertShowDanger('Usuário não autenticado, redirecionando...')
      this.rota.navigate(['/login']);
    } else {
      if (this.autenticado.ValidarToken() != true) {
        this.autenticado.ResetDados();
        this.alert.AlertShowDanger('Token Invalido!')
        this.rota.navigate(['/login']);
      }
    }
    this.primengConfig.ripple = true;
    this.filtro_cabecalho = "Valor total por Setor "
    this.explicacao = "Resumo de todos os setores que contem patrimonio ATIVOS cadastrados e seus respectivos valores."
    this.GetLista();


    this.cols = [
      { field: 'Setor', header: 'Setor' },
      { field: 'Quantidade', header: 'Quantidade' },
      { field: 'Valor Total', header: 'Valor Total' }
    ];
  }

  Paginate(event) {
    if (event.page == 0) {
      this.x = 1;
      this.y = 10;
    } else {

      if (event.page * 10 > this.qto) {
        this.x = this.qto;
        this.y = this.qto;
      } else {
        this.x = event.page * 10;
        if (this.x + 10 > this.qto) {
          this.y = this.qto;
        } else {
          this.y = this.x + 10;
        }
      }
    }
    this.pagina_atual = event.page;

    this.GetListaTotalQuantidadeDez(this.pagina_atual);
  }

  Consulta() {
    var texto = (<HTMLInputElement>document.getElementById("texto_consulta")).value;
    if (texto != "") {
      this.textoconsulta = texto;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
        })
      }
      this.http.get<any>(this.urlpatrimonio + 'GetListaConsultaSetores/?parametro=' + texto+'&tipo=TODOS SETORES', httpOptions).subscribe(
        (success) => {
          this.cadastros = success;
          this.qto = success.length;
        },
        (error)=>{
          
        }

      )
    } else {
      this.textoconsulta = "";
      this.GetLista();
    }
  }

  GetListaTotalQuantidadeDez(pagina: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get<any>(this.urlpatrimonio + 'GetListaConsultaLimite/?parametro=' + pagina, httpOptions).subscribe(
      (success) => {
        this.cadastros = success;
        this.qto = success.length;
      }
    )
  }



  VisualizarDetalhe(event) {
    localStorage.removeItem('SetorPatrimonio');
    localStorage.setItem('SetorPatrimonio', event.currentTarget.value);
    this.rota.navigate(['/dashboard/gerenciamento/patrimonio/listagem-patrimonio']);
  }


  SetDados(event) {
    this.idusuario = event.currentTarget.value;
  }

  SetOpcao(event) {
    this.opcao = event.currentTarget.value;
  }




}
