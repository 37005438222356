import { AlertModalService } from './../../shared/alert-modal/alert-modal.service';
import { RecuperarAutenticacaoService } from './recuperar-autenticacao.service';
import { UsuarioModel } from './usuario-model';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-recuperar-autenticacao',
  templateUrl: './recuperar-autenticacao.component.html',
  styleUrls: ['./recuperar-autenticacao.component.css']
})
export class RecuperarAutenticacaoComponent implements OnInit {

  formRecuperacaoLogin: FormGroup;
  model: UsuarioModel;
  modalconteudo: string = '';
  modaltitulo: string = ''
  startedClass = false;
  completedClass = false;
  preventAbuse = false;
  submitted = false;

  constructor(private alert: AlertModalService,
    private formBuilder: FormBuilder,
    private recuperarservice: RecuperarAutenticacaoService
  ) { }

  ngOnInit() {
    this.criarform();

  }
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }

  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
    // pegar o nome dos campos para validação
    get f() {
      return this.formRecuperacaoLogin.controls;
    }

  criarform() {
    this.formRecuperacaoLogin = this.formBuilder.group(
      {
        identificacao: ["", [Validators.required,Validators.minLength(11),Validators.maxLength(14)]]
      }
    );
  }
  RecuperarLogin() {
    this.submitted = true;
    if (this.formRecuperacaoLogin.get('identificacao').value.length < 11) {
      this.alert.AlertShowDanger('Favor informar o CPF corretamente!')
    } else {
      this.StartProgressBar();
      this.recuperarservice.GetRecuperarAutenticacao(this.formRecuperacaoLogin.get('identificacao').value).subscribe(
        success => {
          this.StartProgressBar();
          if (success.dt_cancelamento !=null) {
            document.getElementById("openModalButton").click();
            this.modaltitulo = 'Cadastro Cancelado!'
            this.modalconteudo = 'Olá, <b>' + success.nome + '! </b><br>Estou verificando nas minhas anotações e observei que seu cadastro encontra-se <b>CANCELADO</b>,<br>Solicitamos por favor que caso não tenha cancelado ou precise reativar o mesmo que utilize o botão "Reportar problemas" para que possamos analisar e lhe ajudar!'
          }
          else if (success.ativo == 'F' && success.email!=null) {
            document.getElementById("openModalButton").click();
            this.modaltitulo = 'Cadastro Inativo'
            this.modalconteudo = 'Olá, <b>' + success.nome + '! </b><br>Estou verificando nas minhas anotações e observei que seu cadastro encontra-se <b>INATIVO</b>,<br>Solicitamos por favor verifique na sua caixa de e-mail se não existe um pedido de confirmação de cadastro, caso já tenha feito este procedimento por favor nos informe utilizando o botão "Reportar problemas" para que possamos analisar e lhe ajudar!'
          }else if (success.ativo == 'F' && success.email ==null) {
            document.getElementById("openModalButton").click();
            this.modaltitulo = 'Cadastro Inativo'
            this.modalconteudo = 'Olá, <b>' + success.nome + '! </b><br>Estou verificando nas minhas anotações e observei que seu cadastro encontra-se <b>INATIVO</b>,Como não informou e-mail no momento do cadastro o mesmo está aguardando liberação por parte de nossa administração, solicitamos que aguarde por favor e caso tenha se passado mais de 48hs do cadastro por favor nos envie uma mensagem utilizando o botão "Reportar problemas" para que possamos analisar e lhe ajudar!'
          }  
          else if (success.ativo == 'T' && success.email ==null) {
            document.getElementById("openModalButton").click();
            this.modaltitulo = 'Recuperação Não pode ser realizada!'
            this.modalconteudo = 'Olá, <b>' + success.nome + '! </b><br>Lamentamos informar que a recuperação não e possivel pois não existe email no seu cadastro, mas não se preocupe que iremos lhe ajudar de forma simples e rapida!<br>Para que possamos lhe ajudar clique no botão abaixo Reportar Problema e nos comunique o que está ocorrendo,para que possamos lhe ajudar rapidamente a resolver este problema!<br>'
          
          }else if (success.ativo == 'T' && success.email !=null) {
            document.getElementById("openModalButton").click();
            this.modaltitulo = 'Recuperação realizada com sucesso!'
            this.modalconteudo = 'Olá, <b>' + success.nome + '! </b><br>Você pediu e nós lhe atendemos!<br>Acabamos de enviar um email para <b>' + success.email + '</b> Basta abrir seu email para que tenha acesso aos dados de login e senha,<br>Em caso de duvidas não deixe de nos comunicar!'
          }
        },
        error => {
          if(error.status=="400"){
            this.StartProgressBar();
            this.alert.AlertShowDanger(error.error);
          }else if(error.status=="500"){
            this.alert.AlertShowDanger(error.error);
          }else{
            this.alert.AlertShowDanger('( '+ error.status+' ) '+ error.error);
          }
        });
    }
  }
}
