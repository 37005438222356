import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { UsuarioModel } from 'src/app/Recuperacoes/recuperar-autenticacao/usuario-model';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { PerfilService } from '../../perfil/perfil.service';
import { MinisterioModel } from './MinisterioModel';
import { MembrosMinisterioAjustesService } from './membros-ministerio-ajustes.service';

@Component({
  selector: 'app-membros-ministerio-ajustes',
  templateUrl: './membros-ministerio-ajustes.component.html',
  styleUrls: ['./membros-ministerio-ajustes.component.css']
})
export class MembrosMinisterioAjustesComponent implements OnInit {
  rota: Router;
  perfil: string;
  nome: string;
  cpf: string;
  idusuario: string;


  formusuario: FormGroup;
  formministerio: FormGroup;
  submitted = false;
  modelusuario: UsuarioModel;
  modelministerio: MinisterioModel;

  membro: boolean = false;
  aluno: boolean = false;
  obreiro: boolean = false;
  consolidacao: boolean = false;
  diacono: boolean = false;
  louvor: boolean = false;
  secretaria: boolean = false;
  pastor_presidente: boolean = false;
  pastor_auxiliar: boolean = false;
  ministerio_casais: boolean = false;
  ministerio_jovem: boolean = false;
  ministerio_mulheres: boolean = false;
  ministerio_homens: boolean = false;
  ministerio_danca: boolean = false;

  constructor(private http: HttpClient,
    private primengConfig: PrimeNGConfig,
    private alert: AlertModalService,
    private autenticado: SharedModule,
    private formBuilder: FormBuilder,
    private serviceUsuario: PerfilService,
    private serviceministerio: MembrosMinisterioAjustesService,
    r: Router
  ) {
    this.rota = r;
    this.perfil = this.autenticado.perfil;
    this.nome = this.autenticado.nome;
    this.cpf = this.autenticado.cpf;
    this.modelusuario = new UsuarioModel();
    this.modelministerio = new MinisterioModel();

  }

  startedClass = false;
  completedClass = false;
  preventAbuse = false;

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//


  criarformusuario() {
    this.formusuario = this.formBuilder.group({
      cpf: ['', Validators.required],
      nome: ['', Validators.required]

    });
  }

  criarformministerio() {
    this.formministerio = this.formBuilder.group({
      idusuario: [this.idusuario, Validators.required],
      membro: [false, Validators.required],
      aluno: [false, Validators.required],
      obreiro: [false, Validators.required],
      consolidacao: [false, Validators.required],
      diacono: [false, Validators.required],
      louvor: [false, Validators.required],
      secretaria: [false, Validators.required],
      pastor_presidente: [false, Validators.required],
      pastor_auxiliar: [false, Validators.required],
      ministerio_casais: [false, Validators.required],
      ministerio_jovem: [false, Validators.required],
      ministerio_mulheres: [false, Validators.required],
      ministerio_homens: [false, Validators.required],
      ministerio_danca: [false, Validators.required]
    });
  }



  ngOnInit(): void {
    if (this.autenticado.token == null) {
      this.autenticado.ResetDados();
      this.alert.AlertShowDanger('Usuário não autenticado, redirecionando...')
      this.rota.navigate(['/login']);
    } else {
      if (this.autenticado.ValidarToken() != true) {
        this.autenticado.ResetDados();
        this.alert.AlertShowDanger('Token Invalido!')
        this.rota.navigate(['/login']);
      }
    }

    this.idusuario = localStorage.getItem('UsuarioPerfilDetalhe');
    if (this.idusuario == null || this.idusuario == "") {
      this.rota.navigate(['/dashboard/perfil/perfil-usuario']);
    } else {
      localStorage.removeItem('UsuarioPerfilDetalhe');


      this.criarformusuario();
      this.criarformministerio();
      this.carregarusuario();
      this.CarregarMinisterio();
    }
  }

  carregarusuario() {
    this.serviceUsuario
      .GETUsuarioID(this.idusuario)
      .subscribe(
        (success) => {
          this.StopProgressBar();
          if (success.nome != null) {
            (this.modelusuario.nome = success.nome),
              (this.modelusuario.cpf = success.cpf)
          } else {
            this.rota.navigate(['/dashboard/perfil/perfil-usuario']);
          }
        },
        (error) => {
          this.StopProgressBar();
          this.alert.AlertShowDanger(
            '( ' + error.status + ' ) ' + error.message
          );
        }
      );
  }


  get c() {
    return this.formministerio.controls;
  }

  get u() {
    return this.formusuario.controls;
  }



  CarregarMinisterio() {
    this.serviceministerio
      .GETMinisterio(this.idusuario)
      .subscribe(
        (success) => {
          this.StopProgressBar();
          this.modelministerio.idusuario = this.idusuario;

          if (success.membro == "T") {
            this.modelministerio.membro = true;
            this.membro = true;
          }

          if (success.aluno == "T") {
            this.modelministerio.aluno = true;
            this.aluno = true;
          }
          if (success.consolidacao == "T") {
            this.modelministerio.consolidacao = true;
            this.consolidacao = true;
          }
          if (success.obreiro == "T") {
            this.modelministerio.obreiro = true;
            this.obreiro = true;
          }
          if (success.diacono == "T") {
            this.modelministerio.diacono = true;
            this.diacono = true;
          }
          if (success.louvor == "T") {
            this.modelministerio.louvor = true;
            this.louvor = true;
          }
          if (success.secretaria == "T") {
            this.modelministerio.secretaria = true;
            this.secretaria = true;
          }
          if (success.pastor_presidente == "T") {
            this.modelministerio.pastor_presidente = true;
            this.pastor_presidente = true;
          }
          if (success.pastor_auxiliar == "T") {
            this.modelministerio.pastor_auxiliar = true;
            this.pastor_auxiliar = true;
          }
          if (success.ministerio_casais == "T") {
            this.modelministerio.ministerio_casais = true;
            this.ministerio_casais = true;
          }
          if (success.ministerio_jovem == "T") {
            this.modelministerio.ministerio_jovem = true;
            this.ministerio_jovem = true;
          }
          if (success.ministerio_mulheres == "T") {
            this.modelministerio.ministerio_mulheres = true;
            this.ministerio_mulheres = true;
          }
          if (success.ministerio_homens == "T") {
            this.modelministerio.ministerio_homens = true;
            this.ministerio_homens = true;
          }
          if (success.ministerio_danca == "T") {
            this.modelministerio.ministerio_danca = true;
            this.ministerio_danca = true;
          }
          
        },
        (error) => {
          this.StopProgressBar();
          this.alert.AlertShowDanger(
            '( ' + error.status + ' ) ' + error.message
          );
        }
      );
  }


  onSubmit() {
    this.submitted = true;

    if (this.formusuario.invalid) {
      this.alert.AlertShowDanger('Favor verificar os campos em destaque!');
      return;
    }
    if (this.formministerio.invalid) {
      this.alert.AlertShowDanger('Favor verificar os campos em destaque!');
      return;
    }

    this.serviceministerio.POSMinisterio(this.formministerio.value).subscribe(
      () => {
        this.alert.AlertShowSucess('Ministerios atualizados com sucesso!');
      },
      (error) => {
        this.alert.AlertShowDanger('Erro ao Atualizar Ministerios!');
      });
  }

}
