import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { AgendaService } from '../nossa-agenda/agenda.service';
import { AgendaModel } from '../nossa-agenda/AgendaModel';
import { AlertModalService } from '../shared/alert-modal/alert-modal.service';
import { EventEmitterService } from '../shared/EventEmitterService';
import { SharedModule } from '../shared/shared.module';
declare var jQuery: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [NgbCarouselConfig]
})
export class HomeComponent {

  images = [944, 1011, 984].map((n) => `https://picsum.photos/id/${n}/900/500`);

  rota: Router;
  contato: any;
  submitted = false;
  formcadastro: FormGroup;

  constructor(private autenticado: SharedModule,
    private formBuilder: FormBuilder,
    private alert: AlertModalService,
    private _service: AgendaService,
    router: Router
  ) {
    this.rota = router;
    EventEmitterService.get('loginAlert').emit(false);
  }

  startedClass = false;
  completedClass = false;
  preventAbuse = false;

   //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//


  ngOnInit() {
    this.autenticado.ResetDados();

    if (!localStorage.MoradadeDeus_Cookies) {
      document.querySelector(".box-cookies").classList.remove('hide');
    }

    const acceptCookies = () => {
      document.querySelector(".box-cookies").classList.add('hide');
      localStorage.setItem("MoradadeDeus_Cookies", "accept");
    };

    const btnCookies = document.querySelector(".btn-cookies");

    btnCookies.addEventListener('click', acceptCookies);

    this.contato = new AgendaModel();
    this.criarform();

  }

  modal(e) {
    document.getElementById('openModalButton').click();
  }

  registrar() {
    this.submitted = true;
    if (this.formcadastro.controls['nome'].value == "" || this.formcadastro.controls['email'].value == "") {
      this.alert.AlertShowDanger('Favor preencher todos os campos corretamente!');
    } else {


      this._service
        .Cadastro(this.formcadastro.value)
        .subscribe(
          () => {
            jQuery("#exampleModal").modal("hide");
            this.StopProgressBar();
            this.alert.AlertShowSucess('Newsletter assinada com sucesso');
          },
          (error) => {
            jQuery("#exampleModal").modal("hide");
            this.StopProgressBar();
            this.alert.AlertShowDanger('( ' + error.status + ' ) ' + error.error);
          }
        );
    }
  }

  criarform() {
    this.formcadastro = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      nome: ['', Validators.required]
    });
  }

  // Pegar nome dos campos,
  //====================================================================//
  get f() {
    return this.formcadastro.controls;
  }
  //====================================================================//


}



