import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { Parametros } from 'src/app/shared/parametros.enum';
import { SharedModule } from 'src/app/shared/shared.module';
import { Visitante } from 'src/app/visitante/visitante-model';

@Component({
  selector: 'app-visitantes-detalhe',
  templateUrl: './visitantes-detalhe.component.html',
  styleUrls: ['./visitantes-detalhe.component.css']
})
export class VisitantesDetalheComponent implements OnInit {

  idusuario: any;
  rota: any;
  private urlacessos = Parametros.VISITANTE;


  nome: string = "";
  cpf: any = "";
  estadocivil: string = "";
  dt_nascimento: any = "";
  telefone: string = "";
  email: string = "";
  dt_culto: any = "";
  igreja_pertence: string = "";
  primeira_visita: string = "";
  Receber_visita: string = "";
  convite: string = "";
  mensagem: string = "";
  respondido: string = "";
  dt_respondido: string = "";

  visitante: Array<Visitante>;

  constructor(private http: HttpClient,
    private alert: AlertModalService,
    private autenticado: SharedModule,
    private r: Router) {
    this.rota = r

  }

  startedClass = false;
  completedClass = false;
  preventAbuse = false;

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//

  ngOnInit(): void {

    if (this.autenticado.token == null) {
      this.autenticado.ResetDados();
      this.alert.AlertShowDanger('Usuário não autenticado, redirecionando...')
      this.rota.navigate(['/login']);
    } else {
      if (this.autenticado.ValidarToken() != true) {
        this.autenticado.ResetDados();
        this.alert.AlertShowDanger('Token Invalido!')
        this.rota.navigate(['/login']);
      }
    }

    this.idusuario = localStorage.getItem('VisitanteDetalhe');
    if (this.idusuario == null || this.idusuario == "") {
      this.rota.navigate(['/dashboard/gerenciamento/visitantes/visitantes-listagem']);
    } else {
      //localStorage.removeItem(' ElogioDetalhe')
      this.GetVisitantes();
    }
  }

  GetVisitantes() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get<Visitante>(this.urlacessos + 'GETLoadVisitante/?parametro=' + this.idusuario, httpOptions).subscribe(
      (success) => {
        this.nome = success.nome;
        this.estadocivil = success.estado_civil;
        this.dt_nascimento = success.dt_nascimento.replace("T00:00:00", "");
        this.telefone = success.telefone;
        this.email = success.email;
        this.dt_culto = success.dt_culto.replace("T00:00:00", "");;
        this.igreja_pertence = success.igreja_pertence;
        this.primeira_visita = success.primeira_visita;
        this.Receber_visita = success.receber_visita;
        this.convite = success.convite;
        this.mensagem = success.mensagem;
        this.respondido = success.respondido.replace("T","SIM").replace("F","NÃO");
        this.dt_respondido = success.dt_respondido.replace("T00:00:00", "");
      },
      (error) => {
        this.rota.navigate(['/dashboard/gerenciamento/visitantes/visitantes-listagem']);
      }
    )
  }


}
