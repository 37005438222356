<!--Sem autenticação "Padrão"
Tem mais informação no sem logado pois carrega uma pagina com links para o cliente navegar-->
<div *ngIf="Autenticado==false">
    <!--Menu (navbar)-->
    <app-menu-navbar></app-menu-navbar>
    <div style="padding-top: 3px;">
        <ng-container *ngTemplateOutlet="routerOutletTemplate"></ng-container>
    </div>
    <!--Footer-->
    <div style="padding-top: 10px;">
        <app-footer></app-footer>
    </div>
</div>

<!--Autenticação "Dashboard"-->
<div *ngIf="Autenticado==true">

    <body class="background">
        <ng-container *ngTemplateOutlet="routerOutletTemplate"></ng-container>

        <app-footer-autenticado></app-footer-autenticado>
    </body>
</div>


<!--Rota Outlet padrão para toda aplicação-->
<ng-template #routerOutletTemplate>
    <router-outlet>
    </router-outlet>
</ng-template>