<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>
<div>
    <div class="row">
        <div class="col-md-2" style="margin-right: 2px; padding: 0%;">
            <img src="../../../assets/img/configuracoes.png" height="100px" width="200px" style="padding: 0%;" />
        </div>
        <div class="col-md">
            <h4 class="espacamentotopo " style="margin-left: 5px;">
                Ajuste Perfil
            </h4>
            <label style="margin-left: 5px;">
                Aqui você define Qual a permissão do usuario!.
                <br>
                Tome cuidado ao selecionar o perfil para que não seja liberado perfil superior ao necessario do
                usuario.<br>
            </label>
        </div>
    </div>

    <h4>Permissões de Acesso</h4>
    <hr class="espacamentotopo bold" />
    <form [formGroup]="formusuario" disabled="">
        <div class="row">
            <!---INCIO PRIMEIRA LINHA-->
            <div class="col-md-2">
                <div class="form-group">
                    <label>CPF:</label><strong class="cor">*</strong>
                    <input type="text" disabled="true" class="form-control" id="cpf" placeholder="informe o CPF"
                        mask="CPF_CNPJ" name="cpf" maxlength="14" autocomplete="off" formControlName="cpf"
                        [(ngModel)]="modelusuario.cpf" [ngClass]="{ 'is-invalid': submitted && u.cpf.errors }" />
                    <div *ngIf="submitted && u.cpf.errors" class="invalid-feedback">
                        <div *ngIf="u.cpf.errors.required">Preenchimento obrigatório</div>
                    </div>
                </div>
            </div>

            <div class="col-md-10">
                <div class="form-group">
                    <label>Nome Completo:</label><strong class="cor">*</strong>
                    <input type="text" disabled="true" class="form-control" id="nome" name="nome"
                        [(ngModel)]="modelusuario.nome" placeholder="Informe Nome Completo" formControlName="nome"
                        maxlength="500" minlength="3" autocomplete="off"
                        [ngClass]="{ 'is-invalid': submitted && u.nome.errors }" />
                    <div *ngIf="submitted && u.nome.errors" class="invalid-feedback">
                        <div *ngIf="u.nome.errors.required">Preenchimento obrigatório</div>
                    </div>
                </div>
            </div>
        </div>
    </form>


    <h4>Participação de Ministerios</h4>

    <form [formGroup]="formministerio" disabled="">
        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="membro" disabled data-md-icheck formControlName="membro" [(ngModel)]="membro" />
                    <b>&nbsp;&nbsp;Membro</b><br><span>Ministerio PADRÃO para todos usuarios da plataforma.</span>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="aluno" data-md-icheck formControlName="aluno" [(ngModel)]="aluno" />
                    <b>&nbsp;&nbsp;Aluno</b><br><span>Classificação no ministerio de Aluno.</span>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="consolidacao" data-md-icheck formControlName="consolidacao"
                        [(ngModel)]="consolidacao" />
                    <b>&nbsp;&nbsp;Consolidação</b><br><span>Classificação no ministerio de Consolidação.</span>
                </div>
            </div>
        </div>

        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="obreiro" data-md-icheck formControlName="obreiro"
                        [(ngModel)]="obreiro" />
                    <b>&nbsp;&nbsp;Obreiro</b><br><span>Classificação no ministerio de Obreiro.</span>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="diacono" data-md-icheck formControlName="diacono"
                        [(ngModel)]="diacono" />
                    <b>&nbsp;&nbsp;Diaconato</b><br><span>Classificação no ministerio de Diaconato.</span>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="secretaria" data-md-icheck formControlName="secretaria"
                        [(ngModel)]="secretaria" />
                    <b>&nbsp;&nbsp;Secretaria</b><br><span>Classificação no ministerio de Secretaria.</span>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="louvor" data-md-icheck formControlName="louvor" [(ngModel)]="louvor" />
                    <b>&nbsp;&nbsp;Louvor</b><br><span>Classificação no ministerio de Louvor.</span>
                </div>
            </div>
        </div>

        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="pastor_presidente" data-md-icheck formControlName="pastor_presidente"
                        [(ngModel)]="pastor_presidente" />
                    <b>&nbsp;&nbsp;Pastor Presidente</b><br><span>Classificação no ministerio de Pastor
                        Presidente.</span>
                </div>
            </div>
        </div>

        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="pastor_auxiliar" data-md-icheck formControlName="pastor_auxiliar"
                        [(ngModel)]="pastor_auxiliar" />
                    <b>&nbsp;&nbsp;Pastor Auxiliar</b><br><span>Classificação no ministerio de Pastor Auxiliar.</span>
                </div>
            </div>
        </div>

        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="ministerio_casais" data-md-icheck formControlName="ministerio_casais"
                        [(ngModel)]="ministerio_casais" />
                    <b>&nbsp;&nbsp;Ministerio Casais</b><br><span>Classificação no ministerio de Ministerio de
                        Casais.</span>
                </div>
            </div>
        </div>

        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="ministerio_jovem" data-md-icheck formControlName="ministerio_jovem"
                        [(ngModel)]="ministerio_jovem" />
                    <b>&nbsp;&nbsp;Ministerio Jovem</b><br><span>Classificação no ministerio de Ministerio Jovem.</span>
                </div>
            </div>
        </div>

        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="ministerio_mulheres" data-md-icheck formControlName="ministerio_mulheres"
                        [(ngModel)]="ministerio_mulheres" />
                    <b>&nbsp;&nbsp;Ministerio Mulheres</b><br><span>Classificação no ministerio de Ministerio
                        Mulheres.</span>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="ministerio_homens" data-md-icheck formControlName="ministerio_homens"
                        [(ngModel)]="ministerio_homens" />
                    <b>&nbsp;&nbsp;Ministerio Homens</b><br><span>Classificação no ministerio de Ministerio
                        Homens.</span>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="ministerio_danca" data-md-icheck formControlName="ministerio_danca"
                        [(ngModel)]="ministerio_danca" />
                    <b>&nbsp;&nbsp;Ministerio Dança</b><br><span>Classificação no ministerio de Dança
                        Homens.</span>
                </div>
            </div>
        </div>
    </form>
    <hr>

    <div>
        <button pButton pRipple type="button" icon="pi pi-save" class="p-button-rounded" title="::Salvar Alterações::"
            (click)="onSubmit()">
        </button>
        <button pButton pRipple type="button" icon="pi pi-sign-in" style="margin-left: 5px;" class="p-button-rounded p-button-danger"
            title="::Fechar::" routerLink="/dashboard/perfil/perfil-usuario">
        </button>
    </div>



    <br><br><br><br>


</div>
<button class="voltar-ao-topo" id="back"><a class="ancora" style="color: white;"><i
    class="pi pi-arrow-circle-up"></i></a></button>