export class Model {
    [x: string]: any;
    tipo_documento: string = '';
    cpf: string = '';
    nome: string = '';
    telefone: string = '';
    celular: string = '';
    estado_civil: string = '';
    dt_nascimento: Date;
    email: string = '';
    ficou_sabendo: string = '';
    batizado: string = '';
    rede_social: string = '';
    nome_rede_social: string = '';
    ministerio_morada: string = '';
    ministerio_pertence: string = '';
    pastor_ministerio: string = '';
    convidado_por: string = '';
    parente_participando: string = '';
    nome_parente: string = '';
    uso_medicacao: string = '';
    nome_medicacao: string = '';
    alergico_medicacao: string = '';
    nome_medicacao_alergico: string = '';
    alergico_alimento: string = '';
    nome_alimento_alergico: string = '';
    observacoes: string = '';
    primeiro_contato: string = '';
    primeiro_grau_contato: string = '';
    primeiro_telefone_contato: string = '';
    segundo_contato: string = '';
    segundo_grau_contato: string = '';
    segundo_telefone_contato: string = '';
    
 
    cep: string = '';
    logradouro: string = '';
    numero: number;
    apto: any;
    bairro: string = '';
    uf: string = '';
    cidade: string = '';
 
 
 
 }