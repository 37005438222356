<div style="margin-top: 10px; margin-left: 50px; margin-right: 50px;">
    <h4>Conheça os homens e mulheres que o Senhor levantou para cuidar de nossa igreja.</h4>

    <div class="row">
        <div>
            <div>
                <a routerLink="/desenvolvimento"><img src="../../assets/img/novos/pastores/pr_geraldo.png" width="210"
                        height="210">
                </a>
            </div>
        </div>
        <div class="col-md" style="margin-top: 22px;">
            <b>PR.GERALDO</b>
            <br>
            <b>Cargo:</b> Pastor Presidente<br>
            <b>Resumo:</b> Pastor presidente do ministerio Morada de Deus,casado com a Pra. Cida<br>
        </div>
        <!----->
        <div>
            <div>
                <img class="img-responsive" src="../../assets/img/novos/pastores/pr_rodrigo.png">

            </div>
        </div>

        <div class="col-md" style="margin-top: 22px;">
            <b>PR.RODRIGO</b>
            <br>
            <b>Cargo:</b> Pastor Auxiliar<br>
            <b>Resumo:</b> Pastor auxiliar do ministerio Morada de Deus, casado com a Pra. Lucilene<br>
        </div>
    </div>
    <hr>
    <div class="row">
        <div>
            <div>
                <img src="../../assets/img/novos/pastores/pr_alan.png">

            </div>
        </div>
        <div class="col-md" style="margin-top: 10px;">
            <b>PR.ALAN</b>
            <br>
            <b>Cargo:</b> Pastor Auxiliar<br>
            <b>Resumo:</b> Pastor auxiliar do ministerio Morada de Deus, casado com a Pra. Rita<br>
        </div>
        <!----->

        <div>
            <div>
                <img src="../../assets/img/novos/pastores/pr_carlos.png">

            </div>
        </div>

        <div class="col-md" style="margin-top: 10px;">
            <b>PR.CARLOS</b>
            <br>
            <b>Cargo:</b> Pastor Auxiliar<br>
            <b>Resumo:</b> Pastor auxiliar do ministerio Morada de Deus, casado com obreira Daniely.<br>
        </div>

    </div>
    <hr>
    <div class="row">
        <div>
            <div>
                <img src="../../assets/img/novos/pastores/jonathan.jpeg">

            </div>
        </div>

        <div class="col-md" style="margin-top: 10px;">
            <b>PR.JONATHAN</b>
            <br>
            <b>Cargo:</b> Pastor Auxiliar<br>
            <b>Resumo:</b> Pastor auxiliar do ministerio Morada de Deus, casado.<br>
        </div>
       

            <div>
                <div>
                    <img src="../../assets/img/novos/pastores/pr_jose_lopes.png">
                </div>
            </div>
            <div class="col-md" style="margin-top: 22px;">
                <b>PR.JOSÉ LOPES</b>
                <br>
                <b>Cargo:</b> Pastor Auxiliar<br>
                <b>Resumo:</b> Pastor auxiliar do ministerio Morada de Deus, casado.<br>
            </div>
    
        
    </div>
        <!--
        <div>
            <div>
                <img src="../../assets/img/novos/pastores/pr_joao.png">
            </div>
        </div>

        <div class="col-md" style="margin-top: 22px;">
            <b>PR.JOÃO</b>
            <br>
            <b>Cargo:</b> Pastor Auxiliar<br>
            <b>Resumo:</b> Pastor auxiliar do ministerio Morada de Deus, casado com a Obreira Edilaine<br>
        </div>
-->







    <hr>
    <!----->
    
<!--
        <div>
            <div>
                <img src="../../assets/img/novos/pastores/pr_m_aurelio.png">
            </div>
        </div>
        <div class="col-md" style="margin-top: 22px;">
            <b>PR.MARCO</b>
            <br>
            <b>Cargo:</b> Pastor Auxiliar<br>
            <b>Resumo:</b> Pastor auxiliar do ministerio Morada de Deus, casado com a Pra. Renata<br>
        </div>
    </div>
-->



<hr>

<div class="row">
    <div>
       <div>
            <img src="../../assets/img/novos/pastores/pr_mauro.jpeg">
        </div>
    </div>

    <div class="col-md" style="margin-top: 22px;">
        <b>PR.MAURO</b>
        <br>
        <b>Cargo:</b> Pastor Auxiliar<br>
        <b>Resumo:</b> Pastor auxiliar do ministerio Morada de Deus, casado.<br>
    </div>


    

    <div>
        <div>
            <img src="../../assets/img/novos/pastores/pr_rogerio.png">
        </div>
    </div>
    <div class="col-md" style="margin-top: 22px;">
        <b>PR.ROGERIO</b>
        <br>
        <b>Cargo:</b> Pastor Auxiliar<br>
        <b>Resumo:</b> Pastor auxiliar do ministerio Morada de Deus, casado.<br>
    </div>

    <!--
        <div>

        <div class="col-md" style="margin-top: 22px;">
            <img src="../../assets/img/novos/pastores/pr_ricardo.png">
        </div>
    </div>
    <div class="col-md" style="margin-top: 22px;">
        <b>PR.RICARDO</b>
        <br>
        <b>Cargo:</b> Pastor Auxiliar<br>
        <b>Resumo:</b> Pastor auxiliar do ministerio Morada de Deus, casado com pra.Daniele<br>
    </div>
-->

</div>

<hr>
<div class="row">

    

    <div>
        <div>
            <img src="../../assets/img/novos/pastores/pr_sergio.png">
        </div>
    </div>
    <div class="col-md" style="margin-top: 22px;">
        <b>PR.SERGIO</b>
        <br>
        <b>Cargo:</b> Pastor Auxiliar<br>
        <b>Resumo:</b> Pastor auxiliar do ministerio Morada de Deus, casado.<br>
    </div>

    <div>
        <div>
            <img src="../../assets/img/novos/pastores/pr_ubirata.jpeg">
        </div>
    </div>
    <div class="col-md" style="margin-top: 22px;">
        <b>PR.UBIRATÃ</b>
        <br>
        <b>Cargo:</b> Pastor Auxiliar<br>
        <b>Resumo:</b> Pastor auxiliar do ministerio Morada de Deus, casado.<br>
    </div>


</div>

<hr>
<div class="row">

   

    <div>
        <div>
            <img src="../../assets/img/novos/pastores/pr_cida.png">
        </div>
    </div>

    <div class="col-md" style="margin-top: 22px;">
        <b>PRA.CIDA</b>
        <br>
        <b>Cargo:</b> Pastora Auxiliar<br>
        <b>Resumo:</b> Pastora auxiliar do ministerio Morada de Deus, casada com a Pr. Geraldo<br>
    </div>
    <div>
        <div>
            <img src="../../assets/img/novos/pastores/pra_lucilene.jpeg">
        </div>
    </div>
    <div class="col-md" style="margin-top: 22px;">
        <b>PRA.LUCILENE</b>
        <br>
        <b>Cargo:</b> Pastora Auxiliar<br>
        <b>Resumo:</b> Pastora auxiliar do ministerio Morada de Deus, casada com a Pr. Rodrigo<br>
    </div>

</div>
<hr>
<!--
<div class="row">

    

    <div>
        <div>
            <img src="../../assets/img/novos/pastores/pra_daniely.jpeg">
        </div>
    </div>
    <div class="col-md" style="margin-top: 22px;">
        <b>PRA.DANIELE</b>
        <br>
        <b>Cargo:</b> Pastora Auxiliar<br>
        <b>Resumo:</b> Pastora auxiliar do ministerio Morada de Deus, casada com a Pr. Ricardo<br>
    </div>


    <div>
        <div>
            <img src="../../assets/img/novos/pastores/pra_edilaine.png">
        </div>
    </div>
    <div class="col-md" style="margin-top: 22px;">
        <b>PRA.EDILAINE</b>
        <br>
        <b>Cargo:</b> Pastora Auxiliar<br>
        <b>Resumo:</b> Pastora auxiliar do ministerio Morada de Deus, casada com a Pr. João<br>
    </div>


</div>
-->
<hr>


<div class="row">
   

    

    <div>
        <img src="../../assets/img/novos/pastores/pra_lidia.jpeg">
    </div>

    <div class="col-md" style="margin-top: 22px;">
        <b>PRA.LIDIA</b>
        <br>
        <b>Cargo:</b> Pastora Auxiliar<br>
        <b>Resumo:</b> Pastora auxiliar do ministerio Morada de Deus<br>
    </div>


    <div>

        <div>
            <img src="../../assets/img/novos/pastores/pr_rita.png">
        </div>
    </div>
    <div class="col-md" style="margin-top: 22px;">
        <b>PRA.RITA</b>
        <br>
        <b>Cargo:</b> Pastora Auxiliar<br>
        <b>Resumo:</b> Pastora auxiliar do ministerio Morada de Deus, casada com a Pr. Alan<br>
    </div>



</div>
<hr>




<br>
<div style="padding-left: 50px; padding-bottom: 20px;">
    <button pButton pRipple type="button" icon="pi pi-home" class="p-button-rounded p-button-help" title="::HOME::"
        routerLink=""></button>
</div>
</div>