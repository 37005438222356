import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { Parametros } from 'src/app/shared/parametros.enum';
import { SharedModule } from 'src/app/shared/shared.module';
import { congressohomensmodel } from '../cadastro-congresso-homens/CongressoHomensModel';

@Component({
  selector: 'app-detalhe-participante-congresso-homens',
  templateUrl: './detalhe-participante-congresso-homens.component.html',
  styleUrls: ['./detalhe-participante-congresso-homens.component.css']
})
export class DetalheParticipanteCongressoHomensComponent implements OnInit {

 
  idculto: any;
  rota: any;
  private urlseminario = Parametros.CONGRESSOHOMENS;
  
  nome: string = "";
  data_cadastro: any = "";
  culto: any = "";
  dadosculto: string = "";
  telefone: string = "";
  email: string = "";
  imprimir: any;


  constructor(private http: HttpClient,
    private alert: AlertModalService,
    private autenticado: SharedModule,
    private r: Router) {
    this.rota = r
  }

  startedClass = false;
  completedClass = false;
  preventAbuse = false;

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//

  ngOnInit(): void {
    if (this.autenticado.token == null) {
      this.autenticado.ResetDados();
      this.alert.AlertShowDanger('Usuário não autenticado, redirecionando...')
      this.rota.navigate(['/login']);
    } else {
      if (this.autenticado.ValidarToken() != true) {
        this.autenticado.ResetDados();
        this.alert.AlertShowDanger('Token Invalido!')
        this.rota.navigate(['/login']);
      }
    }
    this.idculto = localStorage.getItem('CongressoHomensDetalhe');
    if (this.idculto == null || this.idculto == "") {
      this.rota.navigate(['/dashboard/gerenciamento/congresso-homens/cadastros']);
    } else {
      this.GetUsuarioSeminario()
    }
  }

  GetUsuarioSeminario() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get<congressohomensmodel>(this.urlseminario + 'GetLoadCongressoHomensDetalhe/?parametro='+ this.idculto, httpOptions).subscribe(
      (success) => {
        this.nome = success.nome;
        this.data_cadastro = success.dt_cadastro.replace("T00:00:00","");
        this.culto = success.congresso;       
        this.telefone = success.telefone;
        this.email = success.email;        
      },
      (error) => {
        this.alert.AlertShowDanger('Erro ao detalhar Usuario');
        this.rota.navigate(['/dashboard/gerenciamento/congresso-homens/cadastros']);
      }
    )
  }
 
}
