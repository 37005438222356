<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>
<h5 class="container">
    <i class="fas fa-user-edit" style="margin-left: 20px;"></i>&nbsp;&nbsp;Registrar Patrimonio
</h5>
<label class="j container">
    Observações!<br />
    &nbsp;&nbsp;&nbsp;&nbsp;Lembramos que os campos com (<strong class="cor"> *</strong> ) são campos de
    preenchimento obrigatório!
</label><br>

<hr class="espacamentotopo bold" />
<form [formGroup]="formpatrimonio" class="m" disabled="">
    <div class="row">


        <!---INCIO PRIMEIRA LINHA-->
        <div class="col-md-3">
            <label>N° Patrimônio:</label><strong class="cor">*</strong>
            <div class="input-group mb-3">
                <input type="text" disabled style="font-weight: bold; text-align: center;" class="form-control" id="numero_patrimonio" name="numero_patrimonio"
                    [(ngModel)]="cadastropatrimonio.numero_patrimonio" placeholder="Informe o numero patrimônio"
                    formControlName="numero_patrimonio" maxlength="500" minlength="3" autocomplete="off" [ngClass]="{
                      'is-invalid': submitted && f.numero_patrimonio.errors
                    }" />
                <div *ngIf="submitted && f.numero_patrimonio.errors" class="invalid-feedback">
                    <div *ngIf="f.numero_patrimonio.errors.required">
                        Preenchimento obrigatório
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-6">
            <div class="form-group">
                <label>Patrimonio:</label><strong class="cor">*</strong>
                <input type="text" class="form-control" id="patrimonio" name="patrimonio"
                    [(ngModel)]="cadastropatrimonio.patrimonio" placeholder="Informe o Patrimônio"
                    formControlName="patrimonio" maxlength="500" minlength="3" autocomplete="off"
                    [ngClass]="{ 'is-invalid': submitted && f.patrimonio.errors }" />
                <div *ngIf="submitted && f.patrimonio.errors" class="invalid-feedback">
                    <div *ngIf="f.patrimonio.errors.required">Preenchimento obrigatório</div>
                </div>
            </div>
        </div>


        <div class=" col-md-3">
            <label>Valor (R$):</label><strong class="cor">*</strong>
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1"><i class="fas fa-dollar-sign"></i></span>
                </div>

                <input type="text" class="form-control" style="font-weight: bold; " mask="separator.2" thousandSeparator="."
                    allowNegativeNumbers="false" minlength="3" maxlength="10" id="valor_estimado" placeholder="0.00" step="any"
                    [(ngModel)]="cadastropatrimonio.valor_estimado" formControlName="valor_estimado"
                    [ngClass]="{ 'is-invalid': submitted && f.valor_estimado.errors }" />

                <div *ngIf="submitted && f.valor_estimado.errors" class="invalid-feedback">
                    <div *ngIf="f.valor_estimado.errors.required">
                        Preenchimento obrigatório
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!---INCIO SEGUNDA LINHA-->
    <div class="row">
        <div class="col-md-3">
            <div class="dropdown">
                <label>Classificação:</label><strong class="cor">*</strong>
                <div class="mb-3">
                    <select class="custom-select" type="text" name="tipo_bem" id="tipo_bem"
                        [(ngModel)]="cadastropatrimonio.tipo_bem" formControlName="tipo_bem"
                        [ngClass]="{ 'is-invalid': submitted && f.tipo_bem.errors }">
                        <div *ngIf="submitted && f.tipo_bem.errors" class="invalid-feedback"></div>
                        <option value=""></option>
                        <option *ngFor="let documento of tipo" [ngValue]="documento">
                            {{ documento }}
                        </option>
                    </select>
                    <div *ngIf="submitted && f.tipo_bem.errors" class="invalid-feedback">
                        <div *ngIf="f.tipo_bem.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-3">
            <div class="dropdown">
                <label>Setor:</label><strong class="cor">*</strong>
                <div class="mb-3">
                    <select class="custom-select" type="text" name="setor" id="setor"
                        [(ngModel)]="cadastropatrimonio.setor" formControlName="setor"
                        [ngClass]="{ 'is-invalid': submitted && f.setor.errors }">
                        <div *ngIf="submitted && f.setor.errors" class="invalid-feedback"></div>
                        <option value=""></option>
                        <option *ngFor="let documentos of setor" [ngValue]="documentos">
                            {{ documentos }}
                        </option>
                    </select>
                    <div *ngIf="submitted && f.setor.errors" class="invalid-feedback">
                        <div *ngIf="f.setor.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>
            </div>
        </div>



        <div class="col-md-3">
            <label>N° Serie:</label>
            <div class="mb-3">
                <input type="text" class="form-control" id="numero_serie" name="numero_serie"
                    [(ngModel)]="cadastropatrimonio.numero_serie" placeholder="Informe o número de série"
                    formControlName="numero_serie" autocomplete="off" />
            </div>
        </div>
        <div class="col-md-3">
            <label>Cod. Extra:</label>
            <div class="mb-2">
                <input type="text" class="form-control" id="codigo_extra" name="codigo_extra"
                    [(ngModel)]="cadastropatrimonio.codigo_extra" placeholder="Informe o código extra"
                    formControlName="codigo_extra" autocomplete="off" />
            </div>
        </div>


    </div>



    <!---INCIO TERCEIRA LINHA-->
    <div class="row">
        <div class="col-md-4">
            <label>Modelo:</label>
            <div class="mb-3">
                <input type="text" class="form-control" id="modelo" name="modelo"
                    [(ngModel)]="cadastropatrimonio.modelo" placeholder="Informe o modelo" formControlName="modelo"
                    autocomplete="off" />
            </div>
        </div>
        <div class="col-md-4">
            <label>Marca:</label>
            <div class="mb-2">
                <input type="text" class="form-control" id="marca" name="marca" [(ngModel)]="cadastropatrimonio.marca"
                    placeholder="Informe a marca" formControlName="marca" autocomplete="off" />
            </div>
        </div>

        <div class="col-md-4">
            <label>Cor:</label>
            <div class="mb-2">
                <input type="text" class="form-control" id="cor" name="cor" [(ngModel)]="cadastropatrimonio.cor"
                    placeholder="Informe a cor" formControlName="cor" autocomplete="off" />
            </div>
        </div>

    </div>

    <!---INCIO QUARTA LINHA-->
    <div class="row">
        <div class="col-md">
            <div class="form-group">
                <label style="padding: 0%;">Detalhes:</label>
                <textarea type="text" rows="2" class="form-control" id="detalhes" aria-describedby="emailHelp"
                    name="detalhes" formControlName="detalhes" [(ngModel)]="cadastropatrimonio.detalhes"
                    autocomplete="off" maxlength="500" minlength="3"></textarea>

                <span>{{ cadastropatrimonio.detalhes.length }}/500</span>
            </div>
        </div>
    </div>


    <!----->
    <div>
        <button pButton pRipple type="button" icon="pi pi-save" class="p-button-rounded" style="margin-right: 5px;"
            title=":: SALVAR ::" (click)="onSubmit()"></button>
        <button pButton pRipple type="button" icon="pi pi-refresh" style="margin-right: 5px;"
            class="p-button-rounded p-button-secondary" title="::LIMPAR::" (click)="reset()"></button>
        
    </div>

</form>