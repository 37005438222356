import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { first, map, retry, take } from 'rxjs/operators';
import { Parametros } from 'src/app/shared/parametros.enum';

@Injectable({
  providedIn: 'root'
})
export class ComunicadoService {

  private urlseminario = Parametros.SEMINARIO;

  constructor(private http: HttpClient) { }
  startedClass = false;
  completedClass = false;
  preventAbuse = false;

  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }

  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }


  UpdateComunicado(token, c) {
    this.StopProgressBar();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + token.replace("\r\n", "").replace("\n", "")
      })
    };
    this.http
      .post(this.urlseminario + 'PostComunicadoEmMassa', c, httpOptions).pipe(first()).subscribe()

  }

  GetQuantidadeEnvio(token, tipo) {
    this.StartProgressBar();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + token.replace("\r\n", "").replace("\n", "")
      })
    };
    return this.http
      .get<any>(this.urlseminario + 'GETQuantidadeEmailEnvio/?parametro=' + tipo, httpOptions)
      .pipe(retry(2));
  }



  UploadArquivo(fileToUpload, token, cpf) {
    this.StartProgressBar();

    const httpOptions = {
      headers: new HttpHeaders({
        'enctype': 'multipart/form-data',
        'Authorization': "Bearer " + token.replace("\r\n", "").replace("\n", "")
      })
    };
    let formData: FormData = new FormData();
    formData.append("arquivo", fileToUpload);

    return this.http
      .post(this.urlseminario + 'Upload/', formData, httpOptions).pipe(map(x => true));
  }

  DownloadFoto(hash, token) {
    this.StartProgressBar();

    const httpOptions = {
      headers: new HttpHeaders({

        'Authorization': "Bearer " + token.replace("\r\n", "").replace("\n", ""),
        'responseType': 'blob' as 'json'

      })
    };


    return this.http
      .get<Blob>(this.urlseminario + 'GETDownloadFoto/?parametro=' + hash, httpOptions).pipe(take(2));
  }

  
}
