import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertModalService } from '../shared/alert-modal/alert-modal.service';
import { Model } from './Model';
import { EncontroHomensService } from './encontro-homens.service';
import { EventEmitterService } from '../shared/EventEmitterService';

@Component({
  selector: 'app-encontro-homens',
  templateUrl: './encontro-homens.component.html',
  styleUrls: ['./encontro-homens.component.css']
})
export class EncontroHomensComponent implements OnInit {

  formcadastro: FormGroup;
  submitted = false;
  cadastroencontro: Model;

  modaltitulo: string = '';
  tipo: string = "";
  modalconteudo: string = '';
  mostrar: string = "T";
  mostrarmedicacao: string = "T";
  mostraralergicomedicacao: string = "T";
  mostraralergicoalimento: string = "T";
  mostrarredesocial: string = "T";
  mostrarparente: string = "T";
  marked: boolean;
  bt_termo: boolean;
  quantidade_cadastro: number = 0;
  limite_cadastro: number = 0;
  sucesso = new EventEmitter();
  lcivil: any = [
    'CASADO(A)',
    'SOLTEIRO(A)',
    'DIVORCIADO(A)',
    'VIÚVO(A)',
    'AMASIADO(A)'];

  lcivill: any = [
    'PARTICIPANDO',
    'TRABALHANDO'];

  parentesco: any = [
    'PAIS',
    'AVÓS',
    'CÔNJUGE',
    'FILHO(A)',
    'IRMÃO',
    'TIO(A)',
    'PRIMO(A)',
    'AMIGO(A)',
    'VIZINHO(A)',
    'TRABALHO'];


  documentos: any = [
    'CPF',
    'RG'];
  membromorada: any = [
    'SIM',
    'NÃO'];

  batismo: any = [
    'SIM',
    'NÃO'];
  usomedicacao: any = [
    'SIM',
    'NÃO'];
  parenteparticipando: any = [
    'SIM',
    'NÃO'];
  alergicomedicacao: any = [
    'SIM',
    'NÃO'];
  alergicoalimento: any = [
    'SIM',
    'NÃO'];

  ficousabendo: any = [
    'INSTAGRAM',
    'FACEBOOK',
    'WHATSAPP',
    'INDICAÇÃO DE AMIGO(A)',
    'CULTO',
  ];

  redesocial: any = [
    'INSTAGRAM',
    'FACEBOOK',
    'NÃO TENHO'
  ];

  luf: any = [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MT',
    'MS',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO',
  ];
  startedClass = false;
  completedClass = false;
  preventAbuse = false;
  rota: Router;


  constructor(
    private formBuilder: FormBuilder,
    private service: EncontroHomensService,
    private alert: AlertModalService,
    private router: Router
  ) {
    this.rota = this.router;
    this.mostrar = "T";
    EventEmitterService.get('loginAlert').emit(false);
    service.GEtQuantidade().subscribe(
      (success) => {
        this.quantidade_cadastro = success;
      }
    );
    service.GETLimite().subscribe(
      (success) => {
        this.limite_cadastro = success;
        if (this.quantidade_cadastro >= this.limite_cadastro) {
          this.modaltitulo = 'Cadastro Suspenso!';
          this.modalconteudo =
            'Olá!<br>' +
            'Informamos que este cadastro no momento encontra-se desativado!<br>' +
            'Em caso de duvidas por favor procure ao Obreiro <b>Higor</b> ou Diacono <b>Sandro</b> para maiores esclarecimentos<br>' +
            'Gratos pela compreensão!<br>' +
            'Atenciosamente!<br><b>Gestão Morada de Deus ( IEPMD )</b>';
          document.getElementById('openModalButtons').click();
          document.getElementById('cpf').focus();

        }
      }
    )
  }





  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//

  //seta os campos para inicio do form
  //====================================================================//
  criarform() {
    this.formcadastro = this.formBuilder.group({
      nome: ['', Validators.required],
      tipo_documento: ['', Validators.required],
      cpf: ['', [Validators.required, Validators.maxLength(14), Validators.minLength(8)]],
      telefone: ['', Validators.required],
      estado_civil: ['', Validators.required],
      dt_nascimento: ['', Validators.required],
      ficou_sabendo: ['', Validators.required],
      ministerio_morada: ['', Validators.required],
      ministerio_pertence: ['', Validators.required],
      pastor_ministerio: ['', Validators.required],
      rede_social: ['', Validators.required],
      batizado: ['', Validators.required],
      parente_participando: ['', Validators.required],
      uso_medicacao: ['', Validators.required],
      alergico_medicacao: ['', Validators.required],
      alergico_alimento: ['', Validators.required],
      primeiro_contato: ['', Validators.required],
      primeiro_grau_contato: ['', Validators.required],
      primeiro_telefone_contato: ['', Validators.required],
      segundo_contato: ['', Validators.required],
      segundo_grau_contato: ['', Validators.required],
      segundo_telefone_contato: ['', Validators.required],
      classificacao: ['', Validators.required],
      marked: ['', Validators.requiredTrue],
      nome_parente: [''],
      nome_rede_social: [''],
      celular: [''],
      email: [''],
      convidado_por: [''],
      nome_medicacao: [''],
      nome_medicacao_alergico: [''],
      nome_alimento_alergico: [''],
      observacoes: [''],
      cep: [''],
      logradouro: ['', Validators.required],
      numero: [
        '',
        [Validators.required, Validators.min(1), Validators.max(999999999)],
      ],
      apto: [''],
      bairro: ['', Validators.required],
      uf: ['', Validators.required],
      cidade: ['', Validators.required],

    });
  }
  //====================================================================//

  //Caso discord do termo no modal
  //====================================================================//
  DiscordeTermo() {
    if (this.marked == true) {
      this.marked = false;
    }
  }
  //====================================================================//

  //Abrir modal quando clicar no aceite para exibir os termos
  //====================================================================//
  AceiteTermo(e) {
    if (e.target.checked == true) {
      this.marked = e.target.checked;
      this.service.GetTermo('encontro-homens').subscribe(
        (success) => {
          this.StopProgressBar();
          this.modalconteudo = success.termo;
          this.bt_termo = true;
          this.modaltitulo = 'Termo Encontro de Homens';
          document.getElementById('openModalButton').click();
        },
        (error) => {
          this.StopProgressBar();
          if (error.status == '400') {
            this.alert.AlertShowDanger(
              'Termo não definido!,solicitamos que não continue e avise a gestão do site, OBS: Continuando confirma está de acordo com toda politica do site!')
          } else {
            this.alert.AlertShowDanger(
              'Termo não definido!,solicitamos que não continue e avise a gestão do site, OBS: Continuando confirma está de acordo com toda politica do site!'
            );
          }
        }
      );
    }
  }
  //====================================================================//

  //pegar tipo de documento para habilitar campo
  Tipo(event: any) {
    this.tipo = event.target.value.replace("1: ", "").replace("2: ", "").replace(" ", "");
    this.formcadastro.controls['cpf'].setValue("");

    if (this.formcadastro.controls['tipo_documento'].value != "") {
      this.formcadastro.controls['cpf'].enable();
      document.getElementById('cpf').focus();
    } else {
      this.formcadastro.controls['cpf'].disable();
      document.getElementById('tipo_documento').focus();
    }
  }


  //recarregar Form
  //====================================================================//
  reset() {
    this.cadastroencontro = new Model();
    this.submitted = false;
    this.mostrar = "F";
  }

  //====================================================================//
  //PEGAR VALOR UF FORM
  //====================================================================//
  get cityNames() {
    return this.formcadastro.get('uf');
  }
  changeCity(e) {
    console.log(e.value);
    this.cityNames.setValue(e.target.value, {
      onlySelf: true,
    });
  }

  //====================================================================//
  //PEGAR VALOR ESTADO CIVIL FORM
  //====================================================================//
  get cityNamess() {
    return this.formcadastro.get('estado_civil');
  }
  changeCitys(e) {
    console.log(e.value);
    this.cityNamess.setValue(e.target.value, {
      onlySelf: true,
    });
  }

  //====================================================================//
  //PEGAR VALOR CLASSIFICACAO
  //====================================================================//
  get cityNamesss() {
    return this.formcadastro.get('classificacao');
  }
  changeCityss(e) {
    console.log(e.value);
    this.cityNamesss.setValue(e.target.value, {
      onlySelf: true,
    });
  }



  //controle membro morada
  get citymembromorada() {
    return this.formcadastro.get('ministerio_morada');
  }
  changeministeriomorada(e) {
    console.log(e.name);
    this.citymembromorada.setValue(e.target.value, {
      onlySelf: true,
    });

    if (e.target.value.includes("SIM")) {
      this.mostrar = "F";
      this.cadastroencontro.ministerio_pertence = "IEPMD - Igreja Evangelica Pentecostal Morada de Deus."
      this.cadastroencontro.pastor_ministerio = "Pastor Rodrigo Pena"
    } else {
      this.mostrar = "T";
      this.cadastroencontro.ministerio_pertence = "";
      this.cadastroencontro.pastor_ministerio = ""
    }
  }

  //controle rede social
  get citredesocial() {
    return this.formcadastro.get('ministerio_morada');
  }
  changeredesocial(e) {
    console.log(e.name);
    this.citredesocial.setValue(e.target.value, {
      onlySelf: true,
    });

    if (e.target.value.includes("NÃO TENHO")) {
      this.mostrarredesocial = "F";
      this.cadastroencontro.nome_rede_social = ""

    } else {
      this.mostrarredesocial = "T";
      this.cadastroencontro.nome_rede_social = "";

    }
  }


  //controle participação parentes
  get citparente() {
    return this.formcadastro.get('parente_participando');
  }
  changeparente(e) {
    console.log(e.name);
    this.citparente.setValue(e.target.value, {
      onlySelf: true,
    });

    if (e.target.value.includes("SIM")) {
      this.mostrarparente = "T";
      this.cadastroencontro.nome_parente = ""

    } else {
      this.mostrarparente = "F";
      this.cadastroencontro.nome_parente = "";

    }
  }


  //controle de medicação alergica
  get cityalergicomedicacao() {
    return this.formcadastro.get('alergico_medicacao');
  }
  changealergicomedicacao(e) {
    console.log(e.name);
    this.cityalergicomedicacao.setValue(e.target.value, {
      onlySelf: true,
    });

    if (e.target.value.includes("SIM")) {
      this.mostraralergicomedicacao = "T";
      this.cadastroencontro.nome_alimento_alergico = ""

    } else {
      this.mostraralergicomedicacao = "F";
      this.cadastroencontro.nome_medicacao_alergico = "";

    }
  }


  //controle uso medicação
  get citymedicacao() {
    return this.formcadastro.get('uso_medicacao');
  }
  changemedicacao(e) {
    console.log(e.name);
    this.citymedicacao.setValue(e.target.value, {
      onlySelf: true,
    });

    if (e.target.value.includes("SIM")) {
      this.mostrarmedicacao = "T";
      this.cadastroencontro.nome_medicacao = ""
    } else {
      this.mostrarmedicacao = "F";
      this.cadastroencontro.nome_medicacao = "";

    }
  }

  //controle uso medicação
  get cityalimento() {
    return this.formcadastro.get('alergico_alimento');
  }
  changealimento(e) {
    console.log(e.name);
    this.cityalimento.setValue(e.target.value, {
      onlySelf: true,
    });

    if (e.target.value.includes("SIM")) {
      this.mostraralergicoalimento = "T";
      this.cadastroencontro.nome_alimento_alergico = ""
    } else {
      this.mostraralergicoalimento = "F";
      this.cadastroencontro.nome_alimento_alergico = "";

    }
  }
  //Inicio
  //====================================================================//
  ngOnInit() {
    this.cadastroencontro = new Model();
    this.criarform();
    document.getElementById('tipo_documento').focus();



  }
  //====================================================================//

  //Consulta o cadastro do condominio já existe na base de dados ( API )
  //====================================================================//
  consultacpf(cpf: any) {
    this.service.Consulta(this.cadastroencontro.cpf).subscribe(
      (success) => {
        this.StopProgressBar();
        document.getElementById('nome').focus();
      },
      (error) => {
        this.StopProgressBar();
        if (error.status == '400') {
          this.cadastroencontro = new Model();
          this.bt_termo = false;
          this.modaltitulo = 'Cadastro Existente!';
          this.modalconteudo =
            'Olá! <b>' + this.formcadastro.get('cpf').value + ' ' + error.error
            + '</b>,<br>Estava olhando aqui em minhas anotações e verifiquei que já existe seu cadastro em nossa plataforma!, Pedimos por favor que aguarde o contato de uns dos organizadores!\nDeus Abençõe!';
          document.getElementById('openModalButton').click();
        } else {
          document.getElementById('nome').focus();
        }
      }
    );
  }
  //====================================================================//

  //CONSULTA CEP API
  //====================================================================//
  consulta_cep(cep: any) {
    if (cep != '' && cep != null) {
      this.service
        .ConsultaCEP(
          cep
            .replace('.', '')
            .replace('-', '')
            .replace('\\', '')
            .replace('/', '')
        )
        .subscribe(
          (success) => {
            if (success.cep != null) {
              (this.cadastroencontro.cep = success.cep),
                (this.cadastroencontro.logradouro = success.logradouro),
                (this.cadastroencontro.bairro = success.bairro),
                (this.cadastroencontro.cidade = success.localidade),
                (this.cadastroencontro.uf = success.uf);
              document.getElementById('numero').focus();
            } else {
              this.alert.AlertShowDanger('CEP não e válido!');
              this.cadastroencontro.cep = '';
            }
          },
          (error) => {
            this.StopProgressBar();
            this.alert.AlertShowDanger(
              '( ' + error.status + ' ) ' + error.message
            );
          }
        );
    }
  }
  //====================================================================//



  // Pegar nome dos campos,
  //====================================================================//
  get f() {
    return this.formcadastro.controls;
  }
  //====================================================================//

  //BOTAO SALVAR
  //====================================================================//
  onSubmit() {
    this.submitted = true;
    if (this.formcadastro.invalid) {
      this.alert.AlertShowDanger('Favor verificar os campos em destaque!');
      return;
    }

    if ((this.cadastroencontro.rede_social.includes('INSTAGRAM') || this.cadastroencontro.rede_social.includes('FACEBOOK')) && this.cadastroencontro.nome_rede_social == '') {
      this.alert.AlertShowDanger('Favor Preencher seu nome na rede social!');
      return;
    }


    if (this.cadastroencontro.uso_medicacao.includes('SIM') && this.cadastroencontro.nome_medicacao == '') {
      this.alert.AlertShowDanger('Favor Preencher a medicação utilizada!');
      return;
    }

    if (this.cadastroencontro.alergico_alimento.includes('SIM') && this.cadastroencontro.nome_alimento_alergico == '') {
      this.alert.AlertShowDanger('Favor Preencher o alimento alergico!');
      return;
    }

    if (this.cadastroencontro.parente_participando.includes('SIM') && this.cadastroencontro.nome_parente == '') {
      this.alert.AlertShowDanger('Favor Preencher o nome do Parente!');
      return;
    }

    if (this.cadastroencontro.alergico_medicacao.includes('SIM') && this.cadastroencontro.nome_medicacao_alergico == '') {
      this.alert.AlertShowDanger('Favor Preencher a medicação que e alergico!');
      return;
    }
      if (this.cadastroencontro.apto== null || this.cadastroencontro.apto<0) {
        this.alert.AlertShowDanger('Favor Preencher o APTO corretamente ou com 0!');
        return;
      }

    if (this.cadastroencontro.telefone == this.cadastroencontro.primeiro_telefone_contato) {
      this.alert.AlertShowDanger('Telefone de contato não pode ser igual ao seu telefone');
      return;
    }
    if (this.cadastroencontro.telefone == this.cadastroencontro.segundo_telefone_contato) {
      this.alert.AlertShowDanger('Telefone de contato não pode ser igual ao seu telefone');
      return;
    }

    this.service
      .CadastroUsuario(this.formcadastro.value)
      .subscribe(
        (sucess) => {
          this.StopProgressBar();
          this.alert.AlertShowSucess('Cadastro salvo');
          localStorage.setItem('tipo', 'encontro-homens');
          localStorage.setItem('cadastro-homens-save', JSON.stringify(this.formcadastro.value));
          this.rota.navigate(['/retorno']);
        },
        (error) => {
          this.StopProgressBar();
          this.alert.AlertShowDanger('( ' + error.status + ' ) ' + error.error);
        }
      );
    //====================================================================//
  }
  //====================================================================//

}
