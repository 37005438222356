import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { Parametros } from 'src/app/shared/parametros.enum';
import { SharedModule } from 'src/app/shared/shared.module';
import { ElogioModel } from '../../registrar-elogio/ElogioModel';

@Component({
  selector: 'app-elogio-detalhe',
  templateUrl: './elogio-detalhe.component.html',
  styleUrls: ['./elogio-detalhe.component.css']
})
export class ElogioDetalheComponent implements OnInit {
  idelogio: any;
  rota: any;
  private urlcadastro = Parametros.RESUMO;
  private urlrelatoriotodos = Parametros.RELATORIOSTODOSELOGIO;
  nome: string = "";
  data_culto: any = "";
  data_envio: any = "";
  mensagem: string = "";
  tipoelogio: string = "";
  visualizada: string = "";
  imprimir: any;


  constructor(private http: HttpClient,
    private alert: AlertModalService,
    private autenticado: SharedModule,
    private r: Router) {
    this.rota = r
  }

  startedClass = false;
  completedClass = false;
  preventAbuse = false;

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//

  ngOnInit(): void {
    if (this.autenticado.token == null) {
      this.autenticado.ResetDados();
      this.alert.AlertShowDanger('Usuário não autenticado, redirecionando...')
      this.rota.navigate(['/login']);
    } else {
      if (this.autenticado.ValidarToken() != true) {
        this.autenticado.ResetDados();
        this.alert.AlertShowDanger('Token Invalido!')
        this.rota.navigate(['/login']);
      }
    }
    this.idelogio = localStorage.getItem('ElogioDetalhe');
    if (this.idelogio == null || this.idelogio == "") {
      this.rota.navigate(['/dashboard/listagem/lista-elogio']);
    } else {
      this.GetElogio()
    }
  }

  GetElogio() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get<ElogioModel>(this.urlcadastro + 'GetLoadElogiodetalhe/?cpf=' + this.autenticado.cpf + '&id=' + this.idelogio, httpOptions).subscribe(
      (success) => {
        this.nome = success[0].nome;
        this.data_culto = success[0].dt_culto.replace("T00:00:00","");
        this.data_envio = success[0].dt_envio.replace("T00:00:00","");
        this.mensagem = success[0].mensagem;
        this.tipoelogio = success[0].elogio;
        if (success[0].visualizada == "T") {
          this.visualizada = "SIM";
        } else {
          this.visualizada = "NÃO";
        }
      },
      (error) => {
        this.alert.AlertShowDanger('Erro ao detalhar Elogio');
        this.rota.navigate(['/dashboard/listagem/lista-elogio']);
      }
    )
  }

  ImprimirElogio() {
    this.imprimir = this.idelogio;
    this.GerarPDF();
  }

  GerarPDF() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token
      })
    }
    this.http.get(this.urlrelatoriotodos + 'GetRelatorioTodosElogioDetalhado/?parametro=' + this.imprimir, httpOptions).subscribe(
      (success) => {
        const linkSource = 'data:application/pdf;base64,' + success;
        const downloadLink = document.createElement("a");
        const fileName = "IEPMD_Elogios_" + (this.autenticado.nome).replace(" ", "") + ".pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }, (error) => {
        if (error.status == '403') {
          this.alert.AlertShowDanger('Erro ao tentar Gerar PDF');
        } else {
          const linkSource = 'data:application/pdf;base64,' + error.error.text;
          const downloadLink = document.createElement("a");
          const fileName = "IEPMD_Elogios_" + (this.autenticado.nome).replace(" ", "") + ".pdf";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      }
    );
  }
}
