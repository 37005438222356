import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer-autenticado',
  templateUrl: './footer-autenticado.component.html',
  styleUrls: ['./footer-autenticado.component.css']
})
export class FooterAutenticadoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
