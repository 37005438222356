<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>
<div>
    <h4 class="espacamentotopo">
        <img src="../../assets/img/cadastro.jpeg" style="width: 40px" /> Registrar Reclamação
    </h4>

    <label class="espacamentotopo col-md" style="margin-left: 5px;">
        &nbsp;&nbsp;&nbsp;&nbsp;Você está na área para registro de reclamações, desde já gostaríamos de informar que
        lamentamos qualquer
        incoveniente e estaremos analisando com todo carinho a reclamação enviada para tomada de providencias
        <br>
        &nbsp;&nbsp;&nbsp;&nbsp;Ressaltamos que todos os dados aqui informados são confidenciais e são enviados somente
        para Gestão da igreja
        não ficando acessivel qualquer outra pessoa.<br>
    </label>
    <label class="espacamentotopo col-md" style="margin-left: 5px;">
        &nbsp;&nbsp;&nbsp;&nbsp; Lembramos que os campos com (<span class="cor"> *</span> ) são campos de
        preenchimento obrigatório!
    </label>

    <hr class="espacamentotopo bold" />
    <form [formGroup]="formreclamacao" class="m" disabled="">
        <div class="row">
            <!---INCIO PRIMEIRA LINHA-->
            <div class="col-md-2">
                <div class="form-group">
                    <label>CPF:</label><strong class="cor">*</strong>
                    <input type="text" disabled="true" class="form-control" id="cpf" placeholder="informe o CPF"
                        mask="CPF_CNPJ" name="cpf" maxlength="14" autocomplete="off" formControlName="cpf"
                        [(ngModel)]="modelreclamacao.cpf" [ngClass]="{ 'is-invalid': submitted && f.cpf.errors }" />
                    <div *ngIf="submitted && f.cpf.errors" class="invalid-feedback">
                        <div *ngIf="f.cpf.errors.required">Preenchimento obrigatório</div>
                    </div>
                </div>
            </div>

            <div class="col-md-7">
                <div class="form-group">
                    <label>Nome Completo:</label><strong class="cor">*</strong>
                    <input type="text" disabled="true" class="form-control" id="nome" name="nome"
                        [(ngModel)]="modelreclamacao.nome" placeholder="Informe Nome Completo" formControlName="nome"
                        maxlength="500" minlength="3" autocomplete="off"
                        [ngClass]="{ 'is-invalid': submitted && f.nome.errors }" />
                    <div *ngIf="submitted && f.nome.errors" class="invalid-feedback">
                        <div *ngIf="f.nome.errors.required">Preenchimento obrigatório</div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <label>Telefone/Celular:</label><strong class="cor">*</strong>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1"><i class="fas fa-phone"></i></span>
                    </div>
                    <input type="text" class="form-control" id="telefone" name="telefone"
                        [(ngModel)]="modelreclamacao.telefone" placeholder="Informe o Telefone"
                        title="Formato (DD) 0000-0000 ou (DD) 00000-0000" formControlName="telefone" maxlength="500"
                        minlength="3" autocomplete="off" mask="(00) 0000-0000 || (00) 00000-0000" [ngClass]="{
                      'is-invalid': submitted && f.telefone.errors
                    }" />
                    <div *ngIf="submitted && f.telefone.errors" class="invalid-feedback">
                        <div *ngIf="f.telefone.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!----->
        <div class="row">
            <div class="col-md-9">
                <div class="dropdown">
                    <label>Motivo da Reclamação:</label><strong class="cor">*</strong>
                    <div class="mb-12">
                        <select class="custom-select" type="text" (change)="changereclamacao($event)"
                            name="tipo_reclamacao" id="tipo_reclamacao" [(ngModel)]="modelreclamacao.tipo_reclamacao"
                            formControlName="tipo_reclamacao"
                            [ngClass]="{ 'is-invalid': submitted && f.tipo_reclamacao.errors }">
                            <div *ngIf="submitted && f.tipo_reclamacao.errors" class="invalid-feedback"></div>
                            <option value=""></option>
                            <option *ngFor="let d of cbtiporeclamacao" [ngValue]="d">
                                {{ d }}
                            </option>
                        </select>
                        <div *ngIf="submitted && f.tipo_reclamacao.errors" class="invalid-feedback">
                            <div *ngIf="f.tipo_reclamacao.errors.required">
                                Preenchimento obrigatório
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <div class="form-group">
                    <label>Data Ocorrido:</label><strong class="cor">*</strong>
                    <input type="date" class="form-control" id="dt_ocorrido" placeholder="Data Ocorrido"
                        name="dt_ocorrido" maxlength="10" minlength="10" autocomplete="off"
                        formControlName="dt_ocorrido" [(ngModel)]="modelreclamacao.dt_ocorrido"
                        [ngClass]="{ 'is-invalid': submitted && f.dt_ocorrido.errors }" />
                    <div *ngIf="submitted && f.dt_ocorrido.errors" class="invalid-feedback">
                        <div *ngIf="f.dt_ocorrido.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>
            </div>

        </div>
        <!----->

        <div class="row">
            <div class="col-md">
                <div class="form-group">
                    <label>Mensagem:</label><strong class="cor">*</strong>
                    <textarea type="text" rows="10" class="form-control" id="mensagem" aria-describedby="emailHelp"
                        name="mensagem" formControlName="mensagem" [(ngModel)]="modelreclamacao.mensagem"
                        autocomplete="off" maxlength="9000" minlength="20"
                        [ngClass]="{ 'is-invalid': submitted && f.mensagem.errors }"></textarea>
                    <span>{{ modelreclamacao.mensagem.length }}/9000</span>

                    <div *ngIf="submitted && f.mensagem.errors" class="invalid-feedback">
                        <div *ngIf="f.mensagem.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <!----->
        <div class="row">
            <div class="col-md">
                <div class="form-group" style=" display: block; border: 0px;">
                    <input type="checkbox" id="receber_copia" data-md-icheck formControlName="receber_copia"
                        [(ngModel)]="receber_copia" (change)="ReceberCopia($event)" />
                    Receber Cópia.
                    <span style=" display: block; border: 0px; font-size: x-small;">Para
                        receber uma cópia e necessário
                        ter e-mail valido em seu Perfil </span>
                </div>
            </div>

        </div>
        <!----->
        <hr>
        <br>
        <!----->
        <div style="padding-bottom: 20px;">
            <button pButton pRipple type="button" icon="pi pi-save" class="p-button-rounded" style="margin-right: 5px;"
                title=":: Registrar ::" (click)="onSubmit()"></button>
            <button pButton pRipple type="button" icon="pi pi-refresh" style="margin-right: 5px;"
                class="p-button-rounded p-button-secondary" title="::LIMPAR::" (click)="reset()"></button>
            <button pButton pRipple type="button" icon="pi pi-sign-in" class="p-button-rounded p-button-danger"
                title="::Fechar::" routerLink="/dashboard/resumo"></button>
        </div>
    </form>
</div>


