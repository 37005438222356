<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>
<div class="row">

    <div class="col-md-12" style="margin-top: 7px;">

        <div>
            <h4 style="text-align: center;">
                <i class="far fa-grin-stars"></i>&nbsp;<b>{{filtro_cabecalho}}</b>
            </h4>
            <p>
                &nbsp;&nbsp;&nbsp;&nbsp;{{explicacao}}
                <br>
                &nbsp;&nbsp;&nbsp;&nbsp;<b>#FicaaDica: </b>Em caso de Celular melhor visualizado na horizontal!
            </p>
        </div>
    </div>
    <br>

    <!--Filtro-->
    <div class="col-md-12" style="display: block; border: 0px;padding-bottom: 0%; ">
        <label style=" display: block; border: 0px; padding-bottom: 0%;"><b
                style="padding: 0%; padding-bottom: 0%;">Filtro:</b>
            <span style=" display: block; border: 0px; font-size: x-small; padding-top: 0%;">Consulta por nome</span>
            <input type="text" class="form-control" (keydown)="Consulta()" id="texto_consulta"
                style="margin-top: 0%;" />
        </label>
    </div>

    <!--Tabela-->
    <div class="panel-body col-md-12">
        <p-table [value]="elogio"[columns]="cols" [paginator]='false' [rows]="10" [showCurrentPageReport]="true"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" selectionMode="single"
            paginatorTemplate="{RowsPerPageDropdown} {FirstPageLink} {PreviousPageLink} {CurrentPageReport} {NextPageLink} {LastPageLink}"
            (onLazyLoad)='Paginate ($event)' styleClass="p-datatable-responsive-demo" autoLayout="true"
            [responsive]="true">

            <ng-template pTemplate="header" let-elogio let-columns>
                <tr style="background-color: dodgerblue; color: aliceblue;">
                    <th *ngFor="let col of columns">
                        {{col.header}}
                    </th>            
                  
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-c>
                <tr>
                    <td>
                        <span class="p-column-title">Data Culto</span>
                        {{c.dt_culto | date: 'dd/MM/yyyy'}}</td>
                    <td>
                        <span class="p-column-title">Data Registro</span>
                        {{c.dt_envio | date: 'dd/MM/yyyy'}}</td>
                    <td>
                        <span class="p-column-title">Elogio</span>
                        {{c.elogio}}</td>
                    <td>
                        <span class="p-column-title">Visualizado</span>
                        {{c.lida.replace("T","SIM").replace("F","NÃO")}}</td>

                    <td *ngIf="c.visualizada === 'F'">
                        <span class="p-column-title">Excluir</span>
                        <button pButton pRipple type="button" icon="pi pi-trash" class="p-button-rounded"
                            style="background-color: red;" styleClass="rounded-button ui-button-danger"
                            value={{c.idelogio}} {{idvisita}}={{c.idelogio}} (click)="SetDados($event)"
                            data-toggle="modal" data-target="#exampleModal1" title="Exluir Elogio"></button>
                    </td>
                    <td *ngIf="c.visualizada === 'T'">
                        <span class="p-column-title">Excluir</span>
                        <button pButton pRipple type="button" icon="pi pi-trash" class="p-button-rounded"
                            style="background-color: gray;" disabled styleClass="rounded-button ui-button-gray"
                            value={{c.idelogio}} title="Elogio Lido ( não permitido exclusão )"></button>
                    </td>
                    <td> <span class="p-column-title">Detalhes</span>
                        
                        <button pButton pRipple type="button" icon="pi pi-search-plus" class="p-button-rounded"
                        style="background-color: green;" styleClass="rounded-button ui-button-success"
                            value={{c.idelogio}} (click)="VisualizarDetalhe($event)"
                            title="Visualizar Detalhe"></button>
                    </td>
                </tr>
            </ng-template>
        </p-table>

        <!--Paginação-->
        <p-paginator [rows]="10" [paginator]='true' [totalRecords]=qto
            currentPageReportTemplate="Mostrando: {{x}} a {{y}} de {{qto}}" (onPageChange)="Paginate($event)"
            [showCurrentPageReport]="true">
        </p-paginator>


        <hr>

        <!--Botao Novo-->
        <button pButton pRipple type="button" icon="pi pi-plus-circle" title="Novo"
            class="p-button-rounded p-button-success" routerLink="/dashboard/elogio"
            style="margin-right: 10px;"></button>
        <!--Botao Retorno-->
        <button pButton pRipple type="button" icon="pi pi-sign-in" title="Retornar"
            class="p-button-rounded p-button-danger" routerLink="/dashboard/resumo"></button>
        <br>
        <br>
        <br>
    </div>

    <!-- Modal -->
    <div id="exampleModal" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Gerar Documento para Impressão</h4>
                </div>
                <div class="modal-body">
                    <p>Por favor escolha a opção correta para geração do PDF para impressão!</p>
                    <br>
                    <div style="font-size: medium;">
                        <input id="novo" name="base" type="radio" value="Filtro:Todos" checked
                            (click)="ValorImpressao($event)" /> Todos Registros ( {{qto}} )
                    </div>
                    <div style="font-size: medium;">
                        <input id="novo" name="base" type="radio" value="Filtro:TodosAbertos"
                            (click)="ValorImpressao($event)" /> Todos Registros não Lidos
                    </div>
                    <div style="font-size: medium;">
                        <input id="novo" name="base" type="radio" value="Filtro:TodosFechados"
                            (click)="ValorImpressao($event)" /> Todos Registros Lidos
                    </div>
                    <div *ngIf="textoconsulta !='' ">
                        <div style="font-size: medium;">
                            <input id="ant" name="base" type="radio" value="Filtro:{{textoconsulta}}"
                                (click)="ValorImpressao($event)" /> Filtro Realizado: ( {{textoconsulta}})
                        </div>
                    </div>
                    <div *ngIf="textoconsulta !='' ">
                        <div style="font-size: medium;">
                            <input id="ant" name="base" type="radio"
                                value="FiltroPagina:{{textoconsulta}} and limit 10 offset {{pagina_atual}}"
                                (click)="ValorImpressao($event)" /> Filtro Realizado e Pagina: ( {{textoconsulta}} e
                            Pagina ( {{pagina_atual}} ))
                        </div>
                    </div>
                    <div style="font-size: medium;">
                        <input id="grade" name="base" type="radio" value="Pagina:{{pagina_atual}}"
                            (click)="ValorImpressao($event)" /> Pagina Atual ( {{pagina_atual}} )
                    </div>

                </div>

            </div>

        </div>
    </div>

    <!-- Modal -->
    <div id="exampleModal1" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog">

            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Confirma Exclusão?</h4>
                </div>
                <div class="modal-body">
                    <b>Olá {{nome}}</b>
                    <p>Ao excluir o Elogio um e-mail será enviado para gestão da igreja informando a
                        desistencia.</p>
                    <p>Um contato ainda pode ser realizado, mas não se preocupe que respeitamos sua decisão!.</p>

                </div>
                <div class="modal-footer">
                    <button pButton pRipple type="button" icon="pi pi-trash" class="p-button-rounded" title="Excluir" (click)="BotaoExcluir()"
                        data-dismiss="modal"></button>
                        <button pButton pRipple type="button" icon="pi pi-sign-in" class="p-button-rounded p-button-danger" title="Retornar" data-dismiss="modal"></button>
                </div>
            </div>

        </div>
    </div>
</div>
<button class="voltar-ao-topo" id="back"><a class="ancora" style="color: white;"><i
    class="pi pi-arrow-circle-up"></i></a></button>