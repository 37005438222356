import { AlertModalService } from './../shared/alert-modal/alert-modal.service';
import { take, retry } from 'rxjs/operators';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { SharedModule } from './../shared/shared.module';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Parametros } from '../shared/parametros.enum';
import { CookieService } from 'ngx-cookie-service';


@Injectable({
  providedIn: 'root',
})
export class ContatoService {
  private urlcontato = Parametros.CONTATO;
  private rota: Router;


  constructor(private http: HttpClient,
    router: Router,
    private alert: AlertModalService,
    private Is: SharedModule) {
    this.rota = router;
  }
  RegistrarContato(_contato) {
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http
      .post(this.urlcontato + 'postcontato', _contato, httpOptions)
      .pipe(retry(1));
  }

}
