import { DecimalPipe } from "@angular/common";

export class patrimoniomodel {

    idpatrimonio: any = '';
    dt_cadastro: string = '';
    patrimonio: string = '';
    numero_patrimonio: string = '';
    tipo_bem: string = '';
    valor_estimado: any = '';
    setor: string = '';
    numero_serie: string = '';
    codigo_extra: string = '';
    modelo: string = '';
    marca: string = '';
    cor: string = '';
    detalhes: string = '';

    qrcode: string = '';
    status: string = '';
    ativo: string = '';
    motivo: string = '';
    registrado_por: string = '';
    data_registro: any = '';
    hora_registro: any = '';
    referencia: string = '';

    //usado para resumo
    valor_total: any = '';
    quantidade: any = '';


}