<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>


<div class="col-md-12">
  <div class="row" style="margin-right: 2px; padding-left: 20px;">
    <img src="../../../assets/img/configuracoes.png" height="130px" width="100px" style="padding: 0%;" />

    <div class="col-md-10" style="padding-top: 15px;">
      <h4 class="espacamentotopo " style="margin-left: 5px;">
        <b>{{filtro_cabecalho}}</b>
      </h4>
      <label>
        {{explicacao}}
        <br>
        <b>#FicaaDica: </b>Em caso de Celular melhor visualizado na horizontal!
      </label>
    </div>
  </div>
</div>

<!--Filtro-->
<div class="col-md-12" style="display: block; border: 0px;padding-bottom: 0%; ">
  <label style=" display: block; border: 0px; padding-bottom: 0%;"><b
    style="padding: 0%; padding-bottom: 0%;">Filtro:</b>
    <span style=" display: block; border: 0px; font-size: x-small; padding-top: 0%;">Consulta por nome</span>
    <input type="text" class="form-control" (keydown)="Consulta()" id="texto_consulta" style="margin-top: 0%;" />
  </label>
</div>

<!--Tabela-->
<div class="panel-body col-md-12">
  <p-table [value]="cadastros" [paginator]='false' [columns]="cols" [rows]="10" [showCurrentPageReport]="true"
           currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" selectionMode="single"
           paginatorTemplate="{RowsPerPageDropdown} {FirstPageLink} {PreviousPageLink} {CurrentPageReport} {NextPageLink} {LastPageLink}"
           (onLazyLoad)='Paginate ($event)' styleClass="p-datatable-responsive-demo" autoLayout="true" [responsive]="true">

    <ng-template pTemplate="header" let-cadastros let-columns>
      <tr style="background-color: dodgerblue; color: aliceblue;">
        <th *ngFor="let col of columns">
          {{col.header}}
        </th>
        <th
          *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao')">
          Opções</th>
        <th
          *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao')">
          Detalhes</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-c>
      <tr>
        <td>
          <span class="p-column-title">Nome</span>
          ESPOSA: {{c.nome_esposa}}<br>
          ESPOSO: {{c.nome_esposo}}
        </td>
        <td>
          <span class="p-column-title">Telefone</span>
          ESPOSA: {{c.telefone_esposa}}<br>
          ESPOSO: {{c.telefone_esposo}}
        </td>
        <td>
          <span class="p-column-title">Uso Medicação</span>
          ESPOSA: {{c.esposa_medicacao}} <br>
          ESPOSO: {{c.esposo_medicacao}}
        </td>


        <td >
          <span class="p-column-title">Alergico Alimentos</span>
          ESPOSA: {{c.esposa_restricao_alimentar}} <br>
          ESPOSO: {{c.esposo_restricao_alimentar}}
        </td>

        <td >
          <span class="p-column-title">Restrição Açucar</span>

          ESPOSA: {{c.esposa_restricao_acucar}} <br>
          ESPOSO: {{c.esposo_restricao_acucar}}
        </td>


        <td *ngIf="c.confirmado == 'T'">
          <span class="p-column-title">Confirmado</span>
          SIM
        </td>
        <td *ngIf="c.confirmado == 'F'">
          <span class="p-column-title">Confirmado</span>
          NÃO
        </td>
        <td>
          <span class="p-column-title">Anotações</span>
          {{c.informacao_cadastro}}
        </td>
        <td
          *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao')||_perfil.includes('Evento_Mulheres')">
          <span class="p-column-title">Opções</span>
          <button pButton pRipple type="button" icon="pi pi-cog" class="p-button-rounded p-button-warning"
                  value={{c.idinscricao}} (click)="SetDados($event)" data-toggle="modal"
                  data-target="#exampleModal" title="Opções"></button>
        </td>

        <td
          *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao')||_perfil.includes('Evento_Mulheres')">
          <span class="p-column-title">Detalhes</span>
          <button pButton pRipple type="button" icon="pi pi-search-plus" class="p-button-rounded"
                  style="background-color: green;" styleClass="rounded-button ui-button-success"
                  value={{c.idinscricao}} (click)="VisualizarDetalhe($event)" title="Visualizar Detalhe"></button>
        </td>
      </tr>
    </ng-template>



  </p-table>
  <!--Paginação-->
  <p-paginator [rows]="10" [paginator]='true' [totalRecords]=qto
               currentPageReportTemplate="Mostrando: {{x}} a {{y}} de {{qto}}" (onPageChange)="Paginate($event)"
               [showCurrentPageReport]="true">
  </p-paginator>

  <hr>
  <!--Botao Retorno-->
  <button pButton pRipple type="button" icon="pi pi-sign-in" title="Retornar" class="p-button-rounded p-button-danger"
          routerLink="/dashboard/resumo"></button>
  <br>
  <br>
  <br>
</div>
<!-- Modal -->
<div id="exampleModal" class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog">

    <!-- Modal content-->
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Escolha uma operação!</h4>
      </div>
      <div class="modal-body">
        <b>Olá {{nome}}</b>
        <p>Por favor escolha uma das opções abaixo e lembre-se que ao escolhar a mesma, o procedimento será
          realizado automaticamente.</p>
        <div style="font-size: medium;">
          <input id="novo" name="base" type="radio" value="Confirmar" checked (click)="SetOpcao($event)" />
          Confirmar Pagamento
        </div>
        <div style="font-size: medium;">
          <input id="novo" name="base" type="radio" value="Desconfirmar" checked (click)="SetOpcao($event)" />
          Retirar Pagamento
        </div>
        <div style="font-size: medium;">
          <input id="novo" name="base" type="radio" value="Ativar" checked (click)="SetOpcao($event)" />
          Ativar Cadastro
        </div>
        <div style="font-size: medium;">
          <input id="novo" name="base" type="radio" value="Inativar" (click)="SetOpcao($event)" />
          Inativar Cadastro
        </div>
        <div style="font-size: medium;">
          <input id="novo" name="base" type="radio" value="Cancelar" (click)="SetOpcao($event)" />
          Cancelar Cadastro
        </div>

      </div>
      <div class="modal-footer">
        <button pButton pRipple type="button" icon="pi pi-check-circle"
                class="p-button-rounded p-button-warning" title="Executar" (click)="Operacao()"
                data-dismiss="modal"></button>
        <button pButton pRipple type="button" icon="pi pi-sign-in" class="p-button-rounded p-button-danger"
                title="Retornar" data-dismiss="modal"></button>
      </div>
    </div>

  </div>
</div>
<button class="voltar-ao-topo" id="back"><a class="ancora" style="color: white;"><i
  class="pi pi-arrow-circle-up"></i></a></button>
