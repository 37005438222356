<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>
<div class="container">
    <div class="row">
        <div class="col-md-1" style="margin-right: 6px;">
            <img src="../../../../assets/img/detalhe.png" style="width: 100px;" />
        </div>
        <div class="col-md">
            <h4 class="espacamentotopo " style="margin-left: 75px;">
                Detalhe Testemunho
            </h4>
            <label style="margin-left: 75px;">
                Aqui você encontra o detalhamento do Testemunho enviado!.
            </label>
            <br>
            <b style="margin-left: 70px;">Obs:</b> ( Em caso de Celular melhor visualização em horizontal)
        </div>
    </div>
    <hr>
    <br>
    <label><b>Nome: </b>{{nome}}</label><br>
    <label><b>Data Envio: </b>{{data_envio}}</label><br>
    <label><b>Tipo Testemunho: </b>{{tipotestemunho}}</label><br>
    <label><b>Manter Sigilo: </b>{{mantersigilo}}</label><br>
    <label><b>Ocultar Nome: </b>{{ocultarnome}}</label><br>
    <label><b>Visualizada pela Gestão da Igreja: </b>{{visualizada}}</label>
    <br><br>

    <label><b>Mensagem: </b></label><br>
    <div [innerHTML]="mensagem" textWrap="true"></div>


    <br>
    <hr>
    <button pButton pRipple type="button" icon="pi pi-print" class="p-button-rounded"
        styleClass="rounded-button ui-button-info" value={{idtestemunho}} (click)="ImprimirTestemunho($event)"
        title="Imprimir Sugestão" style="margin-right: 10px; margin-left: 10px;"></button>
    <button pButton pRipple type="button" icon="pi pi-sign-in" title="Retornar" class="p-button-rounded p-button-danger"
        routerLink="/dashboard/listagem/lista-testemunho"></button>
    <br>
    <br>
    <br>
    <br>
    <br>
</div>
<button class="voltar-ao-topo" id="back"><a class="ancora" style="color: white;"><i
    class="pi pi-arrow-circle-up"></i></a></button>