<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>
<div class="row">

    <div class="col-md-12">
        <div class="row" style="margin-right: 2px; padding-left: 20px;">
            <img src="../../../assets/img/configuracoes.png" height="130px" width="100px" style="padding: 0%;" />

            <div class="col-md-10" style="padding-top: 15px;">
                <h4 class="espacamentotopo " style="margin-left: 5px;">
                    <b>{{filtro_cabecalho}}</b>
                </h4>
                <label>
                    {{explicacao}}
                    <br>
                    <b>#FicaaDica: </b>Em caso de Celular melhor visualizado na horizontal!
                </label>
            </div>
        </div>
    </div>
    <!--Filtro-->
    <div class="col-md-12">
        <div class="row" style="margin-bottom: 3px; padding-right: 5px;">

            <div class="col-md-6" >
                <label style=" display: block; border: 0px; padding-bottom: 0%;"><b
                        style="padding: 0%; padding-bottom: 0%;">Filtro:</b>
                    <span style=" display: block; border: 0px; font-size: x-small; padding-top: 0%;">Consulta por
                        nome</span>
                    <select class="custom-select" type="text" name="tipo_seminario" id="tipo_seminario"
                        (change)="SetConsultaSeminario($event)" [(ngModel)]="tipo_seminario">
                        <option *ngFor="let seminario of seminarios" [ngValue]="seminario">
                            {{ seminario }}
                        </option>

                    </select>
                </label>
            </div>

            <!--Filtro-->
            <div class="col-md" style="display: block; border: 0px;padding-bottom: 0%; padding-left: 5px; ">
                <label style=" display: block; border: 0px; padding-bottom: 0%;"><b
                        style=" padding-bottom: 0%;">Filtro:</b>
                    <span style=" display: block; border: 0px; font-size: x-small; padding-top: 0%;">Consulta por
                        nome</span>
                    <input type="text" class="form-control" (keydown)="Consulta()" id="texto_consulta"
                        style="margin-top: 0%;" />
                </label>
            </div>
        </div>
    </div>

    <!--Tabela-->
    <div class="panel-body col-md-12">
        <p-table [value]="usuario" [paginator]='false' [rows]="10" [showCurrentPageReport]="true"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" selectionMode="single"
            paginatorTemplate="{RowsPerPageDropdown} {FirstPageLink} {PreviousPageLink} {CurrentPageReport} {NextPageLink} {LastPageLink}"
            (onLazyLoad)='Paginate ($event)' styleClass="p-datatable-responsive-demo" autoLayout="true"
            [responsive]="true">

            <ng-template pTemplate="header">
                <tr style="background-color: dodgerblue; color: aliceblue;">
                    <th>Data Cadastro</th>
                    <th>Nome Responsavel</th>
                    <th>Telefone</th>
                    <th>Nome Filho(a)</th>
                    <th>Seminario</th>
                    <th *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao') ||  _perfil.includes('Seminario_Total')" >Excluir</th>
                    <th *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao') ||  _perfil.includes('Seminario_Total') ">Detalhe</th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-c>
                <tr>
                    <td>{{c.dt_cadastro | date: 'dd/MM/yyyy'}}</td>
                    <td>{{c.responsavel}}</td>
                    <td>{{c.telefone}}</td>
                    <td>{{c.nome_filho}}</td>
                    <td>{{c.tipo_culto}}</td>
                    <td *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao') ||  _perfil.includes('Seminario_Total') ">
                        <button pButton pRipple type="button" icon="pi pi-trash" class="p-button-rounded"
                            style="background-color: red;" styleClass="rounded-button ui-button-danger"
                            value={{c.idseminarioinfantil}} {{idvisita}}={{c.idseminarioinfantil}} (click)="SetDados($event)"
                            data-toggle="modal" data-target="#exampleModal1" title="Exluir Usuario"></button>
                    </td>

                    <td *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao') ||  _perfil.includes('Seminario_Total') ">
                        <button pButton pRipple type="button" icon="pi pi-search-plus" class="p-button-rounded"
                            style="background-color: green;" styleClass="rounded-button ui-button-success"
                            value={{c.idseminarioinfantil}} (click)="VisualizarDetalhe($event)"
                            title="Visualizar Detalhe"></button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <!--Paginação-->
        <p-paginator [rows]="10" [paginator]='true' [totalRecords]=qto
            currentPageReportTemplate="Mostrando: {{x}} a {{y}} de {{qto}}" (onPageChange)="Paginate($event)"
            [showCurrentPageReport]="true">
        </p-paginator>

        <hr>

        <!--Botao Retorno-->
        <button pButton pRipple type="button" icon="pi pi-sign-in" title="Retornar"
            class="p-button-rounded p-button-danger" routerLink="/dashboard/resumo"></button>
        <br>
        <br>
        <br>
    </div>

    <!-- Modal -->
    <div id="exampleModal" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Gerar Documento para Impressão</h4>
                </div>
                <div class="modal-body">
                    <p>Por favor escolha a opção correta para geração do PDF para impressão!</p>
                    <br>
                    <div style="font-size: medium;">
                        <input id="novo" name="base" type="radio" value="Filtro:Todos" checked
                            (click)="ValorImpressao($event)" /> Todos Registros ( {{qto}} )
                    </div>
                    <div style="font-size: medium;">
                        <input id="novo" name="base" type="radio" value="Filtro:TodosAbertos"
                            (click)="ValorImpressao($event)" /> Todos Registros não Lidos
                    </div>
                    <div style="font-size: medium;">
                        <input id="novo" name="base" type="radio" value="Filtro:TodosFechados"
                            (click)="ValorImpressao($event)" /> Todos Registros Lidos
                    </div>
                    <div *ngIf="textoconsulta !='' ">
                        <div style="font-size: medium;">
                            <input id="ant" name="base" type="radio" value="Filtro:{{textoconsulta}}"
                                (click)="ValorImpressao($event)" /> Filtro Realizado: ( {{textoconsulta}})
                        </div>
                    </div>
                    <div *ngIf="textoconsulta !='' ">
                        <div style="font-size: medium;">
                            <input id="ant" name="base" type="radio"
                                value="FiltroPagina:{{textoconsulta}} and limit 10 offset {{pagina_atual}}"
                                (click)="ValorImpressao($event)" /> Filtro Realizado e Pagina: ( {{textoconsulta}} e
                            Pagina ( {{pagina_atual}} ))
                        </div>
                    </div>
                    <div style="font-size: medium;">
                        <input id="grade" name="base" type="radio" value="Pagina:{{pagina_atual}}"
                            (click)="ValorImpressao($event)" /> Pagina Atual ( {{pagina_atual}} )
                    </div>

                </div>

            </div>

        </div>
    </div>

    <!-- Modal -->
    <div id="exampleModal1" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog">

            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Confirma Exclusão?</h4>
                </div>
                <div class="modal-body">
                    <b>Olá {{nome}}</b>
                    <p>Ao excluir o Participante um e-mail será enviado para gestão da igreja informando a
                        desistencia.</p>
                    <p>Um contato ainda pode ser realizado, mas não se preocupe que respeitamos sua decisão!.</p>

                </div>
                <div class="modal-footer">
                    <button pButton pRipple type="button" icon="pi pi-trash" class="p-button-rounded" title="Excluir"
                        (click)="BotaoExcluir()" data-dismiss="modal"></button>
                    <button pButton pRipple type="button" icon="pi pi-sign-in" class="p-button-rounded p-button-danger"
                        title="Retornar" data-dismiss="modal"></button>
                </div>
            </div>

        </div>
    </div>
</div>
<button class="voltar-ao-topo" id="back"><a class="ancora" style="color: white;"><i
            class="pi pi-arrow-circle-up"></i></a></button>