<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>
<div class="row">


    <div class="col-md-12" style="margin-top: 7px;">

        <div>
            <h4 style="text-align: center;">
                <i class="fas fa-users"></i>&nbsp;<b>{{filtro_cabecalho}}</b>
            </h4>
            <p>
                &nbsp;&nbsp;&nbsp;&nbsp;{{explicacao}}
                <br>
                &nbsp;&nbsp;&nbsp;&nbsp;<b>#FicaaDica: </b>Em caso de Celular melhor visualizado na horizontal!
            </p>
        </div>
    </div>
    <div class="col-md-12">
        <br>
        <label><b>:: Dados Patrimonio ::</b></label><br>
        <label><b>Numero Patrimonio:&nbsp; </b>{{numeropatrimonio}}</label><br>
        <label><b>Patrimonio:&nbsp; </b>{{nomepatrimonio}}</label><br>
        <label><b>Valor Patrimonio:&nbsp; </b>{{valorpatrimonio}}</label><br>
        <label><b>setor:&nbsp;</b>{{setorpatrimonio}}</label><br>
    </div>
    <div class="col-md-12">
        <label><b>Gerar Etiqueta</b></label><br>
        <button pButton pRipple type="button" icon="pi pi-print" class="p-button-rounded"
            styleClass="rounded-button ui-button-info" (click)="ImprimirEtiqueta()" title="Imprimir Etiqueta"
            style="margin-right: 10px; margin-left: 10px;"></button>
            <br>
    </div>

    <!--Filtro-->
    <div class="col-md-12">
        <div class="row" style="margin-bottom: 3px; padding-right: 5px;">

            <!--Filtro-->
            <div class="col-md" style="display: block; border: 0px;padding-bottom: 0%; padding-left: 5px; ">
                <label style=" display: block; border: 0px; padding-bottom: 0%;"><b
                        style=" padding-bottom: 0%;">Filtro:</b>
                    <span style=" display: block; border: 0px; font-size: x-small; padding-top: 0%;">Consulta por
                        tipo de movimentação</span>
                    <input type="text" class="form-control" (keydown)="Consulta()" id="texto_consulta"
                        style="margin-top: 0%;" />
                </label>
            </div>
        </div>
    </div>

    <!--Tabela-->
    <div class="panel-body col-md-12">
        <p-table [value]="usuario" [paginator]='false' [columns]="cols" [rows]="10" [showCurrentPageReport]="true"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" selectionMode="single"
            paginatorTemplate="{RowsPerPageDropdown} {FirstPageLink} {PreviousPageLink} {CurrentPageReport} {NextPageLink} {LastPageLink}"
            (onLazyLoad)='Paginate ($event)' styleClass="p-datatable-responsive-demo" autoLayout="true"
            [responsive]="true">

            <ng-template pTemplate="header" let-usuario let-columns>
                <tr style="background-color: dodgerblue; color: aliceblue;">
                    <th *ngFor="let col of columns">
                        {{col.header}}
                    </th>
            </ng-template>

            <ng-template pTemplate="body" let-c>
                <tr>
                    <td>
                        <span class="p-column-title">Data Cadastro</span>
                        {{c.dt_movimentacao | date: 'dd/MM/yyyy'}}
                    </td>
                    <td>
                        <span class="p-column-title">Hora</span>
                        {{c.hora_movimentacao}}
                    </td>
                    <td>
                        <span class="p-column-title">Tipo</span>
                        {{c.tipo_movimentacao}}
                    </td>
                    <td>
                        <span class="p-column-title">Detalhes</span>
                        {{c.detalhes_movimentacao}}
                    </td>
                    <td>
                        <span class="p-column-title">Responsavel</span>
                        {{c.nome_usuario}}
                    </td>

                </tr>
            </ng-template>
        </p-table>
        <!--Paginação-->
        <p-paginator [rows]="10" [paginator]='true' [totalRecords]=qto
            currentPageReportTemplate="Mostrando: {{x}} a {{y}} de {{qto}}" (onPageChange)="Paginate($event)"
            [showCurrentPageReport]="true">
        </p-paginator>

        <hr>

        <!--Botao Retorno-->
        <button pButton pRipple type="button" icon="pi pi-sign-in" title="Retornar"
            class="p-button-rounded p-button-danger"
            routerLink="/dashboard/gerenciamento/patrimonio/listagem-patrimonio"></button>
        <br>
        <br>
        <br>
        <br>
    </div>






</div>