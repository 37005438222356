import { RecuperarAutenticacaoComponent } from './Recuperacoes/recuperar-autenticacao/recuperar-autenticacao.component';
import { CadastroMembrosComponent } from './cadastro-membros/cadastro-membros.component';
import { AuthGuard } from './guards/auth.guard';
import { PaginasRetornoComponent } from './paginas-retorno/paginas-retorno.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GuardChild } from './guards/auth.guard.child';
import { ReportarProblemaComponent } from './reportar-problema/reportar-problema.component';
import { ContatoComponent } from './contato/contato.component';
import { ConfirmarCadastroComponent } from './confirmar-cadastro/confirmar-cadastro.component';
import { DashboardHomeComponent } from './Dashboard/dashboard-home/dashboard-home.component';
import { RegistrarElogioComponent } from './Dashboard/registrar-elogio/registrar-elogio.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { VisitanteComponent } from './visitante/visitante.component';
import { DesenvolvimentoComponent } from './desenvolvimento/desenvolvimento.component';
import { PedidoVisitaComponent } from './Dashboard/pedido-visita/pedido-visita.component';
import { HomeCadastrosComponent } from './home-cadastros/home-cadastros.component';
import { MinisteriosComponent } from './ministerios/ministerios.component';
import { MissaoVisaoValoresComponent } from './missao-visao-valores/missao-visao-valores.component';
import { PastoresComponent } from './pastores/pastores.component';
import { PrivacidadeComponent } from './privacidade/privacidade.component';
import { TestemunhoComponent } from './Dashboard/testemunho/testemunho.component';
import { SugestoesComponent } from './Dashboard/sugestoes/sugestoes.component';
import { ReclamacaoComponent } from './Dashboard/reclamacao/reclamacao.component';
import { PerfilComponent } from './Dashboard/perfil/perfil.component';
import { TrocarSenhaComponent } from './Dashboard/trocar-senha/trocar-senha.component';
import { ComunicacaoComponent } from './Dashboard/comunicacao/comunicacao.component';
import { ResumoComponent } from './Dashboard/resumo/resumo.component';
import { PoliticaPrivacidadeComponent } from './Dashboard/politica-privacidade/politica-privacidade.component';
import { ConfiguracaoesComponent } from './Dashboard/configuracaoes/configuracaoes.component';
import { TermosComponent } from './Dashboard/termos/termos.component';
import { CancelarContaComponent } from './Dashboard/cancelar-conta/cancelar-conta.component';
import { ElogioListagemComponent } from './Dashboard/Listagem/elogio-listagem/elogio-listagem.component';
import { ElogioDetalheComponent } from './Dashboard/Detalhamento/elogio-detalhe/elogio-detalhe.component';
import { VisitaListagemComponent } from './Dashboard/Listagem/visita-listagem/visita-listagem.component';
import { VisitaDetalheComponent } from './Dashboard/Detalhamento/visita-detalhe/visita-detalhe.component';
import { ReclamacaoListagemComponent } from './Dashboard/Listagem/reclamacao-listagem/reclamacao-listagem.component';
import { ReclamacaoDetalheComponent } from './Dashboard/Detalhamento/reclamacao-detalhe/reclamacao-detalhe.component';
import { SugestoesListagemComponent } from './Dashboard/Listagem/sugestoes-listagem/sugestoes-listagem.component';
import { SugestoesDetalheComponent } from './Dashboard/Detalhamento/sugestoes-detalhe/sugestoes-detalhe.component';
import { TestesmunhoListagemComponent } from './Dashboard/Listagem/testesmunho-listagem/testesmunho-listagem.component';
import { TestemunhoDetalheComponent } from './Dashboard/Detalhamento/testemunho-detalhe/testemunho-detalhe.component';
import { AcessosListagemComponent } from './Dashboard/Listagem/acessos-listagem/acessos-listagem.component';
import { AcessoDetalheComponent } from './Dashboard/Detalhamento/acesso-detalhe/acesso-detalhe.component';
import { NossosCliquesComponent } from './nossos-cliques/nossos-cliques.component';
import { NossaAgendaComponent } from './nossa-agenda/nossa-agenda.component';
import { CursosComponent } from './cursos/cursos.component';
import { SeminariosComponent } from './seminarios/seminarios-home/seminarios.component';
import { SeminarioCadastroComponent } from './seminarios/seminario-cadastro/seminario-cadastro.component';
import { ListagemParticipantesSeminarioComponent } from './Dashboard/Seminario/listagem-participantes-seminario/listagem-participantes-seminario.component';
import { DetalheUsuarioSeminarioComponent } from './Dashboard/Seminario/detalhe-usuario-seminario/detalhe-usuario-seminario.component';
import { ComunicadoSeminarioComponent } from './Dashboard/Seminario/comunicado-seminario/comunicado-seminario.component';
import { ListagemMembrosComponent } from './Dashboard/membros/listagem-membros/listagem-membros.component';
import { MembrosAtivosComponent } from './Dashboard/membros/membros-ativos/membros-ativos.component';
import { MembrosInativosComponent } from './Dashboard/membros/membros-inativos/membros-inativos.component';
import { MembrosAguardandoLiberacaoComponent } from './Dashboard/membros/membros-aguardando-liberacao/membros-aguardando-liberacao.component';
import { MembrosCanceladosComponent } from './Dashboard/membros/membros-cancelados/membros-cancelados.component';
import { DetalheListagemMembrosComponent } from './Dashboard/membros/detalhe-listagem-membros/detalhe-listagem-membros.component';
import { DetalheMembrosAtivosComponent } from './Dashboard/membros/detalhe-membros-ativos/detalhe-membros-ativos.component';
import { DetalheMembrosAguardandoLiberacaoComponent } from './Dashboard/membros/detalhe-membros-aguardando-liberacao/detalhe-membros-aguardando-liberacao.component';
import { DetalheMembrosCanceladosComponent } from './Dashboard/membros/detalhe-membros-cancelados/detalhe-membros-cancelados.component';
import { DetalheMembrosInativosComponent } from './Dashboard/membros/detalhe-membros-inativos/detalhe-membros-inativos.component';
import { ComunicadoMembrosComponent } from './Dashboard/membros/comunicado-membros/comunicado-membros.component';
import { NossaIgrejaComponent } from './nossa-igreja/nossa-igreja.component';
import { MembrosPerfilComponent } from './Dashboard/membros/membros-perfil/membros-perfil.component';
import { MembrosPerfilAjustesComponent } from './Dashboard/membros/membros-perfil-ajustes/membros-perfil-ajustes.component';
import { MembrosCursosAjustesComponent } from './Dashboard/membros/membros-cursos-ajustes/membros-cursos-ajustes.component';
import { MembrosMinisterioAjustesComponent } from './Dashboard/membros/membros-ministerio-ajustes/membros-ministerio-ajustes.component';
import { ListagemVisitantesComponent } from './Dashboard/visitantes/listagem-visitantes/listagem-visitantes.component';
import { VisitantesDetalheComponent } from './Dashboard/visitantes/visitantes-detalhe/visitantes-detalhe.component';
import { ComunicarVisitantesComponent } from './Dashboard/visitantes/comunicar-visitantes/comunicar-visitantes.component';
import { ListagemParticipantesCultoRegistradoComponent } from './Dashboard/culto_registrado/listagem-participantes-culto-registrado/listagem-participantes-culto-registrado.component';
import { ComunicadoCultoRegistradoComponent } from './Dashboard/culto_registrado/comunicado-culto-registrado/comunicado-culto-registrado.component';
import { DetalheUsuarioCultoRegistradoComponent } from './Dashboard/culto_registrado/detalhe-usuario-culto-registrado/detalhe-usuario-culto-registrado.component';
import { ListagemParticipantesCultoKidsRegistradoComponent } from './Dashboard/culto_kids_registrado/listagem-participantes-culto-kids-registrado/listagem-participantes-culto-kids-registrado.component';
import { ComunicadoCultoKidsRegistradoComponent } from './Dashboard/culto_kids_registrado/comunicado-culto-kids-registrado/comunicado-culto-kids-registrado.component';
import { DetalheUsuarioCultoKidsRegistradoComponent } from './Dashboard/culto_kids_registrado/detalhe-usuario-culto-kids-registrado/detalhe-usuario-culto-kids-registrado.component';
import { ResumoCultosComponent } from './Dashboard/culto_registrado/resumo-cultos/resumo-cultos.component';
import { ResumoCultosKidsComponent } from './Dashboard/culto_kids_registrado/resumo-cultos-kids/resumo-cultos-kids.component';
import { QrCodeComponent } from './qr-code/qr-code.component';
import { CheckoutDocumentoComponent } from './Dashboard/culto_registrado/checkout-documento/checkout-documento.component';
import { CheckoutDocumentoSeminarioComponent } from './Dashboard/Seminario/checkout-documento-seminario/checkout-documento-seminario.component';
import { ResumoSeminarioConfirmadosComponent } from './Dashboard/Seminario/resumo-seminario-confirmados/resumo-seminario-confirmados.component';
import { ResumoCadastroSeminarioComponent } from './Dashboard/Seminario/resumo-cadastro-seminario/resumo-cadastro-seminario.component';
import { CadastroEstabelecimentoComponent } from './Dashboard/Estabelecimento/cadastro-estabelecimento/cadastro-estabelecimento.component';
import { TermoEstabelecimentoComponent } from './Dashboard/termo-estabelecimento/termo-estabelecimento.component';
import { ListagemEstabelecimentoComponent } from './Dashboard/Estabelecimento/listagem-estabelecimento/listagem-estabelecimento.component';
import { EditarEstabelecimentoComponent } from './Dashboard/Estabelecimento/editar-estabelecimento/editar-estabelecimento.component';
import { ListagemCompletaEstabelecimentoComponent } from './Dashboard/Estabelecimento/listagem-completa-estabelecimento/listagem-completa-estabelecimento.component';
import { ConsultaEstabelecimentoComponent } from './consulta-estabelecimento/consulta-estabelecimento.component';
import { ListagemOportunidadeComponent } from './Dashboard/oportunidades/listagem-oportunidade/listagem-oportunidade.component';
import { CadastroOportunidadeComponent } from './Dashboard/oportunidades/cadastro-oportunidade/cadastro-oportunidade.component';
import { EditarOportunidadeComponent } from './Dashboard/oportunidades/editar-oportunidade/editar-oportunidade.component';
import { ListagemOportunidadeCompletaComponent } from './Dashboard/oportunidades/listagem-oportunidade-completa/listagem-oportunidade-completa.component';
import { ListaConsultaOportunidadesComponent } from './Dashboard/oportunidades/lista-consulta-oportunidades/lista-consulta-oportunidades.component';
import { TermoOportunidadeComponent } from './Dashboard/termo-oportunidade/termo-oportunidade.component';
import { ListagemGeralOportunidadesComponent } from './Dashboard/oportunidades/listagem-geral-oportunidades/listagem-geral-oportunidades.component';
import { ListagemGeralEstabelecimentosComponent } from './Dashboard/Estabelecimento/listagem-geral-estabelecimentos/listagem-geral-estabelecimentos.component';
import { TermoEncontroHomensComponent } from './Dashboard/termo-encontro-homens/termo-encontro-homens.component';
import { CheckoutKidsDocumentoComponent } from './Dashboard/culto_kids_registrado/checkout-kids-documento/checkout-kids-documento.component';
import { ResumoCadastroSeminarioInfantilComponent } from './Dashboard/Seminario/resumo-cadastro-seminario-infantil/resumo-cadastro-seminario-infantil.component';
import { ResumoSeminarioInfantilConfirmadosComponent } from './Dashboard/Seminario/resumo-seminario-infantil-confirmados/resumo-seminario-infantil-confirmados.component';
import { ListagemPasticipantesSeminarioInfantilComponent } from './Dashboard/Seminario/listagem-pasticipantes-seminario-infantil/listagem-pasticipantes-seminario-infantil.component';
import { CheckoutDocumentoSeminarioInfantilComponent } from './Dashboard/Seminario/checkout-documento-seminario-infantil/checkout-documento-seminario-infantil.component';
import { DetalheUsuarioSeminarioInfantilComponent } from './Dashboard/Seminario/detalhe-usuario-seminario-infantil/detalhe-usuario-seminario-infantil.component';
import { ComunicadoSeminarioInfantilComponent } from './Dashboard/Seminario/comunicado-seminario-infantil/comunicado-seminario-infantil.component';
import { ListagemCadastrosEncontroMulheresComponent } from './Dashboard/Encontros/listagem-cadastros-encontro-mulheres/listagem-cadastros-encontro-mulheres.component';
import { DetalheCadastroEncontroMulheresComponent } from './Dashboard/Encontros/detalhe-cadastro-encontro-mulheres/detalhe-cadastro-encontro-mulheres.component';
import { ListagemCadastrosEncontroHomensComponent } from './Dashboard/Encontros/listagem-cadastros-encontro-homens/listagem-cadastros-encontro-homens.component';
import { DetalheCadastroEncontroHomensComponent } from './Dashboard/Encontros/detalhe-cadastro-encontro-homens/detalhe-cadastro-encontro-homens.component';
import { TermoEncontroMulheresComponent } from './Dashboard/termo-encontro-mulheres/termo-encontro-mulheres.component';
import { RegistrarPatrimonioComponent } from './Dashboard/patrimonio/registrar-patrimonio/registrar-patrimonio.component';
import { ResumoPatrimonioSetorComponent } from './Dashboard/patrimonio/resumo-patrimonio-setor/resumo-patrimonio-setor.component';
import { ListagemPatrimonioComponent } from './Dashboard/patrimonio/listagem-patrimonio/listagem-patrimonio.component';
import { DetalhePatrimonioComponent } from './Dashboard/patrimonio/detalhe-patrimonio/detalhe-patrimonio.component';
import { ListagemPatrimonioInativoComponent } from './Dashboard/patrimonio/listagem-patrimonio-inativo/listagem-patrimonio-inativo.component';
import { DetalhePatrimonioInativoComponent } from './Dashboard/patrimonio/detalhe-patrimonio-inativo/detalhe-patrimonio-inativo.component';
import { ConsultaQrcodeComponent } from './Dashboard/patrimonio/consulta-qrcode/consulta-qrcode.component';
import { PedidoCompraComponent } from './Dashboard/solicitacao-compras/pedido-compra/pedido-compra.component';
import { ListagemSolicitacaoComprasComponent } from './Dashboard/solicitacao-compras/listagem-solicitacao-compras/listagem-solicitacao-compras.component';
import { DetalheSollicitacaoCompraComponent } from './Dashboard/solicitacao-compras/detalhe-sollicitacao-compra/detalhe-sollicitacao-compra.component';
import { CadastroCasaisComponent } from './Dashboard/cursos-casais/cadastro-casais/cadastro-casais.component';
import { TermoCursoCasaisComponent } from './Dashboard/termo-curso-casais/termo-curso-casais.component';
import { ListagemCursoCasaisComponent } from './Dashboard/cursos-casais/listagem-curso-casais/listagem-curso-casais.component';
import { DetalheCursoCasaisComponent } from './Dashboard/cursos-casais/detalhe-curso-casais/detalhe-curso-casais.component';
import { CkeckoutCongressoMulheresComponent } from './Dashboard/congresso-mulheres/ckeckout-congresso-mulheres/ckeckout-congresso-mulheres.component';
import { ResumoCongressoMulheresComponent } from './Dashboard/congresso-mulheres/resumo-congresso-mulheres/resumo-congresso-mulheres.component';
import { CadastroCongressoMulheresComponent } from './Dashboard/congresso-mulheres/cadastro-congresso-mulheres/cadastro-congresso-mulheres.component';
import { TermoCongressoMulheresComponent } from './Dashboard/termo-congresso-mulheres/termo-congresso-mulheres.component';
import { ListagemParticipantesCongressoMulheresComponent } from './Dashboard/congresso-mulheres/listagem-participantes-congresso-mulheres/listagem-participantes-congresso-mulheres.component';
import { ComunicadoCongressoMulheresComponent } from './Dashboard/congresso-mulheres/comunicado-congresso-mulheres/comunicado-congresso-mulheres.component';
import { DetalheParticipanteCongressoMulheresComponent } from './Dashboard/congresso-mulheres/detalhe-participante-congresso-mulheres/detalhe-participante-congresso-mulheres.component';
import { SeminarioInfantilComponent } from './seminarios/seminario-infantil/seminario-infantil.component';
import { RecuperarDadosComponent } from './Dashboard/congresso-mulheres/recuperar-dados/recuperar-dados.component';
import { CadastroBatismoComponent } from './Dashboard/batismo/cadastro-batismo/cadastro-batismo.component';
import { ListagemBatismoComponent } from './Dashboard/batismo/listagem-batismo/listagem-batismo.component';
import { CadastroCongressoHomensComponent } from './Dashboard/congresso-homens/cadastro-congresso-homens/cadastro-congresso-homens.component';
import { CheckoutCongressoHomensComponent } from './Dashboard/congresso-homens/checkout-congresso-homens/checkout-congresso-homens.component';
import { ComunicadoCongressoHomensComponent } from './Dashboard/congresso-homens/comunicado-congresso-homens/comunicado-congresso-homens.component';
import { DetalheParticipanteCongressoHomensComponent } from './Dashboard/congresso-homens/detalhe-participante-congresso-homens/detalhe-participante-congresso-homens.component';
import { ListagemParticipantesCongressoHomensComponent } from './Dashboard/congresso-homens/listagem-participantes-congresso-homens/listagem-participantes-congresso-homens.component';
import { RecuperarDadosCongressoHomensComponent } from './Dashboard/congresso-homens/recuperar-dados-congresso-homens/recuperar-dados-congresso-homens.component';
import { ResumoCongressoHomensComponent } from './Dashboard/congresso-homens/resumo-congresso-homens/resumo-congresso-homens.component';
import { TermoCongressoHomensComponent } from './Dashboard/termo-congresso-homens/termo-congresso-homens.component';
import { ArquivoFotosComponent } from './arquivo-fotos/arquivo-fotos.component';
import { CadastroCongressoJovensComponent } from './Dashboard/congresso-jovens/cadastro-congresso-jovens/cadastro-congresso-jovens.component';
import { RecuperarDadosCongressoJovensService } from './Dashboard/congresso-jovens/recuperar-dados-congresso-jovens/recuperar-dados-congresso-jovens.service';
import { ListagemParticipantesCongressoJovensComponent } from './Dashboard/congresso-jovens/listagem-participantes-congresso-jovens/listagem-participantes-congresso-jovens.component';
import { ComunicadoCongressoJovensComponent } from './Dashboard/congresso-jovens/comunicado-congresso-jovens/comunicado-congresso-jovens.component';
import { ResumoCongressoJovensComponent } from './Dashboard/congresso-jovens/resumo-congresso-jovens/resumo-congresso-jovens.component';
import { TermoCongressoJovensComponent } from './Dashboard/termo-congresso-jovens/termo-congresso-jovens.component';
import { RecuperarDadosCongressoJovensComponent } from './Dashboard/congresso-jovens/recuperar-dados-congresso-jovens/recuperar-dados-congresso-jovens.component';
import { DetalheParticipanteCongressoJovensComponent } from './Dashboard/congresso-jovens/detalhe-participante-congresso-jovens/detalhe-participante-congresso-jovens.component';
import { CheckoutCongressoJovensComponent } from './Dashboard/congresso-jovens/checkout-congresso-jovens/checkout-congresso-jovens.component';
import { EncontroMulheresComponent } from './encontro-mulheres/encontro-mulheres.component';
import { VincularResponsavelComponent } from './Dashboard/Deducao_Compras/vincular-responsavel/vincular-responsavel.component';
import { ListagemTodasDeducoesComponent } from './Dashboard/Deducao_Compras/listagem-todas-deducoes/listagem-todas-deducoes.component';
import { ListagemDeducoesComprasComponent } from './Dashboard/Deducao_Compras/listagem-deducoes-compras/listagem-deducoes-compras.component';
import { RegistrarCompraComponent } from './Dashboard/Deducao_Compras/registrar-compra/registrar-compra.component';
import { TesteComponent } from './teste/teste.component';
import {EncontroHomensComponent} from "./encontro-homens/encontro-homens.component";
//import {EncontroCasaisComponent} from "./encontro-casais/encontro-casais.component";
import {TermoEncontroCasaisComponent} from "./Dashboard/termo-encontro-casais/termo-encontro-casais.component";
import {
  ListagemCadastroEncontroCasaisComponent
} from "./Dashboard/Encontros/listagem-cadastro-encontro-casais/listagem-cadastro-encontro-casais.component";
import {
  DetalheCadastroEncontroCasaisComponent
} from "./Dashboard/Encontros/detalhe-cadastro-encontro-casais/detalhe-cadastro-encontro-casais.component";
import { CultoKidsInscricaoComponent } from './culto-kids-inscricao/culto-kids-inscricao.component';
import { EncontroCasaisComponent } from './encontro-casais/encontro-casais.component';
const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'home', component: HomeComponent },
  { path: 'login', component: LoginComponent },
  { path: 'recuperar-autenticacao', component: RecuperarAutenticacaoComponent, pathMatch: 'full' },
  { path: 'retorno', component: PaginasRetornoComponent, pathMatch: 'full' },
  { path: 'problema', component: ReportarProblemaComponent, pathMatch: 'full' },
  { path: 'contato', component: ContatoComponent, pathMatch: 'full' },
  { path: 'cadastro-membros', component: CadastroMembrosComponent, pathMatch: 'full' },
  { path: 'cadastro-seminario', component: SeminarioCadastroComponent },
  { path: 'confirmar-cadastro/:hash', component: ConfirmarCadastroComponent, pathMatch: 'full' },
  { path: 'visitante', component: VisitanteComponent, pathMatch: 'full' },
  { path: 'erro', component: NotFoundComponent, pathMatch: 'full' },
  { path: 'desenvolvimento', component: DesenvolvimentoComponent },
  { path: 'cadastros', component: HomeCadastrosComponent },
  { path: 'ministerios', component: MinisteriosComponent },
  { path: 'missao-visao-valores', component: MissaoVisaoValoresComponent },
  { path: 'pastores', component: PastoresComponent },
  { path: 'nossos-cliques', component: NossosCliquesComponent },
  { path: 'nossa-agenda', component: NossaAgendaComponent },
  { path: 'nossa-igreja', component: NossaIgrejaComponent },
  { path: 'nossos-cursos', component: CursosComponent },
  { path: 'nossos-seminarios', component: SeminariosComponent },
  { path: 'politica-privacidade', component: PrivacidadeComponent },
  { path: 'qrcode/:', component: QrCodeComponent },
  { path: 'qrcode-patrimonio/:', component: ConsultaQrcodeComponent },
  { path: 'consulta', component: ConsultaEstabelecimentoComponent },
  { path: 'registros', component: ArquivoFotosComponent },
  //{ path: 'encontro-homens', component: EncontroHomensComponent },
  { path: 'batismo', component: CadastroBatismoComponent },
  { path: 'teste', component: TesteComponent },
  { path: 'curso-casais', component: CadastroCasaisComponent },
  //{ path: 'oficinadoamor', component: CadastroCasaisComponent },
  //{ path: 'encontro-mulheres', component: EncontroMulheresComponent },
  //{ path: 'inscricao_culto', component: CultoInscricaoComponent },
  { path: 'inscricao_culto_kids', component: CultoKidsInscricaoComponent },
  //{ path: 'congresso', component: CadastroCongressoHomensComponent },
  //{ path: 'conferencia', component: CadastroCongressoJovensComponent },
  //{ path: 'recuperar-dados', component: RecuperarDadosCongressoJovensComponent },
  //{ path: 'recuperar-dados-congresso-mulheres', component: RecuperarDadosComponent },
  //{ path: 'congresso', component: CadastroCongressoMulheresComponent },
  //{ path: 'seminario-infantil', component: SeminarioInfantilComponent },
  //{ path: 'cursos/curso-para-casais', component: CadastroCasaisComponent },
  { path: 'cursos/curso-casais', component: CadastroCasaisComponent },
  { path: 'encontro-casais', component: EncontroCasaisComponent },
  //{ path: 'pedido', component: PedidoCompraComponent },

  //{ path: 'teste', component: CkeckoutCongressoMulheresComponent },





  {
    //lembrar se acertar o guard childer
    path: 'dashboard',
    component: DashboardHomeComponent,
    canActivate: [AuthGuard], canActivateChild: [GuardChild], children: [
      { path: 'elogio', component: RegistrarElogioComponent },
      { path: 'visita', component: PedidoVisitaComponent },
      { path: 'testemunho', component: TestemunhoComponent },
      { path: 'sugestoes', component: SugestoesComponent },
      { path: 'reclamacao', component: ReclamacaoComponent },
      { path: 'perfil', component: PerfilComponent },
      { path: 'trocar-senha', component: TrocarSenhaComponent },
      { path: 'comunicacao', component: ComunicacaoComponent },
      { path: 'resumo', component: ResumoComponent },
      { path: 'privacidade', component: PoliticaPrivacidadeComponent },
      { path: 'parametros', component: ConfiguracaoesComponent },
      { path: 'termos', component: TermosComponent },
      { path: 'termos-estabelecimentos', component: TermoEstabelecimentoComponent },
      { path: 'termos-oportunidade', component: TermoOportunidadeComponent },
      { path: 'termos-encontro-homens', component: TermoEncontroHomensComponent },
      { path: 'termos-encontro-mulheres', component: TermoEncontroMulheresComponent },
      { path: 'termos-curso-casais', component: TermoCursoCasaisComponent },
      { path: 'termos-congresso-mulheres', component: TermoCongressoMulheresComponent },
      { path: 'termos-congresso-homens', component: TermoCongressoHomensComponent },
      { path: 'termos-congresso-jovens', component: TermoCongressoJovensComponent },
      { path: 'termos-encontro-casais', component: TermoEncontroCasaisComponent },
      { path: 'cancelar-registro', component: CancelarContaComponent },
      { path: 'listagem/lista-elogio', component: ElogioListagemComponent },
      { path: 'listagem/lista-visita', component: VisitaListagemComponent },
      { path: 'listagem/lista-reclamacao', component: ReclamacaoListagemComponent },
      { path: 'listagem/lista-testemunho', component: TestesmunhoListagemComponent },
      { path: 'listagem/lista-sugestoes', component: SugestoesListagemComponent },
      { path: 'listagem/lista-acessos', component: AcessosListagemComponent },
      { path: 'listagem/lista-seminario', component: ListagemParticipantesSeminarioComponent },
      { path: 'detalhe/visita', component: VisitaDetalheComponent },
      { path: 'detalhe/elogio', component: ElogioDetalheComponent },
      { path: 'detalhe/reclamacao', component: ReclamacaoDetalheComponent },
      { path: 'detalhe/sugestoes', component: SugestoesDetalheComponent },
      { path: 'detalhe/testemunho', component: TestemunhoDetalheComponent },
      { path: 'detalhe/acesso', component: AcessoDetalheComponent },
      { path: 'detalhe/usuario-seminario', component: DetalheUsuarioSeminarioComponent },
      { path: 'detalhe/usuario-seminario-infantil', component: DetalheUsuarioSeminarioInfantilComponent },
      { path: 'comunicado/enviar-comunicado', component: ComunicadoSeminarioComponent },
      { path: 'comunicado/enviar-comunicado-seminario-infantil', component: ComunicadoSeminarioInfantilComponent },
      { path: 'gerenciamento/listagem-membros', component: ListagemMembrosComponent },
      { path: 'gerenciamento/membros-ativos', component: MembrosAtivosComponent },
      { path: 'gerenciamento/membros-inativos', component: MembrosInativosComponent },
      { path: 'gerenciamento/membros-aguardando-liberacao', component: MembrosAguardandoLiberacaoComponent },
      { path: 'gerenciamento/membros-cancelados', component: MembrosCanceladosComponent },
      { path: 'gerenciamento/detalhamento/usuario-detalhe', component: DetalheListagemMembrosComponent },
      { path: 'gerenciamento/detalhamento/usuario-ativo', component: DetalheMembrosAtivosComponent },
      { path: 'gerenciamento/detalhamento/usuario-aguardando-liberacao', component: DetalheMembrosAguardandoLiberacaoComponent },
      { path: 'gerenciamento/detalhamento/usuario-cancelado', component: DetalheMembrosCanceladosComponent },
      { path: 'gerenciamento/detalhamento/usuario-inativo', component: DetalheMembrosInativosComponent },
      { path: 'gerenciamento/comunicacao/comunicar-usuarios', component: ComunicadoMembrosComponent },
      { path: 'perfil/perfil-usuario', component: MembrosPerfilComponent },
      { path: 'gerenciamento/perfil/usuario-ajuste-perfil', component: MembrosPerfilAjustesComponent },
      { path: 'gerenciamento/perfil/usuario-ajuste-curso', component: MembrosCursosAjustesComponent },
      { path: 'gerenciamento/perfil/usuario-ajuste-ministerio', component: MembrosMinisterioAjustesComponent },
      { path: 'gerenciamento/visitantes/visitantes-listagem', component: ListagemVisitantesComponent },
      { path: 'gerenciamento/visitantes/detalhamento/visitantes-detalhes', component: VisitantesDetalheComponent },
      { path: 'gerenciamento/visitantes/comunicacao/comunicar-visitantes', component: ComunicarVisitantesComponent },
      { path: 'gerenciamento/encontro-mulheres/cadastros', component: ListagemCadastrosEncontroMulheresComponent },
      { path: 'gerenciamento/encontro-casais/cadastros', component: ListagemCadastroEncontroCasaisComponent },
      { path: 'gerenciamento/detalhamento/Cadastro-mulheres-detalhe', component: DetalheCadastroEncontroMulheresComponent },
      { path: 'gerenciamento/detalhamento/Cadastro-casais-detalhe', component: DetalheCadastroEncontroCasaisComponent },
      { path: 'gerenciamento/encontro-homens/cadastros', component: ListagemCadastrosEncontroHomensComponent },
      { path: 'gerenciamento/detalhamento/Cadastro-homens-detalhe', component: DetalheCadastroEncontroHomensComponent },
      { path: 'listagem/listagem-culto-registrado', component: ListagemParticipantesCultoRegistradoComponent },
      { path: 'gerenciamento/congresso-mulheres/cadastros', component: ListagemParticipantesCongressoMulheresComponent },
      { path: 'gerenciamento/congresso-homens/cadastros', component: ListagemParticipantesCongressoHomensComponent },
      { path: 'gerenciamento/congresso-jovens/cadastros', component: ListagemParticipantesCongressoJovensComponent },
      { path: 'detalhe/Congresso-Mulheres', component: DetalheParticipanteCongressoMulheresComponent },
      { path: 'detalhe/Congresso-Homens', component: DetalheParticipanteCongressoHomensComponent },
      { path: 'detalhe/Congresso-Jovens', component: DetalheParticipanteCongressoJovensComponent },
      { path: 'listagem/lista-seminario-infantil', component: ListagemPasticipantesSeminarioInfantilComponent },
      { path: 'comunicado/enviar-comunicado-culto-registrado', component: ComunicadoCultoRegistradoComponent },
      { path: 'comunicado/enviar-comunicado-congresso-mulheres', component: ComunicadoCongressoMulheresComponent },
      { path: 'comunicado/enviar-comunicado-congresso-homens', component: ComunicadoCongressoHomensComponent },
      { path: 'comunicado/enviar-comunicado-congresso-jovens', component: ComunicadoCongressoJovensComponent },
      { path: 'detalhe/usuario-culto', component: DetalheUsuarioCultoRegistradoComponent },
      { path: 'listagem/listagem-culto-kids-registrado', component: ListagemParticipantesCultoKidsRegistradoComponent },
      { path: 'comunicado/enviar-comunicado-culto-kids-registrado', component: ComunicadoCultoKidsRegistradoComponent },
      { path: 'detalhe/usuario-culto-kids', component: DetalheUsuarioCultoKidsRegistradoComponent },
      { path: 'resumo/resumo-culto-registrado', component: ResumoCultosComponent },
      { path: 'resumo/resumo-culto-kids-registrado', component: ResumoCultosKidsComponent },
      { path: 'checkout/documento', component: CheckoutDocumentoComponent },
      { path: 'checkout/documento-kids', component: CheckoutKidsDocumentoComponent },
      { path: 'checkout/documento-seminario', component: CheckoutDocumentoSeminarioComponent },
      { path: 'checkout/documento-seminario-infantil', component: CheckoutDocumentoSeminarioInfantilComponent },
      { path: 'checkout/congresso-mulheres', component: CkeckoutCongressoMulheresComponent },
      { path: 'checkout/congresso-homens', component: CheckoutCongressoHomensComponent },
      { path: 'checkout/congresso-jovens', component: CheckoutCongressoJovensComponent },
      { path: 'resumo/congresso-mulheres', component: ResumoCongressoMulheresComponent },
      { path: 'resumo/congresso-homens', component: ResumoCongressoHomensComponent },
      { path: 'resumo/congresso-jovens', component: ResumoCongressoJovensComponent },
      { path: 'resumo/resumo-seminario-registrado', component: ResumoCadastroSeminarioComponent },
      { path: 'resumo/resumo-seminario-confirmado', component: ResumoSeminarioConfirmadosComponent },
      { path: 'resumo/resumo-seminario-infantil-registrado', component: ResumoCadastroSeminarioInfantilComponent },
      { path: 'resumo/resumo-seminario-infantil-confirmado', component: ResumoSeminarioInfantilConfirmadosComponent },
      { path: 'cadastro-estabelecimento', component: CadastroEstabelecimentoComponent },
      { path: 'listagem-estabelecimento', component: ListagemEstabelecimentoComponent },
      { path: 'editar-estabelecimento', component: EditarEstabelecimentoComponent },
      { path: 'listagem/listagem-empresa-servico', component: ListagemCompletaEstabelecimentoComponent },
      { path: 'cadastrar-oportunidade', component: CadastroOportunidadeComponent },
      { path: 'listagem-oportunidade', component: ListagemOportunidadeComponent },
      { path: 'editar-oportunidade', component: EditarOportunidadeComponent },
      { path: 'listagem/listagem-oportunidades', component: ListagemOportunidadeCompletaComponent },
      { path: 'consulta/consulta-oportunidades', component: ListaConsultaOportunidadesComponent },
      { path: 'consulta/consulta-geral-oportunidades', component: ListagemGeralOportunidadesComponent },
      { path: 'consulta/consulta-geral-servicos-estabelecimentos', component: ListagemGeralEstabelecimentosComponent },
      { path: 'gerenciamento/patrimonio/registrar-patrimonio', component: RegistrarPatrimonioComponent },
      { path: 'gerenciamento/patrimonio/resumo-patrimonio-setor', component: ResumoPatrimonioSetorComponent },
      { path: 'gerenciamento/patrimonio/listagem-patrimonio', component: ListagemPatrimonioComponent },
      { path: 'gerenciamento/patrimonio/historico-patrimonio', component: DetalhePatrimonioComponent },
      { path: 'gerenciamento/patrimonio/historico-patrimonio-inativo', component: DetalhePatrimonioInativoComponent },
      { path: 'gerenciamento/patrimonio/listagem-patrimonio-inativos', component: ListagemPatrimonioInativoComponent },
      { path: 'gerenciamento/compras/solicitacao-compras', component: PedidoCompraComponent },
      { path: 'gerenciamento/compras/listagem-sollicitcao-compras', component: ListagemSolicitacaoComprasComponent },
      { path: 'gerenciamento/compra/historico-solicitacao', component: DetalheSollicitacaoCompraComponent },
      { path: 'gerenciamento/cursos/curso-para-casais/listagem-cadastros', component: ListagemCursoCasaisComponent },
      { path: 'gerenciamento/cursos/curso-para-casais/detalhe-cadastros-casal', component: DetalheCursoCasaisComponent },
      { path: 'gerenciamento/deducao-compras/vincular-responsavel', component: VincularResponsavelComponent },
      { path: 'gerenciamento/deducao-compras/listagem-todas-deducoes', component: ListagemTodasDeducoesComponent },
      { path: 'gerenciamento/deducao-compras/listagem-deducoes-compras', component: ListagemDeducoesComprasComponent },
      { path: 'gerenciamento/deducao-compras/registrar-compra', component: RegistrarCompraComponent },
      { path: 'listagem/listagem-batismo', component: ListagemBatismoComponent }







    ]
  },
  {
    path: '**', component: NotFoundComponent
  }




];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
