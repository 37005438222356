export class centrocustomodel {

    cpf: string = '';
    nome:string='';
    pastor_presidente: boolean = false;
    pastor_auxiliar: boolean = false;
    ministerio_consolidacao: boolean = false;
    ministerio_obreiro: boolean = false;
    ministerio_diaconato: boolean = false;
    ministerio_louvor: boolean = false;
    ministerio_secretaria: boolean = false;
    ministerio_casais: boolean = false;
    ministerio_jovem: boolean = false;
    ministerio_mulheres: boolean = false;
    ministerio_homens: boolean = false;
    ministerio_danca: boolean = false;
    ministerio_patrimonio: boolean = false;
    ministerio_infantil: boolean = false;
    ministerio_midias: boolean = false;
    ministerio_eventos: boolean = false;
    ministerio_sonoplastia: boolean = false;
    ministerio_geral: boolean = false;
}

