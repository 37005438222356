import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { ReclamacaoService } from '../reclamacao/reclamacao.service';
import { ReclamacaoModel } from '../reclamacao/reclamacaomodel';

@Component({
  selector: 'app-reclamacao',
  templateUrl: './reclamacao.component.html',
  styleUrls: ['./reclamacao.component.css']
})
export class ReclamacaoComponent implements OnInit {
  rota: Router;
  modelreclamacao: ReclamacaoModel;
  formreclamacao: FormGroup;
  startedClass = false;
  completedClass = false;
  preventAbuse = false;
  receber_copia: boolean;

  cbtiporeclamacao: any = [
    'ADMINISTRAÇÃO',
    'PASTOR(A)', 'DIACONO(A)', 'GESTÃO', 'MINISTRAÇÃO'
    , 'ESTACIONAMENTO', 'OFENSA', 'HORARIO', 'HIGIENE / LIMPEZA'];
  submitted: boolean = false;


  constructor(
    private formBuilder: FormBuilder,
    private alert: AlertModalService,
    private reclamacaoservice: ReclamacaoService,
    private router: Router,
    private autenticado: SharedModule,
  ) {
    this.rota = this.router;
    this.modelreclamacao = new ReclamacaoModel();

  }

  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }

  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }

  reset() {
    this.modelreclamacao = new ReclamacaoModel();
    this.submitted = false;
    this.formreclamacao.get('email').setValue(this.autenticado.email);
    this.formreclamacao.get('idusuario').setValue(this.autenticado.iduser);
    this.formreclamacao.get('nome').setValue(this.autenticado.nome);
    this.formreclamacao.get('cpf').setValue(this.autenticado.cpf);
  }
  get f() {
    return this.formreclamacao.controls;
  }

  get cityNames() {
    return this.formreclamacao.get('tipo_reclamacao');
  }

  changereclamacao(e) {
    this.cityNames.setValue(e.target.value, {
      onlySelf: true,
    });
  }

  ReceberCopia(e) {
    if (e.target.checked == true && this.autenticado.email == '') {
      this.receber_copia = false;
      this.alert.AlertShowDanger(':: AVISO ::\nPara que possa receber uma cópia da Reclamação e necessário ter e-mail valido em seu cadastro! \nPor favor preencha um e-mail valido em seu Perfil e tente novamente!');
    }

  }

  //BOTAO SALVAR
  //====================================================================//
  onSubmit() {
    this.submitted = true;
    if (this.formreclamacao.invalid) {
      this.alert.AlertShowDanger('Favor verificar os campos em destaque!');
      return;
    }
    else if (this.formreclamacao.get('telefone').value.length < 10) {
      this.alert.AlertShowDanger('Favor Preencher Telefone corretamente');
    } else {
      this.reclamacaoservice
        .CadastrarReclamacao(this.autenticado.token, this.formreclamacao.value)
        .subscribe(
          (sucess) => {
            this.StopProgressBar();
            this.alert.AlertShowSucess('Reclamação Enviado com Sucesso');
            this.criarform();
            this.submitted = false;
            this.formreclamacao.get('email').setValue(this.autenticado.email);
            this.formreclamacao.get('idusuario').setValue(this.autenticado.iduser);
            this.formreclamacao.get('nome').setValue(this.autenticado.nome);
            this.formreclamacao.get('cpf').setValue(this.autenticado.cpf);
          },
          (error) => {
            this.StopProgressBar();
            this.alert.AlertShowDanger('( ' + error.status + ' ) ' + error.error);
          }
        );
    }
  }

  //====================================================================//
  ngOnInit() {
    if (this.autenticado.token == null) {
      this.autenticado.ResetDados();
      this.alert.AlertShowDanger('Usuário não autenticado, redirecionando...')
      this.rota.navigate(['/login']);
    } else {
      if (this.autenticado.ValidarToken() != true) {
        this.autenticado.ResetDados();
        this.alert.AlertShowDanger('Token Invalido!')
        this.rota.navigate(['/login']);
      } else {
        try {
          this.modelreclamacao = new ReclamacaoModel();
          this.criarform();
          this.modelreclamacao.cpf = this.autenticado.cpf;
          this.modelreclamacao.nome = this.autenticado.nome;
          this.formreclamacao.get('email').setValue(this.autenticado.email);
          this.formreclamacao.get('idusuario').setValue(this.autenticado.iduser);
          this.formreclamacao.get('nome').setValue(this.autenticado.nome);
          this.formreclamacao.get('cpf').setValue(this.autenticado.cpf);
        } catch {
          this.rota.navigate(['/dashboard/resumo']);
        }
      }
    }
  }
  //====================================================================//
  criarform() {
    this.formreclamacao = this.formBuilder.group({
      cpf: ['', Validators.required],
      nome: ['', Validators.required],
      email: [''],
      telefone: ['', Validators.required],
      tipo_reclamacao: ['', Validators.required],
      mensagem: ['', [Validators.required, Validators.min(20), Validators.max(9000)]],
      receber_copia: [false],
      dt_ocorrido: ['', Validators.required],
      idusuario: [0, Validators.required]
    });
  }

}
