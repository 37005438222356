import { EventEmitterService } from './shared/EventEmitterService';

import { Component } from '@angular/core';
import { SharedModule } from './shared/shared.module';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  title = 'Morada de Deus';

  Autenticado: boolean = true;
  constructor(
    private IS:SharedModule
  ) {
    //Verificar se cliente autenticado para abrir as Divs corretas
    this.IS.ResetDados();
    EventEmitterService.get('loginAlert').subscribe(data => this.Autenticado = data);

  }

  //Destruir autenticação após login
  ngOnDestroy() {
    EventEmitterService.get('loginAlert').unsubscribe();
  }

}
