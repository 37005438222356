import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { Parametros } from 'src/app/shared/parametros.enum';
import { ParametrosModel } from '../../configuracaoes/ParametrosModel';
import{congressohomensmodel} from './CongressoHomensModel';
import{CongressoHomensService} from './congresso-homens.service';
import { EventEmitterService } from 'src/app/shared/EventEmitterService';

@Component({
  selector: 'app-cadastro-congresso-homens',
  templateUrl: './cadastro-congresso-homens.component.html',
  styleUrls: ['./cadastro-congresso-homens.component.css']
})
export class CadastroCongressoHomensComponent implements OnInit {

  

  formculto: FormGroup;
  submitted = false;
  cadastroculto: congressohomensmodel;
  parametros: ParametrosModel;
  modaltitulo: string = '';
  modalconteudo: string = '';
  marked: boolean;
  bt_termo: boolean;

  startedClass = false;
  completedClass = false;
  preventAbuse = false;
  rota: Router;
  dados_culto: string = "";
  mostrar: string = "T";
  permitido: boolean = true;
  tipo: string = "";
  limite: number = 0;
  private urlcadastro = Parametros.CONGRESSOHOMENS;


  congressos: any = [
    'CONGRESSO DE HOMENS - HOMENS NO CENTRO DA VONTADE DE DEUS'
  ];

  membromorada: any = [
    'SIM',
    'NÃO'];

  classificacao: any = [
    'MEMBRO(A)',
    'PASTOR(A)',
    'VISITANTE',
    'OUTRO(A)'];

  documentos: any = [
    'CPF',
    'RG'];

  ficousabendo: any = [
    'INSTAGRAM',
    'FACEBOOK',
    'WHATSAPP',
    'INDICAÇÃO DE AMIGO(A)',
    'CULTO',
  ];

  luf: any = [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MT',
    'MS',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO',
  ];
  habilitado: boolean = true;

  constructor(
    private formBuilder: FormBuilder,
    private alert: AlertModalService,
    private service: CongressoHomensService,
    private router: Router
  ) {
    this.rota = this.router;
    EventEmitterService.get('loginAlert').emit(false);
    this.mostrar = "T";
    this.LimiteCulto();

  }

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//

  criarform() {
    this.formculto = this.formBuilder.group({
      cpf: ['', [Validators.required, Validators.maxLength(14), Validators.minLength(8)]],
      nome: ['', Validators.required],
      congresso: ['', Validators.required],
      ficou_sabendo: ['', Validators.required],
      tipo_documento: ['', Validators.required],
      ministerio_morada: ['', Validators.required],
      telefone: ['', Validators.required],
      ministerio_pertence: ['', Validators.required],
      classificacao: ['', Validators.required],
      uf: ['', Validators.required],
      logradouro: ['', Validators.required],
      bairro: ['', Validators.required],
      cidade: ['', Validators.required],
      numero: ['', Validators.required],
      marked: ['', Validators.requiredTrue],
      email: ['', [Validators.required,Validators.email]],
      dados_culto: [''],
      cep: [''],
      apto: [''],
      duvida: ['']
    });
  }



  LimiteCulto() {
    this.service.LimiteCadastro().subscribe(
      (success) => {
        this.limite = success;
        if (success == 0) {
          this.modaltitulo = 'Cadastro Bloqueado!';
          this.modalconteudo =
            'Olá!<br>' +
            'Pedimos desculpas mas o cadastro encontra-se bloqueado!<br>' +
            'Em caso de dúvidas por favor procure o <b>Obreiro Anderson ou Obreiro Luiz</b> para esclarecimentos!<br>' +
            'Atenciosamente!<br><b>Gestão Morada de Deus ( IEPMD )</b>';
          document.getElementById('openModalButton').click();
          document.getElementById('cpf').focus();
          this.bt_termo  = false;
          this.formculto.disable();
          this.habilitado = false;
          return;
        }

      }, (error) => {
        this.limite = 0;
      });


  }


  Tipo(event: any) {
    this.tipo = event.target.value.replace("1: ", "").replace("2: ", "").replace(" ", "");
    this.formculto.controls['cpf'].setValue("");

    if (this.formculto.controls['tipo_documento'].value != "") {
      this.formculto.controls['cpf'].enable();
      document.getElementById('cpf').focus();
    } else {
      this.formculto.controls['cpf'].disable();
      document.getElementById('tipo_documento').focus();
    }
  }

  //Caso discord do termo no modal
  //====================================================================//
  DiscordeTermo() {
    if (this.marked == true) {
      this.marked = false;
    }
  }
  //====================================================================//

  //Abrir modal quando clicar no aceite para exibir os termos
  //====================================================================//
  AceiteTermo(e) {
    if (e.target.checked == true) {
      this.marked = e.target.checked;
      this.service.GetTermo('Congresso-homens').subscribe(
        (success) => {
          this.StopProgressBar();
          this.modalconteudo = success.termo;
          this.bt_termo = true;
          this.modaltitulo = 'Termo de Cadastro';
          document.getElementById('openModalButton').click();
        },
        (error) => {
          this.StopProgressBar();
          if (error.status == '400') {
            this.alert.AlertShowDanger(
              'Termo não definido!,solicitamos que não continue e avise a gestão do site, OBS: Continuando confirma está de acordo com toda politica do site!')
          } else {
            this.alert.AlertShowDanger(
              'Termo não definido!,solicitamos que não continue e avise a gestão do site, OBS: Continuando confirma está de acordo com toda politica do site!'
            );
          }
        }
      );
    }
  }
  //====================================================================//


  //BOTAO SALVAR
  //====================================================================//
  onSubmit() {
    this.submitted = true;
    if (this.habilitado == false) {
      return;
    }
    this.cadastroculto.dados_culto = this.dados_culto;
    if (this.tipo == 'RG' && (this.formculto.controls['cpf'].value).length < 8) {
      this.alert.AlertShowDanger('RG Incorreto');
      document.getElementById('cpf').focus();
      this.formculto.controls['cpf'].setValue("");
      return;
    }

    if (this.tipo == 'RG' && (this.formculto.controls['cpf'].value).length > 8) {
      this.alert.AlertShowDanger('RG Incorreto');
      document.getElementById('cpf').focus();
      this.formculto.controls['cpf'].setValue("");
      return;
    }

    if (this.tipo == 'CPF' && (this.formculto.controls['cpf'].value).length < 11) {
      this.alert.AlertShowDanger('CPF Incorreto');
      document.getElementById('cpf').focus();
      this.formculto.controls['cpf'].setValue("");
      return;
    }

    if (this.formculto.invalid) {
      this.alert.AlertShowDanger('Favor verificar os campos em destaque!');
      return;
    } else {
      this.service.QuantidadeCadastro(this.formculto.controls['congresso'].value).subscribe(
        (success) => {
          var c = success;

          if (this.limite == 0) {
            this.modaltitulo = 'Cadastro Bloqueado!';
            this.modalconteudo =
              'Olá!<br>' +
              'Pedimos desculpas mas o cadastro encontra-se bloqueado!<br>' +
              'Em caso de dúvidas por favor procure o <b>Obreiro Anderson ou Obreiro Luiz </b> para esclarecimentos!<br>' +
              'Atenciosamente!<br><b>Gestão Morada de Deus ( IEPMD )</b>';
            document.getElementById('openModalButton').click();
            document.getElementById('cpf').focus();
            this.formculto.disable();
            return;
          }
          if (c >= this.limite) {
            this.modaltitulo = 'Cadastro Esgotado!';
            this.modalconteudo =
              'Olá!<br>' +
              'Pedimos desculpas mas atendendo ao decreto e mantendo a segurança o limite para participar do congresso de Homens foi atingido!<br>' +
              'Em caso de dúvidas por favor procure o <b>Obreiro Anderson ou Obreiro Luiz </b> para esclarecimentos!<br>' +
              'Atenciosamente!<br><b>Gestão Morada de Deus ( IEPMD )</b>';
            document.getElementById('openModalButton').click();
            document.getElementById('cpf').focus();
            return;
          }

        });

      this.service
        .CadastroCulto(this.formculto.value)
        .subscribe(
          () => {
            this.StopProgressBar();
            this.submitted = false;
            this.alert.AlertShowSucess('Registro Realizado Com sucesso!');
            localStorage.setItem('tipo', 'CongressoHomens');
            localStorage.setItem('CongressoHomens-save', JSON.stringify(this.formculto.value));
            this.rota.navigate(['/retorno']);
          },
          (error) => {
            this.StopProgressBar();
            this.permitido = false;
            if (error.status == '200') {
              this.StopProgressBar();
              this.submitted = false;
              this.alert.AlertShowSucess('Registro Realizado Com sucesso!');
              localStorage.setItem('tipo', 'CongressoHomens');
              localStorage.setItem('CongressoHomens-save', JSON.stringify('SEM EMAIL'));
              this.rota.navigate(['/retorno']);
            }
            if (error.status == '422') {
              this.permitido = false;
              this.dados_culto = "";
              this.cadastroculto = new congressohomensmodel();
              this.modaltitulo = 'Cadastro Existente!';
              this.modalconteudo =
                'Olá! <b>' + error.error.nome
                + '</b>,<br>Estava olhando aqui em minhas anotações e verifiquei que já existe seu cadastro em nossa plataforma para o Congresso [ <b>' + error.error.tipo_culto + '</b> ], em caso de duvidas não deixe de nos reportar para que possamos analisar e lhe retornar!\nDeus Abençõe!';
              document.getElementById('openModalButton').click();
              document.getElementById('cpf').focus();

            } else if (error.status == '500') {
              this.permitido = false;
              this.dados_culto = "";
              this.alert.AlertShowDanger(error.error);

            } else {
              this.permitido = true;
              if (error.status == '200') {

              } else {
                this.alert.AlertShowDanger(error.error);
              }
              document.getElementById('nome').focus();

            }
          }
        );
    }
    //====================================================================//
  }
  //====================================================================//

  //recarregar Form
  //====================================================================//
  reset() {
    this.cadastroculto = new congressohomensmodel();
    this.submitted = false;
    this.mostrar = "F";
    document.getElementById('cpf').focus();
  }

  // Pegar nome dos campos,
  //====================================================================//
  get f() {
    return this.formculto.controls;
  }

  //Inicio
  //====================================================================//
  ngOnInit() {
    this.cadastroculto = new congressohomensmodel();
    this.criarform();
    this.formculto.controls['ministerio_morada'].setValue("1: SIM");
    this.formculto.controls['numero'].setValue("0");
    this.formculto.controls['apto'].setValue("0");
    document.getElementById('cpf').focus();

    if (this.formculto.controls['tipo_documento'].value == "") {
      this.formculto.controls['cpf'].disable();
    }


  }

  quantidadecadastro(event) {


    this.service.QuantidadeCadastro(event).subscribe(
      (success) => {
        var c = success;

        if (c >= this.limite) {
          this.modaltitulo = 'Cadastro Esgotado!';
          this.modalconteudo =
            'Olá!<br>' +
            'Pedimos desculpas mas atendendo ao decreto e mantendo a segurança o limite para participar do congresso de Homens foi atingido!<br>' +
            'Em caso de dúvidas por favor procure o Obreiro Anderson ou Obreiro Luiz para esclarecimentos!<br>' +
            'Atenciosamente!<br><b>Gestão Morada de Deus ( IEPMD )</b>';
          document.getElementById('openModalButton').click();
          document.getElementById('cpf').focus();
          this.bt_termo =false;
          return;
        }

      });

  }


  //Consulta o cadastro do condominio já existe na base de dados ( API )
  //====================================================================//
  consultacpf(cpf: any) {
    if (this.cadastroculto.cpf.length >= 8) {
      this.service.Consulta(this.cadastroculto.cpf).subscribe(
        (success) => {
          this.StopProgressBar();
       
          this.cadastroculto = new congressohomensmodel();
          this.bt_termo = false;
          this.modaltitulo = 'Cadastro já Existente!';
          this.modalconteudo =
            'Olá <b>' + success.nome.toUpperCase()
            + '</b>,<br>Estava olhando aqui em minhas anotações e verifiquei que já existe seu cadastro em nossa plataforma!, Pedimos por favor que verifique seu e-mail ou qualquer duvida entre em contato com <b>Obreiro Anderson ou Obreiro Luiz </b>!<br>Deus Abençõe!<br><b>Att.<br>Morada de Deus</b>';
          document.getElementById('openModalButton').click();
          this.mostrar = "F";         
        },
        (error) => {
          this.StopProgressBar();
          (this.cadastroculto.cep = ""),
            (this.cadastroculto.logradouro = ""),
            (this.cadastroculto.bairro = ""),
            (this.cadastroculto.cidade = ""),
            (this.cadastroculto.uf = ""),
            (this.cadastroculto.telefone = ""),
            (this.cadastroculto.nome = ""),
            (this.cadastroculto.email = "");
          (this.cadastroculto.ficou_sabendo = "");
          (this.cadastroculto.ministerio_morada = "");
          (this.cadastroculto.ministerio_pertence = "");
          this.mostrar = "T";
          document.getElementById('nome').focus();
        }
      );
    }
  }

  //====================================================================//

  //CONSULTA CEP API
  //====================================================================//
  consulta_cep(cep: any) {
    if (cep != '' && cep != null) {
      this.service
        .ConsultaCEP(
          cep
            .replace('.', '')
            .replace('-', '')
            .replace('\\', '')
            .replace('/', '')
        )
        .subscribe(
          (success) => {
            if (success.cep != null) {
              (this.cadastroculto.cep = success.cep),
                (this.cadastroculto.logradouro = success.logradouro),
                (this.cadastroculto.bairro = success.bairro),
                (this.cadastroculto.cidade = success.localidade),
                (this.cadastroculto.uf = success.uf);
              document.getElementById('numero').focus();
            } else {
              this.alert.AlertShowDanger('CEP não e válido!');
              this.cadastroculto.cep = '';
            }
          },
          (error) => {
            this.StopProgressBar();
            this.alert.AlertShowDanger(
              '( ' + error.status + ' ) ' + error.message
            );
          }
        );
    }
  }

  //====================================================================//
  //PEGAR VALOR DIZIMISTA FORM
  //====================================================================//
  get citymembromorada() {
    return this.formculto.get('ministerio_morada');
  }
  //====================================================================//
  // Pegar o valor do combobox DIZIMISTA
  //====================================================================//
  changeministeriomorada(e) {
    console.log(e.name);
    this.citymembromorada.setValue(e.target.value, {
      onlySelf: true,
    });

    if (e.target.value.includes("SIM")) {
      this.mostrar = "F";
      this.cadastroculto.ministerio_pertence = "IEPMD - Igreja Evangelica Pentecostal Morada de Deus."
    } else {
      this.mostrar = "T";
      this.cadastroculto.ministerio_pertence = "";
    }
  }
  //====================================================================//

}
