<div class="col-md text-justify bold" style="align-content: flex-start; font-size: large; font-style: oblique;">
  <label>Olá!<br>Seja bem-vindo(a)!<br>Você está no banco de fotos de todos os cultos e eventos de nossa
    igreja!</label>
  <label>Lembramos que qualquer utilização de fotos aqui publicadas por terceiros ou fora do site da plataforma da
    morada de Deus e suas redes sociais e de inteira responsabilidade do publicante!, não tendo a morada de Deus
    qualquer vinculo ou responsabilidade.</label>
  <label>As fotos são mantidas por aproximadamente 5 dias em nosso acervo, sendo removidas sem aviso prévio podendo
    este prazo ser maior ou menor conforme necessidade detectada pela Equipe da Morada de Deus.</label>
  <label>Em caso de não conformidade de sua foto por favor nos comunique para remoção da mesma! <a
    href="https://api.whatsapp.com/send?l=pt_BR&phone=55032988189440&text=Remoção de Foto ( Acervo de fotos )"
    style="text-align: right; float: right;" title="Solicitar Remoção" style="color: red;" target="_blank">
    Solicitar Remoção</a></label>

<!--
  <p-accordion>
    <p-accordionTab header="Fevereiro 2023">

      <p-accordion>
        <label><a href="https://drive.google.com/drive/folders/1fqTiVxToDH4u7N4KKyFGEgqbaFeJss8u"
                  target="_blank">13 - Ceia do Senhor 19H </a></label>
      </p-accordion>


      <p-accordion>
        <label><a href="https://drive.google.com/drive/folders/1K88rp8yvYqoe0pvI87Ah7FtCOw4N9C_v"
                  target="_blank">14 - Reunião de Mulheres [Rede Filhas de Leão] 19:30H </a></label>
      </p-accordion>


    </p-accordionTab>


    <p-accordionTab header="Março 2023">

      <p-accordion>
        <label><a href="https://drive.google.com/drive/folders/1-1pSDR7efLXmPfTCn3aSerW_WJUTvYlO"
                  target="_blank">04 - Reunião de Meulheres [Dia das Mulheres] 14:30H </a></label>
      </p-accordion>


      <p-accordion>
        <label><a href="https://drive.google.com/drive/folders/1Z-XahQHMdmJLYpnS57AulN94T7oJNFqk"
                  target="_blank">05 - Culto de Domingo 19H </a></label>
      </p-accordion>


    </p-accordionTab>

  </p-accordion>
  -->

  <P>Todas fotos podem ser vistas em nosso Instagram <a href="https://www.instagram.com/moradajfoficial/">@Moradajfoficial</a></P>

</div>





<div class="col-md" style="padding-left: 20px; padding-top: 10px;padding-bottom: 10px;">
  <button pButton pRipple type="button" icon="pi pi-home" style="padding: 0%;" class="p-button-rounded p-button-help"
          title="::HOME::" routerLink=""></button>
</div>
