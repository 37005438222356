import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { EditarOportunidadeService } from './editar-oportunidade.service';
import { OportunidadeModel } from '../cadastro-oportunidade/OportunidadeModel';

@Component({
  selector: 'app-editar-oportunidade',
  templateUrl: './editar-oportunidade.component.html',
  styleUrls: ['./editar-oportunidade.component.css']
})
export class EditarOportunidadeComponent implements OnInit {

  formcadastro: FormGroup;
  submitted = false;
  cadastromembros: OportunidadeModel;

  modaltitulo: string = '';
  modalconteudo: string = '';
  marked: boolean = false;
  ativo: boolean = true;

  bt_termo: boolean;

  lsexo: any = [
    'AMBOS',
    'MASCULINO',
    'FEMININO'
  ];

  luf: any = [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MT',
    'MS',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO',
  ];
  startedClass = false;
  completedClass = false;
  preventAbuse = false;
  rota: Router;
  habilitado: boolean = false;
  isDisabled = true;
  id: string;


  constructor(
    private formBuilder: FormBuilder,
    private _serviceestabelecimento: EditarOportunidadeService,
    private alert: AlertModalService,
    private router: Router,
    private autenticado: SharedModule,
  ) {
    this.rota = this.router;

  }

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//

  //seta os campos para inicio do form
  //====================================================================//

  criarform() {
    this.formcadastro = this.formBuilder.group({
      idoportunidade:[''],
      cpf: [''],
      idusuario: [0],
      nome: [''],
      oportunidade: ['', Validators.required],
      telefone: ['', Validators.required],
      celular: [''],
      email: [''],
      site: [''],
      facebook: [''],
      instagram: [''],
      maps: [''],
      cep: [''],
      logradouro: ['', Validators.required],
      numero: [
        '',
        [Validators.required]
      ],
      quantidade: ['', [Validators.required, Validators.min(1)]],
      apto: [''],
      bairro: ['', Validators.required],
      sexo: ['', Validators.required],
      uf: ['', Validators.required],
      cidade: ['', Validators.required],
      informacoes: ['', Validators.required],
      tag: ['', Validators.required],
      funcionamento: ['', Validators.required],
      ativo: ['']
    });
  }
  //====================================================================//

  //Caso discord do termo no modal
  //====================================================================//
  DiscordeTermo() {
    if (this.marked == true) {
      this.marked = false;
    }
  }
  //====================================================================//


  //====================================================================//
  CKAtivo(e) {
    if (e.target.checked == true) {
      this.formcadastro.get("ativo").setValue(true);
      this.ativo = true;
    } else {
      this.formcadastro.get("ativo").setValue(false);
      this.ativo = false;
    }
  }

  //====================================================================//
  //PEGAR VALOR UF FORM
  //====================================================================//
  get cityNames() {
    return this.formcadastro.get('uf');
  }
  //====================================================================//
  // Pegar o valor do combobox UF
  //====================================================================//
  changeCity(e) {
    console.log(e.value);
    this.cityNames.setValue(e.target.value, {
      onlySelf: true,
    });
  }


  //====================================================================//
  //PEGAR VALOR UF FORM
  //====================================================================//
  get citySexo() {
    return this.formcadastro.get('sexo');
  }
  //====================================================================//
  // Pegar o valor do combobox UF
  //====================================================================//
  changeSexo(e) {
    console.log(e.value);
    this.citySexo.setValue(e.target.value, {
      onlySelf: true,
    });
  }


  //Inicio
  //====================================================================//
  ngOnInit() {
    if (this.autenticado.token == null) {
      this.autenticado.ResetDados();
      this.alert.AlertShowDanger('Usuário não autenticado, redirecionando...')
      this.rota.navigate(['/login']);
    } else {
      if (this.autenticado.ValidarToken() != true) {
        this.autenticado.ResetDados();
        this.alert.AlertShowDanger('Token Invalido!')
        this.rota.navigate(['/login']);
      }
    }

    this.id = localStorage.getItem('OportunidadeDetalhe');
    if (this.id == null || this.id == "") {
      this.rota.navigate(['/dashboard/listagem-oportunidade']);
    } else {

      this.cadastromembros = new OportunidadeModel();
      this.criarform();
      this._serviceestabelecimento
        .GETOportunidadeID(this.id)
        .subscribe(
          (success) => {
            this.formcadastro.enable();
            this.formcadastro.get('cpf').disable();
            this.formcadastro.get('nome').disable();
            this.formcadastro.get('cpf').setValue(this.autenticado.cpf);
            this.formcadastro.get('nome').setValue(this.autenticado.nome);

            //================================================================
            this.cadastromembros.oportunidade = success[0].oportunidade;
            this.formcadastro.get('idoportunidade').setValue(success[0].idoportunidade);
            this.cadastromembros.telefone = success[0].telefone;
            this.cadastromembros.cpf = success[0].cpf;
            this.cadastromembros.nome = this.autenticado.nome;
            this.cadastromembros.celular = success[0].celular;
            this.cadastromembros.email = success[0].email;
            this.cadastromembros.cep = success[0].cep;
            this.cadastromembros.logradouro = success[0].logradouro;
            this.cadastromembros.numero = success[0].numero;
            if (success[0].apto == null) {
              this.cadastromembros.apto = 0;
            } else {
              this.cadastromembros.apto = success[0].apto;
            }
            this.cadastromembros.bairro = success[0].bairro;
            this.cadastromembros.quantidade = success[0].quantidade;
            this.cadastromembros.sexo = success[0].sexo;
            this.cadastromembros.cidade = success[0].cidade;
            this.cadastromembros.uf = success[0].uf;
            this.cadastromembros.site = success[0].site;
            this.cadastromembros.facebook = success[0].facebook;
            this.cadastromembros.instagram = success[0].instagram;
            this.cadastromembros.maps = success[0].maps;
            this.cadastromembros.tag = success[0].tag;
            this.cadastromembros.funcionamento = success[0].funcionamento;
            this.cadastromembros.informacoes = success[0].informacoes;
            if(success[0].ativo=='T'){
              this.cadastromembros.ativo=true;
            }else{
              this.cadastromembros.ativo=false;
            }

            //===============================================================

            this.cadastromembros.cpf = success.cpf;
            this.cadastromembros.nome = success.nome;
            this.formcadastro.get('cpf').setValue(success.cpf);
            this.formcadastro.get('nome').setValue(success.nome);
            document.getElementById('oportunidade').focus();
          },
          (error) => {

            this.rota.navigate(['/dashboard/listagem-oportunidade']);

          }
        )
    }



  }
  //====================================================================//


  //CONSULTA CEP API
  //====================================================================//
  consulta_cep(cep: any) {
    if (cep != '' && cep != null) {
      this._serviceestabelecimento
        .ConsultaCEP(
          cep
            .replace('.', '')
            .replace('-', '')
            .replace('\\', '')
            .replace('/', '')
        )
        .subscribe(
          (success) => {
            if (success.cep != null) {
              (this.cadastromembros.cep = success.cep),
                (this.cadastromembros.logradouro = success.logradouro),
                (this.cadastromembros.bairro = success.bairro),
                (this.cadastromembros.cidade = success.localidade),
                (this.cadastromembros.uf = success.uf);
              document.getElementById('numero').focus();
            } else {
              this.alert.AlertShowDanger('CEP não e válido!');
              this.cadastromembros.cep = '';
            }
          },
          (error) => {
            this.StopProgressBar();
            this.alert.AlertShowDanger(
              '( ' + error.status + ' ) ' + error.message
            );
          }
        );
    }
  }
  //====================================================================//




  // Pegar nome dos campos,
  //====================================================================//
  get f() {
    return this.formcadastro.controls;
  }
  //====================================================================//

  //BOTAO SALVAR
  //====================================================================//
  onSubmit() {
    this.submitted = true;
    if (this.formcadastro.invalid) {
      this.alert.AlertShowDanger('Favor verificar os campos em destaque!');
      return;
    }
    if (this.formcadastro.status == 'DISABLED') {
      return;
    }
    this.formcadastro.get('cpf').setValue(this.autenticado.cpf);
    this.formcadastro.get('nome').setValue(this.autenticado.nome);
    this._serviceestabelecimento
      .UpdateOportunidade(this.formcadastro.value, this.autenticado.token)
      .subscribe(
        (sucess) => {
          this.StopProgressBar();
          this.submitted = false;
          this.alert.AlertShowSucess('Oportunidade Atualizada com sucesso!');
          this.rota.navigate(['/dashboard/listagem-oportunidade']);
        },
        (error) => {
          this.StopProgressBar();
          this.submitted = false;
          if (error.status == 422) {
            this.alert.AlertShowDanger('Já existe uma oportunidade com o nome informado!');
          } else {
            this.alert.AlertShowDanger('Erro ao tentar atualizar oportunidade');
          }
        }
      );
    //====================================================================//
  }
  //====================================================================//

}