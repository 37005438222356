<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>


 <div class="col-md-12" style="margin-top: 7px;">

        <div>
            <h4 style="text-align: center;">
                <i class="fas fa-check"></i>&nbsp;<b>{{filtro_cabecalho}}</b>
            </h4>
            <p>
                &nbsp;&nbsp;&nbsp;&nbsp;{{explicacao}}
                <br>
                &nbsp;&nbsp;&nbsp;&nbsp;<b>#FicaaDica: </b>Em caso de Celular melhor visualizado na horizontal!
            </p>
        </div>
    </div>
    <br>
    <div class="row">
    <!--Filtro-->
    <div class="col-md-12" style="display: block; border: 0px;padding-bottom: 0%; ">
        <label style=" display: block; border: 0px; padding-bottom: 0%;"><b
                style="padding: 0%; padding-bottom: 0%;">Filtro:</b>
            <span style=" display: block; border: 0px; font-size: x-small; padding-top: 0%;">Consulta por nome</span>
            <input type="text" class="form-control" id="texto_consulta" style="margin-top: 0%;" />
        </label>
    </div>

    <!--Tabela-->
    <div class="panel-body col-md-12">
        <p-table [value]="oportunidade" [columns]="cols" [paginator]='false' [rows]="10" [showCurrentPageReport]="true"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" selectionMode="single"
            paginatorTemplate="{RowsPerPageDropdown} {FirstPageLink} {PreviousPageLink} {CurrentPageReport} {NextPageLink} {LastPageLink}"
            styleClass="p-datatable-responsive-demo" autoLayout="true" [responsive]="true">

            <ng-template pTemplate="header" let-oportunidade let-columns>
                <tr>
                    <th *ngFor="let col of columns">
                        {{col.header}}
                    </th>
                  
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-c>
                <tr>
                    <td>
                        <span class="p-column-title">Data Cadastro</span>
                        {{c.dt_cadastro | date: 'dd/MM/yyyy'}}
                    </td>
                    <td>
                        <span class="p-column-title">Oportunidade</span>
                        {{c.oportunidade}}
                    </td>
                    <td>
                        <span class="p-column-title">Telefone</span>
                        {{c.telefone}}
                    </td>
                    <td>
                        <span class="p-column-title">Ativa</span>
                        {{c.ativo.replace("T","SIM").replace("F","NÃO")}}
                    </td>
                    <td>
                        <span class="p-column-title">Bloqueada</span>
                        {{c.bloqueada.replace("T","SIM").replace("F","NÃO")}}
                    </td>

                    <td>
                        <span class="p-column-title">Excluir</span>
                        <button pButton pRipple type="button" icon="pi pi-trash" class="p-button-rounded"
                            style="background-color: red;" styleClass="rounded-button ui-button-danger"
                            value={{c.idoportunidade}} {{idvisita}}={{c.idoportunidade}} (click)="SetDados($event)"
                            data-toggle="modal" data-target="#exampleModal1" title="Exluir Oportunidade"></button>
                    </td>

                    <td>
                        <span class="p-column-title">Editar</span>
                        <button pButton pRipple type="button" icon="pi pi-pencil" class="p-button-rounded"
                            style="background-color: green;" styleClass="rounded-button ui-button-success"
                            value={{c.idoportunidade}} (click)="VisualizarDetalhe($event)"
                            title="Visualizar Detalhe"></button>
                    </td>
                </tr>
            </ng-template>
        </p-table>     
        <hr>
        <!--Botao Novo-->
        <button pButton pRipple type="button" icon="pi pi-plus-circle" title="Novo"
            class="p-button-rounded p-button-success" routerLink="/dashboard/cadastrar-oportunidade"
            style="margin-right: 10px;"></button>
        <!--Botao Retorno-->
        <button pButton pRipple type="button" icon="pi pi-sign-in" title="Retornar"
            class="p-button-rounded p-button-danger" routerLink="/dashboard/resumo"></button>
        <br>
        <br>
        <br>
    </div>

    <!-- Modal -->
    <div id="exampleModal" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Gerar Documento para Impressão</h4>
                </div>
                <div class="modal-body">
                    <p>Por favor escolha a opção correta para geração do PDF para impressão!</p>
                    <br>
                    <div style="font-size: medium;">
                        <input id="novo" name="base" type="radio" value="Filtro:Todos" checked
                            (click)="ValorImpressao($event)" /> Todos Registros ( {{qto}} )
                    </div>
                    <div style="font-size: medium;">
                        <input id="novo" name="base" type="radio" value="Filtro:TodosAbertos"
                            (click)="ValorImpressao($event)" /> Todos Registros não Lidos
                    </div>
                    <div style="font-size: medium;">
                        <input id="novo" name="base" type="radio" value="Filtro:TodosFechados"
                            (click)="ValorImpressao($event)" /> Todos Registros Lidos
                    </div>
                    <div *ngIf="textoconsulta !='' ">
                        <div style="font-size: medium;">
                            <input id="ant" name="base" type="radio" value="Filtro:{{textoconsulta}}"
                                (click)="ValorImpressao($event)" /> Filtro Realizado: ( {{textoconsulta}})
                        </div>
                    </div>
                    <div *ngIf="textoconsulta !='' ">
                        <div style="font-size: medium;">
                            <input id="ant" name="base" type="radio"
                                value="FiltroPagina:{{textoconsulta}} and limit 10 offset {{pagina_atual}}"
                                (click)="ValorImpressao($event)" /> Filtro Realizado e Pagina: ( {{textoconsulta}} e
                            Pagina ( {{pagina_atual}} ))
                        </div>
                    </div>
                    <div style="font-size: medium;">
                        <input id="grade" name="base" type="radio" value="Pagina:{{pagina_atual}}"
                            (click)="ValorImpressao($event)" /> Pagina Atual ( {{pagina_atual}} )
                    </div>

                </div>

            </div>

        </div>
    </div>

    <!-- Modal -->
    <div id="exampleModal1" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog">

            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Confirma Exclusão?</h4>
                </div>
                <div class="modal-body">
                    <b>Olá {{nome}}</b>
                    <p>Ao excluir a Oportunidade um e-mail será enviado confirmação.</p>

                </div>
                <div class="modal-footer">
                    <button pButton pRipple type="button" icon="pi pi-trash" class="p-button-rounded" title="Excluir"
                        (click)="BotaoExcluir()" data-dismiss="modal"></button>
                    <button pButton pRipple type="button" icon="pi pi-sign-in" class="p-button-rounded p-button-danger"
                        title="Retornar" data-dismiss="modal"></button>
                </div>
            </div>
        </div>
    </div>
</div>
