<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>
<div>
    <div class="row">
        <div class="col-md-2" style="margin-right: 2px; padding: 0%;">
            <img src="../../../assets/img/configuracoes.png" height="100px" width="200px" style="padding: 0%;" />
        </div>
        <div class="col-md">
            <h4 class="espacamentotopo " style="margin-left: 5px;">
                Ajuste Perfil
            </h4>
            <label style="margin-left: 5px;">
                Aqui você define Qual a permissão do usuario!.
                <br>
                Tome cuidado ao selecionar o perfil para que não seja liberado perfil superior ao necessario do
                usuario.<br>
            </label>
        </div>
    </div>

    <h4>Permissões de Acesso</h4>
    <hr class="espacamentotopo bold" />
    <form [formGroup]="formusuario" disabled="">
        <div class="row">
            <!---INCIO PRIMEIRA LINHA-->
            <div class="col-md-2">
                <div class="form-group">
                    <label>CPF:</label><strong class="cor">*</strong>
                    <input type="text" disabled="true" class="form-control" id="cpf" placeholder="informe o CPF"
                        mask="CPF_CNPJ" name="cpf" maxlength="14" autocomplete="off" formControlName="cpf"
                        [(ngModel)]="modelusuario.cpf" [ngClass]="{ 'is-invalid': submitted && u.cpf.errors }" />
                    <div *ngIf="submitted && u.cpf.errors" class="invalid-feedback">
                        <div *ngIf="u.cpf.errors.required">Preenchimento obrigatório</div>
                    </div>
                </div>
            </div>

            <div class="col-md-10">
                <div class="form-group">
                    <label>Nome Completo:</label><strong class="cor">*</strong>
                    <input type="text" disabled="true" class="form-control" id="nome" name="nome"
                        [(ngModel)]="modelusuario.nome" placeholder="Informe Nome Completo" formControlName="nome"
                        maxlength="500" minlength="3" autocomplete="off"
                        [ngClass]="{ 'is-invalid': submitted && u.nome.errors }" />
                    <div *ngIf="submitted && u.nome.errors" class="invalid-feedback">
                        <div *ngIf="u.nome.errors.required">Preenchimento obrigatório</div>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <form [formGroup]="formperfil" disabled="">
        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="administrador" data-md-icheck formControlName="administrador"
                        [(ngModel)]="administrador" />
                    <b>&nbsp;&nbsp;Administrador</b><br><span>Perfil de acesso MASTER, deve ser atribuido somente ao
                        gerente do
                        sistema!.</span>

                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="presidente" data-md-icheck formControlName="presidente"
                        [(ngModel)]="presidente" />
                    <b>&nbsp;&nbsp;Presidente</b><br><span>Este perfil tem acesso a todos as opções do sistema e
                        visualizar dados
                        dos
                        membros.</span>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="financeiro" data-md-icheck formControlName="financeiro"
                        [(ngModel)]="financeiro" />
                    <b>&nbsp;&nbsp;Financeiro</b><br><span>Este perfil tem acesso as operações comuns a todos membros e
                        acesso
                        exclusivo a
                        area financeira.</span>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="lideranca" data-md-icheck formControlName="lideranca"
                        [(ngModel)]="lideranca" />
                    <b>&nbsp;&nbsp;Liderança</b><br><span>Este perfil tem acesso a comuns a todos membros e acesso a
                        area de
                        liderança.</span>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="auxiliar" data-md-icheck formControlName="auxiliar"
                        [(ngModel)]="auxiliar" />
                    <b>&nbsp;&nbsp;Pastores Auxiliares</b><br><span>Este perfil tem acesso a comuns a todos membros e
                        acesso a area
                        destinada a pastores auxiliares.</span>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="aluno" data-md-icheck formControlName="aluno" [(ngModel)]="aluno" />
                    <b>&nbsp;&nbsp;Aluno</b><br><span>Este perfil tem acesso a comuns a todos membros e acesso a area
                        destinada a
                        alunos.</span>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="membro" data-md-icheck formControlName="membro" [(ngModel)]="membro"
                        disabled />
                    <b>&nbsp;&nbsp;Membro</b><br><span>Este perfil e PADRÃO e não pode ser modificaod.</span>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="gestao" data-md-icheck formControlName="gestao" [(ngModel)]="gestao" />
                    <b>&nbsp;&nbsp;Gestão</b>.<br><span>Este Perfil da acesso a todas as areas fora configurações do
                        sistema.</span>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="secretaria" data-md-icheck formControlName="secretaria"
                        [(ngModel)]="secretaria" />
                    <b>&nbsp;&nbsp;Secretaria</b>.<br><span>Este perfil da acesso a alguns recursos destinado somente
                        para
                        secretariado.</span>
                </div>
            </div>
        </div>

        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="checkout" data-md-icheck formControlName="checkout"
                        [(ngModel)]="checkout" />
                    <b>&nbsp;&nbsp;checkout</b>.<br><span>Este perfil da acesso a alguns recursos destinado somente
                        para
                        checkout.</span>
                </div>
            </div>
        </div>

        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="consolidacao" data-md-icheck formControlName="consolidacao"
                        [(ngModel)]="consolidacao" />
                    <b>&nbsp;&nbsp;consolidacao</b>.<br><span>Este perfil da acesso a alguns recursos destinado somente
                        para
                        consolidacao.</span>
                </div>
            </div>
        </div>

        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="professor" data-md-icheck formControlName="professor"
                        [(ngModel)]="professor" />
                    <b>&nbsp;&nbsp;professor</b>.<br><span>Este perfil da acesso a alguns recursos destinado somente
                        para
                        professor.</span>
                </div>
            </div>
        </div>

        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="seminario_total" data-md-icheck formControlName="seminario_total"
                        [(ngModel)]="seminario_total" />
                    <b>&nbsp;&nbsp;seminario_total</b>.<br><span>Este perfil da acesso a alguns recursos destinado
                        somente
                        para
                        seminario_total.</span>
                </div>
            </div>
        </div>

        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="seminario_parcial" data-md-icheck formControlName="seminario_parcial"
                        [(ngModel)]="seminario_parcial" />
                    <b>&nbsp;&nbsp;seminario_parcial</b>.<br><span>Este perfil da acesso a alguns recursos destinado
                        somente
                        para
                        seminario_parcial.</span>
                </div>
            </div>
        </div>

        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="evento_homens" data-md-icheck formControlName="evento_homens"
                        [(ngModel)]="evento_homens" />
                    <b>&nbsp;&nbsp;evento_homens</b>.<br><span>Este perfil da acesso a alguns recursos destinado somente
                        para
                        evento_homens.</span>
                </div>
            </div>
        </div>


        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="evento_jovens" data-md-icheck formControlName="evento_jovens"
                        [(ngModel)]="evento_jovens" />
                    <b>&nbsp;&nbsp;evento_jovens</b>.<br><span>Este perfil da acesso a alguns recursos destinado somente
                        para
                        evento_jovens.</span>
                </div>
            </div>
        </div>

        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="evento_mulheres" data-md-icheck formControlName="evento_mulheres"
                        [(ngModel)]="evento_mulheres" />
                    <b>&nbsp;&nbsp;evento_mulheres</b>.<br><span>Este perfil da acesso a alguns recursos destinado
                        somente
                        para
                        evento_mulheres.</span>
                </div>
            </div>
        </div>

        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="evento_casais" data-md-icheck formControlName="evento_casais"
                        [(ngModel)]="evento_casais" />
                    <b>&nbsp;&nbsp;evento_casais</b>.<br><span>Este perfil da acesso a alguns recursos destinado somente
                        para
                        evento_casais.</span>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="evento_cursos" data-md-icheck formControlName="evento_cursos"
                        [(ngModel)]="evento_cursos" />
                    <b>&nbsp;&nbsp;evento_cursos</b>.<br><span>Este perfil da acesso a alguns recursos destinado somente
                        para
                        evento_cursos.</span>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="evento_gerais" data-md-icheck formControlName="evento_gerais"
                        [(ngModel)]="evento_gerais" />
                    <b>&nbsp;&nbsp;evento_gerais</b>.<br><span>Este perfil da acesso a alguns recursos destinado somente
                        para
                        evento_gerais.</span>
                </div>
            </div>
        </div>

        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="evento_infantil" data-md-icheck formControlName="evento_infantil"
                        [(ngModel)]="evento_infantil" />
                    <b>&nbsp;&nbsp;evento_Infantil</b>.<br><span>Este perfil da acesso a alguns recursos destinado somente
                        para
                        evento_Infantil.</span>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="patrimonio" data-md-icheck formControlName="patrimonio"
                        [(ngModel)]="patrimonio" />
                    <b>&nbsp;&nbsp;Patrimonio</b>.<br><span>Este perfil da acesso a alguns recursos destinado somente
                        para
                        Patrimonio.</span>
                </div>
            </div>
        </div>
        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="patrimonio_total" data-md-icheck formControlName="patrimonio_total"
                        [(ngModel)]="patrimonio_total" />
                    <b>&nbsp;&nbsp;Patrimonio Total</b>.<br><span>Este perfil da acesso a alguns recursos destinado somente
                        para
                        Patrimonio Total ( Impressão ).</span>
                </div>
            </div>
        </div>

        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="solicitacao_compra" data-md-icheck formControlName="solicitacao_compra"
                        [(ngModel)]="solicitacao_compra" />
                    <b>&nbsp;&nbsp;Solicitação de Compras Total</b>.<br><span>Este perfil da acesso a alguns recursos destinado somente
                        para
                        Solicitação de compras.</span>
                </div>
            </div>
        </div>

        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="curso_casais" data-md-icheck formControlName="curso_casais"
                        [(ngModel)]="curso_casais" />
                    <b>&nbsp;&nbsp;Curso Casais</b>.<br><span>Este perfil da acesso a alguns recursos destinado somente
                        para
                        Curso para Casais.</span>
                </div>
            </div>
        </div>

        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="curso_casais_total" data-md-icheck formControlName="curso_casais_total"
                        [(ngModel)]="curso_casais_total" />
                    <b>&nbsp;&nbsp;Curso Casais Total</b>.<br><span>Este perfil da acesso a TODOS recursos destinado somente
                        para
                        Curso para Casais.</span>
                </div>
            </div>
        </div>



        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="contabilidade" data-md-icheck formControlName="contabilidade"
                        [(ngModel)]="contabilidade" />
                    <b>&nbsp;&nbsp;Contabilidade</b>.<br><span>Este perfil da acesso ao contador.</span>
                </div>
            </div>
        </div>



        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="deducao_compra" data-md-icheck formControlName="deducao_compra"
                        [(ngModel)]="deducao_compra" />
                    <b>&nbsp;&nbsp;Dedução de compras</b>.<br><span>Este perfil da acesso a TODOS recursos destinado somente
                        para
                        dedução de compras.</span>
                </div>
            </div>
        </div>




        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="deducao_oferta" data-md-icheck formControlName="deducao_oferta"
                        [(ngModel)]="deducao_oferta" />
                    <b>&nbsp;&nbsp;Dedução de Ofertas</b>.<br><span>Este perfil da acesso a TODOS recursos destinado somente
                        para
                        dedução de ofertas nos ministerios.</span>
                </div>
            </div>
        </div>

    </form>
    <hr>



    <div>
        <button pButton pRipple type="button" icon="pi pi-save" class="p-button-rounded" title="::Salvar Alterações::"
            (click)="onSubmit()">
        </button>
        <button pButton pRipple type="button" icon="pi pi-sign-in" class="p-button-rounded p-button-danger"
            title="::Fechar::" routerLink="/dashboard/perfil/perfil-usuario">
        </button>
    </div>



    <br><br><br><br>


</div>
<button class="voltar-ao-topo" id="back"><a class="ancora" style="color: white;"><i
            class="pi pi-arrow-circle-up"></i></a></button>