<!--LISTAGEM DE CONSULTA ESTABELECIMENTOS CADASTRADOS-->
<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>
<h4 class="espacamentotopo">
    <img src="../../assets/img/novos/comercio.png" style="width: 40px" /> Listagem
    Empresa / Serviços
</h4>
<label class="j">
  &nbsp;&nbsp;&nbsp; Você está na área de consulta de Empresas/Serviços oferecidos por nossos membros!,Lembramos que todos os dados aqui informados são controlado por nossos membros não sendo informados ou cadastrados pela Morada de Deus.<br>
  <b>#FicaaDica: </b>Em caso de Celular melhor visualizado na horizontal!
</label>


<div style="margin-left: 10px;margin-right: 10px;">


    <!--Filtro-->
    <div class="col-md" style="display: block; border: 0px;padding-bottom: 0%; ">
        <label style=" display: block; border: 0px; padding-bottom: 0%;"><b
                style="padding: 0%; padding-bottom: 0%;">Filtro:</b>
            <span style=" display: block; border: 0px; font-size: x-small; padding-top: 0%;">Consulta por Empresa /
                Serviço / Tipo</span>
            <div class="row">
                <div class="col-md" style="padding: 0%;">
                    <input type="text" class="form-control" id="texto_consulta" style="margin-top: 0%;" />
                    
                </div>
                <div class="col-md">
                    <button pButton pRipple type="button" icon="fas fa-search" class="p-button-rounded"
                    style="background-color: green;" styleClass="rounded-button ui-button-danger"
                    (click)="Consulta()" style="margin-right: 20px;" title="Consulta Empresa / Serviço"></button>
                </div>
            </div>

        </label>

    </div>
    <label><b>Atenção: </b>Abaixo e apresentado as 5 Empresas / Serviços com maior consulta no momento, mas não deixe de efetuar a consulta acima com a especialidade / tipo que esteja precisando!</label>
    <label><b>Manual: </b> Nosso manual com passo-a-passo para de como efetuar seu cadastro <a href="https://www.dropbox.com/s/17efbzfz0gtudhj/manual_servi%C3%A7os.pdf?dl=1">AQUI</a></label>

    <p-table [value]="estabelecimento" [columns]="cols" dataKey="estabelecimento" [resizableColumns]="true"
        autoLayout="true" styleClass="yourTableClass" selectionMode="single" [showCurrentPageReport]="true"
        styleClass="p-datatable-responsive-demo" [responsive]="true">
        <ng-template pTemplate="header" let-estabelecimento let-columns>
            <tr>
                <th *ngFor="let col of columns">
                    {{col.header}}
                </th>
                <th>Detalhes</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-estabelecimento let-rowData let-columns="columns" let-expanded="expanded">
            <tr>

                <td>
                    <span class="p-column-title">Estabelecimento</span>
                    <img src="../../assets/img/novos/prestacao.png"
                        style="width: 40px; height: 40px; margin-right: 10px;"> {{estabelecimento.estabelecimento}}

                </td>
                <td>
                    <span class="p-column-title">Contato</span>
                    {{estabelecimento.telefone}}

                </td>
                <td>
                    <span class="p-column-title">Horário</span>
                    {{estabelecimento.funcionamento}}

                </td>
                <td>
                    <span class="p-column-title">Visualizações</span>
                    {{estabelecimento.cliques}}

                </td>
                <td>
                    <button type="button" pButton pRipple [pRowToggler]="estabelecimento" pButton pRipple type="button"
                        icon="pi pi-plus" class="p-button-rounded" style="background-color: green;"
                        styleClass="rounded-button ui-button-success" [icon]="expanded ? 'pi pi-minus':'pi pi-plus' "
                        (click)="UpdateClique(estabelecimento.idestabelecimento)"></button>
                </td>

            </tr>

        </ng-template>

        <ng-template pTemplate="rowexpansion" let-estabelecimento>
            <td colspan="7">
                <div style=" background-color: rgb(188, 211, 245);" class="col-md">
                    <img src="../../assets/img/novos/prestacao.png"
                        style="width: 15px; height: 15px; margin-left: 50px;"><b>
                        Detalhes de [ {{estabelecimento.estabelecimento}} ]</b>
                    <br>
                    <br>
                    <div style="margin-left: 50px;">
                        <label><i class="fas fa-phone-square-alt"></i><b> Telefone:
                            </b>{{estabelecimento.telefone}}</label><br>
                        <div>
                            <ng-template [ngIf]="estabelecimento.celular === null" [ngIfElse]="elseBlock">
                            </ng-template>
                            <ng-template #elseBlock>
                                <label><i class="fab fa-whatsapp"></i><b> WhatsApp: </b>{{estabelecimento.celular}}<a
                                        href="https://api.whatsapp.com/send?l=pt_BR&phone=550+{{estabelecimento.celular}}"
                                        target="_blank"> Conversar</a></label><br>
                            </ng-template>
                        </div>
                        <div>
                            <ng-template [ngIf]="estabelecimento.email === null" [ngIfElse]="elseBlock5">
                            </ng-template>
                            <ng-template #elseBlock5>
                                <label><i class="fas fa-at"></i><b> E-mail: </b>{{estabelecimento.email}}</label><br>
                            </ng-template>
                        </div>

                        <label><i class="fas fa-info-circle"></i> <b> Informações:
                            </b>{{estabelecimento.informacoes}}</label><br>
                        <label><i class="far fa-clock"></i><b> Horário de Funcionamento:
                            </b>{{estabelecimento.funcionamento}}</label><br>
                        <label><i class="fas fa-car"></i><b> Logradouro: </b>{{estabelecimento.logradouro}} -
                            {{estabelecimento.numero}}</label><br>
                        <label><i class="fas fa-map-signs"></i><b> Bairro: </b>{{estabelecimento.bairro}} -
                            {{estabelecimento.uf}} -
                            {{estabelecimento.cidade}}</label><br>

                        <div>
                            <ng-template [ngIf]="estabelecimento.maps === null" [ngIfElse]="elseBlock0">
                            </ng-template>
                            <ng-template #elseBlock0>
                                <label><i class="fas fa-map-marked-alt"></i><b> Localização: </b><a
                                        href="{{estabelecimento.maps}}" target="_blank">Acessar</a></label><br>
                            </ng-template>
                        </div>

                        <div>
                            <ng-template [ngIf]="estabelecimento.site === null" [ngIfElse]="elseBlock1">
                            </ng-template>
                            <ng-template #elseBlock1>
                                <label><i class="fas fa-sitemap"></i><b> Site: </b><a href="{{estabelecimento.site}}"
                                        target="_blank">Acessar</a></label><br>
                            </ng-template>
                        </div>

                        <div>
                            <ng-template [ngIf]="estabelecimento.facebook === null" [ngIfElse]="elseBlock2">
                            </ng-template>
                            <ng-template #elseBlock2>
                                <label><i class="fab fa-facebook"></i><b> Facebook: </b><a
                                        href="{{estabelecimento.facebook}}" target="_blank">Acessar</a></label><br>
                            </ng-template>
                        </div>

                        <div>
                            <ng-template [ngIf]="estabelecimento.instagram === null" [ngIfElse]="elseBlock3">
                            </ng-template>
                            <ng-template #elseBlock3>
                                <label><i class="fab fa-instagram"></i><b> Instagram: </b><a
                                        href="{{estabelecimento.instagram}}" target="_blank">Acessar</a></label><br>
                            </ng-template>
                        </div>

                    </div>
                </div>
            </td>
            <br>
        </ng-template>


    </p-table>
    <div style="padding-bottom: 20px;">
        <button pButton pRipple type="button" icon="pi pi-sign-in" class="p-button-rounded p-button-danger"
            title="::Fechar::" routerLink="/dashboard/resumo"></button>
    </div>
</div>
<br>
<br><br>