import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PerfilService } from 'src/app/Dashboard/perfil/perfil.service';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { PerfilModel } from './perfilmodel';

@Component({
  selector: 'app-perfil',
  templateUrl: './perfil.component.html',
  styleUrls: ['./perfil.component.css']
})
export class PerfilComponent implements OnInit {


  formcadastro: FormGroup;
  submitted = false;
  cadastromembros: PerfilModel;
  marked: boolean;
  startedClass = false;
  completedClass = false;
  preventAbuse = false;
  rota: Router;



  lcivil: any = [
    'CASADO(A)',
    'SOLTEIRO(A)',
    'DIVORCIADO(A)',
    'VIÚVO(A)',
    'AMASIADO(A)'];

  cbdizimista: any = [
    'SIM',
    'NÃO'];

  cbgenero: any = [
    'MASCULINO',
    'FEMININO'];

  luf: any = [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MT',
    'MS',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO',
  ];

  base: any;
  file: any;



  @ViewChild("fileInput") fileInput;
  base64Data: any;
  converted_image: string = '../../../../assets/img/foto.png';
  foto: string;

  constructor(
    private formBuilder: FormBuilder,
    private service: PerfilService,
    private alert: AlertModalService,
    private router: Router,
    private autenticado: SharedModule,
    private http: HttpClient

  ) {
    this.rota = this.router;


  }

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//

  //seta os campos para inicio do form
  //====================================================================//
  criarform() {
    this.formcadastro = this.formBuilder.group({
      cpf: ['', Validators.required],
      nome: ['', Validators.required],
      estado_civil: ['', Validators.required],
      dt_nascimento: ['', Validators.required],
      contato_preferencial: ['', Validators.required],
      contato_secundario: [''],
      email: [''],
      dt_batismo: [''],
      dizimista: ['', Validators.required],
      cep: [''],
      logradouro: ['', Validators.required],
      numero: [
        '',
        [Validators.required, Validators.min(1), Validators.max(999999999)],
      ],
      apto: [''],
      foto: [''],
      bairro: ['', Validators.required],
      uf: ['', Validators.required],
      cidade: ['', Validators.required],
      genero: ['', Validators.required],


    });
  }

  //====================================================================//
  //PEGAR VALOR UF FORM
  //====================================================================//
  get cityNames() {
    return this.formcadastro.get('uf');
  }
  changeCity(e) {
    console.log(e.value);
    this.cityNames.setValue(e.target.value, {
      onlySelf: true,
    });
  }

  //====================================================================//
  //PEGAR VALOR ESTADO CIVIL FORM
  //====================================================================//
  get cityNamess() {
    return this.formcadastro.get('estado_civil');
  }
  changeCitys(e) {
    console.log(e.value);
    this.cityNamess.setValue(e.target.value, {
      onlySelf: true,
    });
  }

  //====================================================================//
  //PEGAR VALOR DIZIMISTA FORM
  //====================================================================//
  get citydizimista() {
    return this.formcadastro.get('dizimista');
  }
  changedizimista(e) {
    console.log(e.name);
    this.citydizimista.setValue(e.target.value, {
      onlySelf: true,
    });
  }

  //====================================================================//
  //PEGAR VALOR DIZIMISTA FORM
  //====================================================================//
  get citygenero() {
    return this.formcadastro.get('genero');
  }
  changenero(e) {
    console.log(e.name);
    this.citygenero.setValue(e.target.value, {
      onlySelf: true,
    });
  }

  get f() {
    return this.formcadastro.controls;
  }


  onSubmit() {
    this.submitted = true;
    if (this.formcadastro.invalid) {
      this.alert.AlertShowDanger('Favor verificar os campos em destaque!');
      return;
    }

    this.service.UpdateUsuario(this.formcadastro.value, this.autenticado.token).subscribe(
      () => {
        if (this.foto != "") {
          let fi = this.fileInput.nativeElement;
          if (fi.files && fi.files[0]) {
            let fileToUpload = fi.files[0];

            this.service.UploadFoto(fileToUpload, this.autenticado.token, this.autenticado.cpf)
              .subscribe(
                () => { }
              );
          }
        }
        this.alert.AlertShowSucess('Perfil Atualizado com sucesso!');


      },
      (error) => {
        this.alert.AlertShowDanger('Favor verificar os campos em destaque!');
      });
  }





  //===============================================================

  ngOnInit() {

    if (this.autenticado.token == null) {
      this.autenticado.ResetDados();
      this.alert.AlertShowDanger('Usuário não autenticado, redirecionando...')
      this.rota.navigate(['/login']);
    } else {
      if (this.autenticado.ValidarToken() != true) {
        this.autenticado.ResetDados();
        this.alert.AlertShowDanger('Token Invalido!')
        this.rota.navigate(['/login']);
      } else {
        try {

          this.cadastromembros = new PerfilModel();
          this.criarform();
          this.carregarusuario();
          this.carregarfoto();
          document.getElementById('nome').focus();

          $('img').click(function (e) {
            e.preventDefault();
            $('input').trigger('click');
          });
        } catch { }
      }
    }

  }

  FotoPadrao() {
    this.converted_image = "../../../../assets/img/foto.png";
    this.formcadastro.controls['foto'].setValue("");
    this.foto = "";

  }

  carregarfoto() {
    this.base64Data = ImageData;
    this.service.DownloadFoto(this.autenticado.hash, this.autenticado.token)
      .subscribe(
        (success) => {
          this.converted_image = "data:image/jpeg;base64," + success;
        },
        (error) => {
          if (error.error.text == 'StatusCode(400,Sem Dados de Foto)') {
            this.converted_image = "data:image/png;base64," + error.error.text;
          } else {
            this.converted_image = "data:image/jpeg;base64," + error.error.text;
          }
        }
      );
  }

  //====================================================================//
  carregarusuario() {
    this.service
      .GetUsuaruio(this.autenticado.hash)
      .subscribe(
        (success) => {
          if (success.nome != null) {
            (this.cadastromembros.cep = success.cep),
              (this.cadastromembros.logradouro = success.logradouro),
              (this.cadastromembros.bairro = success.bairro),
              (this.cadastromembros.cidade = success.cidade),
              (this.cadastromembros.nome = success.nome),
              (this.cadastromembros.email = success.email),
              (this.formcadastro.controls.dt_nascimento.setValue(formatDate(success.dt_nascimento, 'yyyy-MM-dd', 'en-US'))),
              (this.formcadastro.controls.dt_batismo.setValue(formatDate(success.dt_batismo, 'yyyy-MM-dd', 'en-US'))),
              (this.cadastromembros.contato_preferencial = success.contato_preferencial),
              (this.cadastromembros.contato_secundario = success.contato_secundario),
              (this.cadastromembros.genero = success.genero),
              (this.cadastromembros.numero = success.numero),
              (this.cadastromembros.dizimista = success.dizimista),
              (this.cadastromembros.estado_civil = success.estado_civil),
              (this.cadastromembros.cpf = success.cpf),
              (this.cadastromembros.apto = success.apto),
              (this.cadastromembros.uf = success.uf);



          } else {

          }
        },
        (error) => {
          this.StopProgressBar();
          this.alert.AlertShowDanger(
            '( ' + error.status + ' ) ' + error.message
          );
        }
      );

  }




  //CONSULTA CEP API
  //====================================================================//
  consulta_cep(cep: any) {
    if (cep != '' && cep != null) {
      this.service
        .ConsultaCEP(
          cep
            .replace('.', '')
            .replace('-', '')
            .replace('\\', '')
            .replace('/', '')
        )
        .subscribe(
          (success) => {
            if (success.cep != null) {
              (this.cadastromembros.cep = success.cep),
                (this.cadastromembros.logradouro = success.logradouro),
                (this.cadastromembros.bairro = success.bairro),
                (this.cadastromembros.cidade = success.localidade),
                (this.cadastromembros.uf = success.uf),
                (this.cadastromembros.numero = null),
                (this.cadastromembros.apto = null);
              document.getElementById('numero').focus();
            } else {
              this.alert.AlertShowDanger('CEP não e válido!');
              this.cadastromembros.cep = '';
            }
          },
          (error) => {
            this.StopProgressBar();
            this.alert.AlertShowDanger(
              '( ' + error.status + ' ) ' + error.message
            );
          }
        );
    }
  }
  //====================================================================//


}