import { AlertModalComponent } from './alert-modal.component';

import { Injectable } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';

export enum AlertTypes {
  DANGER = 'danger',
  SUCCESS = 'success',
  WARNING = 'warning',
  INFO = 'info'
}

@Injectable({
  providedIn: 'root',
})
export class AlertModalService {
  constructor(private modalService: BsModalService) {}

  private showAlert(
    message: string,
    type: AlertTypes,
    dismissTimeout?: number
  ) {
    const bsModalRef: BsModalRef = this.modalService.show(AlertModalComponent);
    bsModalRef.content.message = message;
    bsModalRef.content.type = type;

    if (dismissTimeout) {
      setTimeout(() => bsModalRef.hide(), dismissTimeout);
    }
  }

  AlertShowDanger(message: string) {
    this.showAlert(message, AlertTypes.DANGER, 3000);
  }

  AlertShowSucess(message: string) {
    this.showAlert(message, AlertTypes.SUCCESS, 3000);
  }

  AlertShowWarning(message: string) {
    this.showAlert(message, AlertTypes.WARNING, 3000);
  }

  AlertShowInfo(message: string) {
    this.showAlert(message, AlertTypes.INFO, 3000);
  }
}
