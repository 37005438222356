<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>
<div>
    <div class="col-md-12">
        <div class="row" style="margin-right: 2px; padding-left: 20px;">
            <img src="../../../assets/img/configuracoes.png" height="130px" width="100px" style="padding: 0%;" />

            <div class="col-md-10">
                <h4 class="espacamentotopo " style="margin-left: 5px;">
                    Ajuste Comunicação
                </h4>
                
                <label >
                    &nbsp;&nbsp;&nbsp;&nbsp;Aqui você define como a plataforma deve lhe enviar os comunicados!.
                    <br>
                    &nbsp;&nbsp;&nbsp;&nbsp;Tome cuidado para não desativar todos os meios e ficar sem receber os comunicados de nossa igreja!,
                    &nbsp;&nbsp;&nbsp;&nbsp;Lembramos que todos os comunicados enviados são supervisionados.<br>
                </label>
            </div>
        </div>
    </div>


    <hr class="espacamentotopo bold" />
    <form [formGroup]="formcomunicado" class="m" disabled="">
        <div class="row">
            <!---INCIO PRIMEIRA LINHA-->
            <div class="col-md-2">
                <div class="form-group">
                    <label>CPF:</label><strong class="cor">*</strong>
                    <input type="text" disabled="true" class="form-control" id="cpf" placeholder="informe o CPF"
                        mask="CPF_CNPJ" name="cpf" maxlength="14" autocomplete="off" formControlName="cpf"
                        [(ngModel)]="modelcomunicado.cpf" [ngClass]="{ 'is-invalid': submitted && f.cpf.errors }" />
                    <div *ngIf="submitted && f.cpf.errors" class="invalid-feedback">
                        <div *ngIf="f.cpf.errors.required">Preenchimento obrigatório</div>
                    </div>
                </div>
            </div>

            <div class="col-md-10">
                <div class="form-group">
                    <label>Nome Completo:</label><strong class="cor">*</strong>
                    <input type="text" disabled="true" class="form-control" id="nome" name="nome"
                        [(ngModel)]="modelcomunicado.nome" placeholder="Informe Nome Completo" formControlName="nome"
                        maxlength="500" minlength="3" autocomplete="off"
                        [ngClass]="{ 'is-invalid': submitted && f.nome.errors }" />
                    <div *ngIf="submitted && f.nome.errors" class="invalid-feedback">
                        <div *ngIf="f.nome.errors.required">Preenchimento obrigatório</div>
                    </div>
                </div>
            </div>

        </div>
        <div class="row" style="margin-top: 0%;">
            <div class="col-md" style="margin: 0%;">
                <div class="form-group">
                    <input type="checkbox" id="receber_comunicado" data-md-icheck formControlName="receber_comunicado"
                        [(ngModel)]="receber_comunicado" />
                    Receber Comunicados / Eventos / Atualizações por E-mail.<br><span>Somente se no cadastro estiver
                        preenchido com e-mail válido!</span>

                </div>
            </div>
        </div>

        <hr>
        <br>
        <div>
            <button pButton pRipple type="button" icon="pi pi-save" class="p-button-rounded"
                title="::Salvar Alterações::" (click)="onSubmit()">
            </button>
            <button pButton pRipple type="button" icon="pi pi-sign-in" class="p-button-rounded p-button-danger"
                title="::Fechar::" routerLink="/dashboard/resumo">
            </button>
        </div>
    </form>
</div>
<button class="voltar-ao-topo" id="back"><a class="ancora" style="color: white;"><i
    class="pi pi-arrow-circle-up"></i></a></button>