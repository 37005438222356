import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertModalService } from '../shared/alert-modal/alert-modal.service';
import { EventEmitterService } from '../shared/EventEmitterService';
import { Visitante } from './visitante-model';
import { VisitanteService } from './visitante.service';


@Component({
  selector: 'app-visitante',
  templateUrl: './visitante.component.html',
  styleUrls: ['./visitante.component.css']
})
export class VisitanteComponent implements OnInit {
  formvisitante: FormGroup
  submitted = false;
  visitante: Visitante;
  startedClass = false;
  completedClass = false;
  preventAbuse = false;
  rota: Router;
  copia: boolean = false;

  cbestadocivil: any = [
    'CASADO(A)',
    'SOLTEIRO(A)',
    'DIVORCIADO(A)',
    'VIÚVO(A)',
    'AMASIADO(A)'];

  cbprimeiravisita: any = [
    'SIM',
    'NÃO'];

  cbrecebervisita: any = [
    'SIM',
    'NÃO'];


  constructor(
    private formBuilder: FormBuilder,
    private alert: AlertModalService,
    private _service: VisitanteService,
    router: Router
  ) {
    this.rota = router;
    EventEmitterService.get('loginAlert').emit(false);
  }

  ngOnInit(): void {
    this.visitante = new Visitante();
    this.createForm();
    
  }

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }

  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//


  //Metodo para criar o form
  createForm() {
    this.formvisitante = this.formBuilder.group({
      mensagem: [""],
      nome: ["", Validators.required],
      email: [""],
      estado_civil: ["",Validators.required],
      dt_nascimento: ["",Validators.required],
      telefone: ["", Validators.required],
      dt_culto: [Date.now.toString(), Validators.required],
      igreja_pertence: [""],
      primeira_visita : ["",Validators.required],
      receber_visita: ["",Validators.required],
      convite: [""],
      receber_copia: [false]
    });
  }

  ReceberCopia(e) {
    if (e.target.checked == true && this.formvisitante.get('email').value == '') {
      this.visitante.receber_copia = false;
      this.formvisitante.controls['receber_copia'].setValue(false);
      this.visitante.receber_copia = false;
      this.alert.AlertShowDanger(':: AVISO ::\nPara que possa receber uma cópia do Formulario e necessário informar o e-mail!');
    } else {
      this.visitante.receber_copia = true;
      this.formvisitante.controls['receber_copia'].setValue(true);
    }

  }

  //====================================================================//
  //PEGAR VALOR DIZIMISTA FORM
  //====================================================================//
  get getestadocivil() {
    return this.formvisitante.get('estado_civil');
  }
  //====================================================================//
  // Pegar o valor do combobox DIZIMISTA
  //====================================================================//
  changeestadocivil(e) {    
    this.getestadocivil.setValue(e.target.value, {
      onlySelf: true,
    });
  }

  //====================================================================//
  //PEGAR VALOR DIZIMISTA FORM
  //====================================================================//
  get getprimeiravisita() {
    return this.formvisitante.get('primeira_visita');
  }
  //====================================================================//
  // Pegar o valor do combobox DIZIMISTA
  //====================================================================//
  changeprimeiravisita(e) {   
    this.getprimeiravisita.setValue(e.target.value, {
      onlySelf: true,
    });
  }

  //====================================================================//
  //PEGAR VALOR DIZIMISTA FORM
  //====================================================================//
  get getrecebervisita() {
    return this.formvisitante.get('receber_visita');
  }
  //====================================================================//
  // Pegar o valor do combobox DIZIMISTA
  //====================================================================//
  changerecebervisita(e) {  
    this.getrecebervisita.setValue(e.target.value, {
      onlySelf: true,
    });
  }
  //=====

  // pegar o nome dos campos para validação
  get f() {
    return this.formvisitante.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.formvisitante.invalid) {
      this.alert.AlertShowDanger('Favor verificar os campos em destaque!');
      return;
    }

    this._service.Registrarvisitante(this.formvisitante.value).subscribe(
      () => {
        this.StopProgressBar();
        this.alert.AlertShowSucess('Visita Registrada com sucesso!');
        localStorage.setItem('tipo', 'visitante');
        localStorage.setItem('visitante-save', JSON.stringify(this.formvisitante.value));
        this.rota.navigate(['/retorno']);
      },
      (error) => {
        this.StopProgressBar();
        this.alert.AlertShowDanger('( ' + error.status + ' ) ' + error.error);
      }
    );
  }
}
