import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { Parametros } from 'src/app/shared/parametros.enum';
import { SharedModule } from 'src/app/shared/shared.module';
import { ElogioModel } from '../../registrar-elogio/ElogioModel';
import { ElogioListagemService } from './elogio-listagem.service';
import { map } from 'rxjs/operators/map';
import * as FileSaver from 'file-saver';
import * as fileSaver from 'file-saver';
import { SelectControlValueAccessor } from '@angular/forms';
import { retry, take } from 'rxjs/operators';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';


@Component({
  selector: 'app-elogio-listagem',
  templateUrl: './elogio-listagem.component.html',
  styleUrls: ['./elogio-listagem.component.css']
})
export class ElogioListagemComponent implements OnInit {

  private urlresumo = Parametros.RESUMO;
  private urlelogio = Parametros.ELOGIO;
  x = 1;
  y = 10;
  filtro_cabecalho: string = ""
  explicacao: string = "";
  textoconsulta: string = "";
  rota: any;
  perfil: any;
  pagina_atual = 1;
  imprimir: string = "Filtro:Todos";
  blob: Blob;
  idelogio: any;
  nome: string;


  constructor(private http: HttpClient,
    private primengConfig: PrimeNGConfig,
    private alert: AlertModalService,
    private autenticado: SharedModule,
    private service: ElogioListagemService,
    r: Router
  ) {
    this.rota = r;
    this.perfil = this.autenticado.perfil;
    this.nome = this.autenticado.nome;
  }

  startedClass = false;
  completedClass = false;
  preventAbuse = false;

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//

  elogio: Array<ElogioModel>;
  qto: number;
  cols: any[];


  GetLista() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get<ElogioModel[]>(this.urlresumo + 'GETListaElogioCpf/?cpf=' + this.autenticado.cpf, httpOptions).subscribe(
      dados => this.elogio = dados, dados => this.qto = dados.length
    )
  }

  GetListaTotal() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get<any>(this.urlresumo + 'GETListaElogioCpfQuantidade/?cpf=' + this.autenticado.cpf, httpOptions).subscribe(
      dados => this.qto = dados
    )
  }

  GetListaTotalQuantidadeDez(pagina:string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get<any>(this.urlelogio + 'GetListaConsultaLimite/?cpf=' + this.autenticado.cpf+'&id='+pagina, httpOptions).subscribe(
      dados => this.elogio = dados
    )
  }

  ngOnInit() {
    if (this.autenticado.token == null) {
      this.autenticado.ResetDados();
      this.alert.AlertShowDanger('Usuário não autenticado, redirecionando...')
      this.rota.navigate(['/login']);
    } else {
      if (this.autenticado.ValidarToken() != true) {
        this.autenticado.ResetDados();
        this.alert.AlertShowDanger('Token Invalido!')
        this.rota.navigate(['/login']);
      }
    }
    this.primengConfig.ripple = true;
    this.filtro_cabecalho = "Listagem de Elogios"
    this.explicacao = "Aqui você está visualizando todos os elogios feitos por você a Morada de Deus."
    this.GetListaTotal();
    this.GetLista();

    this.cols = [
      { field: 'Data Culto', header: 'Data Culto' },
      { field: 'Data Registro', header: 'Data Registro' },
      { field: 'Elogio', header: 'Elogio' },
      { field: 'Visualizado', header: 'Visualizado' },        
      { field: 'Excluir', header: 'Excluir' },
      { field: 'Detalhe', header: 'Detalhe' }

    ];
  }

  Paginate(event) {
    this.x = event.first + 1;
    this.pagina_atual = event.first;

    this.GetListaTotalQuantidadeDez(event.first.toString());    
    if (event.first + 10 > this.qto) {
      this.y = this.qto;
    } else {
      this.y = event.first + 10;
    }
  }

  Consulta() {
    var texto = (<HTMLInputElement>document.getElementById("texto_consulta")).value;
    if (texto != "") {
      this.textoconsulta = texto;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
        })
      }
      this.http.get<ElogioModel[]>(this.urlresumo + 'GetListaConsultaElogioCpf/?cpf=' + this.autenticado.cpf + '&consulta=' + texto, httpOptions).subscribe(
        dados => this.elogio = dados, dados => this.qto = dados.length
      )
    } else {
      this.textoconsulta = "";
      this.GetLista();
    }
  }

  VisualizarDetalhe(event) {
    localStorage.removeItem('ElogioDetalhe');
    localStorage.setItem('ElogioDetalhe', event.currentTarget.value);
    this.rota.navigate(['/dashboard/detalhe/elogio']);
  }

  
  //modal
  ValorImpressao(event) {
    this.imprimir = event.currentTarget.value;
  }

 

  SetDados(event) {
    this.idelogio = event.currentTarget.value;
  }

  BotaoExcluir() {
    this.ExcluirConsulta();
  }


  ExcluirConsulta() {
    this.service.CancelarElogio(this.idelogio, this.autenticado.token).subscribe(
      (success) => {
        this.StopProgressBar();
        this.alert.AlertShowInfo('Elogio Excluido com sucesso!');
        this.GetLista();
      },
      (error) => {
        this.StopProgressBar();
        this.alert.AlertShowDanger('Erro ao cancelar Elogio!');
        this.GetLista();
      }
    )
  }
}
