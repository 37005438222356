<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>


<div class="col-md-12">
    <div class="row" style="margin-right: 2px; padding-left: 20px;">
        <img src="../../../assets/img/configuracoes.png" height="130px" width="100px" style="padding: 0%;" />

        <div class="col-md-10" style="padding-top: 15px;">
            <h4 class="espacamentotopo " style="margin-left: 5px;">
                <b>{{filtro_cabecalho}}</b>
            </h4>
            <label>
                {{explicacao}}
                <br>
                <b>#FicaaDica: </b>Em caso de Celular melhor visualizado na horizontal!
            </label>
        </div>
    </div>
</div>

<!--Filtro-->
<div class="col-md-12" style="display: block; border: 0px;padding-bottom: 0%; ">
    <label style=" display: block; border: 0px; padding-bottom: 0%;"><b
            style="padding: 0%; padding-bottom: 0%;">Filtro:</b>
        <span style=" display: block; border: 0px; font-size: x-small; padding-top: 0%;">Consulta por descrição / Observação</span>
        <input type="text" class="form-control" (keydown)="Consulta()" id="texto_consulta" style="margin-top: 0%;" />
    </label>
</div>

<!--Tabela-->
<div class="panel-body col-md-12">
    <p-table [value]="compras" [paginator]='false' [rows]="10" [showCurrentPageReport]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" selectionMode="single"
        paginatorTemplate="{RowsPerPageDropdown} {FirstPageLink} {PreviousPageLink} {CurrentPageReport} {NextPageLink} {LastPageLink}"
        (onLazyLoad)='Paginate ($event)' styleClass="p-datatable-responsive-demo" autoLayout="true" [responsive]="true">

        <ng-template pTemplate="header">
            <tr style="background-color: dodgerblue; color: aliceblue;">
                <th>Data Compra</th>
                <th>valor</th>
                <th>Descrição</th>
                <th>Ministério</th>
                <th>Data Cadastro</th>
                <th>Solicitado Reembolso</th>
                <th>Status Reembolso</th>
                <th>Excluir</th>

            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-c>
            <tr>
                <td>{{c.dt_referencia| date: 'dd/MM/yyyy'}}</td>
                <td>R$:{{c.valor|number : '.2-2'}}</td>
                <td>{{c.descricao}}</td>
                <td>{{c.ministerio_lancamento}}</td>
                <td>{{c.dt_cadastro| date: 'dd/MM/yyyy'}}</td>
                <td>{{c.reembolso.replace("T","SIM").replace("F","NÃO")}}</td>
                <td>{{c.aceito}}</td>
                <td>
                    <span class="p-column-title">Excluir</span>
                    <button pButton pRipple type="button" icon="pi pi-trash" class="p-button-rounded"
                        style="background-color: red;" styleClass="rounded-button ui-button-danger"
                        value={{c.iddeducaocompra}} {{iddeducaocompra}}={{c.iddeducaocompra}} (click)="SetDados($event)"
                        data-toggle="modal" data-target="#exampleModal" title="Exluir"></button>
                </td>

            </tr>
        </ng-template>
    </p-table>
    <!--Paginação-->
    <p-paginator [rows]="10" [paginator]='true' [totalRecords]=qto
        currentPageReportTemplate="Mostrando: {{x}} a {{y}} de {{qto}}" (onPageChange)="Paginate($event)"
        [showCurrentPageReport]="true">
    </p-paginator>

    <span><b>====  Mês Atual(R$: {{valortotalmesatual|number : '.2-2'}} )    ====    Mês Anterior(R$: {{valortotalmespassado|number : '.2-2'}} )    ====    Valor Anual(R$: {{valortotalanual|number : '.2-2'}} )    ====    Valor Reembolso(R$: {{valortotalreembolso|number : '.2-2'}} )</b></span>

    <hr>
    <!--Botao Retorno-->
    <button pButton pRipple type="button" icon="pi pi-sign-in" title="Retornar" class="p-button-rounded p-button-danger"
        routerLink="/dashboard/resumo"></button>
    <br>
    <br>
    <br>
</div>