export enum Parametros {



  //AWS MORADA
  
  
  URLPADRAO = 'https://api.moradadedeus.com.br/',
  CADASTROUSUARIO = 'https://api.moradadedeus.com.br/usuario/',
  TERMO = 'https://api.moradadedeus.com.br/termo/',
  CORREIOS = 'https://viacep.com.br/ws/',
  CONTATO = 'https://api.moradadedeus.com.br/contato/',
  LOGIN = 'https://api.moradadedeus.com.br/login/',
  ELOGIO = 'https://api.moradadedeus.com.br/elogio/',
  VISITANTE = 'https://api.moradadedeus.com.br/visitante/',
  VISITA = 'https://api.moradadedeus.com.br/visita/',
  PROBLEMA = 'https://api.moradadedeus.com.br/problema/',
  EVENTOS = 'https://api.moradadedeus.com.br/newsletter/',
  RECLAMACAO = 'https://api.moradadedeus.com.br/reclamacao/',
  SUGESTAO = 'https://api.moradadedeus.com.br/sugestao/',
  TESTEMUNHO = 'https://api.moradadedeus.com.br/testemunho/',
  PARAMETROS = 'https://api.moradadedeus.com.br/parametros/',
  ACESSOS = 'https://api.moradadedeus.com.br/acesso/',
  SEMINARIO = 'https://api.moradadedeus.com.br/seminario/',
  RESUMO = 'https://api.moradadedeus.com.br/resumo/',
  RELATORIOS = 'https://api.moradadedeus.com.br/relatorios/',
  RELATORIOSTODOSELOGIO = 'https://api.moradadedeus.com.br/relatorioselogiostodos/',
  RELATORIOSTODOSVISITA = 'https://api.moradadedeus.com.br/relatorioselogiostodos/',
  RELATORIOSTODOSRECLAMACAO = 'https://api.moradadedeus.com.br/relatoriosreclamacaotodos/',
  RELATORIOSTODOSSUGESTAO = 'https://api.moradadedeus.com.br/RelatoriosSugestoesTodos/',
  RELATORIOSTODOSTESTEMUNHO = 'https://api.moradadedeus.com.br/RelatoriosTestemunhosTodos/',
  RELATORIOSTODOSACESSOS = 'https://api.moradadedeus.com.br/RelatoriosAcessosTodos/',
  RELATORIOSTODOSSEMINARIO = 'https://api.moradadedeus.com.br/RelatoriosSeminarioTodosContoller/',
  RELATORIOSTODOSSEMINARIOINFANTIL = 'https://api.moradadedeus.com.br/RelatoriosSeminarioInfantilTodos/',
  RELATORIOSTODOSENCONTROMULHERES = 'https://api.moradadedeus.com.br/RelatorioEncontroMulheresTodos/',
  RELATORIOSTODOSENCONTROHOMENS = 'https://api.moradadedeus.com.br/RelatorioEncontroHomensTodos/',
  RELATORIOSTODOSENCONTROCASAIS = 'https://api.moradadedeus.com.br/RelatorioEncontroCasaisTodos/',
  RELATORIOSTODOSMEMBROS = 'https://api.moradadedeus.com.br/RelatoriosMembrosTodos/',
  RELATORIOSTODOSVISITANTES = 'https://api.moradadedeus.com.br/RelatoriosVisitantesTodos/',
  RELATORIOSTODOSCULTOREGISTRADO = 'https://api.moradadedeus.com.br/RelatorioCultoRegistradoTodos/',
  RELATORIOSTODOSCULTOKIDSREGISTRADO = 'https://api.moradadedeus.com.br/RelatorioCultoKidsRegistradoTodos/',
  GERENCIAMEMBROS = 'https://api.moradadedeus.com.br/GerenciaMembrosContoller/',
  RELATORIOSTODOSCONGRESSOMULHERES = 'https://api.moradadedeus.com.br/RelatorioCongressoMulheresTodos/',
  RELATORIOSTODOSCONGRESSOHOMENS = 'https://api.moradadedeus.com.br/RelatorioCongressoHomensTodos/',
  RELATORIOSTODOSCONGRESSOJOVENS = 'https://api.moradadedeus.com.br/RelatorioCongressoJovensTodos/',
  PERFIL = 'https://api.moradadedeus.com.br/Perfil/',
  CURSOS = 'https://api.moradadedeus.com.br/curso/',
  MINISTERIO = 'https://api.moradadedeus.com.br/ministerio/',
  CADASTROCULTO = 'https://api.moradadedeus.com.br/cadastroCulto/',
  CADASTROCULTOKIDS = 'https://api.moradadedeus.com.br/cadastroCultoKids/',
  CADASTROESTABELECIMENTO = 'https://api.moradadedeus.com.br/estabelecimento/',
  CADASTROOPORTUNIDADE = 'https://api.moradadedeus.com.br/oportunidade/',
  ENCONTROHOMENS = 'https://api.moradadedeus.com.br/encontrohomens/',
  ENCONTROMULHERES = 'https://api.moradadedeus.com.br/encontromulheres/',
  CURSOCASAIS = 'https://api.moradadedeus.com.br/cursocasais/',
  ENCONTROCASAIS = 'https://api.moradadedeus.com.br/encontrocasais/',
  RELATORIOSTODOSCURSOCASAIS = 'https://api.moradadedeus.com.br/relatoriotodoscursocasais/',
  SEMINARIOINFANTIL = 'https://api.moradadedeus.com.br/seminarioinfantil/',
  PATRIMONIO = 'https://api.moradadedeus.com.br/patrimonio/',
  RELATORIOTODOSPATRIMONIO = 'https://api.moradadedeus.com.br/relatoriopatrimoniotodos/',
  SOLICITACAOCOMPRA = 'https://api.moradadedeus.com.br/solicitacaocompra/',
  RELATORIOSTODOSBATISMOREGISTRADO = 'https://api.moradadedeus.com.br/RelatorioBatismoRegistradoTodos/',
  CONGRESSOMULHERES = 'https://api.moradadedeus.com.br/congressomulheres/',
  CONGRESSOHOMENS = 'https://api.moradadedeus.com.br/congressohomens/',
  CONGRESSOJOVENS = 'https://api.moradadedeus.com.br/congressoJovens/',
  BATISMO = 'https://api.moradadedeus.com.br/batismo/',
  CENTROCUSTO = 'https://api.moradadedeus.com.br/centrocusto/',
  CADASTRODEDUCOESCOMPRAS = 'https://api.moradadedeus.com.br/deducaocompras/',

/*

  //LOCAL
  URLPADRAO = 'http://localhost:5000/',
  CADASTROUSUARIO = 'http://localhost:5000/usuario/',
  TERMO = 'http://localhost:5000/termo/',
  CORREIOS = 'https://viacep.com.br/ws/',
  CONTATO = 'http://localhost:5000/contato/',
  LOGIN = 'http://localhost:5000/login/',
  ELOGIO = 'http://localhost:5000/elogio/',
  VISITANTE = 'http://localhost:5000/visitante/',
  VISITA = 'http://localhost:5000/visita/',
  PROBLEMA = 'http://localhost:5000/problema/',
  EVENTOS = 'http://localhost:5000/newsletter/',
  RECLAMACAO = 'http://localhost:5000/reclamacao/',
  SUGESTAO = 'http://localhost:5000/sugestao/',
  TESTEMUNHO = 'http://localhost:5000/testemunho/',
  PARAMETROS = 'http://localhost:5000/parametros/',
  ACESSOS = 'http://localhost:5000/acesso/',
  RESUMO = 'http://localhost:5000/resumo/',
  SEMINARIO = 'http://localhost:5000/seminario/',
  RELATORIOS = 'http://localhost:5000/relatorios/',
  RELATORIOSTODOSELOGIO = 'http://localhost:5000/relatorioselogiostodos/',
  RELATORIOSTODOSVISITA = 'http://localhost:5000/relatoriosvisitastodos/',
  RELATORIOSTODOSRECLAMACAO = 'http://localhost:5000/relatoriosreclamacaotodos/',
  RELATORIOSTODOSSUGESTAO = 'http://localhost:5000/RelatoriosSugestoesTodos/',
  RELATORIOSTODOSTESTEMUNHO = 'http://localhost:5000/RelatoriosTestemunhosTodos/',
  RELATORIOSTODOSACESSOS = 'http://localhost:5000/RelatoriosAcessosTodos/',
  RELATORIOSTODOSSEMINARIO = 'http://localhost:5000/RelatoriosSeminarioTodosContoller/',
  RELATORIOSTODOSSEMINARIOINFANTIL = 'http://localhost:5000/RelatoriosSeminarioInfantilTodos/',
  RELATORIOSTODOSENCONTROMULHERES = 'http://localhost:5000/RelatorioEncontroMulheresTodos/',
  RELATORIOSTODOSENCONTROHOMENS = 'http://localhost:5000/RelatorioEncontroHomensTodos/',
  RELATORIOSTODOSENCONTROCASAIS = 'http://localhost:5000/RelatorioEncontroCasaisTodos/',
  RELATORIOSTODOSMEMBROS = 'http://localhost:5000/RelatoriosMembrosTodos/',
  RELATORIOSTODOSVISITANTES = 'http://localhost:5000/RelatoriosVisitantesTodos/',
  RELATORIOSTODOSCULTOREGISTRADO = 'http://localhost:5000/RelatorioCultoRegistradoTodos/',
  RELATORIOSTODOSBATISMOREGISTRADO = 'http://localhost:5000/RelatorioBatismoRegistradoTodos/',
  RELATORIOSTODOSCONGRESSOMULHERES = 'http://localhost:5000/RelatorioCongressoMulheresTodos/',
  RELATORIOSTODOSCONGRESSOHOMENS = 'http://localhost:5000/RelatorioCongressoHomensTodos/',
  RELATORIOSTODOSCONGRESSOJOVENS = 'http://localhost:5000/RelatorioCongressoJovensTodos/',
  RELATORIOSTODOSCULTOKIDSREGISTRADO = 'http://localhost:5000/RelatorioCultoKidsRegistradoTodos/',
  GERENCIAMEMBROS = 'http://localhost:5000/GerenciaMembrosContoller/',
  PERFIL = 'http://localhost:5000/Perfil/',
  CURSOS = 'http://localhost:5000/curso/',
  MINISTERIO = 'http://localhost:5000/ministerio/',
  CADASTROCULTO = 'http://localhost:5000/cadastroCulto/',
  CADASTROCULTOKIDS = 'http://localhost:5000/cadastroCultoKids/',
  CADASTROESTABELECIMENTO = 'http://localhost:5000/estabelecimento/',
  CADASTROOPORTUNIDADE = 'http://localhost:5000/oportunidade/',
  ENCONTROHOMENS = 'http://localhost:5000/encontrohomens/',
  ENCONTROMULHERES = 'http://localhost:5000/encontromulheres/',
  CURSOCASAIS = 'http://localhost:5000/cursocasais/',
  ENCONTROCASAIS = 'http://localhost:5000/encontrocasais/',
  RELATORIOSTODOSCURSOCASAIS = 'http://localhost:5000/relatoriotodoscursocasais/',
  SEMINARIOINFANTIL = 'http://localhost:5000/seminarioinfantil/',
  PATRIMONIO = 'http://localhost:5000/patrimonio/',
  RELATORIOTODOSPATRIMONIO = 'http://localhost:5000/relatoriopatrimoniotodos/',
  SOLICITACAOCOMPRA = 'http://localhost:5000/solicitacaocompra/',
  CONGRESSOMULHERES = 'http://localhost:5000/congressomulheres/',
  CONGRESSOHOMENS = 'http://localhost:5000/congressohomens/',
  CONGRESSOJOVENS = 'http://localhost:5000/congressoJovens/',
  BATISMO = 'http://localhost:5000/batismo/',
  CENTROCUSTO = 'http://localhost:5000/centrocusto/',
  CADASTRODEDUCOESCOMPRAS = 'http://localhost:5000/deducaocompras/',
*/
}
