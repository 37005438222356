<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>
<div>
    <h4 class="espacamentotopo">
        <img src="../../assets/img/cadastro.jpeg" style="width: 40px" /> Solicitar Visita
    </h4>
    <label class="espacamentotopo col-md" style="margin-left: 5px;">
        &nbsp;&nbsp;&nbsp;&nbsp;Você está na área para registro solicitação de visita, desde já gostaríamos de informar
        que estamos felizes por
        estar
        prestes a nos encaminhar uma solicitação!
        &nbsp;&nbsp;&nbsp;&nbsp;Lembramos que está solicitação dependerá exclusivamente da disponabilidade do visitante,
        como dia e horário.<br>
        &nbsp;&nbsp;&nbsp;&nbsp;A visita somente será realizada após confirmação previa por parte do visitante!<br>
        &nbsp;&nbsp;&nbsp;&nbsp;Que Deus lhe abençoes grandiosamente!<br>
        &nbsp;&nbsp;&nbsp;&nbsp;Abraço da Equipe Morada de Deus

    </label>
    <label class="espacamentotopo col-md" style="margin-left: 5px;">
        &nbsp;&nbsp;&nbsp;&nbsp;Lembramos que os campos com (<strong class="cor"> *</strong> ) são campos de
        preenchimento obrigatório!
    </label>
    <hr class="espacamentotopo bold" />
    <form [formGroup]="formvisita" class="m" disabled="">
        <div class="row">
            <!---INCIO PRIMEIRA LINHA-->
            <div class="col-md-2">
                <div class="form-group">
                    <label>CPF:</label><strong class="cor">*</strong>
                    <input type="text" disabled="true" class="form-control" id="cpf" placeholder="informe o CPF"
                        mask="CPF_CNPJ" name="cpf" maxlength="14" autocomplete="off" formControlName="cpf"
                        [(ngModel)]="visitamodel.cpf" [ngClass]="{ 'is-invalid': submitted && f.cpf.errors }" />
                    <div *ngIf="submitted && f.cpf.errors" class="invalid-feedback">
                        <div *ngIf="f.cpf.errors.required">Preenchimento obrigatório</div>
                    </div>
                </div>
            </div>

            <div class="col-md-7">
                <div class="form-group">
                    <label>Nome Completo:</label><strong class="cor">*</strong>
                    <input type="text" disabled="true" class="form-control" id="nome" name="nome"
                        [(ngModel)]="visitamodel.nome" placeholder="Informe Nome Completo" formControlName="nome"
                        maxlength="500" minlength="3" autocomplete="off"
                        [ngClass]="{ 'is-invalid': submitted && f.nome.errors }" />
                    <div *ngIf="submitted && f.nome.errors" class="invalid-feedback">
                        <div *ngIf="f.nome.errors.required">Preenchimento obrigatório</div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <label>Telefone/Celular:</label><strong class="cor">*</strong>
                <div class="input-group mb-3">
                    <div class="input-group-prepend">
                        <span class="input-group-text" id="basic-addon1"><i class="fas fa-phone"></i></span>
                    </div>
                    <input type="text" class="form-control" id="telefone" name="telefone"
                        [(ngModel)]="visitamodel.telefone" placeholder="Informe o Telefone"
                        title="Formato (DD) 0000-0000 ou (DD) 00000-0000" formControlName="telefone" maxlength="500"
                        minlength="3" autocomplete="off" mask="(00) 0000-0000 || (00) 00000-0000" [ngClass]="{
                      'is-invalid': submitted && f.telefone.errors
                    }" />
                    <div *ngIf="submitted && f.telefone.errors" class="invalid-feedback">
                        <div *ngIf="f.telefone.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!---FIM PRIMEIRA LINHA-->
        <div class="row">
            <div class="col-md-12">
                <label>Dias de Disponabilidade!</label><strong class="cor">*</strong>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <input type="checkbox" id="segunda_feira" data-md-icheck formControlName="segunda_feira"
                    [(ngModel)]="segunda_feira" (change)="CKSegunda($event)" /><label class="lb">[Segunda-Feira]</label>
                <input type="checkbox" id="terca_feira" data-md-icheck formControlName="terca_feira"
                    [(ngModel)]="terca_feira" (change)="CKTerca($event)" /><label class="lb">[Terça-Feira]</label>
                <input type="checkbox" id="quarta_feira" data-md-icheck formControlName="quarta_feira"
                    [(ngModel)]="quarta_feira" (change)="CKQuarta($event)" /><label class="lb">[Quarta-Feira]</label>
                <input type="checkbox" id="quinta_feira" data-md-icheck formControlName="quinta_feira"
                    [(ngModel)]="quinta_feira" (change)="CKQuinta($event)" /><label class="lb">[Quinta-Feira]</label>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12">
                <input type="checkbox" id="sexta_feira" data-md-icheck formControlName="sexta_feira"
                    [(ngModel)]="sexta_feira" (change)="CKSexta($event)" /><label class="lb">[Sexta-Feira]</label>
                <input style="margin-left: 22px;" type="checkbox" id="sabado" data-md-icheck formControlName="sabado"
                    [(ngModel)]="sabado" (change)="CKSabado($event)" /><label class="lb">[Sábado]</label>
                <input style="margin-left: 28px;" type="checkbox" id="domingo" data-md-icheck formControlName="domingo"
                    [(ngModel)]="domingo" (change)="CKDomingo($event)" /><label class="lb">[Domingo]</label>
                <input style="margin-left: 24px;" type="checkbox" id="todos" data-md-icheck formControlName="todos"
                    (change)="CKTodos($event)" /><label class="lb">[Todos os Dias]</label>
            </div>
        </div>
        <br>
        <!----->
        <div class="row">
            <div class="col-md-8">
                <div class="dropdown">
                    <label>Motivo da Visita:</label><strong class="cor">*</strong>
                    <div class="mb-12">
                        <select class="custom-select" type="text" (change)="changevisita($event)" name="tipo_visita"
                            id="tipo_visita" [(ngModel)]="visitamodel.tipo_visita" formControlName="tipo_visita"
                            [ngClass]="{ 'is-invalid': submitted && f.tipo_visita.errors }">
                            <div *ngIf="submitted && f.tipo_visita.errors" class="invalid-feedback"></div>
                            <option value=""></option>
                            <option *ngFor="let d of cbtipovisita" [ngValue]="d">
                                {{ d }}
                            </option>
                        </select>
                        <div *ngIf="submitted && f.tipo_visita.errors" class="invalid-feedback">
                            <div *ngIf="f.tipo_visita.errors.required">
                                Preenchimento obrigatório
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
                <div class="dropdown">
                    <label>Melhor Horário:</label><strong class="cor">*</strong>
                    <div class="mb-12">
                        <select class="custom-select" type="text" (change)="changemelhorhorario($event)"
                            name="melhor_horario" id="elogio" [(ngModel)]="visitamodel.melhor_horario"
                            formControlName="melhor_horario"
                            [ngClass]="{ 'is-invalid': submitted && f.melhor_horario.errors }">
                            <div *ngIf="submitted && f.melhor_horario.errors" class="invalid-feedback"></div>
                            <option value=""></option>
                            <option *ngFor="let d of cbmelhorhorario" [ngValue]="d">
                                {{ d }}
                            </option>
                        </select>
                        <div *ngIf="submitted && f.melhor_horario.errors" class="invalid-feedback">
                            <div *ngIf="f.melhor_horario.errors.required">
                                Preenchimento obrigatório
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!----->

        <div class="row">
            <div class="col-md">
                <div class="form-group">
                    <label>Mensagem:</label><strong class="cor">*</strong>
                    <textarea type="text" rows="6" class="form-control" id="mensagem" aria-describedby="emailHelp"
                        name="mensagem" formControlName="mensagem" [(ngModel)]="visitamodel.mensagem" autocomplete="off"
                        maxlength="8000" minlength="10"
                        [ngClass]="{ 'is-invalid': submitted && f.mensagem.errors }"></textarea>
                    <span>{{ visitamodel.mensagem.length }}/8000</span>

                    <div *ngIf="submitted && f.mensagem.errors" class="invalid-feedback">
                        <div *ngIf="f.mensagem.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <!----->
        <div class="row">
            <div class="col-md">
                <div class="form-group" style=" display: block; border: 0px;">
                    <input type="checkbox" id="receber_copia" data-md-icheck formControlName="receber_copia"
                        [(ngModel)]="receber_copia" (change)="ReceberCopia($event)" />
                    Receber Cópia.

                    <span style=" display: block; border: 0px; font-size: x-small;">Para
                        receber uma cópia e necessário
                        ter e-mail valido em seu Perfil </span>
                </div>
            </div>
        </div>


        <!----->
        <hr>
        <br>
        <!----->
        <div style="padding-bottom: 20px;">
            <button pButton pRipple type="button" icon="pi pi-save" class="p-button-rounded" style="margin-right: 5px;"
                title=":: Registrar ::" (click)="onSubmit()"></button>
            <button pButton pRipple type="button" icon="pi pi-refresh" style="margin-right: 5px;"
                class="p-button-rounded p-button-secondary" title="::LIMPAR::" (click)="reset()"></button>
            <button pButton pRipple type="button" icon="pi pi-sign-in" class="p-button-rounded p-button-danger"
                title="::Fechar::" routerLink="/dashboard/resumo"></button>
        </div>

    </form>
</div>
<button class="voltar-ao-topo" id="back"><a class="ancora" style="color: white;"><i
    class="pi pi-arrow-circle-up"></i></a></button>