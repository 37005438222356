<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>
<div class="container">
    <div class="row">
        <div class="col-md-1" style="margin-right: 6px;">
            <img src="../../../../assets/img/detalhe.png" style="width: 100px;" />
        </div>
        <div class="col-md">
            <h4 class="espacamentotopo " style="margin-left: 75px;">
                Detalhe Visita
            </h4>
            <label style="margin-left: 75px;">
                Aqui você encontra o detalhamento da visita enviada!.
            </label>
            <br>
            <b style="margin-left: 70px;">Obs:</b> ( Em caso de Celular melhor visualização em horizontal)
        </div>
    </div>
    <hr>
    <br>
    <label><b>Nome: </b>{{nome}}</label><br>
    <label><b>Data Envio: </b>{{data_envio}}</label><br>
    <label><b>Tipo Visita: </b>{{tipovisita}}</label><br>
    <label><b>Melhor Horario: </b>{{horario}}</label><br>
    <label><b>Dias: </b></label><br>
    <label style="margin-left: 20px;"><b>Segunda-Feira: </b>{{segunda}}</label><br>
    <label style="margin-left: 20px;"><b>Terça-Feira: </b>{{terca}}</label><br>
    <label style="margin-left: 20px;"><b>Quarta-Feira: </b>{{quarta}}</label><br>
    <label style="margin-left: 20px;"><b>Quinta-Feira: </b>{{quinta}}</label><br>
    <label style="margin-left: 20px;"><b>Sexta-Feira: </b>{{sexta}}</label><br>
    <label style="margin-left: 20px;"><b>Sábado: </b>{{sabado}}</label><br>
    <label style="margin-left: 20px;"><b>Domingo: </b>{{domingo}}</label>
    <br>
    <label><b>Visualizada pela Gestão da Igreja: </b>{{visualizada}}</label>
    <br><br>

    <label><b>Mensagem: </b></label><br>
    <div [innerHTML]="mensagem" textWrap="true"></div>


    <br>
    <hr>
    <button pButton pRipple type="button" icon="pi pi-print" class="p-button-rounded"
        styleClass="rounded-button ui-button-info" value={{idpedidovisita}} (click)="ImprimirVisita($event)"
        title="Imprimir Pedido Visita" style="margin-right: 10px; margin-left: 10px;"></button>
    <button pButton pRipple type="button" icon="pi pi-sign-in" title="Retornar" class="p-button-rounded p-button-danger"
        routerLink="/dashboard/listagem/lista-visita"></button>
    <br>
    <br>
    <br>
    <br>
    <br>
</div>
<button class="voltar-ao-topo" id="back"><a class="ancora" style="color: white;"><i
    class="pi pi-arrow-circle-up"></i></a></button>