<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>
<div class="container">
    <div class="col-md-12">
        <div class="row" style="margin-right: 2px; padding-left: 20px;">
            <img src="../../../assets/img/detalhe.png" height="130px" width="100px" style="padding: 0%;" />

            <div class="col-md-10" style="padding-top: 15px;">
                <h4 class="espacamentotopo " style="margin-left: 5px;">
                    <b>Detalhe Elogio</b>
                </h4>
                <label>
                    Aqui você encontra o detalhe do elogio realizado na plataforma!
                    <br>
                    <b>#FicaaDica: </b>Em caso de Celular melhor visualizado na horizontal!
                </label>
            </div>
        </div>
    </div>
    <hr>
    <br>
    <label><b>Nome: </b>{{nome}}</label><br>
    <label><b>Data Envio: </b>{{data_envio}}</label><br>
    <label><b>Data Culto: </b>{{data_culto}}</label><br>
    <label><b>Visualizado pela Gestão: </b>{{visualizada}}</label><br>
    <label><b>Tipo Elogio: </b>{{tipoelogio}}</label><br><br>
    <label><b>Mensagem: </b></label><br>
    <div [innerHTML]="mensagem" textWrap="true" ></div>
    
    <br>
    <hr>
    <button pButton pRipple type="button" icon="pi pi-print" class="p-button-rounded"
                            styleClass="rounded-button ui-button-info" value={{idelogio}}
                            (click)="ImprimirElogio()" title="Imprimir Elogio" style="margin-left: 5px; margin-right: 10px; margin-left: 10px;"></button>
                            
                            <button pButton pRipple type="button" icon="pi pi-sign-in" title="Retornar" class="p-button-rounded p-button-danger" routerLink="/dashboard/listagem/lista-elogio"></button>
    <br>
    <br>
    <br>
    <br>
</div>
<button class="voltar-ao-topo" id="back"><a class="ancora" style="color: white;"><i
    class="pi pi-arrow-circle-up"></i></a></button>