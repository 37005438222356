export class cultomodel {

    cpf: string = '';
    nome: string = '';
    tipo_culto: string = '';
    email: string = '';
    telefone: string = '';
    ministerio_morada: string = '';
    ministerio_pertence: string = '';
    ficou_sabendo: string = '';
    duvida: string = '';
    cep: string = '';
    logradouro: string = '';
    numero: number = 0;
    apto: number = 0;
    bairro: string = '';
    uf: string = '';
    cidade: string = '';
    dados_culto: string = '';
    localidade: string = '';
    dt_cadastro: any;
    idculto: any;
    tipo_documento: any;

    //usado no checkout
    rg: any;
    hash: any;
    certificado_impresso: any;
    dt_impresso: any;
    ativo: any;
    referencia: any;
    confirmado: any;
    data_confirmado: any;
    hora_confirmado: any;
    para: any;
    assunto: any;
    mensagem: any;
    anexo: any;
   

}