export class cadastromembrosmodel {
    [x: string]: any;

    cpf: string = '';
    nome: string = '';
    estado_civil: string = '';
    dt_nascimento: Date;  
    contato_preferencial: string = '';
    contato_secundario: string = '';
    email: string = '';
    dt_batismo: Date;
    cargo: string = '';
    congregacao_nome: string = '';   
    dizimista: string = '';
    cep: string = '';    
    logradouro: string = '';
    numero: number;
    apto: number;   
    bairro: string = '';
    uf: string = '';
    cidade: string = '';   
    observacoes: string = '';
    login: string = '';
    senha: string = '';
    genero:string='';
    membro_novo:string='';
   }