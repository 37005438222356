import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { RegistarElogioService } from './registar-elogio.service';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { ElogioModel } from './ElogioModel';
import { SharedModule } from 'src/app/shared/shared.module';

@Component({
  selector: 'app-registrar-elogio',
  templateUrl: './registrar-elogio.component.html',
  styleUrls: ['./registrar-elogio.component.css']
})
export class RegistrarElogioComponent implements OnInit {

  formelogio: FormGroup;
  submitted = false;
  cadastroelogio: ElogioModel;

  modaltitulo: string = '';
  modalconteudo: string = '';
  receber_copia: boolean;
  bt_termo: boolean;
  // sucesso = new EventEmitter();
  cbelogio: any = [
    'CULTO',
    'PASTORES', 'MINISTRAÇÃO', 'ORGANIZAÇÃO', 'EQUIPE DE LOUVOR', 'DIACONATO', 'OBREIROS', 'OUTROS'];

  startedClass = false;
  completedClass = false;
  preventAbuse = false;
  rota: Router;

  constructor(
    private formBuilder: FormBuilder,
    private alert: AlertModalService,
    private elogioservice: RegistarElogioService,
    private router: Router,
    private autenticado: SharedModule,
  ) {
    this.rota = this.router;

  }

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//

  //seta os campos para inicio do form
  //====================================================================//
  criarform() {
    this.formelogio = this.formBuilder.group({
      cpf: ['', Validators.required],
      nome: ['', Validators.required],
      elogio: ['', Validators.required],
      dt_culto: ['', Validators.required],
      mensagem: ['', Validators.required],
      lida: [''],
      email: [''],
      dt_respondida: [''],
      idresposta: [0],
      idusuario: [0],    
      receber_copia: ['']
    });
  }
  //====================================================================//

  //recarregar Form
  //====================================================================//
  reset() {
    this.cadastroelogio = new ElogioModel();
    this.submitted = false;
    this.formelogio.get('email').setValue(this.autenticado.email);
    this.formelogio.get('idusuario').setValue(this.autenticado.iduser);
    this.formelogio.get('nome').setValue(this.autenticado.nome);
    this.formelogio.get('cpf').setValue(this.autenticado.cpf);
  }
  //====================================================================//

  //====================================================================//
  //PEGAR VALOR COMBO
  //====================================================================//
  get cityNames() {
    return this.formelogio.get('elogio');
  }
  //====================================================================//
  // Pegar o valor do combobox
  //====================================================================//
  changeelogio(e) {
    this.cityNames.setValue(e.target.value, {
      onlySelf: true,
    });
  }
  //====================================================================//
  // Pegar nome dos campos,
  //====================================================================//
  get f() {
    return this.formelogio.controls;
  }
  //====================================================================//
  //====================================================================//
  ngOnInit() {
    if (this.autenticado.token == null) {
      this.autenticado.ResetDados();
      this.alert.AlertShowDanger('Usuário não autenticado, redirecionando...')
      this.rota.navigate(['/login']);
    } else {
      if (this.autenticado.ValidarToken() != true) {
        this.autenticado.ResetDados();
        this.alert.AlertShowDanger('Token Invalido!')
        this.rota.navigate(['/login']);
      } else {
        try {
          this.cadastroelogio = new ElogioModel();
          this.criarform();
          this.cadastroelogio.cpf = this.autenticado.cpf;
          this.cadastroelogio.nome = this.autenticado.nome;         
          this.formelogio.get('email').setValue(this.autenticado.email);
          this.formelogio.get('idusuario').setValue(this.autenticado.iduser);
          this.formelogio.get('nome').setValue(this.autenticado.nome);
          this.formelogio.get('cpf').setValue(this.autenticado.cpf);
          document.getElementById('dt_culto').focus();

        } catch {
          this.rota.navigate(['/dashboard/resumo']);
        }
      }
    }
  }
  //====================================================================//

  //====================================================================//
  // Pegar o valor do combobox UF
  //====================================================================//
  ReceberCopia(e) {
    if (e.target.checked == true && this.autenticado.email == '') {
      this.receber_copia = false;
      this.alert.AlertShowDanger(':: AVISO ::\nPara que possa receber uma cópia do Elogio e necessário ter e-mail valido em seu cadastro! \nPor favor preencha um e-mail valido em seu Perfil e tente novamente!');
    }

  }

  //BOTAO SALVAR
  //====================================================================//
  onSubmit() {
    this.submitted = true;
    if (this.formelogio.invalid) {
      this.alert.AlertShowDanger('Favor verificar os campos em destaque!');
      return;
    }
    this.elogioservice
      .CadastrarElogio(this.autenticado.token, this.formelogio.value)
      .subscribe(
        (sucess) => {
          this.StopProgressBar();
          this.alert.AlertShowSucess('Elogio Enviado com Sucesso');
          this.cadastroelogio = new ElogioModel();
          this.criarform();
          this.cadastroelogio.cpf = this.autenticado.cpf;
          this.cadastroelogio.nome = this.autenticado.nome;         
          this.formelogio.get('email').setValue(this.autenticado.email);
          this.formelogio.get('idusuario').setValue(this.autenticado.iduser);
          this.formelogio.get('nome').setValue(this.autenticado.nome);
          this.formelogio.get('cpf').setValue(this.autenticado.cpf);
          document.getElementById('dt_culto').focus();
          this.submitted = false;
        },
        (error) => {
          this.StopProgressBar();
          this.alert.AlertShowDanger('Erro ao tentar registar Elogio!');
        }
      );
    //====================================================================//
  }
}