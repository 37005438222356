<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>
<div class="row">


    <div class="col-md-12" style="margin-top: 7px;">

        <div>
            <h4 style="text-align: center;">
                <i class="far fa-calendar-check"></i>&nbsp;<b>{{filtro_cabecalho}}</b>
            </h4>
            <p>
                &nbsp;&nbsp;&nbsp;&nbsp;{{explicacao}}
                <br>
                &nbsp;&nbsp;&nbsp;&nbsp;<b>#FicaaDica: </b>Em caso de Celular melhor visualizado na horizontal!
            </p>
        </div>
    </div>
    <br>

    <!--Filtro-->
    <div class="col-md-12" style="display: block; border: 0px;padding-bottom: 0%; ">
        <label style=" display: block; border: 0px; padding-bottom: 0%;"><b
                style="padding: 0%; padding-bottom: 0%;">Filtro:</b>
            <span style=" display: block; border: 0px; font-size: x-small; padding-top: 0%;">Consulta por dispositivos</span>
            <input type="text" class="form-control" (keydown)="Consulta()" id="texto_consulta"
                style="margin-top: 0%;" />
        </label>
    </div>

    <!--Tabela-->
    <div class="panel-body col-md-12">
        <p-table [value]="acesso" [columns]="cols" [paginator]='false' [rows]="10" [showCurrentPageReport]="true"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" selectionMode="single"
            paginatorTemplate="{RowsPerPageDropdown} {FirstPageLink} {PreviousPageLink} {CurrentPageReport} {NextPageLink} {LastPageLink}"
            (onLazyLoad)='Paginate ($event)' styleClass="p-datatable-responsive-demo" autoLayout="true"
            [responsive]="true">

            <ng-template pTemplate="header" let-acesso let-columns>
                <tr style="background-color: dodgerblue; color: aliceblue;">

                    <th *ngFor="let col of columns">
                        {{col.header}}
                    </th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-c>
                <tr>
                    <td>
                        <span class="p-column-title">Data Acesso</span>
                        {{c.dt_acesso | date: 'dd/MM/yyyy'}}
                    </td>
                    <td>
                        <span class="p-column-title">Hora</span>
                        {{c.hora}}
                    </td>
                    <td>
                        <span class="p-column-title">Browser</span>
                        {{c.browser}}
                    </td>
                    <td>
                        <span class="p-column-title">Dispositivo</span>
                        {{c.dispositivo}}
                    </td>

                    <td>
                        <span class="p-column-title">Detalhes</span>
                        <button pButton pRipple type="button" icon="pi pi-search-plus" class="p-button-rounded"
                            style="background-color: green;" styleClass="rounded-button ui-button-success"
                            value={{c.idacesso}} (click)="VisualizarDetalhe($event)"
                            title="Visualizar Detalhe"></button>
                    </td>
                </tr>
            </ng-template>
        </p-table>

        <!--Paginação-->
        <p-paginator [rows]="10" [paginator]='true' [totalRecords]=qto
            currentPageReportTemplate="Mostrando: {{x}} a {{y}} de {{qto}}" (onPageChange)="Paginate($event)"
            [showCurrentPageReport]="true">
        </p-paginator>


        <hr>
        <!--Botao Imprimir-->
        <button pButton pRipple type="button" icon="pi pi-print" class="p-button-rounded"
            styleClass="rounded-button ui-button-info" (click)="ImprimirAcessos()" title="Imprimir Relação de acessos"
            style="margin-right: 10px; margin-left: 10px;"></button>
        <!--Botao Retorno-->
        <button pButton pRipple type="button" icon="pi pi-sign-in" title="Retornar"
            class="p-button-rounded p-button-danger" routerLink="/dashboard/resumo"></button>
        <br>
        <br>
        <br>
    </div>

</div>
<button class="voltar-ao-topo" id="back"><a class="ancora" style="color: white;"><i
            class="pi pi-arrow-circle-up"></i></a></button>