<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>
<div class="container">
    <div class="col-md-12">
        <div class="row" style="margin-right: 2px; padding-left: 20px;">
            <img src="../../../assets/img/detalhe.png" height="130px" width="100px" style="padding: 0%;" />

            <div class="col-md-10" style="padding-top: 15px;">
                <h4 class="espacamentotopo " style="margin-left: 5px;">
                    <b>Detalhe Cadastro</b>
                </h4>
                <label>
                    Aqui você encontra o detalhe do cadastro realizado na plataforma!
                    <br>
                    <b>#FicaaDica: </b>Em caso de Celular melhor visualizado na horizontal!
                </label>
            </div>
        </div>
    </div>
    <hr>
    <br>
    <label><b>Registro: </b>{{registro}}</label><br><br>
    <label><b>Nome: </b>{{nome}}</label>&nbsp;&nbsp;&nbsp;&nbsp;<label><b>CPF: </b>{{cpf}}</label><br>
    <label><b>Estado Civil: </b>{{estadocivil}}</label>&nbsp;&nbsp;&nbsp;&nbsp;<label><b>Data Cadastro:
        </b>{{dt_cadastro}}</label><br>
    <label><b>Data Nascimento:
        </b>{{dt_nascimento}}</label><br>
    <label><b>Telefone: </b>{{telefone}}</label>&nbsp;&nbsp;&nbsp;&nbsp;<label><b>E-mail:
        </b>{{email}}</label><br><br>


    <label><b>Status Cadastro: </b><label>{{ativo}}</label></label>&nbsp;&nbsp;&nbsp;&nbsp;<br>
    <label><b>Motivo: </b></label>{{motivo}}
    <br>
    <br>

    <label><b>Cep: </b>{{cep}}</label><br>
    <label><b>Logradouro: </b>{{logradouro}}</label>&nbsp;&nbsp;&nbsp;&nbsp;<label><b>Numero:
        </b>{{numero}}</label>&nbsp;&nbsp;&nbsp;&nbsp;<label><b>Apto:
        </b>{{apto}}</label><br>
    <label><b>Bairro: </b>{{bairro}}</label>&nbsp;&nbsp;&nbsp;&nbsp;<label><b>Cidade:
        </b>{{cidade}}</label>&nbsp;&nbsp;&nbsp;&nbsp;<label><b>UF:
        </b>{{uf}}</label><br>
    <br>

    <label><b>Uso de Medicação: </b><label
            style="background-color: green;">{{usomedicacao}}</label></label>&nbsp;&nbsp;&nbsp;&nbsp;
    <label><b>Nome Medicação: </b></label><label>{{nomemedicacao}}</label>
    <br>
    <label><b>Alergico Medicação: </b><label
            style="background-color: green;">{{alergicomedicacao}}</label></label>&nbsp;&nbsp;&nbsp;&nbsp;
    <label><b>Nome Medicação Alergico: </b></label><label>{{nomemedicacaoalergico}}</label>
    <br>
    <label><b>Alergico Alimento: </b><label
            style="background-color: green;">{{alergicoalimento}}</label></label>&nbsp;&nbsp;&nbsp;&nbsp;
    <label><b>Nome Alimento Alergico: </b></label><label>{{nomealimento}}</label>

    <br>
    <label><b>Observações: </b><label style="background-color: green;">{{observacoes}}</label></label>


    <br>
    <hr>
    <button *ngIf="perfil.includes('Administrador')||perfil.includes('Gestao')||perfil.includes('Presidente')||perfil.includes('Secretaria')||perfil.includes('Evento_Gerais')" pButton pRipple type="button" icon="pi pi-print" class="p-button-rounded"
    styleClass="rounded-button ui-button-info" value={{idinscricao}} (click)="GerarPDFEncontroMulheres()"
    title="Imprimir Ficha" style="margin-left: 5px; margin-right: 10px; margin-left: 10px;"></button>

    <button pButton pRipple type="button" icon="pi pi-sign-in" title="Retornar" class="p-button-rounded p-button-danger"
        routerLink="/dashboard/gerenciamento/encontro-mulheres/cadastros"></button>
    <br>
    <br>
    <br>
    <br>
    <br>
</div>
<button class="voltar-ao-topo" id="back"><a class="ancora" style="color: white;"><i
            class="pi pi-arrow-circle-up"></i></a></button>