import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { retry } from 'rxjs/operators';
import { Parametros } from '../shared/parametros.enum';

@Injectable({
  providedIn: 'root'
})
export class AgendaService {

  private urlcadastro = Parametros.EVENTOS;

  constructor(private http: HttpClient) { }

  startedClass = false;
  completedClass = false;
  preventAbuse = false;

  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }

  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }


  Cadastro(evento){
    this.StartProgressBar();
    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http
      .post(this.urlcadastro + 'POSTNewsletter', evento, httpOptions)
      .pipe(retry(2));
  }
}
