import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { EventEmitterService } from 'src/app/shared/EventEmitterService';
import { seminariomodel } from '../seminario-cadastro/seminariomodel';
import { SeminarioServiceService } from '../seminario-cadastro/seminario-service.service';

@Component({
  selector: 'app-seminario-cadastro',
  templateUrl: './seminario-cadastro.component.html',
  styleUrls: ['./seminario-cadastro.component.css']
})
export class SeminarioCadastroComponent implements OnInit {

  formseminario: FormGroup;
  submitted = false;
  cadastroseminario: seminariomodel;

  startedClass = false;
  completedClass = false;
  preventAbuse = false;
  rota: Router;
  modaltitulo: string;
  modalconteudo: string;
  dados_seminario: string = "";
  mostrar: string = "T";
  permitido: boolean = true;


  seminarios: any = [
    //'Crescimento e Desenvolvimento de Ministérios',
    //'Batalha Espiritual e Cura Interior',
    //'Louvor Adoração e Chamado',
    'Congresso Infantil'
  ];

  membromorada: any = [
    'SIM',
    'NÃO'];

  ficousabendo: any = [
    'INSTAGRAM',
    'FACEBOOK',
    'WHATSAPP',
    'INDICAÇÃO DE AMIGO(A)',
    'CULTO',
  ];

  luf: any = [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MT',
    'MS',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO',
  ];

  constructor(
    private formBuilder: FormBuilder,
    private alert: AlertModalService,
    private service: SeminarioServiceService,
    private router: Router
  ) {
    this.rota = this.router;
    EventEmitterService.get('loginAlert').emit(false);
    this.mostrar = "T";

  }

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//

  criarform() {
    this.formseminario = this.formBuilder.group({
      cpf: ['', Validators.required],
      nome: ['', Validators.required],
      tipo_seminario: ['', Validators.required],
      ficou_sabendo: ['', Validators.required],
      ministerio_morada: ['', Validators.required],
      telefone: ['', Validators.required],
      ministerio_pertence: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      dados_seminario: [''],
      cep: [''],
      logradouro: [''],
      numero: [''],
      apto: [''],
      bairro: [''],
      uf: [''],
      cidade: [''],
      duvida: ['']
    });
  }

  //BOTAO SALVAR
  //====================================================================//
  onSubmit() {
    this.submitted = true;
    this.cadastroseminario.dados_seminario = this.dados_seminario;
    if (this.formseminario.invalid) {
      this.alert.AlertShowDanger('Favor verificar os campos em destaque!');
      return;
    } else {
      this.service
        .CadastroUsuarioSeminario(this.formseminario.value)
        .subscribe(
          () => {
            this.StopProgressBar();
            this.submitted = false;
            this.alert.AlertShowSucess('Registro Realizado Com sucesso!');
            localStorage.setItem('tipo', 'Seminario');
            localStorage.setItem('Seminario-save', JSON.stringify(this.formseminario.value));
            this.rota.navigate(['/retorno']);
          },
          (error) => {
            this.StopProgressBar();
            this.permitido = false;
            if (error.status == '200') {
              this.StopProgressBar();
              this.submitted = false;
              this.alert.AlertShowSucess('Registro Realizado Com sucesso!');
              localStorage.setItem('tipo', 'Seminario');
              localStorage.setItem('Seminario-save', JSON.stringify('SEM EMAIL'));
              this.rota.navigate(['/retorno']);
            }
            if (error.status == '422') {
              this.permitido = false;
              this.dados_seminario = "";
              this.cadastroseminario = new seminariomodel();
              this.modaltitulo = 'Cadastro Existente!';
              this.modalconteudo =
                'Olá! <b>' + error.error.cpf + ' ' + error.error.nome
                + '</b>,<br>Estava olhando aqui em minhas anotações e verifiquei que já existe seu cadastro em nossa plataforma para o seminario [ <b>' + error.error.tipo_seminario + '</b> ], em caso de duvidas não deixe de nos reportar para que possamos analisar e lhe retornar!\nDeus Abençõe!';
              document.getElementById('openModalButton').click();
              document.getElementById('cpf').focus();

            } else if (error.status == '500') {
              this.permitido = false;
              this.dados_seminario = "";
              this.alert.AlertShowDanger(error.error);

            } else {
              this.permitido = true;
              if (error.status == '200') {

              } else {
                this.alert.AlertShowDanger(error.error);
              }
              document.getElementById('nome').focus();

            }
          }
        );
    }
    //====================================================================//
  }
  //====================================================================//

  //recarregar Form
  //====================================================================//
  reset() {
    this.cadastroseminario = new seminariomodel();
    this.submitted = false;
    this.mostrar = "F";
    document.getElementById('cpf').focus();
  }

  // Pegar nome dos campos,
  //====================================================================//
  get f() {
    return this.formseminario.controls;
  }

  //Inicio
  //====================================================================//
  ngOnInit() {
    this.cadastroseminario = new seminariomodel();
    this.criarform();
    this.formseminario.controls['ministerio_morada'].setValue("1: SIM");
    this.formseminario.controls['numero'].setValue("0");
    this.formseminario.controls['apto'].setValue("0");
    document.getElementById('cpf').focus();
  }



  //Consulta o cadastro do condominio já existe na base de dados ( API )
  //====================================================================//
  DadosSeminario(t: any) {
    var x = t;
    if (x == "Crescimento e Desenvolvimento de Ministérios") {
      this.dados_seminario = "03 e 10 de Abril 2021 de 15:00Hs as 17:30Hs."
    } else if (x == "Batalha Espiritual e Cura Interior") {
      this.dados_seminario = "08 de Maio 2021 de 13Hs as 21Hs."
    } else if (x == "Louvor Adoração e Chamado") {
      this.dados_seminario = "22 de Maio 2021 as 15Hs."
    }
    else if (x == "Congresso Infantil") {
      this.dados_seminario = "24 de Julho 2021 de 14Hs. as 16Hs."
    }
    else {
      this.dados_seminario = "";
    }
  }
  //====================================================================//

  //CONSULTA CEP API
  //====================================================================//
  consulta_cep(cep: any) {
    if (cep != '' && cep != null) {
      this.service
        .ConsultaCEP(
          cep
            .replace('.', '')
            .replace('-', '')
            .replace('\\', '')
            .replace('/', '')
        )
        .subscribe(
          (success) => {
            if (success.cep != null) {
              (this.cadastroseminario.cep = success.cep),
                (this.cadastroseminario.logradouro = success.logradouro),
                (this.cadastroseminario.bairro = success.bairro),
                (this.cadastroseminario.cidade = success.localidade),
                (this.cadastroseminario.uf = success.uf);
              document.getElementById('numero').focus();
            } else {
              this.alert.AlertShowDanger('CEP não e válido!');
              this.cadastroseminario.cep = '';
            }
          },
          (error) => {
            this.StopProgressBar();
            this.alert.AlertShowDanger(
              '( ' + error.status + ' ) ' + error.message
            );
          }
        );
    }
  }

  //====================================================================//
  //PEGAR VALOR DIZIMISTA FORM
  //====================================================================//
  get citymembromorada() {
    return this.formseminario.get('ministerio_morada');
  }
  //====================================================================//
  // Pegar o valor do combobox DIZIMISTA
  //====================================================================//
  changeministeriomorada(e) {
    console.log(e.name);
    this.citymembromorada.setValue(e.target.value, {
      onlySelf: true,
    });

    if (e.target.value.includes("SIM")) {
      this.mostrar = "F";
      this.cadastroseminario.ministerio_pertence = "IEPMD - Igreja Evangelica Pentecostal Morada de Deus."
    } else {
      this.mostrar = "T";
      this.cadastroseminario.ministerio_pertence = "";
    }
  }
  //====================================================================//

}
