<div>
    <ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>
    <h5 class="container">
        <i class="fas fa-dolly" style="margin-left: 20px;"></i>&nbsp;&nbsp;Solicitação de Compra
    </h5>
    <label class="j container">
        Observações!<br />
        &nbsp;&nbsp;&nbsp;&nbsp;Lembramos que os campos com (<strong class="cor"><b> *</b></strong> ) são campos de
        preenchimento obrigatório!
    </label><br>

    <hr class="espacamentotopo bold" />
    <form [formGroup]="formpedido" disabled="">
        <div class="row">
            <!---INCIO PRIMEIRA LINHA-->
            <div class="col-md-2">
                <div class="form-group">
                    <label>CPF:</label><strong class="cor">*</strong>
                    <input type="text" disabled="true" class="form-control" id="cpf" placeholder="informe o CPF"
                        mask="CPF_CNPJ" name="cpf" maxlength="14" autocomplete="off" formControlName="cpf"
                        [(ngModel)]="pedidomodel.cpf" [ngClass]="{ 'is-invalid': submittedPedido && p.cpf.errors }" />
                    <div *ngIf="submittedPedido && p.cpf.errors" class="invalid-feedback">
                        <div *ngIf="p.cpf.errors.required">Preenchimento obrigatório</div>
                    </div>
                </div>
            </div>

            <div class="col-md-7">
                <div class="form-group">
                    <label>Nome Completo:</label><strong class="cor">*</strong>
                    <input type="text" disabled="true" class="form-control" id="nome" name="nome"
                        [(ngModel)]="pedidomodel.nome" placeholder="Informe Nome Completo" formControlName="nome"
                        maxlength="500" minlength="3" autocomplete="off"
                        [ngClass]="{ 'is-invalid': submittedPedido && p.nome.errors }" />
                    <div *ngIf="submittedPedido && p.nome.errors" class="invalid-feedback">
                        <div *ngIf="p.nome.errors.required">Preenchimento obrigatório</div>
                    </div>
                </div>
            </div>
            <div class="col-md-3">
                <label>Nº Solicitação:</label><strong class="cor">*</strong>
                <div class="input-group mb-3">
                    <input type="text" class="form-control" id="telefone" name="telefone" disabled
                        style="font-weight: bold; text-align: center;" [(ngModel)]="pedidomodel.numero_pedido"
                        formControlName="numero_pedido" maxlength="500" placeholder="0000" minlength="3"
                        autocomplete="off" [ngClass]="{
                  'is-invalid': submittedPedido && p.numero_pedido.errors
                }" />
                    <div *ngIf="submittedPedido && p.numero_pedido.errors" class="invalid-feedback">
                        <div *ngIf="p.numero_pedido.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
    <form [formGroup]="formproduto" class="m" disabled="">
        <div class="row">


            <!---INCIO PRIMEIRA LINHA-->
            <div class="col-md-2">
                <label>Quantidade:</label><strong class="cor">*</strong>
                <div class="input-group mb-3">
                    <input type="number" style="font-weight: bold; text-align: center;" class="form-control"
                        id="quantidade" name="quantidade" [(ngModel)]="produtomodel.quantidade"
                        formControlName="quantidade" autocomplete="off" [ngClass]="{
                      'is-invalid': submitted && f.quantidade.errors
                    }" />
                    <div *ngIf="submitted && f.quantidade.errors" class="invalid-feedback">
                        <div *ngIf="f.quantidade.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-3">
                <div class="dropdown">
                    <label>Tipo:</label><strong class="cor">*</strong>
                    <div class="mb-3">
                        <select class="custom-select" type="text" name="tipo" id="tipo" [(ngModel)]="produtomodel.tipo"
                            formControlName="tipo" [ngClass]="{ 'is-invalid': submitted && f.tipo.errors }">
                            <div *ngIf="submitted && f.tipo.errors" class="invalid-feedback"></div>
                            <option *ngFor="let tipo of tipos" [ngValue]="tipo">
                                {{ tipo }}
                            </option>
                        </select>
                        <div *ngIf="submitted && f.tipo.errors" class="invalid-feedback">
                            <div *ngIf="f.tipo.errors.required">
                                Preenchimento obrigatório
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div class=" col-md-7">
                <label>Produto:</label><strong class="cor">*</strong>
                <div class="input-group mb-3">
                    <input type="text" class="form-control" minlength="3" maxlength="100" id="produto"
                        placeholder="Informe o Produto" [(ngModel)]="produtomodel.produto" formControlName="produto"
                        [ngClass]="{ 'is-invalid': submitted && f.produto.errors }" />

                    <div *ngIf="submitted && f.produto.errors" class="invalid-feedback">
                        <div *ngIf="f.produto.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!---INCIO SEGUNDA LINHA-->
        <div class="row">
            <div class="col-md-3">
                <div class="dropdown">
                    <label>Setor:</label><strong class="cor">*</strong>
                    <div class="mb-3">
                        <select class="custom-select" type="text" name="setor" id="setor"
                            [(ngModel)]="produtomodel.setor" formControlName="setor"
                            [ngClass]="{ 'is-invalid': submitted && f.setor.errors }">
                            <div *ngIf="submitted && f.setor.errors" class="invalid-feedback"></div>
                            <option value=""></option>
                            <option *ngFor="let documentos of setor" [ngValue]="documentos">
                                {{ documentos }}
                            </option>
                        </select>
                        <div *ngIf="submitted && f.setor.errors" class="invalid-feedback">
                            <div *ngIf="f.setor.errors.required">
                                Preenchimento obrigatório
                            </div>
                        </div>
                    </div>
                </div>
            </div>



            <div class="col-md-3">
                <label>Modelo:</label>
                <div class="mb-3">
                    <input type="text" class="form-control" maxlength="100" id="modelo" name="modelo"
                        [(ngModel)]="produtomodel.modelo" placeholder="Informe o modelo" formControlName="modelo"
                        autocomplete="off" />
                </div>
            </div>
            <div class="col-md-3">
                <label>Marca:</label>
                <div class="mb-2">
                    <input type="text" class="form-control" id="marca" maxlength="100" name="marca"
                        [(ngModel)]="produtomodel.marca" placeholder="Informe a Marca" formControlName="marca"
                        autocomplete="off" />
                </div>
            </div>

            <div class="col-md-3">
                <label>Cor:</label>
                <div class="mb-2">
                    <input type="text" class="form-control" id="cor" maxlength="100" name="cor"
                        [(ngModel)]="produtomodel.cor" placeholder="Informe a Cor" formControlName="cor"
                        autocomplete="off" />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md">
                <div class="form-group">
                    <label style="padding: 0%;">Observações Produto:</label>
                    <textarea type="text" rows="1" class="form-control" id="observacoes" name="observacoes"
                        formControlName="observacoes" [(ngModel)]="produtomodel.observacoes" autocomplete="off"
                        maxlength="500" minlength="3"></textarea>

                    <span>{{ produtomodel.observacoes.length }}/500</span>
                </div>
            </div>
        </div>
       

        <button pButton pRipple type="button" icon="pi pi-plus" class="p-button-rounded p-button-success" style="margin-bottom: 10px;"
            title=":: Adicionar Produto ::" (click)="inserir()"></button>

        <br>
        <br>





        <!--Tabela-->
        <label style="margin-left: 12px;">Lista de Produtos</label>
        <div class="panel-body col-md-12">
            <p-table [value]="produto" [paginator]='false' [columns]="cols" [rows]="10" [showCurrentPageReport]="true"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" selectionMode="single"
                paginatorTemplate="{RowsPerPageDropdown} {FirstPageLink} {PreviousPageLink} {CurrentPageReport} {NextPageLink} {LastPageLink}"
                styleClass="p-datatable-responsive-demo" autoLayout="true" [responsive]="true">

                <ng-template pTemplate="header" let-Produto let-columns>
                    <tr style="background-color: dodgerblue; color: aliceblue;">
                        <th *ngFor="let col of columns">
                            {{col.header}}
                        </th>
                        <th>
                            Excluir</th>

                    </tr>
                </ng-template>

                <ng-template pTemplate="body" let-c>
                    <tr>
                        <td>
                            <span class="p-column-title">Quantidade</span>
                            {{c.quantidade}}
                        </td>

                        <td>
                            <span class="p-column-title">Tipo</span>
                            {{c.tipo}}
                        </td>
                        <td>
                            <span class="p-column-title">Produto</span>
                            {{c.produto}}
                        </td>
                        <td>
                            <span class="p-column-title">Observações</span>
                            {{c.observacoes}}
                        </td>

                        <td>
                            <span class="p-column-title">Excluir</span>
                            <button pButton pRipple type="button" icon="pi pi-trash" class="p-button-rounded"
                                style="background-color: red;" styleClass="rounded-button ui-button-danger"
                                value={{c.produto}} data-toggle="modal" (click)="remover($event)"
                                title="Remover Produto"></button>
                        </td>


                    </tr>
                </ng-template>
            </p-table>



        </div>


    </form>
    <!----->

    <br>
    <button pButton pRipple type="button" icon="pi pi-save" class="p-button-rounded" title=":: SALVAR ::"
        (click)="onSubmi()" style="margin-left: 25px; margin-bottom: 10px;"></button>
    <button pButton pRipple type="button" icon="pi pi-refresh" style="margin-left: 25px; margin-bottom: 10px;"
        class="p-button-rounded p-button-secondary" title="::LIMPAR::" (click)="reset()"></button>
        <a href="https://api.whatsapp.com/send?l=pt_BR&phone=55032988189440&text=Problemas Pedido de Compra"
        style="text-align: right; float: right;" title="Fale Conosco" target="_blank">Informar Problema!</a>
    <br>
    <br>
</div>