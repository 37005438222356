import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { from } from 'rxjs';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import{TermoOportunidadeModel} from'./TermoOportunidadeModel';
import{TermoOportunidadeService}from'./termo-oportunidade.service';
import { SharedModule } from 'src/app/shared/shared.module';

@Component({
  selector: 'app-termo-oportunidade',
  templateUrl: './termo-oportunidade.component.html',
  styleUrls: ['./termo-oportunidade.component.css']
})
export class TermoOportunidadeComponent implements OnInit {

  startedClass = false;
  completedClass = false;
  preventAbuse = false;
  formtermo: FormGroup;
  submitted: boolean;
  modeltermo: TermoOportunidadeModel;
  rota: Router;

  constructor(
    private formBuilder: FormBuilder,
    private alert: AlertModalService,
    private serviceparametros: TermoOportunidadeService,
    private router: Router,
    private autenticado: SharedModule,
  ) {

    this.rota = this.router;
    this.modeltermo = new TermoOportunidadeModel();
  }

  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }

  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }

  get f() {
    return this.formtermo.controls;
  }

  ngOnInit(): void {
    if (this.autenticado.token == null) {
      this.autenticado.ResetDados();
      this.alert.AlertShowDanger('Usuário não autenticado, redirecionando...')
      this.rota.navigate(['/login']);
    } else {
      if (this.autenticado.ValidarToken() != true) {
        this.autenticado.ResetDados();
        this.alert.AlertShowDanger('Token Invalido!')
        this.rota.navigate(['/login']);
      } else {
        this.modeltermo = new TermoOportunidadeModel();
        this.criarform();
        this.CarregarTemos(this.autenticado.token);
      }
    }
  }

  CarregarTemos(token) {
    this.serviceparametros
      .GettermoCadastro()
      .subscribe(
        (success) => {
          if (success != null) {
            (this.modeltermo.idtermo = success.idtermo),
              (this.modeltermo.termo = success.termo),
              (this.modeltermo.tipo = success.tipo)
          }
        },
        (error) => {
          this.StopProgressBar();
          this.alert.AlertShowDanger(
            '( ' + error.status + ' ) ' + error.message
          );
        }
      );
  }
  criarform() {
    this.formtermo = this.formBuilder.group({

      idtermo: [1],
      tipo: [''],
      termo: ['']
    });
  }


  onSubmit() {
    this.submitted = true;
    this.serviceparametros.UpdateTermoCadastro(this.autenticado.token, this.formtermo.value).subscribe(
      () => {
        this.alert.AlertShowSucess('Termo Atualizados com sucesso!');
      },
      (error) => {
        this.alert.AlertShowDanger('Erro ao Atualizar Termo!');
      });
  }
}

