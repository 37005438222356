<div class="col-md-12">
  <div class="row" style="margin-right: 2px; padding-left: 20px">
    <img
      src="../../../assets/img/comunicado.png"
      height="130px"
      width="150px"
      style="padding: 0%"
    />

    <div class="col-md-10" style="padding-top: 15px">
      <h4 class="espacamentotopo" style="margin-left: 5px">
        <b>Seja bem vido(a)!</b>
      </h4>
      <label>
        Para ficar ligadinho na nossa agenda Participe de nosso Gupo no Telegram
        <!-- <button data-toggle="modal" data-target="#exampleModal" style="border: 0ch;"><i class="fas fa-rss"
                            style="font-size: 30px;"></i></button>
                            -->
        <a
          href="https://t.me/moradadedeus"
          target="_blank"
          style="border: 0ch; margin-right: 2px"
          ><i class="fas fa-rss" style="font-size: 30px"></i
        ></a>
      </label>
    </div>
  </div>
</div>

<!--AGOSTO-->
<div class="col-md-12" style="color: black">
  <div
    class="card"
    role="tab"
    id="headingOne"
    style="background-color: #051f5c"
  >
    <a
      role="button"
      data-toggle="collapse"
      data-parent="#accordion"
      href="#SET"
      aria-expanded="true"
      aria-controls="collapseOne"
      style="
        text-align: center;
        font-weight: bold;
        font-size: larger;
        color: rgb(248, 245, 245);
      "
    >
      - FEVEREIRO -
    </a>
  </div>

  <div  style="background-color: rgb(224, 224, 224)">
    <a
      role="button"
      data-toggle="collapse"
      data-parent="#accordion"
      href="#NOV11"
      aria-expanded="true"
      aria-controls="collapseOne"
      style="text-align: left; font-weight: bold; font-size: larger"
    >
      10 a 13 - RETIRO DA JUVENTUDE
    </a>
  </div>
  

  

 


  


 
</div>
<!--FIM AGOSTO-->

<!-- ========================================================================================= -->
<br />
<hr />
<!--FIXOS-->
<div class="col-md-12" style="color: black">
  <div
    class="card"
    role="tab"
    id="headingOne"
    style="background-color: #051f5c"
  >
    <a
      style="
        text-align: center;
        font-weight: bold;
        font-size: larger;
        color: rgb(248, 245, 245);
      "
    >
      - AGENDA SEMANAL -
    </a>
  </div>
  <div style="margin-left: 20px; margin-right: 20px; align-items: center">
    <div style="background-color: rgb(224, 224, 224)">
      <a style="text-align: left; font-weight: bold; font-size: larger">
        * Quarta-Feira => Culto de cura e libertação
      </a>
      <br />
      <a style="text-align: left; font-weight: bold; font-size: larger">
        * Quinta-Feira => Círculo de oração
      </a>
      <br />
     
      <a style="text-align: left; font-weight: bold; font-size: larger">
        * Sábado => Culto da juventude [Geração morada de Deus]
      </a>
      <br />
      <a style="text-align: left; font-weight: bold; font-size: larger">
        * Domingo Manhã => Culto Jejum das causas impossíveis
      </a>
      <br />
      <a style="text-align: left; font-weight: bold; font-size: larger">
        * Domingo Noite => Culto de Louvor e Adoração
      </a>
      <br />
      <a style="text-align: left; font-weight: bold; font-size: larger">
        * Eventuais mudanças são informadas no nosso instagram @moradajfoficial
      </a>
    </div>
  </div>
</div>

<!-- ========================================================================================= -->

<!--MODAL-->

<div
  class="modal fade"
  id="exampleModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="exampleModalLabel">
          Assine nossa Newsletter
        </h5>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="formcadastro">
          <div class="form-group">
            <label for="recipient-name" class="col-form-label">Nome:</label>
            <input
              type="text"
              class="form-control"
              id="nome"
              placeholder="informe o Nome"
              name="nome"
              autocomplete="off"
              formControlName="nome"
              [(ngModel)]="contato.nome"
              [ngClass]="{ 'is-invalid': submitted && f.nome.errors }"
            />
            <div *ngIf="submitted && f.nome.errors" class="invalid-feedback">
              <div *ngIf="submitted && f.nome.errors.required">
                Preenchimento obrigatório
              </div>
            </div>
          </div>
          <div class="form-group">
            <label for="recipient-name" class="col-form-label">E-mail:</label>
            <input
              type="text"
              class="form-control"
              id="email"
              placeholder="informe o E-mail"
              name="email"
              autocomplete="off"
              formControlName="email"
              [(ngModel)]="contato.email"
              [ngClass]="{
                'is-invalid': submitted && f.email.errors
              }"
            />
            <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
              <div *ngIf="submitted && f.email.errors.required">
                Preenchimento obrigatório
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-primary" (click)="registrar()">
          Registrar
        </button>
        <button type="button" class="btn btn-danger" data-dismiss="modal">
          Fechar
        </button>
      </div>
    </div>
  </div>
</div>
