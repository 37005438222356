import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Model } from '@swimlane/ngx-datatable';
import { PrimeNGConfig } from 'primeng/api';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { Parametros } from 'src/app/shared/parametros.enum';
import { SharedModule } from 'src/app/shared/shared.module';
import { environment } from 'src/environments/environment';
import { ElogioModel } from '../../registrar-elogio/ElogioModel';

@Component({
  selector: 'app-visita-detalhe',
  templateUrl: './visita-detalhe.component.html',
  styleUrls: ['./visita-detalhe.component.css']
})
export class VisitaDetalheComponent implements OnInit {

  idpedidovisita: any;
  rota: any;
  private urlcadastro = Parametros.RESUMO;
  private urlrelatoriotodos = Parametros.RELATORIOSTODOSVISITA;
  nome: string = "";
  data_envio: any = "";
  mensagem: string = "";
  tipovisita: string = "";
  segunda: string = "";
  terca: string = "";
  quarta: string = "";
  quinta: string = "";
  sexta: string = "";
  sabado: string = "";
  domingo: string = "";
  horario: string = "";
  visualizada: string = "";
  imprimir: any;


  constructor(private http: HttpClient,
    private alert: AlertModalService,
    private autenticado: SharedModule,
    private r: Router) {
    this.rota = r

  }

  startedClass = false;
  completedClass = false;
  preventAbuse = false;

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//

  ngOnInit(): void {
    if (this.autenticado.token == null) {
      this.autenticado.ResetDados();
      this.alert.AlertShowDanger('Usuário não autenticado, redirecionando...')
      this.rota.navigate(['/login']);
    } else {
      if (this.autenticado.ValidarToken() != true) {
        this.autenticado.ResetDados();
        this.alert.AlertShowDanger('Token Invalido!')
        this.rota.navigate(['/login']);
      }
    }
    this.idpedidovisita = localStorage.getItem('VisitaDetalhe');
    if (this.idpedidovisita == null || this.idpedidovisita == "") {
      this.rota.navigate(['/dashboard/listagem/lista-visita']);
    } else {
      //localStorage.removeItem(' ElogioDetalhe')
      this.GetElogio()
      this.mensagem.replace("\n", "<br>");
    }

  }

  GetElogio() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get<Model>(this.urlcadastro + 'GetLoadvisitadetalhe/?cpf=' + this.autenticado.cpf + '&id=' + this.idpedidovisita, httpOptions).subscribe(
      (success) => {
        this.nome = success[0].nome;
        this.data_envio = success[0].dt_envio.replace("T00:00:00", "");
        this.mensagem = (success[0].mensagem).replace("\n", "<br>");
        this.tipovisita = success[0].tipo_visita;
        this.segunda = success[0].segunda_feira.replace("T", "SIM").replace("F", "NÃO");
        this.terca = success[0].terca_feira.replace("T", "SIM").replace("F", "NÃO");
        this.quarta = success[0].quarta_feira.replace("T", "SIM").replace("F", "NÃO");
        this.quinta = success[0].quinta_feira.replace("T", "SIM").replace("F", "NÃO");
        this.sexta = success[0].sexta_feira.replace("T", "SIM").replace("F", "NÃO");
        this.sabado = success[0].sabado.replace("T", "SIM").replace("F", "NÃO");
        this.domingo = success[0].domingo.replace("T", "SIM").replace("F", "NÃO");
        this.horario = success[0].melhor_horario;
        this.visualizada = success[0].visualizada.replace("T", "SIM").replace("F", "NÃO");
        this.mensagem.replace("\n", "<br>");
      },
      (error) => {
        this.rota.navigate(['/dashboard/listagem/lista-visita']);
      }
    )
  }

  ImprimirVisita(event) {
    this.imprimir = event.currentTarget.value;
    this.GerarPDF(this.imprimir);
  }

  GerarPDF(idelogio: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token
      })
    }
    this.http.get(this.urlrelatoriotodos + 'GetRelatorioTodosVisitaDetalhado/?parametro=' + this.imprimir, httpOptions).subscribe(
      (success) => {
        const linkSource = 'data:application/pdf;base64,' + success;
        const downloadLink = document.createElement("a");
        const fileName = "IEPMD_Visita_" + (this.autenticado.nome).replace(" ", "") + ".pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }, (error) => {
        if (error.status == '403') {
          this.alert.AlertShowDanger('Erro ao tentar Gerar PDF');
        } else {
          const linkSource = 'data:application/pdf;base64,' + error.error.text;
          const downloadLink = document.createElement("a");
          const fileName = "IEPMD_Visita_" + (this.autenticado.nome).replace(" ", "") + ".pdf";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      }
    );
  }

}
