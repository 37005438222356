<div class="container">
    <div class="h">
        <h2 style="color: red; font-style: oblique;"><img src="../../assets/img/404.jpeg"
                style="width: 100px; margin-right: 5px;">Pagina não encontrada!</h2>
        <h5>Isto não foi legal!</h5>
        <label>Pedimos sinceras desculpas por este inconveniente!</label>
        <label>Já comuniquei ao meu criador para me ajudar a resolver a duvida que encontrei e não consegui resolver
            sozinho, logo logo ele me ensina e isto não vai mais ocorrer.</label>
        <label>Até que ele posso me explicar deixe-me levar você a tela inicial, para isto basta clicar no botão abaixo.</label><br>
        <label>Att.</label><br>
        <label><b>Equipe Morada de Deus</b></label><br>
        <button pButton pRipple type="button" icon="pi pi-home" class="p-button-rounded p-button-help" title="Início" routerLink=""></button>
    </div>
</div>
