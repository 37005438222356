import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Parametros } from 'src/app/shared/parametros.enum';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { UserModel } from './usermodel';
import { waitForAsync } from '@angular/core/testing';
import { retry, take } from 'rxjs/operators';
import { UsuarioModel } from 'src/app/Recuperacoes/recuperar-autenticacao/usuario-model';
import { LoginService } from 'src/app/login/login.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  _loginservice:LoginService;
  private url = Parametros.LOGIN;
  private urlparametros = Parametros.PARAMETROS;
  private urlcentrocusto = Parametros.CENTROCUSTO;
 

  constructor(private http: HttpClient,
    private alert: AlertModalService,) { }

  startedClass = false;
  completedClass = false;

   StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }

  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }


   ConsultaTokens(token) {
    this.StartProgressBar();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization':"Bearer "+token.replace("\r\n", "").replace("\n", "")
      })
    };

    return this.http.get<UserModel>(this.url + 'GETdadosuser',httpOptions).pipe(take(1));

  }


  FazerBackup(token) {
    this.StartProgressBar();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization':"Bearer "+token.replace("\r\n", "").replace("\n", "")
      })
    };

    return this.http.get<any>(this.urlparametros + 'GetBackup',httpOptions).pipe(take(1));

  }


  retornarcentrocusto(c) {
    this.StartProgressBar();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
    };
    return this.http
      .get(this.urlcentrocusto + 'GETministeriousuario/?parametro='+c,httpOptions)
      .pipe(retry(2));
  }
 
  


}
