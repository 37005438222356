<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>
<div class="row">


    <div class="col-md-12" style="margin-top: 7px;">

        <div>
            <h4 style="text-align: center;">
                <i class="fas fa-users"></i>&nbsp;<b>{{filtro_cabecalho}}</b>
            </h4>
            <p>
                &nbsp;&nbsp;&nbsp;&nbsp;{{explicacao}}
                <br>
                &nbsp;&nbsp;&nbsp;&nbsp;<b>#FicaaDica: </b>Em caso de Celular melhor visualizado na horizontal!
            </p>
        </div>
    </div>

    <!--Filtro-->
    <div class="col-md-12">
        <div class="row" style="margin-bottom: 3px; padding-right: 5px;">

            <div class="col-md-6">
                <label style=" display: block; border: 0px; padding-bottom: 0%;"><b
                        style="padding: 0%; padding-bottom: 0%;">Filtro:</b>
                    <span style=" display: block; border: 0px; font-size: x-small; padding-top: 0%;">Consulta por
                        nome</span>
                    <select class="custom-select" type="text" name="tipo_culto" id="tipo_culto"
                        (change)="SetConsultaSeminario($event)" [(ngModel)]="tipo_culto">
                        <option value=""></option>
                        <option value="TODOS CONGRESSOS">Todos Registros</option>
                        <option *ngFor="let culto of cultos" [ngValue]="culto.congresso">
                            {{ culto.congresso }}
                        </option>

                    </select>
                </label>
            </div>

            <!--Filtro-->
            <div class="col-md" style="display: block; border: 0px;padding-bottom: 0%; padding-left: 5px; ">
                <label style=" display: block; border: 0px; padding-bottom: 0%;"><b
                        style=" padding-bottom: 0%;">Filtro:</b>
                    <span style=" display: block; border: 0px; font-size: x-small; padding-top: 0%;">Consulta por
                        nome</span>
                    <input type="text" class="form-control" (keydown)="Consulta()" id="texto_consulta"
                        style="margin-top: 0%;" />
                </label>
            </div>
        </div>
    </div>

    <!--Tabela-->
    <div class="panel-body col-md-12">
        <p-table [value]="usuario" [paginator]='false' [columns]="cols" [rows]="10" [showCurrentPageReport]="true"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" selectionMode="single"
            paginatorTemplate="{RowsPerPageDropdown} {FirstPageLink} {PreviousPageLink} {CurrentPageReport} {NextPageLink} {LastPageLink}"
            (onLazyLoad)='Paginate ($event)' styleClass="p-datatable-responsive-demo" autoLayout="true"
            [responsive]="true">

            <ng-template pTemplate="header" let-usuario let-columns>
                <tr style="background-color: dodgerblue; color: aliceblue;">
                    <th *ngFor="let col of columns">
                        {{col.header}}
                    </th>
                    <th
                        *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao')||(Evento_Mulheres)">
                        Excluir</th>
                    <th
                        *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao')||(Evento_Mulheres)">
                        Detalhes</th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-c>
                <tr>
                    <td>
                        <span class="p-column-title">Data Cadastro</span>
                        {{c.dt_cadastro | date: 'dd/MM/yyyy'}}
                    </td>
                    <td>
                        <span class="p-column-title">Nome</span>
                        {{c.nome}}
                    </td>
                    <td>
                        <span class="p-column-title">Telefone</span>
                        {{c.telefone}}
                    </td>
                    <td>
                        <span class="p-column-title">Congresso</span>
                        {{c.congresso}}
                    </td>
                    <td
                        *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao')||(Evento_Mulheres)">
                        <span class="p-column-title">Excluir</span>
                        <button pButton pRipple type="button" icon="pi pi-trash" class="p-button-rounded"
                            style="background-color: red;" styleClass="rounded-button ui-button-danger"
                            value={{c.idcongresso}} {{idvisita}}={{c.idcongresso}} (click)="SetDados($event)"
                            data-toggle="modal" data-target="#exampleModal1" title="Exluir Usuario"></button>
                    </td>

                    <td
                        *ngIf="_perfil.includes('Administrador') || _perfil.includes('Presidente') || _perfil.includes('Gestao')||(Evento_Mulheres)">
                        <span class="p-column-title">Detalhes</span>
                        <button pButton pRipple type="button" icon="pi pi-search-plus" class="p-button-rounded"
                            style="background-color: green;" styleClass="rounded-button ui-button-success"
                            value={{c.idcongresso}} (click)="VisualizarDetalhe($event)" title="Visualizar Detalhe"></button>
                    </td>
                </tr>
            </ng-template>
        </p-table>
        <!--Paginação-->
        <p-paginator [rows]="10" [paginator]='true' [totalRecords]=qto
            currentPageReportTemplate="Mostrando: {{x}} a {{y}} de {{qto}}" (onPageChange)="Paginate($event)"
            [showCurrentPageReport]="true">
        </p-paginator>

        <hr>

        <!--Botao Retorno-->
        <button pButton pRipple type="button" icon="pi pi-sign-in" title="Retornar"
            class="p-button-rounded p-button-danger" routerLink="/dashboard/resumo"></button>
        <br>
        <br>
        <br>
        <br>
    </div>

   
    <!-- Modal -->
    <div id="exampleModal1" class="modal fade" tabindex="-1" role="dialog">
        <div class="modal-dialog">

            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Confirma Exclusão?</h4>
                </div>
                <div class="modal-body">
                    <b>Olá {{nome}}</b>
                    <p>Ao excluir o Cadastro um e-mail será enviado para gestão da igreja informando a
                        desistencia.</p>
                    <p>Um contato ainda pode ser realizado, mas não se preocupe que respeitamos sua decisão!.</p>

                </div>
                <div class="modal-footer">
                    <button pButton pRipple type="button" icon="pi pi-trash" class="p-button-rounded" title="Excluir"
                        (click)="BotaoExcluir()" data-dismiss="modal"></button>
                    <button pButton pRipple type="button" icon="pi pi-sign-in" class="p-button-rounded p-button-danger"
                        title="Retornar" data-dismiss="modal"></button>
                </div>
            </div>

        </div>
    </div>



</div>
