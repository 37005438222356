import { AlertModalService } from './../shared/alert-modal/alert-modal.service';
import { Contato } from './Contato.model';
import { Component, OnInit } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { ContatoService } from './ContatoService';
import { Router } from '@angular/router';
import { EventEmitterService } from '../shared/EventEmitterService';
import { CadastromembrosService } from '../cadastro-membros/CadastromembrosService';

@Component({
  selector: 'app-contato',
  templateUrl: './contato.component.html',
  styleUrls: ['./contato.component.css']
})
export class ContatoComponent implements OnInit {

  formproblema: FormGroup
  submitted = false;
  contato: Contato;
  nome: string;
  email: string;
  telefone: string;
  startedClass = false;
  completedClass = false;
  preventAbuse = false;
  rota: Router;

  constructor(
    private formBuilder: FormBuilder,
    private alert: AlertModalService,
    private _contatoservice: ContatoService,
    private _cadastromenbroservice: CadastromembrosService,
    router: Router
  ) {
    this.rota = router;
    EventEmitterService.get('loginAlert').emit(false);
  }

  ngOnInit(): void {
    this.contato = new Contato();
    this.createForm();
  }

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }

  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//


  //Metodo para criar o form
  createForm() {
    this.formproblema = this.formBuilder.group({
      tipo_contato: ["", Validators.required],
      mensagem: ["", [Validators.required, Validators.min(15), Validators.max(500)]],
      documento: ["", [Validators.required, Validators.min(14)]],
      nome: ["", Validators.required],
      email: [""],
      idusuario:[0],
      telefone: ["", Validators.required],
      copia: [false]
    });
  }

  consultacpf() {
    this._cadastromenbroservice.Consulta(this.contato.documento).subscribe(
      (success) => {
        this.StopProgressBar(); 
        document.getElementById('nome').focus();
      },
      (error) => {
        this.StopProgressBar();
        this.contato.nome = error.error;
        document.getElementById('email').focus();
      }
    );
  }

  // pegar o nome dos campos para validação
  get f() {
    return this.formproblema.controls;
  }

  onSubmit() {
    this.submitted = true;
    if (this.formproblema.invalid) {
      this.alert.AlertShowDanger('Favor verificar os campos em destaque!');
      return;
    }

    this._contatoservice.RegistrarContato(this.formproblema.value).subscribe(
      () => {
        this.StopProgressBar();
        this.alert.AlertShowSucess('Contato Enviado com sucesso!');
        localStorage.setItem('tipo', 'contato');
        localStorage.setItem('contato-save', JSON.stringify(this.formproblema.value));
        this.rota.navigate(['/retorno']);
      },
      (error) => {
        this.StopProgressBar();
        this.alert.AlertShowDanger('( ' + error.status + ' ) ' + error.error);
      }
    );
  }
}
