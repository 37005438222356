<div class="col-md-12 center">
    <div class="row center ">
        <img src="../../assets/img/novos/boneco-desenvolvimento.png" height="200px" width="70px">
    </div>
</div>


<div class="col-md  ">
    <div style="align-items: center; align-self: center; text-align: center; margin-top: 0%;">
        <h3>Só mais um pouquinho!</h3>

        <label>Aguarde só mais um pouquinho e logo está pagina estará acessivel!</label><br>
        <label>Nossa equipe tem trabalho arduamente para deixar tudo pronto para você, mas até o momento não foi possível
            liberar esta página. Pedimos desculpas pelo inconveniente mas enquanto trabalhamos para o termino desta
            página deixe-me levar você a nossa tela inicial para que possa continuar a
            desfrutar
            de nossa plataforma!</label>
        <br>

        <label>Deus lhe abençoe!</label>
        <br>
        <label>Att.</label><br>
        <label><b>Equipe Morada de Deus!</b></label>
        <br>

    </div>


   
</div>
<div class="col-md-12">
        
    <div class="col-6" style="padding-top: 5px; padding-bottom: 10px; padding-left: 49%;">
        <button pButton pRipple type="button" icon="pi pi-home" class="p-button-rounded p-button-help" title="::HOME::"
            routerLink=""></button>
    </div>

</div>
