<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>
<div class="container">
    <div class="col-md-12">
        <div class="row" style="margin-right: 2px; padding-left: 20px;">
            <img src="../../../assets/img/detalhe.png" height="130px" width="100px" style="padding: 0%;" />

            <div class="col-md-10" style="padding-top: 15px;">
                <h4 class="espacamentotopo " style="margin-left: 5px;">
                    <b>Detalhe Cadastro</b>
                </h4>
                <label>
                    Aqui você encontra o detalhe do cadastro realizado na plataforma!
                    <br>
                    <b>#FicaaDica: </b>Em caso de Celular melhor visualizado na horizontal!
                </label>
            </div>
        </div>
    </div>
    <hr>
    <br>
    <label><b>Registro: </b>{{usuario.registro}}</label> <label style="text-align: right; float: right;"><b>Data
            Cadastro: </b>{{dtcadastro}}</label><br>
            <label><b>Tipo União: </b>{{usuario.tipo_uniao}}</label> <label style="text-align: right; float: right;"><b>Tempo União:</b>{{usuario.tempo_casamento}}</label><br>
    <label><b>Nome Esposa: </b>{{usuario.nome_esposa}}</label>
    <br>
<label style="text-align: center; float: right;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Data Nascimento:
        </b>{{usuario.dt_nascimento_esposa}}</label><label style="text-align: right; float: right;"><b>Telefone:
        </b>{{usuario.telefone_esposa}}</label><br>
    <label><b>Profissão: </b>{{usuario.profissao_esposa}}</label><label
    style="text-align: center; float: right;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>E-mail: </b>{{usuario.email_esposa}}</label><label
        style="text-align: center; float: right;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Professa Fé::
        </b>{{usuario.religiao_esposa}}</label><br>
    <br>
    <label><b>Nome Esposo: </b>{{usuario.nome_esposo}}</label><label
        style="text-align: center; float: right;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Data Nascimento:
        </b>{{usuario.dt_nascimento_esposo}}</label><label style="text-align: right; float: right;"><b>Telefone:
        </b>{{usuario.telefone_esposo}}</label><br>
    <label><b>Profissão: </b>{{usuario.profissao_esposo}}</label><label
    style="text-align: center; float: right;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>E-mail: </b>{{usuario.email_esposo}}</label><label
        style="text-align: center; float: right;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Professa Fé::
        </b>{{usuario.religiao_esposo}}</label><br>


        <br>
        <label><b>Informado Filhos: </b>{{usuario.filhos}}</label><label
            style="text-align: center; float: right;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Quantidade Filhos:
            </b>{{usuario.quantidade_filhos}}</label><label style="text-align: right; float: right;"><b>Quantidade Enteados:
            </b>{{usuario.quantidade_enteados}}</label><br>
        <label><b>Observações Filhos: </b>{{usuario.observacoes_filhos}}</label><br>

            <br>
        <label><b>Participa Alguma Igreja: </b>{{usuario.pertence_alguma_igreja}}</label><label
            style="text-align: center; float: right;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Igreja Pertence:
            </b>{{usuario.igreja_pertence}}</label><label style="text-align: right; float: right;"><b>Responsável Igreja:
            </b>{{usuario.responsavel_igreja}}</label><br>
        <label><b>Observações Igreja: </b>{{usuario.observacoes_igreja}}</label><br>
    

            <br>
    <label><b>Logradouro: </b>{{usuario.logradouro}}</label><label
        style="text-align: center; float: right;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Numero:
        </b>{{usuario.numero}}</label><label style="text-align: right; float: right;"><b>Apto:
        </b>{{usuario.apto}}</label><br>
    <label><b>Bairro: </b>{{usuario.bairro}}</label><label
    style="text-align: center; float: right;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Cidade: </b>{{usuario.cidade}}</label><label
        style="text-align: center; float: right;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>UF:
        </b>{{usuario.uf}}</label><br>
        <br>
        <label><b>Observações Cadastro: </b>{{usuario.observacoes_cadastro}}</label>
        <br>


        <label><b>Confirmado: </b>{{confirmado}}</label><label
        style="text-align: center; float: right;">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<b>Referencia:
        </b>{{usuario.referencia}}</label><label style="text-align: right; float: right;"><b>Cadastro:
        </b>{{ativo}}</label><br>
   

    <br>
    <hr>
    <button
        *ngIf="perfil.includes('Administrador')||perfil.includes('Gestao')||perfil.includes('Presidente')||perfil.includes('Curso_Casais_Total')"
        pButton pRipple type="button" icon="pi pi-print" class="p-button-rounded"
        styleClass="rounded-button ui-button-info" value={{idinscricao}} (click)="GerarPDFEncontroMulheres()"
        title="Imprimir Ficha" style="margin-left: 5px; margin-right: 10px; margin-left: 10px;"></button>

    <button pButton pRipple type="button" icon="pi pi-sign-in" title="Retornar" class="p-button-rounded p-button-danger"
        routerLink="/dashboard/gerenciamento/cursos/curso-para-casais/listagem-cadastros"></button>
    <br>
    <br>
    <br>
    <br>
    <br>
</div>
<button class="voltar-ao-topo" id="back"><a class="ancora" style="color: white;"><i
            class="pi pi-arrow-circle-up"></i></a></button>




            