import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ProdutosModel } from './ProdutosModel';
import { PedidoModel } from './PedidoModel';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { PedidoCompraService } from './pedido-compra.service';
import { SharedModule } from 'src/app/shared/shared.module';

@Component({
  selector: 'app-pedido-compra',
  templateUrl: './pedido-compra.component.html',
  styleUrls: ['./pedido-compra.component.css']
})
export class PedidoCompraComponent implements OnInit {

  startedClass = false;
  completedClass = false;
  preventAbuse = false;
  produto: Array<ProdutosModel> = [];
  produtomodel: ProdutosModel;
  pedidomodel: PedidoModel;
  formproduto: FormGroup;
  formpedido: FormGroup;
  submitted = false;
  submittedPedido = false;
  cols: { field: string; header: string; }[];


  setor: any = [
    'SALÃO',
    'COZINHA',
    'SECRETARIA',
    'SOM/MIDIA',
    'USO E CONSUMO',
    'BANHEIRO',
    'EVENTOS',
    'EXTERNO',
    'GERAL',
    'OUTROS'

  ];

  tipos: any = [
    'UNIDADE',
    'FRASCO',
    'SACO',
    'KILO',
    'FARDO',
    'ENVELOPE',
    'BOMBONA',
    'ENVELOPE',
    'PACOTE',
    'ROLO'
  ];

  constructor(
    private formBuilder: FormBuilder,
    private alert: AlertModalService,
    private service: PedidoCompraService,
    private autenticado: SharedModule,
  ) {
    this.produtomodel = new ProdutosModel();
    this.pedidomodel = new PedidoModel();
    this.produto.push();
  }

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//

  ngOnInit() {

    this.criarform();
    this.criarformPedido();

    this.cols = [
      { field: 'Quantidade', header: 'Quantidade' },
      { field: 'Tipo', header: 'Tipo' },
      { field: 'Produto', header: 'Produto' },
      { field: 'Observações', header: 'Observações' }
    ];




    this.recarregardados();
    document.getElementById('quantidade').focus();


  }


  remover(event) {
    this.produto.splice(this.produto.map(x => x.produto).indexOf(event, 1))
  }

  inserir() {

    this.produtomodel.produto = this.formproduto.get('produto').value;
    this.produtomodel.quantidade = this.formproduto.get('quantidade').value;
    this.produtomodel.tipo = this.formproduto.get('tipo').value;
    this.produtomodel.setor = this.formproduto.get('setor').value;

    if (this.formproduto.get('modelo').value != '') {
      this.produtomodel.modelo = this.formproduto.get('modelo').value;
    }

    if (this.formproduto.get('cor').value != '') {
      this.produtomodel.cor = this.formproduto.get('cor').value;
    }
    if (this.formproduto.get('marca').value != '') {
      this.produtomodel.marca = this.formproduto.get('marca').value;
    }

    if (this.formproduto.get('observacoes').value != '') {
      this.produtomodel.observacoes = this.formproduto.get('observacoes').value;
    }

    if (this.formproduto.get('quantidade').value == '' || this.formproduto.get('tipo').value == '' || this.formproduto.get('setor').value == '') {
      return
    } else {
      this.produtomodel.numero_pedido = this.formpedido.get('numero_pedido').value;
      this.produto.push(this.produtomodel);
      this.produtomodel = new ProdutosModel();
    }
    document.getElementById('quantidade').focus();
    this.produtomodel = new ProdutosModel();
  }

  reset() {
    this.produtomodel = new ProdutosModel();
    this.pedidomodel = new PedidoModel();
    this.produto = [];
    this.submitted = false;
    this.submittedPedido = false;
    this.recarregardados();

  }

  recarregardados() {
    this.service.RecuperarIDCompra(this.autenticado.token).subscribe(
      (sucess) => {

      },
      (error) => {

        this.pedidomodel.numero_pedido = error.error.text;
        this.pedidomodel.cpf = this.autenticado.cpf;
        this.pedidomodel.nome = this.autenticado.nome;
        this.produto = [];
        document.getElementById('quantidade').focus();
      }
    );
  }

  criarform() {
    this.formproduto = this.formBuilder.group({
      dt_pedido: [],
      quantidade: ['', Validators.required],
      tipo: ['', Validators.required],
      produto: ['', Validators.required],
      setor: ['', Validators.required],
      numero_pedido: ['', Validators.required],
      modelo: [],
      cor: [],
      marca: [],

      observacoes: ['']
    });
  }

  criarformPedido() {
    this.formpedido = this.formBuilder.group({
      numero_pedido: ['', Validators.required],
      cpf: ['', Validators.required],
      nome: ['', Validators.required]

    });
  }

  // Pegar nome dos campos,
  //====================================================================//
  get f() {
    return this.formproduto.controls;
  }

  get p() {
    return this.formpedido.controls;
  }



  onSubmi() {

    this.produtomodel = new ProdutosModel();
    this.submittedPedido = true;
    this.submitted = false;

    if (this.formpedido.invalid) {
      return;
    } else {

      if (this.produto.length <= 0) {
        this.alert.AlertShowDanger('O Pedido deve conter ao menos 1 Produto!');
      } else {

        this.service.CadastroCompra(this.formpedido.value, this.autenticado.token).subscribe(
          (success) => {

            this.alert.AlertShowSucess('Solicitação registrada com sucesso!')
          },
          (error) => {

            if (error.status == 200) {
              
              this.service.CadastroCompraProdutos(this.produto, this.autenticado.token).subscribe(
                (success) => {
                  this.produtomodel = new ProdutosModel();
                  this.pedidomodel = new PedidoModel();
                  this.produto = [];
                  this.alert.AlertShowSucess('Solicitação registrada com sucesso!')
                  this.recarregardados();
                  this.produtomodel = new ProdutosModel();
                }, (error) => {

                }
              );
            }
          });
      }
    }

  }
}


