<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>



<div class="col-md-12">
    <div class="row" style="margin-right: 2px; padding-left: 20px;">
        <img src="../../../assets/img/configuracoes.png" height="130px" width="100px" style="padding: 0%;" />

        <div class="col-md-10" style="padding-top: 15px;">
            <h4 class="espacamentotopo " style="margin-left: 5px;">
                <b>{{filtro_cabecalho}}</b>
            </h4>
            <label>
                {{explicacao}}
                <br>
                <b>#FicaaDica: </b>Em caso de Celular melhor visualizado na horizontal!
            </label>
        </div>
    </div>
</div>

<!--Filtro-->
<div class="col-md-12" style="display: block; border: 0px;padding-bottom: 0%; ">
    <label style=" display: block; border: 0px; padding-bottom: 0%;"><b
            style="padding: 0%; padding-bottom: 0%;">Filtro:</b>
        <span style=" display: block; border: 0px; font-size: x-small; padding-top: 0%;">Consulta por nome</span>
        <input type="text" class="form-control" (keydown)="Consulta()" id="texto_consulta" style="margin-top: 0%;" />
    </label>
</div>

<!--Tabela-->
<div class="panel-body col-md-12">
    <p-table [value]="usuario" [paginator]='false' [rows]="10" [showCurrentPageReport]="true"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" selectionMode="single"
        paginatorTemplate="{RowsPerPageDropdown} {FirstPageLink} {PreviousPageLink} {CurrentPageReport} {NextPageLink} {LastPageLink}"
        (onLazyLoad)='Paginate ($event)' styleClass="p-datatable-responsive-demo" autoLayout="true" [responsive]="true">

        <ng-template pTemplate="header">
            <tr style="background-color: dodgerblue; color: aliceblue;">
                <th>Registro</th>
                <th>Nome</th>
                <th>Telefone</th>
                <th>Data Cancelamento</th>
                <th>Motivo</th>
                <th>Opções</th>
                <th>Detalhes</th>
            </tr>
        </ng-template>

        <ng-template pTemplate="body" let-c>
            <tr>
                <td>{{c.numero_registro}}</td>
                <td>{{c.nome}}</td>
                <td>{{c.contato_preferencial}}</td>
                <td>{{c.dt_cancelamento| date: 'dd/MM/yyyy'}}</td>
                <td>{{c.motivo_cancelamento}}</td>
                <td><button pButton pRipple type="button" icon="pi pi-cog" class="p-button-rounded p-button-warning"
                        value={{c.idusuario}} (click)="SetDados($event)" data-toggle="modal" data-target="#exampleModal"
                        title="Opções"></button>
                </td>
                <td><button pButton pRipple type="button" icon="pi pi-search-plus" class="p-button-rounded"
                        style="background-color: green;" styleClass="rounded-button ui-button-success"
                        value={{c.idusuario}} (click)="VisualizarDetalhe($event)" title="Visualizar Detalhe"></button>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <!--Paginação-->
    <p-paginator [rows]="10" [paginator]='true' [totalRecords]=qto
        currentPageReportTemplate="Mostrando: {{x}} a {{y}} de {{qto}}" (onPageChange)="Paginate($event)"
        [showCurrentPageReport]="true">
    </p-paginator>

    <hr>
    <!--Botao Retorno-->
    <button pButton pRipple type="button" icon="pi pi-sign-in" title="Retornar" class="p-button-rounded p-button-danger"
        routerLink="/dashboard/resumo"></button>
    <br>
    <br>
    <br>
</div>
<!-- Modal -->
<div id="exampleModal" class="modal fade" tabindex="-1" role="dialog">
    <div class="modal-dialog">

        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">Escolha uma operação!</h4>
            </div>
            <div class="modal-body">
                <b>Olá {{nome}}</b>
                <p>Por favor escolha uma das opções abaixo e lembre-se que ao escolhar a mesma, o procedimento será
                    realizado automaticamente.</p>

                <div style="font-size: medium;">
                    <input id="novo" name="base" type="radio" value="Ativar" checked (click)="SetOpcao($event)" />
                    Ativar Membro
                </div>
                <div style="font-size: medium;">
                    <input id="novo" name="base" type="radio" value="Inativar" (click)="SetOpcao($event)" />
                    Inativar Membro
                </div>
                <div style="font-size: medium;">
                    <input id="novo" name="base" type="radio" value="Cancelar" (click)="SetOpcao($event)" />
                    Cancelar Registro
                </div>

            </div>
            <div class="modal-footer">
                <button pButton pRipple type="button" icon="pi pi-check-circle"
                    class="p-button-rounded p-button-warning" title="Executar" (click)="Operacao()"
                    data-dismiss="modal"></button>
                <button pButton pRipple type="button" icon="pi pi-sign-in" class="p-button-rounded p-button-danger"
                    title="Retornar" data-dismiss="modal"></button>
            </div>
        </div>

    </div>
</div>
<button class="voltar-ao-topo" id="back"><a class="ancora" style="color: white;"><i
    class="pi pi-arrow-circle-up"></i></a></button>