import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import{OportunidadeModel} from './OportunidadeModel';
import{OportunidadeService} from'./oportunidade.service';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { SharedModule } from 'src/app/shared/shared.module';

@Component({
  selector: 'app-cadastro-oportunidade',
  templateUrl: './cadastro-oportunidade.component.html',
  styleUrls: ['./cadastro-oportunidade.component.css']
})
export class CadastroOportunidadeComponent implements OnInit {

  formcadastro: FormGroup;
  submitted = false;
  cadastromembros: OportunidadeModel;

  modaltitulo: string = '';
  modalconteudo: string = '';
  marked: boolean = false;
  ativo: boolean = true;

  bt_termo: boolean;

  lsexo: any = [
    'AMBOS',
    'MASCULINO',
    'FEMININO'
  ];

  luf: any = [
    'AC',
    'AL',
    'AP',
    'AM',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MT',
    'MS',
    'MG',
    'PA',
    'PB',
    'PR',
    'PE',
    'PI',
    'RJ',
    'RN',
    'RS',
    'RO',
    'RR',
    'SC',
    'SP',
    'SE',
    'TO',
  ];
  startedClass = false;
  completedClass = false;
  preventAbuse = false;
  rota: Router;
  habilitado: boolean = false;
  isDisabled = true;


  constructor(
    private formBuilder: FormBuilder,
    private _serviceestabelecimento: OportunidadeService,
    private alert: AlertModalService,
    private router: Router,
    private autenticado: SharedModule,
  ) {
    this.rota = this.router;

  }

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//

  //seta os campos para inicio do form
  //====================================================================//

  criarform() {
    this.formcadastro = this.formBuilder.group({
      cpf: [''],
      idusuario: [0],
      nome: [''],
      oportunidade: ['', Validators.required],
      telefone: ['', Validators.required],
      celular: [''],
      email: [''],
      site: [''],
      facebook: [''],
      instagram: [''],
      maps: [''],
      cep: [''],
      logradouro: ['', Validators.required],
      numero: [
        '',
        [Validators.required]
      ],
      quantidade: ['', [Validators.required,Validators.min(1)]],
      apto: [''],
      bairro: ['', Validators.required],
      sexo: ['', Validators.required],
      uf: ['', Validators.required],
      cidade: ['', Validators.required],
      informacoes: ['', Validators.required],
      tag: ['', Validators.required],
      funcionamento: ['', Validators.required],
      marked: ['', Validators.requiredTrue],
      ativo: ['']
    });
  }
  //====================================================================//

  //Caso discord do termo no modal
  //====================================================================//
  DiscordeTermo() {
    if (this.marked == true) {
      this.marked = false;
    }
  }
  //====================================================================//

  //Abrir modal quando clicar no aceite para exibir os termos
  //====================================================================//
  AceiteTermo(e) {
    if (e.target.checked == true) {
      this.marked = e.target.checked;
      this._serviceestabelecimento.GetTermo('oportunidade').subscribe(
        (success) => {
          this.StopProgressBar();
          this.modalconteudo = success.termo;
          this.bt_termo = true;
          this.modaltitulo = 'Termo de Cadastro Oportunidade';
          document.getElementById('openModalButton').click();
          this.formcadastro.get("marked").setValue(true);
        },
        (error) => {
          this.StopProgressBar();
          if (error.status == '400') {
            this.alert.AlertShowDanger(
              'Termo não definido!,solicitamos que não continue e avise a gestão do site, OBS: Continuando confirma está de acordo com toda politica do site!')
          } else {
            this.alert.AlertShowDanger(
              'Termo não definido!,solicitamos que não continue e avise a gestão do site, OBS: Continuando confirma está de acordo com toda politica do site!'
            );
          }
        }
      );
    }
  }
  //====================================================================//
  CKAtivo(e) {
    if (e.target.checked == true) {
      this.formcadastro.get("ativo").setValue(true);
      this.ativo = true;
    } else {
      this.formcadastro.get("ativo").setValue(false);
      this.ativo = false;
    }
  }
  //recarregar Form
  //====================================================================//
  reset() {
    this.cadastromembros = new OportunidadeModel();
    this.submitted = false;
    this.formcadastro.get('cpf').setValue(this.autenticado.cpf);
    this.formcadastro.get('nome').setValue(this.autenticado.nome);
    this.formcadastro.get('idusuario').setValue(this.autenticado.iduser);
    document.getElementById('oportunidade').focus();

  }
  //====================================================================//
  //PEGAR VALOR UF FORM
  //====================================================================//
  get cityNames() {
    return this.formcadastro.get('uf');
  }
  //====================================================================//
  // Pegar o valor do combobox UF
  //====================================================================//
  changeCity(e) {
    console.log(e.value);
    this.cityNames.setValue(e.target.value, {
      onlySelf: true,
    });
  }


   //====================================================================//
  //PEGAR VALOR UF FORM
  //====================================================================//
  get citySexo() {
    return this.formcadastro.get('sexo');
  }
  //====================================================================//
  // Pegar o valor do combobox UF
  //====================================================================//
  changeSexo(e) {
    console.log(e.value);
    this.citySexo.setValue(e.target.value, {
      onlySelf: true,
    });
  }





  //Inicio
  //====================================================================//
  ngOnInit() {


    this.cadastromembros = new OportunidadeModel();
    this.criarform();
    this.cadastromembros.cpf = this.autenticado.cpf;
    this.cadastromembros.nome = this.autenticado.nome;
    this.formcadastro.get('cpf').setValue(this.autenticado.cpf);
    this.formcadastro.get('nome').setValue(this.autenticado.nome);
    this.formcadastro.get("idusuario").setValue(this.autenticado.iduser);
    document.getElementById('oportunidade').focus();

  }
  //====================================================================//



  //CONSULTA CEP API
  //====================================================================//
  consulta_cep(cep: any) {
    if (cep != '' && cep != null) {
      this._serviceestabelecimento
        .ConsultaCEP(
          cep
            .replace('.', '')
            .replace('-', '')
            .replace('\\', '')
            .replace('/', '')
        )
        .subscribe(
          (success) => {
            if (success.cep != null) {
              (this.cadastromembros.cep = success.cep),
                (this.cadastromembros.logradouro = success.logradouro),
                (this.cadastromembros.bairro = success.bairro),
                (this.cadastromembros.cidade = success.localidade),
                (this.cadastromembros.uf = success.uf);
              document.getElementById('numero').focus();
            } else {
              this.alert.AlertShowDanger('CEP não e válido!');
              this.cadastromembros.cep = '';
            }
          },
          (error) => {
            this.StopProgressBar();
            this.alert.AlertShowDanger(
              '( ' + error.status + ' ) ' + error.message
            );
          }
        );
    }
  }
  //====================================================================//




  // Pegar nome dos campos,
  //====================================================================//
  get f() {
    return this.formcadastro.controls;
  }
  //====================================================================//

  //BOTAO SALVAR
  //====================================================================//
  onSubmit() {
    this.submitted = true;
    if (this.formcadastro.invalid) {
      this.alert.AlertShowDanger('Favor verificar os campos em destaque!');
      return;
    }
    if (this.formcadastro.status == 'DISABLED') {
      return;
    }
    this.formcadastro.get('cpf').setValue(this.autenticado.cpf);
    this.formcadastro.get('nome').setValue(this.autenticado.nome);
    this._serviceestabelecimento
      .CadastroEstabelecimento(this.formcadastro.value, this.autenticado.token)
      .subscribe(
        (sucess) => {
          this.StopProgressBar();
          this.submitted = false;
          this.alert.AlertShowSucess('Oportunidade Registrada com sucesso!');
          this.cadastromembros = new OportunidadeModel();
          this.criarform();
          this.cadastromembros.cpf = this.autenticado.cpf;
          this.cadastromembros.nome = this.autenticado.nome;
          this.formcadastro.get("idusuario").setValue(this.autenticado.iduser);
          document.getElementById('oportunidade').focus();
          this.formcadastro.get('idusuario').setValue(this.autenticado.iduser);
          this.formcadastro.get('cpf').setValue(this.autenticado.cpf);
          this.formcadastro.get('nome').setValue(this.autenticado.nome);
          this.formcadastro.get("idusuario").setValue(this.autenticado.iduser);

          this._serviceestabelecimento
            .Quantidade(this.autenticado.token)
            .subscribe(
              () => {
                this.formcadastro.enable();

              },
              (error) => {
                this.formcadastro.disable();

              }
            )

        },
        (error) => {
          this.StopProgressBar();
          this.submitted = false;
          if (error.status == 422) {
            this.alert.AlertShowDanger('Já existe uma Oportunidade com o nome informado!');
          } else {
            this.alert.AlertShowDanger('Erro ao tentar registrar Oportunidade');
          }
        }
      );
    //====================================================================//
  }
  //====================================================================//

  instagram() {
    this.cadastromembros.instagram = 'https://www.instagram.com/moradajfoficial/';
  }

  maps() {
    this.cadastromembros.maps = 'https://www.google.com.br/maps/@-21.7416378,-43.3744249,3a,75y,32.52h,64.56t/data=!3m6!1e1!3m4!1sD8h6bxziytkJnut-Z7j4ug!2e0!7i13312!8i6656';
  }

  facebook() {
    this.cadastromembros.facebook = 'https://www.facebook.com/igrejapentecostalMoradadeDeus';
  }

  site() {
    this.cadastromembros.site = 'http://www.moradadedeus.com.br';

  }
}
