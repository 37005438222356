import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { Parametros } from 'src/app/shared/parametros.enum';
import { ConfirmarDocumentoSeminario } from './ConfirmarDocumentoSeminario';
import { ConfirmarDocumentoSerminarioService } from './confirmar-documento-serminario.service';

@Component({
  selector: 'app-checkout-documento-seminario',
  templateUrl: './checkout-documento-seminario.component.html',
  styleUrls: ['./checkout-documento-seminario.component.css']
})
export class CheckoutDocumentoSeminarioComponent implements OnInit {

  formculto: FormGroup;
  submitted = false;
  cadastroculto: ConfirmarDocumentoSeminario;


  startedClass = false;
  completedClass = false;
  preventAbuse = false;
  rota: Router;

  registro: string = '';
  status: string = '';
  user: string = '';
  hash: string = '';
  nome: string = '';
  data: string = '';
  hora: string = '';
  isDone = '';

  private urlcadastro = Parametros.SEMINARIO;


  listapara: any = [
    'Batalha Espiritual e Cura Interior',
    'Louvor Adoração e Chamado'
  ];

  constructor(
    private formBuilder: FormBuilder,
    private alert: AlertModalService,
    private service: ConfirmarDocumentoSerminarioService,
    private router: Router
  ) {
    this.rota = this.router;
  }

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//

  criarform() {
    this.formculto = this.formBuilder.group({
      c: [Validators.required],
      documento: ['', [Validators.required, Validators.maxLength(14), Validators.minLength(11)]]
    });
  }


  SetPara(event) {
    if (event.target.value.replace("1:", "").replace("2:", "").replace("3:", "").replace("4:", "").replace("5:", "").replace("6:", "").replace("7:", "").replace("8:", "").replace("9:", "").replace("10:", "").includes("02/05 - Domingo ( 08:30 )")) {
      this.formculto.controls['para'].setValue("02/05 - Domingo ( 08:30 )");
    } else if (event.target.value.replace("1:", "").replace("2:", "").replace("3:", "").replace("4:", "").replace("5:", "").replace("6:", "").replace("7:", "").replace("8:", "").replace("9:", "").replace("10:", "").includes("02/05 - Domingo ( 19:30 )")) {
      this.formculto.controls['para'].setValue("02/05 - Domingo ( 19:30 )");
    } else if (event.target.value.replace("1:", "").replace("2:", "").replace("3:", "").replace("4:", "").replace("5:", "").replace("6:", "").replace("7:", "").replace("8:", "").replace("9:", "").replace("10:", "").includes("09/05 - Domingo ( 08:30 )")) {
      this.formculto.controls['para'].setValue("09/05 - Domingo ( 08:30 )");
    } else if (event.target.value.replace("1:", "").replace("2:", "").replace("3:", "").replace("4:", "").replace("5:", "").replace("6:", "").replace("7:", "").replace("8:", "").replace("9:", "").replace("10:", "").includes("09/05 - Domingo ( 19:30 )")) {
      this.formculto.controls['para'].setValue("09/05 - Domingo ( 19:30 )");
    }
    else if (event.target.value.replace("1:", "").replace("2:", "").replace("3: ", "").replace("4:", "").replace("5:", "").replace("6:", "").replace("7:", "").replace("8:", "").replace("9:", "").replace("10:", "").includes("06/05 - Quinta Profética)")) {
      this.formculto.controls['para'].setValue("06/05 - Quinta Profética)");
    }

  }

  //BOTAO SALVAR
  //====================================================================//
  onSubmit() {
    this.submitted = true;

    if (this.formculto.controls['documento'].value.length < 8) {
      this.alert.AlertShowDanger('DOCUMENTO INVÁLIDO');
      document.getElementById('cpf').focus();
      this.formculto.controls['cpf'].setValue("");
      return;
    }


    if (this.formculto.invalid) {

      this.alert.AlertShowDanger('Favor verificar os campos em destaque!');
      return;
    } else {
      var x = this.formculto.controls['c'].value;

      if (x == "" || x == "null") {
        this.alert.AlertShowDanger('Favor Preencher o Evento / Seminario!');
        return;
      }
      this.service.ConfirmarCadastro(this.formculto.controls['documento'].value, this.formculto.controls['c'].value).subscribe(
        (success) => {
          this.cadastroculto = new ConfirmarDocumentoSeminario();
          document.getElementById('documento').focus();
          this.user = success.nome;
          this.isDone = 'autorizado';
          this.registro = success.hash;
          this.formculto.get("c").patchValue(x);
          this.formculto.get("documento").patchValue("");
        },
        (error) => {
          if (error.status == "400") {
            this.StopProgressBar();
            this.user = "REGISTRO NÃO EXISTE EM NOSSA BASE";
            this.isDone = 'nao_encontrado';
            this.registro = "DOCUMENTO NÃO ENCONTRADO EM NOSSA BASE";
            this.formculto.get("c").patchValue(x);
            this.formculto.get("documento").patchValue("");
            return;
          } else {
            this.StopProgressBar();
            this.user = "REGISTRO JÁ FOI UTILIZADO NA ENTRADA";
            this.isDone = 'usado';
            this.data = error.error.data_confirmado;
            this.hora = error.error.hora_confirmado;
            this.registro = "DOCUMENTO JÁ FOI UTILIZADO ANTERIORMENTE";
            this.formculto.get("documento").patchValue("");
            this.formculto.get("c").patchValue(x);
            return;
          }
        }
      );
    }
    //====================================================================//
  }
  //====================================================================//



  // Pegar nome dos campos,
  //====================================================================//
  get f() {
    return this.formculto.controls;
  }

  //Inicio
  //====================================================================//
  ngOnInit() {
    this.cadastroculto = new ConfirmarDocumentoSeminario();
    this.criarform();
    document.getElementById('documento').focus();
  }



}