import { Component, OnInit } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { EventEmitterService } from 'src/app/shared/EventEmitterService';
import { Router } from '@angular/router';
import { DashboardService } from './dashboard.service';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Parametros } from 'src/app/shared/parametros.enum';



@Component({
  selector: 'app-dashboard-home',
  templateUrl: './dashboard-home.component.html',
  styleUrls: ['./dashboard-home.component.css']
})
export class DashboardHomeComponent implements OnInit {
  startedClass = false;
  completedClass = false;
  _user: string = '';
  _perfil: string = '';
  _ministerio: string = '';
  _curso: string = '';
  _cpf: string = '';
  _registro: string = '';
  _acesso: string = '';
  _hora: string = '';
  arrDataExclusao: string = '';
  _servicedashboard: DashboardService;



  rota: Router;
  urlrelatoriotodos = Parametros.RELATORIOSTODOSSEMINARIO;
  urlrelatoriotodosinfantil = Parametros.RELATORIOSTODOSSEMINARIOINFANTIL;
  urlrelatoriotodosencontromulheres = Parametros.RELATORIOSTODOSENCONTROMULHERES;
  urlrelatoriotodosencontrohomens = Parametros.RELATORIOSTODOSENCONTROHOMENS;
  urlrelatoriotodosencontrocasais = Parametros.RELATORIOSTODOSENCONTROCASAIS;
  urlrelatoriotodosmembros = Parametros.RELATORIOSTODOSMEMBROS;
  urlrelatoriotodoVisitantes = Parametros.RELATORIOSTODOSVISITANTES;
  urlrelatoriotodocultoregistrado = Parametros.RELATORIOSTODOSCULTOREGISTRADO;
  urlrelatoriotodobatismoregistrado = Parametros.RELATORIOSTODOSBATISMOREGISTRADO;
  urlrelatoriotodocongressomulheres = Parametros.RELATORIOSTODOSCONGRESSOMULHERES;
  urlrelatoriotodocongressohomens = Parametros.RELATORIOSTODOSCONGRESSOHOMENS;
  urlrelatoriotodocongressojovens = Parametros.RELATORIOSTODOSCONGRESSOJOVENS;
  urlrelatoriotodospatrimonio = Parametros.RELATORIOTODOSPATRIMONIO;
  urlrelatoriotodoscasal = Parametros.RELATORIOSTODOSCURSOCASAIS;
  urlrelatoriotodocultokidsregistrado = Parametros.RELATORIOSTODOSCULTOKIDSREGISTRADO;
  private urlcadastrocultos = Parametros.CADASTROCULTO;
  private urlcadastrobatismo = Parametros.BATISMO;
  private urlcadastrocongressomulheres = Parametros.CONGRESSOMULHERES;
  private urlcadastrocongressohomens = Parametros.CONGRESSOHOMENS;
  private urlcadastrocongressojovens = Parametros.CONGRESSOJOVENS;
  private urlcadastrocultoskids = Parametros.CADASTROCULTOKIDS;
  private urlpatrimonio = Parametros.PATRIMONIO;
  imprimir: string = "Todos";
  resposta: any;
  cultos: any;
  setores: any;
  cultoskids: any;
  congressos: any;
  referencias: any;
  congressoshomens: any;
  congressosjovens: any;



  constructor(roter: Router,
    private http: HttpClient,
    private autenticado: SharedModule,
    private alert: AlertModalService,
    private s: DashboardService
  ) {
    this.rota = roter;
    this._servicedashboard = s;


  }




  GETListagemCultosKids() {
    this.StartProgressBar();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token
      })
    };
    return this.http
      .get<any>(this.urlcadastrocultoskids + 'GETlistaCultos', httpOptions).subscribe(
        (success) => {
          this.cultoskids = success;
        },
        (error) => {

        }
      )
  }

  GETListagemCultos() {
    this.StartProgressBar();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token
      })
    };
    return this.http
      .get<any>(this.urlcadastrocultos + 'GETListagemCultos', httpOptions).subscribe(
        (success) => {
          this.cultos = success;
        },
        (error) => {

        }
      )
  }

  GETListagemReferencias() {
    this.StartProgressBar();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token
      })
    };
    return this.http
      .get<any>(this.urlcadastrobatismo + 'GETlistaReferenciaBatismoUltimos', httpOptions).subscribe(
        (success) => {
          this.referencias = success;
        },
        (error) => {

        }
      )
  }

  GETListagemCongressoMulheres() {
    this.StartProgressBar();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token
      })
    };
    return this.http
      .get<any>(this.urlcadastrocongressomulheres + 'GETListagemCongressosmulheres', httpOptions).subscribe(
        (success) => {
          this.congressos = success;
        },
        (error) => {

        }
      )
  }



  GETListagemCongressoHomens() {
    this.StartProgressBar();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token
      })
    };
    return this.http
      .get<any>(this.urlcadastrocongressohomens + 'GETListagemCongressoshomens', httpOptions).subscribe(
        (success) => {
          this.congressoshomens = success;
        },
        (error) => {

        }
      )
  }


  GETListagemCongressoJovens() {
    this.StartProgressBar();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token
      })
    };
    return this.http
      .get<any>(this.urlcadastrocongressojovens + 'GETListagemCongressosjovens', httpOptions).subscribe(
        (success) => {
          this.congressosjovens = success;
        },
        (error) => {

        }
      )
  }

  GETListagemSetores() {
    this.StartProgressBar();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token
      })
    };
    return this.http
      .get<any>(this.urlpatrimonio + 'GETlistaSetores', httpOptions).subscribe(
        (success) => {
          this.setores = success;
        },
        (error) => {

        }
      )
  }



  ngOnInit() {
    //Efetuar controle na abertura
    if (this.autenticado.token == null || this.autenticado.token == '') {
      this.autenticado.ResetDados();
      EventEmitterService.get('loginAlert').emit(false);
      this.alert.AlertShowDanger('Usuário não autenticado, redirecionando...')
      this.rota.navigate(['/login']);

    } else {
      //setar autenticação por garantia
      EventEmitterService.get('loginAlert').emit(true);


      //Pegar dados token
      this._servicedashboard.ConsultaTokens(this.autenticado.token).subscribe(
        (success) => {
          this.StopProgressBar();
          if (success != null) {

            this.autenticado.dt_geracao_token = success.data_token_geracao;
            this.autenticado.dt_validade_token = success.data_token_expiracao;
            this.autenticado.hora_token_geracao = success.hora_token_geracao;
            this.autenticado.hora_validade_token = success.hora_token_expiracao;

            if (this.autenticado.ValidarToken() == true) {
              localStorage.setItem('dadosusuario', JSON.stringify(success));
              if (success.email == "" || success.email == null) {
                this.autenticado.email = "";
              } else {
                this.autenticado.email = success.email;
              }
              this.autenticado.hash = success.hash;
              this.autenticado.iduser = success.idusuario;
              this.autenticado.nome = success.nome;
              this.autenticado.registro = success.numero_registro;
              this.autenticado.perfil = success.perfil;
              this.autenticado.ministerio = success.ministerio;
              this.autenticado.curso = success.curso;
              this.autenticado.cpf = success.cpf;
              this.autenticado.iduser = success.idusuario;
              this._perfil = success.perfil;
              this._ministerio = success.ministerio;
              this._curso = success.curso;
              this._user = success.nome;
              this._cpf = success.cpf;
              this._registro = success.numero_registro;
              this._acesso = success.dt_acesso;
              this._hora = success.hora_acesso;
              //Pegar ministerio para deducoes
              this._servicedashboard.retornarcentrocusto(this.autenticado.cpf).subscribe(
                (success) => {
                  if (success == null) {
                  }
                }
                , (error) => {
                  this.autenticado.ministerio_custo = error.error.text;
                });

              this.rota.navigate(['/dashboard/resumo']);

            } else {
              this.autenticado.ResetDados();
              this.rota.navigate(['/login']);
            }
          } else {
            this.autenticado.ResetDados();
            this.rota.navigate(['/login']);
          }
        },
        (error) => {
          this.StopProgressBar();
          this.autenticado.ResetDados();
          this.rota.navigate(['/login']);
        });
      this.GETListagemCultos();
      this.GETListagemCultosKids();
      this.GETListagemSetores();
      this.GETListagemCongressoMulheres();
      this.GETListagemCongressoHomens();
      this.GETListagemCongressoJovens();
      this.GETListagemReferencias();





    }

    //JQ do collapse
    $(document).ready(function () {
      $('#sidebarCollapse').on('click', function () {
        $('#sidebar').toggleClass('active');
      });
    });
  }

  /*
  changeVisibility() {
    var $icon = document.getElementById("toggler");
    if ($icon.classList.contains('fa-toggle-off')) {
      $icon.classList.add('fa-toggle-on');
      $icon.classList.remove('fa-toggle-off');
    } else if ($icon.classList.contains('fa-toggle-on')) {
      $icon.classList.add('fa-toggle-off');
      $icon.classList.remove('fa-toggle-on');
    }
  }
  */

  //BARRA DE PROGRESSO
  //=================================
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }

  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //=================================


  //Botão Sair
  exit() {
    this.StopProgressBar();
    this.autenticado.ResetDados();
    this.rota.navigate(['/login']);
  }
  //=================================================//


  //MODAL ETIQUETAS
  ValorImpressaoEtiqueta(event) {
    this.imprimir = "TODOS";
    this.imprimir = event.currentTarget.value;
  }

  //IMPRIMIR ETIQUETAS
  GerarPDFEtiquetas() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token
      })
    }
    this.http.get(this.urlrelatoriotodospatrimonio + 'GetRelatorioPatrimonioQrcode/?parametro=' + this.imprimir, httpOptions).subscribe(
      (success) => {
        const linkSource = 'data:application/pdf;base64,' + success;
        const downloadLink = document.createElement("a");
        const fileName = "IEPMD_Etiquetas_Patrimonio_" + (this.autenticado.nome).replace(" ", "") + ".pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }, (error) => {
        if (error.status == '403') {
          this.alert.AlertShowDanger('Erro ao tentar Gerar PDF');
        } else {
          const linkSource = 'data:application/pdf;base64,' + error.error.text;
          const downloadLink = document.createElement("a");
          const fileName = "IEPMD_Etiquetas_Patrimonio_" + (this.autenticado.nome).replace(" ", "") + ".pdf";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      }
    );
  }

  //////////////////////////////

  //MODAL CASAL
  ValorImpressaoCasals(event) {
    this.imprimir = "TODOS";
    this.imprimir = event.currentTarget.value;
  }

  //IMPRIMIR DETALHE CASAL
  GerarPDFDetalheCasal() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token
      })
    }
    this.http.get(this.urlrelatoriotodoscasal + 'GetRelatorioTodosCasaisDetalhado/?parametro=' + this.imprimir, httpOptions).subscribe(
      (success) => {
        const linkSource = 'data:application/pdf;base64,' + success;
        const downloadLink = document.createElement("a");
        const fileName = "IEPMD_Relatorio_Casal_" + (this.autenticado.nome).replace(" ", "") + ".pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }, (error) => {
        if (error.status == '403') {
          this.alert.AlertShowDanger('Erro ao tentar Gerar PDF');
        } else {
          const linkSource = 'data:application/pdf;base64,' + error.error.text;
          const downloadLink = document.createElement("a");
          const fileName = "IEPMD_Relatorio_Casal_" + (this.autenticado.nome).replace(" ", "") + ".pdf";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      }
    );
  }
  ////////////////////
  //MODAL CASAL SIMPLES
  ValorImpressaoCasalssimples(event) {
    this.imprimir = "TODOS";
    this.imprimir = event.currentTarget.value;
  }

  //IMPRIMIR DETALHE CASAL SIMPLES
  GerarPDFDetalheCasalsimples() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token
      })
    }
    this.http.get(this.urlrelatoriotodoscasal + 'GetListagemCursoCasais/?parametro=' + this.imprimir, httpOptions).subscribe(
      (success) => {
        const linkSource = 'data:application/pdf;base64,' + success;
        const downloadLink = document.createElement("a");
        const fileName = "IEPMD_Relatorio_Casal_" + (this.autenticado.nome).replace(" ", "") + ".pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }, (error) => {
        if (error.status == '403') {
          this.alert.AlertShowDanger('Erro ao tentar Gerar PDF');
        } else {
          const linkSource = 'data:application/pdf;base64,' + error.error.text;
          const downloadLink = document.createElement("a");
          const fileName = "IEPMD_Relatorio_Casal_" + (this.autenticado.nome).replace(" ", "") + ".pdf";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      }
    );
  }

  //MODAL CONGRESSO MULHERES
  ValorImpressaocongressomulheres(event) {
    this.imprimir = "TodosCadastrados";
    this.imprimir = event.currentTarget.value;
  }

  //IMPRIMIR CONGRESSO MULHERES
  GerarPDFCongressoMulheres() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token
      })
    }
    this.http.get(this.urlrelatoriotodocongressomulheres + 'GetListagemCongressoMulheres/?parametro=' + this.imprimir, httpOptions).subscribe(
      (success) => {
        const linkSource = 'data:application/pdf;base64,' + success;
        const downloadLink = document.createElement("a");
        const fileName = "IEPMD_Listagem_congresso_mullheres_" + (this.autenticado.nome).replace(" ", "") + ".pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }, (error) => {
        if (error.status == '403') {
          this.alert.AlertShowDanger('Erro ao tentar Gerar PDF');
        } else {
          const linkSource = 'data:application/pdf;base64,' + error.error.text;
          const downloadLink = document.createElement("a");
          const fileName = "IEPMD_Listagem_congresso_mullheres_" + (this.autenticado.nome).replace(" ", "") + ".pdf";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      }
    );
  }



  //MODAL CONGRESSO HOMENS
  ValorImpressaocongressohomens(event) {
    this.imprimir = "TodosCadastrados";
    this.imprimir = event.currentTarget.value;
  }

  //IMPRIMIR CONGRESSO HOMENS
  GerarPDFCongressoHomens() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token
      })
    }
    this.http.get(this.urlrelatoriotodocongressohomens + 'GetListagemCongressoHomens/?parametro=' + this.imprimir, httpOptions).subscribe(
      (success) => {
        const linkSource = 'data:application/pdf;base64,' + success;
        const downloadLink = document.createElement("a");
        const fileName = "IEPMD_Listagem_congresso_homens_" + (this.autenticado.nome).replace(" ", "") + ".pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }, (error) => {
        if (error.status == '403') {
          this.alert.AlertShowDanger('Erro ao tentar Gerar PDF');
        } else {
          const linkSource = 'data:application/pdf;base64,' + error.error.text;
          const downloadLink = document.createElement("a");
          const fileName = "IEPMD_Listagem_congresso_homens_" + (this.autenticado.nome).replace(" ", "") + ".pdf";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      }
    );
  }

  //MODAL CONGRESSO JOVENS
  ValorImpressaocongressojovens(event) {
    this.imprimir = "TodosCadastrados";
    this.imprimir = event.currentTarget.value;
  }

  //IMPRIMIR CONGRESSO JOVENS
  GerarPDFCongressoJovens() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token
      })
    }
    this.http.get(this.urlrelatoriotodocongressojovens + 'GetListagemCongressoJovens/?parametro=' + this.imprimir, httpOptions).subscribe(
      (success) => {
        const linkSource = 'data:application/pdf;base64,' + success;
        const downloadLink = document.createElement("a");
        const fileName = "IEPMD_Listagem_congresso_jovens_" + (this.autenticado.nome).replace(" ", "") + ".pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }, (error) => {
        if (error.status == '403') {
          this.alert.AlertShowDanger('Erro ao tentar Gerar PDF');
        } else {
          const linkSource = 'data:application/pdf;base64,' + error.error.text;
          const downloadLink = document.createElement("a");
          const fileName = "IEPMD_Listagem_congresso_jovens_" + (this.autenticado.nome).replace(" ", "") + ".pdf";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      }
    );
  }



  //MODAL BATISMO
  ValorImpressaobatismo(event) {
    this.imprimir = "TodosCadastrados";
    this.imprimir = event.currentTarget.value;
  }


  //IMPRIMIR BATISMO
  GerarPDFBatismo() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token
      })
    }
    this.http.get(this.urlrelatoriotodobatismoregistrado + 'GetlistagemBatismo/?parametro=' + this.imprimir, httpOptions).subscribe(
      (success) => {
        const linkSource = 'data:application/pdf;base64,' + success;
        const downloadLink = document.createElement("a");
        const fileName = "IEPMD_Listagem_Batismo_" + (this.autenticado.nome).replace(" ", "") + ".pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }, (error) => {
        if (error.status == '403') {
          this.alert.AlertShowDanger('Erro ao tentar Gerar PDF');
        } else {
          const linkSource = 'data:application/pdf;base64,' + error.error.text;
          const downloadLink = document.createElement("a");
          const fileName = "IEPMD_Listagem_Batismo_" + (this.autenticado.nome).replace(" ", "") + ".pdf";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      }
    );
  }


  //MODAL CULTOS
  ValorImpressaoculto(event) {
    this.imprimir = "TodosCadastrados";
    this.imprimir = event.currentTarget.value;
  }

  //IMPRIMIR CULTOS
  GerarPDFCulto() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token
      })
    }
    this.http.get(this.urlrelatoriotodocultoregistrado + 'GetListagemCulto/?parametro=' + this.imprimir, httpOptions).subscribe(
      (success) => {
        const linkSource = 'data:application/pdf;base64,' + success;
        const downloadLink = document.createElement("a");
        const fileName = "IEPMD_Listagem_culto_" + (this.autenticado.nome).replace(" ", "") + ".pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }, (error) => {
        if (error.status == '403') {
          this.alert.AlertShowDanger('Erro ao tentar Gerar PDF');
        } else {
          const linkSource = 'data:application/pdf;base64,' + error.error.text;
          const downloadLink = document.createElement("a");
          const fileName = "IEPMD_Listagem_culto_" + (this.autenticado.nome).replace(" ", "") + ".pdf";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      }
    );
  }
  //=====================================================================================//


  ValorImpressaocultokids(event) {
    this.imprimir = "Todos usuarios Cadastrados ( Ativos e Inativos )";

    if (event.currentTarget.value == "Todos usuarios Cadastrados ( Ativos e Inativos )") {
      this.imprimir = "Todos usuarios Cadastrados ( Ativos e Inativos )";
    } else if (event.currentTarget.value == "Todos usuarios Ativos") {
      this.imprimir = "Todos usuarios Ativos";
    }
    else if (event.currentTarget.value == "Todos usuarios Inativos") {
      this.imprimir = "Todos usuarios Inativos";
    }

    else {
      this.imprimir = event.currentTarget.value;
    }
  }




  GerarPDFCultoKids() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token
      })
    }
    this.http.get(this.urlrelatoriotodocultokidsregistrado + 'GetListagemCulto/?parametro=' + this.imprimir, httpOptions).subscribe(
      (success) => {
        const linkSource = 'data:application/pdf;base64,' + success;
        const downloadLink = document.createElement("a");
        const fileName = "IEPMD_Listagem_culto_KIDS_" + (this.autenticado.nome).replace(" ", "") + ".pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }, (error) => {
        if (error.status == '403') {
          this.alert.AlertShowDanger('Erro ao tentar Gerar PDF');
        } else {
          const linkSource = 'data:application/pdf;base64,' + error.error.text;
          const downloadLink = document.createElement("a");
          const fileName = "IEPMD_Listagem_culto_KIDS_" + (this.autenticado.nome).replace(" ", "") + ".pdf";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      }
    );
  }


  ValorImpressao(event) {
    this.imprimir = "Todos Registros ( Ativos e Inativos )";

    if (event.currentTarget.value == "Todos Registros ( Ativos e Inativos )") {
      this.imprimir = "Todos Registros ( Ativos e Inativos )";
    } else if (event.currentTarget.value == "Todos Registros Ativos") {
      this.imprimir = "Todos Registros Ativos";
    }
    else if (event.currentTarget.value == "Todos Registros Inativos") {
      this.imprimir = "Todos Registros Inativos";
    }
    else if (event.currentTarget.value == "CRESCIMENTO E DESENVOLVIMENTO DE MINISTÉRIOS") {
      this.imprimir = "Crescimento e desenvolvimento de ministério"
    } else if (event.currentTarget.value == "BATALHA ESPIRITUAL E CURA INTERIOR") {
      this.imprimir = "Batalha espiritual e cura interior";
    } else {
      this.imprimir = "Louvor Adoração e Chamado";
    }
  }




  GerarPDF() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token
      })
    }
    this.http.get(this.urlrelatoriotodos + 'GetListagemSeminario/?parametro=' + this.imprimir, httpOptions).subscribe(
      (success) => {
        const linkSource = 'data:application/pdf;base64,' + success;
        const downloadLink = document.createElement("a");
        const fileName = "IEPMD_Listagem_Seminario_" + (this.autenticado.nome).replace(" ", "") + ".pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }, (error) => {
        if (error.status == '403') {
          this.alert.AlertShowDanger('Erro ao tentar Gerar PDF');
        } else {
          const linkSource = 'data:application/pdf;base64,' + error.error.text;
          const downloadLink = document.createElement("a");
          const fileName = "IEPMD_Listagem_Seminario_" + (this.autenticado.nome).replace(" ", "") + ".pdf";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      }
    );
  }


  ValorImpressaoInfantil(event) {
    this.imprimir = "Todos Registros ( Ativos e Inativos )";

    if (event.currentTarget.value == "Todos Registros ( Ativos e Inativos )") {
      this.imprimir = "Todos Registros ( Ativos e Inativos )";
    } else if (event.currentTarget.value == "Todos Registros Ativos") {
      this.imprimir = "Todos Registros Ativos";
    }
    else if (event.currentTarget.value == "Todos Registros Inativos") {
      this.imprimir = "Todos Registros Inativos";
    }
    else if (event.currentTarget.value == "Festinha da Morada Kids") {
      this.imprimir = "Festinha da Morada Kids";
    }
    else {
      this.imprimir = "Buscando meu amigo Espírito Santo"
    }
  }

  ValorImpressaoEncontroMulheres(event) {
    this.imprimir = "Todos Registros ( Ativos e Inativos )";

    if (event.currentTarget.value == "Todos Registros ( Ativos e Inativos )") {
      this.imprimir = "Todos Registros ( Ativos e Inativos )";
    } else if (event.currentTarget.value == "Todos Registros Ativos") {
      this.imprimir = "Todos Registros Ativos";
    }
    else if (event.currentTarget.value == "Todos Registros Inativos") {
      this.imprimir = "Todos Registros Inativos";
    }
    else {
      this.imprimir = ""
    }
  }


  ValorImpressaoEncontroCasais(event) {
    this.imprimir = "Todos Registros ( Ativos e Inativos )";

    if (event.currentTarget.value == "Todos Registros ( Ativos e Inativos )") {
      this.imprimir = "Todos Registros ( Ativos e Inativos )";
    } else if (event.currentTarget.value == "Todos Registros Ativos") {
      this.imprimir = "Todos Registros Ativos";
    }
    else if (event.currentTarget.value == "Todos Registros Inativos") {
      this.imprimir = "Todos Registros Inativos";
    }
    else if (event.currentTarget.value == "Todos Trabalhando") {
      this.imprimir = "Todos Trabalhando";
    }
    else if (event.currentTarget.value == "Todos Passando") {
      this.imprimir = "Todos Passando";
    }
    else {
      this.imprimir = ""
    }
  }


  ValorImpressaoEncontroHomens(event) {
    this.imprimir = "Todos Registros ( Ativos e Inativos )";

    if (event.currentTarget.value == "Todos Registros ( Ativos e Inativos )") {
      this.imprimir = "Todos Registros ( Ativos e Inativos )";
    } else if (event.currentTarget.value == "Todos Registros Ativos") {
      this.imprimir = "Todos Registros Ativos";
    }
    else if (event.currentTarget.value == "Todos Registros Inativos") {
      this.imprimir = "Todos Registros Inativos";
    }
    else if (event.currentTarget.value == "Todos Trabalhando") {
      this.imprimir = "Todos Trabalhando";
    }
    else if (event.currentTarget.value == "Todos Passando") {
      this.imprimir = "Todos Passando";
    }
    else {
      this.imprimir = ""
    }
  }

  GerarPDFInfantil() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token
      })
    }
    this.http.get(this.urlrelatoriotodosinfantil + 'GetListagemSeminarioinfantil/?parametro=' + this.imprimir, httpOptions).subscribe(
      (success) => {
        const linkSource = 'data:application/pdf;base64,' + success;
        const downloadLink = document.createElement("a");
        const fileName = "IEPMD_Listagem_Seminario_Infantil_" + (this.autenticado.nome).replace(" ", "") + ".pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }, (error) => {
        if (error.status == '403') {
          this.alert.AlertShowDanger('Erro ao tentar Gerar PDF');
        } else {
          const linkSource = 'data:application/pdf;base64,' + error.error.text;
          const downloadLink = document.createElement("a");
          const fileName = "IEPMD_Listagem_Seminario_Infantil_" + (this.autenticado.nome).replace(" ", "") + ".pdf";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      }
    );
  }


  GerarPDFEncontroMulheres() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token
      })
    }
    this.http.get(this.urlrelatoriotodosencontromulheres + 'GetListagemEncontroMulheres/?parametro=' + this.imprimir, httpOptions).subscribe(
      (success) => {
        const linkSource = 'data:application/pdf;base64,' + success;
        const downloadLink = document.createElement("a");
        const fileName = "IEPMD_Listagem_Encontro_Mulheres_" + (this.autenticado.nome).replace(" ", "") + ".pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }, (error) => {
        if (error.status == '403') {
          this.alert.AlertShowDanger('Erro ao tentar Gerar PDF');
        } else {
          const linkSource = 'data:application/pdf;base64,' + error.error.text;
          const downloadLink = document.createElement("a");
          const fileName = "IEPMD_Listagem_Encontro_Mulheres_" + (this.autenticado.nome).replace(" ", "") + ".pdf";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      }
    );
  }


  GerarPDFEncontroHomens() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token
      })
    }
    this.http.get(this.urlrelatoriotodosencontrohomens + 'GetListagemEncontroHomens/?parametro=' + this.imprimir, httpOptions).subscribe(
      (success) => {
        const linkSource = 'data:application/pdf;base64,' + success;
        const downloadLink = document.createElement("a");
        const fileName = "IEPMD_Listagem_Encontro_Homens_" + (this.autenticado.nome).replace(" ", "") + ".pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }, (error) => {
        if (error.status == '403') {
          this.alert.AlertShowDanger('Erro ao tentar Gerar PDF');
        } else {
          const linkSource = 'data:application/pdf;base64,' + error.error.text;
          const downloadLink = document.createElement("a");
          const fileName = "IEPMD_Listagem_Encontro_Homens_" + (this.autenticado.nome).replace(" ", "") + ".pdf";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      }
    );
  }


  GerarPDFEncontroCasais() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token
      })
    }
    this.http.get(this.urlrelatoriotodosencontrocasais + 'GetListagemEncontroCasais/?parametro=' + this.imprimir, httpOptions).subscribe(
      (success) => {
        const linkSource = 'data:application/pdf;base64,' + success;
        const downloadLink = document.createElement("a");
        const fileName = "IEPMD_Listagem_Encontro_Casais_" + (this.autenticado.nome).replace(" ", "") + ".pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }, (error) => {
        if (error.status == '403') {
          this.alert.AlertShowDanger('Erro ao tentar Gerar PDF');
        } else {
          const linkSource = 'data:application/pdf;base64,' + error.error.text;
          const downloadLink = document.createElement("a");
          const fileName = "IEPMD_Listagem_Encontro_Casais_" + (this.autenticado.nome).replace(" ", "") + ".pdf";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      }
    );
  }



  ValorImpressaoMembros(event) {
    this.imprimir = "Todos";

    if (event.currentTarget.value == "Todos") {
      this.imprimir = "Todos";
    } else if (event.currentTarget.value == "TodosAtivos") {
      this.imprimir = "TodosAtivos";
    }
    else if (event.currentTarget.value == "TodosInativos") {
      this.imprimir = "TodosInativos";
    } else {
      this.imprimir = "TodosCancelados";

    }
  }


  GerarPDFMembros() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token
      })
    }
    this.http.get(this.urlrelatoriotodosmembros + 'GetListagemMembros/?parametro=' + this.imprimir, httpOptions).subscribe(
      (success) => {
        const linkSource = 'data:application/pdf;base64,' + success;
        const downloadLink = document.createElement("a");
        const fileName = "IEPMD_Listagem_Membros_" + (this.autenticado.nome).replace(" ", "") + ".pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }, (error) => {
        if (error.status == '403') {
          this.alert.AlertShowDanger('Erro ao tentar Gerar PDF');
        } else {
          const linkSource = 'data:application/pdf;base64,' + error.error.text;
          const downloadLink = document.createElement("a");
          const fileName = "IEPMD_Listagem_Membros_" + (this.autenticado.nome).replace(" ", "") + ".pdf";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      }
    );
  }




  ValorImpressaoVisitantes(event) {
    this.imprimir = "Todos";

    if (event.currentTarget.value == "TodosContatoRealizado") {
      this.imprimir = "TodosContatoRealizado";
    } else if (event.currentTarget.value == "TodosContatoNaoRealizado") {
      this.imprimir = "TodosContatoNaoRealizado";
    }
    else if (event.currentTarget.value == "TodosPrimeiraVisita") {
      this.imprimir = "TodosPrimeiraVisita";
    }
    else if (event.currentTarget.value == "TodosSegundaVisita") {
      this.imprimir = "TodosSegundaVisita";
    } else {
      this.imprimir = "Todos";

    }
  }

  GerarPDFVisitantes() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token
      })
    }
    this.http.get(this.urlrelatoriotodoVisitantes + 'GetListagemVisitantes/?parametro=' + this.imprimir, httpOptions).subscribe(
      (success) => {
        const linkSource = 'data:application/pdf;base64,' + success;
        const downloadLink = document.createElement("a");
        const fileName = "IEPMD_Listagem_Visitantes_" + (this.autenticado.nome).replace(" ", "") + ".pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }, (error) => {
        if (error.status == '403') {
          this.alert.AlertShowDanger('Erro ao tentar Gerar PDF');
        } else {
          const linkSource = 'data:application/pdf;base64,' + error.error.text;
          const downloadLink = document.createElement("a");
          const fileName = "IEPMD_Listagem_Visitantes_" + (this.autenticado.nome).replace(" ", "") + ".pdf";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      }
    );
  }

  Backup() {
    this._servicedashboard.FazerBackup(this.autenticado.token).subscribe(
      (success) => {
        this.StopProgressBar();
        this.alert.AlertShowSucess('Backup Realizado e enviado por E-mail!')
      }
      , (error) => {
        this.StopProgressBar();
        this.alert.AlertShowDanger('Usuario sem Permissão para efetuar Backup!')
      }
    );
  }

  BotBackup() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get('https://api.moradadedeus.com.br/botbackup/sendbackup/', httpOptions).subscribe(
      (success) => {
        this.StopProgressBar();
        this.alert.AlertShowSucess('Bot Moradadedeus lhe atendeu!')
      }, (error) => {
        if (error.status == '200') {
          this.StopProgressBar();
          this.alert.AlertShowSucess('Bot Moradadedeus lhe atendeu!')
        }
        else {
          this.StopProgressBar();
          this.alert.AlertShowDanger('O Bot Moradadedeus não respondeu!')
        }
      }
    );
  }




  BotVersiculo() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get('https://api.moradadedeus.com.br/bottelegram/sendversiculo', httpOptions).subscribe(
      (success) => {
        this.StopProgressBar();
        this.alert.AlertShowSucess('Bot Moradadedeus lhe atendeu!')
      }, (error) => {
        if (error.status == '200') {
          this.StopProgressBar();
          this.alert.AlertShowSucess('Bot Moradadedeus lhe atendeu!')
        }
        else {
          this.StopProgressBar();
          this.alert.AlertShowDanger('O Bot Moradadedeus não respondeu!')
        }
      }
    );

  }

  BotLive() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get('https://api.moradadedeus.com.br/botlive/sendlive', httpOptions).subscribe(
      (success) => {
        this.StopProgressBar();
        this.alert.AlertShowSucess('Bot Moradadedeus lhe atendeu!')
      }, (error) => {
        if (error.status == '200') {
          this.StopProgressBar();
          this.alert.AlertShowSucess('Bot Moradadedeus lhe atendeu!')
        }
        else {
          this.StopProgressBar();
          this.alert.AlertShowDanger('O Bot Moradadedeus não respondeu!')
        }
      }
    );
  }

  BotCultoFamilia() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get('https://api.moradadedeus.com.br/botcultofamilia/sendcultofamilia', httpOptions).subscribe(
      (success) => {
        this.StopProgressBar();
        this.alert.AlertShowSucess('Bot Moradadedeus lhe atendeu!')
      }, (error) => {
        if (error.status == '200') {
          this.StopProgressBar();
          this.alert.AlertShowSucess('Bot Moradadedeus lhe atendeu!')
        }
        else {
          this.StopProgressBar();
          this.alert.AlertShowDanger('O Bot Moradadedeus não respondeu!')
        }
      }
    );
  }

  BotDevocional() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get('https://api.moradadedeus.com.br/botdevocional/senddevocional', httpOptions).subscribe(
      (success) => {
        this.StopProgressBar();
        this.alert.AlertShowSucess('Bot Moradadedeus lhe atendeu!')
      }, (error) => {
        if (error.status == '200') {
          this.StopProgressBar();
          this.alert.AlertShowSucess('Bot Moradadedeus lhe atendeu!')
        }
        else {
          this.StopProgressBar();
          this.alert.AlertShowDanger('O Bot Moradadedeus não respondeu!')
        }
      }
    );
  }
  BotCultoHomenss() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get('https://api.moradadedeus.com.br/botcultohomens/sendcultohomens', httpOptions).subscribe(
      (success) => {
        this.StopProgressBar();
        this.alert.AlertShowSucess('Bot Moradadedeus lhe atendeu!')
      }, (error) => {
        if (error.status == '200') {
          this.StopProgressBar();
          this.alert.AlertShowSucess('Bot Moradadedeus lhe atendeu!')
        }
        else {
          this.StopProgressBar();
          this.alert.AlertShowDanger('O Bot Moradadedeus não respondeu!')
        }
      }
    );
  }
  BotCultoMulheres() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get('https://api.moradadedeus.com.br/botcultomulheres/sendcultomulheres', httpOptions).subscribe(
      (success) => {
        this.StopProgressBar();
        this.alert.AlertShowSucess('Bot Moradadedeus lhe atendeu!')
      }, (error) => {
        if (error.status == '200') {
          this.StopProgressBar();
          this.alert.AlertShowSucess('Bot Moradadedeus lhe atendeu!')
        }
        else {
          this.StopProgressBar();
          this.alert.AlertShowDanger('O Bot Moradadedeus não respondeu!')
        }
      }
    );
  }

}

