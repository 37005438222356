<div class="container">
    <ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>

    <div class="col-md-12">
        <div class="row" style="margin-right: 2px; padding-left: 20px;">
            <img src="../../../assets/img/sair_conta.png" height="180px" width="100px" style="padding: 0%;" />

            <div class="col-md-10" style="padding-top: 15px;">
                <h4 class="espacamentotopo " style="margin-left: 5px;">
                    <b>Cancelar Registro</b>
                </h4>
                <label>
                    <b style="color: red;">Atenção!</b>, Informamos que este processo e irreversivél!, uma vez feito o cancelamento em caso de arrependimento
                    deverá entrar em contato com a equipe de gestão da igreja para analise e liberação do cadastro.<br>
                    Este contato pode ser feito pela Pagina da Igreja acessando o menu "Contato", onde será analisado e
                    diante analise uma resposta será enviada.
                </label>
            </div>
        </div>
    </div>

    
    <br>

    <hr>

    <form [formGroup]="forncancelamento" class="m" disabled="">
        <div class="row">
            <input class="form-check-input" type="radio" value="Excesso de envios de comunicados" id="opcao"
                formControlName="opcao" [(ngModel)]="modelcancelar.opcao" name="opcao" checked
                (change)="onItemChange($event.target.value)" /> Excesso de envios de comunicados!
        </div>
        <div class="row">
            <input class="form-check-input" type="radio" value="A plataforma e muito dificil de utilizar" id="opcao"
                formControlName="opcao" [(ngModel)]="modelcancelar.opcao" name="opcao" 
                (change)="onItemChange($event.target.value)" /> A plataforma e muito dificil de utilizar!
        </div>
        <div class="row">
            <input class="form-check-input" type="radio" value="Estou me desligando da Igreja" id="opcao"
                formControlName="opcao" [(ngModel)]="modelcancelar.opcao" name="opcao" 
                (change)="onItemChange($event.target.value)" /> Estou me desligando da Igreja!
        </div>
        <div class="row">
            <input class="form-check-input" type="radio" value="Não achei interessante a plataforma" id="opcao"
                formControlName="opcao" [(ngModel)]="modelcancelar.opcao" name="opcao" 
                (change)="onItemChange($event.target.value)" /> Não achei interessante a plataforma!
        </div>
        <div class="row">
            <input class="form-check-input" type="radio" value="outros" id="opcao" formControlName="opcao"
                [(ngModel)]="modelcancelar.opcao" name="opcao"
                (change)="onItemChange($event.target.value)" /> Outros
            <div *ngIf="modelcancelar.opcao=='outros'" >
                <br>
                <input class="row" [(ngModel)]="modelcancelar.outros" name="outros" id="outros" formControlName="outros" />
            </div>
        </div>

    </form>
    <hr>
    <br>
    <div>
        <button pButton pRipple type="button" icon="pi pi-save" class="p-button-rounded" title="::Salvar Alterações::"
            (click)="onSubmit()">
        </button>
        <button pButton pRipple type="button" icon="pi pi-sign-in" class="p-button-rounded p-button-danger"
            title="::Fechar::" routerLink="/dashboard/resumo">
        </button>

    </div>


</div>
<button class="voltar-ao-topo" id="back"><a class="ancora" style="color: white;"><i
    class="pi pi-arrow-circle-up"></i></a></button>