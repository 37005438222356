<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>

<h4 class="espacamentotopo">
  <img src="../../assets/img/novos/comercio.png" style="width: 40px" /> Atualização
  Empresa / Serviços
</h4>
<label class="j">
  Seja bem-vindo(a)!<br />
  &nbsp;&nbsp;&nbsp;&nbsp; Você está na área de atualização de sua Empresa / Serviço prestado!,Lembre-se de preencher
  todos os campos.
</label>
<label class="j">
  Lembramos que os campos com (<strong class="cor"> *</strong> ) são campos de
  preenchimento obrigatório!
  <br>
<b>#FicaaDica: </b>Em caso de Celular melhor visualizado na horizontal!
</label>
<hr class="espacamentotopo bold" />
<form [formGroup]="formcadastro" disabled="">


  <!---INICIO TERCEIRA LINHA-->
  <div class="row">

    <div class="col-md-6">
      <div class="form-group">
        <label>Empresa / Serviço:</label><strong class="cor">*</strong>
        <input type="text" class="form-control" id="estabelecimento" name="estabelecimento"
          [(ngModel)]="cadastromembros.estabelecimento" placeholder="Informe Nome estabelecimento"
          formControlName="estabelecimento" maxlength="500" minlength="3" autocomplete="off"
          [ngClass]="{ 'is-invalid': submitted && f.estabelecimento.errors }" />
        <div *ngIf="submitted && f.estabelecimento.errors" class="invalid-feedback">
          <div *ngIf="f.estabelecimento.errors.required">Preenchimento obrigatório</div>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <label>Telefone/Celular:</label><strong class="cor">*</strong>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon1"><i class="fas fa-phone"></i></span>
        </div>
        <input type="tel" class="form-control" id="telefone" name="telefone" [(ngModel)]="cadastromembros.telefone"
          placeholder="Informe o Telefone" title="Formato (DD) 0000-0000 ou (DD) 00000-0000" formControlName="telefone"
          maxlength="500" minlength="3" autocomplete="off" mask="(00) 0000-0000 || (00) 00000-0000" [ngClass]="{
              'is-invalid': submitted && f.telefone.errors
            }" />
        <div *ngIf="submitted && f.telefone.errors" class="invalid-feedback">
          <div *ngIf="f.telefone.errors.required">
            Preenchimento obrigatório
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-3">
      <label>WhatsApp:</label>
      <div class="input-group mb-3">
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon1"><i class="fas fa-phone"></i></span>
        </div>
        <input type="tel" class="form-control" id="celular" name="celular" maxlength="500" autocomplete="off"
          mask="(00) 0000-0000 || (00) 00000-0000" [(ngModel)]="cadastromembros.celular"
          placeholder="Informe o Telefone" formControlName="celular" title="Formato (DD) 0000-0000 ou (DD) 00000-0000"
          autocomplete="off" />
      </div>
    </div>
  </div>



  <div class="row">
    <div class="col-md-2">
      <div class="form-group">
        <label>Cep:</label>
        <input type="tel" class="form-control" placeholder="Informe o CEP" id="cep" mask="00.000-000" name="cep"
          formControlName="cep" (blur)="consulta_cep(cadastromembros.cep)" [(ngModel)]="cadastromembros.cep"
          autocomplete="off" />
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label>Logradouro:</label><strong class="cor">*</strong>
        <input type="text" class="form-control" id="logradouro" placeholder="Informe Logradouro" name="logradouro"
          formControlName="logradouro" [(ngModel)]="cadastromembros.logradouro" maxlength="500" minlength="3"
          autocomplete="off" [ngClass]="{ 'is-invalid': submitted && f.logradouro.errors }" />
        <div *ngIf="submitted && f.logradouro.errors" class="invalid-feedback">
          <div *ngIf="f.logradouro.errors.required">
            Preenchimento obrigatório
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-2">
      <div class="form-group">
        <label>Numero:</label><strong class="cor">*</strong>
        <input type="tel" class="form-control" id="numero" placeholder="Numero" min="1" name="numero" autocomplete="off"
          formControlName="numero" [(ngModel)]="cadastromembros.numero" maxlength="9"
          [ngClass]="{ 'is-invalid': submitted && f.numero.errors }" />
        <div *ngIf="submitted && f.numero.errors" class="invalid-feedback">
          <div *ngIf="f.numero.errors.required">
            Preenchimento obrigatório
          </div>
          <div *ngIf="f.numero.errors.required">Menor valor 0</div>
        </div>
      </div>
    </div>

    <div class="col-md-2">
      <div class="form-group">
        <label>apto:</label>
        <input type="tel" class="form-control" id="apto" placeholder="apto" name="apto" formControlName="apto"
          [(ngModel)]="cadastromembros.apto" maxlength="9" autocomplete="off" />
      </div>
    </div>
  </div>

  <!----->








  <!----->
  <div class="row">
    <div class="col-md-4">
      <div class="form-group">
        <label>Bairro:</label><strong class="cor">*</strong>
        <input type="text" class="form-control" id="bairro" placeholder="Informe o Bairro" name="bairro"
          formControlName="bairro" [(ngModel)]="cadastromembros.bairro" maxlength="500" minlength="3" autocomplete="off"
          [ngClass]="{ 'is-invalid': submitted && f.bairro.errors }" />
        <div *ngIf="submitted && f.bairro.errors" class="invalid-feedback">
          <div *ngIf="f.bairro.errors.required">
            Preenchimento obrigatório
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-2">
      <div class="dropdown">
        <label>UF:</label><strong class="cor">*</strong>
        <div class="mb-3">
          <select class="custom-select" type="text" (change)="changeCity($event)" name="uf" id="uf"
            [(ngModel)]="cadastromembros.uf" formControlName="uf" autocomplete="off"
            [ngClass]="{ 'is-invalid': submitted && f.uf.errors }">
            <option value=""></option>
            <option *ngFor="let city of luf" [ngValue]="city">
              {{ city }}
            </option>
          </select>
          <div *ngIf="submitted && f.uf.errors" class="invalid-feedback">
            <div *ngIf="f.uf.errors.required">Preenchimento obrigatório</div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-4">
      <div class="form-group">
        <label>Cidade:</label><strong class="cor">*</strong>
        <input type="text" class="form-control" id="cidade" placeholder="Informe sua cidade" name="cidade"
          formControlName="cidade" [(ngModel)]="cadastromembros.cidade" autocomplete="off"
          [ngClass]="{ 'is-invalid': submitted && f.cidade.errors }" maxlength="500" minlength="3" />
        <div *ngIf="submitted && f.cidade.errors" class="invalid-feedback">
          <div *ngIf="f.cidade.errors.required">
            Preenchimento obrigatório
          </div>
        </div>
      </div>
    </div>

    <div class="col-md" style="padding-top: 2.5%;">
      <div>
        <input type="checkbox" id="ativo" data-md-icheck (change)="CKAtivo($event)" formControlName="ativo" name="ativo"
          [(ngModel)]="cadastromembros.ativo" />
        <b>&nbsp;Ativa</b>
      </div>
    </div>

  </div>

  <div class="row">
    <div class="col-md-6">
      <div class="form-group">
        <label>Site:</label><span><a href="http://www.moradadedeus.com.br" target="_blank"> exemplo</a></span>
        <input type="text" class="form-control" id="site" name="site" placeholder="Copie e cole a url do site aqui!"
          formControlName="site" maxlength="500" [(ngModel)]="cadastromembros.site" autocomplete="off" />
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group">
        <label>Facebook:</label><span><a href="https://www.facebook.com/igrejapentecostalMoradadeDeus" target="_blank">
            exemplo</a></span>
        <input type="text" class="form-control" id="facebook" name="facebook"
          placeholder="Copie e cole a url do facebook aqui!" formControlName="facebook" maxlength="500"
          [(ngModel)]="cadastromembros.facebook" autocomplete="off" />

      </div>
    </div>


    <div class="col-md-6">
      <div class="form-group">
        <label>Instagram:</label><span><a href="https://www.instagram.com/moradajfoficial/" target="_blank">
            exemplo</a></span>
        <input type="text" class="form-control" id="instagram" name="instagram"
          placeholder="Copie e cole a url do Instagram aqui!" formControlName="instagram" maxlength="500"
          [(ngModel)]="cadastromembros.instagram" autocomplete="off" />

      </div>
    </div>


    <div class="col-md-6">
      <div class="form-group">
        <label>Google Maps</label><span><a
            href="https://www.google.com.br/maps/@-21.7416378,-43.3744249,3a,75y,32.52h,64.56t/data=!3m6!1e1!3m4!1sD8h6bxziytkJnut-Z7j4ug!2e0!7i13312!8i6656"
            target="_blank"> exemplo</a></span>
        <input type="text" class="form-control" id="maps" name="maps"
          placeholder="Copie e cole a url do Google Maps aqui!" formControlName="maps" maxlength="500"
          [(ngModel)]="cadastromembros.maps" autocomplete="off" />

      </div>
    </div>
  </div>




  <div class="row">

    <div class="col-md">
      <div class="form-group">
        <label>TAG de pesquisa:</label><span style="font-size: smaller;"> Ex: Tecnologia | Suporte |
          Informática</span>
        <input type="text" class="form-control" id="tag" name="tag" placeholder="Informe as TAGS separadas por |"
          formControlName="tag" maxlength="500" minlength="3" [(ngModel)]="cadastromembros.tag" autocomplete="off"
          [ngClass]="{ 'is-invalid': submitted && f.tag.errors }" />
        <div *ngIf="submitted && f.tag.errors" class="invalid-feedback">
          <div *ngIf="f.tag.errors.required">Preenchimento obrigatório</div>
        </div>
        <span style="font-size: smaller;"> <b>Obs:</b> separe por | entre as TAGS como no exemplo,essas
          TAGS são utilizadas na consulta.</span>
        <br><span>{{ cadastromembros.informacoes.length }}/500</span>

      </div>
    </div>


    <div class="col-md-4">
      <div class="form-group">
        <label>E-mail:</label>
        <input type="text" class="form-control" id="email" name="email" [(ngModel)]="cadastromembros.email"
          formControlName="email" maxlength="500" autocomplete="off" />
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-md">
      <div class="form-group">
        <label>Informações sobre a Empresa / Serviço:</label><strong class="cor">*</strong>
        <textarea type="text" rows="3" class="form-control" id="informacoes" name="informacoes"
          formControlName="informacoes" [(ngModel)]="cadastromembros.informacoes" rows="2" autocomplete="off"
          maxlength="500" minlength="3" [ngClass]="{ 'is-invalid': submitted && f.informacoes.errors }"></textarea>

        <div *ngIf="submitted && f.informacoes.errors" class="invalid-feedback">
          <div *ngIf="f.informacoes.errors.required">
            Preenchimento obrigatório
          </div>
        </div>
        <span>{{ cadastromembros.informacoes.length }}/500</span>
      </div>
    </div>
    <div class="col-md-4">
      <div>
        <label>Hórario de Funcionamento:</label><strong class="cor">*</strong>
        <textarea type="text" class="form-control" id="funcionamento" placeholder="Horário de funcionamento"
          name="funcionamento" formControlName="funcionamento" [(ngModel)]="cadastromembros.funcionamento"
          maxlength="500" minlength="5" rows="2" autocomplete="off"
          [ngClass]="{ 'is-invalid': submitted && f.funcionamento.errors }"></textarea>
        <div *ngIf="submitted && f.funcionamento.errors" class="invalid-feedback">
          <div *ngIf="f.funcionamento.errors.required">
            Preenchimento obrigatório
          </div>
        </div>
      </div>
    </div>
  </div>



  <!----->
  <div style="padding-top: 15px; padding-bottom: 10px;">
    <button pButton pRipple type="button" icon="pi pi-save" class="p-button-rounded" style="margin-right: 5px;"
      title=":: SALVAR ::" (click)="onSubmit()"></button>
    <button pButton pRipple type="button" icon="pi pi-sign-in" class="p-button-rounded p-button-danger"
      title="::Fechar::" routerLink="/dashboard/listagem-estabelecimento">
    </button>

  </div>

</form>

<br><br><br>