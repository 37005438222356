<ng-progress #progressBar (started)="StartProgressBar()" (completed)="StopProgressBar()"></ng-progress>
<form [formGroup]="formcadastro" >
    <div class="row" style="margin-top: 10px; margin-right: 20px;">
        <div class="col-md">
            <img id="fotos" src="{{converted_image}}" width="250px" height="200px" style="border: 2px;" />
            <div class="row" style="margin-top: 0%; margin-left: 5px;">
                <label for='arquivo' class="x">+</label>
                <input #fileInput type='file' onchange="readURL(this);" accept="image/png, image/jpg,image/jpeg"
                    id='arquivo' >
                <label for='remover' class="xx">-</label>
                <input #fileInput type='button' (click)="FotoPadrao()" accept="image/png, image/jpg,image/jpeg"
                    id='remover'>
            </div>
        </div>

        <div class="col-md-10">
            <br>
            <h3><b>Perfil</b></h3>
            <label style="padding-top: 5px;"><b>Atenção:</b> Mantenha sempre seus dados atualizados para que possa continuar recebendo as
                notificações
                de nossa igreja</label>
            <label>Não compartilhamos a foto muito menos os dados informados #FiqueTranquilo</label>
            <br>
            <br>
            <div class="row" style="padding-top: 15px;">

                <div class="col-md-3">
                    <div class="form-group">
                        <label>CPF:</label><strong class="cor">*</strong>
                        <input type="text" disabled="true" class="form-control" id="cpf" placeholder="informe o CPF"
                            mask="CPF_CNPJ" name="cpf" maxlength="14" autocomplete="off" formControlName="cpf"
                            [(ngModel)]="cadastromembros.cpf" [ngClass]="{ 'is-invalid': submitted && f.cpf.errors }" />
                        <div *ngIf="submitted && f.cpf.errors" class="invalid-feedback">
                            <div *ngIf="f.cpf.errors.required">Preenchimento obrigatório</div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form-group">
                        <label>Nome Completo:</label><strong class="cor">*</strong>
                        <input type="text" class="form-control" id="nome" name="nome" [(ngModel)]="cadastromembros.nome"
                            placeholder="Informe Nome Completo" formControlName="nome" maxlength="500" minlength="3"
                            [(ngModel)]="cadastromembros.nome" autocomplete="off"
                            [ngClass]="{ 'is-invalid': submitted && f.nome.errors }" />
                        <div *ngIf="submitted && f.nome.errors" class="invalid-feedback">
                            <div *ngIf="f.nome.errors.required">Preenchimento obrigatório</div>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="dropdown">
                        <label>Estado Civil:</label><strong class="cor">*</strong>
                        <div class="mb-3">
                            <select class="custom-select" type="text" name="estado_civil" id="estado_civil"
                                [(ngModel)]="cadastromembros.estado_civil" formControlName="estado_civil"
                                [ngClass]="{ 'is-invalid': submitted && f.estado_civil.errors }">
                                <div *ngIf="submitted && f.estado_civil.errors" class="invalid-feedback"></div>
                                <option value=""></option>
                                <option *ngFor="let city of lcivil" [ngValue]="city">
                                    {{ city }}
                                </option>
                            </select>
                            <div *ngIf="submitted && f.estado_civil.errors" class="invalid-feedback">
                                <div *ngIf="f.estado_civil.errors.required">
                                    Preenchimento obrigatório
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row" style="margin-right: 20px;">

        <div class="col-md-6">
            <label>E-mail:</label>
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1"><i class="fas fa-at"></i></span>
                </div>
                <input type="email" class="form-control" id="email" placeholder="Informe E-mail" name="email"
                    formControlName="email" [(ngModel)]="cadastromembros.email" maxlength="500" minlength="3"
                    autocomplete="off" />
            </div>
        </div>
        <div class="col-md-3">
            <div class="form-group">
                <label>Data Nascimento:</label><strong class="cor">*</strong>
                <input type="date" class="form-control" id="dt_nascimento" placeholder="Data Nascimento"
                    name="dt_nascimento" maxlength="10" minlength="10" autocomplete="off"
                    formControlName="dt_nascimento" [(ngModel)]="cadastromembros.dt_nascimento"
                    [ngClass]="{ 'is-invalid': submitted && f.dt_nascimento.errors }" />
                <div *ngIf="submitted && f.dt_nascimento.errors" class="invalid-feedback">
                    <div *ngIf="f.dt_nascimento.errors.required">
                        Preenchimento obrigatório
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <div class="form-group">
                <label>Data Batismo:</label>
                <input type="date" class="form-control" id="dt_batismo" placeholder="Data Batismo" name="dt_batismo"
                    maxlength="10" minlength="10" autocomplete="off" formControlName="dt_batismo"
                    [(ngModel)]="cadastromembros.dt_batismo" />
            </div>
        </div>
    </div>


    <!---INICIO TERCEIRA LINHA-->
    <div class="row" style="margin-right: 20px;">
        <div class="col-md-3">
            <label>Telefone/Celular:</label><strong class="cor">*</strong>
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1"><i class="fas fa-phone"></i></span>
                </div>
                <input type="text" class="form-control" id="contato_preferencial" name="contato_preferencial"
                    [(ngModel)]="cadastromembros.contato_preferencial" placeholder="Informe o Telefone"
                    title="Formato (DD) 0000-0000 ou (DD) 00000-0000" formControlName="contato_preferencial"
                    maxlength="500" minlength="3" autocomplete="off" mask="(00) 0000-0000 || (00) 00000-0000" [ngClass]="{
                    'is-invalid': submitted && f.contato_preferencial.errors}" />
                <div *ngIf="submitted && f.contato_preferencial.errors" class="invalid-feedback">
                    <div *ngIf="f.contato_preferencial.errors.required">
                        Preenchimento obrigatório
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-3">
            <label>Telefone/Celular:</label>
            <div class="input-group mb-3">
                <div class="input-group-prepend">
                    <span class="input-group-text" id="basic-addon1"><i class="fas fa-phone"></i></span>
                </div>
                <input type="text" class="form-control" id="contato_secundario" name="contato_secundario"
                    maxlength="500" minlength="3" autocomplete="off" mask="(00) 0000-0000 || (00) 00000-0000"
                    [(ngModel)]="cadastromembros.contato_secundario" placeholder="Informe o Telefone"
                    formControlName="contato_secundario" title="Formato (DD) 0000-0000 ou (DD) 00000-0000"
                    autocomplete="off" />
            </div>
        </div>
        <div class="col-md-3">
            <div class="dropdown">
                <label>Gênero:</label><strong class="cor">*</strong>
                <div class="mb-3">
                    <select class="custom-select" type="text" name="genero" id="genero"
                        [(ngModel)]="cadastromembros.genero" formControlName="genero"
                        [ngClass]="{ 'is-invalid': submitted && f.genero.errors }">
                        <div *ngIf="submitted && f.genero.errors" class="invalid-feedback"></div>
                        <option value=""></option>
                        <option *ngFor="let d of cbgenero" [ngValue]="d">
                            {{ d }}
                        </option>
                    </select>
                    <div *ngIf="submitted && f.genero.errors" class="invalid-feedback">
                        <div *ngIf="f.genero.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col-md-3">
            <div class="dropdown">
                <label>Será Dizimista?:</label><strong class="cor">*</strong>
                <div class="mb-3">
                    <select class="custom-select" type="text" name="dizimista?" id="dizimista"
                        [(ngModel)]="cadastromembros.dizimista" formControlName="dizimista"
                        [ngClass]="{ 'is-invalid': submitted && f.dizimista.errors }">
                        <div *ngIf="submitted && f.dizimista.errors" class="invalid-feedback"></div>
                        <option value=""></option>
                        <option *ngFor="let d of cbdizimista" [ngValue]="d">
                            {{ d }}
                        </option>
                    </select>
                    <div *ngIf="submitted && f.dizimista.errors" class="invalid-feedback">
                        <div *ngIf="f.dizimista.errors.required">
                            Preenchimento obrigatório
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
    <!---FIM TERCEIRA LINHA-->

    <div class="row" style="margin-right: 20px;">
        <div class="col-md-2">
            <div class="form-group">
                <label>Cep:</label>
                <input type="text" class="form-control" placeholder="Informe o CEP" id="cep" mask="00.000-000"
                    (blur)="consulta_cep(cadastromembros.cep)" name="cep" formControlName="cep"
                    [(ngModel)]="cadastromembros.cep" maxlength="500" minlength="3" />
            </div>
        </div>
        <div class="col-md-6">
            <div class="form-group">
                <label>Logradouro:</label><strong class="cor">*</strong>
                <input type="text" class="form-control" id="logradouro" placeholder="Informe Logradouro"
                    name="logradouro" formControlName="logradouro" [(ngModel)]="cadastromembros.logradouro"
                    maxlength="500" minlength="3" [ngClass]="{ 'is-invalid': submitted && f.logradouro.errors }" />
                <div *ngIf="submitted && f.logradouro.errors" class="invalid-feedback">
                    <div *ngIf="f.logradouro.errors.required">
                        Preenchimento obrigatório
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-2">
            <div class="form-group">
                <label>Numero:</label><strong class="cor">*</strong>
                <input type="number" class="form-control" id="numero" placeholder="Numero" min="1" name="numero"
                    formControlName="numero" [(ngModel)]="cadastromembros.numero" maxlength="9" minlength="3"
                    [ngClass]="{ 'is-invalid': submitted && f.numero.errors }" />
                <div *ngIf="submitted && f.numero.errors" class="invalid-feedback">
                    <div *ngIf="f.numero.errors.required">
                        Preenchimento obrigatório
                    </div>
                    <div *ngIf="f.numero.errors.required">Menor valor 0</div>
                </div>
            </div>
        </div>

        <div class="col-md-2">
            <div class="form-group">
                <label>Apto:</label>
                <input type="number" class="form-control" id="apto" placeholder="apto" min="1" name="apto"
                    formControlName="apto" [(ngModel)]="cadastromembros.apto" maxlength="9" minlength="3" />
            </div>
        </div>
    </div>
    <!----->
    <!----->
    <div class="row" style="margin-right: 20px;">
        <div class="col-md-5">
            <div class="form-group">
                <label>Bairro:</label><strong class="cor">*</strong>
                <input type="text" class="form-control" id="bairro" placeholder="Informe o Bairro" name="bairro"
                    formControlName="bairro" [(ngModel)]="cadastromembros.bairro" maxlength="500" minlength="3"
                    [ngClass]="{ 'is-invalid': submitted && f.bairro.errors }" />
                <div *ngIf="submitted && f.bairro.errors" class="invalid-feedback">
                    <div *ngIf="f.bairro.errors.required">
                        Preenchimento obrigatório
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-2">
            <div class="dropdown">
                <label>UF:</label><strong class="cor">*</strong>
                <div class="mb-3">
                    <select class="custom-select" type="text" name="uf" id="uf" [(ngModel)]="cadastromembros.uf"
                        formControlName="uf" [ngClass]="{ 'is-invalid': submitted && f.uf.errors }">
                        <div *ngIf="submitted && f.uf.errors" class="invalid-feedback"></div>
                        <option value=""></option>
                        <option *ngFor="let city of luf" [ngValue]="city">
                            {{ city }}
                        </option>
                    </select>
                    <div *ngIf="submitted && f.uf.errors" class="invalid-feedback">
                        <div *ngIf="f.uf.errors.required">Preenchimento obrigatório</div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-md-5">
            <div class="form-group">
                <label>Cidade:</label><strong class="cor">*</strong>
                <input type="text" class="form-control" id="cidade" placeholder="Informe sua cidade" name="cidade"
                    formControlName="cidade" [(ngModel)]="cadastromembros.cidade"
                    [ngClass]="{ 'is-invalid': submitted && f.cidade.errors }" maxlength="500" minlength="3" />
                <div *ngIf="submitted && f.cidade.errors" class="invalid-feedback">
                    <div *ngIf="f.cidade.errors.required">
                        Preenchimento obrigatório
                    </div>
                </div>
            </div>
        </div>
    </div>
<hr>
    <div style="padding-bottom: 50px; padding-top: 20px;">

        <button pButton pRipple type="button" icon="pi pi-save" class="p-button-rounded" style="margin-right: 5px;"
            title=":: Atualizar ::" (click)="onSubmit()"></button>
        <button pButton pRipple type="button" icon="pi pi-sign-in" class="p-button-rounded p-button-danger"
            title="::Fechar::" routerLink="/dashboard/resumo"></button>

    </div>



</form>
<button class="voltar-ao-topo" id="back"><a class="ancora" style="color: white;"><i
            class="pi pi-arrow-circle-up"></i></a></button>