export class ProdutosModel {



    numero_pedido: string = '';
    dt_pedido: Date;
    quantidade: any = '';
    tipo: string = '';
    produto: string = '';
    modelo: string = '';
    cor: string = '';
    marca: string = '';
    setor: string = '';
    observacoes: string = '';


}
