import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { cultomodel } from 'src/app/culto-inscricao/CultoModel';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { Parametros } from 'src/app/shared/parametros.enum';
import { SharedModule } from 'src/app/shared/shared.module';

@Component({
  selector: 'app-detalhe-usuario-culto-registrado',
  templateUrl: './detalhe-usuario-culto-registrado.component.html',
  styleUrls: ['./detalhe-usuario-culto-registrado.component.css']
})
export class DetalheUsuarioCultoRegistradoComponent implements OnInit {

  idculto: any;
  rota: any;
  private urlseminario = Parametros.CADASTROCULTO;
  private urlrelatoriotodos = Parametros.RELATORIOSTODOSCULTOREGISTRADO;
  nome: string = "";
  data_cadastro: any = "";
  culto: any = "";
  dadosculto: string = "";
  telefone: string = "";
  email: string = "";
  imprimir: any;


  constructor(private http: HttpClient,
    private alert: AlertModalService,
    private autenticado: SharedModule,
    private r: Router) {
    this.rota = r
  }

  startedClass = false;
  completedClass = false;
  preventAbuse = false;

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//

  ngOnInit(): void {
    if (this.autenticado.token == null) {
      this.autenticado.ResetDados();
      this.alert.AlertShowDanger('Usuário não autenticado, redirecionando...')
      this.rota.navigate(['/login']);
    } else {
      if (this.autenticado.ValidarToken() != true) {
        this.autenticado.ResetDados();
        this.alert.AlertShowDanger('Token Invalido!')
        this.rota.navigate(['/login']);
      }
    }
    this.idculto = localStorage.getItem('CultoDetalhe');
    if (this.idculto == null || this.idculto == "") {
      this.rota.navigate(['/dashboard/listagem/listagem-culto-registrado']);
    } else {
      this.GetUsuarioSeminario()
    }
  }

  GetUsuarioSeminario() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token.replace("\r\n", "").replace("\n", "")
      })
    }
    this.http.get<cultomodel>(this.urlseminario + 'GetLoadCultodetalhe/?parametro='+ this.idculto, httpOptions).subscribe(
      (success) => {
        this.nome = success.nome;
        this.data_cadastro = success.dt_cadastro.replace("T00:00:00","");
        this.culto = success.tipo_culto;
        this.dadosculto = success.dados_culto;
        this.telefone = success.telefone;
        this.email = success.email;
        
      },
      (error) => {
        this.alert.AlertShowDanger('Erro ao detalhar Usuario');
        this.rota.navigate(['/dashboard/listagem/listagem-culto-registrado']);
      }
    )
  }

  ImprimirElogio() {
    this.imprimir = this.idculto;
    this.GerarPDF();
  }

  GerarPDF() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': "Bearer " + this.autenticado.token
      })
    }
    this.http.get(this.urlrelatoriotodos + 'GetRelatorioUsuariosDetalhado/?parametro=' + this.imprimir, httpOptions).subscribe(
      (success) => {
        const linkSource = 'data:application/pdf;base64,' + success;
        const downloadLink = document.createElement("a");
        const fileName = "IEPMD_Cadastro_Culto_" + (this.autenticado.nome).replace(" ", "") + ".pdf";
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
      }, (error) => {
        if (error.status == '403') {
          this.alert.AlertShowDanger('Erro ao tentar Gerar PDF');
        } else {
          const linkSource = 'data:application/pdf;base64,' + error.error.text;
          const downloadLink = document.createElement("a");
          const fileName = "IEPMD_Cadastro_Culto_" + (this.autenticado.nome).replace(" ", "") + ".pdf";
          downloadLink.href = linkSource;
          downloadLink.download = fileName;
          downloadLink.click();
        }
      }
    );
  }
}
