<!--
Autor: Bruno Rafael
Data: 13/08/2020
Tipo: Menu navbar da plataforma
-->
<div>
    <nav class="navbar navbar-expand-md cor bloco col-md">
        <img src="../../assets/img/novos/logo.png"
            style="font-size: xx-large; padding-top: 0.5px; margin-bottom: 1px; padding-left: 0%; padding-right: 0%;">
        <div>
            <label class="txtnossocondominio"> Morada de Deus</label>
            <label class="txtgestaosimplificada"> Igreja Evangelica Pentecostal</label>
        </div>
        <!--Botão toogler-->
        <button class="navbar-toggler navbar-dark navtoogler " type="button" data-toggle="collapse"
            data-target="#navbarNav">
            <span class="navbar-toggler-icon "></span>
        </button>
        <!--Menus ( links )-->
        <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="navbar-nav mr-auto">
            </ul>
            <ul class="navbar-nav">
                
                <li routerLinkActive="active">
                    <a class="nav-link" routerLink="" title="Home"><i
                            class="fas fa-home"></i> Home</a>
                </li>
                
                <li routerLinkActive="active">
                    <a class="nav-link" routerLink="/cadastros" title="Cadastro"><i class="fas fa-book-open"></i>
                        Cadastros</a>
                </li>

                <li routerLinkActive="active">
                    <a class="nav-link" title="Visitante" routerLink="/visitante"><i class="fas fa-heartbeat"></i>
                        Visitantes</a>
                </li>
                <li routerLinkActive="active">
                    <a class="nav-link" routerLink="/login" title="Efetuar Login"><i class="fas fa-fingerprint"></i>
                        Login</a>
                </li>
            </ul>
        </div>
    </nav>
</div>