import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertModalService } from 'src/app/shared/alert-modal/alert-modal.service';
import { EventEmitterService } from 'src/app/shared/EventEmitterService';
import { batismomodel } from './batismoModel';
import { CadastroBatismoService } from './cadastro-batismo.service';

@Component({
  selector: 'app-cadastro-batismo',
  templateUrl: './cadastro-batismo.component.html',
  styleUrls: ['./cadastro-batismo.component.css']
})
export class CadastroBatismoComponent implements OnInit {

  formculto: FormGroup;
  submitted = false;
  cadastroculto: batismomodel;


  startedClass = false;
  completedClass = false;
  preventAbuse = false;
  rota: Router;



  sexos: any = [
    'MASCULINO',
    'FEMININO']
  modaltitulo: string;
  modalconteudo: string;


  constructor(
    private formBuilder: FormBuilder,
    private alert: AlertModalService,
    private service: CadastroBatismoService,
    private router: Router
  ) {
    this.rota = this.router;
    EventEmitterService.get('loginAlert').emit(false);
  }

  //ProgressBar
  //====================================================================//
  StartProgressBar() {
    this.startedClass = true;
    setTimeout(() => {
      this.startedClass = false;
    }, 800);
  }
  StopProgressBar() {
    this.completedClass = true;
    setTimeout(() => {
      this.completedClass = false;
    }, 800);
  }
  //====================================================================//

  criarform() {
    this.formculto = this.formBuilder.group({
      documento: ['', [Validators.required, Validators.maxLength(14), Validators.minLength(8)]],
      nome: ['', Validators.required],
      sexo: ['', Validators.required],
      telefone: ['', [Validators.required, Validators.maxLength(11), Validators.minLength(10)]],
      dt_nascimento: ['', Validators.required],
      email: [''],
      duvida: ['']
    });
  }

  consultacpf(cpf: any) {
    if (this.cadastroculto.documento.length >= 8) {
      this.service.Consulta(this.cadastroculto.documento).subscribe(
        (success) => {
          this.StopProgressBar();

          this.cadastroculto = new batismomodel();
          this.modaltitulo = 'Cadastro já Existente!';
          this.modalconteudo =
            'Olá <b>' + success.nome.toUpperCase()
            + '</b>,<br>Estava olhando aqui em minhas anotações e verifiquei que já existe seu cadastro em nossa plataforma!, Pedimos por favor que verifique seu e-mail ou qualquer duvida entre em contato com a gestão da igreja!<br>Deus Abençõe!<br><b>Att.<br>Morada de Deus</b>';
          document.getElementById('openModalButton').click();
        },
        (error) => {
          this.StopProgressBar();
          document.getElementById('nome').focus();
        }
      );
    }
  }


  //BOTAO SALVAR
  //====================================================================//
  onSubmit() {
    this.submitted = true;

    if (this.formculto.invalid) {
      this.alert.AlertShowDanger('Favor verificar os campos em destaque!');
      return;
    } else {
      this.service
        .CadastroCulto(this.formculto.value)
        .subscribe(
          () => {
            this.StopProgressBar();
            this.submitted = false;
            this.alert.AlertShowSucess('Registro Realizado Com sucesso!');
            localStorage.setItem('tipo', 'CadastroBatismo');
            localStorage.setItem('CadastroBatismo-save', JSON.stringify(this.formculto.value));
            this.rota.navigate(['/retorno']);
          },
          (error) => {
            this.StopProgressBar();
            if (error.status == '200') {
              this.StopProgressBar();
              this.submitted = false;
              this.alert.AlertShowSucess('Registro Realizado Com sucesso!');
              localStorage.setItem('tipo', 'CadastroBatismo');
              localStorage.setItem('CadastroBatismo-save', JSON.stringify('SEM EMAIL'));
              this.rota.navigate(['/retorno']);
            }
            if (error.status == '422') {
              this.cadastroculto = new batismomodel();
              this.modaltitulo = 'Cadastro Existente!';
              this.modalconteudo =
                'Olá! <b>' + error.error.nome
                + '</b>,<br>Estava olhando aqui em minhas anotações e verifiquei que já existe seu cadastro em nossa plataforma para o Batismo nas Águas, em caso de duvidas não deixe de nos reportar para que possamos analisar e lhe retornar!\nDeus Abençõe!';
              document.getElementById('openModalButton').click();
              document.getElementById('cpf').focus();

            } else if (error.status == '500') {
              this.alert.AlertShowDanger(error.error);

            } else {
              if (error.status == '200') {
              } else {
                this.alert.AlertShowDanger(error.error);
              }
              document.getElementById('documento').focus();
            }
          }
        );
    }
    //====================================================================//
  }
  //====================================================================//

  //recarregar Form
  //====================================================================//
  reset() {
    this.cadastroculto = new batismomodel();
    this.submitted = false;
    document.getElementById('documento').focus();
  }

  // Pegar nome dos campos,
  //====================================================================//
  get f() {
    return this.formculto.controls;
  }

  //Inicio
  //====================================================================//
  ngOnInit() {
    this.cadastroculto = new batismomodel();
    this.criarform();
    document.getElementById('documento').focus();

  }


  //====================================================================//
  //PEGAR VALOR DIZIMISTA FORM
  //====================================================================//
  get citysexo() {
    return this.formculto.get('sexo');
  }
  //====================================================================//
  // Pegar o valor do combobox DIZIMISTA
  //====================================================================//
  changeministeriomorada(e) {
    console.log(e.name);
    this.citysexo.setValue(e.target.value, {
      onlySelf: true,
    });
  }
  //====================================================================//

}
